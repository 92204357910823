import { Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ChangeContext, Options } from "ng5-slider";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { AppConfig } from "src/app/config/app.config";
import { Constants } from "src/app/Constants";
import {
  DownloadModel,
  ExpenseAndReportModel,
  FilterModal,
  StatusListModel,
  TripFilterModal,
  UserModel,
  filterM,
} from "src/app/models/Common/common.model";
import {
  AddReportExpense,
  DeleteExpenses,
  ExpenseListModel,
  ExpenseModel,
  GetExpenseModels,
  NewTripModel,
  TripsListModel,
  settlementmodal,
} from "src/app/models/Expenses/expenses.model";
import { CommonService } from "src/app/services/common-services/common.service";
import { ExpensesService } from "src/app/services/expenses.service";
import { PlatformService } from "src/app/services/platformbase.service";
import * as _ from "lodash";
import { DatechangeService } from "src/app/services/common-services/datechange.service";
import {
  IonInput,
  AlertController,
  ModalController,
  NavController,
  NavParams,
} from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/services/common-services/shared.service";
import { CreateAdvance } from "src/app/models/Advance/advance.model";
import { AddCommentModel, FetchTripInfo, GetCommentModel, GetTripInfo, RemoveReportModal } from "src/app/models/Reports/reports.model";
import { ReportsService } from "src/app/services/reports.service";
import { CustomDatePickerComponent } from "../custom-date-picker/custom-date-picker.component";
import { CustomSelectDropdownComponent } from "../custom-select-dropdown/custom-select-dropdown.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { Title } from "@angular/platform-browser";
import { MatDatepicker } from "@angular/material/datepicker";
// import { borderTopRightRadius } from "html2canvas/dist/types/css/property-descriptors/border-radius";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { request } from "http";
// import { request } from "http";
@Component({
  selector: "app-advance",
  templateUrl: "./advance.component.html",
  styleUrls: ["./advance.component.scss"],
})
export class AdvanceComponent implements OnInit {
  @ViewChild("downloadLoader", { static: false }) private downloadLoader;
  @ViewChild('fromdate') fromdate: MatDatepicker<Date>;
  @ViewChild('todate') todate: MatDatepicker<Date>;
  isTripdetail = false;
  isTripOpenn = true;
  isTripOpen = false;
  isTripOpenIcon = true;
  isTripdetailicon = false;
  issettlementpdetailicon=false;
  isclaimdetailicon =false;
  isFirstOpenproof = false;
  isFirstOpenProof = false;
  isapprovalThirdOpen = false;
  isApprovalOpen = false;
  istravellerThirdOpen=false;
  istravellerOpen =false;
  platformType: number;
  hideColumns = [];
  isPageLoad = true;
  pageNumber = 1;
  pageSize: 1;
  showLoading = true;
  expenseList = [];
  advanceList = [];
  expenseAndReportModel = new ExpenseAndReportModel();
  filterModal = new FilterModal();
  userInfo: any;
  ExpenseorReport: string;
  draft = true;
  isApicallinProgress = false;
  totalRecords = 0;
  isExpenseCreated: boolean;
  minAndMax: any;
  selectedExpense = [];
  selectedStatus = [];
  selectedcategory = [];
  indeterminateState: boolean;
  addExpenseListHide = false;
  options: any;
  minPrice = 0;
  maxPrice = 0;
  showExpFilter = false;
  showExpFooter = false;
  showExpList = true;
  showSearch = false;
  tableHide = true;
  showExpSearch = false;
  showExpDetail = false;
  checkAll: boolean = false;
  checkedAll: boolean = false;
  public SearchListText: Subject<string> = new Subject();
  searchExpense: string;
  mobMaxDate: string;
  currentday: string;
  maxDate: Date;
  validationMsg: any;
  showFilter: boolean;
  dropdownFlag: boolean;
  dropdownFlaglist: boolean;
  statusList: any;
  checkAllStatus: boolean;
  statusState: boolean;
  status: string;  
  frmDate: any;
  minDate: Date;
  minDatee: any;
  tDate: any;
  approvesearch: any;
  showSummaryLoading = true;
  expense: ExpenseListModel;
  summaryData: any;
  showaddNewMileage = false;
  totalAmt: number;
  sub: any;
  draftBtns = false;
  btnSubDisabled = false;
  settlebtnSubDisabled =false;
  buttonDisabled = false;
  deleteExpenses = new DeleteExpenses();
  expenseModel = new ExpenseModel();
  addReportExpense = new AddReportExpense();
  nextvalue: number;
  unReportExpenses: any[];
  statusArray = [];
  createAdvanceModel = new CreateAdvance();
  dummyList = [1, 2, 3, 4];
  isFirstOpen = false;
  isThirdOpen = true;
  isSecondOpen = true;
  walletAdvance: any;
  tripsList: any = [];

  showMobileDetails: boolean = true;
  addExpenseHide = true;
  segmentModel = "draft";
  expenseId: number;
  expenseGUID: string;
  claimID: number;
  fromcomp: string;
  searchBar = false;
  hideAdvBtn = true;
  fromAnalytics = false;
  isUserToggle = true;
  sharedSubsc: any;
  /*proof */
  proofList: any;
  modalRef: any;
  modalRef1: any;
  proofid: any;
  imgNum = 1;
  downloadProof = [];
  maxDateStr: any = moment(new Date().getTime())
    .add(2000, "years")
    .format("YYYY-MM-DD");
  minDateStr: any = moment(new Date().getTime())
    .subtract(2000, "years")
    .format("YYYY-MM-DD");
  reportList = [];
  downloadBtn = false;
  downloadActionPoolCount = 0;
  downloadTime: any;

  //newCode
  getExpenseIds = new GetExpenseModels();
  invoiceDetails: any;
  isPdf: boolean;
  isZip: boolean;
  pdfData: string;
  expenseID: any;
  defaultIndex = 0;

  showDownloadOptions: boolean = false;
  dropdownValue: any;
  downloadOptionSelection = 0;
  isFourthOpen = true;

  isPageLoadTrips = true;
  showLoadingTrips = true;
  pageNumberTrips = 1;
  pageSizeTrips = 50;
  isApicallinProgressTrips = false;
  tripfilterModal = new TripFilterModal();
  selectedTripCategory = [];
  selectedTrips: any[];
  tripsRecoeds = 0;
  seletedTrip: any;
  showTripDetails: boolean = false;
  filterListStatus = [];
  filterstatus: any;
  filterService: any;
  checkAllfilterStatus: any;
  isSelected: boolean = false;
  checkAllTripCategory: boolean = false;
  filterCategoryList = [];
  checkAllprod: boolean = false;
  tripCategoryState: boolean;
  checkAllTripStatus: any;
  tripStatusState: boolean;
  expcurrencyIcon: any;
  // isTripOpen = true;
  openfromdate: boolean = false;
  opentodate: boolean = false;
  getCommentModel = new GetCommentModel();
  addCommentModel = new AddCommentModel();
  chatLoad = false;
  addDisable = false;
  openedExpChat: any;
  chatList: any;
  profileImgPath: string;
  personalInfo: any;
  reportResponseList: any;
  advancesummarylist: any;
  expenseloading: boolean;
  ispayment = false;
  ispaymentopen = false;
  filterdata = [];
  filterMinPrice: number;
  filterMaxPrice: number;
  filterFromDate: any;
  filterToDate: string;
  expenseIds: any;
  previewAttachmentsArray: any[];
  isReadOnly: boolean;
  currentExpenseIndex: number;
  selectedAdvance:any;
  selectedTripDetails: any;
  proofsDownload = true;

  checkParent: boolean;
  downloadfile: boolean;
  formattedAvailableTripAdvanceBalance: any;
  serviceList=[];
  triplistdata=[];
  settlementmodal=new settlementmodal();
  paymentModeList = [{ id: 1, name: "Cash" }, { id: 2, name: "Cheque" },{ id: 2, name: "NEFT" },{ id: 2, name: "UPI" },{ id: 2, name: "Bank Transfer" }];
  currentDateStr: any = moment(new Date(),'DD-MM-YYYY').format("DD-MMM-YY");
  imgUpload: boolean;
  commaSeperatorArray: any;
  selectedTrip=[];
  settleadvanceForm: FormGroup;
  submitted: boolean;
  openfromdatesettlement: boolean=false;
  selectedTripIndex: number;
  ontabchangelist: any;
  tripAttachemnet: boolean;
  tridetails=[];
  hideTripDetails: boolean;
  fromPage = 'advance'
  removeReportList: any;

  globalIndex = 0;
  editedExchangeRate = 0;
  filterClaimTypeId: any;
  filterClaimTypeName: any;

  reportSummaryData: any;

  /*proof */

  constructor(
    private modalService: BsModalService,
    private platformService: PlatformService,
    private expenseService: ExpensesService,
    public toastr: ToastrService,
    private translate: TranslateService,
    private commonService: CommonService,
    private datechangeService: DatechangeService,
    private router: NavController,
    private route: ActivatedRoute,
    public navParams: NavParams,
    private sharedService: SharedService,
    private zone: NgZone,
    public alertController: AlertController,
    private reportsService: ReportsService,
    public modalController: ModalController,
    private toaster: ToastrService,
    private title: Title,
    public formBuilder: FormBuilder,
  ) {
    this.sharedService.getRefreshData().subscribe((value) => {
      if (value) {
        if (this.segmentModel === 'draft') {
          this.draft = true;
          this.segmentModel = 'draft';
          this.pageNumber = 1;
          this.getExpenseList(true);
        }
        else if (this.segmentModel === 'submitted') {
          this.draft = false;
          this.segmentModel = 'submitted';
          this.pageNumber = 1;
          this.getExpenseList(true);
        }
      }
    });
  }

  ngOnInit() {
    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.personalInfo = JSON.parse(sessionStorage.getItem("personalInfo"));
    this.getClaimTypes();
    if (this.personalInfo) {
      this.profileImgPath = this.personalInfo.profilePicPath;
    } else {
      if (this.userInfo.profilePicPath) {
        this.profileImgPath = this.userInfo.profilePicPath;
      } else if (!this.userInfo.profilePicPath) {
        if (this.userInfo.gender) {
          if (this.userInfo.gender === "Male") {
            this.profileImgPath = "./assets/images/userdefaultmale.svg";
          } else if (this.userInfo.gender === "Female") {
            this.profileImgPath = "./assets/images/userdefaultfemale.svg";
          }
        } else if (!this.userInfo.gender) {
          this.profileImgPath = "./assets/images/userdefaultmale.svg";
        }
      }
    }

    this.settleadvanceForm = this.formBuilder.group({
      frmDate: ['', [Validators.required]],
      comments: ['', [Validators.required]],
      modeOfSettlement:['',[Validators.required]]
  });
    this.expcurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
    this.advanceAmtApi();
    
    if(JSON.parse(sessionStorage.getItem('currentIndex')) != null){
      setTimeout(() => {
        let reust = {
          index:JSON.parse(sessionStorage.getItem('currentIndex')),
        }
        sessionStorage.removeItem('currentIndex');
        this.onTabChanged(reust);
      }, 100)
    }

    // this.getExpenseList(true);
    this.SearchListText.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((term: string) => {
      this.ExpenseorReport = term;
      this.showLoading = true;
      if (this.matTabIndex == 0 ||this.matTabIndex == 1) {
        this.getExpenseList(false);
      } else if (this.matTabIndex == 2) {
        this.getreportList();
      } else {
        this.getTripsList(false);
      }
    });

    if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3 || this.userInfo.roleId == 4) && JSON.parse(sessionStorage.getItem("isadmin"))) {
      this.hideAdvBtn = false;
    }

    let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
    console.log('sdsdsdsd prakash',val);
    if (val) {

      if(val?.showDetailreport == true){
        // this.expenseList = val?.expenseList;
        // this.tableHide = false;
        // this.selectedAdvance = val?.selectedAdvance;
        // this.showExpenseDetail2(val?.selectedAdvance);
      }
      // sessionStorage.setItem("toPathInfo", null);
      if (val["url"] && val["url"] == "analytics") {
        this.fromAnalytics = true;
      }
    }
    this.sharedSubsc = this.sharedService.getUserToggle().subscribe((val) => {
      this.isUserToggle = val;
    });

    if (this.userInfo.attachmentFormat != null || this.userInfo.attachmentFormat != '') {
      this.commaSeperatorArray = this.userInfo.attachmentFormat.split(',');
    }
    this.ontabchangelist = (sessionStorage.getItem("Triplist"));
    if(this.ontabchangelist == 'true'){
      this.matTabIndex =1
       sessionStorage.removeItem("Triplist")
    }
    else{
      this.matTabIndex =0
       sessionStorage.removeItem("Triplist")
    }
    // this.advancesummary();

    this.sharedService.getReportSummaryData().subscribe((value) => {
      if (value) {
        this.reportSummaryData = value;
      }

    });

  }

  returnDasbord() {
    // this.router.navigateRoot(["dashboard"]);
    let typeVal = JSON.parse(sessionStorage.getItem("fc365"));
    if (typeVal) {
      this.router.navigateRoot(["moreoptions"]);
    } else {
      this.router.navigateRoot(["dashboard"]);
    }
  }

  ionViewWillEnter() {
    this.pageNumber = 1;
    this.platformType = this.platformService.getPlatformType();
    this.validationMsg = AppConfig;

    this.serviceList = JSON.parse(sessionStorage.getItem('tripservelist')) || [];

    // this.tableHide = true;
    const current_modal = JSON.parse(sessionStorage.getItem('current_modal'));
    console.log('currenct modal :',current_modal);
    if (current_modal?.name == 'list') {
      this.tableHide = true;
    } else if (current_modal?.name == 'advance-detailview') {
      this.tableHide = false;
      this.showSummaryLoading = true;
      this.advanceList = current_modal.data.advanceList;
      this.expenseList = current_modal.data.advanceList;
      this.currentExpenseIndex = current_modal.data.currentExpenseIndex;
      this.showExpenseDetail2(current_modal.data.list)
    }else if(current_modal?.name == 'claim_detailview'){
      this.tableHide = false;
      this.showExpenseDetail2(current_modal?.data?.detailReport);
    }
    let statusid = this.navParams.get("status");
    let applicationTitle = this.userInfo.applicationTitle;
    if (applicationTitle) {
      this.title.setTitle('Advances ' + ' | ' + applicationTitle);
    } else {
      this.title.setTitle("Advances | NoExpenses");
    }
    const date = new Date();
    this.maxDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
    this.currentday = moment(new Date()).format(Constants.mobileCalenderFormat);
    this.getExpenseList(true);

    //status list recall functionality
    if (JSON.parse(localStorage.getItem('common-status'))) {
      this.statusList = JSON.parse(localStorage.getItem('common-status'));
    } else {
      this.getStatusList();
    }
    if (this.platformType === 2) {
      this.showSearch = true;
    }

    let val = JSON.parse(sessionStorage.getItem("fromPathInfo"));
    if (val) {
      if (val["expenseId"] && val["expenseGUID"] && val["status"]) {
        this.showExpenseDetail(val);
      }
    }

  }

  advanceListLength: number;
  getExpenseList(isPageLoad, event?) {
    this.isPageLoad = isPageLoad;
    if (this.pageNumber == 1) {
      this.showLoading = true;
      this.expenseList = [];
    }
    //  this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
      this.expenseAndReportModel.viewType = this.userInfo.roleId;
    } else {
      this.expenseAndReportModel.viewType = 1;
    }

    if (this.userInfo.roleId == 2 && this.expenseAndReportModel.viewType == 2) {
      this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    } else {
      this.expenseAndReportModel.isAdminView = false;
    }

   if(this.matTabIndex ==0){
    this.expenseAndReportModel.isTripAdvance =false;
   } else if(this.matTabIndex == 1){
    this.expenseAndReportModel.isTripAdvance =true;
   }
    // this.expenseAndReportModel.recordType = 2
    this.expenseAndReportModel.recordType = 2;
    this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
    this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.expenseAndReportModel.userId = this.userInfo.userId;
    this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
    this.expenseAndReportModel.pageNumber = this.pageNumber;
    this.expenseAndReportModel.filterBy = this.filterModal;
    this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
    this.expenseAndReportModel.filterBy.isDraft =
      this.platformType === 2 ? this.draft : null;
    this.expenseAndReportModel.pageSize = this.pageSize;
    this.isApicallinProgress = true;
    this.reportsService
      .reportList(this.expenseAndReportModel)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0) {
          if(res?.payload?.pendingCount != null){
            this.sharedService.setPeningApprovalCount(res.payload.pendingCount);
          }
          if (this.pageNumber == 1) {
            this.expenseList = [];
          }
          if (this.expenseList?.length) {
            // sessionStorage.setItem('approvedExpenseList', JSON.stringify(this.approvedExpenseList));
          } else {
          }
          this.showLoading = false;
          this.expenseloading = true;
          this.totalRecords = res.payload.reportsCount;
          this.isExpenseCreated = res.payload.isExpenseCreated;
          this.minAndMax = res.payload;
          this.selectedExpense = [];
          this.selectedStatus = [];
          this.selectedcategory = [];
          this.indeterminateState = false;
          this.changeOptions(
            res.payload.expenseMinAmount,
            res.payload.expenseMaxAmount
          );
          const listarray = res.payload.reportResponseList;

          this.expenseList = this.expenseList.concat(
            listarray.map((list) => new ExpenseListModel(list))
          );
          this.dummyListLength = this.expenseList?.length;
          this.setDummyList();

          if (this.advanceList?.length == 0) {
            this.advanceList = [...this.expenseList];
          }
          // this.advanceList = this.expenseList.concat(
          //   listarray.map((list) => new ExpenseListModel(list))
          // );
          this.advanceListLength = this.expenseList.length;
          if (
            this.pageNumber === 1 &&
            this.isPageLoad === true &&
            this.isExpenseCreated === true
          ) {
            this.isPageLoad = false;
          }
          this.returnExpSegment();

          if (event) {
            event.target.complete();
          }
          if (res.payload?.expenseResponseList?.length < this.pageSize && event) {
            event.target.disabled = true;
          }
          this.isApicallinProgress = false;
        } else {
          this.showLoading = false;
          this.isApicallinProgress = false;
          this.toastr.error(
            res.errorMessage,
            this.translate.instant("alerts.error")
          );
          if (event) {
            event.target.disabled = true;
          }
        }
      });
  }
  // advancesummary() {
  //   this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
  //   this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
  //   this.expenseAndReportModel.userId = this.userInfo.userId;
  //   this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
  //   this.reportsService
  //     .advancesummary(this.expenseAndReportModel)
  //     .subscribe((res) => {
  //       if (!res.isError && res.errorCode === 0) {
  //         this.advancesummarylist = res.payload;

  //       } else {
  //         // this.selectedStatus = [];
  //         // this.selectedReport = [];
  //         // this.isApicallinProgress = false;
  //         // this.showLoading = false;
  //         // this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
  //         // if (event) {
  //         //     event.target.disabled = true;
  //         // }
  //       }
  //     });

  // }
  getreportList() {
    if (this.pageNumber == 1) {
      this.showLoading = true;
      this.expenseList = [];
    }
    this.expenseAndReportModel.recordType = 1;
    this.expenseAndReportModel.isAdminView = JSON.parse(
      sessionStorage.getItem("isadmin")
    );
    if (JSON.parse(sessionStorage.getItem("isadmin")) == true) {
      this.expenseAndReportModel.viewType = this.userInfo.roleId;
    } else {
      this.expenseAndReportModel.viewType = 1;
    }
    this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
    this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.expenseAndReportModel.userId = this.userInfo.userId;
    this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
    this.expenseAndReportModel.filterBy = this.filterModal;
    this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
    this.expenseAndReportModel.filterBy.isDraft =
      this.platformType == 2 ? this.draft : null;
    this.expenseAndReportModel.pageNumber = this.pageNumber;
    this.expenseAndReportModel.pageSize = this.pageSize;
    this.isApicallinProgress = true;
    this.expenseAndReportModel.linkedWithAdvance = true;
    this.reportsService
      .reportList(this.expenseAndReportModel)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0) {
          if(res?.payload?.pendingCount != null){
            this.sharedService.setPeningApprovalCount(res.payload.pendingCount);
          }
          this.showLoading = false;
          this.totalRecords = res.payload.reportsCount;
          this.isExpenseCreated = res.payload.isReportCreated;
          this.reportResponseList = res.payload.reportResponseList;
          this.minAndMax = res.payload;
          this.selectedExpense = [];
          this.selectedStatus = [];
          this.selectedcategory = [];
          this.indeterminateState = false;
          this.changeOptions(
            res.payload.reportMinAmount,
            res.payload.reportMaxAmount
          );
          res.payload.reportResponseList.forEach(element => {
            element.expenseId = element?.expenseIds;
          });
          const listarray = res.payload.reportResponseList;
          this.expenseList = this.expenseList.concat(
            listarray.map((list) => new ExpenseListModel(list))
          );
          this.dummyListLength = this.expenseList?.length;
          this.setDummyList();

          if (
            this.pageNumber === 1 &&
            this.isPageLoad === true &&
            this.isExpenseCreated === true
          ) {
            this.isPageLoad = false;
          }
          this.returnExpSegment();
        } else {
          // this.selectedStatus = [];
          // this.selectedReport = [];
          // this.isApicallinProgress = false;
          // this.showLoading = false;
          // this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
          // if (event) {
          //     event.target.disabled = true;
          // }
        }
      });
  }

  opendetails(expense) {
    this.showMobileDetails = false;
    (this.expenseId = expense.expenseId),
      (this.expenseGUID = expense.expenseGUID),
      (this.claimID = expense.claimId),
      (this.fromcomp = "advance");
  }

  expFilter() {
    this.showExpFilter = true;
    this.showExpList = false;
    this.showExpDetail = false;
    this.showExpSearch = false;
    this.addExpenseHide = false;
    this.selectedExpense = [];
    this.showLoading = false;
  }

  changeOptions(minValue, maxValue) {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = minValue;
    newOptions.ceil = maxValue;
    this.minPrice = minValue;
    this.maxPrice = maxValue;
    this.options = newOptions;
  }

  expenseSegment(e) {
    this.segmentModel = e.target.value;
    if (e.target.value === "draft") {
      this.draft = true;
      this.pageNumber = 1;
      this.getExpenseList(false);
      this.selectedcategory = [];
      this.selectedStatus = [];
      //this.resetAll();
    } else {
      this.draft = false;
      this.pageNumber = 1;
      this.selectedExpense = [];
      this.getExpenseList(false);
      this.selectedcategory = [];
      this.selectedStatus = [];
      //this.resetAll();
    }
  }
  resetAll() {
    this.ExpenseorReport = "";
    this.frmDate = undefined;
    this.tDate = null;
    this.filterModal.fromDate = null;
    this.filterModal.toDate = null;
    this.filterModal.minPrice = null;
    this.filterModal.maxPrice = null;
    this.status = "";
    this.minDatee = "";
    this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
    // this.resetAmount();
  }

  reportexpsearch(e) {
    this.filterModal.searchText = e.detail.value;
  }

  toggleColumns(e, val: string) {
    if (e.target.checked === true) {
      this.hideColumns.push(val);
    } else {
      const index = this.hideColumns.indexOf(val);
      if (index > -1) {
        this.hideColumns.splice(index, 1);
      }
    }
  }

  showhideColumns(val: string) {
    let column = true;
    if (this.hideColumns.indexOf(val) > -1) {
      column = false;
    }
    return column;
  }

  showFiltersection() {
    this.showFilter = !this.showFilter;
    this.dropdownFlag = false;
  }
  showDropDown = false;
  returnExpSegment() {
    this.showExpFilter = false;
    this.showExpList = true;
  }

  searchbar() {
    this.showSearch = !this.showSearch;
  }

  onsearchExpense(val) {
    this.ExpenseorReport = val.target.value;
    this.checkAll = false;
    if (this.ExpenseorReport?.length >= 0) {
      this.resetPaging();
      this.SearchListText.next(this.ExpenseorReport);
    }
    if (this.searchExpense == "") {
      this.resetPaging();
      if (this.matTabIndex == 0 ||this.matTabIndex == 1) {
        this.getExpenseList(false);
      } else if (this.matTabIndex == 2) {
        this.getreportList();
      }
      // else {
      //   this.getTripsList(false);
      // }
    }
  }

  loadMore(event) {
    if (!this.isApicallinProgress) {
      if (this.expenseList.length < this.pageSize && event) {
        event.target.disabled = true;
      } else {
        this.pageNumber++;
        this.selectAllexpenses(event);
        if (this.matTabIndex == 0 ||this.matTabIndex == 1 ) {
          this.getExpenseList(false, event);
        }
      }
    }
  }

  selectSingleexpenses(list, e?) {
    if (e) {
      list.isSelected = e?.target?.checked;
    }
    if (list.isSelected) {
      this.selectedExpense.push(list.expenseId);
      if (list.claimId === null) {
        this.unReportExpenses.push(list.expenseId);
      }
      if (list.status === 1) {
        this.statusArray.push(list.claimId);
      }
    } else {
      let index = _.indexOf(this.selectedExpense, list.expenseId);
      this.selectedExpense.splice(index, 1);
      if (list.claimId == null) {
        index = _.indexOf(this.unReportExpenses, list.expenseId);
        this.unReportExpenses.splice(index, 1);
      }
      if (list.status === 1) {
        index = _.indexOf(this.statusArray, list.claimId);
        this.statusArray.splice(index, 1);
      }
    }
    if (
      this.expenseList.length !== this.selectedExpense.length &&
      this.selectedExpense.length > 0
    ) {
      this.checkAll = false;
      this.indeterminateState = true;
    } else if (this.selectedExpense.length === 0) {
      this.checkAll = false;
      this.indeterminateState = false;
    } else {
      this.checkAll = true;
      this.indeterminateState = false;
    }
    this.removeReportModal.claimIds = this.statusArray;
    this.deleteExpenses.expenseIds = this.selectedExpense;
    this.addReportExpense.expenseIds = this.unReportExpenses;
  }

  selectAllexpenses(event?) {
    setTimeout(() => {
      this.expenseList.forEach((item) => {
        item.isSelected = event ? false : this.checkAll;
      });
    });
  }
  resetPaging() {
    this.pageNumber = 1;
    this.expenseList = [];
    this.expenseList.length = 0;
    this.reportList = [];
    this.reportList.length = 0;
  }

  getStatusList() {
    const type = { type: +1 };
    this.commonService.statusList(type).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        const listarray = res.payload;
        this.statusList = listarray.map((list) => new StatusListModel(list));
        localStorage.setItem('common-status', JSON.stringify(this.statusList));
      } else if (res.isError === true) {
        this.toastr.error(
          res.errorMessage,
          this.translate.instant("alerts.error")
        );
      }
    });
  }

  selectSingleStatus(list, e?) {
    if (e) {
      list.isSelected = e?.target?.checked;
    }
    if (list.isSelected) {
      this.selectedStatus.push(list.statusId);
      //this.filterModal.statusIds = this.selectedStatus;
    } else {
      const index = _.indexOf(this.selectedStatus, list.statusId);
      this.selectedStatus.splice(index, 1);
      //this.filterModal.statusIds = this.selectedStatus;
    }
    this.filterModal.statusIds = this.selectedStatus;
    if (
      this.statusList.length !== this.selectedStatus.length &&
      this.selectedStatus.length > 0
    ) {
      this.checkAllStatus = false;
      this.statusState = true;
    } else if (this.selectedStatus.length === 0) {
      this.checkAllStatus = false;
      this.statusState = false;
    } else {
      this.checkAllStatus = true;
      this.statusState = false;
    }
  }

  selectAllStatus() {
    setTimeout(() => {
      this.statusList.forEach((item) => {
        item.isSelected = this.checkAllStatus;
      });
    });
  }

  getfromDate(e) {
    this.frmDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.filterModal.fromDate = date;
    this.minDate = new Date(this.frmDate);
  }

  gettodate(e) {
    this.tDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.filterModal.toDate = date;
    this.maxDate = new Date(this.tDate);
  }

  resetFilters(list) {
    list?.forEach((item) => {
      item.isSelected = false;
    });
  }

  editechangecurrencypopup(incoming,editechangecurrencypop:TemplateRef<any>){
    this.globalIndex =incoming;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef1 = this.modalService.show(editechangecurrencypop, config);
  }

  updatecurrencyexchange(){
    console.log('edit expenses date :',this.editedExchangeRate)
    this.reportSummaryData.expenseList[this.globalIndex].editedExchangeRate = this.editedExchangeRate;
    this.toaster.success('Exchange Updated Succssfully','Success');
    this.modalRef1.hide();
  }

  numberValidation(event: any, isTime?) {
    const pattern = /^[0-9!.@#$%^&*()-]+$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  resetAmount() {
    this.filterModal.minPrice = this.minAndMax.expenseMinAmount;
    this.filterModal.maxPrice = this.minAndMax.expenseMaxAmount;
  }
  priceSlider(changeContext: ChangeContext) {
    this.filterModal.minPrice = changeContext.value;
    this.filterModal.maxPrice = changeContext.highValue;
  }

  resetDates() {
    this.frmDate = undefined;
    this.tDate = undefined;
    this.filterModal.fromDate = undefined;
    this.filterModal.toDate = undefined;
    this.minDate = undefined;
    this.maxDate = new Date();
  }

  applyfilter() {
    this.showFilter = true;
    this.resetPaging();
    if (this.frmDate !== undefined && this.tDate === undefined) {
      this.tDate = this.mobMaxDate;
      this.filterModal.toDate = moment(new Date()).format(
        Constants.mobileDateFormat
      );
    }
    if (this.frmDate === undefined && this.tDate !== "") {
      this.frmDate = this.tDate;
      this.filterModal.fromDate = this.tDate;
    }
    // this.createFilters();
    this.filterModal.fromDate = this.frmDate
      ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat)
      : undefined;
    this.filterModal.toDate = this.tDate
      ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat)
      : undefined;

    this.getExpenseList(false);
    this.returnExpSegment();
    this.modalRef.hide();
    // this.maxDate = new Date();

    //  this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
  }
  // applyfilteradvance(closeModal?, removeAmount?) {
  //   this.filterMinPrice = removeAmount? undefined : this.filterModal.minPrice;
  //   this.filterMaxPrice = removeAmount? undefined : this.filterModal.maxPrice;
  //   this.showFilter = false;
  //   this.resetPaging();
  //   // if (this.frmDate !== undefined && this.tDate === undefined) {
  //   //   this.tDate = this.mobMaxDate;
  //   //   this.filterModal.toDate = moment(new Date()).format(
  //   //     Constants.mobileDateFormat
  //   //   );
  //   // }
  //   // if (this.frmDate === undefined && this.tDate !== "") {
  //   //   this.frmDate = this.tDate;
  //   //   this.filterModal.fromDate = this.tDate;
  //   // }
  //   // // this.createFilters();
  //   // this.filterModal.fromDate = this.frmDate
  //   //   ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat)
  //   //   : undefined;
  //   // this.filterModal.toDate = this.tDate
  //   //   ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat)
  //   //   : undefined;
  //   if(this.frmDate && this.tDate) {
  //     this.filterFromDate = this.frmDate;
  //     this.filterToDate = this.tDate;
  // } else if(this.frmDate && !this.tDate) {
  //     this.filterFromDate = this.frmDate;
  //     this.filterToDate = moment().format();
  // } else if(!this.frmDate && this.tDate) {
  //     this.filterFromDate = this.tDate;
  //     this.filterToDate = this.tDate;
  // }


  //     this.statusList.forEach(s => {
  //       if(s.isSelected) {
  //           s.showInFilter = true;
  //       } else {
  //           s.showInFilter = false;
  //       }
  //   })

  //   this.showFilter = false;
  //   // this.showRepList = true;
  //   // this.showRepFilter = false;
  //   this.resetPaging();
  //   if (this.frmDate !== undefined && this.tDate == undefined) {
  //       this.tDate = this.maxDate;
  //       this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
  //   }
  //   if (this.frmDate == undefined && this.tDate !== undefined) {
  //       this.frmDate = this.tDate;
  //       this.filterModal.fromDate = this.tDate;
  //   }
  //   this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
  //   this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
  //   //this.getreportList(false);
  //   this.maxDate = new Date();
  //   if(closeModal) {
  //   this.modalRef?.hide();
  //   }

  //   // if (this.filterModal.fromDate && this.filterModal.toDate) {

  //   //   if (this.filterdata.length != 0) {
  //   //     const stateIndex = this.filterdata.findIndex(s => s.type == 'date');
  //   //     if (stateIndex != -1) {
  //   //       this.filterdata.splice(stateIndex);
  //   //     }
  //   //   }
  //   //   let dateModel = new filterM();
  //   //   dateModel.type = 'date';
  //   //   dateModel.index = 0;
  //   //   dateModel.value = moment(new Date(this.frmDate)).format('MMM Do YYYY') + ' to ' + moment(new Date(this.tDate)).format('MMM Do YYYY');
  //   //   this.filterdata.push(dateModel);
  //   // } else if (this.filterModal.fromDate && !this.filterModal.toDate) {

  //   //   if (this.filterdata.length != 0) {
  //   //     const stateIndex = this.filterdata.findIndex(s => s.type == 'date');
  //   //     if (stateIndex != -1) {
  //   //       this.filterdata.splice(stateIndex);
  //   //     }
  //   //   }

  //   //   let dateModel = new filterM();
  //   //   dateModel.type = 'date';
  //   //   dateModel.index = 0;
  //   //   dateModel.value = moment(new Date(this.frmDate)).format('MMM Do YYYY');
  //   //   this.filterdata.push(dateModel);
  //   // } else if (!this.filterModal.fromDate && this.filterModal.toDate) {

  //   //   if (this.filterdata.length != 0) {
  //   //     const stateIndex = this.filterdata.findIndex(s => s.type == 'date');
  //   //     if (stateIndex != -1) {
  //   //       this.filterdata.splice(stateIndex);
  //   //     }
  //   //   }

  //   //   let dateModel = new filterM();
  //   //   dateModel.type = 'date';
  //   //   dateModel.index = 0;
  //   //   dateModel.value = moment(new Date(this.tDate)).format('MMM Do YYYY');
  //   //   this.filterdata.push(dateModel);
  //   // }

  //   // if (this.filterModal.minPrice > 1 || this.filterModal.maxPrice < this.minAndMax.reportMinAmount) {

  //   //   if (this.filterdata.length != 0) {
  //   //     const stateIndex = this.filterdata.findIndex(s => s.type == 'amount');
  //   //     if (stateIndex != -1) {
  //   //       this.filterdata.splice(stateIndex);
  //   //     }
  //   //   }

  //   //   let dateModel = new filterM();
  //   //   dateModel.type = 'amount';
  //   //   dateModel.index = 0;
  //   //   dateModel.value = this.filterModal.minPrice + ' to ' + this.filterModal.maxPrice
  //   //   this.filterdata.push(dateModel);
  //   // }


  //   this.getExpenseList(false);
  //   this.returnExpSegment();
  //   this.modalRef.hide();

  // }
  applyfilteradvance(closeModal?, removeAmount?) {
    this.filterMinPrice = removeAmount ? undefined : this.filterModal.minPrice;
    this.filterMaxPrice = removeAmount ? undefined : this.filterModal.maxPrice;
    if (this.frmDate && this.tDate) {
      this.filterFromDate = this.frmDate;
      this.filterToDate = this.tDate;
    } else if (this.frmDate && !this.tDate) {
      this.filterFromDate = this.frmDate;
      this.filterToDate = moment().format();
    } else if (!this.frmDate && this.tDate) {
      this.filterFromDate = this.tDate;
      this.filterToDate = this.tDate;
    }

    this.statusList.forEach(s => {
      if (s.isSelected) {
        s.showInFilter = true;
      } else {
        s.showInFilter = false;
      }
    })

    this.showFilter = false;
    this.resetPaging();
    if (this.frmDate !== undefined && this.tDate == undefined) {
      this.tDate = this.maxDate;
      this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
    }
    if (this.frmDate == undefined && this.tDate !== undefined) {
      this.frmDate = this.tDate;
      this.filterModal.fromDate = this.tDate;
    }
    this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
    // this.getreportList(false);
    this.maxDate = new Date();
    if (closeModal) {
      this.modalRef?.hide();
    }
    this.showCreallAllButton()
    this.getExpenseList(false);
    this.returnExpSegment();
    this.modalRef.hide();
  }
  resetClosefilter() {
    this.modalRef.hide();
  }

  openNewAdvance() {
    if(this.matTabIndex == 0){
      sessionStorage.setItem('hideTripDetails', 'false');
    }else if(this.matTabIndex == 1){
      sessionStorage.setItem('hideTripDetails', 'true');
    }else if(this.matTabIndex == 3){
      sessionStorage.setItem('hideTripDetails', 'true');
    }else{
      sessionStorage.setItem('hideTripDetails', 'false');
    }
    sessionStorage.setItem('currentIndex',this.matTabIndex.toString());
    this.router.navigateRoot(["newAdvance"]);

   
  }

  showExpenseDetail2(list) {
    this.currentExpenseIndex = this.expenseList?.findIndex(e => e.claimCode == list.claimCode);
    this.selectedAdvance = list;
    this.tableHide = false;
    const current_modalObj = {
      name: 'advance-detailview',
      data: {
        currentExpenseIndex: this.currentExpenseIndex,
        list: list,
        expense: this.expense,
        advanceList: this.advanceList
      }
    }
    sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));
    this.showExpenseDetail(list);

  }

  nextDetail() {
    if (this.currentExpenseIndex < this.expenseList.length - 1) {
      ++this.currentExpenseIndex;
      this.showExpenseDetail(this.expenseList[this.currentExpenseIndex]);
    }
  }

  preDetail() {

    if (this.currentExpenseIndex > 0) {
      --this.currentExpenseIndex;
      this.showExpenseDetail(this.expenseList[this.currentExpenseIndex]);
    }
  }

  showExpenseDetail(list) {
    this.currentExpenseIndex = this.expenseList?.findIndex(e => e.claimCode == list.claimCode);
    this.tableHide = false;
    this.showSummaryLoading = true;
    const current_modal = JSON.parse(sessionStorage.getItem('current_modal'));

    const current_modalObj = {
      name: 'advance-detailview',
      data: {
        currentExpenseIndex: this.currentExpenseIndex,
        advanceList: current_modal.data.advanceList,
        expense: this.expense,
        list: list
      }
    }
    sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));
    this.getExpenseSumary(list);
    this.getExpenseList(true);
    if (list.status === 1 || list.status === 11) {
      this.draftBtns = true;
    } else {
      this.draftBtns = false;
    }

    const expDeleteArry = [];
    this.expenseModel.expenseId = list.claimId;
    expDeleteArry.push(list.claimId);
    this.deleteExpenses.expenseIds = expDeleteArry;
    this.removeReportModal.claimIds = expDeleteArry;
    if (this.platformType == 1) {
      this.nextvalue = this.expenseList.indexOf(list);
    }
  }

  handleBackEvent(){
    this.tableHide = true;
  }

  backToAdvances() {
    this.tableHide = true;
    this.showMobileDetails = true;
    this.selectedExpense = [];
    this.statusArray = [];
    const current_modalObj = {
      name: 'list',
      data: {}
    }
    sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));
  }
  getExpenseModels = new GetExpenseModels();
  settlementattachmentproofs: any = [];            
  getExpenseSumary(list) {
    console.log(list);
    
    this.getExpenseModels.claimId = list.claimId;
    this.getExpenseModels.claimGUID = list.claimGUID;
    this.getExpenseModels.recordType = 2;
    this.getExpenseModels.expenseId = list.expenseId;
    this.getExpenseModels.expenseGUID = list.expenseGUID;
    this.getExpenseModels.userId = this.userInfo.userId;
    this.getExpenseModels.userGUID = this.userInfo.userGuId;
    this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
    this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.reportsService.getReportSummary(this.getExpenseModels)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0) {
          res?.payload?.expenseAttachments?.forEach(element => {
            element.isSelected = false;
          });
          
          const listarray = res.payload;
          this.summaryData = res.payload;
          // this.reportSummaryData = this.summaryData.reportSummary;
          console.log(this.reportSummaryData);
          
          
          this.expense = new ExpenseListModel(listarray);

          
          
          // const current_modal = JSON.parse(sessionStorage.getItem('current_modal'));

          //       const current_modalObj = {
          //           name: 'advance-detailview',
          //           data: {
          //               currentExpenseIndex: this.currentExpenseIndex,
          //               advanceList: current_modal.data.advanceList,
          //               expense: this.expense,
          //               list: current_modal.data.list
          //           }
          //           // data: {
          //           //   currentExpenseIndex: this.currentExpenseIndex,
          //           //   list: list,
          //           //   expense: this.expense,
          //           //   advanceList: this.advanceList
          //           // }
          //       }
          //       sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));


          this.showaddNewMileage = true;
          this.showSummaryLoading = false;
          this.expenseID = res.payload.expenseId;
          //this.getExpenseAttachmentsCall();
          this.invoiceDetails = [];
          this.invoiceDetails = this.expense.expenseAttachments;
          if (this.invoiceDetails) {
            let urlStr = this.invoiceDetails[0]?.url;
            if (urlStr?.split("?")[0].split(".").pop() == "pdf") {
              this.isPdf = true;
              this.isZip = false;
              this.getPdfAsBase64(urlStr).then((baseRes) => {
                const linkSource = `data:application/pdf;base64,${baseRes}`;
                this.pdfData = linkSource;
              })
            } else if(urlStr?.split("?")[0].split(".").pop() == "zip" || urlStr?.split("?")[0].split(".").pop() == "rar") {
              this.isZip = true;
              this.isPdf = false;
            } else {
              this.isPdf = false;
              this.isZip = false;
            }
          }

          this.tridetails=this.expense?.claimTaggedTripList||[];
          this.tridetails.forEach(element => {
            if(element?.tripServiceAttachments){
              element?.tripServiceAttachments.forEach(childelement => {
                if(childelement?.fileURL != null){
                  this.tripAttachemnet =true
                }else{
                  this.tripAttachemnet =false
                }
              });
            }
          });
          let files = res.payload?.settlementList? res.payload?.settlementList[0]?.attahcments[0]?.fileURL?.split(',') : []
          this.settlementattachmentproofs = [];         
          files.forEach((f) => {              
            this.settlementattachmentproofs.push({url: f})            
          })
          if(res.payload.settlementList) {
            res.payload.settlementList[0].attahcments = [];
          }
          this.settlementattachmentproofs.forEach((rr) => {
            if (this.returnExtension(rr.url) == 'pdf') {
              this.getPdfAsBase64(rr.url).then((baseRes) => {
                rr.content = `data:application/pdf;base64,${baseRes}`;
                res.payload.settlementList[0].attahcments.push(rr)
              })
            } else {
              res.payload.settlementList[0].attahcments.push(rr)
            }
          })
          this.totalAmt = this.expense.expenseItems
            .map((t) => t.amount)
            .reduce((a, value) => a + value, 0);
          //   if (this.platformType === 1) {
          //    this.editExpense(this.expense);
          //  }
        } else if (res.isError && res.errorCode === 2) {
          this.showSummaryLoading = false;
        }
      });
  }
  async getPdfAsBase64(url: string, thumbnail?): Promise<string> {
    try {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();

      // Convert the response data to base64
      const base64 = btoa(
        new Uint8Array(buffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      return base64;
    } catch (error) {
      throw error;
    }
  }
  ngOnDestroy() {
    this.resetSubscriptions();
  }

  ionViewWillLeave() {
    this.resetSubscriptions();
  }

  resetSubscriptions() {
    let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
    if (val && val["url"] && val["url"] == "analytics") {
      sessionStorage.setItem("toPathInfo", null);
    }
    this.sharedService.unsubscribeServic(this.sharedSubsc);
  }

  EditAdvance(editreport?) {
    console.log('selected valeu :',editreport);
    editreport.claimTaggedTripList = this.reportSummaryData?.claimTaggedTripList || null;
    if (editreport?.status == 1 || editreport?.status == 11) {
      this.isReadOnly = false;
    } else {
      this.isReadOnly = true;
    }
    if(this.reportSummaryData?.claimTaggedTripList?.length > 0){
      sessionStorage.setItem('hideTripDetails', 'true');
    }else{
      sessionStorage.setItem('hideTripDetails', 'false');
    }

    let navInfo = {
      url: "advance",
      advance: editreport,
      isReadOnly: this.isReadOnly
    };



    // this.sharedService.setRefreshData(true);
    console.log('navigate :',navInfo);
    sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
    this.router.navigateRoot(["newAdvance"]);

    //this.router.navigateRoot(["newAdvance"]);
  }
  resetClose() {
    this.modalRef.hide();
  }

  async removeAdvancePopUp(removeadvpop: TemplateRef<any>, list?) {
    this.expenseId = this.expense?.claimId;
    this.removeReportList = list?.claimId;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-md baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(removeadvpop, config);

    // if (list) {
    //   const expDeleteArry = [];
    //   expDeleteArry.push(list.expenseId);
    //   this.deleteExpenses.expenseIds = expDeleteArry;
    // }

    // const alert = await this.alertController.create({
    //   mode: "md",
    //   message: this.translate.instant("alerts.advconfirmdelete"),
    //   backdropDismiss: false,
    //   buttons: [
    //     {
    //       text: this.translate.instant("common.cancel"),
    //       role: "cancel",
    //     },
    //     {
    //       text: this.translate.instant("common.delete"),
    //       handler: () => {
    //         this.zone.run(() => {
    //           this.buttonDisabled = true;
    //           this.deleteExpenses.legalEntityId = this.userInfo.legalEntityId;
    //           this.deleteExpenses.legalEntityGUID =
    //             this.userInfo.legalEntityGuId;
    //           this.deleteExpenses.userId = this.userInfo.userId;
    //           this.deleteExpenses.userGUID = this.userInfo.userGuId;
    //           this.deleteExpenses.recordType = 2;
    //           this.expenseService
    //             .deleteExpense(this.deleteExpenses)
    //             .subscribe((data) => {
    //               if (!data.isError && data.errorCode === 0) {
    //                 this.buttonDisabled = false;
    //                 this.toastr.success(
    //                   data?.payload,
    //                   this.translate.instant("alerts.succes")
    //                 );
    //                 this.backToAdvances();
    //                 this.getExpenseList(true);
    //               } else if (data.isError && data.errorCode === 2) {
    //                 this.buttonDisabled = false;
    //                 this.toastr.error(
    //                   data.errorMessage,
    //                   this.translate.instant("alerts.error")
    //                 );
    //               }
    //             });
    //         });
    //       },
    //     },
    //   ],
    // });
    // await alert.present();
  }



  badvformreset() {
    if (this.removeReportList) {
      const expDeleteArry = [];
      expDeleteArry.push(this.removeReportList);
      this.deleteExpenses.claimIds = expDeleteArry;
      this.buttonDisabled = true;
      this.deleteExpenses.legalEntityId = this.userInfo.legalEntityId;
      this.deleteExpenses.legalEntityGUID =
        this.userInfo.legalEntityGuId;
      this.deleteExpenses.userId = this.userInfo.userId;
      this.deleteExpenses.userGUID = this.userInfo.userGuId;
      this.deleteExpenses.recordType = 2;
      this.reportsService.removeReport(this.deleteExpenses)
        .subscribe((data) => {
          if (!data.isError && data.errorCode === 0) {
            this.buttonDisabled = false;
            this.toastr.success(
              data?.payload,
              this.translate.instant("alerts.succes")
            );
            this.backToAdvances();
            this.modalRef.hide();
            this.getExpenseList(true);

          } else if (data.isError && data.errorCode === 2) {
            this.buttonDisabled = false;
            this.toastr.error(
              data.errorMessage,
              this.translate.instant("alerts.error")
            );
          }
        });
    } else {
      const expDeleteArry = [];
      expDeleteArry.push(this.getExpenseModels.claimId);
      this.deleteExpenses.claimIds = expDeleteArry;
      this.buttonDisabled = true;
      this.deleteExpenses.legalEntityId = this.userInfo.legalEntityId;
      this.deleteExpenses.legalEntityGUID =
        this.userInfo.legalEntityGuId;
      this.deleteExpenses.userId = this.userInfo.userId;
      this.deleteExpenses.userGUID = this.userInfo.userGuId;
      this.deleteExpenses.recordType = 2;
      this.reportsService.removeReport(this.deleteExpenses)
        .subscribe((data) => {
          if (!data.isError && data.errorCode === 0) {
            this.buttonDisabled = false;
            this.toastr.success(
              data?.payload,
              this.translate.instant("alerts.succes")
            );
            this.backToAdvances();
            this.modalRef.hide();
            this.getExpenseList(true);

          } else if (data.isError && data.errorCode === 2) {
            this.buttonDisabled = false;
            this.toastr.error(
              data.errorMessage,
              this.translate.instant("alerts.error")
            );
          }
        });
    }

  }
  Editadavance(list?) {
    this.getExpenseModels.claimId = list.claimId;
    this.getExpenseModels.claimGUID = list.claimGUID;
    this.getExpenseModels.recordType = 2;
    this.getExpenseModels.expenseId = list.expenseId;
    this.getExpenseModels.expenseGUID = list.expenseGUID;
    this.getExpenseModels.userId = this.userInfo.userId;
    this.getExpenseModels.userGUID = this.userInfo.userGuId;
    this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
    this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.reportsService.getReportSummary(this.getExpenseModels).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        let navInfo = {
          url: "advance",
          advance: res?.payload?.reportSummary,
        };
        if (res?.payload?.reportSummary?.claimTaggedTripList?.length> 0) {
          sessionStorage.setItem('hideTripDetails', 'true');
        } else {
          sessionStorage.setItem('hideTripDetails', 'false');
        }
        sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
        this.router.navigateRoot(["newAdvance"]);
      }
    })

    // let navInfo = {
    //   url: "advance",
    //   advance: list,
    // };
    // sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
    // this.router.navigateRoot(["newAdvance"]);
  }
  async ellipsisClick(submitpopup: TemplateRef<any>, list?, item?) {
    switch (item) {
      case 'edit':
        // let navInfo = {
        //   url: "advance",
        //   advance: list,
        // };
        // sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
        // this.router.navigateRoot(["newAdvance"]);
        break;
      case 'submit':
        const config = {
          ignoreBackdropClick: false,
          class: "modal-dialog-centered modal-md baa",
          keyboard: false,
        };
        this.modalRef = this.modalService.show(submitpopup, config);
        this.removeReportModal.claimIds = [];
        this.removeReportModal.claimIds.push(list.claimId);
        // this.reportSubmitPopUp('fromLand');

        break;
      case 'default':
        break;
    }
  }

  submitReport(submitpopup: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-md baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(submitpopup, config);
    //this.reportSubmitPopUp('fromEdit');
  }



  settlementAdvance(settleAdvance: TemplateRef<any>) {
    this.settlebtnSubDisabled = false;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(settleAdvance, config);
    //this.reportSubmitPopUp('fromEdit');
  }
  submitadvance() {
    // if (fromString == 'fromEdit') {
    // } else {
    //   this.showLoading = true;
    // }
    this.removeReportModal.userId = this.userInfo.userId;
    this.removeReportModal.userGUID = this.userInfo.userGuId;
    this.removeReportModal.legalEntityId =
      this.userInfo.legalEntityId;
    this.removeReportModal.legalEntityGUID =
      this.userInfo.legalEntityGuId;

    this.removeReportModal.recordType = 2;
    this.btnSubDisabled = true;
    this.reportsService
      .draftReport(this.removeReportModal)
      .subscribe((res) => {
        this.btnSubDisabled = false;
        if (!res.isError && res.errorCode == 0) {
          this.toastr.success(res.payload, this.translate.instant("alerts.succes"));
          // if (fromString == 'fromEdit') {
          this.modalRef.hide();
          //this.backToAdvances();
          const current_modal = JSON.parse(sessionStorage.getItem('current_modal'));
          this.getExpenseList(true);
          this.getExpenseSumary(this.selectedAdvance);
          this.backToAdvances();
          // this.getExpenseSumary(this.expense.expenseId);
          // } else {
          //   this.getExpenseList(true);
          // }

        } else if (res.isError == true) {
          this.toastr.error(
            res.errorMessage,
            this.translate.instant("alerts.error")
          );
        }
      });
  }
  removeReportModal = new RemoveReportModal();
  async reportSubmitPopUp(fromString?) {
    const alert = await this.alertController.create({
      mode: "md",
      message: this.translate.instant("alerts.confirmSubmit"),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("common.cancel"),
          role: "cancel",
          handler: () => { },
        },
        {
          text: this.translate.instant("common.submit"),
          handler: () => {
            this.zone.run(() => {
              if (fromString == 'fromEdit') {
              } else {
                this.showLoading = true;
              }
              this.removeReportModal.userId = this.userInfo.userId;
              this.removeReportModal.userGUID = this.userInfo.userGuId;
              this.removeReportModal.legalEntityId =
                this.userInfo.legalEntityId;
              this.removeReportModal.legalEntityGUID =
                this.userInfo.legalEntityGuId;

              this.removeReportModal.recordType = 2;
              this.btnSubDisabled = true;
              this.reportsService
                .draftReport(this.removeReportModal)
                .subscribe((res) => {
                  this.btnSubDisabled = false;
                  if (!res.isError && res.errorCode == 0) {
                    this.toastr.success(res.payload, this.translate.instant("alerts.succes"));
                    if (fromString == 'fromEdit') {
                      this.backToAdvances();
                      this.getExpenseList(true);
                    } else {
                      this.getExpenseList(true);
                    }
                  } else if (res.isError == true) {
                    this.toastr.error(
                      res.errorMessage,
                      this.translate.instant("alerts.error")
                    );
                  }
                });
            });
          },
        },
      ],
    });

    await alert.present();
  }

  async addNewAdvance() {
    this.router.navigateRoot(["newAdvance"]);
  }

  clearSearch() {
    this.searchExpense = "";
    this.ExpenseorReport = "";
    if (this.expenseAndReportModel.filterBy.searchText?.length >= 3) {
      this.getExpenseList(false);
    }
  }

  matTabIndex = 0;
  dummyListLength: number = 5;
  setDummyList() {
    const list = [];
    const dummy_length = this.dummyListLength < 1 ? 1 : (this.dummyListLength < 15 ? this.dummyListLength : 15);
    for (let i = 1; i <= dummy_length; i++) {
      list.push(i)
    }
    this.dummyList = list;
  }

  matTabLabel = 'Advance';
  onTabChanged(e) {
    this.matTabLabel = e.tab?.textLabel;
    if (e.tab?.textLabel == 'Surrendered') { e.index = 2; }
    else if (e.tab?.textLabel == 'My Trips') { e.index = 3; }
    this.showclearAll = false;
    this.claimFilterApplied = false;
    //this.setDummyList();
    this.matTabIndex = e.index;
    this.pageNumber = 1;
    this.pageNumberTrips = 1;
    this.resetClaimType();
    if(this.matTabIndex == 0 || this.matTabIndex == 1) {
      this.matTabLabel = 'Advance';
    }
    if (e.index == 0) {
      this.resetFilters(this.statusList);
      this.selectedStatus = [];
      this.filterModal.statusIds = this.selectedStatus;
      this.ExpenseorReport = '';
      this.getExpenseList(false);
      this.checkAllStatus = false;
      this.statusList?.forEach((item) => {
        item.isSelected = false;
      });
      this.showFilter = false;

    }else if(e.index ==1){
      this.resetFilters(this.statusList);
      this.selectedStatus = [];
      this.filterModal.statusIds = this.selectedStatus;
      this.ExpenseorReport = '';
      this.getExpenseList(false);
      this.checkAllStatus = false;
      this.statusList?.forEach((item) => {
        item.isSelected = false;
      });
      this.showFilter = false;
    }
     else if (e.index == 2) {
      this.expenseList = [];
      this.showFilter = false;
      this.filterModal.minPrice = undefined;
      this.filterModal.maxPrice = undefined;
      this.ExpenseorReport = '';
      this.getreportList();
    } else  if (e.index == 3){
      this.tripfilterModal = new TripFilterModal();
      this.showLoading = true;
      this.selectedExpense = [];
      this.ExpenseorReport = '';
      this.getTripsList(false, e);
      this.getfliterList();
      this.selectedTripCategory = [];
      this.isSelected = false;
      this.resetProductFilters(this.filterCategoryList);
      this.resetTripFilters(this.filterListStatus);
      this.tripCategoryState = false;
      this.showFilter = false;
      this.filterModal.minPrice = undefined;
      this.filterModal.maxPrice = undefined;
      //this.resetAlltripFilters();
      // let paidList = this.statusList.filter(function (status) {
      //   return status.statusId === 7;
      // });

      // if (paidList.length > 0) {
      //   this.resetFilters(this.statusList);
      //   this.selectedStatus = [];
      //   paidList.forEach((item) => {
      //     item.isSelected = true;
      //     this.selectSingleStatus(item);
      //   });
      // }
    }
  }
  refresh() {
    if (this.matTabIndex == 0 ||this.matTabIndex == 1) {
      this.getExpenseList(false);
    } else if (this.matTabIndex == 2) {
      this.getreportList();
    } else if (this.matTabIndex == 3) {
      this.getTripsList(false)
    }
  }

  openChat(chatPopup: TemplateRef<any>) {
    // if (this.expense.recordType == 1) {
    //     expense = this.expense.expenseList[0];
    // }
    // this.openedExpChat = expense;
    this.getMessages(true);

    const config = {
      ignoreBackdropClick: true,
      class: "modal-lg modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(chatPopup, config);
  }
  filterpopup(filtertemplate: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: false,
      class: "modal-xl modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(filtertemplate, config);
  }
  showtripFiltersection(tripfiltertemplate: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: false,
      class: "modal-xl modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(tripfiltertemplate, config);
  }

  getMessages(load) {
    if (load) {
      this.chatLoad = true;
    }
    this.getCommentModel.referenceId = this.summaryData.expenseId;
    this.getCommentModel.referenceGUID = this.summaryData.expenseGUID;
    this.getCommentModel.referenceType = this.summaryData?.recordType;
    this.reportsService.getCommentHistory(this.getCommentModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.chatList = res.payload.historyList;
        this.addCommentModel.comments ='';
      } else if (res.isError && res.errorCode === 2) {
        this.toastr.error(res.payload);
      }
      this.chatLoad = false;
    });
  }

  addMessage() {

    this.addCommentModel.userId = this.userInfo.userId;
    this.addCommentModel.userGUID = this.userInfo.userGuId;
    this.addCommentModel.referenceId = this.summaryData.expenseId;
    this.addCommentModel.referenceGUID = this.summaryData.expenseGUID;
    this.addCommentModel.referenceType = this.summaryData?.recordType;;
    this.addCommentModel.legalEntityId = this.userInfo.legalEntityId;
    this.addDisable = true;
    this.reportsService.addComment(this.addCommentModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.toastr.success(res.payload);
        this.addCommentModel.comments = "";
        this.addDisable = false;
        this.getMessages(false);
      } else if (res.isError && res.errorCode === 2) {
        this.addDisable = false;
      } else {
        this.addDisable = false;
        this.chatLoad = false;
      }
    }, (err: any) => {
      this.addDisable = false;
      this.chatLoad = false;
    });
  }
  advanceAmtApi() {
    let walletModel = new UserModel();
    walletModel.userId = this.userInfo.userId;
    walletModel.userGUID = this.userInfo.userGuId;
    this.reportsService.walletRequest(walletModel).subscribe((res) => {
      if (!res.isError && res.errorCode === 0 && res?.payload) {
        this.walletAdvance = res.payload?.formattedAdvanceBalance;
        this.formattedAvailableTripAdvanceBalance = res.payload?.formattedAvailableTripAdvanceBalance;
      }
    });
  }

  async openDateSheet(isFromDate: boolean) {
    const modal = await this.modalController.create({
      component: CustomDatePickerComponent,
      componentProps: {
        selectedDate: isFromDate ? this.frmDate : this.tDate,
        fromDate: isFromDate ? new Date("1981-01-01") : this.frmDate,
        toDate: isFromDate
          ? this.tDate
            ? this.tDate
            : new Date()
          : new Date(),
      },
      cssClass: "custom-bottom-sheet",
    });
    modal.onDidDismiss().then((result) => {
      if (result.role === "changed" && result.data) {
        if (isFromDate) {
          this.frmDate = result.data.displayDate;
        } else {
          this.tDate = result.data.displayDate;
        }
      }
    });
    return await modal.present();
  }

  getFromDateCall(ev) {
    this.frmDate = moment(ev.detail.value).format("MM-DD-YYYY");
    this.minDatee = this.frmDate;
    this.filterModal.fromDate = moment(ev.detail.value).format("DD-MM-YYYY");
  }

  getToDateCall(ev) {
    // this.ToselectedDate = moment(ev.detail.value).format("MM-DD-YYYY");
    // this.createAdvanceModel.tripToDate = moment(ev.detail.value).format("DD-MM-YYYY");

    this.tDate = moment(ev.detail.value).format("MM-DD-YYYY");
    this.filterModal.toDate = moment(ev.detail.value).format("DD-MM-YYYY");
    this.mobMaxDate = moment(new Date(this.tDate)).format(
      Constants.mobileCalenderFormat
    );
  }

  fromDateMobile(e) {
    if (e.detail.value !== "") {
      this.frmDate = e.detail.value;
      this.filterModal.fromDate = moment(new Date(this.frmDate)).format(
        Constants.mobileDateFormat
      );
      this.minDatee = moment(new Date(this.frmDate)).format(
        Constants.mobileCalenderFormat
      );
    } else {
      this.filterModal.fromDate = undefined;
    }
  }

  toDateMobile(e) {
    if (e.detail.value !== "") {
      this.tDate = e.detail.value;
      this.filterModal.toDate = moment(new Date(this.tDate)).format(
        Constants.mobileDateFormat
      );
      this.mobMaxDate = moment(new Date(this.tDate)).format(
        Constants.mobileCalenderFormat
      );
    } else {
      this.filterModal.fromDate = undefined;
    }
  }

  resetSinglefilter(type) {
    if (type) {
      switch (type) {
        case 1:
          this.frmDate = undefined;
          this.filterModal.fromDate = this.frmDate;
          this.minDatee = undefined;
          this.maxDate = new Date();
          break;
        case 2:
          this.tDate = undefined;
          this.filterModal.toDate = this.tDate;
          this.minDatee = undefined;
          this.maxDate = new Date();
          break;
        case 3:
          this.status = "";
          this.selectedStatus = [];
          this.filterModal.statusIds = this.selectedStatus;
          break;
      }
    }
  }

  resetBlur(eleInput: IonInput) {
    eleInput.getInputElement().then((ele) => ele.blur());
  }

  async openModal(value: string, type: string, isCategory?: boolean) {
    const componentProps = this.getComponentProps(type);
    if (value && value !== "") {
      componentProps.value = this.filterModal[componentProps.setValueKey];
    }
    const searchItemModal = await this.modalController.create({
      component: CustomSelectDropdownComponent,
      componentProps,
      keyboardClose: false,
    });
    searchItemModal.onDidDismiss().then((callback) => {
      if (callback && callback.data) {
        // TODO give onchange methods for status and category
        if (typeof callback.data !== "string") {
          this[componentProps.listName] = callback.data;
          const arr = [];
          this.status = null;
          callback.data.forEach((obj: any) => {
            if (obj.isSelected) {
              arr.push(obj[componentProps.itemValueField]);
              if (this.status && this.status !== "") {
                this.status = `${this.status} , ${obj[componentProps.itemTextField]
                  }`;
              } else {
                this.status = obj[componentProps.itemTextField];
              }
            }
          });
          if (arr && arr.length) {
            this.filterModal[componentProps.setValueKey] = arr;
          }
        }
      }
    });
    return await searchItemModal.present();
  }

  getComponentProps(type: string) {
    switch (type) {
      case "status":
        return {
          title: "Status",
          value: null,
          mainList: this.statusList,
          isMultiSelect: true,
          itemValueField: "statusId",
          itemTextField: "statusDescription",
          listName: `statusList`,
          setValueKey: "statusIds",
        };
    }
  }

  goToAnalytics() {
    this.router.navigateRoot(["analytics"]);
  }

  openProofModal(proofPopup: TemplateRef<any>, list) {
    this.proofList = list?.expenseAttachmentsmodel;
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(proofPopup, config);
  }

  downloadImage() {
    if (!this.proofid) {
      this.proofid = this.proofList[0].attachmentId;
    }
    let attachObj = { attachmentIds: [this.proofid] };
    this.expenseService.downloadAttachment(attachObj).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        this.downloadProof = res.payload.downloadFileResponseDTO;
        this.downloadProof.forEach(element => {
          if (element) {
            window.open(element.url);
          }
        });
        // this.downloadProof = res["payload"]["url"];
        // window.open(this.downloadProof);
        this.toastr.success(
          this.translate.instant("alerts.downloadsucces"),
          this.translate.instant("alerts.succes")
        );
      } else {
        this.toastr.error(res.errorMessage, "Error");
      }
    });
  }

  onSlide(item) {
    const imageIndex = parseInt(item.current.replace("slideId_", ""), 10);
    this.proofid =
      this.proofList[
        parseInt(item.current.replace("slideId_", ""), 10)
      ].attachmentId;
    this.imgNum = imageIndex + 1;
  }

  modalClose() {
    this.modalRef.hide();
  }

  doItAsDuplicate(editreport?) {
    let navInfo = {
      url: "advance-duplicate",
      advance: editreport,
    };
    sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
    this.router.navigateRoot(["newAdvance"]);
  }
  downloadCancel() {
    this.modalRef.hide();
  }
  downloadModel = new DownloadModel();

  bulkDownload(downloadOptTemp?: TemplateRef<any>, list?) {
    if (list) {
      this.dropdownValue = list;
      const config = {
        ignoreBackdropClick: true,
        class: "modal-md modal-dialog-centered",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(downloadOptTemp, config);
      this.hidebulkDown();

    } else {
      if (this.selectedExpense.length == 0 || this.selectedExpense.length > 1) {
        const config = {
          ignoreBackdropClick: true,
          class: "modal-md modal-dialog-centered",
          keyboard: false,
        };
        this.modalRef = this.modalService.show(this.downloadLoader, config);
        this.downloadApiCall();
      } else {
        const config = {
          ignoreBackdropClick: true,
          class: "modal-md modal-dialog-centered",
          keyboard: false,
        };
        this.modalRef = this.modalService.show(downloadOptTemp, config);
      }
    }
  }

  closeDownloadOptTemp() {
    this.modalRef.hide();
  }

  downloadOptSelector(value) {
    switch (value) {
      case "excel":
        this.showDownloadOptions = true;
        this.downloadOptionSelection = 1;
        this.downloadApiCall(null);
        break;
      case "pdf":
        this.showDownloadOptions = true;
        this.downloadOptionSelection = 2;
        this.downloadApiCall("PDF");
        break;
    }
  }

  downloadApiCall(list?) {
    this.downloadBtn = true;
    if (this.selectedExpense.length) {
      this.downloadModel.referenceIds = this.selectedExpense.toString();
    } else {
      this.downloadModel.referenceIds = null;
    }
    console.log('dropdow value :',this.dropdownValue);
    if (this.dropdownValue) {
      this.downloadModel.referenceIds = this.dropdownValue?.claimId;
      // this.downloadBtn = false;
    }
    this.filterModal.isDraft = null;
    this.filterModal.recordTypes = list ? [] : (this.matTabIndex == 0 ||this.matTabIndex == 1 ? [2] : [1]);
    this.filterModal.statusIds = list ? [] : (this.matTabIndex == 0 ||this.matTabIndex == 1 ? [] : [7]);
    this.downloadModel.filterBy = this.filterModal;
    this.downloadModel.userId = this.userInfo.userId;
    this.downloadModel.userGUID = this.userInfo.userGuId;
    this.downloadModel.recordType = this.dropdownValue?.recordType ? this.dropdownValue?.recordType : (this.matTabIndex == 0 ||this.matTabIndex == 1 ? 2 : 1);
    this.downloadModel.entityType = 2;
    this.downloadModel.isAdminView = JSON.parse(
      sessionStorage.getItem("isadmin")
    );
    this.downloadModel.legalEntityId = this.userInfo.legalEntityId;
    this.downloadModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.downloadModel.viewType = 2;
    this.downloadModel.isApprover = false;
    this.downloadModel.docType = list;
    this.downloadModel.linkedWithAdvance = list ? null : (this.matTabIndex == 0 ||this.matTabIndex == 1 ? null : true);
    this.downloadModel.downloadActionType = this.matTabLabel;
    if(this.matTabIndex ==0){
      this.downloadModel.isTripAdvance =false;
     } else if(this.matTabIndex == 1){
      this.downloadModel.isTripAdvance =true;
     }
    this.commonService.downloadList(this.downloadModel).subscribe((res) => {
      if (!res.error && res.payload) {
        this.downloadPoolingStart(res.payload, 3000);
      }
    });
  }

  advanceDownload(list?) { }

  downloadPoolingStart(request, time) {
    this.downloadActionPoolCount++;
    this.downloadModel.jobId = request.jobId;
    this.downloadModel.jobGUID = request.jobGUID;
    this.commonService
      .downloadpoolStart(this.downloadModel)
      .subscribe((res) => {
        if (
          !res.isError &&
          (!res.payload?.resultFileURL) &&
          this.downloadActionPoolCount < 20
        ) {
          this.downloadTime = setTimeout(() => {
            this.downloadPoolingStart(res.payload, time);
          }, time);
        } else if (!res.isError && res.payload.status == 3) {
          this.downloadBtn = false;
          this.downloadActionPoolCount = 0;
          window.open(res["payload"]["resultFileURL"], "_self");
          this.toaster.success("Download successfully", "Success");
          this.downloadOptionSelection = 0;
          this.selectedExpense = [];
          this.expenseList.forEach(element => {
            element.isSelected = false;
          });
          this.showDownloadOptions = false;
          this.modalRef.hide();

        } else {
          this.downloadBtn = false;
          this.toaster.error(res.errorMessage, "Error");
          this.expenseList.forEach(element => {
            element.isSelected = false;
          });
          this.downloadActionPoolCount = 0;
          this.downloadOptionSelection = 0;
          this.selectedExpense = [];
          this.showDownloadOptions = false;
          this.modalRef.hide();
        }
      });
  }
  getExpenseAttachmentsCall() {
    this.getExpenseIds.expenseIds = [this.expenseID];
    this.getExpenseIds.legalEntityId = this.userInfo.legalEntityId;
    this.getExpenseIds.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.expenseService
      .expenseAttachViewer(this.getExpenseIds)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0) {
          this.invoiceDetails = [];
          this.invoiceDetails = res.payload.attachementList;
          if (this.invoiceDetails) {
            let urlStr = this.invoiceDetails[0].url;
            if (urlStr.split("?")[0].split(".").pop() == "pdf") {
              this.isPdf = true;
              this.isZip = false;
              const linkSource = `data:application/pdf;base64,${this.invoiceDetails[0].base64}`;
              this.pdfData = linkSource;
            } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
              this.isZip = true;
              this.isPdf = false;
            } else {
              this.isPdf = false;
              this.isZip = false;
            }
          }
        } else if (res.isError && res.errorCode === 2) {
        }
      });
  }
  pdfSelectionCall(pdfSelection: TemplateRef<any>, index?) {
    // this.invoiceDetails = obj.expenseAttachments;
    this.defaultIndex = index || 0;

    let urlStr = this.invoiceDetails[this.defaultIndex].url;
    if (urlStr.split("?")[0].split(".").pop() == "pdf") {
      this.isPdf = true;
      this.isZip = false;
      // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
      // this.pdfData = linkSource;
      this.getPdfAsBase64(urlStr).then((baseRes) => {
        const linkSource = `data:application/pdf;base64,${baseRes}`;
        this.pdfData = linkSource;
      })

    } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
      this.isZip = true;
      this.isPdf = false;
    } else {
      this.isPdf = false;
      this.isZip = false;
    }
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(pdfSelection, config);
  }
  closeSelection() {
    this.defaultIndex = 0;
    this.modalRef.hide();
  }

  prevBtnadvanceimage() {
    if (this.defaultIndex != 0) {
      this.defaultIndex = this.defaultIndex - 1;
      let urlStr = this.invoiceDetails[this.defaultIndex].url;
      if (urlStr.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.isZip = false;
        // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64
        //   }`;

        // this.pdfData = linkSource;
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
        })
      } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
        this.isZip = true;
        this.isPdf = false;
      } else {
        this.isPdf = false;
        this.isZip = false;
      }
    }
  }
  prevBtn() {
    if (this.defaultIndex != 0) {
      this.defaultIndex = this.defaultIndex - 1;
      let urlStr = this.invoiceDetails[this.defaultIndex].url;
      if (urlStr.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.isZip = false;
        const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64
          }`;

        this.pdfData = linkSource;
      } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
        this.isZip = true;
        this.isPdf = false;
      } else {
        this.isPdf = false;
        this.isZip = false;
      }
    }
  }
  nextBtnadvanceimage() {
    if (this.defaultIndex + 1 == this.invoiceDetails.length) {
      // this.defaultIndex = 0;
    } else {
      this.defaultIndex = this.defaultIndex + 1;
      let urlStr = this.invoiceDetails[this.defaultIndex].url;
      if (urlStr.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.isZip = false;
        // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64
        //   }`;
        // this.pdfData = linkSource;
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
        })
      } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
        this.isZip = true;
        this.isPdf = false;
      } else {
        this.isPdf = false;
        this.isZip = false;
      }
    }
  }
  nextBtn() {
    if (this.defaultIndex + 1 == this.invoiceDetails.length) {
      // this.defaultIndex = 0;
    } else {
      this.defaultIndex = this.defaultIndex + 1;
      let urlStr = this.invoiceDetails[this.defaultIndex].url;
      if (urlStr.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.isZip = false;
        const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64
          }`;
        this.pdfData = linkSource;
      } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
        this.isZip = true;
        this.isPdf = false;
      } else {
        this.isPdf = false;
        this.isZip = false;
      }
    }
  }

  getTripsList(isPageLoad, event?) {
    this.isPageLoadTrips = isPageLoad;
    if (this.pageNumberTrips == 1) {
      this.showLoadingTrips = true;
      this.tripsList = [];
    }
    this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
      this.expenseAndReportModel.viewType = this.userInfo.roleId;
    } else {
      this.expenseAndReportModel.viewType = 1;
    }

    let modl = new FetchTripInfo();
    modl.legalEntityId = this.userInfo.legalEntityId;
    modl.legalEntityGUID = this.userInfo.legalEntityGuId;
    modl.companyId = this.userInfo.companyId;
    modl.companyGUID = this.userInfo.companyGuId;
    modl.searchText = this.ExpenseorReport;
    modl.tripStartDate = '';
    modl.tripEndDate = '';
    modl.pageNumber = this.pageNumberTrips;
    modl.pageSize = this.pageSizeTrips;
    this.isApicallinProgressTrips = true;
    modl.filterBy = this.tripfilterModal;
    modl.filterBy.statusIds = modl.filterBy.statusIds[0] ? modl.filterBy.statusIds : [];
    this.reportsService.fetchTripDetails(modl).subscribe((res) => {
      this.showLoading = false;
      if (!res.isError && res.errorCode == 0 && res?.payload) {
        this.showLoadingTrips = false;

        if (this.platformType == 1) {
          if (JSON.parse(localStorage.getItem('trip-filter-data'))) {
            // this.tripfilterPatching(JSON.parse(localStorage.getItem('trip-filter-data')));
          } else {
            this.getfliterList();
          }
        }

        if (this.pageNumberTrips == 1) {
          this.tripsList = [];
          this.selectedTrips = [];
        }
        this.minAndMax = res.payload;
        this.selectedExpense = [];
        this.selectedStatus = [];
        this.selectedTripCategory = [];
        this.changeFilterOptions(
          res.payload.min,
          res.payload.max,
        );
        // this.changeFilterOptions(res.payload.min,res.payload.max);
        const listarray = res.payload.tripList;
        this.tripsList = this.tripsList.concat((listarray.map((list) => new NewTripModel(list))));
        this.tripsRecoeds = this.tripsList.length;
        this.tripsList.forEach(istrip => {
          // attachmentsExp.forEach(elm => {

          //     if (elm.referenceId == istrip.tripId && (elm.attachmentType == "2" || elm.attachmentType == "1")) {
          //         istrip.isTripShow = true;
          //     }
          // });
        });
        this.dummyListLength = this.tripsList?.length;
        this.setDummyList();
        if (
          this.pageNumberTrips === 1 &&
          this.isPageLoadTrips === true
        ) {
          this.isPageLoadTrips = false;
        }

        if (event) {
          event.target.complete();
        }
        if (this.tripsList.length < this.pageSize && event) {
          event.target.disabled = true;
        }
        this.isApicallinProgressTrips = false;
        this.modalRef?.hide();

      } else {

        if (res.errorCode != 14) {
          this.tripsList = [];
          this.toaster.error(res.errorMessage, 'Error');
        }
        this.showLoadingTrips = false;
        this.isApicallinProgressTrips = false;
        if (event) {
          event.target.disabled = true;
        }
      }
      
    });

  }
  loadMoreTrips(event) {
    if (this.tripsList.length < this.pageSizeTrips && event) {
      event.target.disabled = true;
    } else {
      this.pageNumberTrips++;
      this.getTripsList(false, event);
    }
  }
  downloadBtnCall() {
    let attachObj = {
      attachmentIds: [this.invoiceDetails[this.defaultIndex].attachmentId],
    };
    this.expenseService.downloadAttachment(attachObj).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        // this.downloadProof = res["payload"]["url"];
        // window.open(this.downloadProof);
        this.downloadProof = res.payload.downloadFileResponseDTO;
        this.downloadProof.forEach(element => {
          if (element) {
            window.open(element.url);
          }
        });
        this.toastr.success(
          this.translate.instant("alerts.downloadsucces"),
          this.translate.instant("alerts.succes")
        );
      } else {
        this.toastr.error(
          res.errorMessage,
          this.translate.instant("alerts.error")
        );
      }
    });
  }
  pdfchecking(value) {
    if (value?.url.split("?")[0].split(".").pop() == "pdf") {
      return "pdf";
    } else if(value?.url.split("?")[0].split(".").pop() == "zip" || value?.url.split("?")[0].split(".").pop() == "rar") {
      return "zip";
    } else {
      return "png";
    }
  }

  // tripDetails(obj) {
  //  // this.selectedTrips = [];
  //  // this.serviceList=[];
  //   this.selectedTrips = [];
  //   // this.serviceList=obj
  //   this.seletedTrip = obj;
  //   this.tripsList.forEach(element => {
  //     element.isSelected = false;
  //   });
  //   this.showTripDetails = true;
  //   this.showExpFilter = true;
  //   this.showExpList = true;
  //   this.showExpDetail = true;
  //   this.showExpSearch = true;
  //   this.addExpenseHide = true;
  //   this.showLoading = true;
  //   // this.navCtrl.navigateRoot(['tripDetails']);
  // }
  tripDetails(obj) {
    this.selectedTrips = [];
    this.tripsList.forEach(element => {
        element.isSelected = false;
    });
    this.showTripDetails = true;
    this.showExpFilter = true;
    this.showExpList = true;
    this.showExpDetail = false;
    this.showExpSearch = true;
    this.addExpenseHide = true;
    // this.hideReporttable = false;
    // this.showDetailreport = false;
    // this.navCtrl.navigateRoot(['tripDetails']);
}
  backToExpenses() {
    this.showTripDetails = false;
    this.showLoading = false;
  }
  selectAllTrips(event?) {
    setTimeout(() => {
      this.tripsList.forEach((item) => {
        item.isSelected = event ? false : this.checkAll;
      });
    });
  }
  selectSingleTrip(list, e) {
    list.isSelected = e.target.checked;
    if (list.isSelected) {
      this.selectedTrips.push(list);
    } else {
      let index = _.indexOf(this.selectedTrips, list.tripId);
      this.selectedTrips.splice(index, 1);
    }
  }
  filterlist = [];
  getfliterList() {
    let requestModal = {
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
    }
    this.commonService.fliterList(requestModal).subscribe((res) => {
      if (res.payload) {
        localStorage.setItem('trip-filter-data', JSON.stringify(res.payload));
        this.tripfilterPatching(res.payload);
      } else if (res.isError === true) {
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    });
  }

  tripfilterPatching(res) {
    res?.forEach(element => {
      this.filterService = element.services
    });
    res?.forEach(element => {
      this.filterstatus = element.tripStatus;

    });
    this.filterListStatus = this.filterstatus;
    this.filterCategoryList.forEach(element => {
      element.isSelected = false;
    });
    this.filterCategoryList = this.filterService;
  }

  selectAllFilterStatus(e) {
    if (e.detail.checked) {
      this.filterListStatus.forEach(element => {
        element.isSelected = true;
      });
    } else {
      this.filterListStatus.forEach(element => {
        element.isSelected = false;
      });
    }
  }
  selectAllTripProducts(e) {
    if (e.detail.checked) {
      this.filterCategoryList.forEach(element => {
        element.isSelected = true;
      });
    } else {
      this.filterCategoryList.forEach(element => {
        element.isSelected = false;
      });
    }

  }
  getFiltertodate(e) {
    this.tDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.tripfilterModal.toDate = date;
    this.maxDate = new Date(this.tDate);
  }
  getFiltefromrDate(e) {
    this.frmDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.tripfilterModal.fromDate = date;
    this.minDate = new Date(this.frmDate);
  }
  
  changeFilterOptions(minValue, maxValue) {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = minValue;
    newOptions.ceil = maxValue;
    this.minPrice = minValue;
    this.maxPrice = maxValue;
    this.options = newOptions;
  }
  resetFilterAmount() {
    // this.tripfilterModal.minPrice = this.minAndMax.min;
    // this.tripfilterModal.maxPrice = this.minAndMax.max;
  }
  applyTripfilter() {
    this.showFilter = false;
    this.resetFilterPaging();
    if (this.frmDate !== undefined && this.tDate === undefined) {
      this.tDate = this.mobMaxDate;
      this.tripfilterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
    }
    if (this.frmDate === undefined && this.tDate !== "") {
      this.frmDate = this.tDate;
      this.tripfilterModal.fromDate = this.tDate;
    }
    this.tripfilterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    this.tripfilterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
    this.getTripsList(false, '');
    this.returnExpSegment();
  }
  applyfiltertripadvance() {
    this.showFilter = false;
    this.resetFilterPaging();
    if (this.frmDate !== undefined && this.tDate === undefined) {
      this.tDate = this.mobMaxDate;
      this.tripfilterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
    }
    if (this.frmDate === undefined && this.tDate !== "") {
      this.frmDate = this.tDate;
      this.tripfilterModal.fromDate = this.tDate;
    }
    this.tripfilterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    this.tripfilterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
    this.getTripsList(false, '');
    this.returnExpSegment();
  }
  applyfiltertrip(closeModal?, removeAmount?) {
    this.showFilter = false;
    // this.resetFilterPaging();
    // if (this.frmDate !== undefined && this.tDate === undefined) {
    //   this.tDate = this.mobMaxDate;
    //   this.tripfilterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
    // }
    // if (this.frmDate === undefined && this.tDate !== "") {
    //   this.frmDate = this.tDate;
    //   this.tripfilterModal.fromDate = this.tDate;
    // }
    // this.tripfilterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    // this.tripfilterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;


    // this.filterMinPrice = removeAmount ? undefined : this.tripfilterModal.minPrice;
    // this.filterMaxPrice = removeAmount ? undefined : this.tripfilterModal.maxPrice;
    if (this.frmDate && this.tDate) {
      this.filterFromDate = this.frmDate;
      this.filterToDate = this.tDate;
    } else if (this.frmDate && !this.tDate) {
      this.filterFromDate = this.frmDate;
      this.filterToDate = moment().format();
    } else if (!this.frmDate && this.tDate) {
      this.filterFromDate = this.tDate;
      this.filterToDate = this.tDate;
    }
    this.filterListStatus.forEach(s => {
      if (s.isSelected) {
        s.showInFilter = true;
      } else {
        s.showInFilter = false;
      }
    })
    this.filterCategoryList.forEach(s => {
      if (s.isSelected) {
        s.showIncatFilter = true;
      } else {
        s.showIncatFilter = false;
      }
    })

    //this.resetPaging();
    if (this.frmDate !== undefined && this.tDate == undefined) {
      this.tDate = this.maxDate;
      this.tripfilterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
    }
    if (this.frmDate == undefined && this.tDate !== undefined) {
      this.frmDate = this.tDate;
      this.tripfilterModal.fromDate = this.tDate;
    }
    this.tripfilterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    this.tripfilterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
    this.maxDate = new Date();
    if (closeModal) {
      this.modalRef?.hide();
    }
    this.showCreallAlltripsButton()
    this.getTripsList(false, '');
    this.returnExpSegment();
    this.modalRef.hide();
  }



  priceSliderFilter(changeContext: ChangeContext) {
    // this.tripfilterModal.minPrice = changeContext.value;
    // this.tripfilterModal.maxPrice = changeContext.highValue;
  }
  resetFilterPaging() {
    this.tripsList = [];
    this.tripsList.length = 0;
  }
  selectSingleProduct(list) {
    if (list.isSelected) {
      this.selectedTripCategory.push(list.productId);
    } else {
      const index = _.indexOf(this.selectedTripCategory, list.productId);
      this.selectedTripCategory.splice(index, 1);
    }
    this.tripfilterModal.productIds = this.selectedTripCategory;
    if (this.filterCategoryList.length !== this.selectedTripCategory.length && this.selectedTripCategory.length > 0) {
      this.checkAllprod = false;
      this.tripCategoryState = true;
    } else if (this.selectedTripCategory.length === 0) {
      this.checkAllprod = false;
      this.tripCategoryState = false;
    } else {
      this.checkAllprod = true;
      this.tripCategoryState = false;
    }

  }
  resetTripFilters(list) {
    this.isSelected = false;
    list.forEach((item) => {
      item.isSelected = false;
    });
  }
  resetProductFilters(list) {
    this.checkAllprod = false;
    list.forEach((item) => {
      item.isSelected = false;
    });
  }
  selectSingleTripStatus(list) {
    if (list.isSelected) {
      this.selectedStatus.push(list.statusId);
      this.tripfilterModal.statusIds = this.selectedStatus;
    } else {
      const index = _.indexOf(this.selectedStatus, list.statusId);
      this.selectedStatus.splice(index, 1);
      this.tripfilterModal.statusIds = this.selectedStatus;
    }
    this.tripfilterModal.statusIds = this.selectedStatus;
    if (
      this.statusList.length !== this.selectedStatus.length &&
      this.selectedStatus.length > 0
    ) {
      this.checkAllTripStatus = false;
      this.tripStatusState = true;
    } else if (this.selectedStatus.length === 0) {
      this.checkAllTripStatus = false;
      this.tripStatusState = false;
    } else {
      this.checkAllTripStatus = true;
      this.tripStatusState = false;
    }
  }

  selectedTripInvoiceArray: any[];
  pdfloader: boolean = false;
  tripsPdfCall(pdfSelection1: TemplateRef<any>, trip) {
    this.selectedTripInvoiceArray = [];
    this.pdfloader = true;
    this.getpdfTripProofs(trip);

    //this.getTripDetails(trip);
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(pdfSelection1, config);
  }
  settlementPdfCall(settlementimageprevie: TemplateRef<any>, trip) {
    this.selectedTripInvoiceArray = [];
    this.pdfloader = true;
   
      this.getsettlementProofs(trip);
    

    //this.getTripDetails(trip);
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(settlementimageprevie, config);
  }
  getsettlementProofs(settlementdetails){
    this.invoiceDetails = [];
    this.invoiceDetails = settlementdetails.attahcments ||[];

    if (this.invoiceDetails) {
      let urlStr = this.invoiceDetails[0].url;
      if (urlStr?.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.isZip = false;
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
         // this.invoiceDetails[0].content = baseRes
          this.pdfloader = false;
        })
      } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
        this.isZip = true;
        this.isPdf = false;
      } else {
        this.isPdf = false;
        this.isZip = false;
        this.pdfloader = false;
      }
    }
  }
  getpdfTripProofs(expenseDetails){
    this.invoiceDetails = [];
    this.invoiceDetails = expenseDetails.tripServiceAttachments ||[];
    if (this.invoiceDetails.length > 0 && this.invoiceDetails[0]?.fileURL && this.invoiceDetails[0]?.fileURL !== 'null') {
      const urlStr = this.invoiceDetails[0].
      fileURL;
      if (urlStr.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.isZip = false;
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
          this.invoiceDetails[0].content = baseRes;
          this.pdfloader = false;
        });
      } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
        this.isZip = true;
        this.isPdf = false;
      } else {
        this.pdfloader = false;
        this.isPdf = false;
        this.isZip = false;
      }
    } else {
      // Handle the case when invoiceDetails is an empty array or URL is null or 'camw'
      this.pdfloader = false;
      this.isPdf = false;
      this.isZip = false;
    }
  }
  getpdfTripDetails(expenseDetails) {
    this.invoiceDetails = [];
    this.invoiceDetails = expenseDetails.invoiceList ||[];
    if (this.invoiceDetails.length > 0 && this.invoiceDetails[0]?.invoiceUrl && this.invoiceDetails[0]?.invoiceUrl !== 'null') {
      const urlStr = this.invoiceDetails[0].invoiceUrl;
      if (urlStr.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.isZip = false;
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
          this.invoiceDetails[0].content = baseRes;
          this.pdfloader = false;
        });
      } else if(urlStr.split("?")[0].split(".").pop() == "zip" || urlStr.split("?")[0].split(".").pop() == "rar") {
        this.isZip = true;
        this.isPdf = false;
      } else {
        this.pdfloader = false;
        this.isPdf = false;
        this.isZip = false;
      }
    } else {
      // Handle the case when invoiceDetails is an empty array or URL is null or 'camw'
      this.pdfloader = false;
      this.isPdf = false;
      this.isZip = false;
    }
  }
  getTripDetails(expenseDetails) {
    // this.defaultBoolean = true;
    let getTripModl = new GetTripInfo();
    getTripModl.legalEntityId = this.userInfo.legalEntityId;
    getTripModl.legalEntityGUID = this.userInfo.legalEntityGuId;
    getTripModl.requestId = expenseDetails.requestId;
    getTripModl.requestGUID = expenseDetails.requestGUID;
    getTripModl.productId = expenseDetails.productId;
    getTripModl.tripId = expenseDetails.tripId;
    getTripModl.tripGUID = expenseDetails.tripGUID;
    this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
      if (!res.isError && res.errorCode === 0 && res?.payload) {
        if (expenseDetails.productId == "1") {
          res?.payload.flightDetails.flights.forEach((element) => {
            element.invoiceDetails?.forEach((innerelement) => {
              if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
                let object_data = {
                  fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
                  content: innerelement.base64Invoice,
                  url: innerelement.invoiceUrl,
                  isDeleted: "",
                  remarks: expenseDetails.productName,
                  referenceId: expenseDetails.tripId,
                };
                // this.expenseModel.expenseAttachments.push(object_data);
                this.selectedTripInvoiceArray.push(object_data);
              }

            });
          });
        }
        this.pdfloader = false;
      }
    });
  }
  returnExtension(filename) {
    let text = filename?.split("?")[0]?.split(".").pop();
    return text?.toLowerCase() || '';
  }
  base64dataRe(incomingD) {
    const linkSource = `data:application/pdf;base64,${incomingD}`;
    return linkSource;
  }
  preBtn() {
    if (this.defaultIndex != 0) {
      this.defaultIndex = this.defaultIndex - 1;
    }
  }
  fromDatev2() {
    this.openfromdate = true;
    this.opentodate = false;
  }
  onClickedOutsideCalenderfrom(e) {
    let el = <HTMLElement>e?.target;
    if (this.openfromdate && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
      || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today"
      || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
      this.fromdate?.open();
    }
    else {
      this.fromdate?.close();

    }

  }
  toDatev2() {
    this.opentodate = true;
    this.openfromdate = false;
  }
  onClickedOutsideCalendertodate(e) {
    let el = <HTMLElement>e?.target;
    if (this.opentodate && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
      || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today"
      || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
      this.todate?.open();


    }
    else {
      this.openfromdate = false;
      this.todate?.close();

    }

  }
  selectedbulkIndex: any = -1;
  bulkMenu(index) {
    if (this.selectedbulkIndex != index)
      this.selectedbulkIndex = index;
    else
      this.selectedbulkIndex = -1;
  }
  hidebulkDown() {
    if (this.selectedbulkIndex !== -1) {
      this.selectedbulkIndex = -1;
    }
  }

  approvalSearch(val) {
    this.ExpenseorReport = val.currentTarget.value.toLowerCase();
    if (this.ExpenseorReport?.length >= 2) {
      this.resetPage();
      this.SearchListText.next(this.ExpenseorReport);
    }
    if (this.ExpenseorReport.length >2) {
      this.resetPage();
      this.getTripsList(true);
    }
  }
  resetPage() {
    this.pageNumber = 1;
    this.tripsList = [];
    this.tripsList.length = 0;
  }


  formatDate(date) {
    if (date) {
      return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');

    }
  }

  claimFilterApplied = false;

  removeFilter(list) {
    list.isSelected = false;
    this.filterModal.statusIds = [];
    this.checkAllStatus = false;
    this.statusList.forEach(s => {
      if (s.isSelected) {
        this.filterModal.statusIds.push(s.statusId)
      }
    })
    let removeAmount = false;
    if (!this.filterMaxPrice && !this.filterMinPrice) {
      removeAmount = true;
    }
    this.applyfilteradvance(false, removeAmount);
  }
  removeFIlterdate(list, index) {
    if (list.type == "date") {
      this.resetDates();
      this.filterdata.splice(index, 1)
      this.getExpenseList(false);
      this.returnExpSegment();
    } else if (list.type == "amount") {
      // this.filterModal.minPrice = undefined;
      // this.filterModal.maxPrice = undefined;
      this.resetAmount();
      this.filterdata.splice(index, 1)
      //this.filterdata = [];
      this.getExpenseList(false);
      this.returnExpSegment();
    }
  }
  removeDateFilter() {
    this.filterFromDate = undefined;
    this.filterToDate = undefined;
    this.frmDate = undefined;
    this.tDate = undefined;
    let removeAmount = false;
    if (!this.filterMaxPrice && !this.filterMinPrice) {
      removeAmount = true;
    }
    // this.applyFilter(false, removeAmount);
    this.applyfilteradvance(false, removeAmount);
  }

  removeAmountFilter() {
    this.filterMinPrice = undefined;
    this.filterMaxPrice = undefined;
    this.filterModal.minPrice = this.minPrice;
    this.filterModal.maxPrice = this.maxPrice;
    this.applyfilteradvance(false, true);
  }
  removetipDateFilter() {
    this.filterFromDate = undefined
    this.filterToDate = undefined;
    this.frmDate = undefined;
    this.tDate = undefined;
    let removeAmount = false;
    if (!this.filterMaxPrice && !this.filterMinPrice) {
      removeAmount = true;
    }
    this.applyfiltertrip(false, removeAmount);
  }
  removetripAmountFilter() {
    this.filterMinPrice = undefined;
    this.filterMaxPrice = undefined;
    // this.tripfilterModal.minPrice = this.minPrice;
    // this.tripfilterModal.maxPrice = this.maxPrice;

    // this.tripfilterModal.minPrice = this.minAndMax.min;
    // this.tripfilterModal.maxPrice = this.minAndMax.max;
    this.applyfiltertrip(false, true);
  }
  resettripAllFilters() {
    this.removetipDateFilter();
    this.removetripAmountFilter();
    //this.removeFilter(this.statusList);
    // this.filterModal.statusIds = [];
    // this.statusList.splice(index,1)
    this.getTripsList(false, '');
  }
  removetripFilter(list) {
    list.isSelected = false;
    this.tripfilterModal.statusIds = [];
    this.filterListStatus.forEach(s => {
      if (s.isSelected) {
        this.tripfilterModal.statusIds.push(s.statusId)
      }
    })
    let removeAmount = false;
    if (!this.filterMaxPrice && !this.filterMinPrice) {
      removeAmount = true;
    }
    this.applyfiltertrip(false, removeAmount);
  }
  showclearAll = false;
  statusAll = false;
  resetAllFilters() {
    if (this.matTabIndex == 0 ||this.matTabIndex == 1) {
      this.filterFromDate = undefined;
      this.filterToDate = undefined;
      this.frmDate = undefined;
      this.tDate = undefined;

      this.filterMinPrice = undefined;
      this.filterMaxPrice = undefined;
      this.filterModal.minPrice = this.minPrice;
      this.filterModal.maxPrice = this.maxPrice;
      this.filterModal.fromDate = null;
      this.filterModal.toDate = null;

      this.statusAll = false;
      this.checkAllStatus = false;
      this.showclearAll = false;
      this.statusList.forEach(s => {
        s.isSelected = false;
        s.showInFilter = false;
      })
      this.filterModal.statusIds = [];

      this.getExpenseList(false);
    }


  }
  resetAlltripFilters() {
    this.filterFromDate = undefined;
    this.filterToDate = undefined;
    this.frmDate = undefined;
    this.tDate = undefined;
    this.filterMinPrice = undefined;
    this.filterMaxPrice = undefined;
    // this.tripfilterModal.minPrice = this.minPrice;
    // this.tripfilterModal.maxPrice = this.maxPrice;
    this.statusAll = false;
    this.isSelected = false;
    this.showclearAll = false;
    this.filterListStatus.forEach(s => {
      s.isSelected = false;
      s.showInFilter = false;
    })
    this.checkAllprod = false;
    this.filterCategoryList.forEach((item) => {
      item.isSelected = false;
      item.showIncatFilter = false;
    });
    this.tripfilterModal.statusIds = [];
    this.tripfilterModal.productIds = [];
    this.getTripsList(false, '');
  }
  showIncatFilter(list) {
    list.isSelected = false;
    this.tripfilterModal.productIds = [];
    this.filterCategoryList.forEach(s => {
      if (s.isSelected) {
        this.tripfilterModal.productIds.push(s.productId)
      }
    })
    let removeAmount = false;
    if (!this.filterMaxPrice && !this.filterMinPrice) {
      removeAmount = true;
    }
    this.applyfiltertrip(false, removeAmount);
  }
  // pdfloader: boolean = true;
  pdfSelectionCalltripview(pdfSelection: TemplateRef<any>, trip) {
    
    this.previewAttachmentsArray = [];
    this.pdfloader = true;
    //this.getSingleTripDeatils(trip);
    this.getpdfTripDetails(trip);
    // this.selectedTripInvoiceArray = trip.attchements;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(pdfSelection, config);
  }
  getSingleTripDeatils(selectedTrip?) {
    let getTripModl = new GetTripInfo();
    // this.getReportModal.legalEntityId = this.reportSummary.legalEntityId;
    // this.getReportModal.legalEntityGUID = this.reportSummary.legalEntityGUID;
    getTripModl.legalEntityId = this.userInfo.legalEntityId;
    getTripModl.legalEntityGUID = this.userInfo.legalEntityGUID;
    getTripModl.companyId = this.userInfo.companyId;
    getTripModl.companyGUID = this.userInfo.companyGuId;
    getTripModl.tripId = selectedTrip.tripId;
    getTripModl.tripGUID = selectedTrip.tripGUID;
    getTripModl.productId = selectedTrip.productId;
    getTripModl.requestId = selectedTrip.requestId;
    getTripModl.requestGUID = selectedTrip.requestGUID;

    this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
      if (!res.isError && res.errorCode === 0 && res?.payload) {
        res?.payload.flightDetails?.flights[0]?.invoiceDetails?.forEach((innerelement) => {
          if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
            let object_data = {
              fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
              content: innerelement.base64Invoice,
              url: innerelement.invoiceUrl,
              isDeleted: "",
              attachmentType: 1,
              referenceId: res?.payload.tripId,
            };
            this.previewAttachmentsArray.push(object_data);
          }
        });
      }

      this.pdfloader = false;
    });
  }
  showCreallAllButton() {
    let price = false;
    let date = false;
    let status = false;
    let category = false;
    if (this.filterMinPrice || this.filterMaxPrice) {
      price = true;
    }
    if (this.filterFromDate || this.filterToDate) {
      date = true;
    }
    this.statusList.forEach(s => {
      if (s.showInFilter && !status) {
        status = true;
      }
    })

    if (!price && !date && !status && !category) {
      this.statusAll = false;
      this.showclearAll = false;
    } else {
      this.showclearAll = true;
    }
  }
  showCreallAlltripsButton() {
    let price = false;
    let date = false;
    let status = false;
    let category = false;
    if (this.filterMinPrice || this.filterMaxPrice) {
      price = true;
    }
    if (this.filterFromDate || this.filterToDate) {
      date = true;
    }
    this.filterListStatus.forEach(s => {
      if (s.showInFilter && !status) {
        status = true;
      }
    })
    this.filterCategoryList.forEach(s => {
      if (s.showIncatFilter && !category) {
        category = true;
      }
    })

    if (!price && !date && !status && !category) {
      this.statusAll = false;
      this.showclearAll = false;
    } else {
      this.showclearAll = true;
    }
  }

  // createNewExpense(list) {
  //   console.log("trip")
  //   this.selectedTripDetails = list;
  //   this.selectedTrips = [];
 
  //   let requestobj = {
  //     tripId: list.tripId,
  //     tripGUID:list.tripGUID
  //   }
  //   if (list.isMultiService) {
  //     this.reportsService.getTripServiceList(requestobj).subscribe((res) => {
  //       if (!res.isError && res.errorCode == 0 && res?.payload) {
  //         this.serviceList = res?.payload.serviceList
  //         this.serviceList.forEach(element => {
  //           let requestobject = {
  //             id: null,
  //             expenseId: null,
  //             requestId: element.requestId,
  //             requestGUID: element.requestGUID,
  //             tripId: element.tripId,
  //             tripGUID: element.tripGUID,
  //             startDate: element.tripStartDate,
  //             endDate: element.tripEndDate,
  //             fromLocation: element.tripName,
  //             toLocation: "",
  //             totalAmount: element.tripCost,
  //             paymentMode: element.paymentMode,
  //             productId: element.productId,
  //             isAssociated: true,
  //             productName: element.productName,
  //             invoiceList: element.invoiceList
  //           };
  //           console.log("requestobject",requestobject)
  //           this.selectedTrip.push(requestobject)
  //           console.log("requestobject",this.selectedTrip)
  //         });
  //       } 
  //     });
  //   } else {
  //     this.selectedTrips.push(list);
  //   }
  //   console.log("selectedTrips",this.selectedTrip)
  //   let navInfo = {
  //     url: "advance",
  //     selectedTrips: list.isMultiService?this.selectedTrips:this.selectedTrips,
  //   };
  //   console.log("tripnavInfo",navInfo)
  //   sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
  //   console.log("tripnavInfo",navInfo)

  //   this.router.navigateRoot(["newAdvance"]);
  //   // this.singleSelection = true;
  //   // this.multiSelection = false;
  //   // const config = {
  //   //     ignoreBackdropClick: true,
  //   //     class: "modal-dialog-centered modal-lg baa",
  //   //     keyboard: false,
  //   // };
  //   // this.modalRef = this.modalService.show(expenseSelection, config);
  // }
  createNewExpense(list) {
    this.selectedTripDetails = list;
    this.selectedTrips = [];
    this.selectedTrip = [];

    let requestobj = {
        tripId: list.tripId,
        tripGUID: list.tripGUID,
        legalEntityGuid: this.userInfo?.legalEntityGuId,
        legalEntityId: this.userInfo?.legalEntityId
    };

    if (list.isMultiService) {
        this.reportsService.getTripServiceList(requestobj).subscribe((res) => {
            if (!res.isError && res.errorCode == 0 && res?.payload) {
                this.serviceList = res?.payload.serviceList;
                console.log('sdsds',res?.payload);
                this.serviceList.forEach(element => {
                    let requestobject = {
                        id: null,
                        expenseId: null,
                        requestId: element.requestId,
                        requestGUID: element.requestGUID,
                        tripId: element.tripId,
                        tripGUID: element.tripGUID,
                        startDate: element.tripStartDate,
                        endDate: element.tripEndDate,
                        fromLocation: element.tripName,
                        toLocation: "",
                        totalAmount: element.tripCost,
                        paymentMode: element.paymentMode,
                        productId: element.productId,
                        isAssociated: true,
                        productName: element.productName,
                        invoiceList: element.invoiceList,
                        tripEstimateDetails: res?.payload?.tripEstimateDetails
                    };
                    this.selectedTrip.push(requestobject);
                });
            }
            console.log('selected trip :',this.selectedTrip);
            // Navigate after processing the service details
            this.navigateWithTrips(list.isMultiService);
        });
    } else {
        this.selectedTrips.push(list);
        // Navigate directly when not multi-service
        this.navigateWithTrips(false);
    }
}

  navigateWithTrips(isMultiService) {
    if(this.matTabIndex == 0){
      sessionStorage.setItem('hideTripDetails', 'false');
    }else if(this.matTabIndex == 1){
      sessionStorage.setItem('hideTripDetails', 'true');
    }else if(this.matTabIndex == 3){
      sessionStorage.setItem('hideTripDetails', 'true');
    }else{
      sessionStorage.setItem('hideTripDetails', 'false');
    }
    let navInfo = {
        url: "advance",
        selectedTrips: isMultiService ? this.selectedTrip : this.selectedTrips,
    };

    sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
    this.router.navigateRoot(["newAdvance"]);
}
  gotodashboard() {
    this.router.navigateRoot(['moreoptions']);
  }

  checkCheckbox() {
    setTimeout(() => {
      this.expense?.expenseAttachmentsmodel.forEach((item: any) => {
        item.isSelected = this.checkParent;
      });
    });
  }

  verifyEvent() {
    const allItems = this.expense?.expenseAttachmentsmodel.length;
    let selected = 0;
    this.expense?.expenseAttachmentsmodel.map((item: any) => {
      if (item.isSelected) selected++;
    });
    if (selected > 0 && selected < allItems) {
      // One item is selected among all checkbox elements
      this.indeterminateState = true;
      this.checkParent = false;
      this.proofsDownload = false;
    } else if (selected == allItems) {
      // All item selected
      this.checkParent = true;
      this.indeterminateState = false;
      this.proofsDownload = false;
    } else {
      // No item is selected
      this.indeterminateState = false;
      this.checkParent = false;
      this.proofsDownload = true;
    }
  }

  downloadProofs() {
    this.proofsDownload = true;
    this.downloadfile = true;
    const selectItems = [];
    this.expense?.expenseAttachmentsmodel.map((item: any) => {
      if (item.isSelected) selectItems.push(item.attachmentId);
    });
    let attachObj = { attachmentIds: selectItems};
    this.expenseService.downloadAttachment(attachObj).subscribe(res => {
      this.downloadfile = false;
      if (!res.isError && res.errorCode === 0) {
        this.downloadProof = res.payload.downloadFileResponseDTO;
        this.downloadProof.forEach(element => {
            if (element) {
                window.open(element.url);
            }
        });
        // window.open(this.downloadProof);
        this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
        this.expense?.expenseAttachmentsmodel.map((item: any) => {
          item.isSelected = false;
        });
      } else {
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    });
  }

  dateTimeFormat(date) {
    return moment(date, 'DD-MM-YYYY HH:mm:ss').format('DD-MMM-YY HH:mm:ss')
  }
  // toggleExpanded(index: number, item) {
  //   this.tripsList[index].isExpanded = !this.tripsList[index].isExpanded;
  //   this.tripsList.forEach((element, inx) => {
  //     if (index != inx) {
  //       element.isExpanded = false;
  //     }
  //   });
  //   if (item.serviceList.length == 0) {
  //     this.reportsService.getTripServiceList({
  //       tripId: item.tripId,
  //       tripGUID: item.tripGUID
  //     }).subscribe((res) => {
  //       if (!res.isError && res.errorCode == 0 && res?.payload) {
  //         this.tripsList[index].serviceList = res?.payload.serviceList;
  //       }
  //       if (!item.isMultiService) {
  //         this.tripDetails(this.tripsList[index].serviceList[0]);
  //       }

  //     });
  //   } else {
  //     if (!item.isMultiService) {
  //       this.tripDetails(this.tripsList[index].serviceList[0]);
  //     }
  //   }
  // }

  toggleExpanded(index: number, item, expandTrip?) {
    this.selectedTripIndex = index;
    this.seletedTrip = item;
    if(expandTrip) {
      this.tripsList[index].isExpanded = !this.tripsList[index].isExpanded;
    }
    this.tripsList.forEach((element, inx) => {
        if (index != inx) {
            element.isExpanded = false;
        }
    });
    if (item.serviceList.length == 0) {
        this.reportsService.getTripServiceList({
            tripId: item.tripId,
            tripGUID: item.tripGUID,
            legalEntityGuid: this.userInfo?.legalEntityGuId,
            legalEntityId: this.userInfo?.legalEntityId
        }).subscribe((res) => {
            if (!res.isError && res.errorCode == 0 && res?.payload) {
                this.tripsList[index].serviceList = res?.payload?.serviceList;
                this.tripsList[index].tripEstimateDetails = res?.payload?.tripEstimateDetails;
                this.tripsList[index].advanceSummary = res?.payload?.advanceSummary;
            }
            if(!expandTrip){
                this.tripDetails(this.tripsList[index].serviceList[0]);
            }

        });
    }else{
        if(!expandTrip){
            this.tripDetails(this.tripsList[index].serviceList[0]);
        }
    }
}
  selectModeOption(ev) {
    console.log('selected value :',ev);
    this.settlementmodal.modeOfSettlement = ev;
    this.settleadvanceForm.controls["modeOfSettlement"].setValue(ev);
  }
  settleadvanceclose(){
    setTimeout(() => {
      this.submitted = false;
    }, 2000);
    this.modalRef.hide();
    this.settlementmodal.claimId=''
    this.settlementmodal.modeOfSettlement=''
    //this.settlementmodal.availableTripAdvanceAmount=''
    this.settlementmodal.settlementAttachments=[]
    this.settlementmodal.settlementDate=""
    this.settlementmodal.comments=""    
    // this.frmDate="";
    // this.settleadvanceForm.reset();

  }

  totalBaseApprovedAmountCalculcation(){
     var totalAmount = 0;
    this.reportSummaryData?.expenseList?.forEach(element => {
      totalAmount += element.unformattedOriginalAmount || 0;
      console.log('base approved 123 :',totalAmount);
    });
    console.log('base approved amount :',totalAmount);
    return totalAmount;
  }
 

  imagesCount = 0;
  handleInputChange(event,index?) {
    if (!event.target) {
      return;
    }
    if (!event.target.files) {
      return;
    }
    if (this.userInfo.maxAttachementCount < event.target.files.length) {
      this.toastr.error('Attchement count Should not greater than max count', 'Error');
      return;
    }
    if (this.userInfo.maxAttachementCount == this.expenseModel.expenseAttachments.length) {
      this.toastr.error('you have reached attchement limit', 'Error');
      return;
    }

    var file = event.target.files;
      var expensetion = file[0].name.split("?")[0].split("#")[0].split('.').pop();
      if (this.userInfo.attachmentFormat) {
        if (this.commaSeperatorArray.indexOf(expensetion.toLowerCase()) > -1) {
        } else {
          this.toastr.error('invalid extension. Extension Should be ' + this.commaSeperatorArray.join(', ') + '', 'Error');
          this.buttonDisabled = false;
          return;
        }
      }
     this.imagesCount = event.target.files.length;
    //new code

    const obj = {
      "legalEntityGUID": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "fileName": file[0].name,
      "uploadFor": "EXPENSE-ATTACHMMENTS"
    }

     this.imgUpload = true;
    this.expenseService.getUploadUrl(obj).subscribe((res: any) => {
      sessionStorage.setItem('s3uploadUrl', res.payload?.s3Url)

      this.expenseService.uploadFileInS3(file[0], res.payload?.preSignedS3URL).subscribe(response => {
        if (response['status'] == 200) {
          this.imgUpload = false;
          sessionStorage.removeItem('s3uploadUrl');
            let request = {
              //fileName:file[0].name,
              fileURL: res.payload.s3Url,
              // content:res.payload.s3Url
            };
              this.settlementmodal.settlementAttachments.push(request);
        } else {
          sessionStorage.removeItem('s3uploadUrl');
          this.toastr.error('Unable to submit file');
          this.imgUpload = false;
        }
      }, err => {
        sessionStorage.removeItem('s3uploadUrl');
        this.toastr.error('Unable to submit file');
        this.imgUpload = false;
      })
    }, err => {
      sessionStorage.removeItem('s3uploadUrl');
      this.toastr.error('Unable to submit file');
      this.imgUpload = false;
    })
  }
  @ViewChild('fileUpload') fileUploadInput!: ElementRef<HTMLInputElement>;
  resetInputFile() {
    if (this.fileUploadInput && this.fileUploadInput.nativeElement) {
      this.fileUploadInput.nativeElement.value = '';
    }
  }

  getSettlemntfromrDate(e) {
    this.frmDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.settlementmodal.settlementDate = date;
  }
  get f() {
    return this.settleadvanceForm.controls;
  }

  submitsettleAdvance() {
    this.submitted = true;
    if (!this.settleadvanceForm.valid) {
      return;
    } else {
      this.settlementmodal.claimId = this.reportSummaryData.claimId;
      this.settlementmodal.claimGuid = this.reportSummaryData.claimGUID;
      // this.settlementmodal.expenseId = this.reportSummaryData.claimCode;
      // this.settlementmodal.expenseGUID = this.reportSummaryData.expenseGUID;
      
      this.settlebtnSubDisabled = true;
      console.log(this.settlementmodal,this.reportSummaryData.expenseList);

      let totalApprovedAmount = 0
      let advanceList = [];
      this.reportSummaryData?.expenseList.forEach(element => {
        if(element?.status != 4){
          let requst = {
            expenseId: element.expenseId,
            originalAmount: element?.unformattedOriginalApproveAmount,
            availableTripAdvanceAmount:parseInt(element?.unformattedOriginalAmount),
            originalCurrencyId:element?.originalCurrencyId,
            exchangeRate:element?.exchangeRate,
            editedExchangeRate:element.editedExchangeRate
          }
          totalApprovedAmount = totalApprovedAmount + (element?.unformattedOriginalApproveAmount * element?.exchangeRate)
          advanceList.push(requst);
        }
        
      });

      const requestpayload = {
        userId:this.userInfo?.userId,
        userGUID:this.userInfo?.userGuId,
        legalEntityId:this.userInfo?.legalEntityId,
        legalEntityGUID:this.userInfo?.legalEntityGuId,
        claimId:this.reportSummaryData?.claimId,
        claimGUID:this.reportSummaryData?.claimGUID,
        modeOfSettlement:this.settlementmodal.modeOfSettlement,
        settlementAttachments:this.settlementmodal.settlementAttachments,
        comments:this.settlementmodal.comments,
        availableTripAdvanceAmount: this.reportSummaryData?.claimTaggedTripList?.length == 0 ? totalApprovedAmount : this.reportSummaryData?.availableTripAdvanceAmount,
        advanceList: advanceList,
        isTripAdvance:this.reportSummaryData?.isTripAdvance
      }

      this.expenseService.advanceSettlement(requestpayload).subscribe((res) => {
        if (!res.isError && res.errorCode == 0) {
          this.modalRef.hide();
          const current_modal = JSON.parse(sessionStorage.getItem('current_modal'));
          this.submitted = false;
          this.settlebtnSubDisabled = false;
          // this.getExpenseSumary(this.reportSummaryData);
          this.tableHide = true;
          setTimeout(() => {
            this.showExpenseDetail2(this.reportSummaryData);
          }, 0)
          this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
        } else {
          this.settlebtnSubDisabled = false;
          this.toastr.error(
            res.errorMessage,
            this.translate.instant("alerts.error", { timeOut: 3000 })
          );
        }
      }, err => {
        this.settlebtnSubDisabled = false;
        // this.toastr.error('Unable to submit');
      });

      console.log('final request payload :',requestpayload);

      // console.log('report summmary :',this.reportSummaryData);
      // const request_payload = [];
      // this.reportSummaryData?.expenseList.forEach(element => {
      //   let request_obj  = {
      //     description:element?.description,
      //     fromDate:this.reportSummaryData?.tripStartDate,
      //     isTrip:element?.isTripAdvance,
      //     originalAmount:element?.originalAmount,
      //     originalCurrencyCode:element?.originalCurrencyCode,
      //     originalCurrencyId:element?.originalCurrencyId,
      //     originalCurrencyIcon:element?.originalCurrencyIcon,
      //     exchangeRate:element?.exchangeRate,
      //     reason:element?.reason,
      //     toDate:this.reportSummaryData?.tripEndDate,
      //     tripId:element?.tripId,
      //     tripName:element?.tripName,
      //     expenseId:element?.expenseId,
      //     expenseGUID:element?.expenseGUID,
      //     claimId:element?.claimId,
      //     claimGUID:this.reportSummaryData?.claimGUID,
      //     claimCode:element?.claimCode,
      //     userId:this.userInfo?.userId,
      //     legalEntityId:this.userInfo?.legalEntityId,
      //     userGUID:this.userInfo?.userGuId,
      //     legalEntityGUID:this.userInfo?.legalEntityGuId,
      //     recordType:7,
      //     action:2,
      //     categoryType:element?.categoryType,
      //     claimTaggedTripList:element?.claimTaggedTripList,
      //     claimName:element?.claimName,
      //     advanceTypeId:element?.advanceTypeId,
      //     advanceTypeName:element?.advanceTypeName,
      //     editedExchangeRate: element?.editedExchangeRate,
      //     modeOfSettlement:this.settlementmodal.modeOfSettlement,
      //     settlementAttachments:this.settlementmodal.settlementAttachments,
      //     settlementDate:this.settlementmodal.settlementDate,
      //     comments:this.settlementmodal.comments,
      //     expensedDate:element.expensedDate

      //   }

      //   request_payload.push(request_obj);
      // });
      // this.expenseService.createExpenseandMileage(request_payload).subscribe(res =>{
      //   console.log('response is',res);
      // })
    }
  }
  @ViewChild('frompicker') frompicker: MatDatepicker<Date>;
  onClickedOutsideCalenderSettlePopup(e, frompickerSettle: any) {
    let el = <HTMLElement>e?.target;
    if ((el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
      || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
      || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
      frompickerSettle?.open();
    } else if (el) {
      frompickerSettle?.close();
    }
  }

  getAbsValue(val) {
    return val < 0 ? Math.abs(val) : val;
  }

  submitsettleAdvanceNew() {
    console.log('createAdvanceModel ____ ', this.createAdvanceModel);
    console.log('reportSummaryData ____ ', this.reportSummaryData);
    console.log('createAdvanceModel ____ ', this.createAdvanceModel);
  }

  resetClaimType(removeFilter?) {
    this.filterModal.claimTypeId = null;
    this.filterModal.claimTypeName = null;
    this.filterClaimTypeId = null;
    this.filterClaimTypeName = null;
    if (removeFilter) {
      this.applyfilteradvance();
    }
  }

  setFilterClaimType(type) {
    this.filterClaimTypeId = type.id;
    this.filterClaimTypeName = type.claimType;
    this.filterModal.claimTypeId = this.filterClaimTypeId;
    this.filterModal.claimTypeName = this.filterClaimTypeName;
  }

  claimTypes: any = [];
  getClaimTypes() {
    const obj = {
      "action": "claim_types",
      "legalEntityGuid": this.userInfo?.legalEntityGuId,
      "legalEntityId": this.userInfo?.legalEntityId,
      "companyId": this.userInfo?.companyId,
      "companyGUID": this.userInfo?.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      this.claimTypes = res?.payload || [];
    })
  }

}
