import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'src/app/models/Settings/category.model';
import { financeIntegration, financeIntegrationList } from 'src/app/models/Settings/finacial-integration.model';
import { PlatformService } from 'src/app/services/platformbase.service';
import { SettingsService } from 'src/app/services/settings-services';
import * as _ from "lodash";
import { ExpensesService } from 'src/app/services/expenses.service';
import { log } from 'console';

@Component({
  selector: 'app-add-finance-integration',
  templateUrl: './add-finance-integration.component.html',
  styleUrls: ['./add-finance-integration.component.scss'],
})
export class AddFinanceIntegrationComponent implements OnInit {
  //@ViewChild('category', { static: false }) auto: ElementRef;
  @ViewChild('category', { static: false }) autoCat: ElementRef;
  platformType: number;
  finacialForm: FormGroup;
  displayTitle: string = "Add New";
  financeModel = new financeIntegration();
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisabled: boolean = false;
  validationMsg: any;
  loaderList: any = [1, 2, 3];
  selectedLegalEntity: any;
  userInfo: any;
  selectedStatusId: any;
  categoryModel = new Category();
  categoryList: any;
  categoryTemList: any;
  listNames: any;
  categorylists = [];
  showCategory: boolean = false;
  openOptions: boolean = false;
  claimTypes = [];
  claimTypesTemp = [];
  catList = [];
  categoryName = [];

  constructor(
    private platformService: PlatformService,
    private router: NavController,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private toastr: ToastrService,
    private expenseService:ExpensesService,
    private settingService: SettingsService,
  ) {
    this.finacialForm = this.fb.group({
      financeIntegrationName: ["", Validators.required],
      financeIntegrationCode: [""],
      categoryName: [''],
      categoryId: [''],
    });
  }

  ngOnInit() { }

  ionViewWillEnter() {

    this.platformType = this.platformService.getPlatformType();
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.financeModel.financeIntegrationId = this.activatedRoute.snapshot.params["id"];
    this.financeModel.financeIntegrationGUID = this.activatedRoute.snapshot.params["guid"];
    this.financeModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.financeModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    this.selectedStatusId = this.activatedRoute.snapshot.params["statusId"];    
    this.getCategoryList();
    this.getClaimTypes();
    if (this.financeModel.financeIntegrationId) {
      this.displayTitle = "Update";
      this.getSingleCategory();
    }
  }

  getSingleCategory() {
    this.showLoader = true;
    this.categoryName = [];
    this.settingsService.getfinacialIntegration(this.financeModel).subscribe((data) => {
      if (!data.isError) {
        this.showLoader = false;
        this.financeModel = new financeIntegrationList(data.payload);
        if(this.financeModel.categoryList?.length != 0){
          // this.financeModel.categoryList.forEach(element => {
          //   const categoryName = element.categoryName.split(' - ')[0]; // Splitting categoryName before '-'
          //   if(categoryName){
          //     this.categoryName.push(categoryName); // Adding to selectedValues array if not already present
          //   }
          // });
          if(this.financeModel.claimTypeName != null && this.financeModel.claimTypeName != ''){
            const filteredArray = this.categoryList?.filter(item => item.claimTypeNames?.includes(this.financeModel.claimTypeName));
            if (filteredArray?.length != 0) {
              this.categoryList = filteredArray;
            }
          }
         
        }
        console.log(this.financeModel.categoryList);
      } else {
        this.toastr.error(data.errorMessage, "Error");
      }
    });
  }

   // Compare function for ng-select to compare objects
   compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.categoryId === c2.categoryId : c1 === c2;
  }
  addorUpdateTax() {
    this.financeModel.statusId = this.selectedStatusId;
    this.submitted = true;
    if (!this.finacialForm.valid) {
      return;
    } else {
      this.buttonDisabled = true;
      this.settingsService.savefinacialIntegration(this.financeModel).subscribe((data) => {
        if (!data.isError) {
          this.buttonDisabled = false;
          this.toastr.success(data.payload, 'Success');
          this.backToTax();
        } else {
          this.buttonDisabled = false;
          this.toastr.error(data.errorMessage, 'Error');
          this.backToTax();
        }
      });
    }
  }

  backToTax() {
    this.router.navigateRoot(["/settings/financeintegration"]);
  }

  get f() {
    return this.finacialForm.controls;
  }
  getCategoryList() {
    this.categoryModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    this.categoryModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.categoryModel.categoryType = 1;
    let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
    if (navInfo && navInfo.categoryType) {
      this.categoryModel.categoryType = navInfo.categoryType;
    }

    this.categoryModel.status = 1;
    this.settingService.categoryListForFinanceCode(this.categoryModel).subscribe(data => {
      this.categoryList = data.payload.financecategoryList;
      this.categoryTemList = data.payload.financecategoryList
      console.log('cat list :',this.categoryList);
    });
  }

  getClaimTypes() {
    const obj = {
      "action": "claim_types",
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      this.claimTypes = res.payload;
      this.claimTypesTemp = [...this.claimTypes];
    })
  }

  claimTypeSelection(event){
    console.log(event);    
    const cfIndex = this.claimTypes?.findIndex(c => c.claimType == event);
    if(cfIndex != -1){
      this.financeModel.claimTypeId = this.claimTypes[cfIndex].id;
    }
    const filteredArray = this.categoryTemList?.filter(item => item.claimTypeName?.includes(this.financeModel.claimTypeName));
    if(filteredArray.length != 0){
      this.categoryList = filteredArray;
    }
    
  }

  addCategory(e): void {
    this.showCategory = true;
    const input = e.input;
    const value = e.value;
    if ((value || '').trim()) {
      this.categorylists.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    e.preventDefault();
    e.stopPropagation();
        this.showCategory = true;
        setTimeout(() => {
            this.autoCat.nativeElement.focus();
        }, 0);
  }
 
  attachmentsPdf(){
    this.toastr.error('Attachements not found','error');
  }
  remove(i: string): void {
    const index = this.categorylists.indexOf(i);
    if (index >= 0) {
      this.categorylists.splice(index, 1);
      this.financeModel.categoryList.splice(index,1);
    
    }
    // let indexx=this.categoryList.findIndex(item => item.categoryName=== i)

    // this.categoryList[indexx].isMapped=false
  }
  getProductsNames() {
    return _.map(this.categorylists, 'categoryName').join(',');
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value.isMapped) {
      this.toastr.error("Already Mapped","Eroor") 
    } else { 
      this.categorylists.push(event.option.value.categoryName);
      let categorydata = {
        categoryName: event.option.value.categoryName,
        categoryId: event.option.value.categoryId
      };
      this.financeModel.categoryList.push(categorydata);
    }
   
    this.finacialForm.patchValue({
      categoryName: this.getProductsNames()
    });
    this.showCategory = false;
  }
  onClickedCategory() {
    this.showCategory = false;
  }
  oncategorySearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (searchText != "") {
      let filterdata = this.categoryTemList.filter((data) => {
        if (
          data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >=
          0
        ) {
          return data;
        } else {
        }
      });
      this.categoryList = filterdata;

    } else {
      this.categoryList = this.categoryTemList
    }
  }
  onCategoryEventFocus() {
    setTimeout(() => {
      this.categoryList = this.categoryTemList;
    }, 0);
  }

}
