export class Mileage {
    categoryType: any;
    companyId: number;
    companyGUID: string;
    legalEntityGUID: string;
    legalEntityId: number;
    mileageRateId: any;
    mileageRateGUID: any;
    categoryId: string;
    rate: string;
    countryId: any;
    countryName: any;
    stateId: any;
    stateName: any;
    startDate: string;
    endDate:string;
    currencyId: any;
    unitsId: any;
    units: any;
    categoryName: any;
    status: number;
    mileageType: any;
    locationName:any;
    locationId:any
    pageSize:any;
    pageNumber:any;
    isAutoCalculateDistanceAmount: boolean = true;
    linkedCategoryIds: any = [];
    linkedCategoryNames: any = [];
    isBillNumberManditory: boolean = false;
}
// export class LegalEntity {
//     legalEntityId: any;
//     legalEntityGuid: any;
//     parentId: any;
//     legaEntityName: any;
//     companyId: any;
//     companyGuid: any;
//     baseCurrencyId: number;
//     addressLine1: any;
//     addressLine2: any;
//     countryId: any;
//     countryName: any;
//     stateId: any;
//     stateName: any;
//     city: any;
//     postalCode: any;
//     createdBy: number;
//     searchText: any;
//     pageSize: number;
//     pageNumber: number;
//     sortColumn: any;
//     sortType: any;
//     legalEntityCode: any;
//     logoPath:any;
//     parentName:any;
//     isPrimary: any;
//     currencyName: any;
//     currencyCode:any;
//     subLegalEntityCount: any;
//   }
export class MileageList {
    categoryType: any;
    companyId: number;
    companyGUID: string;
    legalEntityGUID: string;
    legalEntityId: number;
    mileageRateId: any;
    mileageRateGUID: any;
    categoryId: string;
    rate: string;
    startDate: string;
    endDate:string;
    categoryName: any;
    currencyId: any;
    unitsId: any;
    units: any;
    status: number;
    mileageType: any;
    locationName:any;
    locationId:any;
    countryName: any;
    stateId: any;
    stateName: any;
    countryId: any;
    pageSize:any;
    pageNumber:any;
    isAutoCalculateDistanceAmount: boolean = true;
    mandateFromToLocations: boolean = false;
    linkedCategoryIds: any = [];
    linkedCategoryNames: any = [];
    isBillNumberManditory: boolean = false;
    constructor(list) {
        this.mileageRateId = list.mileageRateId;
        this.mileageRateGUID = list.mileageRateGUID;
        this.legalEntityId = list.legalEntityId;
        this.legalEntityGUID = list.legalEntityGUID;
        this.categoryId = list.categoryId;
        this.categoryName = list.categoryName;
        this.rate = list.rate;
        this.startDate = list.startDate;
        this.endDate=list.endDate;
        this.currencyId = list.currencyId;
        this.unitsId = list.unitsId;
        this.units = list.units;
        this.status = list.status;
        this.locationName = list.locationName;
        this.mileageType = list.mileageType;
        this.locationId = list.locationId;
        this.countryId=list.countryId;
        this.countryName=list.countryName;
        this.stateName=list.stateName;
        this.stateId=list.stateId;
        this.pageSize = list.pageSize;
        this.pageNumber = list.pageNumber;
        this.isAutoCalculateDistanceAmount = list.isAutoCalculateDistanceAmount;
        this.mandateFromToLocations = list.mandateFromToLocations;
        this.linkedCategoryIds = list.linkedCategoryIds;
        this.linkedCategoryNames = list.linkedCategoryNames;
        this.isBillNumberManditory = list.isBillNumberManditory;
    }
}