import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SettingsGeneralPolicyCreate } from 'src/app/models/Settings/policy.model';
import { PlatformService } from 'src/app/services/platformbase.service';
import { SettingsService } from "src/app/services/settings-services";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ExpensesService } from 'src/app/services/expenses.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-general-policy',
  templateUrl: './general-policy.component.html',
  styleUrls: ['./general-policy.component.scss'],
})
export class GeneralPolicyComponent implements OnInit {
  @ViewChild("fileUpload", { static: false }) fileUploadInput: ElementRef;
  fileUploading = false;
  documentReferenceURLs = [];
  modalRef: BsModalRef;
  attachmentIndex: any;

  platformType: number;
  isChecked = false;
  policyForm: FormGroup;
  polModel = new SettingsGeneralPolicyCreate();
  buttonDisabled = false;
  showLoader = false;
  currentMatTab = 0; 
  selectedLegalEntity: any;
  public selectItems = ['jpg', 'jpeg', 'png', 'pdf', 'txt', 'csv', 'xls', 'xlsx', 'XML', 'svg',
    'xlsm', 'xltm', 'xltx','zip','rar'];
    public displayedColumns = ['ItemName', 'Description'];
    public dataSource = new MatTableDataSource();
   userInfo: any;
   FinanceLevel: any;
   statusArray: any;
   monthsList: any = [
    {id: 1, name: "Jan"}, {id: 2, name: "Feb"}, {id: 3, name: "Mar"}, {id: 4, name: "Apr"},
    {id: 5, name: "May"}, {id: 6, name: "Jun"}, {id: 7, name: "Jul"}, {id: 8, name: "Aug"},
    {id: 9, name: "Sep"}, {id: 10, name: "Oct"}, {id: 11, name: "Nov"}, {id: 12, name: "Dec"}
   ]
  //  claimTypes: any = [{id: 1, name: "Domestic"}, {id: 2, name: "International"}, {id: 3, name: "both"}]
  claimTypes: any;
  expenseTypeOptions = [
    {id: 1, name: 'Expense'},
    {id: 2, name: 'Mileage'},
    {id: 3, name: 'Perdiem'},
    {id: 4, name: 'Card'}
  ]

  constructor(private platformService: PlatformService,
    private settingService: SettingsService,
    private toaster: ToastrService,
    private title :Title,
    private translate: TranslateService,
    public formBuilder: FormBuilder,
    private expenseService: ExpensesService,
    private modalService: BsModalService,
    ) { }

  ngOnInit() {
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.getCountries();
  }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('General Policies| Settings' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle("General Policies| Settings | NoExpenses");
    }
    this.getClaimTypes();
    this.createPolicyFrom();
    this.getGeneralPolicy();
  }

  createPolicyFrom() {
    this.policyForm = this.formBuilder.group({
      dayDifference: [""],
      systemGeneratedKmValue: [""],
      dayDifferenceStatus: [""],
      maxAttachementCount: [],
      attachmentFormat: [],
      daysToSettleAdvance: [""]

    });

  }

  changedStatus(e: any, value?) {
    this.isChecked = e?.detail.checked;
    if (value == 'ocr') {
      if (e?.detail.checked) {
        this.polModel.isOcrEnabled = true;
      } else {
        this.polModel.isOcrEnabled = false;
      }
    } else if (value == 'tax') {
      if (e?.detail.checked) {
        this.polModel.isTaxCodeEnabled = true;
      } else {
        this.polModel.isTaxCodeEnabled = false;
      }
    } else if (value == 'finance') {
      if (e?.detail.checked) {
        this.polModel.isFinanceCodeEnabled = true;
      } else {
        this.polModel.isFinanceCodeEnabled = false;
      }
    }
    else if (value == 'hsn') {
      if (e?.detail.checked) {
        this.polModel.isHsnCodeEnabled = true;
      } else {
        this.polModel.isHsnCodeEnabled = false;
      }
    }
    else if (value == 'isAttachmentMandatory') {
      if (e?.detail.checked) {
        this.polModel.isAttachmentMandatory = true;
      } else {
        this.polModel.isAttachmentMandatory = false;
      }
    }
    else if (value == 'isBlockDuplicateExpense') {
      if (e?.detail.checked) {
        this.polModel.isBlockDuplicateExpense = true;
      } else {
        this.polModel.isBlockDuplicateExpense = false;
      }
    } else if (value == 'isDeviateGeneratedKmEnabled') {
      if (e?.detail.checked) {
        this.polModel.isDeviateGeneratedKmEnabled = true;
      } else {
        this.polModel.isDeviateGeneratedKmEnabled = false;
      }
    } else if (value == 'isCategoryBasedEnabled') {
      if (e?.detail.checked) {
        this.polModel.isCategoryBasedEnabled = true;
      } else {
        this.polModel.isCategoryBasedEnabled = false;
      }
    }else if (value == 'isRejectReasonMandatory') {
      if (e?.detail.checked) {
        this.polModel.isRejectReasonMandatory = true;
      } else {
        this.polModel.isRejectReasonMandatory = false;
      }
    }
    else if (value == 'isMileageEnabled') {
      if (e?.detail.checked) {
        this.polModel.isMileageEnabled = true;
      } else {
        this.polModel.isMileageEnabled = false;
      }
    }
    else if (value == 'isPerDiemEnabled') {
      if (e?.detail.checked) {
        this.polModel.isPerDiemEnabled = true;
      } else {
        this.polModel.isPerDiemEnabled = false;
      }
    } 
    else if (value == 'isSendBackEnabled') {
      if (e?.detail.checked) {
        this.polModel.isSendBackEnabled = true;
      } else {
        this.polModel.isSendBackEnabled = false;
      }
    } 
    else if (value == 'isAssociatedExpenseEnabled') {
      if (e?.detail.checked) {
        this.polModel.isAssociatedExpenseEnabled = true;
      } else {
        this.polModel.isAssociatedExpenseEnabled = false;
      }
    }else if (value == 'isServiceExpenseEnabled') {
      if (e?.detail.checked) {
        this.polModel.isServiceExpenseEnabled = true;
      } else {
        this.polModel.isServiceExpenseEnabled = false;
      }     
    }else if (value == 'isLinkedToAdvanceMandatory') {
      if (e?.detail.checked) {
        this.polModel.isLinkedToAdvanceMandatory = true;
      } else {
        this.polModel.isLinkedToAdvanceMandatory = false;
      }     
    }else if (value == 'isEditExchangeRateEnabled') {
      if (e?.detail.checked) {
        this.polModel.isEditExchangeRateEnabled = true;
      } else {
        this.polModel.isEditExchangeRateEnabled = false;
      }     
    }else if (value == 'isSettledAdvance') {
      if (e?.detail.checked) {
        this.polModel.isSettledAdvance = true;
      } else {
        this.polModel.isSettledAdvance = false;
      }     
    }else if (value == 'isMultiClaimsLinkedToAdvance') {
      if (e?.detail.checked) {
        this.polModel.isMultiClaimsLinkedToAdvance = true;
      } else {
        this.polModel.isMultiClaimsLinkedToAdvance = false;
      }     
    }else if (value == 'isEnableBudgetCheck') {
      if (e?.detail.checked) {
        this.polModel.isEnableBudgetCheck = true;
      } else {
        this.polModel.isEnableBudgetCheck = false;
      }     
    }else if (value == 'isRestrictUserOnInsufficientBudget') {
      if (e?.detail.checked) {
        this.polModel.isRestrictUserOnInsufficientBudget = true;
      } else {
        this.polModel.isRestrictUserOnInsufficientBudget = false;
      }     
    }else if (value == 'isRestrictManagerOnInsufficientBudget') {
      if (e?.detail.checked) {
        this.polModel.isRestrictManagerOnInsufficientBudget = true;
      } else {
        this.polModel.isRestrictManagerOnInsufficientBudget = false;
      }     
    }else if (value == 'isRestrictFinanceOnInsufficientBudget') {
      if (e?.detail.checked) {
        this.polModel.isRestrictFinanceOnInsufficientBudget = true;
      } else {
        this.polModel.isRestrictFinanceOnInsufficientBudget = false;
      }
    }else if (value == 'isEnableDimensions') {
      if (e?.detail.checked) {
        this.polModel.isEnableDimensions = true;
      } else {
        this.polModel.isEnableDimensions = false;
      }     
    }else if (value == 'isEnableTrackMileage') {
      if (e?.detail.checked) {
        this.polModel.isEnableTrackMileage = true;
      } else {
        this.polModel.isEnableTrackMileage = false;
      }     
    }else if (value == 'showReferenceDocument') {
      if (e?.detail.checked) {
        this.polModel.showReferenceDocument = true;
      } else {
        this.polModel.showReferenceDocument = false;
      }     
    }
    else if (value == 'isEnableBudgetUser') {
      if (e?.detail.checked) {
        this.polModel.isEnableBudgetUser = true;
      } else {
        this.polModel.isEnableBudgetUser = false;
      }     
    }
    else if (value == 'isEnableBudgetFinance') {
      if (e?.detail.checked) {
        this.polModel.isEnableBudgetFinance = true;
      } else {
        this.polModel.isEnableBudgetFinance = false;
      }     
    }
    else if (value == 'isEnableBudgetManager') {
      if (e?.detail.checked) {
        this.polModel.isEnableBudgetManager = true;
      } else {
        this.polModel.isEnableBudgetManager = false;
      }     
    }
    else if (value == 'isEnableAdvanceEditApprover') {
      if (e?.detail.checked) {
        this.polModel.isEnableAdvanceEditApprover = true;
      } else {
        this.polModel.isEnableAdvanceEditApprover = false;
      }     
    }
    else if (value == 'isEnableAdvanceTab') {
      if (e?.detail.checked) {
        this.polModel.isEnableAdvanceTab = true;
      } else {
        this.polModel.isEnableAdvanceTab = false;
      }
    }
    else if (value == 'isEnableAdvanceCreation') {
      if (e?.detail.checked) {
        this.polModel.isEnableAdvanceCreation = true;
      } else {
        this.polModel.isEnableAdvanceCreation = false;
      }
    }
    else if (value == 'isFinanceCodeEnabledAdvance') {
      if (e?.detail.checked) {
        this.polModel.isFinanceCodeEnabledAdvance = true;
      } else {
        this.polModel.isFinanceCodeEnabledAdvance = false;
      }
    }
    else if (value == 'isEnableCategorySelectionAtClaim') {
      if (e?.detail.checked) {
        this.polModel.isEnableCategorySelectionAtClaim = true;
      } else {
        this.polModel.isEnableCategorySelectionAtClaim = false;
      }     
    }
    else if (value == 'isEnableClaimTypes') {
      if (e?.detail.checked) {
        this.polModel.isEnableClaimTypes = true;
      } else {
        this.polModel.isEnableClaimTypes = false;
      }
    }
    else if (value == 'isEnableAdvanceTypes') {
      if (e?.detail.checked) {
        this.polModel.isEnableAdvanceTypes = true;
      } else {
        this.polModel.isEnableAdvanceTypes = false;
      }
    }
    else if (value == 'sendNotificationSettleAdvance') {
      if (e?.detail.checked) {
        this.polModel.sendNotificationSettleAdvance = true;
      } else {
        this.polModel.sendNotificationSettleAdvance = false;
      }
    } else if (value == 'isStatusEnabled') {
      if (e?.detail.checked) {
        this.polModel.isStatusEnabled = true;
      } else {
        this.polModel.isStatusEnabled = false;
      }     
    } else if (value == 'isMultipleClaimsAllowed') {
      if (e?.detail.checked) {
        this.polModel.isMultipleClaimsAllowed = true;
      } else {
        this.polModel.isMultipleClaimsAllowed = false;
      }     
    } else if (value == 'makeCityManadatoryCountry') {
      if (e?.detail.checked) {
        this.polModel.makeCityManadatoryCountry = true;
      } else {
        this.polModel.makeCityManadatoryCountry = false;
      }
    }
    else if (value == 'enableMechantCodeAtFinance') {
      if (e?.detail.checked) {
        this.polModel.enableMechantCodeAtFinance = true;
      } else {
        this.polModel.enableMechantCodeAtFinance = false;
      }
    }
    else if (value == 'enableOnlyParentCategory') {
      if (e?.detail.checked) {
        this.polModel.enableOnlyParentCategory = true;
      } else {
        this.polModel.enableOnlyParentCategory = false;
      }
    }
    else if (value == 'isAllowUserToEditAtExpenseLevel') {
      if (e?.detail.checked) {
        this.polModel.isAllowUserToEditAtExpenseLevel = true;
      } else {
        this.polModel.isAllowUserToEditAtExpenseLevel = false;
      }
    }
    else if (value == 'enableDirectTripSubmission') {
      if (e?.detail.checked) {
        this.polModel.enableDirectTripSubmission = true;
      } else {
        this.polModel.enableDirectTripSubmission = false;
      }
    }else if (value == 'enableClaimStartTimeEndTime') {
      if (e?.detail.checked) {
        this.polModel.enableClaimStartTimeEndTime = true;
      } else {
        this.polModel.enableClaimStartTimeEndTime = false;
      }
    }else if (value == 'enableBillingEntity') {
      if (e?.detail.checked) {
        this.polModel.enableBillingEntity = true;
      } else {
        this.polModel.enableBillingEntity = false;
      }
    }else if (value == 'displayTripIdUnderApprovals') {
      if (e?.detail.checked) {
        this.polModel.displayTripIdUnderApprovals = true;
      } else {
        this.polModel.displayTripIdUnderApprovals = false;
      }
    }else if (value == 'showTripsOnClickNewClaim') {
      if (e?.detail.checked) {
        this.polModel.showTripsOnClickNewClaim = true;
      } else {
        this.polModel.showTripsOnClickNewClaim = false;
      }
    }else if (value == 'enableRecordPaymentWithSAP') {
      if (e?.detail.checked) {
        this.polModel.enableRecordPaymentWithSAP = true;
      } else {
        this.polModel.enableRecordPaymentWithSAP = false;
      }
    }else if (value == 'showTripEstimateAmount') {
      if (e?.detail.checked) {
        this.polModel.showTripEstimateAmount = true;
      } else {
        this.polModel.showTripEstimateAmount = false;
      }
    }else if (value == 'restrictGSTNumberMissMatch') {
      if (e?.detail.checked) {
        this.polModel.restrictGSTNumberMissMatch = true;
      } else {
        this.polModel.restrictGSTNumberMissMatch = false;
      }
    }else if (value == 'restrictMobileBillMissMatch') {
      if (e?.detail.checked) {
        this.polModel.restrictMobileBillMissMatch = true;
      } else {
        this.polModel.restrictMobileBillMissMatch = false;
      }
    }else if (value == 'isEnableCategorySelectionAtExpense') {
      if (e?.detail.checked) {
        this.polModel.isEnableCategorySelectionAtExpense = true;
      } else {
        this.polModel.isEnableCategorySelectionAtExpense = false;
      }
    }else if (value == 'skipIfApproverNotAvailable') {
      if (e?.detail.checked) {
        this.polModel.skipIfApproverNotAvailable = true;
      } else {
        this.polModel.skipIfApproverNotAvailable = false;
      }
    }else if (value == 'showModifiedNameOfNonTravel') {
      if (e?.detail.checked) {
        this.polModel.showModifiedNameOfNonTravel = true;
      } else {
        this.polModel.showModifiedNameOfNonTravel = false;
      }
    }else if (value == 'isAttachmentMandatoryForAdvance') {
      if (e?.detail.checked) {
        this.polModel.isAttachmentMandatoryForAdvance = true;
      } else {
        this.polModel.isAttachmentMandatoryForAdvance = false;
      }
    }else if (value == 'isDescriptionMandatory') {
      if (e?.detail.checked) {
        this.polModel.isDescriptionMandatory = true;
      } else {
        this.polModel.isDescriptionMandatory = false;
      }
    }else if (value == 'prepopulateTypeOfClaim') {
      if (e?.detail.checked) {
        this.polModel.prepopulateTypeOfClaim = true;
      } else {
        this.polModel.prepopulateTypeOfClaim = false;
      }
    }else if (value == 'enableTravelExpenseType') {
      if (e?.detail.checked) {
        this.polModel.enableTravelExpenseType = true;
      } else {
        this.polModel.enableTravelExpenseType = false;
      }
    }else if (value == 'enableNonTravelExpenseType') {
      if (e?.detail.checked) {
        this.polModel.enableNonTravelExpenseType = true;
      } else {
        this.polModel.enableNonTravelExpenseType = false;
      }
    }else if (value == 'enableCardExpenseType') {
      if (e?.detail.checked) {
        this.polModel.enableCardExpenseType = true;
      } else {
        this.polModel.enableCardExpenseType = false;
      }
    }
    //  else if (value == 'financeYearStartMonth') {
    //   if (e?.detail.checked) {
    //     this.polModel.financeYearStartMonth = true;
    //   } else {
    //     this.polModel.financeYearStartMonth = false;
    //   }     
    // }
    else {
      if (e?.detail.checked) {
        this.polModel.dayDifferenceStatus = 1;
      } else {
        this.polModel.dayDifferenceStatus = 2;
      }
    }
  }

  createGenPol() {
    // if(!this.polModel.isAssociatedExpenseEnabled && !this.polModel.isServiceExpenseEnabled){
    //   this.toaster.error("Please enable one configuration to proceed")
    // }else{
    let attachement = "";
    if (this.polModel && this.polModel.attachmentFormats) {
      this.polModel.attachmentFormats.forEach((element, index) => {
        if (this.polModel.attachmentFormats.length === 1) {
          attachement = element
        } else {
          attachement = ((this.polModel.attachmentFormats.length - 1) === index) ? attachement + element : attachement + element + ","
        }
      });
      this.polModel.attachmentFormat = attachement
    } else {
      this.polModel.attachmentFormat = undefined;
      this.polModel.attachmentFormats = undefined;
    }
    this.polModel.referenceDocumentUrls = '';
    const urls = this.documentReferenceURLs.map(d => d.url);
    this.polModel.referenceDocumentUrls = urls.join(', ');

    // console.log(this.polModel);
    // console.log(this.FinanceLevel)
    if (this.FinanceLevel) {
      this.statusArray = this.FinanceLevel?.map(item => (item.label || item)?.trim())?.join(', ');
      this.polModel.statusDesc = this.statusArray;
    }
    
    this.settingService.generalPolicyCreate(this.polModel).subscribe(res => {
      if (!res.isError && res.payload) {
        this.toaster.success(res.payload, this.translate.instant('alerts.succes'));
        sessionStorage.setItem("isAttachmentMandatory", JSON.stringify(this.polModel.isAttachmentMandatory))
      } else {
        this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    })

   // }
  }

  tripRequestStatusList: any;
  getGeneralPolicy() {
    this.showLoader = true;
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.polModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || userInfo?.legalEntityId;
    this.polModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || userInfo?.legalEntityGuId;
    this.settingService.generalPolicyGet(this.polModel).subscribe(res => {
      this.showLoader = false;
      if (!res.isError && res?.payload) {
        this.polModel = res.payload;
        this.tripRequestStatusList = res.payload?.tripRequestStatusList || [];
        // let urlsList = res.payload.referenceDocumentUrls?.split(', ') || [];
        let urlsList = [];
        if(res.payload.referenceDocumentUrls && res.payload.referenceDocumentUrls != "") {
          urlsList = res?.payload?.referenceDocumentUrls?.split(', ') || [];
        }
        this.documentReferenceURLs = [];
        urlsList.forEach(u => {
          this.documentReferenceURLs.push({
            url: u,
            content: ''
          })
        })
        if (!this.polModel?.legalEntityId && !this.polModel?.legalEntityGUID) {
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          this.polModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || userInfo?.legalEntityId;
          this.polModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || userInfo?.legalEntityGuId;
          this.polModel.attachmentFormat = res.payload.attachmentFormats
        }
        if(res.payload.statusDesc){
           this.FinanceLevel = res?.payload?.statusDesc?.split(",");
        }
      } else {
        this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    })
  }
  numberValidation(event: any, isTime?) {
    const pattern = /^[0-9!.@#$%^&*()]+$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  onCategTabChange(e) {
    this.currentMatTab = e.index;
    this.getGeneralPolicy();
    //this.setRulesDatasource();
  }

  resetInputFile() {
    this.fileUploadInput.nativeElement.value = "";
  }

  handleInputChange(event) {
    if (!event.target) {
      return;
    }
    if (!event.target.files) {
      return;
    }


    var file = event.target.files;
    let imagesCount = event.target.files.length;

    let formats = this.userInfo?.attachmentFormat?.split(',');
    var expensetion = file[0]?.name?.split("?")[0]?.split("#")[0]?.split('.').pop();
    if (this.userInfo?.attachmentFormat) {
      if (formats.indexOf(expensetion.toLowerCase()) > -1) {
      } else {
        this.fileUploading = false;
        this.toaster.error('Invalid extension. Extension Should be ' + formats.join(', ') + '', 'Error');
        return;
      }
    }

    const obj = {
      "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId,
      "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId,
      "fileName": file[0].name,
      "uploadFor": "EXPENSE-ATTACHMMENTS"
    }

    this.fileUploading = true;
    this.expenseService.getUploadUrl(obj).subscribe((res_url: any) => {
      sessionStorage.setItem('s3uploadUrl', res_url.payload?.s3Url);
      this.expenseService.uploadFileInS3(file[0], res_url.payload?.preSignedS3URL).subscribe(res_upload => {
        if (res_upload['status'] == 200) {
          this.documentReferenceURLs.push({
            content: '',
            url: res_url.payload.s3Url
          });
          this.fileUploading = false;
          sessionStorage.removeItem('s3uploadUrl');
          this.toaster.success("Attachments added successfully", "Success");
        }

      }, err => {
        this.fileUploading = false;
        sessionStorage.removeItem('s3uploadUrl');
        this.toaster.error('Unable to submit file');
      })
    }, err => {
      this.fileUploading = false;
      sessionStorage.removeItem('s3uploadUrl');
      this.toaster.error('Unable to submit file');
    });
    
  }

  returnExtension(filename) {
    let text = filename?.split("?")[0].split(".").pop();
    return text?.toLowerCase();
  }

  showAttachPreview(attachPreview: TemplateRef<any>, index) {
    this.attachmentIndex = index ? index : 0;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    if (this.returnExtension(this.documentReferenceURLs[index]?.url) == 'pdf') {
      this.getPdfAsBase64(this.documentReferenceURLs[index].url).then((baseRes) => {
        this.documentReferenceURLs[index].content = `data:application/pdf;base64,${baseRes}`;
        this.modalRef = this.modalService.show(attachPreview, config);
      })
    } else {
      this.modalRef = this.modalService.show(attachPreview, config);
    }
  }

  async getPdfAsBase64(url: string): Promise<string> {
    try {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();

      const base64 = btoa(
        new Uint8Array(buffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      
      return base64;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }


  preAttach() {
    if (this.attachmentIndex != 0) {
      this.attachmentIndex = this.attachmentIndex - 1;
    }
    if (this.returnExtension(this.documentReferenceURLs[this.attachmentIndex]?.url) == 'pdf') {
      this.getPdfAsBase64(this.documentReferenceURLs[this.attachmentIndex].url).then((base64) => {
        this.documentReferenceURLs[this.attachmentIndex].content = `data:application/pdf;base64,${base64}`;
      })
    }
  }

  nextAttach() {
    if (this.attachmentIndex + 1 == this.documentReferenceURLs.length) {
    } else {
      this.attachmentIndex = this.attachmentIndex + 1;
    }
    if (this.returnExtension(this.documentReferenceURLs[this.attachmentIndex]?.url) == 'pdf') {
      this.getPdfAsBase64(this.documentReferenceURLs[this.attachmentIndex].url).then((base64) => {
        this.documentReferenceURLs[this.attachmentIndex].content = `data:application/pdf;base64,${base64}`;
      })
    }
  }

  base64data(incomingD) {
    const linkSource = `data:application/pdf;base64,${incomingD}`;
    return linkSource;
  }

  deleteAttachment() {
    this.documentReferenceURLs.splice(this.attachmentIndex, 1);
    this.closeSelection();
  }

  deleteConfirmationAlert(removeformTemp: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false
    };
    this.modalRef = this.modalService.show(removeformTemp, config);
  }

  closeSelection() {
    this.modalRef.hide();
  }

  countryList: any;
  getCountries() {
    this.settingService.getCountries({}).subscribe(data => {
      this.countryList = data?.payload || [];
    });
  }

  setCountries(ev) {
    console.log(ev);
    this.polModel.makeCityManadatoryCountryIds = ev?.map(e => `${e?.countryId}`);
    this.polModel.makeCityManadatoryCountryNames = ev?.map(e => `${e?.countryName}`);
  }

  setTripStatus(ev) {
    this.polModel.statusNames = ev?.map(e => `${e?.statusDescription}`);
    this.polModel.statusIds = ev?.map(e => `${e?.statusId}`);
  }


  getClaimTypes() {
    const obj = {
      "action": "claim_types",
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      this.claimTypes = res?.payload || [];
    })
  }

  addPerdiemClaimTypes(ev) {
    this.polModel.perDiemClaimTypeNames = ev?.map(e => `${e?.claimType}`);
    this.polModel.perDiemClaimTypeIds = ev?.map(e => `${e?.id}`);
  }

  addMileageClaimTypes(ev) {
    this.polModel.mileageClaimTypeNames = ev?.map(e => `${e?.claimType}`);
    this.polModel.mileageClaimTypeIds = ev?.map(e => `${e?.id}`);
  }
  
  prepopulateTypeOfClaim(ev) {
    this.polModel.prepopulateTypeOfClaimId = ev?.id;
    this.polModel.prepopulateTypeOfClaimName = ev?.claimType;
  }

  selectedExpenseTypeOptions(ev, type) {
    if(type == 'non-travel') {
      this.polModel.nonTravelExpenseTypeIds = ev?.map(e => `${e?.id}`);
      this.polModel.nonTravelExpenseTypeNames = ev?.map(e => `${e?.name}`);
    } else if(type == 'travel') {
      this.polModel.travelExpenseTypeIds = ev?.map(e => `${e?.id}`);
      this.polModel.travelExpenseTypeNames = ev?.map(e => `${e?.name}`);
    } else if(type == 'card') {
      this.polModel.cardExpenseTypeIds = ev?.map(e => `${e?.id}`);
      this.polModel.cardExpenseTypeNames = ev?.map(e => `${e?.name}`);
    }
    console.log(this.polModel)
  }
}
