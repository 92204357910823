import { FilterModal, TripFilterModal } from '../Common/common.model';
import { ExpenseListModel } from '../Expenses/expenses.model';

export class Report {
  claimId: number;
  claimGUID: string;
  claimCode: string;
  claimName: string;
  isLinkedToAdvancePayment: any;
  isLinkedToPettyCashPayment: any;
  advancePaymentId: string;
  advanceAmount: any;
  unformattedAdvanceAmount: any;
  unformattedPettyCashAmount: any;
  pettyCashAmount: any;
  status: string;
  expenseIds: Array<number>;
  legalEntityId: string;
  legalEntityGUID: string;
  userId: number;
  userGUID: string;
  action: number;
  recordType: number;
  poolId: number;
  dynamoId: number;
  tripId: string;
  tripName: string;
  tripStartDate: string;
  tripEndDate: string;
  claimStartDate: any;
  claimEndDate: any;
  totalBaseAmount:any;
  skipBudgetCheckMessage:boolean = false;
  skipAnomolies:boolean = false;
  claimCategoryId: any;
  claimCategoryName: any;
  companyPaid: any;
  employeePaid: any;
  claimTypeId: any;
  claimTypeName: any;
  claimTotal: any;
  claimStartTime: any;
  claimEndTime: any;
  claimTaggedTripList=[];
  tripEstimateDetails: any;
  fromMytrips: boolean;
  claimLevelVoilation: any;
  sentBackAmount: any;
  isSubmitWithoutApprovalFlow: any;
}
export class ReportReqlistModel {
  legalEntityId: number;
  legalEntityGUID: string;
  userId: number;
  userGUID: string;
  claimEndDate: any;
  claimStartDate: any;
}
export class ReportListResModel {
  advancePaymentId: string;
  approverFirstName: string;
  approverId: string;
  approverLastName: string;
  approverTypeName: string;
  baseCurrencyCode: string;
  baseCurrencyIcon: string;
  baseCurrencyId: number;
  claimCode: string;
  claimGUID: string;
  claimId: number;
  claimName: string;
  cliamNamecode: string;
  createdDate: string;
  employeeId: string;
  expenseIds: Array<number>;
  expenseList: Array<ExpenseListModel>;
  isLinkedToAdvancePayment: boolean;
  isLinkedToPettyCashPayment: boolean;
  legalEntityGUID: string;
  legalEntityId: number;
  noOfExpenses: number;
  paidDate: string;
  paymentStatus: string;
  status: number;
  statusDescription: string;
  submittedDate: string;
  totalBaseAmount: number;
  tripId: string;
  updatedDate: string;
  userFirstName: string;
  userGUID: string;
  userId: number;
  userLastName: string;
  isSelected = false;
  hasViolation: boolean;
  unformattedAdvanceAmount: any;
  unformattedPettyCashAmount: any;
  recordType: any;
  claimTaggedTripList: any;
  claimStartDate: any;
  claimEndDate: any;
  advanceSummary:any;
  claimCategoryId: any;
  claimCategoryName: any;
  showIntegrationHistory: any;
  companyPaid: any;
  employeePaid: any;
  statusCode: any;
  subStatus: any;
  claimTypeId: any;
  claimTypeName: any;
  tripEstimateDetails: any;
  claimLevelVoilation: any;
  isSubmitWithoutApprovalFlow: any;
  sentBackAmount: any;
  canEditClaim: boolean;
  showProofofpayment: boolean;
  proofOfPaymentFileUrl: any;
  restrictClaimOnStartEndDates: boolean;
  amountAutoCalculated: boolean
  isBillNumberManditory: boolean
  constructor(list: any) {
    if (list) {
      this.claimId = list.claimId;
      this.claimTaggedTripList = list.claimTaggedTripList
      this.baseCurrencyCode = list.baseCurrencyCode;
      this.baseCurrencyId = list.baseCurrencyId;
      this.baseCurrencyIcon = list.baseCurrencyIcon;
      this.claimGUID = list.claimGUID;
      this.claimName = list.claimName;
      this.claimCode = list.claimCode;
      this.cliamNamecode = list.cliamNamecode;
      this.employeeId = list.employeeId;
      this.tripId = list.tripId;
      this.expenseIds = list.expenseIds;
      this.expenseList = list.expenseList;
      this.isLinkedToAdvancePayment = list.isLinkedToAdvancePayment;
      this.isLinkedToPettyCashPayment = list.isLinkedToPettyCashPayment;
      this.advancePaymentId = list.advancePaymentId;
      this.approverFirstName = list.approverFirstName;
      this.approverLastName = list.approverLastName;
      this.approverTypeName = list.approverTypeName;
      this.approverId = list.approverId;
      this.legalEntityId = list.legalEntityId;
      this.legalEntityGUID = list.legalEntityGUID;
      this.createdDate = list.createdDate;
      this.submittedDate = list.submittedDate;
      this.paidDate = list.paidDate;
      this.paymentStatus = list.paymentStatus;
      // this.totalAmount = list.totalAmount;
      this.noOfExpenses = list.noOfExpenses;
      this.status = list.status;
      this.statusDescription = list.statusDescription;
      this.totalBaseAmount = list.totalBaseAmount;
      this.userGUID = list.userGUID;
      this.userId = list.userId;
      this.approverFirstName = list.approverFirstName;
      this.approverId = list.approverId;
      this.approverLastName = list.approverLastName;
      this.updatedDate = list.updatedDate;
      this.updatedDate = list.updatedDate;
      this.userFirstName = list.userFirstName;
      this.userLastName = list.userLastName;
      this.hasViolation = list.hasViolation;
      this.unformattedAdvanceAmount = list.unformattedAdvanceAmount;
      this.unformattedPettyCashAmount = list.unformattedPettyCashAmount;
      this.recordType = list.recordType;
      this.claimStartDate = list.claimStartDate;
      this.claimEndDate = list.claimEndDate;
      this.advanceSummary = list.advanceSummary;
      this.claimCategoryId = list.claimCategoryId;
      this.claimCategoryName = list.claimCategoryName;
      this.showIntegrationHistory = list.showIntegrationHistory;
      this.showIntegrationHistory = list.showIntegrationHistory;
      this.companyPaid = list.companyPaid;
      this.employeePaid = list.employeePaid;
      this.statusCode = list.statusCode;
      this.subStatus = list.subStatus;
      this.claimTypeId = list.claimTypeId;
      this.claimTypeName = list.claimTypeName;
      this.tripEstimateDetails = list.tripEstimateDetails;
      this.claimLevelVoilation = list.claimLevelVoilation;
      this.isSubmitWithoutApprovalFlow = list.isSubmitWithoutApprovalFlow;
      this.sentBackAmount = list.sentBackAmount;
      this.canEditClaim = list.canEditClaim;
      this.showProofofpayment = list.showProofofpayment;
      this.proofOfPaymentFileUrl = list.proofOfPaymentFileUrl;
      this.restrictClaimOnStartEndDates = list.restrictClaimOnStartEndDates;
      this.amountAutoCalculated = list.amountAutoCalculated;
      this.isBillNumberManditory = list.isBillNumberManditory;
    }
  }
}

export class GetReportModal {
  claimId: number;
  claimGUID: string;
  legalEntityId: number;
  legalEntityGUID: string;
  userId: number;
  userGUID: string;
  rejectedExpenseList: Array<RejectedModel>;
  expenseList: Array<RejectedModel>;
  recordType: number;
  reason: any;
  expenseWithCodesList: any;
  isLinkedToAdvancePayment: boolean = true;
  isLinkedToPettyCashPayment: any;
  unformattedAdvanceAmount: any;
  unformattedPettyCashAmount: any;
  advanceApplied: any;
  pettyCashAmount: any;
  reimbursedAmount: any;
  claimTaggedTripList: any;
  skipBudgetCheckMessage:boolean = false;
  skipAnomolies:boolean = false;
  sapRequestData: any;
  editableFields: any;
}

export class RejectArray {
  expenseId: number;
  reason: string;
}

export class RejectedModel {
  expenseId: number;
  reason: string;
}
export class RemoveReportModal {
  legalEntityId: number;
  legalEntityGUID: string;
  userId: number;
  userGUID: string;
  claimIds = [];
  claimName: any;
  recordType: number;
  categoryType: any;
  skipBudgetCheckMessage:boolean = false;
  skipAnomolies:boolean = false;
  isSubmitWithoutApprovalFlow: any;
}

export class ReportSummaryRequestModal {
  claimId: number;
  claimGUID: string;
  recordType: number;
  viewType: number;
  isApproval: boolean;
}

export class EmailReportSummaryModal {
  claimId: number;
  claimGUID: string;
  approverId: number;
  approverGUID: number;
}
export class ReportSummaryResponseModal {
  advanceAppliedAmount: any;
  approverId: any;
  claimId: number;
  claimGUID: string;
  claimCode: string;
  claimName: string;
  isLinkedToAdvancePayment: boolean;
  isLinkedToPettyCashPayment: boolean;
  advancePaymentId: string;
  legalEntityId: number;
  legalEntityGUID: string;
  status: number;
  statusDescription: string;
  createdDate: string;
  updatedDate: string;
  userId: number;
  userFirstName: string;
  userLastName: string;
  employeeId: string;
  expenseIds: [];
  approverFirstName: string;
  approverLastName: string;
  approverTypeName: string;
  userGUID: string;
  noOfExpenses: number;
  baseCurrencyId: number;
  baseCurrencyCode: string;
  baseCurrencyIcon: string;
  totalBaseAmount: number;
  submittedDate: string;
  paymentStatus: number;
  paidDate: string;
  expenseList: Array<ExpenseListModel>;
  violationList: Array<reportViolationList>;
  reportHistoryList: Array<HistorySummaryModel>;
  recordType: number;
  advanceAmount: number;
  approvedAmount: number;
  unformattedApprovedAmount: number;
  unformattedPettyCashAmount: any;
  unformattedAdvanceAmount: any;
  pettyCashAmount: any;
  reason: string;
  reportPaymentDetails: reportPaymentDetailsModel;
  hasViolation: boolean;
  tripId: string;
  tripName: string;
  tripStartDate: string;
  tripEndDate: string;
  dynamoId: string;
  dynamoGUID: string;
  rejectedAmount: any;
  financeApproverIds: any;
  sapDocNumber: any;
  hasAssociatedExpense: boolean;
  paidByCompany: number;
  paidByEmployee: any;
  totalReportCostSummary: any
  isCategoryBasedEnabled: any
  claimTaggedTripList: any;
  claimStartDate: any;
  claimEndDate: any;
  approversList: Array<approversList>;
  userLocationName:any;
  userDepartment:any;
  userDesignation:any;
  availableBalance:number;
  settlementSummary:any;
  settlementList:[];
  advanceSummary:any;
  hideLinkToAdvanceSection:boolean;
  showSettlementButton:boolean;
  tripTravellersList: Array<any> = [];
  costCenterName:any;
  sapNumber:any;
  showRepushButton:boolean = false;
  claimCategoryId: any;
  claimCategoryName: any;
  showCheckBox: boolean = false;
  visibility: boolean = false;
  showIntegrationHistory: boolean = false;
  companyPaid: any;
  employeePaid: any;
  companyPaidApproved: any;
  employeePaidApproved: any;
  companyPaidRejected: any;
  employeePaidRejected: any;
  claimTypeId: any;
  claimTypeName: any;
  advanceTypeId: any;
  advanceTypeName: any;
  formattedBalance: any;
  isLinktoAdvace:boolean = false;
  isTripDirectSubmit = false;
  statusCode: any;
  subStatus: any;
  showSubStatus: boolean =false;

  isTripAdvance:boolean = false;
  isWithdraw = false;
  claimLevelVoilation: any;
  bandName: any;
  originalCurrencyCode:any;
  originalCurrencyIcon:any;
  merchantCode: any;
  merchantName: any;
  claimStartTime: any;
  claimEndTime: any;
  billingEntityName: any;
  showModifyAndRepushButton: boolean = false;
  isRepushAndApprove: boolean = false;
  tripEstimateDetails: any;
  isSubmitWithoutApprovalFlow: any;
  sentBackAmount: any;
  canEditClaim: boolean;
  dataPushMessage: any;
  overallClaimType: any;
  constructor(list: any) {
    if (list) {
      this.originalCurrencyCode = list.originalCurrencyCode
      this.originalCurrencyIcon = list.originalCurrencyIcon
      this.showSettlementButton=list.showSettlementButton
      this.hideLinkToAdvanceSection =list.hideLinkToAdvanceSection;
      this.availableBalance=list.availableBalance
      this.advanceAppliedAmount = list.advanceAppliedAmount
      this.claimId = list.claimId;
      this.claimTaggedTripList = list.claimTaggedTripList;
      this.claimGUID = list.claimGUID;
      this.claimCode = list.claimCode;
      this.claimName = list.claimName;
      this.tripId = list.tripId;
      this.tripName = list.tripName;
      this.tripStartDate = list.tripStartDate;
      this.tripEndDate = list.tripEndDate;
      this.dynamoId = list.dynamoId;
      this.dynamoGUID = list.dynamoGUID;
      this.recordType = list.recordType;
      this.isLinkedToAdvancePayment = list.isLinkedToAdvancePayment;
      this.isLinktoAdvace = list.isLinkedToAdvancePayment;
      this.isLinkedToPettyCashPayment = list.isLinkedToPettyCashPayment;
      this.advancePaymentId = list.advancePaymentId;
      this.legalEntityId = list.legalEntityId;
      this.legalEntityGUID = list.legalEntityGUID;
      this.status = list.status;
      this.statusDescription = list.statusDescription;
      this.createdDate = list.createdDate;
      this.updatedDate = list.updatedDate;
      this.userId = list.userId;
      this.userFirstName = list.userFirstName;
      this.userLastName = list.userLastName;
      this.employeeId = list.employeeId;
      this.approverId = list.approverId;
      this.approverFirstName = list.approverFirstName;
      this.approverLastName = list.approverLastName;
      this.approverTypeName = list.approverTypeName;
      this.userGUID = list.userGUID;
      this.noOfExpenses = list.noOfExpenses;
      this.baseCurrencyId = list.baseCurrencyId;
      this.baseCurrencyCode = list.baseCurrencyCode;
      this.baseCurrencyIcon = list.baseCurrencyIcon;
      this.totalBaseAmount = list.totalBaseAmount;
      this.submittedDate = list.submittedDate;
      this.paymentStatus = list.paymentStatus;
      this.paidDate = list.paidDate;
      this.expenseList = list.expenseList;
      this.approversList = list.approversList;
      this.reportHistoryList = list.reportHistoryList;
      this.expenseIds = list.expenseIds;
      this.advanceAmount = list.advanceAmount;
      this.approvedAmount = list.approvedAmount;
      this.rejectedAmount = list.rejectedAmount;
      this.unformattedApprovedAmount = list.unformattedApprovedAmount;
      this.unformattedPettyCashAmount = list.unformattedPettyCashAmount
      this.unformattedAdvanceAmount = list.unformattedAdvanceAmount;
      this.pettyCashAmount = list.pettyCashAmount;
      this.reportPaymentDetails = list.reportPaymentDetails;
      this.reason = list.reason;
      this.hasViolation = list.hasViolation;
      this.violationList = list.violationList;
      this.financeApproverIds = list.financeApproverIds;
      this.sapDocNumber = list.sapDocNumber;
      this.hasAssociatedExpense = list.hasAssociatedExpense;
      this.paidByCompany = list.paidByCompany;
      this.paidByEmployee = list.paidByEmployee;
      this.totalReportCostSummary = list.totalReportCostSummary;
      this.isCategoryBasedEnabled = list.isCategoryBasedEnabled;
      this.claimTaggedTripList = list.claimTaggedTripList;
      this.claimStartDate = list.claimStartDate;
      this.claimEndDate = list.claimEndDate;
      this.userLocationName = list.userLocationName;
      this.userDepartment = list.userDepartment;
      this.userDesignation = list.userDesignation;
      this.settlementSummary =list.settlementSummary;
      this.advanceSummary = list.advanceSummary;
      this.tripTravellersList = list.tripTravellersList;
      this.costCenterName=list.costCenterName;
      this.sapNumber=list.sapDocNumber;
      this.showRepushButton = list.showRepushButton;
      this.claimCategoryId = list.claimCategoryId;
      this.claimCategoryName = list.claimCategoryName;
      this.showCheckBox = list.showCheckBox;
      this.visibility = list.visibility;
      this.showIntegrationHistory = list.showIntegrationHistory;
      this.companyPaid = list.companyPaid;
      this.employeePaid = list.employeePaid;
      this.companyPaidApproved = list.companyPaidApproved;
      this.employeePaidApproved = list.employeePaidApproved;
      this.companyPaidRejected = list.companyPaidRejected;
      this.employeePaidRejected = list.employeePaidRejected;
      this.claimTypeId = list.claimTypeId;
      this.claimTypeName = list.claimTypeName;
      this.advanceTypeId = list.advanceTypeId;
      this.advanceTypeName = list.advanceTypeName;
      this.formattedBalance = list.formattedBalance;
      this.isTripDirectSubmit = list.isTripDirectSubmit;
      this.statusCode = list.statusCode;
      this.subStatus = list.subStatus;
      this.isTripAdvance = list.isTripAdvance;
      this.isWithdraw = list.isWithdraw;
      this.showSubStatus = list.showSubStatus;
      this.claimLevelVoilation = list.claimLevelVoilation;
      this.bandName = list.bandName;
      this.showModifyAndRepushButton = list.showModifyAndRepushButton;
      this.claimStartTime = list.claimStartTime;
      this.claimEndTime = list.claimEndTime;
      this.billingEntityName = list.billingEntityName;
      this.showModifyAndRepushButton = list.showModifyAndRepushButton;
      this.isRepushAndApprove = list.isRepushAndApprove;
      this.tripEstimateDetails = list.tripEstimateDetails;
      this.dataPushMessage = list.dataPushMessage;
      this.isSubmitWithoutApprovalFlow = list.isSubmitWithoutApprovalFlow;
      this.sentBackAmount = list.sentBackAmount;
      this.canEditClaim = list.canEditClaim;
      this.overallClaimType = list.overallClaimType;
    }
  }
}

export class reportViolationList {
  expenseIds: Array<ExpenseListModel>;
  statusId: number;
  violationCode: number;
  violationId: number;
  violationMessage: string
}
export class reportPaymentDetailsModel {
  advanceAmount: number;
  approvedAmount: number;
  totalBaseAmount: number;
  paymentModes: Array<paymentModesModel>

  reimbursedAmount: any;
  advanceAppliedAmount: any;
  comments: any;
}
export class paymentModesModel {
  paidAmount: number;
  paidBy: string;
  paidDate: string
  paymentMode: string
  referenceNumber: string;
}
export class HistorySummaryModel {
  userName: string;
  statusDescription: string;
  createdDate: string;
  comments: string;
}
export class approversList {
  categoryName: string;
  approverTypeName: string;
  approverName: string;
  employeeId: any;
  approverId: any;
  level: any;
  statusId: any;
  statusDescription: any;
}
export class ApprstatRequest {
  userId: number;
  userGUID: string;
  recordType: number;
  viewType: number;
  legalEntityId: number;
  legalEntityGUID: string;
}

export class ApprovialstatRes {
  label: string;
  count: number;
  statusIds: Array<number>;
  dropdown: boolean = false;
  showInPending: any = false;
  constructor(list) {
    this.label = list.label;
    this.count = list.count;
    this.statusIds = list.statusIds;
    this.showInPending = list.showInPending;
  }
}

export class RecordAdvancePayment {
  isAdminView: boolean;
  claimId: number;
  claimGUID: string;
  userId: number;
  userGUID: string;
  expenseId: number;
  recordType: number;
  reimbursedAmount: number;
  paymentMode: string;
  referenceNumber: string;
  comments: string;
  viewType: number;
  legalEntityId: string;
  legalEntityGUID: string;
  isTripAdvance:any;
  advanceList:any;
}

export class RecordReportPaymentModel {
  isAdminView: boolean;
  claimId: number;
  claimGUID: string;
  userId: number;
  userGUID: string;
  expenseId: number;
  recordType: number;
  paymentMode: string;
  referenceNumber: string;
  comments: string;
  isLinkedToAdvancePayment: boolean;
  isLinkedToPettyCashPayment: boolean;
  advanceApplied: number;
  reimbursedAmount: number;
  viewType: number;
  pettyCashAmount: number;
}

export class AddCommentModel {
  referenceId: number;
  referenceGUID: string;
  referenceType: number;
  userId: number;
  userGUID: string;
  comments: string;
  legalEntityId: number
}

export class GetCommentModel {
  referenceId: number;
  referenceGUID: string;
  referenceType: number;
  userGUID: string;
  userId: number;
}

export class FetchTripInfo {
  // tripId: any;
  legalEntityId: string;
  legalEntityGUID: number;
  companyId: number;
  companyGUID: string;
  searchText: any;
  tripStartDate: any;
  tripEndDate: any;
  pageNumber: any;
  pageSize: any;
  filterBy: TripFilterModal;
  claimStatus:any;
  viewType: number;
}
export class TripInfoPool {
  poolId: number;
  poolGUID: string;
}
export class GetTripInfo {
  dynamoId: any;
  dynamoGUID: string;
  legalEntityId: string;
  legalEntityGUID: number;
  companyId: number;
  companyGUID: string;
  tripId: any;
  tripGUID: any;
  requestId: any;
  requestGUID: any;
  productId: any;
}