
export class financeIntegration {
    legalEntityGUID: string;
    legalEntityId: number;
    financeIntegrationId: number;
    financeIntegrationGUID: string;
    financeIntegrationName: string;
    financeIntegrationCode:string;
    isDefault:boolean;
    mileageUnit: string;
    mileageRate: number;
    mileageRateCurrencyId: number;
    mileageRateCurrencyCode: string;
    mileageRateCurrencyIcon: string;
    pageNumber: number;
    pageSize: number;
    sortType: any;
    sortColumn: any;
    statusId: number;
    categoryId: any;
    categoryName = [];
    categoryList = [];
    claimTypeName:any;
    claimTypeId:any;
    
  }
  export class financeIntegrationList {
    legalEntityGUID: string;
    legalEntityId: number;
    categoryType: any;
    financeIntegrationId: number;
    financeIntegrationGUID: string;
    financeIntegrationName: string;
    financeIntegrationCode: string;
    showOnDashboard: boolean;
    mileageUnit: string;
    mileageRate: number;
    mileageRateCurrencyId: number;
    mileageRateCurrencyCode: string;
    mileageRateCurrencyIcon: string;
    pageNumber: number;
    isDefault:boolean;
    pageSize: number;
    sortType: any;
    sortColumn: any;
    statusId: number;
    categoryId: any;
    categoryName: any;
    categoryList = [];
    claimTypeName:any;
    claimTypeId:any;
    constructor(list) {
      this.legalEntityGUID = list.legalEntityGUID;
      this.legalEntityId = list.legalEntityId;
      this.financeIntegrationId = list.financeIntegrationId;
      this.financeIntegrationGUID = list.financeIntegrationGUID;
      this.financeIntegrationName = list.financeIntegrationName;
      this.financeIntegrationCode = list.financeIntegrationCode;
      this.mileageUnit = list.mileageUnit;
      this.mileageRate = list.mileageRate;
      this.mileageRateCurrencyId = list.mileageRateCurrencyId;
      this.mileageRateCurrencyCode = list.mileageRateCurrencyCode;
      this.mileageRateCurrencyIcon = list.mileageRateCurrencyIcon;
      this.statusId = list.statusId;
      this.isDefault=list.isDefault;
      this.categoryType = list.categoryType;
      this.categoryId = list.categoryId;
      this.categoryName = list.categoryName;
      this.categoryList=list.categoryList;
      this.claimTypeName=list.claimTypeName;
      this.claimTypeId=list.claimTypeId;
    }
  }
  