import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/Constants';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  modalInst=[];
  i=0;
  scriptfile: HTMLScriptElement;
  constructor(public httpClient: HttpClient) { }

  getSingleLegalEntity(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/legal-entity/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  fileUpload(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/file-upload/upload";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getAllLegalEntities(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/legal-entity/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getCategorylist(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/category/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getCatgryMasterlist(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/category/master/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getMerchantlist(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/merchant/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getMerchantlistByCategory(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/merchant/list-by-category";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCurrencylist(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/currency/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  statusList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/common/status";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  fliterList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/trip-details/filter";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getpolicyList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/for-user";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getViolations(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/policy-check";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getUsers(requestBody: any): Observable<any> {
    const url = Constants.BASE_URL + "v1/user-managment/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getForUserPolicyCatpolicyList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/for-user-category";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCategoryRule(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/category/rule/check";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

   downloadList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-jobs/export";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  downloadTripsList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-jobs/download-trips";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  
  downloadAnalyticsAll(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-jobs/export-analytics";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  downloadActiveUserSummary(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-jobs/active-user-summary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  downloadpoolStart(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-jobs/pool";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getStates(requestBody): Observable<any>{
    const url = Constants.BASE_URL + "v1/common/state/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getCountries(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/common/country/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getCities(requestBody): Observable<any> {
    const url = "https://uat.api.eva.travel/v1/common/cities";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCommonLookup(requestBody): Observable<any>{
    const url = Constants.BASE_URL + "v1/common-lookup/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getDistanceFromMapMyIndia(requestBody): Observable<any>{
    const url = Constants.DOMAIN_URL + "v1/mapmyindia/getDistance";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getUserPreferance(requestBody): Observable<any>{
    const url = Constants.DOMAIN_URL + "v1/user-preference/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  saveUserPreferance(requestBody): Observable<any>{
    const url = Constants.DOMAIN_URL + "v1/user-preference/save";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }


  bulkFinancePayment(requestBody): Observable<any>{
    const url = Constants.DOMAIN_URL + "/v1/finance-payment/bulk-finance-payment";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  // Mobile FC-365
 
  getExpenseOverviewData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/year-data-365";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSystemFieldsList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/company-fields/system-fields/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getUserFieldsList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/company-fields/custom-fields/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  saveSystemFields(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/company-fields/system-fields/save";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  saveCustomFields(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/company-fields/custom-fields/save";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getFieldsInfo(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/company-fields/fields-info";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getUserManagerList(requestBody: any): Observable<any> {
    const url = Constants.BASE_URL + "v1/user-managment/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  storeModal(x)
  {
        this.modalInst[this.i]=x;
        this.i++;
  }


  async downloadPdfUrl(url:any) {
    var errorCode = 1;
      /*
    if (url == "" || url== null) {
    } else {
        let target = "_system";
        let options: InAppBrowserOptions = {
          location: 'yes',
          hidden: 'no',
          clearcache: 'yes',
          clearsessioncache: 'yes',
          zoom: 'yes',
          hardwareback: 'yes',
          mediaPlaybackRequiresUserAction: 'no',
          shouldPauseOnSuspend: 'no',
          closebuttoncaption: 'Close',
          disallowoverscroll: 'no',
          toolbar: 'yes',
          enableViewportScale: 'no',
          allowInlineMediaPlayback: 'no',
          presentationstyle: 'pagesheet',
          fullscreen: 'yes'
      };

        this.pdfDownloadCall.create(url, target, options);
        errorCode = 0;
    }
 */
    return errorCode;

 }

 public googlescriptAdd(){
  this.scriptfile = document.createElement('script');
  let googlekey;
  let user = JSON.parse(sessionStorage.getItem("userInfo"));
  if (user) googlekey = user.googleKey; // "AIzaSyBzSd9D55dnMezjUNfoqelAplxaPkHn8q0" //user.googleKey;
  this.scriptfile.src = "https://maps.googleapis.com/maps/api/js?libraries=places&key=" + googlekey;
  this.scriptfile.type = 'text/javascript';
  this.scriptfile.async = true;
  this.scriptfile.charset = 'utf-8';
  this.scriptfile.id = 'googleAutoComplete';
    let googleautoComplete = document.getElementById('googleAutoComplete') as HTMLScriptElement;
    if(!googleautoComplete){
      document.getElementsByTagName('body')[0].appendChild(this.scriptfile);
    } else{
      googleautoComplete.src = this.scriptfile.src;
    }
}

getCustomReport(requestBody: any): Observable<any> {
  const url = Constants.DOMAIN_URL + "/v1/ai-booking-report-data-parsing";
  const body = requestBody;
  return this.httpClient.post(url, body);
}

encryptData(data: string): string {
  let key = environment.EncryptionKey;
  var keys = CryptoJS.enc.Utf8.parse(key);
  var iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(
      data,
      keys,
      {
          iv: iv,
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC
      }
  );

  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}


}
