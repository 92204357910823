import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { Title } from '@angular/platform-browser';
import { LoginModel } from 'src/app/models/Login/login.model';
import { AppConfig } from '../../config/app.config';
import { htmlValidator } from '../../validations/custom-validation';
import { ToastrService } from 'ngx-toastr';
import { MenuController, NavController } from '@ionic/angular';
import { CookieService } from "ngx-cookie-service";
import { ThemeService } from 'src/app/services/theme.service';
// import { Storage } from '@ionic/storage';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { environment } from 'src/environments/environment';
import { EmailApprovalM } from 'src/app/models/Common/common.model';
import { InAppBrowserOptions, InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { CommonService } from 'src/app/services/common-services/common.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ["./login.component.scss"],

})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    ssoLoginForm1: FormGroup;
    ssoLoginForm2: FormGroup;
    slider: any;
    submitted = false;
    buttonDisabled = false;
    slideOpts = {
        initialSlide: 0,
        slidesPerView: 1,
        autoplay: true
    };
    platformType: number;
    validationMsg: any;
    public expiredDate = new Date();
    loginModel = new LoginModel();
    ssoLogin: boolean = false;
    apiKey: string;

    showPassword = false;
    passwordToggleIcon = 'eye-close'
    applogo: any;
    pathlogo: any;
    userInfo: any;
    isPerDiemEnabled: any;
    ssoToken: any;
    favIcon = './assets/images/Logo-EP.svg';
    passwordVisible = false;
    isAndroidIOS: any;
    appLogo: any;
    showSSOForm1 = false;
    showSSOForm2 = false;
    domainName: any;

    constructor(

        private formBuilder: FormBuilder,
        private platformService: PlatformService,
        private authenticationService: AuthenticationService,
        private title: Title,
        public toaster: ToastrService,
        public router: NavController,
        public menuCtrl: MenuController,
        private cookieService: CookieService,
        private theme: ThemeService,
        // public storage: Storage,
        public sharedService: SharedService, private activeRouter: ActivatedRoute,
        private router1: Router,
        private theInAppBrowser: InAppBrowser,
        private commonService: CommonService
    ) {

        if (this.router1.url.includes('/ssoLogin/')) {
            this.ssoLogin = true;
            this.apiKey = this.activeRouter.snapshot.params['apiKey'];
            this.loginModel.token = this.apiKey;
            this.formSubmit();
        }
        if (this.router1.url.includes('/nessoLogin/')) {
            this.ssoLogin = true;
            this.ssoToken = this.activeRouter.snapshot.params['ssoToken'];
            this.loginModel.ssoToken = this.ssoToken;
            this.formSubmit();
        }
        console.log('router url :', this.router1);
        // if (this.router1.url.includes('/emailApproval/')) {
        //     console.log('return value is :', this.activeRouter.snapshot.params['ssoToken']);
        //     this.loginModel.ssoToken = this.activeRouter.snapshot.params['ssoToken'];
        //     let email_model = new EmailApprovalM();
        //     email_model.claimId = this.activeRouter.snapshot.params['claimId'];
        //     email_model.claimGUID = this.activeRouter.snapshot.params['claimGUID'];
        //     email_model.roleId = this.activeRouter.snapshot.params['roleId'];
        //     email_model.userId = this.activeRouter.snapshot.params['userId'];
        //     email_model.userGuid = this.activeRouter.snapshot.params['userGuid'];
        //     this.sharedService.setEmailApprovalInfo(email_model);
        //     this.buttonDisabled = true;
        //     this.emailApprovalformSubmit();
        // }

        this.submitted = false;
        this.slider = {
            isBeginningSlide: true,
            isEndSlide: false,
            slidesItems: [
                {
                    description: "70% of corporates use emails for business travel bookings. Eva is an email bot which automates travel bookings by email."
                },
                {
                    description: "Eva reads & understands the email requests using AI. The more Eva is used, the smarter it becomes and gives personalised travel options."
                },
                {
                    description: "Eva is more than an email bot. Eva comes with a web-based Self Booking Tool which seamlessly integrates with other travel ERPs."
                }
            ]
        };
    }

    ionViewWillEnter() {
        this.menuCtrl.enable(false);
        this.submitted = false;
        this.buttonDisabled = false;
        // this.title.setTitle('Login | NoExpenses');
        this.loginForm.reset();
        // sessionStorage.clear();
        // this.storage.clear();
    }

    ngOnInit() {
        this.appLogo = environment.appLogo;
        if (environment.appLogo == "expenses") {
            this.applogo = "noexp";
            this.pathlogo = './assets/images/Logo-EP.svg';
        } else if (environment.appLogo == "fc365") {
            this.applogo = "FC365logo";
            this.pathlogo = './assets/svg/FC365logo.svg';
        } else {
            this.applogo = "noexp";
            this.pathlogo = './assets/images/Logo-EP.svg';
        }
        let path = window.location.origin;
        // (path == 'http://localhost:8100')
        if (path == 'https://www.byjusezclaim.com') {
            this.title.setTitle(`Login | EZ CLaims`);
            this.favIcon = './assets/icon/byjus.svg'
            const favIcon = document.querySelector('#appIcon') as HTMLLinkElement;
            favIcon.href = this.favIcon;
            this.pathlogo = './assets/images/byjuslogo.svg';
        } else {
            this.title.setTitle(`Login | NoExpenses`);
            this.favIcon = './assets/images/Noexpfav.svg'
            const favIcon = document.querySelector('#appIcon') as HTMLLinkElement;
            favIcon.href = this.favIcon;
            // this.pathlogo = './assets/images/Logo-EP.svg';
        }

        this.validationMsg = AppConfig;
        this.platformType = this.platformService.getPlatformType();
        this.isAndroidIOS = this.platformService.isAndroidIOS();
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, htmlValidator]],
            password: ['', [Validators.required]],
            rememberMe: ['']
        });
        this.ssoLoginForm1 = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, htmlValidator]],
            // action: ['ssouser']
        });
        this.ssoLoginForm2 = this.formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]],
            domain: ['rane'],
            action: [''],
        });

        if (this.cookieService.check("remember")) {
            this.loginForm.patchValue({
                email: this.cookieService.get("email"),
                rememberMe: Boolean(this.cookieService.get("remember"))
            });
        }

    }

    get f() {
        return this.loginForm.controls;
    }

    get sf1() {
        return this.ssoLoginForm1.controls;
    }
    get sf2() {
        return this.ssoLoginForm2.controls;
    }
    refreshPage(event) {

        setTimeout(() => {
            event.target.complete();
            this.submitted = false;
            this.buttonDisabled = false
            this.loginForm.reset();
        }, 2000);

    }

    ssoForm1Submit() {
        this.submitted = true;
        if (!this.ssoLoginForm1.valid) {
            return;
        }
        this.buttonDisabled = true;
        this.authenticationService.authenticateUser(this.ssoLoginForm1.value).subscribe(res => {
            if (!res.isError && res.errorCode === 0 && res.payload) {
                if(res?.payload?.maintenanceCode == 1 || res?.payload?.maintenanceCode == 2) {
                    this.sharedService.setMaintenanceflag(true);
                }
                this.domainName = res?.payload?.ssoCode;
                this.buttonDisabled = false;
                if(this.domainName == 'RANEEVA') {
                    this.showSSOForm2 = true;
                    this.showSSOForm1 = false;
                    this.submitted = false;
                    this.ssoLoginForm2.controls.action.patchValue(res.payload?.ssoCode);
                } else {
                    this.getSSOUrl();
                }
            } else if (res.isError) {
                this.buttonDisabled = false;
                this.toaster.error(res.errorMessage, 'Error');
            }
        }, err => {
            this.buttonDisabled = false;
        });
    }

    ssoForm2Submit() {
        this.submitted = true;
        if (!this.ssoLoginForm2.valid) {
            return;
        }
        this.buttonDisabled = true;
        this.authenticationService.authenticateEvaUser(this.ssoLoginForm2.value).subscribe(res => {
            if (!res.isError && res.errorCode === 0 && res.payload) {
                this.ssoLogin = true;
                this.loginModel.action = 'ssouser';
                this.loginModel.ssoToken = res.payload;
                this.formSubmit();

            } else if (res.isError) {
                this.buttonDisabled = false;
                this.toaster.error(res.errorMessage, 'Error');
            }
        }, err => {
            this.buttonDisabled = false;
        });
    }

    getSSOUrl() {
        let obj = {
            email: this.ssoLoginForm1.controls.email.value,
            domain: 'eva'
        }
        this.authenticationService.getSSOurl(obj).subscribe(res => {
            console.log(res);
            
            if (!res.isError && res.errorCode == 0 && res.payload) {
                // TESTING
                // this.ssoLogin = true;
                // this.loginModel.action = 'ssouser';
                // this.loginModel.ssoToken = 'ODkxNDg=';
                // this.formSubmit();
                // TESTING
                let ref = this.theInAppBrowser.create(res.payload.url, '_blank', 'location=yes');

                ref.on('loadstop').subscribe(function (event) {
                    if (event.url.indexOf('ssoLogin') >= 0) {
                        var final = event.url.substring(event.url.lastIndexOf('/') + 1);
                        sessionStorage.setItem("SSO", final);
                        sessionStorage.setItem("SSOCalling", "false");
                        ref.close();
                    } else if (event.url.indexOf('air') >= 0 || event.url.indexOf('home') >= 0) {
                        sessionStorage.removeItem('SSO');
                        sessionStorage.setItem("SSOCalling", "true");
                        ref.close();
                    } else {
                        sessionStorage.removeItem('SSO');
                        sessionStorage.setItem("SSOCalling", "false");
                        ref.show();
                    }
                });
                let timer = setInterval(() => {
                    if (sessionStorage.getItem('SSO')) {
                        clearInterval(timer)
                        this.ssoLogin = true;
                        this.loginModel.action = 'ssouser';
                        this.loginModel.ssoToken = sessionStorage.getItem('SSO');
                        this.formSubmit();
                    }
                    if (sessionStorage.getItem("SSOCalling") == "true") {
                        clearInterval(timer)
                        this.getSSOUrl();
                    }
                }, 1000);

            } else if (res.isError) {
                this.buttonDisabled = false;
                this.toaster.error(res.errorMessage, 'Error');
            }
        }, err => {
            this.buttonDisabled = false;
            this.toaster.error(err.errorMessage, 'Error');
        });
    }

    loginWithSSO() {
        this.showSSOForm1 = true;
        this.showSSOForm2 = false;
    }

    loginWithPswrd() {
        this.showSSOForm1 = false;
        this.showSSOForm2 = false;
    }

    disableCopyPaste(event: Event) {
        if (environment.appLogo == "fc365" && this.platformService.isMobileDevice()) {
            event.preventDefault();
        }
    }

    formSubmit() {
        console.log('this.ssoLogin :');
        this.submitted = true;
        if (this.ssoLogin) {
            this.loginModel.email = null;
            this.loginModel.password = null;

            // this.loginModel.token = 'oQnFxDTW4iqd--zwfCOmNfkWptY14D--qFPfN7q5uo9j6OwlRqLRt--EqSOGG4cZ+4SQGd5cDgQtKj2c3f4BuH1nynHUiA2Xf5rUpn0Za2TRRN0MrmHyfpjkdnjlNmYlBWWyeBA5pIETYJLr2viEsMiHYmrhjoLgXITjAioIQZHQq0='
        } else {
            if (!this.loginForm.valid) {
                return;
            }
        }
        this.buttonDisabled = true;
        let obj = JSON.parse(JSON.stringify({ ...this.loginModel }));
        if (!this.ssoLogin) {
            obj.email = this.commonService.encryptData((this.loginModel.email)?.toString());
            obj.password = this.commonService.encryptData((this.loginModel.password)?.toString());
            obj.isEncrypted = true;
        }
        this.authenticationService.authenticateUser(obj).subscribe(res => {
            if (!res.isError && res.errorCode === 0 && res.payload) {
                if(res?.payload?.maintenanceCode == 1 || res?.payload?.maintenanceCode == 2) {
                    this.sharedService.setMaintenanceflag(true);
                }
                sessionStorage.setItem("isAuthenticated", JSON.stringify(true));
                sessionStorage.setItem("isAttachmentMandatory", JSON.stringify(res?.payload?.isAttachmentMandatory));
                // localStorage.setItem("isAttachmentMandatory", JSON.stringify(res.payload.isAttachmentMandatory));
                sessionStorage.setItem("userInfo", JSON.stringify(res.payload));
                localStorage.setItem("userInfo", JSON.stringify(res.payload));
                // this.sharedService.setUserInfo(true);
                this.menuCtrl.enable(true, 'mainMenu');
                this.menuCtrl.enable(false, 'settingMenu');
                this.isRememberedMe();
                this.buttonDisabled = false;
                sessionStorage.setItem("current-lang", res.payload.baseLanguage);
                this.authenticationService.status.next(true);
                this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                this.getperdiemstatus(this.userInfo);

                const obj = {
                    primary: res.payload.primaryColor,
                    secondary: res.payload.secondaryColor,
                    fontFamily: res.payload.font
                };
                sessionStorage.setItem('theme', JSON.stringify(obj));
                this.theme.applyTheme(obj);
                if (res.payload.forceChangePassword) {
                    this.router.navigateRoot(['changepassword']);
                } else {
                    //this.router.navigateRoot(['dashboard']);
                    // console.log('isAndroidIOS _________ ', this.isAndroidIOS);
                    if (this.isAndroidIOS == 1) {
                        this.router.navigateRoot(['dashboard']);
                    } else if (this.isAndroidIOS == 2) {
                        sessionStorage.setItem("isadmin", JSON.stringify(false));
                        this.sharedService.setUserToggle(!false);
                        // this.router.navigateRoot(['camera']); //murthySir changes
                        if (environment.appLogo == "fc365") {
                            let fc365Obj = res.payload.appsUrl;
                            if (fc365Obj) {
                                let obj365url = fc365Obj[0].value;
                                localStorage.setItem('fc365', JSON.stringify(true));
                                localStorage.setItem('fc365url', JSON.stringify(obj365url));
                                this.router.navigateRoot(['dashboardfc365']);
                            } else {
                                this.router.navigateRoot(['dashboard']);
                            }
                        } else {
                            this.router.navigateRoot(['dashboard']);
                        }

                        this.sharedService.setUserInfo(true);

                    }
                }
            } else if (res.isError && res.errorCode === 2) {
                this.buttonDisabled = false;
                this.toaster.error(res.errorMessage, 'Error');
            }
        });
    }

    // emailApprovalformSubmit() {
    //     this.buttonDisabled = true;
    //     this.authenticationService.authenticateUser(this.loginModel).subscribe(res => {
    //         if (!res.isError && res.errorCode === 0 && res.payload) {
    //             sessionStorage.setItem("isAuthenticated", JSON.stringify(true));
    //             sessionStorage.setItem("isAttachmentMandatory", JSON.stringify(res.payload.isAttachmentMandatory));
    //             sessionStorage.setItem("userInfo", JSON.stringify(res.payload));
    //             localStorage.setItem("userInfo", JSON.stringify(res.payload));
    //             sessionStorage.setItem("mainheadehidden",JSON.stringify(true));
                
    //             this.menuCtrl.enable(true, 'mainMenu');
    //             this.menuCtrl.enable(false, 'settingMenu');
    //             this.isRememberedMe();
    //             this.buttonDisabled = false;
    //             sessionStorage.setItem("current-lang", res.payload.baseLanguage);
    //             this.authenticationService.status.next(true);
    //             this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //             this.getperdiemstatus(this.userInfo);

    //             const obj = {
    //                 primary: res.payload.primaryColor,
    //                 secondary: res.payload.secondaryColor,
    //                 fontFamily: res.payload.font
    //             };
    //             sessionStorage.setItem('theme', JSON.stringify(obj));
    //             this.theme.applyTheme(obj);
    //             sessionStorage.setItem("isEmailApproval", JSON.stringify(true));
    //             this.router.navigateRoot(['approvals']);
    //         } else if (res.isError && res.errorCode === 2) {
    //             this.buttonDisabled = false;
    //             this.toaster.error(res.errorMessage, 'Error');
    //         }
    //     });
    // }

    getperdiemstatus(item) {
        const requestObj = {
            "legalEntityId": item.legalEntityId,
            "legalEntityGUID": item.legalEntityGuId,
        }
        this.authenticationService.getUserConfiguration(JSON.stringify(requestObj)).subscribe(data => {
            if (data.errorCode == 0) {
                sessionStorage.setItem("isPerDiemEnabled", JSON.stringify(data.payload));
            }
        });
    }

    // Function for Remember Me Check Box
    isRememberedMe() {
        if (this.loginForm.controls?.rememberMe?.value) {
            this.expiredDate.setDate(this.expiredDate.getDate() + 10);
            this.cookieService.set("email", this.loginForm.controls.email?.value, this.expiredDate);
            this.cookieService.set("remember", String(this.loginForm.controls.rememberMe?.value), this.expiredDate);
        } else {
            this.cookieService.delete("email");
            this.cookieService.delete("remember");
        }
    }

    showPwd() {

    }

    navigateToSignup() {
        this.router.navigateRoot(['signup']);
    }

    returnForgot() {
        this.router.navigateRoot(['forgotpassword']);
    }

    togglePassword() {
        if (this.loginModel.password == '' || this.loginModel.password == null) {
        } else {
            this.showPassword = !this.showPassword;
            if (this.passwordToggleIcon == 'eye-close') {
                this.passwordToggleIcon = 'eye-open'
            } else {
                this.passwordToggleIcon = 'eye-close'
            }
        }
    }

}
