export class Category {
  legalEntityGUID: string;
  legalEntityId: number;
  categoryType: any;
  categoryId: number;
  categoryGUID: string;
  categoryName: string;
  categoryTypeName: string;
  showOnDashboard: boolean;
  categoryDescription: string;
  mileageUnit: string;
  mileageRate: number;
  mileageRateCurrencyId: number;
  mileageRateCurrencyCode: string;
  mileageRateCurrencyIcon: string;
  pageNumber: number;
  pageSize: number;
  sortType: any;
  sortColumn: any;
  status: number;
  parentCategory: any;
  parentCategoryId: any;
  claimTypeIds: any;
  claimTypeNames: any;
  expenseTypeOptionIds: any;
  expenseTypeOptionNames: any;
  isStartEndDateEnabled: boolean = false;
  isSpentLocationManditory: boolean = false;
  isPolicyCalcPerNight: boolean = false;
  categoryRestricted:string = '';
  isPerDayEnabled = false;
  restrictedCategories: any = [];
  allowAmountEdit: boolean = false;
  isBillNumberManditory: boolean = false;
}
export class CategoryList {
  legalEntityGUID: string;
  legalEntityId: number;
  categoryType: any;
  categoryId: number;
  categoryGUID: string;
  categoryName: string;
  categoryTypeName: string;
  showOnDashboard: boolean;
  categoryDescription: string;
  mileageUnit: string;
  mileageRate: number;
  mileageRateCurrencyId: number;
  mileageRateCurrencyCode: string;
  mileageRateCurrencyIcon: string;
  pageNumber: number;

  pageSize: number;
  sortType: any;
  sortColumn: any;
  status: number;
  parentCategoryId: any;
  selectedClaimTypeIds: any;
  selectedExpenseTypeIds: any;
  selectedEnableCategoryIds: any;
  isPolicyCalcPerNight:boolean = false;
  isSpentLocationManditory:boolean = false;
  isStartEndDateEnabled:boolean = false;
  categoryRestricted:any;
  isCategoryResricted:any;
  category:any;
  isPerDayEnabled = false;
  restrictClaimOnStartEndDates:any;
  isProofOfPaymentAttachmentRequired: boolean = false;
  allowAmountEdit: boolean = false;
  isBillNumberManditory: boolean = false;
  isServiceResricted: boolean = false;
  constructor(list) {
    this.legalEntityGUID = list.legalEntityGUID;
    this.legalEntityId = list.legalEntityId;
    this.categoryType = list.categoryType;
    this.categoryId = list.categoryId;
    this.categoryGUID = list.categoryGUID;
    this.categoryName = list.categoryName;
    this.categoryTypeName = list.categoryTypeName;
    this.showOnDashboard = list.showOnDashboard;
    this.categoryDescription = list.categoryDescription;
    this.mileageUnit = list.mileageUnit;
    this.mileageRate = list.mileageRate;
    this.mileageRateCurrencyId = list.mileageRateCurrencyId;
    this.mileageRateCurrencyCode = list.mileageRateCurrencyCode;
    this.mileageRateCurrencyIcon = list.mileageRateCurrencyIcon;
    this.status = list.status;
    this.parentCategoryId = list.parentCategoryId;
    this.isPolicyCalcPerNight = list.isPolicyCalcPerNight;
    this.isSpentLocationManditory = list.isSpentLocationManditory;
    this.isStartEndDateEnabled = list.isStartEndDateEnabled;
    this.categoryRestricted = list.categoryResricted;
    this.isCategoryResricted = list.isCategoryResricted;
    this.category = list.category;
    this.isPerDayEnabled = list.isPerDayEnabled;
    this.restrictClaimOnStartEndDates = list.restrictClaimOnStartEndDates;
    this.isProofOfPaymentAttachmentRequired = list.isProofOfPaymentAttachmentRequired;
    this.allowAmountEdit = list.allowAmountEdit;
    this.isBillNumberManditory = list.isBillNumberManditory;
    this.isServiceResricted = list.isServiceResricted;
  }
}
