import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { ThemeService } from 'src/app/services/theme.service';
import { SettingsService } from 'src/app/services/settings-services';
import { CommonService } from 'src/app/services/common-services/common.service';

@Component({
  selector: 'app-more-option365',
  templateUrl: './more-option365.component.html',
  styleUrls: ['./more-option365.component.scss'],
})
export class MoreOption365Component implements OnInit {

  userInfo: any;
  productsListdata:any;
  constructor(public router: NavController,
    private sharedService: SharedService,
    private theme: ThemeService,
    public authenticationService: AuthenticationService,
    private commonService: CommonService,
    private settingService: SettingsService,
    ) {
    this.authenticationService.setShowHeaderEV(true);
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.getCustomFieldsInfo();
    this.getCostCenters();
    this.productsListdata = [
      {
        title: 'Claims',
        iconimg: 'assets/svg/expenses.svg',
        url: "expenses",
        id: 2
      },
      {
        title: 'Approvals',
        iconimg: 'assets/svg/approval.svg',
        url: "approvals",
        id: 5
      },
      {
        title: 'Advance',
        iconimg: 'assets/svg/menuAdvance.svg',
        url: "advance",
        id: 4
      },
      {
        title: "Settings",
        iconimg: "assets/images/settingIcon.svg",
        id: 10,
        "childMenu": [
          {
            title: 'My Profile',
            iconimg: 'assets/images/profileIcon.svg',
            url: "settings/userpersonalInfo",
            id: 14
          },
          {
            title: "Change Password",
            iconimg: "assets/svg/menuChangePW.svg",
            url: "changepassword",
            id: 7,
          },
          // {
          //   title: 'Vehicle Preferences',
          //   iconimg: 'assets/svg/edit-icon.svg',
          //   url: "mileagedefaults",
          //   id: 8
          // },
        ]
      }
    ]

    if(this.userInfo?.showCards) {
      this.productsListdata.splice(1, 0, {
        title: 'Cards',
        iconimg: 'assets/images/mycards-icon.svg',
        url: "mycards",
        id: 16
      });
    }
    if(this.userInfo?.showTripDetails) {
      this.productsListdata.splice(1, 0, {
        title: 'My Trips',
        iconimg: 'assets/svg/trip-icon.svg',
        url: "mytrips",
        id: 15
      });
    }

    this.productsListdata.forEach(p => {
      if (p.title == 'Settings' && this.userInfo.isEnableTrackMileage) {
        p.childMenu.push({ title: 'Vehicle Preferences', iconimg: 'assets/svg/edit-icon.svg', url: "mileagedefaults", id: 8 })
      }
    })

  }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    sessionStorage.removeItem('selectedValue');
  }

  navigateTo(page) {
    sessionStorage.setItem('approvalData',null);
    sessionStorage.setItem('current_modal',JSON.stringify({"name": "list","data": {}}));

    // sessionStorage.setItem("isAuthenticated", JSON.stringify(true));
    sessionStorage.setItem('fc365', JSON.stringify(true));

    if (page.url == 'mileagedefaults') {
        localStorage.setItem('fromUP', 'menu');
    }
    if ((page.url)) {
        this.router.navigateRoot(["/" + page.url]);
    }
    // if(page.title == 'Travel'){
    //     // window.open(page.webview, "_system", "location=yes");
    //     const options: InAppBrowserOptions= {
    //         zoom: 'no',
    //         hideurlbar: 'yes', // hide the url toolbar
    //         hidenavigationbuttons: 'yes', // hide navigation buttons back/forward
      
    //       }
    //     this.theInAppBrowser.create(page.webview, '_self',  options );
    // }

}
backBtn(){
  this.router.navigateRoot(["/" + 'dashboard']);
}

  logout() {
    let domailUrl = this.userInfo.domainURL;
    if(domailUrl && domailUrl !=''){
        let myVal = domailUrl + 'login'
        window.location.href = myVal;
    }else{
        this.router.navigateRoot(["login"]);
    }

    sessionStorage.clear();
    localStorage.clear();
    this.sharedService.setUserInfo(false);
    this.sharedService.setRefreshData(false);
    this.sharedService.setExpenseData(null);
    this.sharedService.setCompanyInfo(null);
    this.sharedService.setMapData(null);
    this.sharedService.setPreCategory(null);
    this.sharedService.setPeningApprovalCount(null);
    this.theme.applyTheme(null);
  }

  getCustomFieldsInfo() {
    const requestModel = {
      "legalEntityGUID": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.commonService.getFieldsInfo(requestModel).subscribe((response) => {
      sessionStorage.setItem('customFieldsInfo', JSON.stringify(response.payload));
    })
  }

  getCostCenters() {
    const requestModel = {
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.settingService.getCostCenterList(requestModel).subscribe((res) => {
      sessionStorage.setItem('costCenters', JSON.stringify(res?.payload?.costCenterList || []));
    })

    this.settingService.getProjectCodesList(requestModel).subscribe((res) => {
      sessionStorage.setItem('projectCodes', JSON.stringify(res?.payload?.projectList || []));
    })

    this.settingService.getDepartmentsist(requestModel).subscribe((res) => {
      sessionStorage.setItem('departments', JSON.stringify(res?.payload?.departmentList || []));
    })
    this.settingService.getBrandsList(requestModel).subscribe((res) => {
      sessionStorage.setItem('bands', JSON.stringify(res?.payload?.bandList || []));
    })
    this.settingService.getDesignationsList(requestModel).subscribe((res) => {
      sessionStorage.setItem('designations', JSON.stringify(res?.payload?.designationList || []));
    })
  }
}
