import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  TemplateRef,
  NgZone,
  HostListener,
} from "@angular/core";
import {
  AlertController,
  IonInput,
  IonSlides,
  LoadingController,
  ModalController,
  NavController,
  Platform,
} from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { PlatformService } from "src/app/services/platformbase.service";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from "@angular/forms";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../config/app.config";
// import { IonicSelectableComponent } from "ionic-selectable";
import { ImageViewComponent } from "../image-view/image-view.component";
import {
  BulkItemModel,
  BulkModel,
  bulkOCRAttachment,
  CapturedImageModal,
  checkpointCon,
  checkpointModel,
  CheckPointsList,
  CitiesModel,
  claimViolationsModel,
  DistanceobjetModel,
  ExpenseAttachmentsModel,
  ExpenseItemsModel,
  ExpenseListModel,
  ExpenseModel,
  FinanceIntCodeModel,
  GetExpenseModels,
  HsnCodeModel,
  MileageBulk,
  MileageBulkItemModel,
  NewTripModel,
  ocrAttachment,
  ocrReqModel,
  RuleCheckModel,
  stateListModel,
  TaxCodeModel,
} from "src/app/models/Expenses/expenses.model";
import { CommonService } from "src/app/services/common-services/common.service";
import {
  CategoryAndMerchantModel,
  CategoryList,
  CurrencyList,
  ExpenseAndReportModel,
  FilterModal,
  MerchantList,
  TripFilterModal,
} from "src/app/models/Common/common.model";
import { ToastrService } from "ngx-toastr";
import { ExpensesService } from "src/app/services/expenses.service";
import { DatechangeService } from "src/app/services/common-services/datechange.service";
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from "@angular/material/datepicker";
import { ReportsService } from "src/app/services/reports.service";
import {
  FetchTripInfo,
  GetTripInfo,
  Report,
  ReportListResModel,
} from "src/app/models/Reports/reports.model";
import { Constants } from "src/app/Constants";
import { Subject, Subscription, BehaviorSubject } from "rxjs";
import * as moment from "moment";
import { SharedService } from "src/app/services/common-services/shared.service";
import { CustomDatePickerComponent } from "../../custom-date-picker/custom-date-picker.component";
import { CustomSelectDropdownComponent } from "../../custom-select-dropdown/custom-select-dropdown.component";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SettingsService } from "src/app/services/settings-services";
import { SelectLocationComponent } from "../../select-location/select-location.component";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { PerdiemModel } from "src/app/models/Expenses/perdiem.model";
import { PerdiemService } from "src/app/services/common-services/perdiem.service";
import { debounceTime, distinctUntilChanged, share, switchMap } from "rxjs/operators";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GpsTrackingComponent } from "../../gps-tracking/gps-tracking.component";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { CustomDateService } from "src/app/services/custom-date.service";
import { CompanyService } from "src/app/services/company.service";
// import { CameraPreview } from '@ionic-native/camera-preview/ngx';


declare var google;

@Component({
  selector: "app-expenseandmileagecomponent",
  templateUrl: "./expenseandmileage.component.html",
  styleUrls: ["./expenseandmileage.component.scss"],
  providers: [NgbCarouselConfig],
})
export class ExpenseAndMileageComponent implements OnInit, AfterViewChecked {
  fromCitiesList = [];
  @ViewChild("togoglemapCity", { static: false }) googleSearchAutoComplete: ElementRef;
  @ViewChild("mileageFromGoogleSearch", { static: false }) mileageFromGoogleSearch: ElementRef;
  @ViewChild("mileageToGoogleSearch", { static: false }) mileageToGoogleSearch: ElementRef;
  @ViewChild("perdiemtogoglemapCity", { static: false }) perdiemgoogleSearchAutoComplete: ElementRef;
  @ViewChild("dynamicTemp", { static: false }) private dynamicTemp;
  showcitySearchListSpinner: boolean = false;
  sessionTokenEnable = true;
  sessionToken: any;
  requestType: any;
  private geocoder: any;
  public showFromList: boolean = false;

  // @ViewChild("selectComponent") selectComponent: IonicSelectableComponent;
  // @ViewChild("selectMerchantComponent")
  // selectMerchantComponent: IonicSelectableComponent;
  // @ViewChild("selectCurrencyComponent")
  // selectCurrencyComponent: IonicSelectableComponent;
  // @ViewChild("vehicleComponent") vehicleComponent: IonicSelectableComponent;
  // @ViewChild("reportComponent") reportComponent: IonicSelectableComponent;
  // @ViewChild("reportExpComponent") reportExpComponent: IonicSelectableComponent;
  // @ViewChild("mielageCurrency") mielageCurrency: IonicSelectableComponent;
  @ViewChild("categoryfocusOff", { static: false }) categoryfocus: ElementRef;
  @ViewChild("pdCitiesfocusOff", { static: false }) pdCitiesfocusOff: ElementRef;
  @ViewChild("paidByfocusOff", { static: false }) paidByfocus: ElementRef;
  @ViewChild("mileagecategory", { static: false }) mileagecategoryId: ElementRef;
  @ViewChild("financeIntCodefocusOff", { static: false }) financeIntCodefocusOff: ElementRef;
  @ViewChild("taxCodefocusOff", { static: false }) taxCodefocusOff: ElementRef;
  @ViewChild("hsnCodefocusOff", { static: false }) hsnCodefocusOff: ElementRef;
  @ViewChild("merchantfocusOff", { static: false }) bulkMerchant: ElementRef;
  @ViewChild("currencyfocusOff", { static: false }) currencyfocusOff: ElementRef;
  @ViewChild("reportfocusOff", { static: false }) reportfocusOff: ElementRef;
  @ViewChild("claimCategoryfocusOff", { static: false }) claimCategoryfocus: ElementRef;
  @ViewChild("claimTypefocusOff", { static: false }) claimTypefocus: ElementRef;
  @ViewChild("target") private myScrollContainer: ElementRef;
  @ViewChild("fileUpload", { static: false }) fileUploadInput: ElementRef;
  @ViewChild("exppicker", { read: undefined, static: false })
  exppicker: MatDatepicker<Date>;

  @ViewChild("perdiempicker", { read: undefined, static: false })
  perdiempicker: MatDatepicker<Date>;

  @ViewChild("perdiemStartDate", { read: undefined, static: false })
  _perdiemStartDate: MatDatepicker<Date>;
  @ViewChild("perdiemEndDate", { read: undefined, static: false })
  _perdiemEndDate: MatDatepicker<Date>;
  @ViewChild("mileagepicker", { read: undefined, static: false })
  mileagepicker: MatDatepicker<Date>;
  @ViewChild("incompleteTemp", { static: false }) private incompleteTemp;
  @ViewChild("ocrOverrideTemp", { static: false }) private ocrOverrideTemp;
  @ViewChild("violationsPopup", { static: false }) private violationsPopup;
  @ViewChild("currency", { static: false }) public currencyAutoCompleteReference: ElementRef;
  @ViewChild('category', { static: false }) categoryAutoCompleteReference: ElementRef;
  @ViewChild('category', { static: false }) category: ElementRef;
  @ViewChild("custom", { static: false }) public customAuto: ElementRef;
  public vehicalAutoCompleteReference: ElementRef;
  @ViewChild("matFormFieldParent") matFormFieldParentElement: ElementRef;
  @ViewChild("bulkmatFormFieldParent") bulkmatFormFieldParent: ElementRef;
  @ViewChild("removedeleteTemp", { static: false }) private removedeleteTemp;
  @ViewChild("oneCard", { static: false }) private oneCard;
  @ViewChild("FieldsMissing", { static: false }) private FieldsMissingTemp;
  @ViewChild("futureDate", { static: false }) private futureDateTemp;
  @ViewChild("expenseOutsideClaimDatesError", { static: false }) private expenseOutsideClaimDatesError;
  @ViewChild("mobileNumberMismatch", { static: false }) private mobileNumberMismatch;
  @ViewChild("proofOfPaymentError", { static: false }) private proofOfPaymentError;
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() cancel: EventEmitter<number> = new EventEmitter<number>();
  @Output() save: EventEmitter<number> = new EventEmitter<number>();
  @Input() type;
  @Input() duplicate;
  @Input() claimId;
  @Input() claimGUID;
  @Input() claimName?;
  @Input() report;
  @Input() imageArray;
  @Input() isEdit;
  @Input() isGPSShow;
  @Input() GPSData;

  expenseId: any;
  expenseGUID: any;
  recordType: any;
  modalRef: BsModalRef;
  modalRefDynamic: BsModalRef;
  addReport = false;
  addExpNewReport = false;
  active = '';
  platformType: number;
  submitExpense: boolean = false;
  submitMileage: boolean = false;
  submitPerdiem: boolean = false;
  submitExp = false;
  validationMsg: any;
  mileageForm: FormGroup;
  expenseForm: FormGroup;
  perdiemForm: FormGroup;
  capturedImage: any;
  filename: any;
  showText = false;
  photos: any;
  categoryList: any;
  categorytempList: any;
  perdiemtempList: any;
  merchantList: any;
  merchanttempList: any;
  currencyList: any;
  currencytempList: any;
  reportList: any;
  reporttempList: any;
  slideOpts = {
    zoom: false,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 10,
    initialSlide: 0,
  };
  sliderOpts = {
    zoom: false,
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 10,
    initialSlide: 0,
  };
  userInfo: any;
  fileType: boolean = false;
  image: any = "";
  uploadUrl: any;
  attachment: boolean;
  buttonDisabled: boolean = false;
  buttonDownloadDisable: boolean = false;
  buttoncloseDisabled = false;
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imgContent: string = "";
  minDate: Date;
  maxDate: Date;
  sumOfamount: Subscription;
  total: number = 0;
  distanceIn: any;
  fileName: any;
  uploadedfiles = [];
  imagesCount = 0;
  getExpenseModels = new GetExpenseModels();
  filterModal = new FilterModal();
  editlist: any;
  updatetitle = false;
  mileageRate: string = "";
  expense: any;
  items: any;
  maxxDate: any;
  @ViewChild(IonSlides) slides: IonSlides;
  activeIndex: any = 0;
  expcurrencySymbol: string;
  milecurrencySymbol: string;
  selectedBaseCurr: any;
  exchangeAmt: number = 0;
  distanceVal: any;
  milageAmt: any;
  milRate: any;
  milageTotalAmt: number = 0;
  milageCurrencyCode: any;
  milageConvRate: any;
  expTotalAmt: number;
  expConvRate: any;
  expcurrencyIcon: any;
  merchant: any;
  merchanttemp: any;
  searchFail = false;
  merchSelect = false;
  selectedDate: string;
  selectedCategories: any;
  selectedMerchants: any;
  selectedCurrencies: any;
  selectedClaims: any;
  merchantButtonDisable = true;
  reportSlect: boolean = false;
  editreport: any;
  extractData: any;
  extractDataIndex: any;
  ocrResattachments: any;
  proofid: any;
  imgNum = 1;
  imageIndex: number;
  imgUpload = false;
  dummyList = [1, 2, 3, 4];
  tripsdummyList = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  fromPathSubscr: any;
  fromPathInfo: any;
  fromPage: any;
  expenseArray: any;
  currentIndex = 0;
  isCloseModal = false;
  policyList: any;
  receiptWarning = false;
  receiptCatWarning = false;
  loginUserAttchementChecking: boolean = false;
  categDesc = false;
  expenseWarning = false;
  expenseCatWarning = false;
  expensePolicyAmount = false;
  expensePolicyReceipt = false;
  expenseRuleWarning = false;
  categoryLimit = true;
  public lat: Number = 17.385;
  public lng: Number = 78.4867;
  public origin: any;
  public destination: any;
  toCitiesList = [];
  fromOrto: any;
  showToMapField = false;
  showFromMapField = false;
  perdiemshowFromMapField = false;
  showFromLocation: boolean = false;
  showEndLocation: boolean = false;
  showDistanceFromMapField = false;
  isCheckPoints: boolean = false;
  showMoreViolations = false;
  displayViolations = [];
  distanceto: any;
  distancetoobj = new DistanceobjetModel();
  distancefromobj = new DistanceobjetModel();
  distancefrom: any;
  distanceViolationMiles = false;
  totaldistance: string = "0";
  zoom: number = 12;
  fromPettyCash = false;
  editExpense: false;
  milageCategoryList: any;
  milageCategoryListWarning: boolean = false;
  searchFreetext: string = "";
  allUserList = [];
  selectedUserList = [];
  secondTabList = [];
  thirdTabList = [];
  public firstname: string = "J";
  divAmt: number;
  leftAmt: number = 0;
  totalinputAmt = 0;
  totalinputperc: any;
  totalinputPerc: any;
  leftPerc: number = 0;
  percError: any;
  amtError: boolean = false;
  expenseToalAmt: number;
  matTabIndex = 0;
  attachFilename: any;
  tripdetailview: any;
  listLoaderDic = [
    { noApi: true, loader: false },
    { noApi: true, loader: false },
    { noApi: true, loader: false },
  ];
  globalIndex: any;
  mileageIndex: any;
  currentDateStr: any = moment(new Date()).format("DD-MM-YYYY");
  maxDateStr: any = moment(new Date()).format("YYYY-MM-DD");
  minDateStr: any = moment(new Date().getTime())
    .subtract(2000, "years")
    .format("YYYY-MM-DD");
  isReadOnly = false;
  defaultTabSelection: boolean = false;

  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showCurrencyList: boolean = false;
  showFinanceCodeList: boolean = false;
  showTaxCodeList: boolean = false;
  showHsnCodeList: boolean = false;
  baseCurrencyList: any;
  selectedCurrency: any;
  getExpenseDetails: any;
  staticValue: boolean = false;
  backClick = false;
  selectedIndex = 0;
  selectedLocationId = null;
  stateIds = null;
  selectedstateId = null;
  dateDisableValue: boolean = false;
  test = [1, 2, 4, 5];
  bulkupload: boolean = false;
  bulkExpenseDate: any;
  mapImagefrom: boolean = false;
  travelTripdata = {
    tripId: "",
    tripName: "",
    tripEndDate: "",
    tripStartDate: "",
  };
  hideColumns = [];
  expenseModel = new ExpenseModel();
  dummyexpenseModel = new ExpenseModel();
  totalBulkModel = new BulkModel();
  totalMileageBulkModel = new MileageBulk();
  perdiemModel = new PerdiemModel();
  CategoryMerchantModel = new CategoryAndMerchantModel();
  expenseAttacModel = new ExpenseAttachmentsModel();
  totalBulkForm: FormGroup;

  singleTravelTrip: boolean = false;
  singleTravelTripDetails: any;
  multiTravelTrip: boolean = false;
  isAssociated: boolean = false;
  expenseTaggedTripList: any;
  tripsList = [];
  selectedTrips: any = [];
  continuoeDisable: boolean = false;
  getTripDetailsPayload: any;
  selectedTripInvoiceArray: any;
  defaultIndex = 0;
  isYesClicked = 0;
  mileagePickPath: boolean = false;
  imgsuploades = [];
  indexValue = 0;
  tripsSearchtxt: string = "";
  nodatafound: boolean = false;
  isAssociatedAttchments: any;
  defaultBoolean = false;
  associatedAttchements = [];
  splitAmountBooelan: boolean = false;
  duplicateValue: boolean = false;
  mileagePickerOp: boolean = false;
  expensePickerOp: boolean = false;
  perdiemPickerOp: boolean = false;
  perdiemStartD: boolean = false;
  perdiemEndD: boolean = false;
  bulkPickerOp: boolean = false;
  startTimer: boolean = false;
  endTimer: boolean = false;

  sampleDt = [];

  perdiemRateList = [];
  perdiemTypeList = [];
  Stime = { hour: 0, minute: 0 };
  Etime = { hour: 0, minute: 0 };
  perdiemRateSelected: any;
  perdiemSelected: any;
  selectedPolicyPerdiem: any;
  _perDay: any;
  _perHalfDay: any;
  _perHour: any;
  _loader: any;
  _perdiemSelectedIndex: any;
  _perdiemEditForm: boolean = false;
  perdiemCategoryList: string[] = ["Per Diem"];
  _expenseTypeVerification: any;
  hasExpenseSplit: boolean = true;
  isSelectedLocationConfigered: boolean = false;
  resetSplitExtractAmount: boolean = false;
  resetSplitExtractAmountPer: boolean = false;

  _splitExractAmount: boolean = false;
  bulkUploadRejectStatus: boolean = false;
  perdiemEndDateStatus: boolean = false;
  mapType: number;
  _bulkProgressState: boolean = false;
  stateList: any;
  spentLocations = [];
  request = {};
  stateData: any;
  stateTempList: any;
  financeIntCodeList: any
  financeIntCodeTempList: any
  taxCodeList: any;
  taxCodeTempList: any;
  hsnCodeList: any;
  hsnCodeTempList: any
  showHsnBtns: boolean = false;
  commaSeperatorArray: any;
  selectedMerchant: any;
  approvedExpenseList = [];
  approveAmountValidation: boolean = false;
  taxPercentageValidation: boolean = false;
  taxAmountValidation: boolean = false;
  itemwise_taxPercentageValidation: boolean = false;
  itemwise_taxAmountValidation: boolean = false;
  pdfloader: boolean = true;
  _isEdit: boolean = false;
  mapMyIndiaReferenceId = [];
  private locationSearchText: Subject<string> = new Subject();
  latitude: any = 0; //latitude
  longitude: any = 0; //longitude

  getLatLong: any;
  totalDistanceVal: Number = 0;
  _expenseType = 'Expense';
  isLoading = false;

  fromLat: any = 0;
  fromLong: any = 0;
  toLat: any = 0;
  toLong: any = 0;
  stopStart: boolean = true;
  speedStr: number;
  fromLatLong: boolean = false;
  toLatLong: boolean = false;
  totalDist: any = 0;
  distance_disbale_status: boolean = false;

  tripfilterModal = new TripFilterModal();
  checklist = new CheckPointsList();
  // checkpointModel: checkpointModel;

  userPreferanceData: any;
  currentTime: any = 0;

  currentDateTime = moment(new Date()).format("DD MMM HH:mm:ss");
  defaultmerchant: boolean = false;
  showCategoryList: boolean = false;
  navGlobaldata: any;
  navInfoData: any;
  showNewExpenseButton: boolean = false;
  showMileageBtn: boolean = true;
  showBulkBtn: boolean = true;
  showperdiemBtn: boolean = true;
  bulkExActiveIndex = 0;
  previewAttachmentsArray: any;
  index: any;
  expensetype = 'Expense'
  firstTimeIndexRemoved: boolean = false;
  miileageBulkSelectedIndex: any;
  showAdditemsBtn: boolean = true;
  bulkAlreadyRemoved: boolean = false;
  showClaimFormViolation: boolean = false;
  claimMindate: any;
  _claimMin: any;
  _claimMax: any;
  @ViewChild("defaultcalenderopening") calenderOpenRefrence: ElementRef;
  @ViewChild('fromdate') fromdate: MatDatepicker<Date>;
  @ViewChild('exppickerbulk_i') exppickerbulk_i: MatDatepicker<Date>;
  @ViewChild('exppickerEndbulk_i') exppickerEndbulk_i: MatDatepicker<Date>;
  @ViewChild('mileagepicker_i') mileagepicker_i: MatDatepicker<Date>;
  @ViewChild('customPicker_i') customPicker_i: MatDatepicker<Date>;
  @ViewChild('todate') todate: MatDatepicker<Date>;
  openfromdate: boolean = false;
  openfromdateexp: boolean = false;
  openfromdatemileage: boolean = false;
  openfromdatemileagee: boolean = false;
  opentodatemileage: boolean = false;
  opentodate: boolean = false;
  previous_selected_value: any = '';
  vehicalList_tenp = [];
  categoryCountLimitWarning: boolean = false;
  categoryRuleLimitAmountWarning: boolean = false;

  userlists = [];
  showlist: boolean = false;
  @ViewChild('corporate', { static: false }) corporateList: ElementRef;

  listNames: any;
  userlist = [];
  expenseTabIndex = 0;
  expenseTabLabel = 'Expenses';
  approveError: boolean = false;
  closeOptionsPopup: boolean = false;
  mouseover: boolean = false;
  currentField: string;
  policyPerDiemList = [];
  filterSearch: any;
  showSearch = false;
  attachmentIndex: any;
  totalClaimedAmount: number;
  originalTotalClaimedAmount: number;
  bulkFieldsMandatory: boolean = false;
  mileageFieldsMandatory: boolean = false;
  perdiemFieldsMandatory: boolean = false;
  bulkFieldsMandatoryText: any = '';
  mileageFieldsMandatoryText: any = '';
  perdiemFieldsMandatoryText: any = '';
  currentDatePickerInd = 0;
  showDatePicker = true;
  clickedDatePicker = false;
  mouseoverDatePicker = false;
  originalExpenseAmount: number;
  perdiemAttachmentIndex: any;
  isTripOpen = false;
  isTripOpenIcon = false;
  allExpenses: any = [];
  timesList = [
    { value: 0, label: '00:00' },
    { value: 1, label: '01:00' },
    { value: 2, label: '02:00' },
    { value: 3, label: '03:00' },
    { value: 4, label: '04:00' },
    { value: 5, label: '05:00' },
    { value: 6, label: '06:00' },
    { value: 7, label: '07:00' },
    { value: 8, label: '08:00' },
    { value: 9, label: '09:00' },
    { value: 10, label: '10:00' },
    { value: 11, label: '11:00' },
    { value: 12, label: '12:00' },
    { value: 13, label: '13:00' },
    { value: 14, label: '14:00' },
    { value: 15, label: '15:00' },
    { value: 16, label: '16:00' },
    { value: 17, label: '17:00' },
    { value: 18, label: '18:00' },
    { value: 19, label: '19:00' },
    { value: 20, label: '20:00' },
    { value: 21, label: '21:00' },
    { value: 22, label: '22:00' },
    { value: 23, label: '23:00' }
  ]
  approverlist: any = [];
  isAdminView: boolean;
  enableUpdate = false;
  expensesMaxLimit = false;
  mileagesMaxLimit = false;
  totalExpensesCount = 0;
  totalMileagesCount = 0;
  // expenseReadOnly = false;
  // mileageReadOnly = false;
  editform: any = [];
  financeDropdownIndex = 0;
  userPreferenceObj: any;
  isGPStracking: boolean = false;
  latLong_from: string
  latLong_to: string;
  mainheadehidden: boolean;
  advanceAmount: any;
  isLinkedToAdvancePayment: boolean;
  isLinkedToPettyCashPayment: boolean;
  walletAdvance: any;
  editechangecurrency = [];
  currencyCode: any;
  userexpConvRate: any;
  editexchange: number;
  toPathInfoData: any;
  customFields: any = [];
  showPerdiemAdvanced = false;
  costCenters: any;
  projectCodes: any;
  bands: any;
  departments: any;
  designations: any;
  costCentersTemp: any;
  projectCodesTemp: any;
  bandsTemp: any;
  departmentsTemp: any;
  // showCostCenters = false;
  // showProjectCodes = false;
  // showBands = false;
  // showDepartments = false;
  showPaidBy = false;
  // ccDisplay: any;
  // pcDisplay: any;
  // bDisplay: any;
  // dDisplay: any;
  paidByDisplay: any;
  // costCentersMandatory = false;
  // projectCodesMandatory = false;
  // bandsMandatory = false;
  // departmentsMandatory = false;
  paidByMandatory = false;
  bInd: any;
  dInd: any;
  ccInd: any;
  pcInd: any;
  TravelAdvanceSummary = null;
  showView = false;
  isMobile: any;
  customDateSub: Subscription;
  isAndroid: any;

  showBands = { expense : false, mileage : false, perdiem : false };
  showDepartments = { expense : false, mileage : false, perdiem : false };
  showCostCenters = { expense : false, mileage : false, perdiem : false };
  showProjectCodes = { expense : false, mileage : false, perdiem : false };
  bandsMandatory = { expense : false, mileage : false, perdiem : false };
  departmentsMandatory = { expense : false, mileage : false, perdiem : false };
  costCentersMandatory = { expense : false, mileage : false, perdiem : false };
  projectCodesMandatory = { expense : false, mileage : false, perdiem : false };
  bDisplay = { expense : null, mileage : null, perdiem : null };
  dDisplay = { expense : null, mileage : null, perdiem : null };
  ccDisplay = { expense : null, mileage : null, perdiem : null };
  pcDisplay = { expense : null, mileage : null, perdiem : null };
  tripAttachemnet: boolean;
  invoiceDetails: any[];
  isPdf: boolean;
  pdfData: string;
  tripServiceAttachments=[];
  documentReferenceURLs: any;
  invoiceList=[];
  tripTravellers=[];
  downloadedProofs = [];
  downloadingAttachment = false;
  tripdetailviewenable: boolean = false;
  enableproceededbtn: boolean = false;
  child_cats = [];
  allCategories = [];
  allCategories1 = [];
  selectedCategoryIndex:any = 0;
  disablenextbtn: boolean = true;
  categoryRes = [];
  countryList = [];
  readOCRboolean:boolean =false;
  disableCLaimType:boolean = false;
  isMobileDevice: boolean = false;
  modalHeader: any;
  allEditExpenses: any;
  currentEditIndex: any;
  tripEstimateDetails: any;
  proofofPaymentPreview: boolean = false;
  restrictClaimOnStartEndDates: boolean = false;
  isProofOfPaymentAttachmentRequired: boolean = false;
  showProofofpayment: boolean = false;
  canEditClaim: boolean;
  dTempHeader: string;
  dTempBody: string;


  places: any[] = [];
  query: string;
  placesSub: Subscription;
  private _places = new BehaviorSubject<any[]>([]);
  googlePlaceSearch = new Subject<string>();

// GS
  selectedFromToLocation(event: any, index, type) {
    if(type == 'from') {
      this.totalMileageBulkModel.expenseRequestDTOList[index].fromLocationName = event.title;
    } else if (type == 'to') {
      this.totalMileageBulkModel.expenseRequestDTOList[index].toLocationName = event.title;
    };
    this.MileagebulkItemsForms.value[index].fromLocationName = this.totalMileageBulkModel.expenseRequestDTOList[index].fromLocationName;
    this.MileagebulkItemsForms.value[index].toLocationName = this.totalMileageBulkModel.expenseRequestDTOList[index].toLocationName;
  }

  get search_places() {
    return this._places.asObservable();
  }

  placesLoading = false;
  customSearchFn(term: string) {
    this.placesLoading = true;
    console.log(term);
	}

  onNGSearchChange(event: any) {
    const searchTerm = event?.term || '';
    this.googlePlaceSearch.next(searchTerm);
  }

  async onSearchChange(event: any) {
    console.log(event.detail.value, event);
    this.query = event.detail.value;
    if (this.query.length > 0) await this.getPlaces();
  }
  async getPlaces() {
    console.clear();
    try {
      let service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input: this.query,
        // componentRestrictions: {
        //   country: 'IN'
        // }
      }, (predictions) => {
        let autoCompleteItems = [];
        this.zone.run(() => {
          if (predictions != null) {
            console.log('prediction || ', predictions);
            predictions.forEach(async (prediction) => {
              // console.log('prediction: ', prediction);
              let latLng: any = await this.geoCode(prediction.description);
              const places = {
                title: prediction.structured_formatting.main_text,
                address: prediction.description,
                lat: latLng.lat,
                lng: latLng.lng
              };
              // console.log('places: ', places);
              autoCompleteItems.push(places);
              
              this.placesLoading = false;
            });
            // this.places = autoCompleteItems;
            console.log('final places || ', this.places);
            // rxjs behaviorSubject
            this._places.next(autoCompleteItems);
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  }
  geoCode(address) {
    let latlng = { lat: '', lng: '' };
    return new Promise((resolve, reject) => {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': address }, (results) => {
        // console.log('results: ', results);
        latlng.lat = results[0].geometry.location.lat();
        latlng.lng = results[0].geometry.location.lng();
        // console.log(results[0].formatted_address, results[0].geometry.location.lat(), results[0].geometry.location.lng());
        resolve(latlng);
      });
    });
  }
  
  constructor(
    public customDate: CustomDateService,
    public modalController: ModalController,
    private camera: Camera,
    public httpClient: HttpClient,
    public formBuilder: FormBuilder,
    public reportService: ReportsService,
    public router: Router,
    private title: Title,
    private platformService: PlatformService,
    private commonService: CommonService,
    private perdiemService: PerdiemService,
    private toastr: ToastrService,
    private expenseService: ExpensesService,
    private datechangeService: DatechangeService,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    public alertController: AlertController,
    public renderer: Renderer2,
    private translate: TranslateService,
    public navControlr: NavController,
    public activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private settingService: SettingsService,
    private ngZone: NgZone,
    private reportsService: ReportsService,
    private loadingController: LoadingController,
    private settingsService: SettingsService,
    private geolocation: Geolocation,
    config: NgbCarouselConfig, private zone: NgZone,
    public platform: Platform,
    private companyService: CompanyService
    // private cameraPreview: CameraPreview
  ) {
    this.totalBulkForm = this.formBuilder.group({
      claimName: [""],
      claimStartDate: [""],
      claimEndDate: [""],
      claimStartTime: [0],
      claimEndTime: [0],
      claimCategory: [""],
      claimCategoryId: [""],
      claimTypeId: [null],
      claimTypeName: [null],
      bulkItems: this.formBuilder.array([]),
    });
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.customFields = JSON.parse(sessionStorage.getItem('customFieldsInfo'));
    this.navInfoData = JSON.parse(sessionStorage.getItem("toPathInfo")) || {};
    this.companyData = JSON.parse(sessionStorage.getItem('companyData')) || [];
    let cTypeId: any;
    if (this.userInfo?.isEnableClaimTypes) {
      if (this.navInfoData?.report?.claimTypeId) {
        cTypeId = this.navInfoData?.report?.claimTypeId;
      } else if (this.navInfoData?.detailReport?.claimTypeId) {
        cTypeId = this.navInfoData?.detailReport?.claimTypeId;
      }

      if (cTypeId) {
        this.customFields.expenseForm.customFieldList = this.customFields?.expenseForm?.customFieldList?.filter(item => item.claimTypeId == cTypeId);
        this.customFields.expenseForm.systemFieldList = this.customFields?.expenseForm?.systemFieldList?.filter(item => item.claimTypeId == cTypeId);
        this.customFields.mileageForm.customFieldList = this.customFields?.mileageForm?.customFieldList?.filter(item => item.claimTypeId == cTypeId);
        this.customFields.mileageForm.systemFieldList = this.customFields?.mileageForm?.systemFieldList?.filter(item => item.claimTypeId == cTypeId);
        this.customFields.perDiemForm.customFieldList = this.customFields?.perDiemForm?.customFieldList?.filter(item => item.claimTypeId == cTypeId);
        this.customFields.perDiemForm.systemFieldList = this.customFields?.perDiemForm?.systemFieldList?.filter(item => item.claimTypeId == cTypeId);
      }
    }

    this.showBands.expense = this.enableFields('Band', 'expense');
    this.showBands.mileage = this.enableFields('Band', 'mileage');
    this.showBands.perdiem = this.enableFields('Band', 'perdiem');
    this.showDepartments.expense = this.enableFields('Department', 'expense');
    this.showDepartments.mileage = this.enableFields('Department', 'mileage');
    this.showDepartments.perdiem = this.enableFields('Department', 'perdiem');
    this.showCostCenters.expense = this.enableFields('Cost Center', 'expense');
    this.showCostCenters.mileage = this.enableFields('Cost Center', 'mileage');
    this.showCostCenters.perdiem = this.enableFields('Cost Center', 'perdiem');
    this.showProjectCodes.expense = this.enableFields('Project Code', 'expense');
    this.showProjectCodes.mileage = this.enableFields('Project Code', 'mileage');
    this.showProjectCodes.perdiem = this.enableFields('Project Code', 'perdiem');
    this.showPaidBy = this.enableFields('Paid By', '');

    this.fromPettyCash = JSON.parse(sessionStorage.getItem("fromPettyCash"));
    this.isMobile = this.platformService.isAndroidIOS();
    this.isAndroid = this.platformService.isAndroid();
    this.isMobileDevice = this.platformService.isMobileDevice();
    this.submitExpense = false;
    this.expenseModel.expenseType = 1;
    this.maxxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
    // this.getSystemFieldsList();
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;

  }

  enableFields(name, type,validate?) {
    let status = false;
    if (name == 'Band') {
      this.checkBand(name, type);
      // this.bDisplay = this.customFields?.expenseForm?.systemFieldList[fieldIndex]?.displayType;
      // this.bandsMandatory = this.customFields?.expenseForm?.systemFieldList[fieldIndex]?.isMandatory || false;
    } else if (name == 'Department') {
      this.checkDepartment(name, type);
      // this.dDisplay = this.customFields?.expenseForm?.systemFieldList[fieldIndex]?.displayType;
      // this.departmentsMandatory = this.customFields?.expenseForm?.systemFieldList[fieldIndex]?.isMandatory || false;
    } else if (name == 'Cost Center') {
      this.checkCostCenter(name, type);
      // this.ccDisplay = this.customFields?.expenseForm?.systemFieldList[fieldIndex]?.displayType;
      // this.costCentersMandatory = this.customFields?.expenseForm?.systemFieldList[fieldIndex]?.isMandatory || false;
    } else if (name == 'Project Code') {
      this.checkProjectCode(name, type);
      // this.pcDisplay = this.customFields?.expenseForm?.systemFieldList[fieldIndex]?.displayType;
      // this.projectCodesMandatory = this.customFields?.expenseForm?.systemFieldList[fieldIndex]?.isMandatory || false;
    } else if (name == 'Paid By') {
      const index = this.customFields?.expenseForm?.systemFieldList.findIndex(s => s.fieldName == name);
      this.paidByDisplay = this.customFields?.expenseForm?.systemFieldList[index]?.displayType;
      this.paidByMandatory = this.customFields?.expenseForm?.systemFieldList[index]?.isMandatory || false;
    }
    if(type == 'expense') {
      const index = this.customFields?.expenseForm?.systemFieldList.findIndex(s => s.fieldName == name);
      status = this.customFields?.expenseForm?.systemFieldList[index]?.statusId == 1 || false;
    } else if(type == 'mileage') {
      const index = this.customFields?.mileageForm?.systemFieldList.findIndex(s => s.fieldName == name);
      status = this.customFields?.mileageForm?.systemFieldList[index]?.statusId == 1 || false;
    } else if(type == 'perdiem') {
      const index = this.customFields?.perDiemForm?.systemFieldList.findIndex(s => s.fieldName == name);
      status = this.customFields?.perDiemForm?.systemFieldList[index]?.statusId == 1 || false;
    } else if (name == 'Paid By') {
      const index = this.customFields?.expenseForm?.systemFieldList.findIndex(s => s.fieldName == name);
      return this.customFields?.expenseForm?.systemFieldList[index]?.statusId == 1 || false;
    }
    return status;
  }

  getFieldIndex(name, type) {
    if(type == 'expense') {
      return this.customFields?.expenseForm?.systemFieldList.findIndex(s => s.fieldName == name);
    } else if(type == 'mileage') {
      return this.customFields?.mileageForm?.systemFieldList.findIndex(s => s.fieldName == name);
    } else if(type == 'perdiem') {
      return this.customFields?.perDiemForm?.systemFieldList.findIndex(s => s.fieldName == name);
    }
  }

  checkBand(name, type) {
    const index = this.getFieldIndex(name, type);
    if(type == 'expense') {
      this.bDisplay.expense = this.customFields?.expenseForm?.systemFieldList[index]?.displayType;
      this.bandsMandatory.expense = this.customFields?.expenseForm?.systemFieldList[index]?.isMandatory || false;
    } else if(type == 'mileage') {
      this.bDisplay.mileage = this.customFields?.mileageForm?.systemFieldList[index]?.displayType;
      this.bandsMandatory.mileage = this.customFields?.mileageForm?.systemFieldList[index]?.isMandatory || false;
    } else if(type == 'perdiem') {
      this.bDisplay.perdiem = this.customFields?.perDiemForm?.systemFieldList[index]?.displayType;
      this.bandsMandatory.perdiem = this.customFields?.perDiemForm?.systemFieldList[index]?.isMandatory || false;
    }
  }

  checkDepartment(name, type) {
    const index = this.getFieldIndex(name, type);
    if(type == 'expense') {
      this.dDisplay.expense = this.customFields?.expenseForm?.systemFieldList[index]?.displayType;
      this.departmentsMandatory.expense = this.customFields?.expenseForm?.systemFieldList[index]?.isMandatory || false;
    } else if(type == 'mileage') {
      this.dDisplay.mileage = this.customFields?.mileageForm?.systemFieldList[index]?.displayType;
      this.departmentsMandatory.mileage = this.customFields?.mileageForm?.systemFieldList[index]?.isMandatory || false;
    } else if(type == 'perdiem') {
      this.dDisplay.perdiem = this.customFields?.perDiemForm?.systemFieldList[index]?.displayType;
      this.departmentsMandatory.perdiem = this.customFields?.perDiemForm?.systemFieldList[index]?.isMandatory || false;
    }
  }

  checkCostCenter(name, type) {
    const index = this.getFieldIndex(name, type);
    if(type == 'expense') {
      this.ccDisplay.expense = this.customFields?.expenseForm?.systemFieldList[index]?.displayType;
      this.costCentersMandatory.expense = this.customFields?.expenseForm?.systemFieldList[index]?.isMandatory || false;
    } else if(type == 'mileage') {
      this.ccDisplay.mileage = this.customFields?.mileageForm?.systemFieldList[index]?.displayType;
      this.costCentersMandatory.mileage = this.customFields?.mileageForm?.systemFieldList[index]?.isMandatory || false;
    } else if(type == 'perdiem') {
      this.ccDisplay.perdiem = this.customFields?.perDiemForm?.systemFieldList[index]?.displayType;
      this.costCentersMandatory.perdiem = this.customFields?.perDiemForm?.systemFieldList[index]?.isMandatory || false;
    }
  }

  checkProjectCode(name, type) {
    const index = this.getFieldIndex(name, type);
    if(type == 'expense') {
      this.pcDisplay.expense = this.customFields?.expenseForm?.systemFieldList[index];
      this.projectCodesMandatory.expense = this.customFields?.expenseForm?.systemFieldList[index]?.isMandatory || false;
      console.log('sdsds',name,type,this.pcDisplay.expense,this.projectCodesMandatory.expense);
    } else if(type == 'mileage') {
      this.pcDisplay.mileage = this.customFields?.mileageForm?.systemFieldList[index]?.displayType;
      this.projectCodesMandatory.mileage = this.customFields?.mileageForm?.systemFieldList[index]?.isMandatory || false;
    } else if(type == 'perdiem') {
      this.pcDisplay.perdiem = this.customFields?.perDiemForm?.systemFieldList[index]?.displayType;
      this.projectCodesMandatory.perdiem = this.customFields?.perDiemForm?.systemFieldList[index]?.isMandatory || false;
    }
  }

  dateAndMonthChange(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  showDatepicker = false;
  dp_date: any;
  dp_type: any;
  dp_index: any;
  dp_form: FormGroup;
  openDateTimePicker(type, date, minDate?, maxDate?, productId?, openRightSide?) {
    sessionStorage.removeItem('datePickerRightSide')
    console.log({"type": type, "date": date, "minDate": minDate, "maxDate": maxDate});
    if (productId && productId != '') {
      return;
    }
    if (date && date != '' && !moment.utc(date, 'DD-MM-YYYY', true).isValid() || moment.isMoment(date)) {
      date = moment(date).format('DD-MM-YYYY');
    }
    if (minDate && minDate != '' && (!moment.utc(minDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(minDate))) {
      minDate = moment(minDate).format('DD-MM-YYYY');
    }
    if(maxDate && maxDate != '' && (!moment.utc(maxDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(maxDate))) {
      maxDate = moment(maxDate).format('DD-MM-YYYY');
    }
    this.dp_date = date;
    this.dp_type = type;
    if(!this.dp_date) {
      this.dp_date = moment().format('DD-MM-YYYY');
    }
    
    sessionStorage.setItem('selectedDate', this.dp_date);
    sessionStorage.setItem('min_Date', minDate);
    sessionStorage.setItem('max_Date', maxDate);
    if(openRightSide) {
      sessionStorage.setItem('datePickerRightSide', openRightSide);
    }
    this.showDatepicker = true;
  }


  getSelectedDate() {
    this.customDateSub = this.customDate.getSelectedDate().subscribe((date: any) => {      

      if (date) {
        if(this.dp_type == 'from' || this.dp_type == 'to') {
          this.ClaimdateChange(date, this.dp_type, false, this.currentTemplate, true);
        } else if (this.dp_type == 'expense') {
          this.expenseDate(date, this.dp_index, true);
          this.updateBulkForm('', this.dp_form, 'date', this.dp_index);
        }else if (this.dp_type == 'expenseEndDate') {
          this.expenseDate(date, this.dp_index, true);
          this.updateBulkForm('', this.dp_form, 'date', this.dp_index);    
          
        } else if (this.dp_type == 'mileage') {
          this.mileageDate(date, this.dp_index, true);
        } else if (this.dp_type == 'perdiemStart') {
          this.perdiemStartDateChange(date, true);
        } else if (this.dp_type == 'perdiemEnd') {
          this.perdiemEndDateChange(date, true);
        }
        this.showDatePicker = false;
        this.dp_date = null;
        // this.dp_type = null;
        this.dp_form = null;
        this.dp_index = null;

        if (this.dp_type == 'perdiemStart') {
          this.openDateTimePicker('perdiemEnd', this.pv.PDendDate.value, date, this.totalBulkForm.controls.claimEndDate.value)
          this.showDatePicker = true;
        } else {
          this.dp_type = null;
        }
      }
    })
  }

  setDPdata(index, item, type?) {
    this.dp_form = item;
    this.dp_index = index;
    console.log(index, item);
  }

  setCT(template?: TemplateRef<any>) {
    this.currentTemplate = template;
  }

  companyData: any = [];
  ngOnInit() {
    this.googlePlaceSearch.pipe(
      debounceTime(800),
      switchMap(term => {
        this.query = term;
        if (this.query.length > 0) {
          return this.getPlaces();
        } else {
          return [];
        }
      })
    ).subscribe();
    this.placesSub = this.search_places.subscribe({
      next: (places) => {
        this.places = places;
        // console.log('places _________ || ', this.places);
        // this.cdr.detectChanges(); // Trigger change detection manually
      },
      error: (e) => {
        console.log(e);
      }
    });
    // this.getSelectedDate();

    this.settingService.getCountries({}).subscribe(data => {
      this.countryList = data.payload;
    });

    this.bands = JSON.parse(sessionStorage.getItem("bands")) || [];
    this.departments = JSON.parse(sessionStorage.getItem("departments")) || [];
    this.costCenters = JSON.parse(sessionStorage.getItem("costCenters")) || [];
    this.projectCodes = JSON.parse(sessionStorage.getItem("projectCodes")) || [];
    this.designations = JSON.parse(sessionStorage.getItem("designations")) || [];
    this.bandsTemp = JSON.parse(sessionStorage.getItem("bands")) || [];
    this.departmentsTemp = JSON.parse(sessionStorage.getItem("departments")) || [];
    this.costCentersTemp = JSON.parse(sessionStorage.getItem("costCenters")) || [];
    this.projectCodesTemp = JSON.parse(sessionStorage.getItem("projectCodes")) || [];

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.mainheadehidden = JSON.parse(sessionStorage.getItem('mainheadehidden'));
    this.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    this.showMileageAdvanced = this.userInfo?.showAdvanceOptions && this.userInfo?.viewAdvanceOptions;

    this.bInd = this.bands.findIndex(b => b.bandId == this.userInfo.bandId);
    this.dInd = this.departments.findIndex(d => d.departmentId == this.userInfo.departmentId);
    this.ccInd = this.costCenters.findIndex(cc => cc.costCenterId == this.userInfo.costCenterId);
    this.pcInd = this.projectCodes.findIndex(pc => pc.projectId == this.userInfo.projectCodeId);

    let urlsList = this.userInfo.referenceDocumentUrls?.split(', ') || [];
    this.documentReferenceURLs = [];
    urlsList.forEach(u => {
      this.documentReferenceURLs.push({
        url: u,
        content: ''
      })
    })

    if (this.userInfo.attachmentFormat != null || this.userInfo.attachmentFormat != '') {
      this.commaSeperatorArray = this.userInfo.attachmentFormat.split(',');
    }
    // this.showNewExpenseButton = this.userInfo.allowItemizedExpenses;
    // this.getstateList();
    this.getmastCategorylist();
    this.getClaimTypes();
    this.getMilageStates();

    this.locationSearchText.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((term: string) => {
        this.googleAutoCompleteResult(term);
      });
    this.locationSearchText.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((term: string) => {
        this.googleAutoCompleteSearchOnlyIndia(term)
      });
    // this.checkpointModel = new checkpointModel()
    this.checklist.checkpoint = [];
  }
  callGPSData() {
    this.expenseTabIndex = 1;
    if (this.GPSData) {
      this.distance_disbale_status = true;
      this.expenseModel.distance = this.GPSData.distance.toFixed(2);
      if (this.type == 2) {
        this.expenseModel.description = 'Mileage Expense Created on ' + moment(new Date()).format("DD MMM YYYY");
      }
      this.fromLat = this.GPSData.fromLat;
      this.fromLong = this.GPSData.fromLong;
      this.toLat = this.GPSData.toLat;
      this.toLong = this.GPSData.toLong;
      if (this.toLat && this.toLong) { this.toLatLong = true; } else {
        this.toLatLong = false;
      }
      if (this.fromLat && this.fromLong) { this.fromLatLong = true; } else {
        this.fromLatLong = false;
      }
      // this.mileageForm.controls.expensedDate.patchValue(this.convertDateToUtc(this.GPSData.expensedDate));
      this.expenseModel.expensedDate = this.GPSData.expensedDate;
      //let dateTime = new Date().toLocaleString();
      this.expenseModel.claimName = 'Mileage Created On ' + this.currentDateTime;
      // console.log('this.expenseModel : ', this.expenseModel);

      this.totalDist = 0.00;
      localStorage.setItem('gpsDistance', this.totalDist);
      this.getUserPreferance();
      // this.expenseModel.expensedDate =this.maxxDate;
    } else {
      this.distance_disbale_status = false;
    }
  }

  async trackGPS() {
    const date = new Date();
    const time = date.toLocaleTimeString();
    if (localStorage.getItem("startTime") == null || localStorage.getItem("startTime") == '') {
      localStorage.setItem('startTime', time);
    }
    const modal = await this.modalController.create({
      component: GpsTrackingComponent,
      cssClass: "my-custom-class",
      componentProps: {
        isEdit: false
      },
    });
    return await modal.present();
  }
  ngOnDestroy() {
    if(this.placesSub) this.placesSub.unsubscribe();
    if (this.locationSearchText) {
      this.locationSearchText.unsubscribe();
    }
    window.removeEventListener("scroll", this.scrollChangeCallback, true);
  }

  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.onContentScrolled(event);
    window.addEventListener("scroll", this.scrollChangeCallback, true);
  }

  selectedClaimCatObj = {
    categoryId: null,
    categoryName: null
  }

  jsonFetach() {
    fetch('./assets/company-config.json').then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();  // Parse the JSON from the response
    }).then(data => {
        console.log('companyData |___| ', data);
    }).catch(error => {
        console.error('There was a problem with the fetch operation:', error);
    });
  }
  ionViewWillEnter() {
    this.navInfoData = JSON.parse(sessionStorage.getItem("toPathInfo")) || {};
    this.canEditClaim = this.navInfoData?.detailReport?.canEditClaim ?? true;
    if(this.navInfoData?.openTabIndex >= 0) {
      this.expenseTabIndex = this.navInfoData?.openTabIndex;
      if(this.expenseTabIndex == 0) {
        this.modalHeader = 'Add New Expense';
        this.expenseTabLabel = 'Expenses';
        this.active = 'bottom';
      } else if (this.expenseTabIndex == 1) {
        this.active = 'middle';
        this.modalHeader = `Add New ${(this.userInfo?.mileageTabLabel && this.userInfo?.mileageTabLabel != '') ? this.userInfo?.mileageTabLabel : 'Mileage'}`;
        this.expenseTabLabel = 'Mileage';
      } else if (this.expenseTabIndex == 2) {
        this.modalHeader = 'Add New Perdiem';
        this.expenseTabLabel = 'Perdiem';
        this.active = 'end';
      }
    }
    this.userPreferenceObj = JSON.parse(localStorage.getItem('user-preference'));
    this.isGPStracking = false;
    if (this.navInfoData.expenseId && this.navInfoData.expenseGUID) {
      this.enableUpdate = true;
    }

    if(this.navInfoData?.detailReport && !this.userInfo?.isEnableCategorySelectionAtExpense) {
      this.selectedClaimCatObj.categoryId = this.navInfoData?.detailReport?.claimCategoryId || null;
      this.selectedClaimCatObj.categoryName = this.navInfoData?.detailReport?.claimCategoryName || null;
    } else if(this.navInfoData?.report && !this.userInfo?.isEnableCategorySelectionAtExpense) {
      this.selectedClaimCatObj.categoryId = this.navInfoData?.report?.claimCategoryId || null;
      this.selectedClaimCatObj.categoryName = this.navInfoData?.report?.claimCategoryName || null;
    } else if(this.navInfoData?.expenseList?.length > 0 && this.userInfo?.isEnableCategorySelectionAtExpense) {
      this.selectedClaimCatObj.categoryId = this.navInfoData?.expenseList[0]?.parentCategoryId || null;
      this.selectedClaimCatObj.categoryName = this.navInfoData?.expenseList[0]?.parentCategoryName || null;
    }
    console.log(1148, this.selectedClaimCatObj);
    this.expenseFormValidations();
    this.mileageFormValidations(1);
    this.perdiemValidation();
    this.getUserList();
    this.bulkFormValidations(1, 'not');
    this.getCurrencylist();
    this.getReportList();
    this.getUsers();

    if (this.GPSData) {
      this.isGPStracking = true;
      this.callGPSData();
    }

    this.fromPettyCash = JSON.parse(sessionStorage.getItem("fromPettyCash"));
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.approvedExpenseList = JSON.parse(sessionStorage.getItem("approvedExpenseList"));
    this.submitExpense = false;
    this.expenseModel.expenseType = 1;
    this.maxxDate = moment(new Date()).format(Constants.mobileCalenderFormat);

    this.commonService.googlescriptAdd();
    this.renderer.addClass(document.body, "md-pop");
    this.validationMsg = AppConfig;
    this.platformType = this.platformService.getPlatformType();
    let date = new Date();
    this.maxDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    // this.getmastCategorylist();
    // this.expenseFormValidations();
    // this.mileageFormValidations(1);
    // this.perdiemValidation();
    // this.getUserList();
    // // this.bulkFormValidations(1);
    // this.getCurrencylist();

    // this.getReportList();
    this.photos = [];
    this.sumOfamount = this.expenseItemsArray.valueChanges.subscribe((data) => {
      this.total = data.reduce((a, b) => a + +b.amount, 0);
    });
    this.expcurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
    if (this.type == 1) {
      this._expenseType = 'Expense'
    } else if (this.type == 2) {
      this._expenseType = 'Mileage'
    } else if (this.type == 4) {
      this._expenseType = 'Perdiem'
    }
    this.setNavParams();
    this.getRecurrentCodesList();
    // this.bulkFormValidations(1, 'not');
    this.perdiemModel.PDstartTime = this.Stime.hour;
    this.perdiemModel.PDendTime = this.Etime.hour;
    if (!this.fromPettyCash && !this.updatetitle) {
      this.addCorporateUser({
        emailId: this.userInfo.email,
        userId: this.userInfo.userId,
        userLogo: this.userInfo.profilePicPath,
        userName: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
      });
    }
    console.log(this.navInfoData);
    if(this.navInfoData?.allExpenses) {
      this.allEditExpenses = this.navInfoData?.allExpenses || [];
    }
    if ((this.navInfoData?.expenseId && this.navInfoData?.expenseGUID) || this.navInfoData?.fromExpenseEdit) {
      if(this.navInfoData.claimTaggedTripList){
        this.navInfoData.claimTaggedTripList?.forEach(element => {
          if(element?.tripServiceAttachments){
            element?.tripServiceAttachments.forEach(childelement => {
              if(childelement?.fileURL !=null){
                this.tripAttachemnet =true
              }else{
                this.tripAttachemnet =false
              }
            });
          }
        });
      }
      this.expenseTaggedTripList = this.navInfoData.claimTaggedTripList || [];
      this.tripTravellers = this.navInfoData?.tripTravellers || [];
      this.isTripOpen = this.expenseTaggedTripList?.length > 0 ? true : false;
      // console.log('case 000',this.navInfoData);
      if (this.navInfoData.formClaimEdit) {
        // console.log('case 123');
        this.allEditExpenses = this.navInfoData?.allExpenses || [];
        this.currentEditIndex = this.navInfoData?.editExpenseIndex;
        this.setBulkEditData(this.allEditExpenses[this.currentEditIndex]?.expenseId, [this.allEditExpenses[this.currentEditIndex]]);
      } else {
        console.log('case 456');
        // this.getexpenseDetails();
        this.allEditExpenses = this.navInfoData?.allExpenses || [];
        this.currentEditIndex = this.navInfoData?.editExpenseIndex;
        this.setBulkEditData(this.allEditExpenses[this.currentEditIndex]?.expenseId, [this.allEditExpenses[this.currentEditIndex]] || [this.allEditExpenses[this.currentEditIndex]]);
        // this.setBulkEditData(this.navInfoData.expenseId, this.navInfoData.expenseList);
      }
    } else {
      if (this.navInfoData?.isEdit && this.navInfoData?.url == 'reports' && (!this.navInfoData?.expenseList || this.navInfoData?.expenseList?.length == 0)) {
        this.emptyClaimStatus = 1;
        this.enableUpdate = true;
      }
      this.totalExpensesCount = 0;
      this.totalMileagesCount = 0;
      this.navInfoData?.expenseList?.forEach(e => {
        if (e.isSelected && e.categoryType == 1 && e.expenseType == 1) {
          this.totalExpensesCount++;
        } else if (e.isSelected && e.categoryType == 2 && e.expenseType == 1) {
          this.totalMileagesCount++;
        }
      })

      // if (this.totalExpensesCount == 25) {
      //   this.expenseReadOnly = true;
      // }
      // if (this.totalMileagesCount == 25) {
      //   this.mileageReadOnly = true;
      // }
      this.totalExpensesCount++;
      this.totalMileagesCount++;

      this.setExpensesMaxLimit();

      this.navChanged(this.expenseTabIndex);
      if (this.platformType == 2) {
        this.expenseForm.controls.originalCurrencyCode.patchValue(this.userInfo.baseCurrency.currencyCode);
        this.mileageForm.controls.originalCurrencyCode.patchValue(this.userInfo.baseCurrency.currencyCode);
        this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
        this.milecurrencySymbol = this.userInfo.baseCurrency.currencyIcon;
        this.expenseModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
        this.expenseModel.originalCurrencyName = this.userInfo.baseCurrency.currencyCode;
      } else {
        this.onTabChanged();
        if (!this.extractData) {
          this.expenseForm.controls.originalCurrencyCode.patchValue(this.userInfo.baseCurrency.currencyCode);
          // this.mileageForm.controls.originalCurrencyId.patchValue(this.userInfo.baseCurrency.currencyId);
          this.expenseModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
          this.expenseModel.originalCurrencyName = this.userInfo.baseCurrency.currencyCode;
          this.expenseModel.originalCurrencyCode = this.userInfo.baseCurrency.currencyCode;
          this.totalBulkModel.expenseRequestDTOList[0].currencyCode = this.userInfo.baseCurrency.currencyCode;
          this.totalBulkModel.expenseRequestDTOList[0].originalCurrencyName = this.userInfo.baseCurrency.currencyId;
          this.totalBulkModel.expenseRequestDTOList[0].originalCurrencyId = this.userInfo.baseCurrency.currencyId;
          this.totalBulkModel.expenseRequestDTOList[0].originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
          this.totalBulkModel.expenseRequestDTOList[0].expConvRate = 1;
          this.totalBulkModel.expenseRequestDTOList[0].exchangeRate = 1;
          this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
          this.milecurrencySymbol = this.userInfo.baseCurrency.currencyCode;
        }
      }
    }

    if (this.expenseModel?.claimId && this.claimGUID) {
      this.expenseForm.controls.claimName.patchValue(
        this.expenseModel?.claimName
      );
      this.mileageForm.controls.claimName.patchValue(
        this.expenseModel?.claimName
      );
      this.expenseModel.claimId = this.expenseModel?.claimId;
    }

    this.sharedService.getTravelTrip().subscribe((res) => {
      if (res) {
        this.travelTripdata.tripId = res.tripId;
        this.travelTripdata.tripName = res.tripName;
        this.travelTripdata.tripStartDate = res.tripEndDate;
        this.travelTripdata.tripEndDate = res.tripEndDate;
      }
    });
    this.sharedService.getReportData().subscribe((value) => {
      if (value) {
        this.editreport = value;
        this.expenseForm.controls.claimName.patchValue(
          this.editreport.claimName
        );
      }
    });
    this.sharedService.getPreCategory().subscribe((value) => {
      if (value) {
        if (this.categoryList?.length == 0) {
          this.categoryList = value;
        }
      }
    });
    this.sharedService.getMapData().subscribe((res) => {
      if (res) {
        this.mileagePickPath = true;
        // let previousAttchments = this.expenseModel.expenseAttachments;
        this.dummyexpenseModel.expenseAttachments = this.expenseModel.expenseAttachments;
        this.expenseModel.expenseAttachments = [];
        // this.expenseModel.expenseAttachments = res.expenseAttachments;
        this.expenseModel.expenseAttachments.push(res.expenseAttachments[0]);
        // this.expenseModel.expenseAttachments = res.expenseAttachments;

        this.expenseModel.originalDistance = res.originalDistance;
        this.expenseModel.distance = res.originalDistance;
        this.expenseModel.mapMyIndiaReferenceIds = JSON.parse(sessionStorage.getItem("MapRefIDs"));

        this.expenseModel.fromLocationName = res.fromLocationName;
        this.expenseModel.toLocationName = res.toLocationName;
        this.expenseModel.fromLangitude = res.fromLangitude;
        this.expenseModel.fromLatitude = res.fromLatitude;
        this.expenseModel.toLangitude = res.toLangitude;
        this.expenseModel.toLatitude = res.toLatitude;
        this.expenseModel.checkPointsList = res.checkPointsList;

        if (res.checkPointsList.length > 0) {
          this.isCheckPoints = true;
        } else {
          this.isCheckPoints = false;
        }

        if (this.expenseModel?.originalDistance > 0) {
          this.getDistance(this.expenseModel.originalDistance);
        }
        this.dummyexpenseModel.expenseAttachments.forEach((element) => {
          this.expenseModel.expenseAttachments.push(element);
        });
        // this.expenseModel.expenseAttachments = _.uniq( this.expenseModel.expenseAttachments);
        // console.log("after",this.expenseModel.expenseAttachments);
        // sessionStorage.setItem("mapData", null);
        this.modalRef.hide();

      }
    });
    if (this.report == undefined) {
      this.reportSlect = true;
    } else {
      this.expenseForm.controls.claimName.patchValue(this.report.claimName);
      this.expenseForm.controls.claimId.patchValue(this.report.claimId);
      this.reportSlect = false;
    }

    this.title.setTitle("Dashboard");
    if (this.imageArray !== undefined) {
      const fileName = Math.random().toString(36).substr(2, 15) + ".png";
      this.capturedImage = this.imageArray[0];
      this.caputredImageModal.capturedImages.push(this.capturedImage);
      this.caputredImageModal.capturedImages.reverse();
      this.uploadImage(this.capturedImage, fileName);
    }

    // if (this.GPSData) {
    //   this.callGPSData()
    // }
    setTimeout(() => {
      this.getSelectedDate();
    }, 100)
  }

  setDropDownValues() {
    if (this.platformType == 2) {
      this.expenseForm.controls.originalCurrencyCode.patchValue({
        originalCurrencyCode: this.userInfo.baseCurrency.currencyCode,
        currencyCode: this.userInfo.baseCurrency.currencyCode,
        originalCurrencyId: this.userInfo.baseCurrency.currencyId,
      });
      this.expenseModel.originalCurrencyId =
        this.userInfo.baseCurrency.currencyId;
    } else {
      this.expenseForm.controls.originalCurrencyCode.patchValue(
        this.userInfo.baseCurrency.currencyCode
      );
      // this.mileageForm.controls.originalCurrencyCode.patchValue(
      //   this.userInfo.baseCurrency.currencyCode
      // );
      this.expenseModel.originalCurrencyId =
        this.userInfo.baseCurrency.currencyId;
    }
  }

  isInt(amount) {
    return amount % 1 === 0;
  }

  getTotalAmount() {
    let expenseAmount = 0;
    let mileageAmount = 0;
    let perdiemAmount = 0;
    let tripCompanyPaid = 0;
    // console.log(this.originalExpenseAmount);

    this.expenseTaggedTripList?.forEach(e => {
      if(e.paidBy == 1) {
        tripCompanyPaid += parseFloat(e.totalAmount?.toString().replace(/,/g, '') || 0);
      }
    });

    this.totalBulkModel?.expenseRequestDTOList?.forEach(e => {
      let exchangeRate;
      exchangeRate = e.editedExchangeRate ? e.editedExchangeRate?.toString() : e.expConvRate?.toString();
      if (this.isInt(e.originalAmount)) {
        expenseAmount += parseInt(e.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(exchangeRate || 1);
      } else {
        expenseAmount += parseFloat(e.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(exchangeRate || 1);
      }
    })
    this.MileagebulkItemsForms?.value?.forEach(m => {
      if (this.isInt(m.distance) && this.isInt(m.mileageRate)) {
        mileageAmount += parseInt(m.distance?.toString().replace(/,/g, '') || 0) * parseInt(m.mileageRate || 0) * parseInt(m.expConvRate || 1);
      } else {
        mileageAmount += parseFloat(m.distance?.toString().replace(/,/g, '') || 0) * parseFloat(m.mileageRate || 0) * parseInt(m.expConvRate || 1);
      }
    })
    this.sampleDt?.forEach(s => {
      if (this.isInt(s.originalAmount)) {
        perdiemAmount += parseInt(s.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(s.expConvRate || 1);
      } else {
        perdiemAmount += parseFloat(s.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(s.expConvRate || 1);
      }
    })
    this.totalClaimedAmount = expenseAmount + mileageAmount + perdiemAmount + tripCompanyPaid;
    // this.totalClaimedAmount = (this.originalTotalClaimedAmount || 0) + expenseAmount + mileageAmount + perdiemAmount - this.originalExpenseAmount;
  }

  setNavParams() {
    this.toPathInfoData = JSON.parse(sessionStorage.getItem("toPathInfo"));
    this.navInfoData = JSON.parse(sessionStorage.getItem("toPathInfo"));
    if(this.navInfoData?.openTabIndex >= 0) {
      this.expenseTabIndex = this.navInfoData?.openTabIndex;
      if(this.expenseTabIndex == 0) {
        this.modalHeader = 'Add New Expense';
        this.expenseTabLabel = 'Expenses';
        this.active = 'bottom';
      } else if (this.expenseTabIndex == 1) {
        this.modalHeader = `Add New ${(this.userInfo?.mileageTabLabel && this.userInfo?.mileageTabLabel != '') ? this.userInfo?.mileageTabLabel : 'Mileage'}`;
        this.expenseTabLabel = 'Mileage';
        this.active = 'middle';
      } else if (this.expenseTabIndex == 2) {
        this.modalHeader = 'Add New Perdiem';
        this.expenseTabLabel = 'Perdiem';
        this.active = 'end';
      }
    }
    const val = JSON.parse(sessionStorage.getItem("toPathInfo"));
    console.log('Expense and Mileage Nav info :', this.navInfoData);
    this.expenseTaggedTripList = [];
    this.allExpenses = val?.expenseList || [];
    if (val != null) {
      this.advanceAmount = val.advanceapplied;
      this.totalClaimedAmount = val.expensesAmt;
      this.originalTotalClaimedAmount = val.expensesAmt;
      this.walletAdvance = val.walletAdvance;
      this.isLinkedToAdvancePayment = val.isLinkedToAdvancePayment;
      this.isLinkedToPettyCashPayment = val.isLinkedToPettyCashPayment;
      this.expenseTaggedTripList = val.claimTaggedTripList || [];
      this.tripTravellers = val?.tripTravellers || [];
      this.originalExpenseAmount = this.isInt(parseInt(val.originalExpenseAmount || 0)) ? parseInt(val.originalExpenseAmount || 0) : parseFloat(val.originalExpenseAmount || 0);
      this._expenseTypeVerification = val.formType ? val.formType : null;
      if ((val && val["url"] &&
        (val["url"] == "dashboard" ||
          val["url"] == "expenses" ||
          val["url"] == "expensesTrip" ||
          val["url"] == "expensestravel" ||
          val["url"] == "petty-cash/expense" ||
          val["url"] == "reports" ||
          val["url"] == "newreport" ||
          val["url"] == "approvals")) ||
        val["url"] == "petty-cash/new-report") {
          if(sessionStorage.getItem('tripData')) {
            let tripData = JSON.parse(sessionStorage.getItem('tripData'))
            this.trip_bookingType = tripData?.bookingType || null;
            this.trip_bookingTypeName = tripData?.bookingTypeName || null;
            this.trip_customFields = tripData?.customFields || null;
            this.trip_costCenterCode = tripData?.costCenterCode || null;
            this.trip_reasonForTravel = tripData?.reasonForTravel || null;
            this.trip_customFieldsList = tripData?.customFields || null;
            // this.trip_customFieldsList?.forEach(t => {
            //   t.key = t.key.replace(/_/g, ' ');
            // });
          }

        // if (val["url"] == "dashboard" && this.platformType == 2) {
        if (val["url"] == "dashboard" && val["from"] == "camera") {
          this.type = 1;
          this.capturedImage = val["imgContent"]
          this.caputredImageModal.capturedImages.push(this.capturedImage);
          this.caputredImageModal.capturedImages.reverse();
          this.uploadedfiles = [];
          // let _request = {
          //   fileName: val["fileName"],
          //   // content: val["imgContent"],
          //   type: "image/png"
          // };
          // this.uploadedfiles.push(_request);
          this.imagesCount = 1;
          if (val["report"].expenseList) {
            this.isTakePictureClicked = true;
            this.setBulkEditData(null, val["report"].expenseList || []);
          }
          this.expenseTaggedTripList = val["report"].claimTaggedTripList || [];
          this.tripTravellers = val["report"].tripTravellers || [];

          this.gets3UploadUrl(
            val["report"].expenseIndex || 0, val["fileName"], this.expenseService.cameraImageFile,
            val["report"].expenseList || [], val["report"].tabIndex, val["backButton"]
          );
        }

        // if (val["url"] == "newreport") {
        //   this.navChanged(1);
        // }
        this.fromPathInfo = val;
        this.updatetitle = this.fromPathInfo?.isEdit ? this.fromPathInfo.isEdit : false;
        //TEMP VALIDATION
        if (this.fromPathInfo?.url == 'approvals') {
          if (this.fromPathInfo?.isReadOnly == true && this.fromPathInfo?.showHsnBtns == true) {
            this.updatetitle = true;
          }
        }
        if (val["expenseId"]) { this.expenseId = val["expenseId"]; }
        if (val["expenseGUID"]) { this.expenseGUID = val["expenseGUID"]; }
        if (val["isDuplicate"]) { this.duplicate = val["isDuplicate"]; this.duplicateValue = val["isDuplicate"]; }
        if (val["recordType"]) { this.recordType = val["recordType"]; }
        if (val["showHsnBtns"]) { this.showHsnBtns = true; } else { this.showHsnBtns = false; }
        if (val["attachment"]) {
          // this.expenseAttachmentsModel.fileName = JSON.parse(val["attachment"])[0]?.fileName;
          // this.expenseAttachmentsModel.content = JSON.parse(val["attachment"])[0]?.content;
          // this.expenseModel.expenseAttachments.push(this.expenseAttachmentsModel);
          // let arr = this.expenseModel.expenseAttachments;
          // this.expenseModel.expenseAttachments = arr.map((item) => new ExpenseAttachments(item));
          // this.attachFilename = this.expenseModel.expenseAttachments[this.expenseModel.expenseAttachments.length - 1];
          // this.attachFilename.url = JSON.parse(val["attachment"])[0]?.url;
          // this.imgContent = this.attachFilename.url;
          // this.handleImageLoad();
        }
        if (val["extractedData"]) {
          // this.extractData = JSON.parse(val["extractedData"]);
          // this.bindingOCrData(JSON.parse(val["extractedData"]), false);
        }

        this.isReadOnly = false;

        if (val["singleSelection"]) {
          this.singleTravelTrip = val['singleSelection'];
        }
        if (val["multiSelection"]) {
          this.multiTravelTrip = val['multiSelection'];
        }
        if (val["expenseArray"]) {
          this.expenseArray = val["expenseArray"];
          if (val["currentIndx"]) {
            this.currentIndex = val["currentIndx"];
          }
          // this.expenseId = this.expenseArray[this.currentIndex].expenseId;
          // this.expenseGUID = this.expenseArray[this.currentIndex].expenseGUID;
          if (val["isReadOnly"]) {
            this.isReadOnly = true;
          }
        }
        if (val["isAssociated"]) {
          this.isAssociated = val["isAssociated"];
          this.expenseModel.isAssociated = val["isAssociated"];
        }
        if (this.isAssociated) {
          this.bulkAlreadyRemoved = true;
        }

        if (val["url"] == "expensesTrip") {
          this.trip_bookingType = val['tripData']?.bookingType || null;
          this.trip_bookingTypeName = val['tripData']?.bookingTypeName || null;
          this.trip_customFields = val['tripData']?.customFields || null;
          this.trip_costCenterCode = val['tripData']?.costCenterCode || null;
          this.trip_reasonForTravel = val['tripData']?.reasonForTravel || null;
          this.showBulkBtn = true;
          this.showMileageBtn = false;
          this.showperdiemBtn = false;
          this.expenseTaggedTripList = [];

          let report = val["report"];
          this.totalBulkModel.claimName = report?.claimName;
          this.expenseArray.forEach(element => {
            element.serviceList.forEach(element => {
              let requestobject = {
                id: null,
                expenseId: null,
                requestId: element.requestId,
                requestGUID: element.requestGUID,
                tripId: element.tripId,
                tripGUID: element.tripGUID,
                startDate: element.tripStartDate,
                endDate: element.tripEndDate,
                fromLocation: element.tripName,
                toLocation: "",
                totalAmount: element.tripCost,
                paymentMode: element.paymentMode,
                productId: element.productId,
                isAssociated: element?.isAssociated,
                invoiceList:[],
                tripServiceAttachments:[],
                productName: element.productName,
                billingEntityName: element.billingEntityName,
                paidBy: element.paidBy ? element.paidBy : null,
                budgetRefNumber: element.budgetRefNumber,
                tripEstimateCost:element?.tripEstimateCost || 0,
                tripEstimateInUSD:element?.tripEstimateInUSD || null,
                financeIntegrationCode: element.financeIntegrationCode || null,
                categoryId: element.categoryId || null,
                isSelfDriveCabRequest: element.isSelfDriveCabRequest || null,
                distance: element.distance || null,
                location: element.pickupStateName || null
              };
              this.trip_estimate = element?.tripEstimateCost || 0;
              this.tripEstimateInUSD = element?.tripEstimateInUSD || null;
              
              this.budgetRefNumber = element?.budgetRefNumber || null;

              // attachment validation method
              let attachmentsArray = [];
              let attachmentsArray1 = [];
              if(element?.invoiceList?.length != 0){
                element?.invoiceList?.forEach(innerelement => {
                  if(innerelement.invoiceUrl != null){
                    let obj = {
                      'fileURL':innerelement.invoiceUrl
                    }
                    attachmentsArray.push(obj);
                    attachmentsArray1.push(innerelement);
                  }
                });
              }
              if(attachmentsArray.length != 0){
                requestobject.invoiceList = attachmentsArray1;
                requestobject.tripServiceAttachments = attachmentsArray;
              }
              this.expenseTaggedTripList.push(requestobject);
            });
            
          });
          if (!this.isAssociated) {
            this.getTripDetails('expensesTrip');
          }
        }

        // console.log('invoice list :',this.expenseTaggedTripList);

        this.TravelAdvanceSummary = this.toPathInfoData?.advanceSummary;

        if (val["report"]) {
          let report = val["report"];
          this.expenseModel.claimId = report?.claimId;
          this.claimGUID = report?.claimGUID;
          this.expenseModel.claimName = report?.claimName;
          this.expenseModel.claimCode = report?.claimCode;
        }
        if (val["url"] == "petty-cash/new-report" || val["url"] == "newreport") {
          this.navGlobaldata = val['expenseList'];
          if (!this.userInfo.allowItemizedExpenses && val?.expenseList?.length != 0) {
            // this.expenseModel.claimId = !this.userInfo.allowItemizedExpenses ? report?.claimId : null;
            // this.expenseModel.claimName = !this.userInfo.allowItemizedExpenses ? report?.claimName : '';
            this.totalBulkModel.claimId = this.navInfoData['report']?.claimId;
            this.totalBulkModel.claimName = this.navInfoData['report']?.claimName;
            this.totalBulkModel.isLinkedToAdvancePayment = this.navInfoData?.isLinkedToAdvancePayment;
            this.totalBulkModel.isLinkedToPettyCashPayment = this.navInfoData?.isLinkedToPettyCashPayment;
          } else {
            this.expenseModel.claimId = null;
            this.expenseModel.claimName = '';
          }

          this.expenseModel.claimCode = "";
          this.claimGUID = "";
        }

        // val.url == 'expenses' ? this.getmastCategorylist() :'';

        if (val["url"] == "petty-cash/new-report") {
          this.fromPettyCash = true;
        }


        // if(this.navInfoData?.from && this.navInfoData.from == 'camera') {          
        //   // this.active = 'middle';
        //   // this.totalBulkModel.expenseRequestDTOList = []
        //   // this.expenseForm.patchValue({
        //   //   claimStartDate: new Date(),
        //   //   claimEndDate: new Date(),
        //   //   bulkItems: [
        //   //     {
        //   //       // location: this.userPreferenceObj?.mileageStateName,
        //   //       // categoryName: this.userPreferenceObj?.mileageCategoryName,
        //   //       // categoryId: this.userPreferenceObj?.mileageCategoryId,
        //   //       // distance: this.GPSData.distance.toFixed(2),
        //   //       description: 'Expense Created on ' + moment(new Date()).format("DD MMM YYYY"),
        //   //       expensedDate: moment().format()
        //   //     }
        //   //   ]
        //   // })
        //   console.log('\n\n\n');
        //   // console.log(this.expenseForm);
        //   // console.log(this.totalBulkModel);
        //   console.log(this.totalBulkModel.expenseRequestDTOList[0]);

        // this.totalBulkForm?.controls?.claimName?.patchValue(this.navInfoData?.report?.claimName);
        // this.totalBulkForm?.controls?.claimStartDate?.patchValue(this.convertDateToUtc(this.navInfoData?.report?.claimStartDate));
        // this.totalBulkForm?.controls?.claimEndDate?.patchValue(this.convertDateToUtc(this.navInfoData?.report?.claimEndDate));
        //   if(this.totalBulkModel?.expenseRequestDTOList[0]) {
        //     this.totalBulkModel.expenseRequestDTOList[0].description = 'Expense Created on ' + moment(new Date()).format("DD MMM YYYY")
        //     this.totalBulkModel.expenseRequestDTOList[0].expensedDate.patchValue(this.convertDateToUtc(this.navInfoData?.report?.claimEndDate));
        //     this.totalBulkModel.expenseRequestDTOList[0].loaderBoolean = true;
        //   }
        //   console.log('\n\n\n');
        // }
        if(this.expenseTaggedTripList?.length != 0){
          this.isTripOpenIcon = !this.isTripOpenIcon;
          this.isTripOpen = !this.isTripOpen;
        }

        if((this.navInfoData.url == 'newreport' || this.navInfoData.url == 'expenses') && this.navInfoData?.mycards){
          this.navInfoData?.mycards.forEach((item,index) => {
             let bulkItem = new BulkItemModel();
              bulkItem.categoryName = item?.categoryName;
              bulkItem.merchantName = item?.merchantName;
              bulkItem.merchantCode = item?.merchantCode;
              bulkItem.taxAmount = item?.taxAmount;
              bulkItem.taxPercentage = item?.taxPercentage;
              bulkItem.taxNumber = item?.taxNumber
              bulkItem.categoryName = "";
              bulkItem.merchantName = item?.merchantName;
              bulkItem.categoryType = '';
              bulkItem.categoryId = '';
              // this.datechangeService.convertCalendarToAPI(e.target.value)
              bulkItem.expensedDate = item?.expensedDate;
              bulkItem.cardTrasactionId = item?.cardTrasactionId;
              bulkItem.description = item?.description;
              bulkItem.invoiceNumber = item?.invoiceNumber;
              bulkItem.taxNumber = item?.taxNumber;
              bulkItem.location = item?.spentLocation;
              bulkItem.currencyCode = this.userInfo.baseCurrency.currencyCode;
              bulkItem.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
              bulkItem.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
              bulkItem.expConvRate = 1;
              bulkItem.paidBy = 1;
              bulkItem.paidByName = 'Corporate card';
              bulkItem.bandId = this.userInfo?.bandId || null;
              bulkItem.bandName = this.userInfo?.bandName || null;
              bulkItem.departmentId = this.userInfo?.departmentId || null;
              bulkItem.departmentName = this.userInfo?.departmentName || null;
              bulkItem.costCenterId = this.userInfo?.costCenterId || null;
              bulkItem.costCenterName = this.userInfo?.costCenterName || null;
              bulkItem.designationId = this.userInfo?.designationId || null;
              bulkItem.designationName = this.userInfo?.designationName || null;
              bulkItem.projectCodeId = this.userInfo?.projectCodeId || null;
              bulkItem.projectCodeName = this.userInfo?.projectCodeName || null;

              bulkItem.amount = item.amount;
              bulkItem.originalAmount = Number(item?.amount);
              this.navInfoData.expenseList.push(bulkItem);
              this.addBulkItem(bulkItem);
          });
          this.totalBulkModel.expenseRequestDTOList.splice(0, 1);
          this.bulkItemsForms.removeAt(0);
        }
      }
    }
  }

  slideExpense(index) {
    if (index) {
      if (this.currentIndex < this.expenseArray.length - 1) {
        this.currentIndex = this.currentIndex + 1;
        if (this.navInfoData["url"] == "approvals") {
          this.mileageForm.controls.claimStartDate.patchValue(this.convertDateToUtc(this.navInfoData['detailReport']?.claimStartDate));
          this.mileageForm.controls.claimEndDate.patchValue(this.convertDateToUtc(this.navInfoData['detailReport']?.claimEndDate));
        }
      } else {
        return;
      }
    } else {
      if (this.currentIndex > 0) {
        this.currentIndex = this.currentIndex - 1;
      } else {
        return;
      }
    }
    var categoryType = 1;
    if (this.expenseArray[this.currentIndex]?.categoryType) {
      categoryType = this.expenseArray[this.currentIndex]?.categoryType;
    }
    switch (categoryType) {
      case 1:
        if (this.expenseArray[this.currentIndex]?.expenseType == 5) {
          this.active = "end";
          this._expenseTypeVerification = 5;
          this._perdiemEditForm = true;
        } else {
          this.active = "top";
        }
        break;
      case 2:
        this.active = "middle";
        break;
      case 4:
        this.active = "top";
        break;
    }
    // this.imgNum = 1;
    this.expenseId = this.expenseArray[this.currentIndex].expenseId;
    this.expenseGUID = this.expenseArray[this.currentIndex].expenseGUID;
    if (!this.isAssociated) {
      this.expenseModel.originalAmount =
        this.expenseArray[this.currentIndex].tripCost;
    }
    this.expenseModel.description = this.expenseArray[this.currentIndex].tripName;
    if ((this.singleTravelTrip || this.multiTravelTrip) && !this.isAssociated) {
      let e = this.categoryList.find((o) => o.categoryName === this.expenseArray[this.currentIndex].productName);
      if (e) {
        this.expenseForm.controls.categoryName.patchValue(e.categoryName);
        this.expenseModel.categoryId = e.categoryId;
      }
      this.merchantList = e.merchantList;
    }
    this.expenseModel.expenseAttachments = [];
    this.getTripDetails(this.expenseArray[this.currentIndex]);
    if (!this.singleTravelTrip && !this.multiTravelTrip) {
      this.getexpenseDetails();
    }
  }

  ngOnDestory() {
    this.renderer.removeClass(document.body, "md-pop");
    this.removeSucbr();
  }

  ionViewWillLeave() {
    if(this.customDateSub) {
      this.customDateSub.unsubscribe();
    }
    sessionStorage.removeItem("fromPettyCash");
    this.removeSucbr();
  }

  removeSucbr() {
    let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
    if (
      val &&
      val["url"] &&
      (val["url"] == "newreport" || val["url"] == "petty-cash/new-report")
    ) {
      //we need to retain newReport and existing expenses data
    } else {
      if (
        this.backClick &&
        this.fromPathInfo &&
        this.fromPathInfo?.url &&
        (this.fromPathInfo?.url == "reports" ||
          this.fromPathInfo?.url == "approvals") &&
        this.fromPathInfo?.goToReportDetails == true
      ) {
        let navInfo = {
          url: this.fromPathInfo.url,
          goToReportDetails: true,
          detailReport: this.fromPathInfo?.detailReport,
        };
        sessionStorage.setItem("fromPathInfo", JSON.stringify(navInfo));
      }
      if(!this.isTakePictureClicked) {
        sessionStorage.setItem("toPathInfo", null);
      }
    }
  }

  scrollToElement(): void {
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scroll({
        top: this.myScrollContainer.nativeElement.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  expenseFormValidations() {
    this.expenseForm = this.formBuilder.group({
      categoryId: [""],
      categoryType: [""],
      categoryName: ["", [Validators.required]],
      merchantName: [""],
      description: ["", [Validators.required]],
      expensedDate: ["", [Validators.required]],
      claimId: [""],
      expenseType: ["", [Validators.required]],
      originalAmount: [
        "",
        [Validators.required, Validators.min(1)],
      ],
      invoiceNumber: [""],
      originalCurrencyCode: ["", [Validators.required]],
      originalCurrencyId: [""],
      claimName: ["", [Validators.required]],
      expenseItems: this.formBuilder.array([]),
      taxNumber: [""],
      taxPercentage: [""],
      taxAmount: [""],
      startName: [""],
      location: [""],
      financeIntegrationCode: [""],
      taxCode: [""],
      hsnCode: [""],
      taxCodeId: [""],
      hsnId: [""],
      financeIntegrationId: [''],
      originalApproveAmount: [""],
      expenseUserSplitList: [''],
      showOptions: (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false
    });
    this.addExpenseItems();
    this.calimIdPatch();
  }

  perdiemValidation() {
    let band = {
      id: this.bands[this.bInd]?.bandId,
      name: this.bands[this.bInd]?.bandName,
    }
    let department = {
      id: this.departments[this.dInd]?.departmentId,
      name: this.departments[this.dInd]?.departmentName,
    }
    let costCenter = {
      id: this.costCenters[this.ccInd]?.costCenterId,
      name: this.costCenters[this.ccInd]?.costCenterName,
    }
    let projectCode = {
      id: this.departments[this.dInd]?.projectCodeId,
      name: this.departments[this.dInd]?.projectCodeName,
    }
    this.perdiemForm = this.formBuilder.group({
      PDlocation: ["", [Validators.required]],
      PDCity: [""],
      PDrate: ["", [Validators.required]],
      PDtype: [""],
      PDstartDate: ["", [Validators.required]],
      PDendDate: ["", [Validators.required]],
      PDclaimName: [""],
      PDclaimId: [""],
      PDdescription: [""],
      PDCategory: [""],
      PDoriginalCurrency: [""],
      PDexpenseDate: [""],
      PDspentLocation: [""],
      PDList: this.formBuilder.array([]),
      showOptions: (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false,
      bandId: [band.id || '', this.bandsMandatory.perdiem ? [Validators.required] : []],
      departmentId: [department.id || '', this.departmentsMandatory.perdiem ? [Validators.required] : []],
      costCenterId: [costCenter.id || '', this.costCentersMandatory.perdiem ? [Validators.required] : []],
      projectCodeId: [projectCode.id || '', this.projectCodesMandatory.perdiem ? [Validators.required] : []],
      bandName: [band.name || ''],
      departmentName: [department.name || ''],
      costCenterName: [costCenter.name || ''],
      projectCodeName: [projectCode.name || '']
    });

    let customFields = [];
    this.customFields?.perDiemForm?.customFieldList?.forEach((field, i) => {
      this.perdiemForm.addControl(field.fieldName, new FormControl('', field.isMandatory ? [Validators.required] : []));
      customFields[i] = {
        id: '',
        expenseId: '',
        customFieldName: field.fieldName,
        customFieldValue: '',
        fieldId: field.fieldId
      };
    });
    this.perdiemModel.customFieldList = customFields;
    this.perdiemModel.bandId = band.id;
    this.perdiemModel.bandName = band.name;
    this.perdiemModel.departmentId = department.id;
    this.perdiemModel.departmentName = department.name;
    this.perdiemModel.costCenterId = costCenter.id;
    this.perdiemModel.costCenterName = costCenter.name;
    this.perdiemModel.projectCodeId = projectCode.id;
    this.perdiemModel.projectCodeName = projectCode.name;
    this.perdiemModel.showAdditionalFields = true;
    // console.log(this.perdiemModel);
  }

  calimIdPatch() {
    if (this.expenseModel?.claimId && this.expenseModel?.claimName) {
      this.expenseForm.controls.claimName.patchValue({
        claimName: this.expenseModel?.claimName,
        claimId: this.expenseModel?.claimId,
      });
    }
  }

  get expenseItemsArray() {
    return this.expenseForm.get("expenseItems") as FormArray;
  }

  addExpenseItems(ele?) {
    this.expenseItemsArray.push(this.addExpenseFormGroup(ele));
    if (!this.editlist) {
      this.secondTabList = [];
      this.thirdTabList = [];
    } else {
    }
    this.onTabChanged();
  }

  removeExpenseItems(index) {
    this.expenseItemsArray.removeAt(index);
    this.resetSplitExtractAmount = true;
    this.resetSplitExtractAmountPer = true;
    this.convertAmt();
  }

  addExpenseFormGroup(ele?): FormGroup {
    if (ele) {
      return this.formBuilder.group({
        name: [ele?.name],
        amount: [ele?.amount],
        taxAmount: [ele?.taxAmount],
        taxPercentage: [ele?.taxPercentage],
        itemId: [ele?.itemId],
      });
    } else {
      return this.formBuilder.group({
        name: [""],
        amount: [""],
        taxAmount: [""],
        taxPercentage: [this.selectedMerchant ? this.selectedMerchant.taxPercentage : ""]
      });
    }
  }

  filteredCategories: any;
  getmastCategorylist(isMileage?, index?) {
    if (this.platformType == 2) {
      // this.present();
    }
    if (this.fromPettyCash) {
      this.CategoryMerchantModel.recordType = 4;
    } else {
      this.CategoryMerchantModel.recordType = 1;
    }
    this.CategoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
    this.CategoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.CategoryMerchantModel.locationId = this.selectedLocationId;
    this.CategoryMerchantModel.stateId = this.stateIds;
    this.commonService.getCatgryMasterlist(this.CategoryMerchantModel).subscribe((res) => {
      if (this.listLoaderDic[0]?.noApi) {
        this.listLoaderDic[0].noApi = false;
      }
      if (!res.isError && res.errorCode == 0) {
        if (this.platformType == 2) {
          this._dismiss();
        }
        let listarray = res.payload;
        if (this.fromPettyCash) {
          this.categoryList = listarray.pettyCashCategoryList.map((list) => new CategoryList(list));
          sessionStorage.setItem('catList',JSON.stringify(this.categoryList));
          this.categorytempList = listarray.pettyCashCategoryList.map((list) => new CategoryList(list));
        } else {
          // this.allCategories = listarray?.expenseCategoryList;
          let categoriesList = [];
          let categoryfiltertype: any;
          console.log('path info tech :',this.toPathInfoData);

          if (this.toPathInfoData?.expenseList?.length) {
                this.toPathInfoData?.expenseList.forEach(element => {
                  if (element?.cardTrasactionId && element?.cardTrasactionId !='') {
                    categoryfiltertype = '3';
                  }
                  else if (this.toPathInfoData?.claimTaggedTripList?.length > 0)
                    {
                      categoryfiltertype = '1';
                    }
                    else{
                      categoryfiltertype = '2';
                    }
                })
              
         
          }
          else {
            // console.log('path info tech :',this.toPathInfoData.enableCategoryFor);
            if (this.toPathInfoData?.enableCategoryFor == 'travel') {
              categoryfiltertype = '1';
            }
            else if (this.toPathInfoData?.enableCategoryFor == 'non-travel'){
              categoryfiltertype = '2';
            }
           else if (this.toPathInfoData?.enableCategoryFor == 'my-cards') {
              categoryfiltertype = '3';
            }
          }
         

          if(this.userInfo?.isEnableClaimTypes) {
            let claimTypeId = this.toPathInfoData?.report?.claimTypeId || this.toPathInfoData?.detailReport?.claimTypeId;
            let claimTypeName = this.toPathInfoData?.report?.claimTypeName || this.toPathInfoData?.detailReport?.claimTypeName;
            this.totalBulkForm?.controls?.claimTypeId.patchValue(claimTypeId);
            this.totalBulkForm?.controls?.claimTypeName.patchValue(claimTypeName);
            if (claimTypeId) {
              //  console.log(claimTypeId)
              //  console.log(categoryfiltertype)
              listarray?.expenseCategoryList?.forEach((le) => {
                if (le?.claimTypeId?.split(',')?.includes(claimTypeId?.toString())) {
                  if(le?.expenseTypeOptionIds?.split(',')?.includes(categoryfiltertype)) {
                    categoriesList.push(le);
                  } else if(le?.expenseTypeOptionIds == null) {
                    categoriesList.push(le);
                  }
                  
                }
              });
            } else {
                // console.log('hiiiii')
                // console.log(categoryfiltertype)
              listarray?.expenseCategoryList?.forEach((le) => {
                if (le?.expenseTypeOptionIds?.split(',')?.includes(categoryfiltertype)) {
                  categoriesList.push(le);
                }
                else if (le?.expenseTypeOptionIds == null) {
                  categoriesList.push(le);
                }
              });
            }
          } else {
            //  console.log(categoryfiltertype)
            listarray?.expenseCategoryList?.forEach((le) => {
              if (le?.expenseTypeOptionIds?.split(',')?.includes(categoryfiltertype)) {
                categoriesList.push(le);
              }
              else if (le?.expenseTypeOptionIds == null) {
                categoriesList.push(le);
              }
            });
          }

          // if(this.toPathInfoData?.url == 'newreport'){
          //   if(this.toPathInfoData?.report?.claimTypeId && this.userInfo?.isEnableClaimTypes) {
          //     this.totalBulkForm.controls.claimTypeName.patchValue(this.toPathInfoData?.report?.claimTypeName);
          //     this.totalBulkForm.controls.claimTypeId.patchValue(this.toPathInfoData?.report?.claimTypeId);
          //     listarray?.expenseCategoryList?.forEach((le) => {
          //       console.log(le.categoryName, le.claimTypeId);
          //       if(le.claimTypeId?.split(',')?.includes(this.toPathInfoData?.report?.claimTypeId?.toString())) {
          //         categoriesList.push(le);
          //       }
          //     });
          //   } else {
          //     categoriesList = listarray?.expenseCategoryList;
          //   }
          //   this.allCategories1 = listarray?.expenseCategoryList;
          // }else if (this.toPathInfoData?.expenseArray && this.toPathInfoData?.expenseArray?.length != 0  && this.toPathInfoData?.expenseArray[0]?.serviceList && this.toPathInfoData?.expenseArray[0]?.serviceList[0]?.claimTypeId && this.userInfo?.isEnableClaimTypes){
          //   // this.totalBulkForm.controls.claimTypeName.patchValue(this.toPathInfoData?.report?.claimTypeName);
          //   listarray?.expenseCategoryList?.forEach((le) => {
          //     if(le.claimTypeId?.split(',')?.includes(this.toPathInfoData?.expenseArray[0]?.serviceList[0]?.claimTypeId?.toString())) {
          //       categoriesList.push(le);
          //     }
          //   });
          // }else{
          //   categoriesList = listarray?.expenseCategoryList;
          // }
          this.filteredCategories = categoriesList;
          this.allCategories = categoriesList;
          this.allCategories1 = listarray?.expenseCategoryList;
          this.child_cats = this.getSubCategories(this.allCategories, 5);
          this.subCategoriesList = [...this.child_cats];
          this.allSubCategoriesList = [...this.child_cats];

          setTimeout(() => {
            if(this.navInfoData?.url=='newreport' && this.selectedClaimCatObj.categoryId) {
              this.selectedSubCategory(this.selectedClaimCatObj, '');
              // this.selectedClaimCatObj.categoryId = null;
              // this.selectedClaimCatObj.categoryName = null;
            }
          }, 1000)
          this.categoryList = listarray.expenseCategoryList.map((list) => new CategoryList(list));
          // prepopulated data should be added
          if ((this.singleTravelTrip || this.multiTravelTrip) && !this.isAssociated) {
            let e = this.categoryList.find((o) => o.categoryName === this.expenseArray[this.currentIndex].productName);
            if (e) {
              this.expenseForm.controls.categoryName.patchValue(e.categoryName);
              this.expenseModel.categoryId = e.categoryId;
            }
            this.merchantList = e?.merchantList;
          }

          this.categoryList = _.map(this.categoryList, category => ({ ...category, "orgcategoryName": _.split(category.categoryName, ' - ')[0] }));
          this.categorytempList = listarray.expenseCategoryList.map((list) => new CategoryList(list));
          if (isMileage) {
            this.milageCategoryList = listarray.mileageCategoryList.map((list) => new CategoryList(list));
            this.MileagebulkItemsForms.value[index].vehicalList = this.milageCategoryList;
            
            if(this.totalMileageBulkModel.expenseRequestDTOList[index]) {
              this.totalMileageBulkModel.expenseRequestDTOList[index].vehicalList = this.milageCategoryList;
            }
            this.vehicalList_tenp = this.milageCategoryList;
            if (this.GPSData) {
              let id_index;
              if(this.userPreferenceObj.mileageCategoryId) {
                id_index = this.totalMileageBulkModel.expenseRequestDTOList[index].vehicalList.findIndex(v => v.categoryId == this.userPreferenceObj.mileageCategoryId);
              } else if (this.userPreferanceData?.mileageCategoryId) {
                id_index = this.totalMileageBulkModel.expenseRequestDTOList[index].vehicalList.findIndex(v => v.categoryId == this.userPreferanceData.mileageCategoryId);
              }
              if(id_index >= 0) {
                this.totalMileageBulkModel.expenseRequestDTOList[index].mileageRate = this.totalMileageBulkModel.expenseRequestDTOList[index].vehicalList[id_index].mileageRate || 0;
                this.totalMileageBulkModel.expenseRequestDTOList[index].mileageUnit = this.totalMileageBulkModel.expenseRequestDTOList[index].vehicalList[id_index].mileageUnit || 0;
              }

              this.mileageForm.patchValue({
                bulkItems: [
                  {
                    categoryId: id_index >= 0 ? this.MileagebulkItemsForms.value[0].vehicalList[id_index].categoryId : '',
                    mileageRate: id_index >= 0 ? this.MileagebulkItemsForms.value[0].vehicalList[id_index].mileageRate : '',
                    mileageUnit: id_index >= 0 ? this.MileagebulkItemsForms.value[0].vehicalList[id_index].mileageUnit : '',
                  }
                ]
              })
            }
          }
          if (this.stateIds && this.milageCategoryList?.length == 0) {
            this.milageCategoryListWarning = true;
          } else {
            this.milageCategoryListWarning = false;
          }


        }
        this.merchantListForDuplicate();
      } else if (res.isError == true) {
        if (this.platformType == 2) {
          this._dismiss();
        }
        this.toastr.error(
          res.errorMessage,
          this.translate.instant("alerts.error", { timeOut: 3000 })
        );
      }

        // this.BindingMyTripsIntoForm(false);
    });
  }

  merchantListForDuplicate() {
    if (this.expenseModel?.categoryId) {
      let index = this.categoryList?.findIndex((categ) => categ.categoryId == this.expenseModel.categoryId);
      if (index > -1) {
        this.selectCategoryOption(this.categoryList[index], 0, true);
      }
    }
  }

  onCategorySearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.categorytempList != null) {
      if (searchText != "") {
        let filterdata = this.categorytempList.filter((data) => {
          if (
            data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          }
        });
        this.categoryList = filterdata;
      } else {
        this.categoryList = this.categorytempList;
      }
    }
  }

  onMerchantSearch(val, index) {
    let searchText = val.currentTarget.value.trim();
    if (this.merchanttempList != null) {
      if (searchText != "") {
        let filterdata = this.totalBulkModel.expenseRequestDTOList[index].merchantList.filter((data) => {
          if (
            data.merchantName.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          }
        });
        this.totalBulkModel.expenseRequestDTOList[index].merchantList = filterdata;
      } else {
        this.totalBulkModel.expenseRequestDTOList[index].merchantList = this.merchanttempList;
      }
    }
  }

  categoryAndMerchantModel = new CategoryAndMerchantModel();

  getCurrencylist() {
    this.categoryAndMerchantModel.legalEntityId = this.userInfo.legalEntityId;
    this.categoryAndMerchantModel.legalEntityGUID =
      this.userInfo.legalEntityGuId;
    this.commonService
      .getCurrencylist(this.categoryAndMerchantModel)
      .subscribe((res) => {
        if (this.listLoaderDic[1]?.noApi) {
          this.listLoaderDic[1].loader = false;
        }
        if (!res.isError && res.errorCode == 0) {
          let listarray = res.payload;
          this.currencytempList = listarray.map(
            (list) => new CurrencyList(list)
          );
          this.currencyList = listarray.map((list) => new CurrencyList(list));
          this.currencyList = _.uniqBy(this.currencyList, "currencyId");
          this.currencyList.forEach((item, i) => {
            if (item.currencyId === 64) {
              this.currencyList.splice(i, 1);
              this.currencyList.unshift(item);
            }
          });
        } else if (!res.isError && res.errorCode == 2) {
          this.toastr.error(
            res.errorMessage,
            this.translate.instant("alerts.error", { timeOut: 3000 })
          );
        }
      });
  }

  onCurrencySearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.currencytempList != null) {
      if (searchText != "") {
        let filterdata = this.currencytempList.filter((data) => {
          if (data.currencyCode.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return data;
          } else {
          }
        });
        this.currencyList = filterdata;
      } else {
        this.currencyList = this.currencytempList;
      }
    }
  }

  onFinanceSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.financeIntCodeTempList != null) {
      if (searchText != "") {
        let filterdata = this.financeIntCodeTempList.filter((data) => {
          if (
            data.financeIntegrationCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          } else {
          }
        });
        this.financeIntCodeList = filterdata;
      } else {
        this.financeIntCodeList = this.financeIntCodeTempList;
      }
    }
  }

  onTaxCodeSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.taxCodeTempList != null) {
      if (searchText != "") {
        let filterdata = this.taxCodeTempList.filter((data) => {
          if (
            data.taxCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          } else {
          }
        });
        this.taxCodeList = filterdata;
      } else {
        this.taxCodeList = this.taxCodeTempList;
      }
    }
  }

  onHsnCodeSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.hsnCodeTempList != null) {
      if (searchText != "") {
        let filterdata = this.hsnCodeTempList.filter((data) => {
          if (
            data.hsnCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          } else {
          }
        });
        this.hsnCodeList = filterdata;
      } else {
        this.hsnCodeList = this.hsnCodeTempList;
      }
    }
  }

  expenseAndReportModel = new ExpenseAndReportModel();

  getReportList() {
    this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
    this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.expenseAndReportModel.userId = this.userInfo.userId;
    this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
    this.expenseAndReportModel.filterBy = this.filterModal;
    this.expenseAndReportModel.filterBy.isDraft = true;
    this.expenseAndReportModel.recordType = this.fromPettyCash ? 4 : 1;
    this.reportService
      .reportList(this.expenseAndReportModel)
      .subscribe((res) => {
        if (this.listLoaderDic[2]?.noApi) {
          this.listLoaderDic[2].loader = false;
        }
        if (!res.isError && res.errorCode == 0) {
          let listArray = res.payload.reportResponseList;
          this.reportList = listArray.map(
            (list) => new ReportListResModel(list)
          );
          this.reporttempList = listArray.map(
            (list) => new ReportListResModel(list)
          );
        } else if (res.isError && res.errorCode === 2) {
          // this.toastr.error(
          //   res.errorMessage,
          //   this.translate.instant("alerts.error", { timeOut: 3000 })
          // );
        }
      });
  }

  onReportSearch(val) {
    if (this.listLoaderDic[2]?.noApi) {
      this.listLoaderDic[2].loader = true;
    }
    let searchText = val.currentTarget.value.trim();
    if (this.reporttempList != null) {
      if (searchText != "") {
        let filterdata = this.reporttempList.filter((data) => {
          if (
            data.claimName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
          ) {
            return data;
          }
        });
        this.reportList = filterdata;
      } else {
        this.reporttempList = this.reportList;
      }
    }
  }

  dismiss() {

    // if (this.type == 2 && this.platformType == 2 && this.GPSData) {
    //   localStorage.removeItem("toLat");
    //   localStorage.removeItem("toLong");
    //   localStorage.removeItem("fromLat");
    //   localStorage.removeItem("fromLong");
    //   localStorage.removeItem("gpsDistance");
    //   localStorage.removeItem("startTime");
    //   this.modalController.dismiss({
    //     dismissed: true,
    //   });
    //   this.navControlr.navigateRoot(['dashboard']);

    // } else {
    //   this.modalController.dismiss({
    //     dismissed: true,
    //   });

    const fab = JSON.parse(sessionStorage.getItem("fab"));
    if (fab) {
      this.modalController.dismiss({ dismissed: true, });
    } else {
      const val = JSON.parse(sessionStorage.getItem("toPathInfo"));
      if (val?.from) {
        if (this.type == 2 && this.platformType == 2 && this.GPSData) {
          localStorage.removeItem("toLat");
          localStorage.removeItem("toLong");
          localStorage.removeItem("fromLat");
          localStorage.removeItem("fromLong");
          localStorage.removeItem("gpsDistance");
          localStorage.removeItem("startTime");
          this.modalController.dismiss({
            dismissed: true,
          });
          //this.navControlr.navigateRoot(['dashboard']);
          if (localStorage.getItem('fromTM') == 'dashboard') {
            this.navControlr.navigateRoot(["dashboard"]);
          } else {
            this.navControlr.navigateRoot(["camera"]);
          }
        } else {
          this.modalController.dismiss({ dismissed: true, });
          this.navControlr.navigateRoot(['dashboard']);
        }
      } else {
        this.modalController.dismiss({ dismissed: true, });
        this.navControlr.navigateRoot(['dashboard']);
        if (localStorage.getItem('fromTM') == 'dashboard') {
          this.navControlr.navigateRoot(["dashboard"]);
        } else if (localStorage.getItem('fromTM') == 'camera') {
          this.navControlr.navigateRoot(["camera"]);
        } else {
          this.navControlr.navigateRoot(['dashboard']);
        }
      }
    }

  }


  get f() {
    return this.expenseForm.controls;
  }

  get mv() {
    return this.mileageForm.controls;
  }

  get pv() {
    return this.perdiemForm.controls;
  }

  matTabLabel: any;
  navChanged(e, ev?) {
    if(ev && ev?.tab?.textLabel) {
      e = ev?.index;
      this.matTabLabel = ev?.tab?.textLabel;
      if(ev?.tab?.textLabel == 'Expenses') {
        e = 0;
      } else if(ev?.tab?.textLabel == 'Mileage') {
        e = 1;
      } else if(ev?.tab?.textLabel == 'Perdiem') {
        e = 2;
      }
    }
    switch (e) {
      case 0:
        if(this.navInfoData?.from != 'camera') {
          this.active = 'bottom';
          this.expenseTabIndex = 0;
        }
        this.expenseModel.fromLocationName = null;
        this.expenseModel.toLocationName = null;
        sessionStorage.setItem("mapData", null);
        // this.active = 'bottom';
        this.type = 3;
        // this.expenseTabIndex = 0;
        this.expcurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
        this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
        this.milecurrencySymbol = this.userInfo.baseCurrency.currencyIcon;
        this.setDropDownValues();
        this.resetForNavChange();
        break;
      case 1:
        this.active = 'middle';
        this.type = 2;
        this.setDropDownValues();
        this.resetForNavChange();
        this.expenseTabIndex = 1;
        if (this.navInfoData["url"] == "newreport") {
          this.mileageForm.controls.claimName.patchValue(this.navInfoData['report']?.claimName);
        }
        break;
      case 2:
        if (this.userInfo.isCategoryBasedEnabled) {
          this.getCatPolicy('', '', '', 'amount', 'bulk');
        } else {
          this.getPolicyApi('', '', '', 'amount', 'bulk');
        }
        this.expcurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
        this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
        this.milecurrencySymbol = this.userInfo.baseCurrency.currencyIcon;
        this.active = 'end';
        this.type = 4;
        this.setDropDownValues();
        this.resetForNavChange();
        this.expenseTabIndex = 2;
        break;

    }
    if (this.GPSData) {
      this.expenseTabIndex = 1;
      this.active = 'middle';
    }
  }

  resetForNavChange() {
    // this.submitExpense = false;
    this.buttonDisabled = false;
    this.buttoncloseDisabled = false;
    this.imgUpload = false;
    // this.submitMileage = false;
    this.addReport = false;
    if (!this.attachFilename) {
      this.imgContent = "";
      this.expenseModel.expenseAttachments = [];
    }
    this.expenseForm.controls.expenseType.patchValue(1);
    this.expenseModel.expenseType = 1;
  }

  clearData() {
    // this.selectComponent.close();
    this.expenseForm.controls.categoryName.patchValue("");
    this.expenseForm.controls.categoryId.patchValue("");
  }

  apply() {
    // this.selectComponent.confirm();
    // this.selectComponent.close();
  }

  clearMerchantData() { }

  applyVehicle() {
    // this.vehicleComponent.confirm();
    // this.vehicleComponent.close();
  }

  applyReport() {
    // this.reportComponent.confirm();
    // this.reportComponent.close();
    this.addReport = false;
    this.expenseModel.claimName = "";
  }

  addNewReportweb() {
    this.addReport = true;
    switch (this.active) {
      case "top":
        this.expenseModel.claimId = null;
        this.expenseModel.claimName = null;
      case "bottom":
        this.totalBulkModel.claimId = null;
        this.totalBulkModel.claimName = null;
    }
  }

  addNewReport() {
    // this.reportComponent.close();
    this.addReport = true;
    this.expenseModel.claimId = null;
  }

  clearReport() { }

  clearExpReport() { }

  applyExpReport() {
    // this.reportExpComponent.confirm();
    // this.reportExpComponent.close();
    this.addExpNewReport = false;
    this.expenseModel.claimName = "";
  }

  addExpReport() {
    // this.reportExpComponent.close();
    this.addExpNewReport = true;
    this.expenseModel.claimId = null;
  }
  caputredImageModal = new CapturedImageModal();

  takePhoto() {
    const options: CameraOptions = {
      quality: 50,
      targetWidth: 400,
      targetHeight: 500,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.PNG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true,
    };
    this.camera.getPicture(options).then(
      (imageData) => {
        // this.capturedImage = (<any>window).Ionic.WebView.convertFileSrc(imgFileUri);
        let fileName = Math.random().toString(36).substr(2, 15) + ".png";
        this.capturedImage = "data:image/png;base64," + imageData;
        this.caputredImageModal.capturedImages.push(this.capturedImage);
        this.caputredImageModal.capturedImages.reverse();
        this.uploadedfiles = [];
        let _request = {
          fileName: fileName,
          content: this.capturedImage,
        };
        this.uploadedfiles.push(_request);
        this.uploadImage(this.capturedImage, fileName);
      },
      (err) => {
        // Handle error
      }
    );
  }

  expenseAttachmentsModel = new ExpenseAttachmentsModel();

  uploadImage(url, name, index?) {
    // console.log(url, name, index);

    if (this.totalBulkModel?.isCSVFile) {
      this.getBulkFormOCR();
    } else {
      this.getOcrData(url, name, index);
    }
  }

  getIndex() {
    this.slides.getActiveIndex().then((index) => {
      this.activeIndex = index;
    });
  }

  // openPreview() {
  //   this.modalController
  //     .create({
  //       component: ImageViewComponent,
  //       componentProps: {
  //         photo: this.caputredImageModal.capturedImages[this.activeIndex],
  //       },
  //     })
  //     .then((modal) => modal.present());
  // }


  selectCategoryOption(e, index: any, retainMerchnt?) {
    this.restrictClaimOnStartEndDates  = e.restrictClaimOnStartEndDates ? e.restrictClaimOnStartEndDates : false;
    this.showProofofpayment = e.isProofOfPaymentAttachmentRequired ? e.isProofOfPaymentAttachmentRequired : false;
    switch (this.active) {
      case "top":
        if (this.platformType == 1) {
          this.expenseForm.controls.categoryName.patchValue(e.categoryName);
        }
        if (e != null) {
          this.expenseModel.categoryId = e.categoryId;
          this.expenseModel.categoryName = e.categoryName;
          this.expenseModel.categoryCode = e.categoryCode;
          if (!retainMerchnt) {
            this.expenseModel.merchantName = "";
            this.expenseModel.merchantCode = "";
            this.expenseModel.taxNumber = "";
            this.expenseModel.taxPercentage = null;
          }
        }

        if (this.userInfo.isCategoryBasedEnabled && this.expenseModel.expensedDate != null && this.expenseModel.categoryId != null && this.expenseModel.originalAmount != null) {
          this.getCatPolicy(e.categoryId, this.expenseModel.expensedDate, 0, 'count', 'bulk');
        } else if (!this.userInfo.isCategoryBasedEnabled && this.expenseModel.expensedDate != null && this.expenseModel.categoryId != null && this.expenseModel.originalAmount != null) {
          this.getPolicyApi(e.categoryId, this.expenseModel.expensedDate, 0, 'count', 'bulk');
        }

        // this.policyCatCountValidation(e.categoryId);
        this.merchSelect = true;
        this.merchant = undefined;
        break;
      case "bottom":
        if (e != null) {
          console.log(e);
          if(e.categoryId != this.totalBulkModel.expenseRequestDTOList[index].categoryId) {
            this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch = false;
          }
          // console.log(index, this.index, this.totalBulkModel.expenseRequestDTOList);
          if (this.totalBulkModel.expenseRequestDTOList[index]) {
            this.totalBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = '';
            this.totalBulkModel.expenseRequestDTOList[index].categoryLimitAmountWarning = '';
            this.totalBulkModel.expenseRequestDTOList[index].categoryLimitReceiptWarning = '';
            this.totalBulkModel.expenseRequestDTOList[index].categoryCountLimitWarning = '';


            this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled = e.isStartEndDateEnabled;
            this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory = e.isSpentLocationManditory;
            this.totalBulkModel.expenseRequestDTOList[index].isPolicyCalcPerNight = e.isPolicyCalcPerNight;
            this.totalBulkModel.expenseRequestDTOList[index].restrictClaimOnStartEndDates = e.restrictClaimOnStartEndDates;
            this.totalBulkModel.expenseRequestDTOList[index].showProofofpayment = e.isProofOfPaymentAttachmentRequired;
            this.totalBulkModel.expenseRequestDTOList[index].isBillNumberManditory = e.isBillNumberManditory;
            if(e.isSpentLocationManditory && this.totalBulkForm?.controls?.claimTypeName?.value == 'Domestic') {
              this.totalBulkModel.expenseRequestDTOList[index].countryName = this.userInfo?.baseCurrency?.countryName;
              this.totalBulkModel.expenseRequestDTOList[index].countryId = this.userInfo?.baseCurrency?.countryId;
            }

            if(!e.isStartEndDateEnabled) {
              this.totalBulkModel.expenseRequestDTOList[index].expenseEndDate = null;
            }
            this.totalBulkModel.expenseRequestDTOList[index].isPerDayEnabled = (e.isPerDayEnabled && !e.allowAmountEdit) ? true : false;

            
          }
          if (this.previous_selected_value == '') {
            this.previous_selected_value = e.categoryName;
          } else {
            this.previous_selected_value = e.categoryId;
            // this.policyCatCountValidation(index);
            this.totalBulkModel.expenseRequestDTOList[index].categoryCountLimitWarning = false;
            if (this.totalBulkModel.expenseRequestDTOList[index].originalAmount != '') {
              // this.policyCatCommonMethod(index);
            }
          }
          this.totalBulkModel.expenseRequestDTOList[index].categoryType = e.categoryType;
          this.totalBulkModel.expenseRequestDTOList[index].categoryName = e.categoryName;
          this.totalBulkModel.expenseRequestDTOList[index].categoryId = e.categoryId;
          this.totalBulkModel.expenseRequestDTOList[index].categoryCode = e.categoryCode;
          this.totalBulkModel.expenseRequestDTOList[index].productCode = e.productId;
          this.totalBulkModel.expenseRequestDTOList[index].productId = null;
          this.totalBulkModel.expenseRequestDTOList[index].merchantName = "";
          this.totalBulkModel.expenseRequestDTOList[index].loader = false;
          this.totalBulkModel.expenseRequestDTOList[index];
          if (e?.merchantList) {
            this.totalBulkModel.expenseRequestDTOList[index].merchantList = e.merchantList.map((list) => new MerchantList(list));
            this.merchanttempList = e.merchantList.map((list) => new MerchantList(list));
          }

          // if(GST)
          if (this.userInfo?.restrictGSTNumberMissMatch && this.totalBulkModel?.expenseRequestDTOList[index]?.expenseAttachments?.length > 0 && (e?.productCode == 2 || e?.productId == 2)) {
            let gstMissMatch = this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch;
            let companyGstNumber = this.totalBulkModel.expenseRequestDTOList[index].companyGstNumber;
            this.totalBulkModel?.expenseRequestDTOList[index]?.expenseAttachments?.forEach(ea => {
              console.log(gstMissMatch, ea);
              if (!gstMissMatch) {
                if (!ea?.gstNumber?.trim()?.includes(companyGstNumber?.trim()) && !companyGstNumber?.trim()?.includes(ea?.gstNumber?.trim())) {
                  let totalAmount = this.strToNum(this.totalBulkModel.expenseRequestDTOList[index].originalAmount);
                  let taxAmount = this.strToNum(this.totalBulkModel.expenseRequestDTOList[index].taxAmount);
                  this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].originalAmount = (totalAmount || 0) - (taxAmount || 0);
                  this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch = true;
                  this.totalBulkModel.expenseRequestDTOList[index].taxAmount = 0;
                  this.totalBulkModel.expenseRequestDTOList[index].taxPercentage = 0;
                }
              }
            });
          }

          if (this.userInfo?.restrictMobileBillMissMatch && e?.isMobile && e.categoryId && this.totalBulkModel?.expenseRequestDTOList[index]?.expenseAttachments?.length > 0) {
            let mobileMissMatch = this.totalBulkModel.expenseRequestDTOList[index].mobileMissMatch;
            this.totalBulkModel?.expenseRequestDTOList[index]?.expenseAttachments?.forEach(ea => {
              console.log(mobileMissMatch, ea);
              if (!mobileMissMatch && (!this.userInfo?.mobile.includes(ea?.PhoneNumber?.replace(/[\s-]/g, '')) && !ea.PhoneNumber?.replace(/[\s-]/g, '')?.includes(this.userInfo?.mobile))) {
                this.totalBulkModel.expenseRequestDTOList[index].mobileMissMatch = true;
              }
            });
          }
        }
        break;
    }
    if (this.showText) {
      this.itemWiseCatRecept();
    } else {
      this.combRecpt();
    }

    this.catagoryDesc();
    this.categoryCheck();
    this.merchantList = e?.merchantList;
  }

  selectPediemOption(e) {
    this.perdiemRateList = [];
    let val = e?.option?.value;
    // this.policyList.policyPerDiemList.forEach(element => {
    //   if (element.countryName == val) {
    //     this.selectedPolicyPerdiem = element;
    //     element.isAllowedWithReceipt == true ? this.perdiemRateList.push("Rate with Receipt") : "";
    //     element.isAllowedWithoutReceipt == true ? this.perdiemRateList.push("Rate without Receipt") : "";
    //   }
    // });
  }

  selectedPerdiemOprion(value, index?) {
    this.perdiemRateList = [];
    this.selectedPolicyPerdiem = value;
    value.isAllowedWithReceipt == true ? this.addPerdiemRateList("Rate with Receipt") : "";
    value.isAllowedWithoutReceipt == true ? this.addPerdiemRateList("Rate without Receipt") : "";
    if (this.userInfo.isCategoryBasedEnabled) {
      this.approverlist = this.policyList.approversList
      //this.getCatPolicy('','', 0, '', '');
    } else if (!this.userInfo.isCategoryBasedEnabled) {
      this.approverlist = this.policyList.approversList
      //this.getPolicyApi('', '', 0, '', '');
    }
  }

  selectFinanceIntCode(item, form: FormGroup, type?) {
    if (type == 'perdiem') {
      this.perdiemModel.PDtaxNumner = item.financeCodeName;
      this.sampleDt[0].financeIntegrationId = item.financeIntegrationId;
      this.sampleDt[0].financeIntegrationCode = item.financeIntegrationCode;
      this.sampleDt[0].financeIntegrationName = item.financeIntegrationName;
    } else {
      form.controls.financeIntegrationCode.patchValue(item.financeCodeName);
      form.controls.financeIntegrationId.patchValue(item.financeIntegrationId);
      this.showFinanceCodeList = false;
    }
    // if (this.expenseTabIndex == 0) {
    // } else if (this.expenseTabIndex == 1) {
    // this.MileagebulkItemsForms.value[index].financeIntegrationCode = item.financeCodeName;
    // this.MileagebulkItemsForms.value[index].financeIntegrationId = item.financeIntegrationId;
    // }
  }

  selectTaxCode(item, form?: FormGroup, type?) {
    if (type == 'perdiem') {
      this.perdiemModel.PDtaxPercentage = item.taxCode;
      this.sampleDt[0].taxCode = item.taxCode;
      this.sampleDt[0].taxCodeId = item.taxCodeId;
    } else {
      console.log('selected values :', item);
      form.controls.taxCode.patchValue(item.taxCode);
      form.controls.taxCodeId.patchValue(item.taxCodeId);
    }
  }

  selectHsnCode(item, form?: FormGroup, type?) {
    if (type == 'perdiem') {
      this.perdiemModel.PDhsnCode = item.hsnCode;
      this.sampleDt[0].hsnId = item.hsnId;
      this.sampleDt[0].hsnCode = item.hsnCode;
    } else {
      console.log('selected values :', item);
      form.controls.hsnCode.patchValue(item.hsnCode);
      form.controls.hsnCodeId?.patchValue(item.hsnId);
      form.controls.hsnId?.patchValue(item.hsnId);
      // this.expenseForm.controls.hsnCode.patchValue(item.hsnCode);
      // this.MileagebulkItemsForms.value[0].hsnCode = item.hsnCode;
      // this.MileagebulkItemsForms.value[0].hsnId = item.hsnId;
      // this.expenseModel.hsnCode = item.hsnCode;
      // this.expenseModel.hsnId = item.hsnId;
      this.showHsnCodeList = false;
    }
  }


  catagoryDesc() {
    let item = this.policyList?.policyCategoryList?.find(
      (elem) => this.expenseModel.categoryId == elem.categoryId
    );
    this.categDesc = item?.isDescriptionRequired;
  }

  merchantChange(e) {
    if (e != null) {
      this.expenseModel.merchantName = e.merchantName;
    }
  }

  onSearchFail(e) {
    if (e.component.items == undefined || []) {
      this.merchanttemp = e.component.searchText;
      this.searchFail = true;
    }
  }

  onSearchSuccess(e) {
    this.merchanttemp = e.component.searchText;
    this.searchFail = true;
  }

  merchantSelect(e) {
    if (e.isSelected) {
      this.searchFail = false;
      //  this.expenseModel.merchantName = e.merchantName
      // this.merchant = e.item.merchantName
      this.merchant = undefined;
    } else if (!e.isSelected) {
      this.searchFail = true;
    }
  }

  applyMerchant() {
    // this.selectMerchantComponent.confirm();
    // this.selectMerchantComponent.close();

    if (this.searchFail && this.merchanttemp !== "") {
      this.expenseModel.merchantName = this.merchanttemp;
      this.merchant = this.merchanttemp;
      let merchantObj = { merchantName: this.merchant };
      this.merchantList.push(merchantObj);
      this.merchantList = [
        new Map(
          this.merchantList.map((item) => [item["merchantName"], item])
        ).values(),
      ];
      this.expenseForm.controls.merchantName.patchValue({
        merchantName: merchantObj.merchantName,
      });
      this.merchant = undefined;
    } else {
      this.merchant = undefined;
    }
    this.searchFail = false;
  }

  expenseDate(e, index: any, fromDoubleDate?) {
    var date: any;
    if(fromDoubleDate) {
      date = e;
    } else {
      if (this.platformType == 1) {
        date = this.datechangeService.convertCalendarToAPI(e.target.value);
      } else {
        date = moment(new Date(e.detail.value)).format(
          Constants.mobileDateFormat
        );
        // console.log('status :', this.active);
      }
    }
    console.log('DATE _______ ', date);
    console.log('status :', this.active);
    
    switch (this.active) {
      case "top":
        this.expenseModel.expensedDate = date;
        if (this.userInfo.isCategoryBasedEnabled && this.expenseModel.expensedDate != null && this.expenseModel.categoryId != null && this.expenseModel.originalAmount != null) {
          this.getCatPolicy(this.expenseModel.categoryId, this.expenseModel.expensedDate, 0, 'amount', 'bulk');
        } else if (!this.userInfo.isCategoryBasedEnabled && this.expenseModel.expensedDate != null && this.expenseModel.categoryId != null && this.expenseModel.originalAmount != null) {
          this.getPolicyApi(this.expenseModel.categoryId, this.expenseModel.expensedDate, 0, 'amount', 'bulk');
        }
      case "bottom":
        if(this.dp_type == 'expenseEndDate') {
          this.totalBulkModel.expenseRequestDTOList[index].expenseEndDate = date;
        } else {
          this.dateDisableValue = false;
          this.totalBulkModel.expenseRequestDTOList[index].expensedDate = date;
          if (new Date(date) < new Date(this.userInfo.allowedExpenseDate)) {
            this.totalBulkModel.expenseRequestDTOList[index].expenseDateExceed = true;
          } else if (new Date(date) > new Date(this.userInfo.allowedExpenseDate)) {
            this.totalBulkModel.expenseRequestDTOList[index].expenseDateExceed = false;
          }
        }
      case "end":
        this.perdiemForm.controls["PDexpenseDate"].setValue(
          this.convertDateToUtc(date)
        );
        this.perdiemModel.PDexpenseDate = date;
    }
  }

  endDateSelection(e,index){
    // console.log('sdsdsd',e);
  }


  convertAmt() {
    this.total = 0;
    for (var i = 0; i < this.expenseItemsArray.controls.length; i++) {
      let amt = this.expenseItemsArray.controls[i]?.get("amount").value;
      if (amt) {
        this.total = this.total + Number(amt);
      }
    }
    let userBaseCurr = this.userInfo.baseCurrency.currencyId;
    //edit
    if (this.editlist) {
      if (this.editlist.exchangeRate) {
        this.exchangeAmt = this.total * this.editlist.exchangeRate;
      }
      if (userBaseCurr && !this.editlist.exchangeRate) {
        this.exchangeAmt = this.total;
      }
    }
    // If we don't select and enter money direct
    if (userBaseCurr && !this.editlist) {
      this.exchangeAmt = this.total;
    }
    // if we select same currenct and enter
    if (this.selectedBaseCurr !== undefined) {
      if (userBaseCurr == this.selectedBaseCurr.currencyId) {
        this.exchangeAmt = this.total;
      }
      // if we select diffrent currency.
      if (this.selectedBaseCurr) {
        if (userBaseCurr !== this.selectedBaseCurr.currencyId) {
          this.exchangeAmt = this.total * this.selectedBaseCurr.conversionRate;
        }
      }
      // for null conversion rate -- for temporary
      if (this.selectedBaseCurr.conversionRate == null) {
        this.exchangeAmt = this.total;
      }
    }
    if (this.exchangeAmt > this.policyList?.receiptRequiredLimit) {
      this.receiptWarning = true;
    } else {
      this.receiptWarning = false;
    }
    this.itemWiseCatRecept();
    if (this.exchangeAmt > this.policyList?.expenseAmountLimit) {
      this.expenseWarning = true;
    } else {
      this.expenseWarning = false;
    }
    this.expenseToalAmt = this.exchangeAmt;
    this.expenseModel.originalAmount = this.total;
    this.splitExpense();
  }

  itemWiseCatRecept() {
    if (this.policyList?.policyCategoryList) {
      let elm = this.policyList?.policyCategoryList.find(
        (elm) => elm.categoryId == this.expenseModel.categoryId
      );
      if (elm) {
        if (this.exchangeAmt > elm.receiptRequiredLimit) {
          this.receiptCatWarning = true;
        } else {
          this.receiptCatWarning = false;
        }
        if (this.exchangeAmt > elm.expenseAmountLimit) {
          this.expenseCatWarning = true;
        } else {
          this.expenseCatWarning = false;
        }
      } else {
        this.receiptCatWarning = false;
        this.expenseCatWarning = false;
      }
    } else {
      this.receiptCatWarning = false;
      this.expenseCatWarning = false;
    }
  }

  // itemWiseRuleRecept() {
  //   if (this.policyList?.policyCategoryRuleList) {
  //     let elm = this.policyList?.policyCategoryRuleList.find(
  //       (elm) => elm.categoryId == this.expenseModel.categoryId
  //     );
  //     if (elm) {
  //       if (this.exchangeAmt > elm.receiptRequiredLimit) {
  //         this.receiptCatWarning = true;
  //       } else {
  //         this.receiptCatWarning = false;
  //       }
  //     } else {
  //       this.receiptCatWarning = false;
  //       this.expenseCatWarning = false;
  //     }
  //   } else {
  //     this.expenseRuleWarning = false;
  //   }
  // }

  combinedAmt() {
    if (this.fromPettyCash) {
      return;
    }
    let amountValidation = this.expenseModel.originalCurrencyId == 64 ? this.expenseModel.originalAmount : (this.expenseModel.exchangeRate * this.expenseModel.originalAmount);
    if (this.policyList?.receiptRequiredLimit) {
      if (amountValidation > this.policyList.receiptRequiredLimit) {
        this.receiptWarning = true;
      } else {
        this.receiptWarning = false;
      }
    } else {
      this.receiptWarning = false;
    }
    if (this.policyList?.expenseAmountLimit) {
      if (amountValidation > this.policyList.expenseAmountLimit) {
        this.expenseWarning = true;
      } else {
        this.expenseWarning = false;
      }
    } else {
      this.expenseWarning = false;
    }
    this.combRecpt();
  }

  combRecpt() {
    let elm = this.policyList?.policyCategoryList?.find(
      (elm) => elm.categoryId == this.expenseModel.categoryId
    );
    let amountValidation = this.expenseModel.originalCurrencyId == 64 ? this.expenseModel.originalAmount : (this.expenseModel.exchangeRate * this.expenseModel.originalAmount);
    if (elm) {
      if (amountValidation > elm.receiptRequiredLimit) {
        this.receiptCatWarning = true;
      } else {
        this.receiptCatWarning = false;
      }
      if (amountValidation > elm.expenseAmountLimit) {
        this.expenseCatWarning = true;
      } else {
        this.expenseCatWarning = false;
      }
    } else {
      this.receiptCatWarning = false;
      this.expenseCatWarning = false;
    }
  }

  bulkAmountValidation(index?) {
    if (this.totalBulkModel.expenseRequestDTOList[index]) {
      this.totalBulkModel.expenseRequestDTOList[index].policyLimitAmountWarning = '';
      this.totalBulkModel.expenseRequestDTOList[index].policyLimitReceiptWarning = '';
    }
    // const expenseDate = this.totalBulkModel.expenseRequestDTOList[index].expensedDate;

    if ((this.totalBulkModel.expenseRequestDTOList[index]?.isStartEndDateEnabled && !this.totalBulkModel.expenseRequestDTOList[index]?.isSpentLocationManditory && this.totalBulkModel.expenseRequestDTOList[index]?.expenseEndDate != null) ||
        (this.totalBulkModel.expenseRequestDTOList[index]?.isSpentLocationManditory && !this.totalBulkModel.expenseRequestDTOList[index]?.isStartEndDateEnabled) ||
        (this.totalBulkModel.expenseRequestDTOList[index]?.isSpentLocationManditory && this.totalBulkModel.expenseRequestDTOList[index]?.isStartEndDateEnabled && this.totalBulkModel.expenseRequestDTOList[index]?.expenseEndDate != null) ||
        (!this.totalBulkModel.expenseRequestDTOList[index]?.isStartEndDateEnabled && !this.totalBulkModel.expenseRequestDTOList[index]?.isSpentLocationManditory)) {

        if (this.totalBulkModel.expenseRequestDTOList[index]?.categoryId != null && this.totalBulkModel.expenseRequestDTOList[index].expensedDate) {
          if (this.userInfo.isCategoryBasedEnabled) {
            this.getCatPolicy(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'amount', 'bulk');
          } else {
            this.getPolicyApi(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'amount', 'bulk');
          }
        }
      }else{
        this.totalBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = false;
        this.totalBulkModel.expenseRequestDTOList[index].categoryLimitAmountWarning = false;
        this.totalBulkModel.expenseRequestDTOList[index].categoryLimitReceiptWarning = false;
        this.totalBulkModel.expenseRequestDTOList[index].policyLimitReceiptWarning = false;
        this.totalBulkModel.expenseRequestDTOList[index].policyLimitAmountWarning = false;
      }

    // if (this.totalBulkModel.expenseRequestDTOList[index].originalAmount != '' && this.totalBulkModel.expenseRequestDTOList[index].categoryId != '' && expenseDate && expenseDate != '') {
    //   if (this.userInfo.isCategoryBasedEnabled) {
    //     this.getCatPolicy(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'amount', 'bulk');
    //   } else {
    //     this.getPolicyApi(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'amount', 'bulk');
    //   }
    // } else {
    //   this.totalBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = false;
    //   this.totalBulkModel.expenseRequestDTOList[index].categoryLimitAmountWarning = false;
    //   this.totalBulkModel.expenseRequestDTOList[index].categoryLimitReceiptWarning = false;
    //   this.totalBulkModel.expenseRequestDTOList[index].policyLimitReceiptWarning = false;
    //   this.totalBulkModel.expenseRequestDTOList[index].policyLimitAmountWarning = false;
    // }
  }

  mileageDistanceValidation(index?) {
    const expenseDate = this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate;
    if (this.totalMileageBulkModel.expenseRequestDTOList[index].categoryName != '' && expenseDate && expenseDate != '' && this.totalMileageBulkModel.expenseRequestDTOList[index].distance != '') {

      if (this.userInfo.isCategoryBasedEnabled) {
        this.getCatPolicy(this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId, this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate, index, '', 'mileage');
      } else {
        this.getPolicyApi(this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId, this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate, index, '', 'mileage');
      }
    } else {
      this.totalMileageBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = false;
    }
  }

  bulkMultiplicationSum(firstV: any, secondV: any) {
    return firstV * secondV;
  }

  // combMileageDistance() {

  //     let elm = this.policyList?.policyMileageRuleList?.find(elm => elm.categoryId == this.expenseModel.categoryId);
  //     if (elm) {

  //         if (this.expenseModel.distance > elm.limitValue) {
  //             this.distanceViolationMiles = true;
  //         } else {
  //             this.distanceViolationMiles = false;
  //         }
  //     }
  // }



  editechangecurrencypopup(e, i, editechangecurrencypop?: TemplateRef<any>) {
    this.selectedIndex = i;
    this.currencyCode = this.totalBulkModel.expenseRequestDTOList[i].currencyCode;
    this.userexpConvRate = this.totalBulkModel.expenseRequestDTOList[i].expConvRate;
    this.editexchange = this.totalBulkModel.expenseRequestDTOList[i].editedExchangeRate ? this.totalBulkModel.expenseRequestDTOList[i].editedExchangeRate : this.totalBulkModel.expenseRequestDTOList[i].expConvRate;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(editechangecurrencypop, config);
  }

  updatecurrencyexchange() {
    // this.totalBulkModel.expenseRequestDTOList[this.selectedIndex].editedExchangeRate=this.editexchange.toFixed(2); 
    // //this.totalBulkModel.expenseRequestDTOList[this.selectedIndex].expConvRate = this.totalBulkModel?.expenseRequestDTOList[this.selectedIndex].editedExchangeRate;
    // console.log("exchangerate",this.totalBulkModel.expenseRequestDTOList[0].expConvRate)
    // this.modalRef.hide();
    // this.getTotalAmount();
    // Ensure that this.editexchange is a number
    const editedExchangeRate = parseFloat(this.editexchange.toString());

    if (!isNaN(editedExchangeRate)) {
      const formattedRate = editedExchangeRate.toFixed(2);
      const editexchange = parseFloat(formattedRate).toString();
      this.totalBulkModel.expenseRequestDTOList[this.selectedIndex].editedExchangeRate = editexchange;
      this.modalRef.hide();
      this.getTotalAmount();
    } else {
      console.error("Invalid editedExchangeRate value.");
      // Handle the case where this.editexchange is not a valid number
    }
  }

  selectPaidBy(e, index, form: FormGroup) {
    this.totalBulkModel.expenseRequestDTOList[index].paidBy = e.id;
    this.totalBulkModel.expenseRequestDTOList[index].paidByName = e.name;
    form.controls.paidBy.patchValue(e.id);
    form.controls.paidByName.patchValue(e.name);
  }


  selectCurrencyOption(e, index: any) {
    this.mouseover = false;
    switch (this.active) {
      case "top":
        this.selectedBaseCurr = e;
        this.exchangeAmt = 0;
        if (this.platformType == 1) {
          this.expenseForm.controls.originalCurrencyCode.patchValue(
            e.currencyCode
          );
        }
        if (e != null) {
          this.expenseModel.originalCurrencyId = e.currencyId;
          this.expenseModel.originalCurrencyCode = e.currencyCode;
          this.expenseModel.originalCurrencyIcon = e.currencyIcon;
          this.expenseModel.originalCurrencyName = e.currencyCode;
          this.expcurrencySymbol = e.currencyCode;
          this.expcurrencyIcon = e.currencyIcon;
          this.expConvRate = e.conversionRate ? e.conversionRate : 0;
          this.expenseModel.exchangeRate = e.conversionRate;
        }

        if (this.expenseModel.originalAmount !== undefined) {
          this.exchangeAmt = this.expConvRate * this.exchangeAmt;
        }
        this.exchangeAmt = this.expConvRate * this.total;
        if (this.expConvRate == null) {
          this.exchangeAmt = this.total;
        }
        this.showCurrencyList = false;
      case "bottom":
        if (e != null) {
          this.editechangecurrency = e
          this.totalBulkModel.expenseRequestDTOList[index].currencyCode = e.currencyCode;
          this.totalBulkModel.expenseRequestDTOList[index].originalCurrencyId = e.currencyId;
          this.totalBulkModel.expenseRequestDTOList[index].showList = false;
          this.totalBulkModel.expenseRequestDTOList[index].originalCurrencyIcon = e.currencyIcon;
          this.totalBulkModel.expenseRequestDTOList[index].expConvRate = e.conversionRate ? e.conversionRate : 1;
          this.totalBulkModel.expenseRequestDTOList[index].exchangeRate = e.conversionRate ? e.conversionRate : 1;
          this.totalBulkModel.expenseRequestDTOList[index].editedExchangeRate = e.conversionRate ? e.conversionRate : null;

          console.log('after call',e);
          // this.getMilageStates(e.currencyId);

          this.getTotalAmount();
        }

        this.showCurrencyList = false;
      case "mobile":
        if (e != null) {
          this.expenseModel.originalCurrencyId = e.currencyId;
          this.expcurrencySymbol = e.currencyCode;
          this.expcurrencyIcon = e.currencyIcon;
          this.expConvRate = e.conversionRate ? e.conversionRate : 0;
        }
        this.showCurrencyList = false;
      case "end":
        this.perdiemModel.PDoriginalCurrency = e.currencyCode;
        this.perdiemModel.PDoriginalCurrencyConvertedRate = e.conversionRate;
        this.perdiemModel.PDoriginalCurrencyIcon = e.currencyIcon;
        this.perdiemModel.PDoriginalCurrencyId = e.currencyId;
        this.perdiemForm.controls["PDoriginalCurrency"].setValue(
          e.currencyCode
        );
    }
    console.log(this.editechangecurrency);

  }

  citySelection(event, index, input, form?){
    console.log(index, input, event);
    

    if(input == 'country'){
      this.totalBulkModel.expenseRequestDTOList[index].countryName = event.countryName;
      this.totalBulkModel.expenseRequestDTOList[index].countryId = event.countryId;

      // check TOGGLE then List
      const isCityMandatory = this.userInfo?.makeCityManadatoryCountryIds?.findIndex(m => m == event.countryId);
      // const isCityMandatory = ["64","146"].findIndex(m => m == event.countryId);
      if(isCityMandatory >= 0 && this.userInfo?.makeCityManadatoryCountry) {
          // console.log('Manadatory ____________');
          form.controls['cityName'].setValidators([Validators.required]);
          form.controls['cityName'].updateValueAndValidity();
      } else {
          // console.log('Non - Manadatory ____________');
          form.controls['cityName']?.setValidators([]);
          form.controls['cityName']?.updateValueAndValidity();
      }

    }else{
      if(event.cityId != this.totalBulkModel.expenseRequestDTOList[index].cityId) {
        this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch = false;
      }

      this.selectedCategoryIndex = index;
      this.totalBulkModel.expenseRequestDTOList[index].cityId = event.cityId;
      this.totalBulkModel.expenseRequestDTOList[index].cityName = event.cityName;
      this.totalBulkModel.expenseRequestDTOList[index].stateTin = event.stateTin;
      this.totalBulkModel.expenseRequestDTOList[index].companyGstNumber = event.gstNumber;

      console.log(this.userInfo?.restrictGSTNumberMissMatch && this.totalBulkModel?.expenseRequestDTOList[index]?.expenseAttachments?.length > 0 && (this.totalBulkModel.expenseRequestDTOList[index]?.productCode == 2 || this.totalBulkModel.expenseRequestDTOList[index]?.productId == 2));
      if (this.userInfo?.restrictGSTNumberMissMatch && this.totalBulkModel?.expenseRequestDTOList[index]?.expenseAttachments?.length > 0 && (this.totalBulkModel.expenseRequestDTOList[index]?.productCode == 2 || this.totalBulkModel.expenseRequestDTOList[index]?.productId == 2)) {
        let gstMissMatch = this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch;
        let companyGstNumber = this.totalBulkModel.expenseRequestDTOList[index].companyGstNumber;
        this.totalBulkModel?.expenseRequestDTOList[index]?.expenseAttachments?.forEach(ea => {
          console.log(gstMissMatch, ea);
          if (!gstMissMatch) {
            if (!ea?.gstNumber?.trim()?.includes(companyGstNumber?.trim()) && !companyGstNumber?.trim()?.includes(ea?.gstNumber?.trim())) {
              this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch = true;
              this.totalBulkModel.expenseRequestDTOList[index].taxAmount = 0;
              this.totalBulkModel.expenseRequestDTOList[index].taxPercentage = 0;
            }
          }
        });
      }


      // if(this.extractData?.GSTNumber && this.extractData?.GSTNumber != '' && !this.userInfo?.restrictGSTNumberMissMatch) {
      //   let gstMissMatch = this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch;
      //   // let stateTin = this.totalBulkModel.expenseRequestDTOList[index].stateTin;
      //   let companyGstNumber = this.totalBulkModel.expenseRequestDTOList[index].companyGstNumber;
      //   // let companyGstNumber = '36AAEFH181QGA1-1IZX';
      //   // this.totalBulkModel.expenseRequestDTOList[index].ocrGSTNumbers.push(this.extractData?.GSTNumber);
      //   attachement.gstNumber = this.extractData?.GSTNumber;
        
      //   // 36AAEFH181QGAIZX
      //   if (!gstMissMatch && companyGstNumber) {
      //     if (!this.extractData?.GSTNumber?.trim()?.includes(companyGstNumber?.trim())) {
      //       this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch = true;
      //       attachement.gstMissMatch = true;
      //       this.extractData['TaxAmount'] = 0;
      //       this.extractData['TaxPercentage'] = 0;
      //       this.totalBulkModel.expenseRequestDTOList[index].taxAmount = 0;
      //       this.totalBulkModel.expenseRequestDTOList[index].taxPercentage = 0;
      //     }
      //   }
      // }



      if ((this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled && !this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory && this.totalBulkModel.expenseRequestDTOList[index].expenseEndDate != null) ||
        (this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory && !this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled && this.totalBulkModel.expenseRequestDTOList[index].cityId != null) ||
        (this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory && this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled && this.totalBulkModel.expenseRequestDTOList[index].expenseEndDate != null && this.totalBulkModel.expenseRequestDTOList[index].cityId != null) ||
        (!this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled && !this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory)) {

        if (this.totalBulkModel.expenseRequestDTOList[index]?.categoryId != null && this.totalBulkModel.expenseRequestDTOList[index].expensedDate) {
          if (this.userInfo.isCategoryBasedEnabled) {
            this.getCatPolicy(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'count', 'bulk');
          } else {
            this.getPolicyApi(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'count', 'bulk');
          }
        }
      }

    }
  }

  callingCities(index,input){
    // console.log('selected index :',index,this.totalBulkModel.expenseRequestDTOList[index]);
    if(input == 'country'){
      this.settingService.getCountries({}).subscribe(data => {
        this.countryList = data.payload;
      });
    }else{
      this.getMilageStates(this.totalBulkModel.expenseRequestDTOList[index]?.countryId);
    }
  }

  selectCombinedOrItemwise(event) {
    this.expenseModel.expenseType = event.detail.value;
    this.resetSplitExtractAmount = true;
    this.resetSplitExtractAmountPer = true;
    if (event.detail.value == 2) {
      this.onTabChanged();
      this.expenseForm.get("originalAmount").setValidators(null);
      this.expenseForm.get("originalAmount").updateValueAndValidity();
      let itemsArray: any = (this.expenseForm.get("expenseItems") as FormArray)
        .controls;
      for (var item of itemsArray) {
        item.controls["name"].setValidators([Validators.required]);
        //item.controls["amount"].setValidators([Validators.required]);
        item.controls["amount"].setValidators([
          Validators.required,
          Validators.maxLength(8),
          Validators.min(1),
        ]);
      }
      this.showText = true;

      if (this.exchangeAmt > this.policyList?.receiptRequiredLimit) {
        this.receiptWarning = true;
      } else {
        this.receiptWarning = false;
      }

      if (this.exchangeAmt > this.policyList?.expenseAmountLimit) {
        this.expenseWarning = true;
      } else {
        this.expenseWarning = false;
      }
      // this.expenseForm.controls.expenseItemsArray. taxPercentage
      this.selectedMerchant ? this.expenseItemsArray.at(0).get('taxPercentage').setValue(this.selectedMerchant.taxPercentage) : ''
      // this.expenseItemsArray.controls.taxPercentage.patchValue("10");
      this.itemWiseCatRecept();
    } else {
      let itemsArray: any = (this.expenseForm.get("expenseItems") as FormArray)
        .controls;
      for (var item of itemsArray) {
        item.controls["name"].setValidators(null);
        item.controls["amount"].setValidators(null);
        item.get("name").updateValueAndValidity();
        item.get("amount").updateValueAndValidity();
      }
      this.expenseForm.get("expenseItems").updateValueAndValidity();
      this.expenseForm
        .get("originalAmount")
        .setValidators([Validators.required]);
      this.expenseForm.get("originalAmount").updateValueAndValidity();

      this.showText = false;

      if (
        this.expenseModel.originalAmount > this.policyList?.receiptRequiredLimit
      ) {
        this.receiptWarning = true;
      } else {
        this.receiptWarning = false;
      }
      if (
        this.expenseModel.originalAmount > this.policyList?.expenseAmountLimit
      ) {
        this.expenseWarning = true;
      } else {
        this.expenseWarning = false;
      }
      this.combRecpt();
    }
  }

  selectreportOption(e) {
    switch (this.active) {
      case "top":
        if (this.platformType == 1) {
          if (e != undefined) {
            this.expenseForm.controls.claimName.patchValue(e.claimName);
          }
        }
        if (e != null) {
          this.addReport = false;
          this.expenseModel.claimId = e.claimId;
        }
      case "bottom":
        if (this.platformType == 1) {
          if (e != undefined) {
            this.totalBulkForm.controls.claimName.patchValue(e.claimName);
          }
        }

        if (e != null) {
          this.addReport = false;
          this.totalBulkModel.claimId = e.claimId;
          this.totalBulkModel.claimName = e.claimName;
        }
    }
  }

  selectCategoryMileageOption(e, index?, form?) {
    console.log(index, e);
    
    if (this.platformType == 1) {
      this.MileagebulkItemsForms.value[index].categoryName = e.categoryName;
      this.MileagebulkItemsForms.value[index].categoryId = e.categoryId;
      this.MileagebulkItemsForms.value[index].categoryCode = e.categoryCode;
      this.MileagebulkItemsForms.value[index].mileageRate = e.mileageRate;
      this.MileagebulkItemsForms.value[index].mileageUnit = e.mileageUnit;
      this.MileagebulkItemsForms.value[index].mandateFromToLocations = e.mandateFromToLocations;
      this.totalMileageBulkModel.expenseRequestDTOList[index].mileageRate = e.mileageRate
      this.totalMileageBulkModel.expenseRequestDTOList[index].rate = e.mileageRate;
      this.totalMileageBulkModel.expenseRequestDTOList[index].mileageUnit = e.mileageUnit;
      this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId = e.categoryId;
      this.totalMileageBulkModel.expenseRequestDTOList[index].categoryName = e.categoryName;
      this.totalMileageBulkModel.expenseRequestDTOList[index].categoryCode = e.categoryCode;
      this.totalMileageBulkModel.expenseRequestDTOList[index].isAutoCalculateDistanceAmount = e.isAutoCalculateDistanceAmount ?? true;
      this.totalMileageBulkModel.expenseRequestDTOList[index].mandateFromToLocations = e.mandateFromToLocations;
      this.totalMileageBulkModel.expenseRequestDTOList[index].isBillNumberManditory = e.isBillNumberManditory;
      form.controls['invoiceNumber'].setValidators(e.isBillNumberManditory ? [Validators.required] : []);
      form.controls['invoiceNumber']?.updateValueAndValidity();
      if (e.mandateFromToLocations) {
        form.controls['fromLocationName'].setValidators([Validators.required]);
        form.controls['toLocationName'].setValidators([Validators.required]);
      } else {
        form.controls['fromLocationName']?.setValidators([]);
        form.controls['toLocationName']?.setValidators([]);
      }
      form.controls['fromLocationName']?.updateValueAndValidity();
      form.controls['toLocationName']?.updateValueAndValidity();

      if (this.totalMileageBulkModel.expenseRequestDTOList[index].isAutoCalculateDistanceAmount) {
        form.controls['distance'].setValidators([Validators.required]);
      } else {
        form.controls['distance']?.setValidators([]);
      }
      form.controls['distance']?.updateValueAndValidity();

      const cName = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryName;
      const eDate = this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate;
      const dist = this.totalMileageBulkModel.expenseRequestDTOList[index].distance;

      // console.log(cName && cName != '');
      // console.log(eDate && eDate != '');
      // console.log(dist && dist != '');
      // console.log(cName && cName != '' && eDate && eDate != '' && dist && dist != '');

      if (cName && cName != '' && eDate && eDate != '' && dist && dist != '') {
        if (this.userInfo.isCategoryBasedEnabled) {
          this.getCatPolicy(this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId, this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate, index, '', 'mileage');
        } else {
          this.getPolicyApi(this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId, this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate, index, '', 'mileage');
        }
      }

    }
    if (e != null) {
      // this.expenseModel.categoryId = e.categoryId;
      // this.expenseModel.mileageUnit = e.mileageUnit;
      // this.expenseModel.mileageRate = e.mileageRate;
      this.mileageRate = e.mileageRate
        ? e.mileageRate + " " + e.mileageRateCurrencyCode + "/" + e.mileageUnit
        : "";
      this.milRate = e.mileageRate;
      if (this.expenseModel.distance !== undefined) {
        // this.expenseModel.originalAmount =
        //   this.expenseModel.distance * this.milRate;

        // if (this.countDecimals(this.expenseModel.distance * this.milRate) > 2) {
        //   const first3Str = String(this.expenseModel.distance * this.milRate).slice(0, 4); // 👉️ '13'
        //   const first3Num = Number(first3Str); // 👉️ 13
        //   this.expenseModel.originalAmount = first3Num
        // } else {
        //   this.expenseModel.originalAmount = this.expenseModel.distance * this.milRate;
        // }

        // this.milageTotalAmt = this.milageConvRate * this.expenseModel.originalAmount;
      }
    }
    //this.combMileageDistance();
  }

  mileageDate(e, index, fromDoubleDate?) {
    let date: any;
    if(fromDoubleDate) {
      date = e;
    } else {
      date = this.datechangeService.convertCalendarToAPI(e.target.value);
    }
    if (this.platformType == 1) {
      this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate = date;
      // this.MileagebulkItemsForms.controls[index].value.expensedDate = moment(date, "DD-MM-YYYY").toDate();
      this.MileagebulkItemsForms.controls[index].patchValue({ expensedDate: moment(date, "DD-MM-YYYY").toDate() });

      if (this.totalMileageBulkModel.expenseRequestDTOList[index].categoryName != '' && this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate != '' && this.totalMileageBulkModel.expenseRequestDTOList[index].distance != '') {
        if (this.userInfo.isCategoryBasedEnabled) {
          this.getCatPolicy(this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId, this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate, index, '', 'mileage');
        } else {
          this.getPolicyApi(this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId, this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate, index, '', 'mileage');
        }
      }

    } else {
      this.expenseModel.expensedDate = moment(new Date(e.detail.value)).format(
        Constants.mobileDateFormat
      );
    }
  }
  getapprovedistance(index?) {
    this.MileagebulkItemsForms.value.forEach(m => {
      this.expenseModel.unformattedOriginalApproveAmount = m.approvedDistance * m.mileageRate;
      this.totalMileageBulkModel.expenseRequestDTOList[index].unformattedOriginalApproveAmount = m.approvedDistance * m.mileageRate;
      if (this.expenseModel.unformattedOriginalApproveAmount) {
        this.expenseModel.originalApproveAmount = this.expenseModel.unformattedOriginalApproveAmount;
        this.totalMileageBulkModel.expenseRequestDTOList[index].originalApproveAmount = this.totalMileageBulkModel?.expenseRequestDTOList[index]?.unformattedOriginalApproveAmount;
      }
    })
    if (this.MileagebulkItemsForms.value[0].approvedDistance > this.MileagebulkItemsForms.value[0].distance) {
      this.approveError = true;
    } else {
      this.approveError = false;
    }

  }
  getDistance(e, index?) {
    if (this.platformType == 1) {
      this.MileagebulkItemsForms.value[index].originalAmount = Math.round(e * this.totalMileageBulkModel.expenseRequestDTOList[index].mileageRate);
      this.totalMileageBulkModel.expenseRequestDTOList[index].originalAmount = Math.round(e * this.totalMileageBulkModel.expenseRequestDTOList[index].mileageRate);
      console.log(e * this.totalMileageBulkModel.expenseRequestDTOList[index].mileageRate, Math.round(e * this.totalMileageBulkModel.expenseRequestDTOList[index].mileageRate));
      console.log(this.MileagebulkItemsForms.value[index].originalAmount);
      console.log(this.totalMileageBulkModel.expenseRequestDTOList[index].originalAmount);
      
    } else {
      this.expenseModel.distance = e;
      if (this.milageConvRate !== undefined) {
        this.expenseModel.originalAmount = this.expenseModel.distance * this.milRate;
        this.milageTotalAmt = this.milageConvRate * this.expenseModel.originalAmount;
      }
      if (this.milRate !== undefined) {
        this.expenseModel.originalAmount =
          this.expenseModel.distance * this.milRate;
        this.milageTotalAmt =
          this.milageConvRate * this.expenseModel.originalAmount;
      }
    }


    // let elm = this.policyList?.policyMileageRuleList?.find(
    //   (elm) => elm.categoryId == this.expenseModel.categoryId
    // );
    // if (elm) {
    //   if (this.expenseModel.distance > elm.limitValue) {
    //     this.distanceViolationMiles = true;
    //   } else {
    //     this.distanceViolationMiles = false;
    //   }
    // }
  }

  updateAmt() {
    this.milageTotalAmt =
      this.milageConvRate * this.expenseModel.originalAmount;
  }

  selectCurrencyMileageOption(e) {
    if (this.platformType == 1) {
      this.mileageForm.controls.originalCurrencyCode.patchValue(e.currencyCode);
    }
    if (e != null) {
      this.expenseModel.originalCurrencyId = e.currencyId;
      this.milecurrencySymbol = e.currencyIcon;
      this.milageCurrencyCode = e.currencyCode;
      this.milageConvRate = e.conversionRate;
    }
    this.milageTotalAmt =
      this.milageConvRate * this.expenseModel.originalAmount;
  }

  selectreportMileageOption(e) {

    if (this.platformType == 1) {
      if (e != undefined) {
        this.mileageForm.controls.claimName.patchValue(e.claimName);
        this.mileageForm.controls.claimId.patchValue(e.claimId);
      }
    }
    if (e != null) {
      this.addReport = false;
      this.expenseModel.claimId = e.claimId;
    }
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e, expenseType, index?) {
    e.preventDefault();
    this.dragging = false;
    e.target.files = e.dataTransfer.files;
    this.handleInputChange(e, expenseType, index);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  onSlide(item) {
    this.bulkExActiveIndex = parseInt(item.current.replace("slideId_", ""), 10);
    this.imageIndex = parseInt(item.current.replace("slideId_", ""), 10);
    this.proofid = this.isAssociated
      ? this.associatedAttchements[
        parseInt(item.current.replace("slideId_", ""), 10)
      ].attachmentId
      : this.expenseModel.expenseAttachments[
        parseInt(item.current.replace("slideId_", ""), 10)
      ].attachmentId;
    this.imgNum = this.imageIndex + 1;
  }



  addBulkCSV(event) {
    this.totalBulkModel.isCSVFile = true;
    this.handleInputChange(event);
  }

  gets3UploadUrl(index, filename, file, expensesList?, tabIndex?, backButton?) {
    this.expenseTabIndex = tabIndex || 0;
    this.totalBulkForm?.controls?.claimName?.patchValue(this.navInfoData?.report?.claimName);
    this.totalBulkForm?.controls?.claimStartDate?.patchValue(this.convertDateToUtc(this.navInfoData?.report?.claimStartDate));
    this.totalBulkForm?.controls?.claimEndDate?.patchValue(this.convertDateToUtc(this.navInfoData?.report?.claimEndDate));
    if ((!expensesList || expensesList.length == 0) && !backButton) {
      this.active = 'bottom';
      this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = true;
      this.totalBulkModel.expenseRequestDTOList[index].description = 'Expense Created on ' + moment(new Date()).format("DD MMM YYYY");
      this.totalBulkModel.expenseRequestDTOList[index].date = this.convertDateToUtc(this.navInfoData?.report?.claimEndDate);
      this.totalBulkModel.expenseRequestDTOList[index].expensedDate = this.convertDateToUtc(this.navInfoData?.report?.claimEndDate);
      this.totalBulkForm.patchValue({
        bulkItems: [{ date: moment().format() }]
      })
    } else {
      if(tabIndex == 0) {
        this.active = 'bottom';
        this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = true;
      } else if(tabIndex == 1) {
        this.active = 'middle';
        this.totalMileageBulkModel.expenseRequestDTOList[index].loaderBoolean = true;
      } else if(tabIndex == 2) {
        this.active = 'end';
      }
      this.navChanged(this.expenseTabIndex);
    }
    // this.totalBulkModel.expenseRequestDTOList[0].date = this.convertDateToUtc(moment().format());
    const obj = {
      "legalEntityGUID": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "fileName": filename,
      "uploadFor": "EXPENSE-ATTACHMMENTS"
    }

    setTimeout(() => {
      document.getElementById('from-camera'+index)?.scrollIntoView();
    }, 500)

    if(!backButton) {
      this.expenseService.getUploadUrl(obj).subscribe((res: any) => {
        sessionStorage.setItem('s3uploadUrl', res.payload?.s3Url)
        this.expenseService.uploadFileInS3(file, res.payload?.preSignedS3URL).subscribe(response => {
          // this._handleReaderLoadedS3(filename, res.payload.s3Url, index);
          if(this.userInfo?.isOCREnable && this.active == "bottom") {
            this.extractImageOCR(filename, res.payload.s3Url, index);
          } else {
            // this._handleReaderLoadedS3(file[0].name, res.payload.s3Url, index);
            this._bulkProgressState = false;
            this.buttonDisabled = false;
            this.loginUserAttchementChecking = false;
            this.setLoaderToFalse(index);
            this.toastr.success("Attachments added successfully", "Success", index);

            let request = {
              fileName: filename,
              url: res.payload.s3Url,
              content: null,
              attachmentType: "1",
            };
            if (this.active == "end" && !this._perdiemEditForm) {
              this.sampleDt[index].expenseAttachments.push(request);
              this.sampleDt[index].showLoader = false;
            } else if (this.active == "end" && this._perdiemEditForm) {
              this.perdiemModel.expenseAttachments.push(request);
              this.sampleDt[index].expenseAttachments.push(request);
              this.sampleDt[index].showLoader = false;
            } else if (this.active == "bottom") {
              this.totalBulkModel.expenseRequestDTOList[index].expenseAttachments.push(request);
            } else if (this.active == "middle") {
              this.totalMileageBulkModel.expenseRequestDTOList[index].expenseAttachments.push(request);
            } else {
              if (this.platformType == 2) {
                if (this._expenseType == 'Perdiem') {
                  this.sampleDt[index].expenseAttachments.push(request);
                  this.sampleDt[index].showLoader = false;
                }
                this._dismiss();
              }
              if (this._expenseType == 'Perdiem') {
                this.sampleDt[index].expenseAttachments.push(request);
                this.sampleDt[index].showLoader = false;
              } else {
                this.expenseModel.expenseAttachments.push(request);
                this.associatedAttchements.push(request);
              }
            }
          }
          if (response['status'] == 200) {
            sessionStorage.removeItem('s3uploadUrl');
          } else {
            this.setLoaderToFalse(index);
            sessionStorage.removeItem('s3uploadUrl');
            this.toastr.error('Unable to submit file');
          }
        }, err => {
          this.setLoaderToFalse(index);
          sessionStorage.removeItem('s3uploadUrl');
          this.toastr.error('Unable to submit file');
        })
      }, err => {
        this.setLoaderToFalse(index);
        sessionStorage.removeItem('s3uploadUrl');
        this.toastr.error('Unable to submit file');
      })
    } else {
      this.setLoaderToFalse(index)
    }
  }

  handleCustomFileUpload(event, expIndex?, cfIndex?, item?) {
    if (!event.target) {
      return;
    }
    if (!event.target.files) {
      return;
    }

    this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].showLoader = true;

    var file = event.target.files;
    this.imagesCount = event.target.files.length;
    if (!this.totalBulkModel.isCSVFile) {
      var expensetion = file[0].name.split("?")[0].split("#")[0].split('.').pop();
      if (this.userInfo.attachmentFormat) {
        if (this.commaSeperatorArray.indexOf(expensetion.toLowerCase()) <= -1) {
          this.toastr.error('invalid extension. Extension Should be ' + this.commaSeperatorArray.join(', ') + '', 'Error');
          this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].showLoader = false;
          this._bulkProgressState = false;
          this.buttonDisabled = false;
          return;
        }
      }
    }

    if (this.totalBulkModel.isCSVFile) {
      let filesCount = event.target.files.length;
      for (let i = 0; i < filesCount; i++) {
        var reader = new FileReader();
        var imgfile = event.dataTransfer ? event.dataTransfer.files[i] : file[i];
        if (this.totalBulkModel.isCSVFile) {
          this.totalBulkModel.csvFileName = imgfile.name;
        }
        this.fileName = imgfile.name;
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(imgfile);
      }
    } else {
      const obj = {
        "legalEntityGUID": this.userInfo.legalEntityGuId,
        "legalEntityId": this.userInfo.legalEntityId,
        "fileName": file[0].name,
        "uploadFor": "EXPENSE-ATTACHMMENTS"
      }

      this.expenseService.getUploadUrl(obj).subscribe((res: any) => {
        sessionStorage.setItem('s3uploadUrl', res.payload?.s3Url)

        this.expenseService.uploadFileInS3(file[0], res.payload?.preSignedS3URL).subscribe(response => {
          this.toastr.success("Attachment added successfully", "Success", expIndex);
          this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].showLoader = false;
            let request = {
              fileName: file[0].name,
              url: res.payload.s3Url,
              content: null,
              attachmentType: "1",
            };
            if(!this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].customFieldValue) {
              this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].customFieldValue = [];
            }
            this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].customFieldValue = this.convertToObj(this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].customFieldValue)
            this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].customFieldValue.push(request);
          if (response['status'] == 200) {
            sessionStorage.removeItem('s3uploadUrl');
          } else {
            this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].showLoader = false;
            sessionStorage.removeItem('s3uploadUrl');
            this.toastr.error('Unable to submit file');
          }
        }, err => {
          this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].showLoader = false;
          sessionStorage.removeItem('s3uploadUrl');
          this.toastr.error('Unable to submit file');
        })
      }, err => {
        this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].showLoader = false;
        sessionStorage.removeItem('s3uploadUrl');
        this.toastr.error('Unable to submit file');
      })
    }
  }

  isReadOCR: any;
  handleInputChange(event, expenseType?, index?, readOCR?) {
    // console.log(event, expenseType, index, readOCR);
    this.open_files = false;
    
    this.isReadOCR = readOCR;
    if (expenseType == 'perdiem') {
      this._perdiemSelectedIndex = index;
    } else if (expenseType == 'bulk') {
      this.globalIndex = index;
    } else if (expenseType == 'mileage') {
      this.mileageIndex = index;
      this.active = 'middle';
    }
    if (!event.target) {
      return;
    }
    if (!event.target.files) {
      return;
    }
    if (this.active == 'top' && this.userInfo.maxAttachementCount < event.target.files.length) {
      this.toastr.error('Attchement count Should not greater than max count', 'Error', { timeOut: 3000 });
      return;
    }
    if (this.active == 'top' && this.userInfo.maxAttachementCount == this.expenseModel.expenseAttachments.length) {
      this.toastr.error('you have reached attchement limit', 'Error', { timeOut: 3000 });
      return;
    }
    if (this.active == 'bottom' && this.userInfo.maxAttachementCount == this.totalBulkModel.expenseRequestDTOList[index]?.expenseAttachments.length) {
      this.toastr.error('you have reached attchement limit', 'Error', { timeOut: 3000 });
      return;
    }
    if (this.active == 'middle' && this.userInfo.maxAttachementCount == this.totalMileageBulkModel.expenseRequestDTOList[index]?.expenseAttachments.length) {
      this.toastr.error('you have reached attchement limit', 'Error', { timeOut: 3000 });
      return;
    }


    var file = event.target.files;
    this.imagesCount = event.target.files.length;
    // console.log('upload image file size : ', file[0]);
    // if (8242880 < file[0].size) {
    //   this.toastr.error('Error', 'File size exceeds the maximum limit');
    //   if (expenseType == 'perdiem') {
    //     this.sampleDt[this._perdiemSelectedIndex].showLoader = false;
    //   } else if (expenseType == 'bulk') {
    //     this.totalBulkModel.expenseRequestDTOList[this.globalIndex].loaderBoolean = false;
    //   } else if (expenseType == 'mileage') {
    //     this.totalMileageBulkModel.expenseRequestDTOList[this.mileageIndex].loaderBoolean = false;
    //   }
    //   this._bulkProgressState = false;
    //   this.buttonDisabled = false;
    //   return;
    // }
    if (!this.totalBulkModel.isCSVFile) {
      var expensetion = file[0].name.split("?")[0].split("#")[0].split('.').pop();
      if (this.userInfo.attachmentFormat) {
        if (this.commaSeperatorArray.indexOf(expensetion.toLowerCase()) > -1) {
        } else {
          this.toastr.error('invalid extension. Extension Should be ' + this.commaSeperatorArray.join(', ') + '', 'Error');
          if (expenseType == 'perdiem') {
            this.sampleDt[index].showLoader = false;
          } else if (expenseType == 'bulk') {
            this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false;
          } else if (expenseType == 'mileage') {
            this.totalMileageBulkModel.expenseRequestDTOList[index].loaderBoolean = false;
          } 
          this._bulkProgressState = false;
          this.buttonDisabled = false;
          return;
        }
      } else {
        // if (expenseType == 'perdiem') {
        //   this.sampleDt[this._perdiemSelectedIndex].showLoader = false;
        // } else {
        //   this.totalBulkModel.expenseRequestDTOList[this.globalIndex].loaderBoolean = false;
        // }
      }
    }

    if (expenseType == 'perdiem') {
      this.sampleDt[index].showLoader = true;
    } else if (expenseType == 'bulk') {
      this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = true;
    } else if (expenseType == 'mileage') {
      this.totalMileageBulkModel.expenseRequestDTOList[index].loaderBoolean = true;
    } 

    if (this.totalBulkModel.isCSVFile) {
      let filesCount = event.target.files.length;
      for (let i = 0; i < filesCount; i++) {
        var reader = new FileReader();
        var imgfile = event.dataTransfer ? event.dataTransfer.files[i] : file[i];
        if (this.totalBulkModel.isCSVFile) {
          this.totalBulkModel.csvFileName = imgfile.name;
        }
        this.fileName = imgfile.name;
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(imgfile);
      }
    } else {
      const obj = {
        "legalEntityGUID": this.userInfo.legalEntityGuId,
        "legalEntityId": this.userInfo.legalEntityId,
        "fileName": file[0].name,
        "uploadFor": "EXPENSE-ATTACHMMENTS"
      }
      // console.log(file[0]);



      this.expenseService.getUploadUrl(obj).subscribe((res: any) => {
        // console.log(res);
        // console.log('s3Url : ', res.payload.s3Url);
        // console.log('preSignedS3URL : ', res.payload?.preSignedS3URL);
        sessionStorage.setItem('s3uploadUrl', res.payload?.s3Url)

        this.expenseService.uploadFileInS3(file[0], res.payload?.preSignedS3URL).subscribe(response => {
          // console.log('389 response : ', response);
          if(this.userInfo?.isOCREnable && this.active == "bottom") {
            this.extractImageOCR(file[0].name, res.payload.s3Url, index);
          } else {
            // this._handleReaderLoadedS3(file[0].name, res.payload.s3Url, index);
            this._bulkProgressState = false;
            this.buttonDisabled = false;
            this.loginUserAttchementChecking = false;
            this.setLoaderToFalse(index);
            this.toastr.success("Attachments added successfully", "Success", index);

            let request = {
              fileName: file[0].name,
              url: res.payload.s3Url,
              content: null,
              attachmentType: "1",
            };
            if (this.active == "end" && !this._perdiemEditForm) {
              this.sampleDt[index].expenseAttachments.push(request);
              this.sampleDt[index].showLoader = false;
            } else if (this.active == "end" && this._perdiemEditForm) {
              this.perdiemModel.expenseAttachments.push(request);
              this.sampleDt[index].expenseAttachments.push(request);
              this.sampleDt[index].showLoader = false;
            } else if (this.active == "bottom") {
              this.totalBulkModel.expenseRequestDTOList[index].expenseAttachments.push(request);
            } else if (this.active == "middle") {
              this.totalMileageBulkModel.expenseRequestDTOList[index].expenseAttachments.push(request);
            } else {
              if (this.platformType == 2) {
                if (this._expenseType == 'Perdiem') {
                  this.sampleDt[index].expenseAttachments.push(request);
                  this.sampleDt[index].showLoader = false;
                }
                this._dismiss();
              }
              if (this._expenseType == 'Perdiem') {
                this.sampleDt[index].expenseAttachments.push(request);
                this.sampleDt[index].showLoader = false;
              } else {
                this.expenseModel.expenseAttachments.push(request);
                this.associatedAttchements.push(request);
              }
            }
          }
          if (response['status'] == 200) {
            sessionStorage.removeItem('s3uploadUrl');

            // this._bulkProgressState = false;
            // this.buttonDisabled = false;
            // this.loginUserAttchementChecking = false;
            // this.setLoaderToFalse(index);
            // this.toastr.success("Attachments added successfully", "Success", index);

            // let request = {
            //   fileName: file[0].name,
            //   url: res.payload.s3Url,
            //   content: null,
            //   attachmentType: "1",
            // };
            // if (this.active == "end" && !this._perdiemEditForm) {
            //   this.sampleDt[index].expenseAttachments.push(request);
            //   this.sampleDt[index].showLoader = false;
            // } else if (this.active == "end" && this._perdiemEditForm) {
            //   this.perdiemModel.expenseAttachments.push(request);
            //   this.sampleDt[index].expenseAttachments.push(request);
            //   this.sampleDt[index].showLoader = false;
            // } else if (this.active == "bottom") {
            //   this.totalBulkModel.expenseRequestDTOList[index].expenseAttachments.push(request);
            // } else if (this.active == "middle") {
            //   this.totalMileageBulkModel.expenseRequestDTOList[index].expenseAttachments.push(request);
            // } else {
            //   if (this.platformType == 2) {
            //     if (this._expenseType == 'Perdiem') {
            //       this.sampleDt[index].expenseAttachments.push(request);
            //       this.sampleDt[index].showLoader = false;
            //     }
            //     this._dismiss();
            //   }
            //   if (this._expenseType == 'Perdiem') {
            //     this.sampleDt[index].expenseAttachments.push(request);
            //     this.sampleDt[index].showLoader = false;
            //   } else {
            //     this.expenseModel.expenseAttachments.push(request);
            //     this.associatedAttchements.push(request);
            //   }
            // }
          } else {
            this.setLoaderToFalse(index);
            sessionStorage.removeItem('s3uploadUrl');
            this.toastr.error('Unable to submit file');
          }
        }, err => {
          this.setLoaderToFalse(index);
          sessionStorage.removeItem('s3uploadUrl');
          this.toastr.error('Unable to submit file');
          // this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false;
        })
      }, err => {
        this.setLoaderToFalse(index);
        sessionStorage.removeItem('s3uploadUrl');
        this.toastr.error('Unable to submit file');
      })
    }

  }

  setLoaderToFalse(index) {
    this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false) : "";
    this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].bulkImgUpload = true) : "";
    this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].isAttachmentMandatory = false) : "";
    this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[index].loaderBoolean = false) : "";
    this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[index].bulkImgUpload = true) : "";
  }

  dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
  }

  isImageFile(dataURL: string): boolean {
    const regex = /^data:image\/(png|jpeg|jpg|gif|svg\+xml);base64,/i;
    return regex.test(dataURL);
  }

  _handleReaderLoaded(e) {
    var reader = e.target;
    this.imgContent = reader.result;
    let _request = {
      fileName: this.active == "bottom" ? this.fileName : (this.active == "top" ? this.fileName : (this.active == "middle" ? this.fileName : "image." + reader.result.substring(reader.result.indexOf("/") + 1, reader.result.indexOf(";base64")))),
      content: reader.result,
    };
    this.uploadedfiles.push(_request);
    if (this.imagesCount == this.uploadedfiles.length) {
      this.uploadImage(this.imgContent, this.fileName);
    }
  }

  _handleReaderLoadedS3(filename, s3AttachmentUrl, index) {
    // var reader = e.target;
    // this.imgContent = reader.result;
    let _request = {
      fileName: filename,
      s3AttachmentUrl: s3AttachmentUrl,
    };
    this.uploadedfiles.push(_request);
    console.log(this.imagesCount, this.uploadedfiles.length);

    if (this.imagesCount == this.uploadedfiles.length) {
      this.uploadImage(s3AttachmentUrl, filename, index);
    }
  }

  resetInputFile() {
    this.fileUploadInput.nativeElement.value = null; // Reset the input element
    this.fileUploadInput.nativeElement.click(); // Reset the input element
  }

  fileChangeEvent(event: any): void {
    if (!event.target) {
      return;
    }
    if (!event.target.files) {
      return;
    }
    if (event.target.files.length !== 1) {
      return;
    }
    const file = event.target.files[0];
    if (file.size > 1048576) {
      return;
    }
    let fileName = event.target.files[0].name;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      const imgBase64Path = reader.result;
      this.image = imgBase64Path;
      this.uploadImage(this.image, fileName);
    };
  }

  removeImage(deleteTemp?: TemplateRef<any>) {
    if (deleteTemp) {
      const config = {
        ignoreBackdropClick: false,
        class: "modal-md modal-dialog-centered",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(deleteTemp, config);
    }
  }

  deleteAttchement() {
    this.mileagePickPath = false;
    if (this.imageIndex == undefined) {
      this.imageIndex = 0;
    }
    if (this.isAssociated) {
      this.expenseModel.expenseAttachments.splice(
        this.expenseModel.expenseAttachments.length -
        this.associatedAttchements.length,
        1
      );
      this.associatedAttchements.splice(this.imageIndex, 1);
    } else {
      if (this.active == "end") {
        this.perdiemModel.expenseAttachments.splice(this.imageIndex, 1);
      } else {
        this.expenseModel.expenseAttachments.splice(this.imageIndex, 1);
      }
    }
    this.imgContent = "";
    this.fileName = "";
    if (this.imageIndex != 0) {
      this.imgNum = this.imgNum - 1;
      this.imageIndex = this.imageIndex - 1;
    } else {
      this.imgNum = 1;
    }
    this.modalRef.hide();
  }

  expenseOutsideClaimDates() {
    let claim_start = this.datechangeService.convertCalendarToAPI(this.totalBulkForm.controls.claimStartDate.value);
    let claim_end = this.datechangeService.convertCalendarToAPI(this.totalBulkForm.controls.claimEndDate.value);

    if(!claim_start || !claim_end) {
      return;
    }

    let isExpenseOutside = false;
    this.totalBulkModel?.expenseRequestDTOList?.forEach(e => {
      if (e.categoryId && e.categoryId != null && e.categoryId != "") {
        if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate) || this.checkDateFormat(e.expensedDate)) {
          e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
        }
        if (moment(e.expensedDate, 'DD-MM-YYYY').isSameOrAfter(moment(claim_start, 'DD-MM-YYYY')) && moment(e.expensedDate, 'DD-MM-YYYY').isSameOrBefore(moment(claim_end, 'DD-MM-YYYY')) && !isExpenseOutside) {
          isExpenseOutside = false;
        } else {
          isExpenseOutside = true;
        }
      }
    });
    return isExpenseOutside;
  }

  importantFields: any = {
    expense: {
      parentCategoryId: 'Category',
      categoryName: 'Sub Category',
      description: 'Description/Reason',
      date: 'Expense Date',
      amount: 'Amount',
      expenseEndDate: 'Expense End Date',
      countryName: 'Country',
      cityName: 'City',
      bandId: 'Band',
      departmentId: 'Department',
      projectCodeId: 'Project Code',
      costCenterId: 'Cost Center',
      paidBy: 'Paid By',
      paidByName: 'Paid By Name',
      invoiceNumber: 'Invoice Number',
      taxNumber: 'Tax Number',
      taxPercentage: 'Tax %',
      taxAmount: 'Tax Amount',
      merchantCode: 'Merchant Code',
      merchantName: 'Merchant',
      location: 'Spent Location'
    },
    mileage: {
      location: 'Location',
      categoryName: 'Vehicle',
      distance: 'Distance',
      expensedDate: 'Expense Date',
      originalAmount: 'Amount',
      description: 'Description/Reason',
      fromLocationName: 'From',
      toLocationName: 'To',
      invoiceNumber: 'Invoice Number'
    },
    perdiem: {
      PDlocation: 'Per Diem Country',
      PDrate: 'Per Diem Rate',
      PDstartDate: 'Start Date',
      PDendDate: 'End Date'
    }
  };

  missingFieldsList: any;
  updateNextExpense;
  updatePreviousExpense;
  async createExpenseAndMileage(isUpdateNext?, isUpdatePrevious?) {
    if (!this.canEditClaim && (isUpdateNext || isUpdatePrevious)) {
      isUpdateNext ? this.editNextExpense() : this.editPrevExpense();
      return;
    }

    let expense = this.totalBulkModel?.expenseRequestDTOList[0] || {};
    if(expense.restrictClaimOnStartEndDates && expense.isStartEndDateEnabled && expense.expensedDate && expense.expenseEndDate) {
      let sDate = expense?.expensedDate;
      let eDate = expense?.expenseEndDate;
      if (!moment.utc(sDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(sDate) || this.checkDateFormat(sDate)) {
        sDate = moment(sDate);
      }
      if (!moment.utc(eDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(eDate) || this.checkDateFormat(eDate)) {
        eDate = moment(eDate);
      }
      if(moment(sDate, 'DD-MM-YYYY').isSame(moment(eDate, 'DD-MM-YYYY'))){
        this.dTempHeader = 'Restriction';
        this.dTempBody = 'This expense is ineligible for claiming if the start and end dates are same.';
        const config = {
            ignoreBackdropClick: false,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRefDynamic = this.modalService.show(this.dynamicTemp, config);
        return;
      }
    }
    
    if(expense?.showProofofpayment && expense?.isProofOfPaymentAttachmentRequired && !expense?.proofOfPaymentFileUrl){
      this.toastr.error("Please Attach proof of payment");
      return;
    }
    this.updateNextExpense = isUpdateNext || false;
    this.updatePreviousExpense = isUpdatePrevious || false;
    let end = this.datechangeService.convertCalendarToAPI(this.totalBulkForm.controls.claimEndDate.value);
    if(moment(end, 'DD-MM-YYYY').isAfter(moment())) {
      this.openFutureDatePopup(this.futureDateTemp);
      return;
    }

    if(this.expenseOutsideClaimDates()) {
      this.openFutureDatePopup(this.expenseOutsideClaimDatesError);
      return;
    }

    console.log(this.expenseTabIndex, this.active);
    console.log('totalBulkForm __ ', this.totalBulkForm);
    console.log('mileageForm __ ', this.mileageForm);
    console.log('perdiemForm __ ', this.perdiemForm);

    this.missingFieldsList = [];
    if(this.expenseTabIndex == 0) {
      this.missingFieldsList = this.getMissingRequiredFields(this.totalBulkForm);
      console.log('Expense Form ___ Missing Required Fields:', this.missingFieldsList);
    } else if(this.expenseTabIndex == 1) {
      this.missingFieldsList = this.getMissingRequiredFields(this.mileageForm);
      console.log('MileageForm Form ___ Missing Required Fields:', this.missingFieldsList);
    } else if(this.expenseTabIndex == 2) {
      this.missingFieldsList = this.getMissingRequiredFields(this.perdiemForm);
      console.log('Perdiem Form ___ Missing Required Fields:', this.missingFieldsList);
    }

    this.expenseModel.allowItemizedExpenses = this.userInfo.allowItemizedExpenses;
    this.isCloseModal = true;

    if(sessionStorage.getItem("toPathInfo")) {
      this.navInfoData = JSON.parse(sessionStorage.getItem("toPathInfo"));
    }


    if(this.userInfo?.restrictMobileBillMissMatch) {
      const restrictMobileBill = this.totalBulkModel?.expenseRequestDTOList?.filter(e => e.mobileMissMatch == true);
      if(restrictMobileBill.length > 0) {
        const config = {
          ignoreBackdropClick: false,
          class: "modal-lg modal-dialog-centered",
          keyboard: false,
        };
        this.modalRef = this.modalService.show(this.mobileNumberMismatch, config);
        return;
      }
    }


    if (this.currentIndex == this.expenseArray?.length - 1) {
      this.isCloseModal = true;
    }
    if (this.active == 'bottom') {
      this.createBulkApi();
      return;
    }
    if (this.active == 'top') {
      this.submitExpense = true;

      this.expenseModel.legalEntityId = this.userInfo.legalEntityId;
      this.expenseModel.legalEntityGUID = this.userInfo.legalEntityGuId;
      this.expenseModel.userId = this.editlist ? this.editlist.userId : this.userInfo.userId;
      this.expenseModel.userGUID = this.editlist ? this.editlist.userGUID : this.userInfo.userGuId;
      this.expenseModel.categoryType = this.type;
      this.expenseModel.recordType = this.fromPettyCash ? 4 : 1;
      this.updatetitle ? (this.expenseModel.expenseId = this.expenseId) : "";
      let temparoryList = [];
      if (this.multiTravelTrip) {
        temparoryList.push(this.expenseTaggedTripList[this.currentIndex]);
      }
      this.isAssociated ? (this.expenseModel.expenseTaggedTripList = this.expenseTaggedTripList) :
        (this.singleTravelTrip ? (this.expenseModel.expenseTaggedTripList = this.expenseTaggedTripList) : (this.multiTravelTrip ? (this.expenseModel.expenseTaggedTripList = temparoryList) : ''));
      if (this.recordType) {
        this.expenseModel.recordType = this.recordType;
      }
      this.expenseModel.tripId = this.travelTripdata.tripId;
      this.expenseModel.tripName = this.travelTripdata.tripName;
      this.expenseModel.tripFromDate = this.travelTripdata.tripStartDate;
      this.expenseModel.tripEndDate = this.travelTripdata.tripEndDate;
      if (!this.expenseForm.valid && this.expenseTabLabel != 'Perdiem') {
        return;
      }
      if (sessionStorage.getItem("isAttachmentMandatory") == 'true' && this.expenseModel.expenseAttachments.length == 0 && !this.showHsnBtns) {
        this.loginUserAttchementChecking = true;
        return;
      } else {
        this.loginUserAttchementChecking = false;
      }

      if (this.showText) {
        if (this.itemwise_taxAmountValidation || this.itemwise_taxPercentageValidation) { return; }
      } else {
        if (this.taxPercentageValidation || this.taxAmountValidation || this.approveAmountValidation) { return; }
      }

      if ((this.expenseModel.splitType === 2 && this.amtError) || (this.expenseModel.splitType === 3 && this.percError)) {
        this.toastr.error("please check split values");
        return;
      }
      if (this.expenseModel.expenseType == 2) {
        var arr = [];
        var taxValue = 0;
        for (let i = 0; i < this.expenseItemsArray.controls.length; i++) {
          arr.push({
            name: this.expenseItemsArray.controls[i].value.name,
            amount: this.expenseItemsArray.controls[i].value.amount,
            taxAmount: this.expenseItemsArray.controls[i].value.taxAmount,
            taxPercentage: this.expenseItemsArray.controls[i].value.taxPercentage,
            itemId: this.expenseItemsArray.controls[i].value.itemId,
          });
          this.expenseModel.expenseItems = arr.map(
            (items) => new ExpenseItemsModel(items)
          );
          taxValue = taxValue + this.expenseItemsArray.controls[i].value.taxAmount;
        }
        this.expenseModel.originalAmount = null;
        this.expenseModel.taxAmount = taxValue;
      } else {
        this.expenseModel.expenseItems = [];
      }
      if (this.isCloseModal) {
        this.buttoncloseDisabled = true;
      } else {
      }

      if (this.expenseModel.unformattedOriginalApproveAmount) {
        this.expenseModel.originalApproveAmount = this.expenseModel.unformattedOriginalApproveAmount;
      }
      if (
        (this.receiptWarning || this.receiptCatWarning) &&
        this.expenseModel.expenseAttachments.length == 0
      ) {
        if (this.platformType == 1) {
          const config = {
            ignoreBackdropClick: false,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
          };
          this.modalRef = this.modalService.show(this.incompleteTemp, config);
        } else {
          const alert = await this.alertController.create({
            header: 'Alert',
            subHeader: 'Incomplete Data',
            message: 'Attachment is Mandatory As per the policy.',
            buttons: ['OK'],
          });
          await alert.present();
        }
        return false;
      } else {
        this.buttonDisabled = true;
        this.expenseModel.claimName = this.expenseForm.controls.claimName.value;
        this.expenseModel.claimStartDate = this.navInfoData['report']?.claimStartDate;
        this.expenseModel.claimEndDate = this.navInfoData['report']?.claimEndDate;
        console.log(this.expenseModel);
        this.expenseService
          .createExpenseandMileage(this.expenseModel)
          .subscribe((res) => {
            if (!res.isError && res.errorCode == 0) {
              this.buttonDisabled = false;
              this.submitExpense = false;
              this.buttoncloseDisabled = false;
              this.backClick = true;
              this.expenseForm.reset();
              this.expenseModel.expenseAttachments = [];
              if (!this.editlist) {
                this.sharedService.setExpenseData(res.payload);
              }
              this.selectedTrips = [];
              if (this.expenseArray?.length && !this.isCloseModal && !this.isAssociated) {
                // sessionStorage.setItem('approvedExpenseList', JSON.stringify(this.approvedExpenseList));
                this.slideExpense(true);
              } else {
                this.onSaveClick(res.payload);
              }

              this.removeImage();
              this.caputredImageModal.capturedImages = [];
              this.expenseForm.controls.expenseType.patchValue(1);
              this.expenseModel.expenseType = 1;
              if (this.platformType == 2) {
                this.expenseForm.controls.originalCurrencyCode.patchValue({
                  originalCurrencyCode: this.userInfo.baseCurrency.currencyCode,
                  currencyCode: this.userInfo.baseCurrency.currencyCode,
                  originalCurrencyId: this.userInfo.baseCurrency.currencyId,
                });
              } else {
                this.expenseForm.controls.originalCurrencyCode.patchValue(
                  this.userInfo.baseCurrency.currencyCode
                );
                this.mileageForm.controls.originalCurrencyCode.patchValue(
                  this.userInfo.baseCurrency.currencyCode
                );
                this.expenseModel.originalCurrencyId =
                  this.userInfo.baseCurrency.currencyId;
              }

              this.displayApiMsgs(1);
              sessionStorage.setItem("MapRefIDs", null);
            } else if (res.isError && res.errorCode == 2) {
              this.buttonDisabled = false;
              this.submitExpense = false;
              this.buttoncloseDisabled = false;
              this.toastr.error(
                res.errorMessage,
                this.translate.instant("alerts.error", { timeOut: 3000 })
              );
            }
          });
      }
    } else if (this.active == 'middle') {
      this.submitMileage = true;
      // console.log('mileageForm __ ', this.mileageForm);

      // // if(this.totalBulkForm.touched && !this.mileageForm.touched) {
      //   for (let i = this.MileagebulkItemsForms.controls.length - 1; i >= 1; i--) {
      //     const object = this.MileagebulkItemsForms.controls[i] as FormGroup;
      //     console.log(object);

      //     if (!object.touched) {
      //       this.MileagebulkItemsForms.removeAt(i);
      //       // this.totalBulkModel.expenseRequestDTOList.splice(i, 1);
      //     }
      //   }
      // // }
      // console.log(this.totalBulkModel.expenseRequestDTOList);


      // if (!this.mileageForm.valid && !this.totalBulkForm.touched && this.active == 'middle') {
      if (!this.mileageForm.valid && this.expenseTabLabel == 'Mileage') {
        this.mileageFieldsMandatory = true;
        this.mileageFieldsMandatoryText = 'Below mandatory fields are missing. Please check.';
      } else {
        this.mileageFieldsMandatory = false;
      }

      if (this.totalBulkModel.expenseRequestDTOList.length == 1) {
        if (this.totalBulkModel.expenseRequestDTOList[0].categoryName != undefined && this.totalBulkModel.expenseRequestDTOList[0].categoryName != null) {
          if (!this.totalBulkForm.valid && this.expenseTabLabel == 'Expenses') {
            this.bulkFieldsMandatory = true;
            this.bulkFieldsMandatoryText = 'Below mandatory fields are missing. Please check.'
          } else {
            this.bulkFieldsMandatory = false;
          }
        }

      } else if (this.totalBulkModel.expenseRequestDTOList.length > 1) {
        if (!this.totalBulkForm.valid && this.expenseTabLabel == 'Expenses') {
          this.bulkFieldsMandatory = true;
          this.bulkFieldsMandatoryText = 'Below mandatory fields are missing. Please check.'
        } else {
          this.bulkFieldsMandatory = false;
        }
      }

      if (this.sampleDt.length != 0) {
        console.log(this.perdiemForm);

        if (!this.perdiemForm.valid && this.expenseTabLabel == 'Perdiem') {
          this.perdiemFieldsMandatory = true;
          this.perdiemFieldsMandatoryText = 'Below mandatory fields are missing. Please check.'
        } else {
          this.perdiemFieldsMandatory = false;
        }
      }

      if (this.bulkFieldsMandatory || this.mileageFieldsMandatory || this.perdiemFieldsMandatory) {
        this.openFieldsMissingPopup();
        return;
      }

      if (this.MileagebulkItemsForms.value[0].approvedDistance > this.MileagebulkItemsForms.value[0].distance) {
        this.approveError = true;
        return
      }

      if (this.checkingAttMandatoryInForms('middle') || this.mileageDistance() || this.bulkFormValidation()) {
        return;
      }

      if (this.sampleDt.length != 0 && this.checkingAttMandatoryInForms('end')) {
        return;
      }
      console.log('update title : u789');
      if (this.mileagePickPath) {
        this.expenseModel.expenseAttachments.forEach((element) => {
          if (!element.attachmentType) {
            let _request = {
              fileName: element.fileName,
              base64Image: element.url,
            };
            this.uploadedfiles.push(_request);
          }
        });
        this.buttonDisabled = true;
        this.uploadMilegefile();
      } else {
        this.buttonDisabled = true;
        if (this.GPSData) {
          this.expenseModel.action = 2;
          // let dateTime = new Date().toLocaleString();
          // this.expenseModel.claimName = 'Mileage Created On' + dateTime;
          this.expenseModel.claimName
          this.expenseModel.mileageSessionId = localStorage.getItem("sessionId");
        }
        this.expenseModel.claimName = this.mileageForm.controls.claimName.value;

        let bulkApiModel = new BulkModel();
        let requstExpense = new ExpenseModel();
        bulkApiModel.legalEntityId = this.userInfo.legalEntityId;
        bulkApiModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        bulkApiModel.userId = this.userInfo.userId;
        bulkApiModel.userGUID = this.userInfo.userGuId;
        bulkApiModel.action = 1;
        bulkApiModel.expenseRequestDTOList = [];
        bulkApiModel.claimTypeId = this.totalBulkForm.controls.claimTypeId.value;
        bulkApiModel.claimTypeName = this.totalBulkForm.controls.claimTypeName.value;
        bulkApiModel.claimCategoryId = this.totalBulkForm?.controls?.claimCategoryId?.value;
        bulkApiModel.claimCategoryName = this.totalBulkForm?.controls?.claimCategory?.value;
        bulkApiModel.claimEndTime = this.totalBulkForm?.controls?.claimEndTime?.value;
        bulkApiModel.claimStartTime = this.totalBulkForm?.controls?.claimStartTime?.value;
        if (!this.userInfo.allowItemizedExpense) {
          bulkApiModel.claimId = this.navInfoData['report']?.claimId;
          bulkApiModel.claimName = this.navInfoData['report']?.claimName;
        } else {
          bulkApiModel.claimId = this.totalBulkModel?.claimId;
          bulkApiModel.claimName = this.totalBulkModel?.claimName;
        }

        // GS
        console.log('before moving mileage :', this.MileagebulkItemsForms);
        this.MileagebulkItemsForms.value.forEach((element, index) => {
          element.expenseType = 1;
          element.categoryType = 2;
          element.recordType = 1;
          element.categoryId = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId;
          element.categoryCode = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryCode;
          // element.expensedDate = this.datechangeService.convertCalendarToAPI(element.expensedDate);
          if (!moment.utc(element.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(element.expensedDate) || this.checkDateFormat(element.expensedDate)) {
            element.expensedDate = moment(element.expensedDate).format('DD-MM-YYYY');
          }
          element.expenseId = this.totalMileageBulkModel.expenseRequestDTOList[index].expenseId;
          element.expenseGUID = this.totalMileageBulkModel.expenseRequestDTOList[index].expenseGUID;
          element.checkPointsList = this.totalMileageBulkModel.expenseRequestDTOList[index].checkPointsList;
          element.customFieldList = this.totalMileageBulkModel.expenseRequestDTOList[index].customFieldList || [];

          if (typeof element.fromLocationName === 'object' && element.fromLocationName !== null) {
            element.fromLocationName = element.fromLocationName.title || '';
          }
          if (typeof element.toLocationName === 'object' && element.toLocationName !== null) {
            element.toLocationName = element.toLocationName.title || '';
          }

          bulkApiModel.expenseRequestDTOList.push(element);
        });

        // BULK FORM EXPENSES
        if (this.totalBulkModel.expenseRequestDTOList.length == 1) {
          if (this.totalBulkModel.expenseRequestDTOList[0].categoryName != undefined && this.totalBulkModel.expenseRequestDTOList[0].categoryName != null) {
            this.totalBulkModel.expenseRequestDTOList.forEach(element => {
              element.expenseType = 1;
              element.categoryType = 1;
              element.recordType = 1;
              bulkApiModel.expenseRequestDTOList.push(element);
            });
          }

        } else if (this.totalBulkModel.expenseRequestDTOList.length > 1) {
          this.totalBulkModel.expenseRequestDTOList.forEach(element => {
            element.expenseType = 1;
            element.categoryType = 1;
            element.recordType = 1;
            bulkApiModel.expenseRequestDTOList.push(element);
          });
        }
        if (this.editlist && (this.navInfoData.url == 'newreport' ? this.navInfoData?.expenseList : this.navInfoData?.expenseArray)) {
          let filterdata = [];
          if (this.navInfoData.url == 'newreport') {
            filterdata = this.navInfoData?.expenseList.filter((obj) => { return obj.isSelected && obj.expenseId != this.editlist.expenseId; });
          } else {
            filterdata = this.navInfoData?.expenseArray.filter((obj) => { return obj.expenseId != this.editlist.expenseId; });
          }
          filterdata.forEach(element => {
            element.expenseAttachments = [];
          });
          bulkApiModel.expenseRequestDTOList.push(...filterdata);
        }

        if(this.strToNum(this.perdiemModel?.originalApproveAmount) >= 0 && this.sampleDt?.length > 0) {
          this.sampleDt[0].originalApproveAmount = this.strToNum(this.perdiemModel?.originalApproveAmount) || null;
        }

        // PERDIEM EXPENSES
        if (this.sampleDt.length != 0) {
          let perdiemRequestDTO = {
            expenseId: null,
            legalEntityId: this.userInfo.legalEntityId,
            legalEntityGUID: this.userInfo.legalEntityGuId,
            locationId: this.perdiemModel.PDlocationId,
            locationName: this.perdiemModel.PDlocation,
            cityId: this.perdiemModel.PDCityId,
            cityName: this.perdiemModel.PDCity,
            perDiemRate: this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
            perDiemType: this.perdiemModel.PDradioSelection,
            startDate: this.perdiemModel.PDstartDate,
            endDate: this.perdiemModel.PDendDate,
            discription: this.perdiemForm.controls["PDdescription"].value,
            description: this.perdiemForm.controls["PDdescription"].value,
            categoryId: 1,
            expenseType: 5,
            recordType: 1,
            exchangeRate: 1,
            originalCurrencyId: 64,
            allowItemizedExpenses: this.userInfo.allowItemizedExpenses,
            perDiemExpenseList: this.sampleDt,
          };

          if (!this.userInfo.allowItemizedExpense) {
            perdiemRequestDTO['claimId'] = this.navInfoData['report']?.claimId;
            perdiemRequestDTO['claimName'] = this.navInfoData['report']?.claimName;
          } else {
            perdiemRequestDTO['claimId'] = '';
            perdiemRequestDTO['claimName'] = this.perdiemModel.PDclaimName;
          }

          bulkApiModel.perdiemRequestDTO = perdiemRequestDTO;

        }

        // bulkApiModel.claimName = this.mileageForm.controls.claimName.value;
        bulkApiModel.allowItemizedExpenses = this.userInfo.allowItemizedExpenses;
        bulkApiModel.claimStartDate = this.datechangeService.convertCalendarToAPI(this.mileageForm?.controls?.claimStartDate?.value);
        bulkApiModel.claimEndDate = this.datechangeService.convertCalendarToAPI(this.mileageForm?.controls?.claimEndDate?.value);
        if(!bulkApiModel.claimStartDate || bulkApiModel.claimStartDate == '' || !bulkApiModel.claimEndDate || bulkApiModel.claimEndDate == '') {
          bulkApiModel.claimStartDate = this.datechangeService.convertCalendarToAPI(this.totalBulkForm?.controls?.claimStartDate?.value);
          bulkApiModel.claimEndDate = this.datechangeService.convertCalendarToAPI(this.totalBulkForm?.controls?.claimEndDate?.value);
        }

        // this.fromPathInfo.report.claimStartDate = this.datechangeService.convertCalendarToAPI(this.mileageForm.controls.claimStartDate.value);
        // this.fromPathInfo.report.claimEndDate = this.datechangeService.convertCalendarToAPI(this.mileageForm.controls.claimEndDate.value);

        // COMING FROM REPORT SUMMARY PAGE (DRAFT)
        if (this.navInfoData.url == 'reports' && this.navInfoData.expenseArray) {
          bulkApiModel.expenseRequestDTOList.forEach(element => {
            element.claimCode = this.editlist?.claimCode,
              element.claimId = this.editlist?.claimId,
              element.claimName = this.editlist?.claimName
          });
          bulkApiModel.claimId = this.editlist?.claimId;
          bulkApiModel.claimName = this.editlist?.claimName;
        }
        // if (this.userInfo.roleId == 3) {
        //   const attchament = bulkApiModel.expenseRequestDTOList.filter((obj) => { return this.navInfoData.expenseId == obj.expenseId });

        //   requstExpense.originalApproveAmount = this.MileagebulkItemsForms.value[0].originalApproveAmount;
        //   requstExpense.categoryId = this.MileagebulkItemsForms.value[0].categoryId;
        //   requstExpense.categoryName = this.MileagebulkItemsForms.value[0].categoryName;
        //   requstExpense.description = this.MileagebulkItemsForms.value[0].description;
        //   requstExpense.distance = this.MileagebulkItemsForms.value[0].distance;
        //   requstExpense.expensedDate = this.MileagebulkItemsForms.value[0].expensedDate;
        //   requstExpense.financeIntegrationCode = this.MileagebulkItemsForms.value[0].financeIntegrationCode;
        //   requstExpense.financeIntegrationId = this.MileagebulkItemsForms.value[0].financeIntegrationId;
        //   requstExpense.fromLocationName = this.MileagebulkItemsForms.value[0].fromLocationName;
        //   requstExpense.mileageRate = this.MileagebulkItemsForms.value[0].mileageRate;
        //   requstExpense.mileageUnit = this.MileagebulkItemsForms.value[0].mileageUnit;
        //   requstExpense.originalAmount = this.MileagebulkItemsForms.value[0].originalAmount;
        //   requstExpense.originalCurrencyCode = this.MileagebulkItemsForms.value[0].originalCurrencyCode;
        //   requstExpense.originalCurrencyId = this.MileagebulkItemsForms.value[0].originalCurrencyId;
        //   requstExpense.taxCode = this.MileagebulkItemsForms.value[0].taxCode;
        //   requstExpense.taxCodeId = this.MileagebulkItemsForms.value[0].taxCodeId;
        //   requstExpense.hsnCode = this.MileagebulkItemsForms.value[0].hsnCode;
        //   requstExpense.hsnId = this.MileagebulkItemsForms.value[0].hsnId;
        //   requstExpense.toLocationName = this.MileagebulkItemsForms.value[0].toLocationName;
        //   requstExpense.expenseType = 1;
        //   requstExpense.categoryType = 2;
        //   requstExpense.claimId = this.getExpenseDetails.claimId;
        //   requstExpense.claimName = this.mileageForm.controls.claimName.value;
        //   requstExpense.expenseAttachments = this.MileagebulkItemsForms.value[0].expenseAttachments;
        //   requstExpense.userId = this.userInfo.userId;
        //   requstExpense.userGUID = this.userInfo.userGuId;
        //   requstExpense.legalEntityId = this.userInfo.legalEntityId;
        //   requstExpense.legalEntityGUID = this.userInfo.legalEntityGuId;
        //   requstExpense.expenseId = this.getExpenseDetails.expenseId;
        //   requstExpense.expenseGUID = this.getExpenseDetails.expenseGUID;
        //   requstExpense.allowItemizedExpenses = this.userInfo.allowItemizedExpenses;
        //   requstExpense.isAssociated = this.getExpenseDetails.isAssociated;
        //   requstExpense.isSelected = false;
        //   requstExpense.location = this.getExpenseDetails.location;
        //   requstExpense.checkPointsList = [];
        //   requstExpense.recordType = 1;
        //   requstExpense.status = this.userInfo.roleId;
        //   // requstExpense.approvedDistance = this.MileagebulkItemsForms.value[0].approvedDistance;
        //   console.log("3163", requstExpense);
        //   this.expenseService.createExpenseandMileage(requstExpense).subscribe((res) => {
        //     if (!res.isError && res.errorCode == 0) {
        //       this.backClick = true;
        //       this.buttonDisabled = false;
        //       this.submitMileage = false;
        //       this.mileageForm.reset();
        //       this.expenseModel.expenseAttachments = [];
        //       this.sharedService.setMapData(null);
        //       this.removeImage();

        //       if (this.expenseArray?.length && !this.isCloseModal && !this.isAssociated) {
        //         this.slideExpense(true);
        //       } else {
        //         this.onSaveClick(res.payload);
        //       }

        //       if (this.platformType == 2) {
        //         this.mileageForm.controls.originalCurrencyCode.patchValue({
        //           originalCurrencyCode: this.userInfo.baseCurrency.currencyCode,
        //           currencyCode: this.userInfo.baseCurrency.currencyCode,
        //           originalCurrencyId: this.userInfo.baseCurrency.currencyId,
        //         });
        //         this.expenseModel.originalCurrencyId =
        //           this.userInfo.baseCurrency.currencyId;

        //       } else {
        //       }
        //       this.sharedService.setExpenseData(res.payload);
        //       this.displayApiMsgs(2);
        //     } else if (res.isError && res.errorCode == 2) {
        //       this.buttonDisabled = false;
        //       this.submitMileage = false;
        //       this.toastr.error(
        //         res.errorMessage,
        //         this.translate.instant("alerts.error", { timeOut: 3000 })
        //       );
        //       this.sharedService.setMapData(null);
        //     }
        //   });
        // } else {
          bulkApiModel.expenseRequestDTOList?.forEach(e => {
            e.baseAmount = parseInt((e.baseAmount || 0).toString().replace(/,/g, ''), 10);
            // e.originalAmount = parseInt((e.originalAmount || 0).toString().replace(/,/g, ''), 10);
            e.originalAmount = parseFloat((e.originalAmount || 0).toString().replace(/,/g, ''));
            console.log("mielageoriginalAmount",e.originalAmount)
            // console.log(e.expensedDate);
            // e.expensedDate = moment(e.expensedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
            // if(moment(e.expensedDate).isUTC() || moment.isMoment(e.expensedDate)) {
            // const cleanedDate = e.expensedDate?.toString().replace(/\(.*\)/, '').trim();
            if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate) || this.checkDateFormat(e.expensedDate)) {
              e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
            }
            e.expenseAttachments?.forEach(a => {
              if (a.content == null || a.content == "") {
                delete a.content;
              }
            });
            if (this.enableUpdate && (!e.claimId || !e.claimName)) {
              const list = this.toPathInfoData?.expenseList[0] || this.toPathInfoData?.expenseArray[0];
              e.claimId = list?.claimId;
              e.claimName = this.totalBulkForm?.controls?.claimName?.value;
            }
            e.baseApproveAmount = e.baseApproveAmount?.toString().replace(/,/g, '');
            e.hsnId = e.hsnCodeId || null;
          })
          if (this.enableUpdate && !bulkApiModel.claimId && (this.navInfoData?.expenseList || this.navInfoData.expenseArray)) {
            const list = this.navInfoData.expenseList[0] || this.navInfoData.expenseArray[0];
            bulkApiModel.claimId = list?.claimId ? list.claimId : this.toPathInfoData?.detailReport?.claimId;
            bulkApiModel.claimName = list?.claimName;
          }

          bulkApiModel.perdiemRequestDTO?.perDiemExpenseList?.forEach(e => {
            e.baseAmount = parseInt((e.baseAmount || 0).toString().replace(/,/g, ''), 10);
            // e.originalAmount = parseInt((e.originalAmount || 0).toString().replace(/,/g, ''), 10);
            e.originalAmount = parseFloat((e.originalAmount || 0).toString().replace(/,/g, ''));
            // console.log(e.expensedDate);
            // e.expensedDate = moment(e.expensedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
            // e.expenseDate = moment(e.expenseDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
            // if(moment(e.expensedDate).isUTC() || moment.isMoment(e.expensedDate)) {
            // let cleanedDate = e.expensedDate?.toString().replace(/\(.*\)/, '').trim();
            if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate) || this.checkDateFormat(e.expensedDate)) {
              e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
            }
            // cleanedDate = e.expenseDate?.toString().replace(/\(.*\)/, '').trim();
            // if(moment(e.expenseDate).isUTC() || moment.isMoment(e.expenseDate)) {
            if (!moment.utc(e.expenseDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expenseDate) || this.checkDateFormat(e.expenseDate)) {
              e.expenseDate = moment(e.expenseDate).format('DD-MM-YYYY');
            }
          })

          if (this.enableUpdate && !bulkApiModel.claimId && (this.navInfoData?.expenseList || this.navInfoData.expenseArray)) {
            const list = this.navInfoData.expenseList[0] || this.navInfoData.expenseArray[0];
            bulkApiModel.claimId = list?.claimId;
            bulkApiModel.claimName = list?.claimName;
          }
          bulkApiModel.isLinkedToAdvancePayment = this.navInfoData?.isLinkedToAdvancePayment;
          bulkApiModel.isLinkedToPettyCashPayment = this.navInfoData?.isLinkedToPettyCashPayment;
          if (bulkApiModel.isLinkedToAdvancePayment == undefined) {
            bulkApiModel.isLinkedToAdvancePayment = false;
          }
          if (bulkApiModel.isLinkedToPettyCashPayment == undefined) {
            bulkApiModel.isLinkedToPettyCashPayment = false;
          }
          if (this.navInfoData?.isLinkedToAdvancePayment) {
            if (this.totalClaimedAmount <= this.walletAdvance) {
              bulkApiModel.advanceAmount = this.totalClaimedAmount;
            } else {
              bulkApiModel.advanceAmount = this.walletAdvance;
            }
          } else {
            bulkApiModel.advanceAmount = 0;
          }
          if (!bulkApiModel.claimTaggedTripList && this.expenseTaggedTripList) {
            bulkApiModel.claimTaggedTripList = this.expenseTaggedTripList;
            bulkApiModel.tripTravellers=this.tripTravellers
          }
          if (this.enableUpdate && (!bulkApiModel?.claimId || !bulkApiModel?.claimName)) {
            const list = this.toPathInfoData?.expenseList[0] || this.toPathInfoData?.expenseArray[0];
            bulkApiModel.claimId = list?.claimId ? list.claimId : this.toPathInfoData?.detailReport?.claimId;
            bulkApiModel.claimName = list?.claimName;
          }
          bulkApiModel.claimName = this.totalBulkForm?.controls?.claimName?.value;
          bulkApiModel.customFields = this.trip_customFields || null;
          bulkApiModel.reasonForTravel = this.trip_reasonForTravel || null;
          bulkApiModel.bookingType = this.trip_bookingType || null;
          bulkApiModel.bookingTypeName = this.trip_bookingTypeName || null;
          bulkApiModel.costCenterCode = this.trip_costCenterCode || null;
          bulkApiModel.budgetRefNumber = this.budgetRefNumber;
          if(bulkApiModel?.customFields?.length == 0) {
            bulkApiModel.customFields = null;
          } else if (typeof bulkApiModel?.customFields != 'string') {
            bulkApiModel.customFields = JSON.stringify(bulkApiModel.customFields || []);
          }
          console.log('4043 _______ ', bulkApiModel);
          this.expenseService.createBulk(bulkApiModel).subscribe((res) => {
            if (!res.isError && res.errorCode == 0) {
              if(!this.allEditExpenses) { this.allEditExpenses = []; }
              if(this.currentEditIndex >= 0 && this.allEditExpenses?.length > 0 && this.allEditExpenses[this.currentEditIndex] && res?.payload?.expenseResponseDTOList[0]?.expenseId) {
                this.allEditExpenses[this.currentEditIndex].expenseId = res?.payload?.expenseResponseDTOList[0]?.expenseId;
                this.allEditExpenses[this.currentEditIndex].expenseGUID = res?.payload?.expenseResponseDTOList[0]?.expenseGUID;
                // this.allEditExpenses[this.currentEditIndex] = res?.payload?.expenseResponseDTOList[0];
                if(res?.payload?.expenseResponseDTOList[0]){
                  this.allEditExpenses[this.currentEditIndex] = res?.payload?.expenseResponseDTOList[0];
                }
                res?.payload?.perdiemResponseDTO?.perDiemExpenseList?.forEach(pE => {
                  this.allEditExpenses.push(pE);
                })
              } else if(this.allEditExpenses?.length > 0 && !this.currentEditIndex && this.currentEditIndex != 0) {
                // this.allEditExpenses.push(res?.payload?.expenseResponseDTOList[0]);
                if(res?.payload?.expenseResponseDTOList[0]){
                  this.allEditExpenses.push(res?.payload?.expenseResponseDTOList[0]);
                }
                res?.payload?.perdiemResponseDTO?.perDiemExpenseList?.forEach(pE => {
                  this.allEditExpenses.push(pE);
                })
              }
              this.tripEstimateDetails = res.payload?.tripEstimateDetails;
              if(this.toPathInfoData?.tripEstimateDetails?.length > 0){
                this.toPathInfoData.tripEstimateDetails = res.payload?.tripEstimateDetails;
              }
              this.expenseTaggedTripList = res.payload.claimTaggedTripList;
              this.totalBulkModel.claimId = res?.payload?.claimId;
              this.trip_customFields = res.payload.customFields || null;
              this.trip_reasonForTravel = res.payload.reasonForTravel || null;
              this.trip_bookingType = res.payload.bookingType || null;
              this.trip_bookingTypeName = res.payload.bookingTypeName || null;
              this.trip_costCenterCode = res.payload.costCenterCode || null;
              sessionStorage.setItem('tripData', JSON.stringify({
                bookingType: this.trip_bookingType,
                bookingTypeName: this.trip_bookingTypeName,
                customFields: this.trip_customFields,
                costCenterCode: this.trip_costCenterCode,
                reasonForTravel: this.trip_reasonForTravel
              }));
              this.backClick = true;
              this.buttonDisabled = false;
              this.submitMileage = false;
              this.mileageForm.reset();
              this.expenseModel.expenseAttachments = [];
              // if (!this.editlist) {
              //   if (res.payload?.perdiemResponseDTO?.perDiemExpenseList) {
              //     res.payload?.perdiemResponseDTO?.perDiemExpenseList.forEach(element => {
              //       element.isSelected = true;
              //       this.fromPathInfo.expenseList.push(element);
              //     });
              //   }
              // }

              this.sharedService.setMapData(null);
              this.removeImage();

              if (this.expenseArray?.length && !this.isCloseModal && !this.isAssociated) {
                this.slideExpense(true);
              } else {
                this.onSaveClick(res.payload);
              }

              if (this.platformType == 2) {
                this.mileageForm.controls.originalCurrencyCode.patchValue({
                  originalCurrencyCode: this.userInfo.baseCurrency.currencyCode,
                  currencyCode: this.userInfo.baseCurrency.currencyCode,
                  originalCurrencyId: this.userInfo.baseCurrency.currencyId,
                });
                this.expenseModel.originalCurrencyId =
                  this.userInfo.baseCurrency.currencyId;

              } else {
              }
              if (!this.editlist) {
                this.sharedService.setExpenseData(res.payload);
              }
              this.displayApiMsgs(2);
            } else if (res.isError && res.errorCode == 2) {
              this.buttonDisabled = false;
              this.submitMileage = false;
              this.toastr.error(
                res.errorMessage,
                this.translate.instant("alerts.error", { timeOut: 3000 })
              );
              this.sharedService.setMapData(null);
            }
          });
        // }
      }
    } else if (this.active == 'end') {
      this.submitPerdiem = true;
      // this.submitExpense = true;
      // console.log(this.totalBulkForm);
      // console.log(this.totalBulkModel.expenseRequestDTOList);

      // if (!this.perdiemForm.valid && !this.totalBulkForm.touched) {
      if (!this.perdiemForm.valid && this.expenseTabLabel == 'Perdiem') {
        this.perdiemFieldsMandatory = true;
        this.perdiemFieldsMandatoryText = "Below mandatory fields are missing. Please check."
      } else {
        this.perdiemFieldsMandatory = false;
      }

      if (this.totalBulkModel.expenseRequestDTOList.length == 1) {
        if (this.totalBulkModel.expenseRequestDTOList[0].categoryName != undefined && this.totalBulkModel.expenseRequestDTOList[0].categoryName != null) {
          if (!this.totalBulkForm.valid && this.expenseTabLabel == 'Expenses') {
            this.bulkFieldsMandatory = true;
            this.submitExpense = true;
            this.bulkFieldsMandatoryText = 'Below mandatory fields are missing. Please check.'
          } else {
            this.bulkFieldsMandatory = false;
          }
        }

      } else if (this.totalBulkModel.expenseRequestDTOList.length > 1) {
        if (!this.totalBulkForm.valid && this.expenseTabLabel == 'Expenses') {
          this.bulkFieldsMandatory = true;
          // this.submitExpense = true;
          this.bulkFieldsMandatoryText = 'Below mandatory fields are missing. Please check.'
        } else {
          this.bulkFieldsMandatory = false;
        }
      }

      if (this.navInfoData.url == 'newreport' && this.expenseTaggedTripList?.length == 0) {
        if (this.totalMileageBulkModel.expenseRequestDTOList.length == 1) {
          if (this.MileagebulkItemsForms.value[0].categoryName != null) {
            if (!this.mileageForm.valid && this.expenseTabLabel == 'Mileage') {
              this.mileageFieldsMandatory = true;
              this.mileageFieldsMandatoryText = "Below mandatory fields are missing. Please check."
            } else {
              this.mileageFieldsMandatory = false;
            }
          }
        } else if (this.totalMileageBulkModel.expenseRequestDTOList.length > 1) {
          if (!this.mileageForm.valid && this.expenseTabLabel == 'Mileage') {
            this.mileageFieldsMandatory = true;
            this.mileageFieldsMandatoryText = "Below mandatory fields are missing. Please check."
          } else {
            this.mileageFieldsMandatory = false;
          }
        }
      }

      if (this.bulkFieldsMandatory || this.mileageFieldsMandatory || this.perdiemFieldsMandatory) {
        this.openFieldsMissingPopup();
        return;
      }
      const attchamentNot = this.sampleDt.filter((obj) => { return obj.expenseAttachments.length == 0; });
      if (this.perdiemRateSelected == "Rate with Receipt" && attchamentNot && attchamentNot.length != 0) {
        if (this.platformType == 1) {
          const config = {
            ignoreBackdropClick: false,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
          };
          this.modalRef = this.modalService.show(this.incompleteTemp, config);
        } else {
          const alert = await this.alertController.create({
            header: 'Alert',
            subHeader: 'Incomplete Data',
            message: 'Attachment is Mandatory As per the policy.',
            buttons: ['OK'],
          });
          await alert.present();
        }
        return;
      }

      if (this.checkingAttMandatoryInForms('end') || this.bulkFormValidation() || this.mileageFormValidatoin()) {
        return;
      }

      this.buttonDisabled = true;
        // BULK FORM EXPENSES //
        let bulkApiModel = new BulkModel();
        bulkApiModel.legalEntityId = this.userInfo.legalEntityId;
        bulkApiModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        bulkApiModel.claimStartDate = this.datechangeService.convertCalendarToAPI(this.totalBulkForm.controls.claimStartDate.value);
        bulkApiModel.claimEndDate = this.datechangeService.convertCalendarToAPI(this.totalBulkForm.controls.claimEndDate.value);
        bulkApiModel.claimCategoryId = this.totalBulkForm.controls.claimCategoryId.value;
        bulkApiModel.claimCategoryName = this.totalBulkForm.controls.claimCategory.value;
        bulkApiModel.claimTypeId = this.totalBulkForm.controls.claimTypeId.value;
        bulkApiModel.claimTypeName = this.totalBulkForm.controls.claimTypeName.value;
        bulkApiModel.claimEndTime = this.totalBulkForm?.controls?.claimEndTime?.value;
        bulkApiModel.claimStartTime = this.totalBulkForm?.controls?.claimStartTime?.value;
        bulkApiModel.userId = this.userInfo.userId;
        bulkApiModel.userGUID = this.userInfo.userGuId;
        bulkApiModel.action = 1;
        bulkApiModel.expenseRequestDTOList = [];
        if (!this.userInfo.allowItemizedExpense) {
          bulkApiModel.claimId = this.navInfoData['report']?.claimId;
          bulkApiModel.claimName = this.navInfoData['report']?.claimName;
        } else {
          bulkApiModel.claimId = this.totalBulkModel?.claimId;
          bulkApiModel.claimName = this.totalBulkModel?.claimName;
        }

        // BULK FORM EXPENSES
        if (this.totalBulkModel.expenseRequestDTOList.length == 1) {
          if (this.totalBulkModel.expenseRequestDTOList[0].categoryName != undefined && this.totalBulkModel.expenseRequestDTOList[0].categoryName != null) {
            this.totalBulkModel.expenseRequestDTOList.forEach(element => {
              element.expenseType = 1;
              element.categoryType = 1;
              element.recordType = 1;
              bulkApiModel.expenseRequestDTOList.push(element);
            });
          }

        } else if (this.totalBulkModel.expenseRequestDTOList.length > 1) {
          this.totalBulkModel.expenseRequestDTOList.forEach(element => {
            element.expenseType = 1;
            element.categoryType = 1;
            element.recordType = 1;
            bulkApiModel.expenseRequestDTOList.push(element);
          });
        }

        // if (this.totalBulkModel.expenseRequestDTOList) {
        //   this.totalBulkModel.expenseRequestDTOList.forEach((item, index) => {
        //     item.expenseType = 1;
        //     item = _.omit(item, "merchantList");
        //     item.loader = false;
        //     item.merchantName = this.bulkItemsForms.value[index].merchantName;
        //     bulkApiModel.expenseRequestDTOList.push(item);
        //   });
        // }

        // if (bulkApiModel.expenseRequestDTOList) {
        //   bulkApiModel.expenseRequestDTOList.forEach(element => {
        //     element.categoryType = 1;
        //     element.expenseAttachments.forEach(innerelement => {
        //       innerelement.content = '';
        //     });
        //   });
        // }

        // MILEAGE EXPENSE //

        if ((this.navInfoData.url == 'newreport' || this.navInfoData.url == 'reports' || this.navInfoData.url == 'expensesTrip') && this.totalMileageBulkModel?.expenseRequestDTOList?.length != 0) {

          if (this.totalMileageBulkModel.expenseRequestDTOList.length == 1) {
            if (this.MileagebulkItemsForms?.value[0]?.categoryName != null) {
              this.MileagebulkItemsForms.value.forEach((element, index) => {
                element.expenseType = 1;
                element.categoryType = 2;
                element.recordType = 1;
                element.categoryId = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId;
                element.categoryCode = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryCode;
                if (typeof element.fromLocationName === 'object' && element.fromLocationName !== null) {
                  element.fromLocationName = element.fromLocationName.title || '';
                }
                if (typeof element.toLocationName === 'object' && element.toLocationName !== null) {
                  element.toLocationName = element.toLocationName.title || '';
                }
                bulkApiModel.expenseRequestDTOList.push(element);
              });
            }
          } else if (this.totalMileageBulkModel.expenseRequestDTOList.length > 1) {
            this.MileagebulkItemsForms?.value?.forEach((element, index) => {
              element.expenseType = 1;
              element.categoryType = 2;
              element.recordType = 1;
              element.categoryId = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId;
              element.categoryCode = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryCode;
              bulkApiModel.expenseRequestDTOList.push(element);
            });
          }
        }

        bulkApiModel.allowItemizedExpenses = this.userInfo.allowItemizedExpenses;
        if (this.navInfoData?.url == 'expensesTrip' || this.isAssociated) {
          bulkApiModel.claimTaggedTripList = this.expenseTaggedTripList;
          bulkApiModel.tripTravellers=this.tripTravellers
        }
        bulkApiModel.claimName = this.totalBulkForm.controls.claimName.value;

        if(this.strToNum(this.perdiemModel?.originalApproveAmount) >= 0 && this.sampleDt?.length > 0) {
          this.sampleDt[0].originalApproveAmount = this.strToNum(this.perdiemModel?.originalApproveAmount) || null;
        }

        let _requstPayload = {
          legalEntityId: this.userInfo.legalEntityId,
          legalEntityGUID: this.userInfo.legalEntityGuId,
          locationId: this.perdiemModel.PDlocationId,
          locationName: this.perdiemModel.PDlocation,
          cityId: this.perdiemModel.PDCityId,
          cityName: this.perdiemModel.PDCity,
          perDiemRate: this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
          perDiemType: this.perdiemModel.PDradioSelection,
          startDate: this.editlist ? this.getExpenseDetails.startDate : this.perdiemModel.PDstartDate,
          endDate: this.editlist ? this.getExpenseDetails.endDate : this.perdiemModel.PDendDate,
          startTime: this.editlist ? this.getExpenseDetails.startTime : this.perdiemModel.PDendDate,
          endTime: this.editlist ? this.getExpenseDetails.endTime : this.perdiemModel.PDendDate,
          // discription: this.perdiemForm.controls["PDdescription"].value,
          originalCurrencyId: 64,
          allowItemizedExpenses: this.userInfo.allowItemizedExpenses,
          perDiemExpenseList: this.sampleDt,
          // financeIntegrationCode: "FI123",
          // financeIntegrationId: 534,
          // financeIntegrationName: "FI!vgjnkml,"
          customFieldList: this.perdiemModel.customFieldList,
          bandId: this.perdiemModel.bandId,
          bandName: this.perdiemModel.bandName,
          departmentId: this.perdiemModel.departmentId,
          departmentName: this.perdiemModel.departmentName,
          costCenterId: this.perdiemModel.costCenterId,
          costCenterName: this.perdiemModel.costCenterName,
          projectCodeId: this.perdiemModel.projectCodeId,
          projectCodeName: this.perdiemModel.projectCodeName,
          originalApproveAmount: this.perdiemModel.originalApproveAmount
        };

        if (!this.userInfo.allowItemizedExpense) {
          _requstPayload['claimId'] = this.navInfoData['report']?.claimId;
          _requstPayload['claimName'] = this.navInfoData['report']?.claimName;
        } else {
          _requstPayload['claimId'] = '';
          _requstPayload['claimName'] = this.perdiemModel.PDclaimName;
        }

        bulkApiModel.perdiemRequestDTO = _requstPayload;

        if (this.editlist && (this.navInfoData.url == 'newreport' ? this.navInfoData?.expenseList : this.navInfoData?.expenseArray)) {
          let filterdata = [];
          if (this.navInfoData.url == 'newreport') {
            filterdata = this.navInfoData?.expenseList.filter((obj) => { return obj.isSelected && obj.expenseId != this.editlist.expenseId; });
          } else {
            filterdata = this.navInfoData?.expenseArray.filter((obj) => { return obj.expenseId != this.editlist.expenseId; });
          }
          filterdata.forEach(element => {
            element.expenseAttachments = [];
          });
          bulkApiModel.expenseRequestDTOList.push(...filterdata);
        }
        bulkApiModel.expenseRequestDTOList?.forEach(e => {
          e.baseAmount = parseInt((e.baseAmount || 0).toString().replace(/,/g, ''), 10);
          // e.originalAmount = parseInt((e.originalAmount || 0).toString().replace(/,/g, ''), 10);
          e.originalAmount = parseFloat((e.originalAmount || 0).toString().replace(/,/g, ''));
          // const cleanedDate = e.expensedDate?.toString().replace(/\(.*\)/, '').trim();
          // e.expensedDate = moment(e.expensedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
          // if(moment(e.expensedDate).isUTC() || moment.isMoment(e.expensedDate)) {
          if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate) || this.checkDateFormat(e.expensedDate)) {
            e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
          }
          e.expenseAttachments?.forEach(a => {
            if (a.content == null || a.content == "") {
              delete a.content;
            }
          });
          if (this.enableUpdate && (!e.claimId || !e.claimName)) {
            const list = this.toPathInfoData?.expenseList[0] || this.toPathInfoData?.expenseArray[0];
            e.claimId = list?.claimId ? list.claimId : this.toPathInfoData?.detailReport?.claimId;
            e.claimName = this.totalBulkForm?.controls?.claimName?.value;
          }
          e.baseApproveAmount = e.baseApproveAmount?.toString().replace(/,/g, '');
          e.hsnId = e.hsnCodeId || null;
        })

        bulkApiModel.perdiemRequestDTO?.perDiemExpenseList?.forEach(e => {
          e.baseAmount = parseInt((e.baseAmount || 0).toString().replace(/,/g, ''), 10);
          // e.originalAmount = parseInt((e.originalAmount || 0).toString().replace(/,/g, ''), 10);
          e.originalAmount = parseFloat((e.originalAmount || 0).toString().replace(/,/g, ''));
          // console.log(e.expensedDate);
          // e.expensedDate = moment(e.expenseDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
          // e.expenseDate = moment(e.expenseDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
          // if(moment(e.expensedDate).isUTC() || moment.isMoment(e.expensedDate)) {
          if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate)) {
            e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
          }
          // if(moment(e.expenseDate).isUTC() || moment.isMoment(e.expenseDate)) {
          if (!moment.utc(e.expenseDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expenseDate)) {
            e.expenseDate = moment(e.expenseDate).format('DD-MM-YYYY');
          }
        })

        if (this.enableUpdate && !bulkApiModel.claimId && (this.navInfoData?.expenseList || this.navInfoData.expenseArray)) {
          const list = this.navInfoData.expenseList[0] || this.navInfoData.expenseArray[0];
          bulkApiModel.claimId = list?.claimId;
          bulkApiModel.claimName = list?.claimName;
        }
        bulkApiModel.isLinkedToAdvancePayment = this.navInfoData?.isLinkedToAdvancePayment;
        bulkApiModel.isLinkedToPettyCashPayment = this.navInfoData?.isLinkedToPettyCashPayment;
        console.log(bulkApiModel);
        if (this.navInfoData?.isLinkedToAdvancePayment) {
          if (this.totalClaimedAmount <= this.walletAdvance) {
            bulkApiModel.advanceAmount = this.totalClaimedAmount;
          } else {
            bulkApiModel.advanceAmount = this.walletAdvance;
          }
        } else {
          bulkApiModel.advanceAmount = 0;
        }
        if (!bulkApiModel.claimTaggedTripList && this.expenseTaggedTripList) {
          bulkApiModel.claimTaggedTripList = this.expenseTaggedTripList;
          bulkApiModel.tripTravellers=this.tripTravellers
        }
        if (this.enableUpdate && (!bulkApiModel?.claimId || !bulkApiModel?.claimName)) {
          const list = this.toPathInfoData?.expenseList[0] || this.toPathInfoData?.expenseArray[0];
          bulkApiModel.claimId = list?.claimId ? list.claimId : this.toPathInfoData?.detailReport?.claimId;
          bulkApiModel.claimName = list?.claimName;
        }
        bulkApiModel.claimName = this.totalBulkForm?.controls?.claimName?.value;
        bulkApiModel.customFields = this.trip_customFields || null;
        bulkApiModel.reasonForTravel = this.trip_reasonForTravel || null;
        bulkApiModel.bookingType = this.trip_bookingType || null;
        bulkApiModel.bookingTypeName = this.trip_bookingTypeName || null;
        bulkApiModel.costCenterCode = this.trip_costCenterCode || null;
        bulkApiModel.budgetRefNumber = this.budgetRefNumber;
        if(bulkApiModel?.customFields?.length == 0) {
          bulkApiModel.customFields = null;
        } else if (typeof bulkApiModel?.customFields != 'string') {
          bulkApiModel.customFields = JSON.stringify(bulkApiModel.customFields || []);
        }
        console.log('4455 _______ ', bulkApiModel);
        this.expenseService.createBulk(bulkApiModel).subscribe((res) => {
          this.buttonDisabled = false;
          if (res.errorCode == 0) {
            if(!this.allEditExpenses) { this.allEditExpenses = []; }
            if(this.currentEditIndex >= 0 && this.allEditExpenses?.length > 0 && this.allEditExpenses[this.currentEditIndex] && res?.payload?.expenseResponseDTOList[0]?.expenseId) {
              this.allEditExpenses[this.currentEditIndex].expenseId = res?.payload?.expenseResponseDTOList[0]?.expenseId;
              this.allEditExpenses[this.currentEditIndex].expenseGUID = res?.payload?.expenseResponseDTOList[0]?.expenseGUID;
              if(res?.payload?.expenseResponseDTOList[0]){
                this.allEditExpenses[this.currentEditIndex] = res?.payload?.expenseResponseDTOList[0];
              }
              res?.payload?.perdiemResponseDTO?.perDiemExpenseList?.forEach(pE => {
                this.allEditExpenses.push(pE);
              })
            } else if(this.allEditExpenses?.length > 0 && !this.currentEditIndex && this.currentEditIndex != 0) {
              if(res?.payload?.expenseResponseDTOList[0]){
                this.allEditExpenses.push(res?.payload?.expenseResponseDTOList[0]);
              }
              res?.payload?.perdiemResponseDTO?.perDiemExpenseList?.forEach(pE => {
                this.allEditExpenses.push(pE);
              })
            }
            this.tripEstimateDetails = res.payload?.tripEstimateDetails;
            if(this.toPathInfoData?.tripEstimateDetails?.length > 0){
              this.toPathInfoData.tripEstimateDetails = res.payload?.tripEstimateDetails;
            }
            this.expenseTaggedTripList = res.payload.claimTaggedTripList;
            this.totalBulkModel.claimId = res?.payload?.claimId;
            this.trip_customFields = res.payload.customFields || null;
            this.trip_reasonForTravel = res.payload.reasonForTravel || null;
            this.trip_bookingType = res.payload.bookingType || null;
            this.trip_bookingTypeName = res.payload.bookingTypeName || null;
            this.trip_costCenterCode = res.payload.costCenterCode || null;
            sessionStorage.setItem('tripData', JSON.stringify({
              bookingType: this.trip_bookingType,
              bookingTypeName: this.trip_bookingTypeName,
              customFields: this.trip_customFields,
              costCenterCode: this.trip_costCenterCode,
              reasonForTravel: this.trip_reasonForTravel
            }));
            this.backClick = true;
            this.toastr.success("Perdiem Created Successfully", "Success", { timeOut: 3000 });
            if (!this.editlist) {
              this.sharedService.setExpenseData(res.payload);
            }
            // if (!this.editlist && res.payload?.perdiemResponseDTO?.perDiemExpenseList) {
            //   res.payload?.perdiemResponseDTO?.perDiemExpenseList.forEach(element => {
            //     element.isSelected = true;
            //     this.fromPathInfo.expenseList.push(element);
            //   });
            // }
            if (this.navInfoData.url == 'expensesTrip') {
              let reportModel = new Report();
              reportModel.isLinkedToAdvancePayment = this.navInfoData.report.isLinkedToAdvancePayment;
              reportModel.isLinkedToPettyCashPayment = this.navInfoData.report.isLinkedToPettyCashPayment;
              reportModel.claimStartDate = res.payload.claimStartDate;
              reportModel.claimEndDate = res.payload.claimEndDate;
              reportModel.claimName = res.payload.claimName;
              reportModel.claimId = res.payload.claimId;
              reportModel.claimGUID = res.payload.claimGUID;
              reportModel.claimTypeName = res.payload.claimTypeName || null;
              reportModel.claimTypeId = res.payload.claimTypeId || null;
              reportModel.claimCategoryName = res.payload.claimCategoryName || null;
              reportModel.claimCategoryId = res.payload.claimCategoryId || null;
              let expenseList = [];
              res.payload.expenseResponseDTOList.forEach(element => {
                element.isSelected = true;
                expenseList.push(element);
              });
              let request_payload = {
                url: 'newreport',
                appliedAmount: 0,
                editAppliedAmt: 0,
                isAdvLinkedBefore: false,
                walletAdvance: 0,
                report: reportModel,
                reporttitle: true,
                claimTaggedTripList: res.payload.claimTaggedTripList,
                tripTravellers:res.payload.tripTravellers,
                expenseList: res.payload.expenseResponseDTOList,
                claimStartDate: res.payload.claimStartDate,
                claimEndDate: res.payload.claimEndDate,
                isLinkedToAdvancePayment: this.navInfoData?.isLinkedToAdvancePayment,
                isLinkedToPettyCashPayment: this.navInfoData?.isLinkedToPettyCashPayment,
              }
              this.fromPathInfo = request_payload;
            }
            this.perdiemForm.reset();
            this.onSaveClick(res.payload);
            // this.backToOriginalPage();
          } else {
            this.toastr.error(res.errorMessage, "Error", { timeOut: 3000 });
          }

        });
    }

  }

  displayApiMsgs(type: any) {
    var alert = "";
    if (type == 1) {
      if (this.fromPettyCash) {
        alert = this.translate.instant("alerts.createPCE");
      } else {
        alert = this.translate.instant("alerts.createExp");
      }
    } else if (type == 2) {
      alert = this.translate.instant("alerts.milcreate");
    } else {
      alert = 'Claim created successfully';
    }

    if (this.updatetitle || this.isEdit) {
      alert = 'Claim ' + this.translate.instant("alerts.updateSuccess");
    } else {
      if (!this.isAssociated) {
        alert = this.translate.instant("alerts.createsuccess");
      } else {
      }
    }
    this.toastr.success(alert, this.translate.instant("alerts.succes", { timeOut: 3000 }));
  }

  createBulkApi() {
    this.submitExpense = true;
    // for (let i = this.bulkItemsForms.controls.length - 1; i >= 1; i--) {
    //   const object = this.bulkItemsForms.controls[i] as FormGroup;
    //   if (!object.touched) {
    //     this.bulkItemsForms.removeAt(i);
    //     this.totalBulkModel.expenseRequestDTOList.splice(i, 1);
    //   }
    // }

    if (!this.totalBulkForm.valid && this.expenseTabLabel == 'Expenses') {
      console.log('total bulk form :',this.total);
      this.bulkFieldsMandatory = true;
      this.bulkFieldsMandatoryText = "Below mandatory fields are missing. Please check."
    } else {
      this.bulkFieldsMandatory = false;
    }

    if(this.totalBulkModel?.showRestrictMessage){
      const config = {
        ignoreBackdropClick: false,
        class: "modal-lg modal-dialog-centered",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(this.violationsPopup, config);
      return;
    }


    if (this.navInfoData.url == 'newreport' && this.expenseTaggedTripList?.length == 0) {
      if (this.totalMileageBulkModel.expenseRequestDTOList.length == 1) {
        if (this.MileagebulkItemsForms.at(0)?.get('location')?.value != null) {
          if (!this.mileageForm.valid && this.expenseTabLabel == 'Mileage') {
            this.mileageFieldsMandatory = true;
            this.mileageFieldsMandatoryText = "Below mandatory fields are missing. Please check."
          } else {
            this.mileageFieldsMandatory = false;
          }
        }else{
          this.mileageFieldsMandatory = false;
        }
      } else if (this.totalMileageBulkModel.expenseRequestDTOList.length > 1) {
        if (!this.mileageForm.valid && this.expenseTabLabel == 'Mileage') {
          this.mileageFieldsMandatory = true;
          this.mileageFieldsMandatoryText = "Below mandatory fields are missing. Please check."
        } else {
          this.mileageFieldsMandatory = false;
        }
      }
    }

    if (this.sampleDt.length != 0) {
      if (!this.perdiemForm.valid && this.expenseTabLabel == 'Perdiem') {
        this.perdiemFieldsMandatory = true;
        this.perdiemFieldsMandatoryText = "Below mandatory fields are missing. Please check."
      } else {
        this.perdiemFieldsMandatory = false;
      }
    }

    if (this.bulkFieldsMandatory || this.mileageFieldsMandatory || this.perdiemFieldsMandatory) {
      this.openFieldsMissingPopup();
      return;
    }
    // const attchamentNot_policy = this.totalBulkModel.expenseRequestDTOList.filter((element) => { return (element.policyLimitReceiptWarning || element.categoryLimitReceiptWarning) && !element.bulkImgUpload && element.expenseAttachments?.length<=0; });
    const attchamentNot_policy = this.totalBulkModel.expenseRequestDTOList.filter((element) => { return element.attachmentVoilation && element.attachmentVoilation != '' && element.expenseAttachments?.length <= 0 });
    if (attchamentNot_policy && attchamentNot_policy.length != 0 && attchamentNot_policy[0]?.expenseAttachments?.length <= 0) {
      const config = {
        ignoreBackdropClick: false,
        class: "modal-md modal-dialog-centered",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(this.incompleteTemp, config);
      return;
    }
    if (this.checkingAttMandatoryInForms('bottom') || this.bulkExpnseFixedAmount() || this.bulkExpenseCount() || this.mileageFormValidatoin()) {
      return;
    }
    if (this.sampleDt.length != 0 && this.checkingAttMandatoryInForms('end')) {
      return;
    }

    // request payload //
    let bulkApiModel = new BulkModel();
    bulkApiModel.legalEntityId = this.userInfo.legalEntityId;
    bulkApiModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    bulkApiModel.claimStartDate = this.datechangeService.convertCalendarToAPI(this.totalBulkForm.controls.claimStartDate.value);
    bulkApiModel.claimEndDate = this.datechangeService.convertCalendarToAPI(this.totalBulkForm.controls.claimEndDate.value);
    bulkApiModel.claimCategoryId = this.totalBulkForm.controls.claimCategoryId.value;
    bulkApiModel.claimCategoryName = this.totalBulkForm.controls.claimCategory.value;
    bulkApiModel.claimTypeId = this.totalBulkForm.controls.claimTypeId.value;
    bulkApiModel.claimTypeName = this.totalBulkForm.controls.claimTypeName.value;
    bulkApiModel.claimEndTime = this.totalBulkForm.controls.claimEndTime.value;
    bulkApiModel.claimStartTime = this.totalBulkForm.controls.claimStartTime.value;
    bulkApiModel.userId = this.userInfo.userId;
    bulkApiModel.userGUID = this.userInfo.userGuId;
    bulkApiModel.isLinkedToAdvancePayment = this.navInfoData?.report?.isLinkedToAdvancePayment;
    bulkApiModel.isLinkedToPettyCashPayment = this.navInfoData?.report?.isLinkedToPettyCashPayment;
    // bulkApiModel.advanceAmount=this.advanceAmount;
    bulkApiModel.action = 1;
    bulkApiModel.expenseRequestDTOList = [];
    bulkApiModel.tripEstimateCost = this.trip_estimate;
    bulkApiModel.tripEstimateInUSD = this.tripEstimateInUSD;

    // if(this.totalClaimedAmount <= this.advanceAmount){
    //   console.log("totalClaimedAmount")
    //   bulkApiModel.advanceAmount=this.totalClaimedAmount;
    // }else{
    //   bulkApiModel.advanceAmount=this.advanceAmount;
    //   console.log("advance")
    // }
    if (!this.userInfo.allowItemizedExpense) {
      bulkApiModel.claimId = this.navInfoData['report']?.claimId;
      bulkApiModel.claimName = this.navInfoData['report']?.claimName;
    } else {
      bulkApiModel.claimId = this.totalBulkModel?.claimId;
      bulkApiModel.claimName = this.totalBulkModel?.claimName;
    }

    console.log('bulk items here :', this.bulkItemsForms);
    // console.log(this.totalBulkModel.expenseRequestDTOList);
    this.totalBulkModel.expenseRequestDTOList.forEach((item, index) => {
      item.expenseType = this.bulkItemsForms.value[index].expenseType ? this.bulkItemsForms.value[index].expenseType : 1;
      item = _.omit(item, "merchantList");
      item.loader = false;
      item.recordType = this.bulkItemsForms.value[index].recordType ? this.bulkItemsForms.value[index].recordType : 1;
      item.financeIntegrationCode = this.bulkItemsForms.value[index].financeIntegrationCode ? this.bulkItemsForms.value[index].financeIntegrationCode : '';
      item.financeIntegrationId = this.bulkItemsForms.value[index].financeIntegrationId ? this.bulkItemsForms.value[index].financeIntegrationId : '';
      item.taxCode = this.bulkItemsForms.value[index].taxCode ? this.bulkItemsForms.value[index].taxCode : '';
      item.taxCodeId = this.bulkItemsForms.value[index].taxCodeId ? this.bulkItemsForms.value[index].taxCodeId : '';
      item.hsnCode = this.bulkItemsForms.value[index].hsnCode ? this.bulkItemsForms.value[index].hsnCode : '';
      item.hsnCodeId = this.bulkItemsForms.value[index].hsnCodeId ? this.bulkItemsForms.value[index].hsnCodeId : '';
      item.locationName = this.bulkItemsForms.value[index]?.locationName ? this.bulkItemsForms.value[index]?.locationName : '';
      item.parentCategoryId = this.selectedClaimCatObj.categoryId;
      item.parentCategoryName = this.selectedClaimCatObj.categoryName;
      item?.customFieldList?.forEach(cf_element => {
        if (Array.isArray(cf_element.customFieldValue)) {
          cf_element.customFieldValue = JSON.stringify(cf_element.customFieldValue);
        }
      });
      bulkApiModel.expenseRequestDTOList.push(item);
    });

    if (this.editlist && (this.navInfoData.url == 'newreport' ? this.navInfoData?.expenseList : this.navInfoData?.expenseArray)) {
      let filterdata = [];
      if (this.navInfoData.url == 'newreport') {
        filterdata = this.navInfoData?.expenseList.filter((obj) => { return obj.isSelected && obj.expenseId != this.editlist.expenseId; });
      } else {
        filterdata = this.navInfoData?.expenseArray.filter((obj) => { return obj.expenseId != this.editlist.expenseId; });
      }
      filterdata.forEach(element => {
        element.expenseAttachments = [];
      });
      bulkApiModel.expenseRequestDTOList.push(...filterdata);
    }

    // converting base 64 to empty //
    bulkApiModel.expenseRequestDTOList.forEach(element => {
      element.categoryType = 1;
      element.expenseAttachments.forEach(innerelement => {
        innerelement.content = '';
      });
    });

    // checking milegae expenses //

    if (this.navInfoData.url == 'newreport' || this.navInfoData.url == 'reports' || this.navInfoData.url == 'expensesTrip') {
      if (this.totalMileageBulkModel.expenseRequestDTOList.length == 1) {
        if (this.MileagebulkItemsForms.value[0].categoryName != null) {
          this.MileagebulkItemsForms.value.forEach((element, index) => {
            element.expenseType = 1;
            element.categoryType = 2;
            element.recordType = 1;
            element.categoryId = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId;
            element.categoryCode = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryCode;
            element.expenseId = this.totalMileageBulkModel.expenseRequestDTOList[index].expenseId;
            element.expenseGUID = this.totalMileageBulkModel.expenseRequestDTOList[index].expenseGUID;
            if (!moment.utc(element.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(element.expensedDate) || this.checkDateFormat(element.expensedDate)) {
              element.expensedDate = moment(element.expensedDate).format('DD-MM-YYYY');
            }
            element.expensedDate = this.datechangeService.convertCalendarToAPI(element.expensedDate);
            element.customFieldList = this.totalMileageBulkModel.expenseRequestDTOList[index].customFieldList || [];
            element.checkPointsList = this.totalMileageBulkModel.expenseRequestDTOList[index].checkPointsList || [];
            if (typeof element.fromLocationName === 'object' && element.fromLocationName !== null) {
              element.fromLocationName = element.fromLocationName.title || '';
            }
            if (typeof element.toLocationName === 'object' && element.toLocationName !== null) {
              element.toLocationName = element.toLocationName.title || '';
            }
            bulkApiModel.expenseRequestDTOList.push(element);
          });
        }
      } else if (this.totalMileageBulkModel.expenseRequestDTOList.length > 1) {
        this.MileagebulkItemsForms.value.forEach((element, index) => {
          element.expenseType = 1;
          element.categoryType = 2;
          element.recordType = 1;
          element.categoryId = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId;
          element.categoryCode = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryCode;
          element.expenseId = this.totalMileageBulkModel.expenseRequestDTOList[index].expenseId;
          element.expenseGUID = this.totalMileageBulkModel.expenseRequestDTOList[index].expenseGUID;
          if (!moment.utc(element.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(element.expensedDate) || this.checkDateFormat(element.expensedDate)) {
            element.expensedDate = moment(element.expensedDate).format('DD-MM-YYYY');
          }
          element.expensedDate = this.datechangeService.convertCalendarToAPI(element.expensedDate);
          element.customFieldList = this.totalMileageBulkModel.expenseRequestDTOList[index].customFieldList || [];
          element.checkPointsList = this.totalMileageBulkModel.expenseRequestDTOList[index].checkPointsList || [];
          if (typeof element.fromLocationName === 'object' && element.fromLocationName !== null) {
            element.fromLocationName = element.fromLocationName.title || '';
          }
          if (typeof element.toLocationName === 'object' && element.toLocationName !== null) {
            element.toLocationName = element.toLocationName.title || '';
          }
          bulkApiModel.expenseRequestDTOList.push(element);
        });
      }
    }

    bulkApiModel.allowItemizedExpenses = this.userInfo.allowItemizedExpenses;
    //adding tagged trip expenses to bulkModel when isAssociated is true//
    if (this.navInfoData?.url == 'expensesTrip' || this.isAssociated) {
      bulkApiModel.claimTaggedTripList = this.expenseTaggedTripList;
      bulkApiModel.tripTravellers=this.tripTravellers
    }
    bulkApiModel.claimName = this.totalBulkForm.controls.claimName.value;

    // expense date Exceed Validation //
    // const attchamentNot = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.expenseDateExceed; });
    // if (attchamentNot && attchamentNot.length != 0) {
    //   return;
    // }

    if(this.strToNum(this.perdiemModel?.originalApproveAmount) >= 0 && this.sampleDt?.length > 0) {
      this.sampleDt[0].originalApproveAmount = this.strToNum(this.perdiemModel?.originalApproveAmount) || null;
    }

    if (this.sampleDt.length != 0) {

      let perdiemRequestDTO = {
        expenseId: null,
        legalEntityId: this.userInfo.legalEntityId,
        legalEntityGUID: this.userInfo.legalEntityGuId,
        locationId: this.perdiemModel.PDlocationId,
        locationName: this.perdiemModel.PDlocation,
        cityId: this.perdiemModel.PDCityId,
        cityName: this.perdiemModel.PDCity,
        perDiemRate: this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
        perDiemType: this.perdiemModel.PDradioSelection,
        startDate: this.perdiemModel.PDstartDate,
        endDate: this.perdiemModel.PDendDate,
        discription: this.perdiemForm.controls["PDdescription"].value,
        categoryId: 1,
        expenseType: 5,
        categoryType: 1,
        recordType: 1,
        exchangeRate: 1,
        originalCurrencyId: 64,
        allowItemizedExpenses: this.userInfo.allowItemizedExpenses,
        perDiemExpenseList: this.sampleDt,
      };

      if (!this.userInfo.allowItemizedExpense) {
        perdiemRequestDTO['claimId'] = this.navInfoData['report']?.claimId;
        perdiemRequestDTO['claimName'] = this.navInfoData['report']?.claimName;
      } else {
        perdiemRequestDTO['claimId'] = '';
        perdiemRequestDTO['claimName'] = this.perdiemModel.PDclaimName;
      }
      bulkApiModel.perdiemRequestDTO = perdiemRequestDTO;
      this.buttonDisabled = true;
    } else {
      this.buttonDisabled = true;
    }

    // COMING FROM REPORT SUMMARY PAGE (DRAFT)
    if (this.navInfoData.url == 'reports' && this.navInfoData.expenseArray) {
      bulkApiModel.expenseRequestDTOList.forEach(element => {
        element.claimCode = this.editlist?.claimCode,
          element.claimId = this.editlist?.claimId,
          element.claimName = this.editlist?.claimName
      });
      bulkApiModel.claimId = this.editlist?.claimId;
      bulkApiModel.claimName = this.editlist?.claimName;
    }
    if (this.isAdminView == true && (this.userInfo?.roleId == 3 || this.userInfo?.roleId == 4)) {
      bulkApiModel.expenseRequestDTOList?.forEach(e => {
        e.baseAmount = parseInt((e.baseAmount || 0).toString().replace(/,/g, ''), 10);
        // e.originalAmount = parseInt((e.originalAmount || 0).toString().replace(/,/g, ''), 10);
        e.originalAmount = parseFloat((e.originalAmount || 0).toString().replace(/,/g, ''));
        e.originalApproveAmount = parseInt((e.unformattedOriginalApproveAmount || 0).toString().replace(/,/g, ''), 10);
        // console.log(e.expensedDate);
        // e.expensedDate = moment(e.expensedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
        // if(moment(e.expensedDate).isUTC() || moment.isMoment(e.expensedDate)) {
        // let cleanedDate = e.expensedDate?.toString().replace(/\(.*\)/, '').trim();
        if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate) || this.checkDateFormat(e.expensedDate)) {
          e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
        }
        e.expenseAttachments?.forEach(a => {
          if (a.content == null || a.content == "") {
            delete a.content;
          }
        });
        if (this.enableUpdate && (!e.claimId || !e.claimName)) {
          const list = this.toPathInfoData?.expenseList[0] || this.toPathInfoData?.expenseArray[0];
          e.claimId = list?.claimId ? list.claimId : this.toPathInfoData?.detailReport?.claimId;
          e.claimName = this.totalBulkForm?.controls?.claimName?.value;
        }
        e.baseApproveAmount = e.baseApproveAmount?.toString().replace(/,/g, '');
        e.hsnId = e.hsnCodeId || null;
      })

      bulkApiModel.perdiemRequestDTO?.perDiemExpenseList?.forEach(e => {
        e.baseAmount = parseInt((e.baseAmount || 0).toString().replace(/,/g, ''), 10);
        // e.originalAmount = parseInt((e.originalAmount || 0).toString().replace(/,/g, ''), 10);
        e.originalAmount = parseFloat((e.originalAmount || 0).toString().replace(/,/g, ''));
        // console.log(e.expensedDate);
        // e.expenseDate = moment(e.expenseDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
        // if(moment(e.expensedDate).isUTC() || moment.isMoment(e.expensedDate)) {
        // let cleanedDate = e.expensedDate?.toString().replace(/\(.*\)/, '').trim();
        if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate) || this.checkDateFormat(e.expensedDate)) {
          e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
        }
        // if(moment(e.expenseDate).isUTC() || moment.isMoment(e.expenseDate)) {
        // cleanedDate = e.expenseDate?.toString().replace(/\(.*\)/, '').trim();
        if (!moment.utc(e.expenseDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expenseDate) || this.checkDateFormat(e.expenseDate)) {
          e.expenseDate = moment(e.expenseDate).format('DD-MM-YYYY');
        }
      })


      if (this.enableUpdate && !bulkApiModel.claimId && (this.navInfoData?.expenseList || this.navInfoData.expenseArray)) {
        const list = this.navInfoData.expenseList[0] || this.navInfoData.expenseArray[0];
        bulkApiModel.claimId = list?.claimId;
        bulkApiModel.claimName = list?.claimName;
      }

      if (bulkApiModel.claimId && this.expenseTaggedTripList) {
        this.expenseTaggedTripList.forEach(expenseTripList => {
          expenseTripList.claimId = bulkApiModel.claimId;
        })
      }
      if (!bulkApiModel.claimTaggedTripList && this.expenseTaggedTripList) {
        bulkApiModel.claimTaggedTripList = this.expenseTaggedTripList;
        bulkApiModel.tripTravellers=this.tripTravellers
      }
      bulkApiModel.isLinkedToAdvancePayment = this.navInfoData?.isLinkedToAdvancePayment;
      bulkApiModel.isLinkedToPettyCashPayment = this.navInfoData?.isLinkedToPettyCashPayment;
      if (this.navInfoData?.isLinkedToAdvancePayment) {
        if (this.totalClaimedAmount <= this.walletAdvance) {
          bulkApiModel.advanceAmount = this.totalClaimedAmount;
        } else {
          bulkApiModel.advanceAmount = this.walletAdvance;
        }
      } else {
        bulkApiModel.advanceAmount = 0;
      }
      if (this.enableUpdate && (!bulkApiModel?.claimId || !bulkApiModel?.claimName)) {
        const list = this.toPathInfoData?.expenseList[0] || this.toPathInfoData?.expenseArray[0];
        bulkApiModel.claimId = list?.claimId ? list.claimId : this.toPathInfoData?.detailReport?.claimId;
        bulkApiModel.claimName = list?.claimName;
      }
      bulkApiModel.claimName = this.totalBulkForm?.controls?.claimName?.value;
      bulkApiModel.customFields = this.trip_customFields || null;
      bulkApiModel.reasonForTravel = this.trip_reasonForTravel || null;
      bulkApiModel.bookingType = this.trip_bookingType || null;
      bulkApiModel.bookingTypeName = this.trip_bookingTypeName || null;
      bulkApiModel.costCenterCode = this.trip_costCenterCode || null;
      bulkApiModel.budgetRefNumber = this.budgetRefNumber;

      if(bulkApiModel?.claimTaggedTripList?.length != 0){
        this.toPathInfoData?.tripEstimateDetails?.forEach((element,index) => {
         element.tripId = bulkApiModel?.claimTaggedTripList[0].tripId
         element.claimId = bulkApiModel?.claimTaggedTripList[0].claimId         
        });

        bulkApiModel.tripEstimateDetails = this.toPathInfoData?.tripEstimateDetails;
      }
      if(bulkApiModel?.customFields?.length == 0) {
        bulkApiModel.customFields = null;
      } else if (typeof bulkApiModel?.customFields != 'string') {
        bulkApiModel.customFields = JSON.stringify(bulkApiModel.customFields || []);
      }
      console.log('4790 _______ ', bulkApiModel);
     this.expenseService
        .createBulk(bulkApiModel)
        .subscribe((res) => {
          if (!res.isError && res.errorCode == 0) {
            if(!this.allEditExpenses) { this.allEditExpenses = []; }
            if(this.currentEditIndex >= 0 && this.allEditExpenses?.length > 0 && this.allEditExpenses[this.currentEditIndex] && res?.payload?.expenseResponseDTOList[0]?.expenseId) {
              this.allEditExpenses[this.currentEditIndex].expenseId = res?.payload?.expenseResponseDTOList[0]?.expenseId;
              this.allEditExpenses[this.currentEditIndex].expenseGUID = res?.payload?.expenseResponseDTOList[0]?.expenseGUID;
              // this.allEditExpenses[this.currentEditIndex] = res?.payload?.expenseResponseDTOList[0];
              if(res?.payload?.expenseResponseDTOList[0]){
                this.allEditExpenses[this.currentEditIndex] = res?.payload?.expenseResponseDTOList[0];
              }
              res?.payload?.perdiemResponseDTO?.perDiemExpenseList?.forEach(pE => {
                this.allEditExpenses.push(pE);
              })
            } else if(this.allEditExpenses?.length > 0 && !this.currentEditIndex && this.currentEditIndex != 0) {
              // this.allEditExpenses.push(res?.payload?.expenseResponseDTOList[0]);
              if(res?.payload?.expenseResponseDTOList[0]){
                this.allEditExpenses.push(res?.payload?.expenseResponseDTOList[0]);
              }
              res?.payload?.perdiemResponseDTO?.perDiemExpenseList?.forEach(pE => {
                this.allEditExpenses.push(pE);
              })
            }
            this.tripEstimateDetails = res.payload?.tripEstimateDetails;
            if(this.toPathInfoData?.tripEstimateDetails?.length > 0){
              this.toPathInfoData.tripEstimateDetails = res.payload?.tripEstimateDetails;
            }
            this.expenseTaggedTripList = res.payload.claimTaggedTripList;
            this.totalBulkModel.claimId = res?.payload?.claimId;
            this.disableCLaimType = false;
            this.trip_customFields = res.payload.customFields || null;
            this.trip_reasonForTravel = res.payload.reasonForTravel || null;
            this.trip_bookingType = res.payload.bookingType || null;
            this.trip_bookingTypeName = res.payload.bookingTypeName || null;
            this.trip_costCenterCode = res.payload.costCenterCode || null;
            sessionStorage.setItem('tripData', JSON.stringify({
              bookingType: this.trip_bookingType,
              bookingTypeName: this.trip_bookingTypeName,
              customFields: this.trip_customFields,
              costCenterCode: this.trip_costCenterCode,
              reasonForTravel: this.trip_reasonForTravel
            }));
            this.buttonDisabled = false;
            this.submitExpense = false;
            this.buttoncloseDisabled = false;
            this.backClick = true;
            this.expenseForm.reset();
            this.expenseModel.expenseAttachments = [];
            if (!this.editlist) {
              this.sharedService.setExpenseData(res.payload);
            }
            this.selectedTrips = [];
            if (this.expenseArray?.length && !this.isCloseModal && !this.isAssociated) {
              // sessionStorage.setItem('approvedExpenseList', JSON.stringify(this.approvedExpenseList));
              this.slideExpense(true);
            } else {
              this.onSaveClick(res.payload);
            }

            this.removeImage();
            this.caputredImageModal.capturedImages = [];
            this.expenseForm.controls.expenseType.patchValue(1);
            this.expenseModel.expenseType = 1;
            if (this.platformType == 2) {
              this.expenseForm.controls.originalCurrencyCode.patchValue({
                originalCurrencyCode: this.userInfo.baseCurrency.currencyCode,
                currencyCode: this.userInfo.baseCurrency.currencyCode,
                originalCurrencyId: this.userInfo.baseCurrency.currencyId,
              });
            } else {
              this.expenseForm.controls.originalCurrencyCode.patchValue(
                this.userInfo.baseCurrency.currencyCode
              );
              this.mileageForm.controls.originalCurrencyCode?.patchValue(
                this.userInfo.baseCurrency.currencyCode
              );
              this.expenseModel.originalCurrencyId =
                this.userInfo.baseCurrency.currencyId;
            }

            this.displayApiMsgs(1);
            sessionStorage.setItem("MapRefIDs", null);
          } else if (res.isError && res.errorCode == 2) {
            this.buttonDisabled = false;
            this.submitExpense = false;
            this.buttoncloseDisabled = false;
            this.toastr.error(
              res.errorMessage,
              this.translate.instant("alerts.error", { timeOut: 3000 })
            );
          }
        });
    } else {
      bulkApiModel.expenseRequestDTOList?.forEach(e => {
        // console.log("originalAmount",e)
        e.baseAmount = parseInt((e.baseAmount || 0).toString().replace(/,/g, ''), 10);
      //  / e.originalAmount = parseInt((e.originalAmount || 0).toString().replace(/,/g, ''), 10);
      e.originalAmount = parseFloat((e.originalAmount || 0).toString().replace(/,/g, ''));
        // console.log(e.expensedDate);
        // e.expensedDate = moment(e.expensedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
        // if(moment(e.expensedDate).isUTC() || moment.isMoment(e.expensedDate)) {
        // let cleanedDate = e.expensedDate?.toString().replace(/\(.*\)/, '').trim();
        // console.log(cleanedDate);
        if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate) || this.checkDateFormat(e.expensedDate)) {
          e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
        }
        e.expenseAttachments?.forEach(a => {
          if (a.content == null || a.content == "") {
            delete a.content;
          }
        });
        if (this.enableUpdate && (!e.claimId || !e.claimName)) {
          const list = this.toPathInfoData?.expenseList[0] || this.toPathInfoData?.expenseArray[0];
          e.claimId = list?.claimId ? list.claimId : this.toPathInfoData?.detailReport?.claimId;
          e.claimName = this.totalBulkForm?.controls?.claimName?.value;
        }
      })

      bulkApiModel.perdiemRequestDTO?.perDiemExpenseList?.forEach(e => {
        e.baseAmount = parseInt((e.baseAmount || 0).toString().replace(/,/g, ''), 10);
        // e.originalAmount = parseInt((e.originalAmount || 0).toString().replace(/,/g, ''), 10);
        e.originalAmount = parseFloat((e.originalAmount || 0).toString().replace(/,/g, ''));
        console.log(e.expensedDate);
        // e.expensedDate = moment(e.expensedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
        // e.expenseDate = moment(e.expenseDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
        // if(moment(e.expensedDate).isUTC() || moment.isMoment(e.expensedDate)) {
        // let cleanedDate = e.expensedDate?.toString().replace(/\(.*\)/, '').trim();
        if (!moment.utc(e.expensedDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expensedDate) || this.checkDateFormat(e.expensedDate)) {
          e.expensedDate = moment(e.expensedDate).format('DD-MM-YYYY');
        }
        // if(moment(e.expenseDate).isUTC() || moment.isMoment(e.expenseDate)) {
        // cleanedDate = e.expenseDate?.toString().replace(/\(.*\)/, '').trim();
        if (!moment.utc(e.expenseDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(e.expenseDate) || this.checkDateFormat(e.expenseDate)) {
          e.expenseDate = moment(e.expenseDate).format('DD-MM-YYYY');
        }
      })


      if (this.enableUpdate && !bulkApiModel.claimId && (this.navInfoData?.expenseList || this.navInfoData.expenseArray)) {
        const list = this.navInfoData.expenseList[0] || this.navInfoData.expenseArray[0];
        bulkApiModel.claimId = list?.claimId;
        bulkApiModel.claimName = list?.claimName;
      }

      if (bulkApiModel.claimId && this.expenseTaggedTripList) {
        this.expenseTaggedTripList.forEach(expenseTripList => {
          expenseTripList.claimId = bulkApiModel.claimId;
        })
      }
       
      if (!bulkApiModel.claimTaggedTripList && this.expenseTaggedTripList) {
        bulkApiModel.claimTaggedTripList = this.expenseTaggedTripList;
        bulkApiModel.tripTravellers=this.tripTravellers
      }
      bulkApiModel.isLinkedToAdvancePayment = this.navInfoData?.isLinkedToAdvancePayment;
      bulkApiModel.isLinkedToPettyCashPayment = this.navInfoData?.isLinkedToPettyCashPayment;
      if (this.navInfoData?.isLinkedToAdvancePayment) {
        if (this.totalClaimedAmount <= this.walletAdvance) {
          bulkApiModel.advanceAmount = this.totalClaimedAmount;
        } else {
          bulkApiModel.advanceAmount = this.walletAdvance;
        }
      } else {
        bulkApiModel.advanceAmount = 0;
      }

      if (bulkApiModel.isLinkedToAdvancePayment == undefined) {
        bulkApiModel.isLinkedToAdvancePayment = false;
      }
      if (bulkApiModel.isLinkedToPettyCashPayment == undefined) {
        bulkApiModel.isLinkedToPettyCashPayment = false;
      }
      if (this.enableUpdate && (!bulkApiModel?.claimId || !bulkApiModel?.claimName)) {
        const list = this.toPathInfoData?.expenseList[0] || this.toPathInfoData?.expenseArray[0];
        bulkApiModel.claimId = list?.claimId ? list.claimId : this.toPathInfoData?.detailReport?.claimId;
        bulkApiModel.claimName = list?.claimName;
      }
      bulkApiModel.claimName = this.totalBulkForm?.controls?.claimName?.value;
      if(this.navInfoData?.url == 'expensesTrip'){
        bulkApiModel?.claimTaggedTripList?.forEach(element => {
          element.isAssociated =  this.navInfoData?.isAssociated;
        });
      }
      if(bulkApiModel?.claimTaggedTripList?.length > 0){
        bulkApiModel.claimTaggedTripList?.forEach(element => {
          element.invoiceList = [];
        });
        bulkApiModel.billingEntityName = bulkApiModel.claimTaggedTripList[0]?.billingEntityName;
      }
      bulkApiModel.customFields = this.trip_customFields || null;
      bulkApiModel.reasonForTravel = this.trip_reasonForTravel || null;
      bulkApiModel.bookingType = this.trip_bookingType || null;
      bulkApiModel.bookingTypeName = this.trip_bookingTypeName || null;
      bulkApiModel.costCenterCode = this.trip_costCenterCode || null;
      bulkApiModel.budgetRefNumber = this.budgetRefNumber;

      console.log(this.toPathInfoData);
      if(bulkApiModel?.claimTaggedTripList?.length > 0){
        this.toPathInfoData?.tripEstimateDetails?.forEach((element,index) => {
         element.tripId = bulkApiModel?.claimTaggedTripList[0].tripId
         element.claimId = bulkApiModel?.claimTaggedTripList[0]?.claimId
        });

        bulkApiModel.tripEstimateDetails = this.toPathInfoData?.tripEstimateDetails;
      }
      if(bulkApiModel?.customFields?.length == 0) {
        bulkApiModel.customFields = null;
      } else if (typeof bulkApiModel?.customFields != 'string') {
        bulkApiModel.customFields = JSON.stringify(bulkApiModel.customFields || []);
      }

      bulkApiModel.expenseRequestDTOList?.forEach(expense => {
        expense.customFieldList?.forEach(cf_element => {
          if(typeof cf_element?.customFieldValue != 'string') {
            cf_element.customFieldValue = JSON.stringify(cf_element.customFieldValue);
          }
        });
      });

      console.log('4906 _______ ', bulkApiModel);
      this.expenseService.createBulk(bulkApiModel).subscribe((res) => {
          if (!res.isError && res.errorCode == 0) {
            if(!this.allEditExpenses) { this.allEditExpenses = []; }
            // console.log(this.allEditExpenses?.length > 0 && !this.currentEditIndex && this.currentEditIndex != 0);
            if(this.currentEditIndex >= 0 && this.allEditExpenses?.length > 0 && this.allEditExpenses[this.currentEditIndex] && res?.payload?.expenseResponseDTOList[0]?.expenseId) {
              this.allEditExpenses[this.currentEditIndex].expenseId = res?.payload?.expenseResponseDTOList[0]?.expenseId;
              this.allEditExpenses[this.currentEditIndex].expenseGUID = res?.payload?.expenseResponseDTOList[0]?.expenseGUID;
              // this.allEditExpenses[this.currentEditIndex] = res?.payload?.expenseResponseDTOList[0];
              if(res?.payload?.expenseResponseDTOList[0]){
                this.allEditExpenses[this.currentEditIndex] = res?.payload?.expenseResponseDTOList[0];
              }
              res?.payload?.perdiemResponseDTO?.perDiemExpenseList?.forEach(pE => {
                this.allEditExpenses.push(pE);
              })
            } else if(this.allEditExpenses?.length > 0 && !this.currentEditIndex && this.currentEditIndex != 0) {
              // this.allEditExpenses.push(res?.payload?.expenseResponseDTOList[0]);
              if(res?.payload?.expenseResponseDTOList[0]){
                this.allEditExpenses.push(res?.payload?.expenseResponseDTOList[0]);
              }
              res?.payload?.perdiemResponseDTO?.perDiemExpenseList?.forEach(pE => {
                this.allEditExpenses.push(pE);
              })
            }
            console.log(this.allEditExpenses);
            this.tripEstimateDetails = res.payload?.tripEstimateDetails;
            if(this.toPathInfoData?.tripEstimateDetails?.length > 0){
              this.toPathInfoData.tripEstimateDetails = res.payload?.tripEstimateDetails;
            }
            this.expenseTaggedTripList = res.payload.claimTaggedTripList;
            this.totalBulkModel.claimId = res?.payload?.claimId;
            this.trip_customFields = res.payload.customFields || null;
            this.trip_reasonForTravel = res.payload.reasonForTravel || null;
            this.trip_bookingType = res.payload.bookingType || null;
            this.trip_bookingTypeName = res.payload.bookingTypeName || null;
            this.trip_costCenterCode = res.payload.costCenterCode || null;
            sessionStorage.setItem('tripData', JSON.stringify({
              bookingType: this.trip_bookingType,
              bookingTypeName: this.trip_bookingTypeName,
              customFields: this.trip_customFields,
              costCenterCode: this.trip_costCenterCode,
              reasonForTravel: this.trip_reasonForTravel
            }));
            this.displayApiMsgs(3);
            if (this.navInfoData.url == 'expensesTrip') {
              let reportModel = new Report();
              reportModel.isLinkedToAdvancePayment = this.navInfoData.report.isLinkedToAdvancePayment;
              reportModel.isLinkedToPettyCashPayment = this.navInfoData.report.isLinkedToPettyCashPayment;
              reportModel.claimStartDate = res.payload.claimStartDate;
              reportModel.claimEndDate = res.payload.claimEndDate;
              reportModel.claimName = res.payload.claimName;
              reportModel.claimId = res.payload.claimId;
              reportModel.claimGUID = res.payload.claimGUID;
              reportModel.claimTypeName = res.payload.claimTypeName || null;
              reportModel.claimTypeId = res.payload.claimTypeId || null;
              reportModel.claimCategoryName = res.payload.claimCategoryName || null;
              reportModel.claimCategoryId = res.payload.claimCategoryId || null;
              let expenseList = [];

              res.payload.expenseResponseDTOList.forEach(element => {
                element.isSelected = true;
                expenseList.push(element);
              });

              reportModel.claimStartTime = res.payload.claimStartTime || null
              reportModel.claimEndTime = res.payload.claimEndTime || null

              let request_payload = {
                url: 'newreport',
                appliedAmount: 0,
                editAppliedAmt: 0,
                isAdvLinkedBefore: false,
                walletAdvance: 0,
                report: reportModel,
                reporttitle: true,
                claimTaggedTripList: res.payload.claimTaggedTripList,
                tripTravellers:res.payload.tripTravellers,
                expenseList: res.payload.expenseResponseDTOList,
                claimStartDate: res.payload.claimStartDate,
                claimEndDate: res.payload.claimEndDate,
                isLinkedToAdvancePayment: this.navInfoData?.isLinkedToAdvancePayment,
                isLinkedToPettyCashPayment: this.navInfoData?.isLinkedToPettyCashPayment,
                // advanceAmount:this.advanceAmount
              }

              console.log('request_payload _____ ', request_payload);

              this.fromPathInfo = request_payload;

              this.onSaveClick(res.payload);

            } else {
              let reportModel = new Report();
              reportModel.isLinkedToAdvancePayment = this.navInfoData?.report?.isLinkedToAdvancePayment;
              reportModel.isLinkedToPettyCashPayment = this.navInfoData?.report?.isLinkedToPettyCashPayment;
              reportModel.claimStartTime = this.totalBulkForm.controls.claimStartTime.value;
              reportModel.claimEndTime = this.totalBulkForm.controls.claimEndTime.value;

              // if(this.totalClaimedAmount <= this.advanceAmount){
              //   console.log("totalClaimedAmount")
              //   reportModel.advanceAmount=this.totalClaimedAmount;
              // }else{
              //   reportModel.advanceAmount=this.advanceAmount;
              //   console.log("advance")
              // }
              reportModel.claimName = res.payload.claimName;
              reportModel.claimId = res.payload.claimId;
              reportModel.claimGUID = res.payload.claimGUID;
              reportModel.claimStartDate = res.payload.claimStartDate,
              reportModel.claimEndDate = res.payload.claimEndDate;
              reportModel.companyPaid = res.payload.companyPaid || 0;
              reportModel.employeePaid = res.payload.employeePaid || 0;
              reportModel.claimTypeName = res.payload.claimTypeName || null;
              reportModel.claimTypeId = res.payload.claimTypeId || null;
              reportModel.claimTotal = res.payload.claimTotal || 0;
              reportModel.claimCategoryName = res.payload.claimCategoryName || null;
              reportModel.claimCategoryId = res.payload.claimCategoryId || null;

              this.fromPathInfo.claimTaggedTripList = [];
              res.payload.claimTaggedTripList?.forEach(element => {
                this.fromPathInfo?.claimTaggedTripList?.push(element);
              });
              res.payload.tripTravellers?.forEach(element => {
                this.fromPathInfo?.tripTravellers?.push(element);
              });
            


              // if (!this.editlist) {
              //   if (res.payload?.perdiemResponseDTO?.perDiemExpenseList) {
              //     res.payload?.perdiemResponseDTO?.perDiemExpenseList.forEach(element => {
              //       element.isSelected = true;
              //       this.fromPathInfo.expenseList.push(element);
              //     });
              //   }
              // }

              this.fromPathInfo.report = reportModel,
                this.onSaveClick(res.payload);
              // if (!this.editlist) {
              //   this.sharedService.setExpenseData(res.payload);
              // }
            }

          } else if (res.isError) {
            this.toastr.error(
              res.errorMessage,
              this.translate.instant("alerts.error", { timeOut: 3000 })
            );
          }
          this.buttonDisabled = false;
          this.submitExpense = false;
        });
    }


  }

  getBulkFormOCR() {
    console.log('ocr pop up :');
    this.uploadedfiles.forEach((element) => {
      let request = {
        fileName: element.fileName,
        content: element.content,
        url: "",
        isDeleted: "",
      };
      this.expenseModel.expenseAttachments.push(request);
    });
    // let arr = this.expenseModel.expenseAttachments;
    // this.expenseModel.expenseAttachments = arr.map(item => new ExpenseAttachments(item));

    let ocrAttch = new bulkOCRAttachment();
    let res =
      this.expenseModel.expenseAttachments[
      this.expenseModel.expenseAttachments.length - 1
      ];
    ocrAttch.base64Image = res.content;
    ocrAttch.fileName = res.fileName;
    ocrAttch.uploadType = "extract-csv";
    this.expenseService.ocrDataPulling(ocrAttch).subscribe((res) => {
      this.totalBulkModel.isCSVFile = false;
      this.bulkUploadRejectStatus = false;

      if (res?.payload?.extractedBulkData) {
        this.uploadedfiles = [];
        // this.bulkFormValidations(0);
        this.totalBulkForm.value.bulkItems.forEach((element, index) => {
          if (element.categoryName == undefined) {
            this.removeBulkItems('Expense', index);
            this.bulkAlreadyRemoved = true;
          }
        });
        this.totalBulkModel.csvFileName = ocrAttch.fileName;
        res.payload?.extractedBulkData.forEach((item) => {
          let bulkItem = new BulkItemModel();
          bulkItem.categoryName = item.categoryName;
          bulkItem.merchantName = item.merchantName;
          bulkItem.merchantCode = item.merchantCode;
          bulkItem.taxAmount = item.taxAmount;
          bulkItem.taxPercentage = item.taxPercentage;
          bulkItem.taxNumber = item.taxNumber

          const category = _.find(this.categoryList, data => { return data.categoryName == item.categoryName });
          if (category) {
            bulkItem.categoryType = category.categoryType;
            bulkItem.categoryId = category.categoryId;
          } else {
            bulkItem.categoryName = "";
            bulkItem.merchantName = item.merchantName;
            bulkItem.categoryType = '';
            bulkItem.categoryId = '';
          }

          if (item.expensedDate) {
            bulkItem.expensedDate = this.givenDateIsExistorNot(item.expensedDate);
          } else {
            bulkItem.expensedDate = '';
          }
          bulkItem.description = item.description;
          bulkItem.invoiceNumber = item.invoiceNumber;
          bulkItem.taxNumber = item.taxNumber;
          bulkItem.location = item.spentLocation;

          const currency = _.find(this.currencyList, {
            currencyCode: item.currencyCode,
          });
          if (currency) {
            bulkItem.originalCurrencyId = currency.currencyId;
            bulkItem.expConvRate = currency.conversionRate;
            bulkItem.originalCurrencyIcon = currency.currencyIcon;
          }
          bulkItem.currencyCode = this.userInfo.baseCurrency.currencyCode;
          bulkItem.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
          bulkItem.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
          bulkItem.expConvRate = 1;

          bulkItem.originalAmount = Number(item.expenseAmount);
          console.log('after ocr reading :', bulkItem);
          this.addBulkItem(bulkItem);
          //VIOLATION VALIDATION CHEC
          if (bulkItem.categoryId != '') {
            // this.policyCatCountValidation(this.totalBulkModel.expenseRequestDTOList.length - 1);
            // this.policyCatCommonMethod(this.totalBulkModel.expenseRequestDTOList.length - 1, this.totalBulkModel.expenseRequestDTOList[this.totalBulkModel.expenseRequestDTOList.length - 1].categoryId);
          }

          // if (this.totalBulkModel.expenseRequestDTOList.length != 0) {
          //   this.totalBulkModel.expenseRequestDTOList.forEach((element, index) => {
          //     if (element.categoryId != '') {
          //       this.policyCatCountValidation(index);
          //       this.policyCatCommonMethod(index,element.categoryId);
          //     }
          //   });
          // }
        });
      } else {
        this.totalBulkModel.csvFileName = "Upload via .xls";
        this.buttonDisabled = false;
        this.totalBulkModel.isCSVFile = false;
        this.uploadedfiles = [];
        this.bulkUploadRejectStatus = true;
        if (res.errorCode != 0) {
          this.toastr.error(
            res.errorMessage,
            this.translate.instant("alerts.error", { timeOut: 3000 })
          );
          this.uploadedfiles = [];
        } else {
          this.toastr.error("Error", this.translate.instant("alerts.error", { timeOut: 3000 }));
          this.uploadedfiles = [];
        }
        // this.bulkFormValidations(5);
      }
    });
  }

  getexpenseDetails() {
    this.getExpenseModels.expenseId = this.expenseId;
    this.getExpenseModels.expenseGUID = this.expenseGUID;
    this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
    this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.expenseService.getExpense(this.getExpenseModels).subscribe((res) => {
      if (!res.isError && res.errorCode == 0) {

        // if (this.hsnCodeList) {
        //   let Dvalue = this.hsnCodeList.find(hscode => hscode.isDefault == true);
        //   if (Dvalue) {
        //     if (res.payload.hsnId == null || res.payload.hsnId == '') {
        //       res.payload.hsnId = Dvalue.hsnId;
        //       res.payload.hsnCode = Dvalue.hsnCode;
        //       res.payload.hsnName = Dvalue.hsnName;
        //     }
        //   }
        // }
        // if (this.taxCodeList) {
        //   let Dvalue = this.taxCodeList.find(code => code.isDefault == true);
        //   if (Dvalue) {
        //     if (res.payload.taxCodeId == null || res.payload.taxCodeId == '') {
        //       res.payload.taxCodeId = Dvalue.taxCodeId;
        //       res.payload.taxCode = Dvalue.taxCode;
        //       res.payload.taxName = Dvalue.taxName;
        //     }
        //   }
        // }
        this.getExpenseDetails = res.payload;

        // this.checklist = new CheckPointsList();
        // if (this.getExpenseDetails.checkPointsList) {
        //   this.getExpenseDetails.checkPointsList.forEach(element => {
        //     if (element) {
        //       this.checklist.checkpoint.push(element);
        //     }
        //   });
        // }
        // if (res.payload.checkPointsList) {
        //   this.isCheckPoints = true;
        // } else {
        //   this.isCheckPoints = false;
        // }
        const listarray = res.payload;
        this.editlist = new ExpenseListModel(listarray);

        // if (this.approvedExpenseList) {
        //   this.approvedExpenseList.forEach(element => {
        //     if (element.status == false && element.expenseId == this.editlist.expenseId) {
        //       this.editlist.unformattedOriginalApproveAmount = element.unformattedOriginalApproveAmount;
        //       element.status = true;
        //     }
        //   });
        // }
        // this.selectedUserList = [];
        // this.hasExpenseSplit = this.editlist.hasExpenseSplit;
        // this.editlist.originalAmount = this.editlist?.unformattedOriginalAmount;
        // this.editlist.baseAmount = this.editlist?.unformattedBaseAmount;
        // if (this.editlist?.expenseUserSplitList) {
        //   this.secondTabList = [];
        //   this.thirdTabList = [];
        //   if (this.editlist?.splitType == 1) {
        //     this.editlist?.expenseUserSplitList.forEach((elm) => {
        //       const userData = new SplitExpenseListModel(elm);
        //       this.selectedUserList.push(userData);
        //     });
        //     this.onTabChanged(0);
        //   } else if (this.editlist?.splitType == 2) {
        //     this.editlist?.expenseUserSplitList.forEach((elm) => {
        //       const userData = new SplitExpenseListModel(elm);
        //       this.secondTabList.push(userData);
        //     });
        //     this.onTabChanged(1);

        //     this.editlist?.expenseUserSplitList.forEach((elm) => {
        //       elm.amount = 0;
        //       const userData = new SplitExpenseListModel(elm);
        //       this.selectedUserList.push(userData);
        //     });
        //     let divAmt =
        //       (this.editlist.originalAmount
        //         ? this.editlist.originalAmount
        //         : 0) / this.selectedUserList.length;
        //     this.editlist?.expenseUserSplitList.forEach((elm) => {
        //       elm.amount = divAmt;
        //     });
        //   } else if (this.editlist?.splitType == 3) {
        //     this.editlist?.expenseUserSplitList.forEach((elm) => {
        //       const userData = new SplitExpenseListModel(elm);
        //       this.thirdTabList.push(userData);
        //     });
        //     this.onTabChanged(2);
        //     this.editlist?.expenseUserSplitList.forEach((elm) => {
        //       elm.amount = 0;
        //       const userData = new SplitExpenseListModel(elm);
        //       this.selectedUserList.push(userData);
        //     });
        //     let divAmt =
        //       (this.editlist.originalAmount
        //         ? this.editlist.originalAmount
        //         : 0) / this.selectedUserList.length;
        //     this.editlist?.expenseUserSplitList.forEach((elm) => {
        //       elm.amount = divAmt;
        //     });
        //   }
        // } else {
        //   this.addCorporateUser({
        //     emailId: this.userInfo.email,
        //     userId: this.userInfo.userId,
        //     userLogo: this.userInfo.profilePicPath,
        //     userName: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
        //   });
        // }
        // //check why below code is not working
        // if (this.editlist?.violationList) {
        //   this.editlist?.violationList.forEach((item, i) => {
        //     if (i < 4) {
        //       this.displayViolations.push(item);
        //     }
        //   });
        // }
        // if (this.editlist.categoryType == null) {
        //   this.editlist.categoryType = 1;
        // }
        if (this.editlist.recordType == 4) {
          // this.active = "top";
          this.navChanged(3); // TODO check for other flows
        } else if (this.editlist.categoryType == 1 && this.editlist.expenseType == 5) {
          this.showMileageBtn = false;
          this.showBulkBtn = false;
          this.showperdiemBtn = true;
          this._perdiemEditForm = true;
          this.navChanged(2);
          this._expenseTypeVerification = 5;
        } else if (this.editlist.categoryType == 1 && this.editlist.expenseType == 1) {

          this.navChanged(0);
          this.expenseModel.categoryId = this.editlist.categoryId;
          this.expenseModel.categoryName = this.editlist.categoryName;


        } else if (this.editlist.categoryType == 2 && this.editlist.expenseType == 1) {
          this.showMileageBtn = true;
          this.showBulkBtn = false;
          this.showperdiemBtn = false;
          this.showAdditemsBtn = false;
          this.expenseTabIndex = 1;
          this.navChanged(1);
          let newModel = new MileageBulkItemModel();
          newModel.categoryId = this.editlist.categoryId;
          newModel.categoryName = this.editlist.categoryName;
          newModel.categoryType = this.editlist.categoryType;
          newModel.expensedDate = this.editlist.expensedDate;
          newModel.distance = this.editlist.distance;
          newModel.mileageRate = this.editlist.mileageRate;
          newModel.description = this.editlist.description;
          newModel.mileageUnit = this.editlist.mileageUnit;
          newModel.originalAmount = this.editlist.originalAmount;
          newModel.originalCurrencyCode = this.editlist.originalCurrencyCode;
          newModel.originalCurrencyIcon = this.editlist.originalCurrencyIcon;
          newModel.originalCurrencyId = this.editlist.originalCurrencyId;
          newModel.location = this.editlist.location;
          newModel.fromLocationName = this.editlist.fromLocationName;
          newModel.toLocationName = this.editlist.toLocationName;
          newModel.taxCode = this.editlist.taxCode;
          newModel.financeIntegrationCode = this.editlist.financeIntegrationCode;
          newModel.financeIntegrationId = this.editlist.financeIntegrationId;
          newModel.hsnId = this.editlist.hsnId;
          newModel.taxCodeId = this.editlist.taxCodeId;
          newModel.hsnCode = this.editlist.hsnCode;
          newModel.status = this.editlist.status;
          newModel.expenseId = this.editlist.expenseId;
          newModel.expenseGUID = this.editlist.expenseGUID;
          newModel.recordType = this.editlist.recordType;
          newModel.status = this.editlist.status;
          newModel.expenseAttachments = this.editlist.expenseAttachments;
          newModel.approvedDistance = this.editlist.approvedDistance;
          newModel.showOptions = (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false;


          const stateIndex = this.stateList.findIndex(s => s.stateName == this.editlist.location)
          if (stateIndex >= 0) {
            this.getStatesCall({ stateId: this.stateList[stateIndex].stateId, countryId: this.stateList[stateIndex].countryId }, 0)
          }

          if (this.editlist.checkPointsList) {
            let internal_array = [];
            this.editlist.checkPointsList.forEach(element => {
              internal_array.push(new checkpointCon(element));
            });
            newModel.checkPointsList = internal_array;
          }
          console.log('before adding :', newModel);
          this.addMileageBulkItem(newModel);

          this.totalMileageBulkModel.expenseRequestDTOList.splice(0, 1);
          this.MileagebulkItemsForms.removeAt(0);

        } else if (this.editlist.categoryType == 3) {
          this.navChanged(2);
          this.showMileageBtn = false;
          this.showBulkBtn = false;
          this.showperdiemBtn = true;
          this._perdiemEditForm = false;
        } else {
        }

        this.showText = this.editlist.expenseType == 2 ? true : false;
        this.expenseModel.expenseType = this.editlist.expenseType;
        if (this.showText) {
          this.expenseItemsArray.controls = [];
          this.editlist.expenseItems.forEach((element) => {
            element.amount = element.unformattedAmount;
            this.addExpenseItems(element);
          });

          this.sumOfamount = listarray.expenseItems.forEach((data) => {
            this.total += data?.unformattedAmount;
          });
          this.expConvRate = this.editlist.exchangeRate;
          this.exchangeAmt = this.editlist?.unformattedBaseAmount; //.baseAmount;
        }

        this.expcurrencyIcon = this.editlist.originalCurrencyIcon;
        if (this.editlist.categoryType == 1 && this.editlist.expenseType == 5) {
          this._perdiemEditForm = true;
          this.perdiemModel.PDlocation = this.getExpenseDetails.locationName;
          this.perdiemModel.PDlocationId = this.getExpenseDetails.location;
          this.perdiemForm.controls.PDclaimName.patchValue(this.getExpenseDetails.claimName);
          this.perdiemForm.controls.PDclaimId.patchValue(this.getExpenseDetails.claimId);

          console.log(this.getExpenseDetails);
          this.perdiemModel.PDradioSelectionName = this.getExpenseDetails.perDiemTypeName;
          this.perdiemForm.controls.PDstartDate.patchValue(this.convertDateToUtc(this.getExpenseDetails.startDate));
          this.perdiemForm.controls.PDendDate.patchValue(this.convertDateToUtc(this.getExpenseDetails.endDate));
          this.perdiemModel.PDstartTime = this.getExpenseDetails.startTime;
          this.perdiemModel.PDendTime = this.getExpenseDetails.endTime;
          this.perdiemselection({
            name: this.getExpenseDetails.perDiemTypeName,
            id: this.getExpenseDetails.perDiemType
          })

          this.sampleDt = [{
            expenseDate: this.getExpenseDetails.expensedDate,
            description: this.getExpenseDetails.description,
            originalAmount: this.getExpenseDetails.originalAmount,
            expenseAttachments: this.getExpenseDetails.expenseAttachments || [],
            duration: this.getExpenseDetails.perDiemType == 3 ?
              `${this.getExpenseDetails.startTime} - ${this.getExpenseDetails.endTime}` : this.getExpenseDetails.perDiemTypeName,
            perDiemRate: this.getExpenseDetails.perDiemRate,
            perDiemType: this.getExpenseDetails.perDiemType,
            startDate: this.getExpenseDetails.startDate,
            startTime: this.getExpenseDetails.startTime,
            endDate: this.getExpenseDetails.endDate,
            endTime: this.getExpenseDetails.endTime,
            categoryId: 1,
            expenseType: 5,
            recordType: 1,
            exchangeRate: 1,
            expenseId: this.editlist ? this.getExpenseDetails.expenseId : null,
            expenseGUID: this.editlist ? this.getExpenseDetails.expenseGUID : null,
            financeIntegrationCode: this.editlist ? this.getExpenseDetails.financeIntegrationCode : null,
            financeIntegrationId: this.editlist ? this.getExpenseDetails.financeIntegrationId : null,
            financeIntegrationName: this.editlist ? this.getExpenseDetails.financeIntegrationName : null,
            taxCode: this.editlist ? this.getExpenseDetails.taxCode : null,
            hsnCode: this.editlist ? this.getExpenseDetails.hsnCode : null,
            taxCodeId: this.editlist ? this.getExpenseDetails.taxCodeId : null,
            hsnId: this.editlist ? this.getExpenseDetails.hsnId : null
          }]

          this.perdiemModel.PDhsnCode = this.editlist ? this.getExpenseDetails.hsnCode : null
          this.perdiemModel.PDtaxNumner = this.editlist ? this.getExpenseDetails.financeIntegrationCode : null
          this.perdiemModel.PDtaxPercentage = this.editlist ? this.getExpenseDetails.taxCode : null


          // console.log('perdiem form rajes :', this.perdiemForm);
          if (!this.policyList) {
            this.policyList = JSON.parse(sessionStorage.getItem("policylist"));
          }
          if (this.policyPerDiemList) {
            this.policyPerDiemList.forEach((element) => {
              if (element.cityId == this.perdiemModel.PDlocationId) {
                this.selectedPolicyPerdiem = element;
                element.isAllowedWithReceipt == true
                  ? this.addPerdiemRateList("Rate with Receipt")
                  : "";
                element.isAllowedWithoutReceipt == true
                  ? this.addPerdiemRateList("Rate without Receipt")
                  : "";
              }
            });
            this.perdiemModel.PDcategory = this.getExpenseDetails.categoryName;
            this.perdiemModel.PDcategoryId = this.getExpenseDetails.categoryId;
            this.perdiemModel.PDrate = this.getExpenseDetails.perDiemRateName;
            this.perdiemModel.PDtype = this.getExpenseDetails.perDiemTypeName;
            this.perdiemModel.PDdescription =
              this.getExpenseDetails.description;
            this.perdiemModel.PDamount =
              this.getExpenseDetails.unformattedOriginalAmount;
            this.perdiemModel.PDoriginalCurrency =
              this.getExpenseDetails.originalCurrencyCode;
            this.perdiemModel.PDoriginalCurrencyId =
              this.getExpenseDetails.originalCurrencyId;
            this.perdiemModel.PDoriginalCurrencyIcon =
              this.getExpenseDetails.originalCurrencyIcon;
            this.perdiemModel.PDexpenseDate =
              this.getExpenseDetails.expensedDate;
            this.perdiemForm.controls["PDexpenseDate"].setValue(
              this.convertDateToUtc(this.getExpenseDetails.expensedDate)
            );
            this.perdiemModel.PDtaxNumner = this.getExpenseDetails.taxNumber;
            this.perdiemModel.PDtaxAmount = this.getExpenseDetails.taxAmount;
            this.perdiemModel.PDtax = this.getExpenseDetails.taxPercentage;
            this.perdiemModel.PDspentLocation =
              this.getExpenseDetails.locationName;
            this.perdiemModel.PDspentLocationId =
              this.getExpenseDetails.locationId;
            this.perdiemModel.expenseAttachments = this.getExpenseDetails
              .expenseAttachments
              ? this.getExpenseDetails.expenseAttachments
              : [];

            let attchmentsA = [];
            attchmentsA.push(res.payload.expenseId);
            this.getExpenseAttachments(attchmentsA);
          }

        } else if (this.editlist.categoryType == 1 || this.editlist.categoryType == 3) {
          if (this.platformType == 2) {
            this.patchToExpense()
          } else if (this.userInfo.allowItemizedExpenses) {
            this.expenseForm.patchValue(this.editlist);
            this.expenseForm.controls.expensedDate.patchValue(this.convertDateToUtc(this.editlist.expensedDate));
            if (this.editlist.financeIntegrationCode) {
              let ind = this.financeIntCodeList.findIndex((ele) => ele.financeIntegrationId == this.editlist.financeIntegrationId);
              if (ind != -1) {
                this.expenseForm.controls.financeIntegrationCode.patchValue(this.financeIntCodeList[ind].financeCodeName);
              }
            }
            this.expenseForm.get("originalCurrencyCode").patchValue(this.editlist.originalCurrencyCode);
            // this.expenseForm.controls.originalCurrencyCode.patchValue(this.editlist.originalCurrencyCode);
            let originalAmount = this.editlist.originalCurrencyId;
            let currencyDetails = _.find(this.currencyList, function (object) {
              return object.currencyId == originalAmount;
            });
            this.expcurrencySymbol = this.editlist.originalCurrencyCode;
            this.expConvRate = currencyDetails ? currencyDetails.conversionRate : '';
          } else {
            let newModel = new BulkItemModel();
            newModel.categoryName = this.editlist.categoryName;
            newModel.categoryId = this.editlist.categoryId;
            newModel.categoryType = this.editlist.categoryType;
            newModel.merchantCode = this.editlist.merchantCode;
            newModel.merchantName = this.editlist.merchantName;
            newModel.expensedDate = this.editlist.expensedDate;
            newModel.originalAmount = this.editlist.baseAmount;
            newModel.description = this.editlist.description;
            newModel.currencyCode = this.userInfo.baseCurrency.currencyCode;
            newModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
            newModel.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
            newModel.showOptions = (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false;
            newModel.expConvRate = 1;
            newModel.exchangeRate = 1;
            newModel.expenseId = this.editlist.expenseId;
            newModel.expenseGUID = this.editlist.expenseGUID;
            newModel.recordType = this.editlist.recordType;
            newModel.status = this.editlist.status;
            newModel.financeIntegrationCode = this.editlist.financeIntegrationCode ? this.editlist.financeIntegrationCode : '';
            newModel.financeIntegrationId = this.editlist.financeIntegrationId ? this.editlist.financeIntegrationId : '';
            newModel.taxCode = this.editlist.taxCode ? this.editlist.taxCode : '';
            newModel.taxCodeId = this.editlist.taxCodeId ? this.editlist.taxCodeId : '';
            newModel.hsnCode = this.editlist.hsnCode ? this.editlist.hsnCode : '';
            newModel.hsnCodeId = this.editlist.hsnId ? this.editlist.hsnId : '';
            newModel.expenseAttachments = this.editlist.expenseAttachments || [];
            newModel.invoiceNumber = this.editlist.invoiceNumber || '';
            newModel.location = this.editlist.location || '';
            newModel.taxNumber = this.editlist.taxNumber || '';
            newModel.taxAmount = this.editlist.taxAmount || '';
            newModel.taxPercentage = this.editlist.taxPercentage || '';
            // newModel.userlists = this.editlist.expenseUserSplitList || [];
            let usersList = [];
            this.editlist.expenseUserSplitList?.forEach(e => {
              usersList.push(e.userName)
            })
            newModel.userlists = usersList;
            this.userlists = usersList;

            this.totalBulkModel.expenseRequestDTOList.push(newModel);
            this.bulkItemsForms.push(this.addBulkFormGroup(newModel));
            this.removeBulkItems('Expense', 0);
          }
        } else {
          if (this.platformType == 2) {
            this.patchToExpense()
          }
          // this.mileageForm.controls.expensedDate.patchValue(this.convertDateToUtc(this.editlist.expensedDate));
          // this.mileageForm.controls.fromLocationName.patchValue(this.editlist.fromLocationName);
          // this.mileageForm.controls.toLocationName.patchValue(this.editlist.toLocationName);

          // this.milecurrencySymbol = this.editlist.originalCurrencyIcon;
          // this.mileageRate = this.editlist.mileageRate && this.editlist.mileageUnit ? this.editlist.mileageRate +
          //     " " +
          //     this.editlist.mileageRateCurrencyCode +
          //     "/" +
          //     this.editlist.mileageUnit
          //     : "";
          // this.milRate = this.editlist.mileageRate;
        }
        this.expenseModel = this.editlist;
        this.editlist.expenseType == 5 ? this.expenseModel.location = this.editlist.locationName : '';
        if (this.duplicate) {
          this.expenseModel.claimCode = null;
          this.expenseModel.claimId = null;
          this.expenseModel.claimName = "";
          this.expenseModel.expenseId = null;
          this.merchantListForDuplicate();
        }
        this.editlist.expenseAttachmentsmodel?.forEach((element) => {
          this.imgContent = element.url;
          this.caputredImageModal.capturedImages.push(element.url);
        });
        console.log('4062 _________ ');
        console.log(listarray?.expenseAttachments?.length > 0, listarray);
        console.log(listarray.expenseAttachments);

        if (listarray?.expenseAttachments?.length > 0) {
          this.expenseModel.expenseAttachments = listarray.expenseAttachments;
        } else {
          this.expenseModel.expenseAttachments = [];
        }

        if (this.showHsnBtns && this.editlist.status == 1 || this.editlist.status == 2 || this.editlist.status == 3) {
          this.isReadOnly = true;
        }

        if (res.payload?.isAssociated) {
          this.imgContent = "";
          this.isAssociated = true;
          this.expenseArray = this.editlist.expenseTaggedTripList;
          this.expenseTaggedTripList = this.editlist.expenseTaggedTripList;
          this.expenseModel.isAssociated = this.isAssociated;
          res.payload.expenseAttachments?.forEach((element) => {
            if (element.attachmentType == "1") {
              this.associatedAttchements.push(element);
            }
          });
        }
        this.expenseModel.merchantCode = res.payload?.merchantCode;
        this.expenseForm.controls.expensedDate.patchValue(this.convertDateToUtc(this.editlist.expensedDate));
        this.expenseForm.controls.expenseType.patchValue(this.editlist.expenseType);
        this.expenseForm.controls.claimName.patchValue(this.editlist.claimName);
        this.mileageForm.controls.claimName.patchValue(this.editlist.claimName);
        this.expenseForm.controls.categoryName.patchValue(this.editlist.categoryName);

        // if(this.showHsnBtns && this.editlist.unformattedOriginalApproveAmount != null){
        //   if(this.editlist.unformattedOriginalApproveAmount){
        //      this.expenseModel.taxAmount = (this.editlist.unformattedOriginalApproveAmount/100)*this.editlist.taxPercentage;
        //      this.expenseForm.controls.taxAmount.patchValue((this.editlist.unformattedOriginalApproveAmount/100)*this.expenseModel.taxAmount);
        //   }
        // financeIntCodeList }
        let attchmentsA = [];
        attchmentsA.push(res.payload?.expenseId);

        if (this.editlist.categoryType == 1 && this.editlist.expenseType == 1) {
          if (this.userInfo.isCategoryBasedEnabled && this.expenseModel.expensedDate != null && this.expenseModel.categoryId != null && this.expenseModel.originalAmount != null) {
            this.getCatPolicy(this.expenseModel.categoryId, this.expenseModel.expensedDate, 0, 'count', 'bulk');
          } else if (!this.userInfo.isCategoryBasedEnabled && this.expenseModel.expensedDate != null && this.expenseModel.categoryId != null && this.expenseModel.originalAmount != null) {
            this.getPolicyApi(this.expenseModel.categoryId, this.expenseModel.expensedDate, 0, 'count', 'bulk');
          }
        }
        this.getExpenseAttachments(attchmentsA);

      } else if (res.isError && res.errorCode == 2) {
        this.toastr.error(
          res.errorMessage,
          this.translate.instant("alerts.error", { timeOut: 3000 })
        );
      }
    });
  }

  patchToExpense() {
    if (this.editlist.categoryType == 1 || this.editlist.categoryType == 3) {
      this.expenseForm.patchValue(this.editlist);
      this.expenseForm.controls.categoryName.patchValue(
        this.editlist.categoryName
      );
      this.expenseForm.controls.merchantName.patchValue(
        this.editlist.merchantName
      );
      this.expenseForm.controls.originalCurrencyCode.patchValue(
        this.editlist.originalCurrencyCode
      );
      this.expenseForm.controls.claimName.patchValue(this.editlist.claimName);

      this.expenseModel.categoryId = this.editlist.categoryId;
      this.expenseModel.categoryName = this.editlist.categoryName;
      this.expenseModel.merchantName = this.editlist.merchantName;
      this.expenseModel.originalCurrencyId = this.editlist.originalCurrencyId;
      this.expenseModel.originalCurrencyName =
        this.editlist.originalCurrencyCode;
      this.expenseModel.claimId = this.editlist.claimId;
      this.expenseModel.claimName = this.editlist.claimName;
      this.merchant = undefined;
    } else {
      this.mileageForm.patchValue(this.editlist);
      this.mileageForm.controls.categoryName.patchValue(
        this.editlist.categoryName
      );
      this.mileageForm.controls.originalCurrencyCode.patchValue(
        this.editlist.originalCurrencyCode
      );
      this.mileageForm.controls.claimName.patchValue(this.editlist.claimName);

      this.expenseModel.categoryId = this.editlist.categoryId;
      this.expenseModel.categoryName = this.editlist.categoryName;
      this.expenseModel.merchantName = this.editlist.merchantName;
      this.expenseModel.originalCurrencyId = this.editlist.originalCurrencyId;
      this.expenseModel.originalCurrencyName =
        this.editlist.originalCurrencyCode;
      this.expenseModel.claimId = this.editlist.claimId;
      this.expenseModel.claimName = this.editlist.claimName;
    }
  }

  async openDateSheet(format?: string) {
    const modal = await this.modalController.create({
      component: CustomDatePickerComponent,
      componentProps: {
        selectedDate: this.selectedDate ? this.selectedDate : new Date(),
        fromDate: new Date("1981-01-01"),
        toDate: new Date(),
      },
      cssClass: "custom-bottom-sheet",
    });
    modal.onDidDismiss().then((result) => {
      if (result.role === "changed" && result.data) {
        this.selectedDate = result.data.displayDate;
        this.expenseModel.expensedDate = result.data.formattedDate;
      }
    });
    return await modal.present();
  }
  getDateCall(ev) {
    this.selectedDate = moment(ev.detail.value).format("MM-DD-YYYY");
    this.expenseModel.expensedDate = moment(ev.detail.value).format(
      "DD-MM-YYYY"
    );
  }

  convertDate(date) {
    const dateParts = date.split("-");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }

  convertDateToUtc(date) {
    this.selectedDate = moment(moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"))
      .utc(true)
      .toISOString(false);
    return this.selectedDate;
  }

  redirectToDashboard = false;
  oncancelClick() {
    this.modalController?.dismiss();
    this.modalRef?.hide();
    this.backClick = true;
    if (this.fromPathInfo && this.fromPathInfo['url'] == 'expensesTrip') {
      this.fromPathInfo['url'] = 'expenses';
    }
    if (this.navInfoData?.from && (this.navInfoData?.from == 'gpsTracking' || this.navInfoData?.from == 'camera')) {
      this.redirectToDashboard = true;
    }
    this.backToOriginalPage(null, true);
    this.proofofPaymentPreview = false;
  }

  async onSaveClick(data) {
    console.log(data);

    //check this -- not necessasry for dashboard, expenses -- new expense or edit expense
    //check for reports?
    // if (!this.editlist) {
    //   this.save.emit(data);
    // }
    if (this.platformType == 2) {
      this.modalController.dismiss(data);
    }
    if (this.platformType == 2) {
      //GPS tracking data remove......
      if (this.type == 2) {
        localStorage.removeItem("toLat");
        localStorage.removeItem("toLong");
        localStorage.removeItem("fromLat");
        localStorage.removeItem("fromLong");
        localStorage.removeItem("gpsDistance");
        localStorage.removeItem("startTime");
      }

      this.modalController.dismiss({
        dismissed: true,
      });
      this.navControlr.navigateRoot(['dashboard']);
      this.sharedService.setRefreshData(true);
    }
    this.backToOriginalPage(data);
  }

  backToOriginalPage(data?, clickedCancel?) {
    console.log('6400 ',this.fromPathInfo);
    if(!clickedCancel) {
      this.fromPathInfo['refresh_claimData'] = true;
    }
    
    if (this.fromPathInfo?.url && this.fromPathInfo["url"] == "petty-cash/new-report") {
      this.navControlr.navigateRoot([this.fromPathInfo["url"]]);
    }
    if (this.fromPathInfo && this.fromPathInfo["url"]) {
      //|| this.fromPathInfo["url"] == "expensesTrip"//
      if ((this.fromPathInfo["url"] == "expenses" || this.fromPathInfo["url"] == "petty-cash/expense") && !this.duplicate) {
        var status = -1;
        if (this.fromPathInfo["status"]) {
          status = this.fromPathInfo["status"];
        }
        let navInfo = {
          expenseId: this.expenseId ? this.expenseId : null,
          expenseGUID: this.expenseGUID ? this.expenseGUID : null,
          status: status,
        };
        sessionStorage.setItem("fromPathInfo", JSON.stringify(navInfo));
      }
      if (this.navInfoData?.from && this.navInfoData?.from == 'camera') {
        this.fromPathInfo["url"] = 'newreport';
        this.fromPathInfo.expenseList = [];
      }
      if (this.fromPathInfo["url"] == "newreport") {
        let checkingvariable = false;
        let selectedIndexV = 0;
        if (data) {
          if(!this.fromPathInfo['report']?.claimId || !this.fromPathInfo['report']?.claimGUID) {
            this.fromPathInfo['report'].claimId = data.claimId;
            this.fromPathInfo['report'].claimGUID = data.claimGUID;
          }
          if (!this.userInfo.allowItemizedExpenses) {
            this.fromPathInfo.report['claimName'] = data.claimName;
          }
          console.log(this.editlist);

          if (this.navInfoData.formClaimEdit) {
            this.fromPathInfo.expenseList = [];
          }
          data.expenseResponseDTOList.forEach((element, index) => {
            element.isSelected = true;
            const expIndex = this.fromPathInfo.expenseList?.findIndex(e => e.expenseId == element.expenseId);
            if (expIndex < 0) {
              this.fromPathInfo.expenseList.push(element);
            }
          });


          if (data.perdiemResponseDTO?.perDiemExpenseList) {
            data.perdiemResponseDTO?.perDiemExpenseList.forEach(element => {
              element.isSelected = true;
              this.fromPathInfo.expenseList.push(element);
            });
          }

          this.fromPathInfo.reporttitle = true;
        }
        console.log('selected trips list :', this.selectedTrips, this.toPathInfoData);
        this.fromPathInfo.advanceSummary = this.selectedTrips?.length != 0 ? this.selectedTrips[0].advanceSummary : this.toPathInfoData?.advanceSummary;
        this.fromPathInfo.allExpenses = this.allEditExpenses;
        this.fromPathInfo.tripEstimateDetails = this.tripEstimateDetails;
        console.log(this.fromPathInfo);
        sessionStorage.setItem('toPathInfo', JSON.stringify(this.fromPathInfo));
      }
      if (this.navInfoData?.from && (this.navInfoData?.from == 'gpsTracking' || this.navInfoData?.from == 'camera') && this.redirectToDashboard) {
        this.fromPathInfo["url"] = 'dashboard';
      }
      console.log('selected trips list :', this.toPathInfoData);
      if(this.updateNextExpense) {
        this.editNextExpense();
      } else if(this.updatePreviousExpense) {
        this.editPrevExpense();
      } else {
        this.modalController?.dismiss();
        this.navControlr.navigateRoot([this.fromPathInfo["url"]]);
      }
      // this.modalController?.dismiss();
      // console.log('selected trips list :', this.toPathInfoData);
      // this.navControlr.navigateRoot([this.fromPathInfo["url"]]);
      // if(this.isMobileDevice) {
      //   this.navControlr.navigateRoot([this.fromPathInfo["url"]]);
      // } else {
      //   this.modalController?.dismiss();
      // }
    }
  }

  async openModal(value: string, type: string, isExpenseForm?: boolean) {
    var formType = 2;
    if (isExpenseForm) {
      formType = 1;
    } else if (this.type == 4) {
      formType == 4;
    }
    const componentProps = this.getComponentProps(type, formType);
    if (value && value !== "") {
      componentProps.value = value;
    }
    const searchItemModal = await this.modalController.create({
      component: CustomSelectDropdownComponent,
      componentProps,
      keyboardClose: false,
    });
    searchItemModal.onDidDismiss().then((callback) => {
      if (callback && callback.data) {
        if (typeof callback.data !== "string") {
          this[componentProps.listName] = callback.data;
          callback.data.forEach((obj: any) => {
            if (obj.isSelected) {
              if (isExpenseForm) {
                if (type != "corporateUser") {
                  this.expenseForm.controls[type].setValue(
                    obj[componentProps.itemTextField]
                  );
                }
                // this.expenseForm.controls[type].setValue(obj[componentProps.itemTextField]);
                this.expenseModel[componentProps.itemTextField] =
                  obj[componentProps.itemTextField];
                this.expenseModel[componentProps.itemValueField] =
                  obj[componentProps.itemValueField];
                if (type === "categoryName") {
                  this.merchantButtonDisable = true;
                  this.expenseForm.controls.merchantName.reset();
                  this.merchant = true;
                  this.merchantList = obj.merchantList;
                  this.merchantButtonDisable = false;
                  if (this.merchantList.length == 0) {
                    this.defaultmerchant = true;
                  } else {
                    this.defaultmerchant = false;
                  }
                }
                if (type === "originalCurrencyCode") {
                  if (obj != null) {
                    this.expenseModel.originalCurrencyId = obj.currencyId;
                    this.expcurrencySymbol = obj.currencyCode;
                    this.expcurrencyIcon = obj.currencyIcon;
                    this.expConvRate = obj.conversionRate
                      ? obj.conversionRate
                      : 0;
                    this.expenseModel.exchangeRate = obj.conversionRate;
                  }
                  this.selectCurrencyOption(obj, 0);
                  this.active = "mobile";
                }
                if (type === "corporateUser") {
                  this.addCorporateUser(obj);
                }
              } else {
                if (type == 'PDclaimName') {
                  this.perdiemForm.controls['PDclaimName'].setValue(obj.claimName);
                } else if (type == 'perdiem') {
                  this.perdiemModel.PDlocation = obj.countryName;
                  this.perdiemForm.controls['PDlocation'].setValue(obj.countryName);
                  this.perdiemRateList = [];
                  this.selectedPolicyPerdiem = obj;
                  obj.isAllowedWithReceipt == true ? this.addPerdiemRateList("Rate with Receipt") : "";
                  obj.isAllowedWithoutReceipt == true ? this.addPerdiemRateList("Rate without Receipt") : "";
                } else {
                  this.mileageForm.controls[type].setValue(obj[componentProps.itemTextField]);
                  if (type == 'location') {
                    this.mileageForm.controls['categoryName'].reset();
                  }
                  this.expenseModel[componentProps.itemTextField] = obj[componentProps.itemTextField];
                  this.expenseModel[componentProps.itemValueField] = obj[componentProps.itemValueField];
                  if (type === "categoryName") {
                    this.selectCategoryMileageOption(obj);
                  } else {
                    this.stateIds = obj[componentProps.itemValueField];
                    this.CategoryMerchantModel.countryId = obj.countryId;
                    this.getmastCategorylist(true);
                  }
                }
              }
            }
          });
        } else {
          if (isExpenseForm) {
            this.expenseForm.controls[type].setValue(callback.data);
            this.expenseModel[componentProps.itemTextField] = callback.data;
          } else {
            if (type == 'PDclaimName') {
              this.perdiemForm.controls['PDclaimName'].setValue(callback.data);
            } else {
              this.mileageForm.controls[type].setValue(callback.data);
              this.mileageForm[componentProps.itemTextField] = callback.data;
            }

          }
        }
      }
    });
    return await searchItemModal.present();
  }

  getComponentProps(type: string, formType?) {
    switch (type) {
      case "corporateUser":
        return {
          title: this.translate.instant("expenses.users"),
          value: null,
          mainList: null,
          isMultiSelect: false,
          itemValueField: "userId",
          itemTextField: "userName",
          listName: "",
        };
      case "categoryName":
        var catList = this.categoryList;
        if (formType) {
          if (formType == 2) {
            catList = this.milageCategoryList;
          }
        }
        return {
          title: this.translate.instant("common.category"),
          value: null,
          mainList: catList,
          isMultiSelect: false,
          itemValueField: "categoryId",
          itemTextField: "categoryName",
          listName: `categoryList`,
        };
      case "merchantName":
        if (this.merchant) {
        } else {
          return;
        }
        return {
          title: this.translate.instant("mastermerchant.merchants"),
          value: null,
          mainList: this.merchantList,
          isMultiSelect: false,
          itemValueField: "merchantId",
          itemTextField: "merchantName",
          listName: `merchantList`,
          add: {
            buttonName: this.translate.instant("mastermerchant.addmerchant"),
            textFieldName: "Merchant",
            placeholder: this.translate.instant("alerts.entermerchant"),
          },
        };
      case "originalCurrencyCode":
        return {
          title: this.translate.instant("common.currency"),
          value: null,
          mainList: this.currencyList,
          isMultiSelect: false,
          itemValueField: "currencyId",
          itemTextField: "currencyCode",
          listName: `currencyList`,
        };
      case "location":
        return {
          title: 'Location',
          value: null,
          mainList: this.stateList,
          isMultiSelect: false,
          itemValueField: "stateId",
          itemTextField: "stateName",
          itemStateField: "countryId",
          listName: `location`,
          // add: {
          //   buttonName: this.translate.instant("reports.addreportbutton"),
          //   textFieldName: "Report",
          //   placeholder: this.translate.instant("alerts.enterreport"),
          // },
        };
      case "claimName":
        return {
          title: this.translate.instant("common.reports"),
          value: null,
          mainList: this.reportList,
          isMultiSelect: false,
          itemValueField: "claimId",
          itemTextField: "claimName",
          listName: `reportList`,
          add: {
            buttonName: this.translate.instant("reports.addreportbutton"),
            textFieldName: "Report",
            placeholder: this.translate.instant("alerts.enterreport"),
          },
        };
      case "PDclaimName":
        return {
          title: this.translate.instant("common.reports"),
          value: null,
          mainList: this.reportList,
          isMultiSelect: false,
          itemValueField: "claimId",
          itemTextField: "claimName",
          listName: `reportList`,
          add: {
            buttonName: this.translate.instant("reports.addreportbutton"),
            textFieldName: "Report",
            placeholder: this.translate.instant("alerts.enterreport"),
          },
        };
      case "PDrate":
        return {
          title: 'perdiem Type',
          value: null,
          mainList: this.perdiemRateList,
          isMultiSelect: false,
          itemValueField: "PDrate",
          itemTextField: "PDrate",
          listName: `reportList`,
          add: {
            buttonName: this.translate.instant("reports.addreportbutton"),
            textFieldName: "Report",
            placeholder: this.translate.instant("alerts.enterreport"),
          },
        };
      case "perdiem":
        return {
          title: 'perdiem Location',
          value: null,
          mainList: this.policyList.policyPerDiemList,
          isMultiSelect: false,
          itemValueField: "countryId",
          itemTextField: "countryName",
          listName: `reportList`,
          add: {
            buttonName: this.translate.instant("reports.addreportbutton"),
            textFieldName: "Report",
            placeholder: this.translate.instant("alerts.enterreport"),
          },
        };
    }
  }

  resetFormControlAndBlur(form: FormGroup, formControlName: string, eleInput: IonInput) {
    form.controls[formControlName].reset();
    eleInput.getInputElement().then((ele) => ele.blur());
    if (formControlName === "categoryName" && form.contains(formControlName)) {
      this.merchantButtonDisable = true;
      this.expenseForm.controls.merchantName.patchValue(null);
    }
  }

  ocrReqModel = new ocrReqModel();
  ocrAttachment = new ocrAttachment();

  getOcrData(url, fileName, index?) {
    console.log(fileName, url);
    
    this.ocrReqModel.attachments = [];
    this.uploadedfiles.forEach((element) => {
      let request = {
        fileName: element.fileName,
        s3AttachmentUrl: element.s3AttachmentUrl,
        // base64Image: element.content,
      };
      this.ocrReqModel.attachments.push(request);
      // if (this.isAssociated) {
      //   this.ocrReqModel.extract = true;
      // } else if (this.active == "end") {
      //   if (this.perdiemModel.expenseAttachments.length != 0) {
      //     this.ocrReqModel.extract = false;
      //   } else {
      //     this.ocrReqModel.extract = true;
      //   }
      // } else {
      //   if (this.expenseModel.expenseAttachments.length != 0) {
      //     this.ocrReqModel.extract = false;
      //   } else {
      //     this.ocrReqModel.extract = true;
      //   }
      // }
      if (this.isAssociated) {
        this.ocrReqModel.extract = true;
      } else {
        this.ocrReqModel.extract = this.isReadOCR ? true : false;
      }
      this.ocrReqModel.uploadType = "expenses";
    });
    this.imgUpload = true;

    this.expenseService.ocrDataPulling(this.ocrReqModel).subscribe((res) => {
      if (!res.isError) {
        this._bulkProgressState = false;
        this.buttonDisabled = false;
        this.loginUserAttchementChecking = false;
        this.setLoaderToFalse(index);
        // this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false) : "";
        // this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].bulkImgUpload = true) : "";
        // this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].isAttachmentMandatory = false) : "";
        // this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[index].loaderBoolean = false) : "";
        // this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[index].bulkImgUpload = true) : "";
        // this.showText = true;
        this.toastr.success("Attachments added successfully", "Success", index);
        this.imgUpload = false;
        this.uploadedfiles = [];
        // res.payload.attachments.forEach((element) => {
        let request = {
          fileName: fileName,
          url: url,
          content: null,
          attachmentType: "1",
        };
        if (this.active == "end" && !this._perdiemEditForm) {
          this.sampleDt[index].expenseAttachments.push(request);
          this.sampleDt[index].showLoader = false;
        } else if (this.active == "end" && this._perdiemEditForm) {
          this.perdiemModel.expenseAttachments.push(request);
          this.sampleDt[index].expenseAttachments.push(request);
          this.sampleDt[index].showLoader = false;
        } else if (this.active == "bottom") {
          this.totalBulkModel.expenseRequestDTOList[index].expenseAttachments.push(request);
        } else if (this.active == "middle") {
          this.totalMileageBulkModel.expenseRequestDTOList[index].expenseAttachments.push(request);
        } else {
          if (this.platformType == 2) {
            if (this._expenseType == 'Perdiem') {
              this.sampleDt[index].expenseAttachments.push(request);
              this.sampleDt[index].showLoader = false;
            }
            this._dismiss();
          }
          if (this._expenseType == 'Perdiem') {
            this.sampleDt[index].expenseAttachments.push(request);
            this.sampleDt[index].showLoader = false;
          } else {
            this.expenseModel.expenseAttachments.push(request);
            this.associatedAttchements.push(request);
          }
        }
        // });
        if (res.payload?.extractedData) {
          console.log('active status :', this.active);
          console.log('extracted data from :', res.payload?.extractedData);
          if (this.active == "bottom") {
            let attachedFile =
              this.expenseModel.expenseAttachments[
              this.expenseModel.expenseAttachments.length - 1
              ];
            // attachedFile.url = res.payload.attachments[0].uploadedURL;
            // delete attachedFile.content;
            this.bindBulkImgData(res.payload.extractedData, attachedFile);
            return;
          }
          this.active == "top"
            ? this.bindingOCrData(res.payload.extractedData)
            : "";
        }
      } else if (res.isError && res.errorCode == 2) {
        this.loginUserAttchementChecking = false;
        this._bulkProgressState = false;
        this.buttonDisabled = false;
        if (this.active == "bottom") {
          // this.bulkItemsForms.value[this.globalIndex].loaderBoolean = false;
          this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false;
          this.totalBulkModel.expenseRequestDTOList[index].bulkImgUpload = false;
        }
        if (this.active == "end") {
          this.sampleDt[index].showLoader = false;
        }
        this.uploadedfiles = [];
        this.imgUpload = false;
        this.imgContent = "";
        this.toastr.error(
          res.errorMessage,
          this.translate.instant("alerts.error", { timeOut: 3000 })
        );
      } else {
        this.loginUserAttchementChecking = false;
        this._bulkProgressState = false;
        this.buttonDisabled = false;
        this.active == "bottom"
          ? (this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false)
          : "";
        this.totalBulkModel.expenseRequestDTOList[index].bulkImgUpload = false;
        this.uploadedfiles = [];
        this.imgUpload = false;
        this.uploadedfiles = [];
        // this.expenseModel.expenseAttachments = [];
        this.imgContent = "";
      }
    }, err => {
      // console.log(this.active);
      this.uploadedfiles = [];
      this.setLoaderToFalse(index);
      // this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false) : "";
      // this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].bulkImgUpload = true) : "";
      // this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[index].isAttachmentMandatory = false) : "";
      // this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[this.mileageIndex].loaderBoolean = false) : "";
      // this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[this.mileageIndex].bulkImgUpload = true) : "";

    });
  }

  // getOcrData() {
  //   this.ocrReqModel.attachments = [];
  //   this.uploadedfiles.forEach((element) => {
  //     let request = {
  //       fileName: element.fileName,
  //       base64Image: element.content,
  //     };
  //     this.ocrReqModel.attachments.push(request);
  //     // if (this.isAssociated) {
  //     //   this.ocrReqModel.extract = true;
  //     // } else if (this.active == "end") {
  //     //   if (this.perdiemModel.expenseAttachments.length != 0) {
  //     //     this.ocrReqModel.extract = false;
  //     //   } else {
  //     //     this.ocrReqModel.extract = true;
  //     //   }
  //     // } else {
  //     //   if (this.expenseModel.expenseAttachments.length != 0) {
  //     //     this.ocrReqModel.extract = false;
  //     //   } else {
  //     //     this.ocrReqModel.extract = true;
  //     //   }
  //     // }
  //     if (this.isAssociated) {
  //       this.ocrReqModel.extract = true;
  //     } else {
  //       this.ocrReqModel.extract = this.isReadOCR? true:false;
  //     }
  //     this.ocrReqModel.uploadType = "expenses";
  //   });
  //   this.imgUpload = true;

  //   this.expenseService.ocrDataPulling(this.ocrReqModel).subscribe((res) => {
  //     if (!res.isError) {
  //       this._bulkProgressState = false;
  //       this.buttonDisabled = false;
  //       this.loginUserAttchementChecking = false;
  //       this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[this.globalIndex].loaderBoolean = false) : "";
  //       this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[this.globalIndex].bulkImgUpload = true) : "";
  //       this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[this.globalIndex].isAttachmentMandatory = false) : "";
  //       this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[this.mileageIndex].loaderBoolean = false) : "";
  //       this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[this.mileageIndex].bulkImgUpload = true) : "";
  //       // this.showText = true;
  //       this.toastr.success("Attachments added successfully", "Success", this.mileageIndex);
  //       this.imgUpload = false;
  //       this.uploadedfiles = [];
  //       res.payload.attachments.forEach((element) => {
  //         let request = {
  //           fileName: element?.fileName,
  //           url: element?.uploadedURL,
  //           content: element?.base64,
  //           attachmentType: "1",
  //         };
  //         if (this.active == "end" && !this._perdiemEditForm) {
  //           this.sampleDt[this._perdiemSelectedIndex].expenseAttachments.push(request);
  //           this.sampleDt[this._perdiemSelectedIndex].showLoader = false;
  //         } else if (this.active == "end" && this._perdiemEditForm) {
  //           this.perdiemModel.expenseAttachments.push(request);
  //           this.sampleDt[this._perdiemSelectedIndex].expenseAttachments.push(request);
  //           this.sampleDt[this._perdiemSelectedIndex].showLoader = false;
  //         } else if (this.active == "bottom") {
  //           this.totalBulkModel.expenseRequestDTOList[this.globalIndex].expenseAttachments.push(request);
  //         } else if (this.active == "middle") {
  //           this.totalMileageBulkModel.expenseRequestDTOList[this.mileageIndex].expenseAttachments.push(request);
  //         } else {
  //           if (this.platformType == 2) {
  //             if (this._expenseType == 'Perdiem') {
  //               this.sampleDt[this._perdiemSelectedIndex].expenseAttachments.push(request);
  //               this.sampleDt[this._perdiemSelectedIndex].showLoader = false;
  //             }
  //             this._dismiss();
  //           }
  //           if (this._expenseType == 'Perdiem') {
  //             this.sampleDt[this._perdiemSelectedIndex].expenseAttachments.push(request);
  //             this.sampleDt[this._perdiemSelectedIndex].showLoader = false;
  //           } else {
  //             this.expenseModel.expenseAttachments.push(request);
  //             this.associatedAttchements.push(request);
  //           }
  //         }
  //       });
  //       if (res.payload?.extractedData) {
  //         console.log('active status :', this.active);
  //         console.log('extracted data from :', res.payload?.extractedData);
  //         if (this.active == "bottom") {
  //           let attachedFile =
  //             this.expenseModel.expenseAttachments[
  //             this.expenseModel.expenseAttachments.length - 1
  //             ];
  //           // attachedFile.url = res.payload.attachments[0].uploadedURL;
  //           // delete attachedFile.content;
  //           this.bindBulkImgData(res.payload.extractedData, attachedFile);
  //           return;
  //         }
  //         this.active == "top"
  //           ? this.bindingOCrData(res.payload.extractedData)
  //           : "";
  //       }
  //     } else if (res.isError && res.errorCode == 2) {
  //       this.loginUserAttchementChecking = false;
  //       this._bulkProgressState = false;
  //       this.buttonDisabled = false;
  //       if (this.active == "bottom") {
  //         // this.bulkItemsForms.value[this.globalIndex].loaderBoolean = false;
  //         this.totalBulkModel.expenseRequestDTOList[this.globalIndex].loaderBoolean = false;
  //         this.totalBulkModel.expenseRequestDTOList[
  //           this.globalIndex
  //         ].bulkImgUpload = false;
  //       }
  //       if (this.active == "end") {
  //         this.sampleDt[this._perdiemSelectedIndex].showLoader = false;
  //       }
  //       this.uploadedfiles = [];
  //       this.imgUpload = false;
  //       this.imgContent = "";
  //       this.toastr.error(
  //         res.errorMessage,
  //         this.translate.instant("alerts.error",{ timeOut: 3000 })
  //       );
  //     } else {
  //       this.loginUserAttchementChecking = false;
  //       this._bulkProgressState = false;
  //       this.buttonDisabled = false;
  //       this.active == "bottom"
  //         ? (this.totalBulkModel.expenseRequestDTOList[this.globalIndex].loaderBoolean = false)
  //         : "";
  //       this.totalBulkModel.expenseRequestDTOList[
  //         this.globalIndex
  //       ].bulkImgUpload = false;
  //       this.uploadedfiles = [];
  //       this.imgUpload = false;
  //       this.uploadedfiles = [];
  //       // this.expenseModel.expenseAttachments = [];
  //       this.imgContent = "";
  //     }
  //   }, err => {
  //     // console.log(this.active);
  //     this.uploadedfiles = [];
  //     this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[this.globalIndex].loaderBoolean = false) : "";
  //     this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[this.globalIndex].bulkImgUpload = true) : "";
  //     this.active == "bottom" ? (this.totalBulkModel.expenseRequestDTOList[this.globalIndex].isAttachmentMandatory = false) : "";
  //     this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[this.mileageIndex].loaderBoolean = false) : "";
  //     this.active == "middle" ? (this.totalMileageBulkModel.expenseRequestDTOList[this.mileageIndex].bulkImgUpload = true) : "";

  //   });
  // }

  bindingOCrData(extractedData, isbulk?) {
    this.extractData = extractedData;
    if (extractedData.currencyCode) {
      let originalCurrencyCode = extractedData.currencyCode;
      if (!this.currencyList) {
        let listarray = JSON.parse(sessionStorage.getItem("currencyList"));
        this.currencytempList = listarray.map((list) => new CurrencyList(list));
        this.currencyList = listarray.map((list) => new CurrencyList(list));
      }
      let currencyDetails = _.find(this.currencyList, function (object) {
        return object.currencyCode == originalCurrencyCode;
      });
      this.expenseModel.originalCurrencyCode = currencyDetails.currencyCode;
      this.expenseModel.originalCurrencyName = currencyDetails.currencyCode;
      this.expenseModel.originalCurrencyId = currencyDetails.currencyId;
      this.expenseModel.originalCurrencyIcon = currencyDetails.currencyIcon;
      this.expcurrencySymbol = currencyDetails.currencyCode;
      this.expcurrencyIcon = currencyDetails.currencyIcon;
      this.expConvRate = currencyDetails.conversionRate
        ? currencyDetails.conversionRate
        : 0;
      this.expenseModel.exchangeRate = currencyDetails.conversionRate;

    } else {
      this.expenseForm.controls.originalCurrencyCode.patchValue(this.userInfo.baseCurrency.currencyCode);
      this.expenseModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
      this.expenseModel.originalCurrencyName = this.userInfo.baseCurrency.currencyCode;
      this.expenseModel.originalCurrencyCode = this.userInfo.baseCurrency.currencyCode;
      this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
    }
    if (this.extractData.invoiceDate) {
      var datee = new Date(this.extractData.invoiceDate);
      if (datee.getFullYear() == new Date().getFullYear()) {
        this.expenseForm.controls.expensedDate.patchValue(this.convertDateToUtc(this.extractData.invoiceDate));
        this.expenseModel.expensedDate = this.extractData.invoiceDate;
      }
    }

    if (this.expenseModel.originalAmount == undefined || this.expenseModel.originalAmount == null) {
      this.expenseModel.originalAmount = this.extractData?.invoiceTotal;
      // amount validation to raise violations
      let amountValidation = this.expenseModel.originalCurrencyCode == 'INR' ? this.expenseModel.originalAmount : (this.expenseModel.originalAmount * this.expConvRate);
      if (this.policyList?.receiptRequiredLimit) {
        if (amountValidation > this.policyList.receiptRequiredLimit) { this.receiptWarning = true; } else {
          this.receiptWarning = false;
        }
      } else {
        this.receiptWarning = false;
      }
      if (this.policyList?.expenseAmountLimit) {
        if (amountValidation > this.policyList.expenseAmountLimit) {
          this.expenseWarning = true;
        } else {
          this.expenseWarning = false;
        }
      } else {
        this.expenseWarning = false;
      }

    }
    if (this.extractData?.invoiceNo) {
      this.expenseModel.invoiceNumber = this.extractData?.invoiceNo;
    }
    if (
      this.expenseModel.taxAmount == undefined ||
      this.expenseModel.taxAmount == null
    ) {
      this.expenseModel.taxAmount = this.extractData?.gstAmount;
    }
    if (
      this.expenseModel.taxPercentage == undefined ||
      this.expenseModel.taxPercentage == null
    ) {
      this.expenseModel.taxPercentage = this.extractData?.gstPercentage;
    }
    if (this.extractData?.businessName && this.expenseModel.description == "") {
      this.expenseModel.description = this.extractData?.businessName;
    }
    if (this.expenseModel.originalAmount && this.expenseModel.taxPercentage) {
      let _incomingData = (this.expenseModel.originalAmount / 100) * this.expenseModel.taxPercentage;
      if (this.countDecimals(_incomingData) > 2) {
        const first2Str = String(_incomingData).slice(0, 4); // 👉️ '13'
        const first2Num = Number(first2Str); // 👉️ 13
        this.expenseModel.taxAmount = first2Num
        this.expenseForm.controls.taxAmount.patchValue(first2Num);
      } else {
        const returnV = (this.expenseModel.originalAmount / 100) * this.expenseModel.taxPercentage;
        if (this.countDecimals(returnV) > 2) {
          const first2Str = String(returnV).slice(0, 4); // 👉️ '13'
          const first2Num = Number(first2Str); // 👉️ 13
          this.expenseModel.taxAmount = first2Num;
          this.expenseForm.controls.taxAmount.patchValue(first2Num);
        } else {
          this.expenseModel.taxAmount = (this.expenseModel.originalAmount / 100) * this.expenseModel.taxPercentage;
          this.expenseForm.controls.taxAmount.patchValue((this.expenseModel.originalAmount / 100) * this.expenseModel.taxPercentage);
        }
      }
    }
    this.splitExpense();

    if (!isbulk) {
    } else {
      this.totalBulkModel.expenseRequestDTOList[this.globalIndex].loaderBoolean = false;
      // this.bulkItemsForms
      //   .at(this.globalIndex)
      //   .get("loaderBoolean")
      //   .patchValue(false);
    }
  }

  bindBulkImgData(extractedData, file) {
    let i = this.globalIndex;
    if (this.totalBulkModel?.expenseRequestDTOList[i]?.bulkImgUpload) {
      if (extractedData) {
        console.log('filter tips :', extractedData);
        this.extractData = extractedData;
        if (this.extractData?.invoiceDate) {
          this.totalBulkModel.expenseRequestDTOList[i].expensedDate = this.givenDateIsExistorNot(this.extractData?.invoiceDate);
          this.updateBulkForm(
            "",
            this.bulkItemsForms.controls[i] as FormGroup,
            "date",
            i
          );
        }
        if (this.extractData?.businessName && this.userInfo.showAdvanceOptions) {
          this.totalBulkModel.expenseRequestDTOList[i].merchantName = this.extractData?.businessName;
          console.log('yuyuyu', this.extractData?.businessName);
          this.bulkItemsForms.value[i].merchantName = this.extractData?.businessName;
          console.log('bulk form :', i, this.bulkItemsForms);
        }
        if (this.extractData?.invoiceTotal) {
          this.totalBulkModel.expenseRequestDTOList[i].originalAmount =
            this.extractData?.invoiceTotal;
        }
        if (this.extractData?.invoiceNo && this.userInfo.showAdvanceOptions) {
          this.totalBulkModel.expenseRequestDTOList[i].invoiceNumber =
            this.extractData?.invoiceNo;
        }
        if (this.extractData?.gstAmount && this.userInfo.showAdvanceOptions) {
          this.totalBulkModel.expenseRequestDTOList[i].taxAmount = String(this.extractData?.gstAmount).slice(0, 3)
        }
        if (this.extractData?.gstPercentage && this.userInfo.showAdvanceOptions) {
          this.totalBulkModel.expenseRequestDTOList[i].taxPercentage =
            this.extractData?.gstPercentage;
        }
        if (this.extractData?.gstNumber && this.userInfo.showAdvanceOptions) {
          this.totalBulkModel.expenseRequestDTOList[i].taxNumber =
            this.extractData?.gstNumber;
        }
        if (this.extractData?.currencyCode) {
          let compareCurrency = this.extractData?.currencyCode;
          let currencyDetails = _.find(this.currencyList, function (object) {
            return object.currencyCode == compareCurrency;
          });
          this.totalBulkModel.expenseRequestDTOList[i].originalCurrencyId = currencyDetails.currencyId;
          this.totalBulkModel.expenseRequestDTOList[i].currencyCode = currencyDetails.currencyCode;
          this.totalBulkModel.expenseRequestDTOList[i].expConvRate = currencyDetails.conversionRate ? currencyDetails.conversionRate : 0;
          this.totalBulkModel.expenseRequestDTOList[i].exchangeRate = currencyDetails.conversionRate ? currencyDetails.conversionRate : 0;
          this.totalBulkModel.expenseRequestDTOList[i].originalCurrencyIcon = currencyDetails.currencyIcon;

          if (this.totalBulkModel.expenseRequestDTOList[i].originalCurrencyId != 64) {
            if (this.totalBulkModel.expenseRequestDTOList[i].expConvRate * this.totalBulkModel.expenseRequestDTOList[i].originalAmount > this.policyList?.expenseAmountLimit) {
              this.totalBulkModel.expenseRequestDTOList[i].policyLimitAmountWarning = true;
            } else {
              this.totalBulkModel.expenseRequestDTOList[i].policyLimitAmountWarning = false;
            }
            if (this.totalBulkModel.expenseRequestDTOList[i].expConvRate * this.totalBulkModel.expenseRequestDTOList[i].originalAmount > this.policyList?.receiptRequiredLimit) {
              this.totalBulkModel.expenseRequestDTOList[i].policyLimitReceiptWarning = true;
            } else {
              this.totalBulkModel.expenseRequestDTOList[i].policyLimitReceiptWarning = false;
            }
          }
        }

      }
    }
    this.totalBulkModel.expenseRequestDTOList[this.globalIndex].loaderBoolean = false;
    // this.bulkItemsForms
    //   .at(this.globalIndex)
    //   .get("loaderBoolean")
    //   .patchValue(false);
  }

  /* options code start*/

  formOptions = {
    optionTypes: [1, 1, 2, 4, 1, 3, 1, 2, 2],
    placeholder: [
      "Category*",
      "Merchant*",
      "Description",
      "Expense date*",
      "Currency*",
      "Amount",
      "Report",
      "GST Amount*",
      "GST Percentage*",
    ],
    formControlName: [
      "categoryName",
      "merchantName",
      "description",
      "expensedDate",
      "originalCurrencyCode",
      "Amount",
      "claimName",
      "gstAmount",
      "gstPercent",
    ],
    itemKey: [
      "categoryName",
      "merchantName",
      "",
      "",
      "currencyCode",
      "",
      "claimName",
      "",
      "",
    ],
    model: [
      this.expenseModel.dummy,
      this.expenseModel.merchantName,
      this.expenseModel.description,
      this.expenseModel.dummy,
      this.expenseModel.dummy,
      this.expenseModel.originalAmount,
      this.expenseModel.claimName,
      this.expenseModel.taxAmount,
      this.expenseModel.taxPercentage,
    ],
    //    errors:[this.f],
    //     errors:[this.f.categoryName.errors,this.f.merchantName.errors,'descErr',this.f.expensedDate.errors,
    // this.f.originalCurrencyCode.errors,this.f.originalAmount.errors,'reportErr','gstAmntErr','gstPerErr'],
    //     required:[this.f.categoryName.errors.required,this.f.merchantName.errors.required,'descErr',this.f.expensedDate.errors.required,
    //     this.f.originalCurrencyCode.errors.required,this.f.originalAmount.errors.required,'reportErr','gstAmntErr','gstPerErr']
  };

  getExpenseModelErrs(val) {
    switch (val) {
      case "categoryName":
        return this.f.categoryName.errors;
      case "merchantName":
        return this.f.merchantName.errors;
      case "expensedDate":
        return this.f.expensedDate.errors;
      case "originalCurrencyCode":
        return this.f.originalCurrencyCode.errors;
      case "Amount":
        return this.f.originalAmount.errors;
      default:
        var dummy = ";fda";
    }
  }

  getExpenseModelRequdErrs(val) {
    switch (val) {
      case "categoryName":
        return this.f.categoryName.errors.required;
      case "merchantName":
        return this.f.merchantName.errors.required;
      case "expensedDate":
        return this.f.expensedDate.errors.required;
      case "originalCurrencyCode":
        return this.f.originalCurrencyCode.errors.required;
      case "Amount":
        return this.f.originalAmount.errors.required;
      default:
        var dummy = ";fda";
    }
  }

  getDropDownList(val) {
    switch (val) {
      case "merchantName":
        return this.merchantList;
      case "categoryName":
        return this.categoryList;
      case "originalCurrencyCode":
        return this.currencyList;
      case "claimName":
        return this.reportList;
      default:
        var dummy = ";fda";
    }
  }

  onClickCustomDropDown(type, val) {
    let searchText = val.currentTarget.value.trim();
    if (type == "merchantName") {
      if (this.merchanttempList != null) {
        if (searchText != "") {
          let filterdata = this.merchanttempList.filter((data) => {
            if (
              data.merchantName
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) >= 0
            ) {
              return data;
            }
          });
          this.merchantList = filterdata;
        } else {
          this.merchantList = this.merchanttempList;
        }
      }
    } else if (type == "categoryName") {
      if (this.categorytempList != null) {
        if (searchText != "") {
          let filterdata = this.categorytempList.filter((data) => {
            if (
              data.categoryName
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) >= 0
            ) {
              return data;
            }
          });
          this.categoryList = filterdata;
        } else {
          this.categoryList = this.categorytempList;
        }
      }
    } else if (type == "originalCurrencyCode") {
      if (this.currencytempList != null) {
        if (searchText != "") {
          let filterdata = this.currencytempList.filter((data) => {
            if (
              data.currencyCode
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) >= 0
            ) {
              return data;
            }
          });
          this.currencyList = filterdata;
        } else {
          this.currencyList = this.currencytempList;
        }
      }
    } else if (type == "claimName") {
      if (this.reporttempList != null) {
        if (searchText != "") {
          let filterdata = this.reporttempList.filter((data) => {
            if (
              data.claimName.toLowerCase().indexOf(searchText.toLowerCase()) >=
              0
            ) {
              return data;
            }
          });
          this.reportList = filterdata;
        } else {
          this.reporttempList = this.reportList;
        }
      }
    }
  }

  /* options code end*/

  /* bulk code start*/
  bulkFormValidations(count: any, from?) {
    // console.log('else conditon :');
    this.totalBulkModel.isCSVFile = false;
    this.totalBulkModel.csvFileName = null;
    this.totalBulkModel.expenseRequestDTOList = [];
    this.totalBulkForm = this.formBuilder.group({
      claimName: [""],
      claimStartDate: [""],
      claimEndDate: [""],
      claimStartTime: [0],
      claimEndTime: [0],
      claimCategory: [""],
      claimCategoryId: [""],
      claimTypeId: [null],
      claimTypeName: [null],
      bulkItems: this.formBuilder.array([]),
    });
    while (count > 0) {
      this.addBulkItem(null);
      count = count - 1;
    }
    if (from) {
      if (this.navInfoData["url"] == "newreport" || this.navInfoData["url"] == "reports" || this.navInfoData["url"] == "approvals") {
        if (this.navInfoData["url"] == "newreport") {
          console.log('docus :',this.navInfoData);
          this.totalBulkForm.controls.claimName.patchValue(this.navInfoData['report']?.claimName);
          this.totalBulkForm.controls.claimStartDate.patchValue(this.convertDateToUtc(this.navInfoData['report']?.claimStartDate));
          this.totalBulkForm.controls.claimEndDate.patchValue(this.convertDateToUtc(this.navInfoData['report']?.claimEndDate));
          
          this.totalBulkForm.controls.claimStartTime.patchValue(this.navInfoData['report']?.claimStartTime);
          this.totalBulkForm.controls.claimEndTime.patchValue(this.navInfoData['report']?.claimEndTime);
          
          this.totalBulkForm.controls.claimCategory.patchValue(this.navInfoData['report']?.claimCategoryName);
          this.totalBulkForm.controls.claimCategoryId.patchValue(this.navInfoData['report']?.claimCategoryId);
          this.mileageForm.controls.claimName.patchValue(this.navInfoData['report']?.claimName);
          this.mileageForm.controls.claimStartDate.patchValue(this.convertDateToUtc(this.navInfoData['report']?.claimStartDate));
          this.mileageForm.controls.claimEndDate.patchValue(this.convertDateToUtc(this.navInfoData['report']?.claimEndDate));
          this.claimStartDate = this.navInfoData['report']?.claimStartDate
          this.claimEndDate = this.navInfoData['report']?.claimEndDate
          this.minStartDate = this.navInfoData['report']?.claimStartDate;
          this.minEndDate = this.navInfoData['report']?.claimEndDate;
          this.FormattedMinStartDate = this.convertDateToUtc(this.navInfoData['report']?.claimStartDate);
          this.FormattedMinEndDate = this.convertDateToUtc(this.navInfoData['report']?.claimEndDate);
          // console.log('perdiem Name is :', this.navInfoData['report']?.claimName);
        } else {
          this.totalBulkForm.controls.claimName.patchValue(this.navInfoData['detailReport']?.claimName);
          this.totalBulkForm.controls.claimStartDate.patchValue(this.convertDateToUtc(this.navInfoData['detailReport']?.claimStartDate));
          this.totalBulkForm.controls.claimEndDate.patchValue(this.convertDateToUtc(this.navInfoData['detailReport']?.claimEndDate));


          this.totalBulkForm.controls.claimCategory.patchValue(this.navInfoData['detailReport']?.claimCategoryName);
          this.totalBulkForm.controls.claimCategoryId.patchValue(this.navInfoData['detailReport']?.claimCategoryId);
          this.totalBulkForm.controls.claimTypeName.patchValue(this.navInfoData['detailReport']?.claimTypeName);
          this.totalBulkForm.controls.claimTypeId.patchValue(this.navInfoData['detailReport']?.claimTypeId);
          this.mileageForm.controls.claimStartDate.patchValue(this.convertDateToUtc(this.navInfoData['detailReport']?.claimStartDate));
          this.mileageForm.controls.claimEndDate.patchValue(this.convertDateToUtc(this.navInfoData['detailReport']?.claimEndDate));
          this.claimStartDate = this.navInfoData['detailReport']?.claimStartDate
          this.claimEndDate = this.navInfoData['detailReport']?.claimEndDate
          this.minStartDate = this.navInfoData['detailReport']?.claimStartDate;
          this.minEndDate = this.navInfoData['detailReport']?.claimEndDate;
          this.FormattedMinStartDate = this.convertDateToUtc(this.navInfoData['detailReport']?.claimStartDate);
          this.FormattedMinEndDate = this.convertDateToUtc(this.navInfoData['detailReport']?.claimEndDate);
          this.totalBulkForm.controls?.claimStartTime?.patchValue(this.navInfoData['detailReport']?.claimStartTime);
          this.totalBulkForm.controls?.claimEndTime?.patchValue(this.navInfoData['detailReport']?.claimEndTime);
          if(this.navInfoData?.claimTaggedTripList && this.navInfoData?.claimTaggedTripList?.length != 0){
            this.disableCLaimType = true;
          }
        }

        if (this.userInfo.allowedExpenseDate) {
          this._claimMin = new Date(this.userInfo.allowedExpenseDate);
        } else {
          this._claimMin = this.convertDateToUtc(this.navInfoData['report']?.claimStartDate);
        }
        this._claimMax = this.convertDateToUtc(this.navInfoData['report']?.claimEndDate);
        this.claimMindate = this.convertDateToUtc(this.navInfoData['report']?.claimStartDate);

      } else if (this.navInfoData?.attachment?.length && this.navInfoData.url == 'dashboard') {

        if (this.userInfo.allowedExpenseDate) {
          this._claimMin = new Date(this.userInfo.allowedExpenseDate);
        } else {
          this._claimMin = this.convertDateToUtc(this.navInfoData['report']?.claimStartDate);
        }

        console.log('extracted data :', this.navInfoData);
        let bulkItem = new BulkItemModel();
        bulkItem.expensedDate = this.navInfoData?.extractedData?.invoiceDate;
        bulkItem.currencyCode = this.userInfo.baseCurrency.currencyCode;
        bulkItem.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
        bulkItem.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
        bulkItem.expConvRate = 1;
        bulkItem.description = this.navInfoData?.extractedData?.businessName;
        bulkItem.invoiceNumber = this.navInfoData?.extractedData?.invoiceNo;
        bulkItem.taxNumber = this.navInfoData?.extractedData?.gstNumber;;
        bulkItem.taxPercentage = this.navInfoData?.extractedData?.gstPercentage;
        bulkItem.taxAmount = this.navInfoData?.extractedData?.gstAmount;
        bulkItem.originalAmount = 0;
        bulkItem.expenseDateExist = false;
        this.navInfoData?.attachment.forEach(element => {
          let request = {
            fileName: element?.fileName,
            url: element?.url,
            content: element?.base64,
            attachmentType: "1",
          };
          bulkItem.expenseAttachments.push(request);
        });
        bulkItem.bandId = this.userInfo?.bandId || null;
        bulkItem.bandName = this.userInfo?.bandName || null;
        bulkItem.departmentId = this.userInfo?.departmentId || null;
        bulkItem.departmentName = this.userInfo?.departmentName || null;
        bulkItem.costCenterId = this.userInfo?.costCenterId || null;
        bulkItem.costCenterName = this.userInfo?.costCenterName || null;
        bulkItem.designationId = this.userInfo?.designationId || null;
        bulkItem.designationName = this.userInfo?.designationName || null;
        bulkItem.projectCodeId = this.userInfo?.projectCodeId || null;
        bulkItem.projectCodeName = this.userInfo?.projectCodeName || null;
        this.addBulkItem(bulkItem);
        this.removeBulkItems('Expense', 0);
      } else if (this.navInfoData.url == 'expensesTrip' && this.navInfoData.report?.fromMytrips) {
        this.totalBulkForm.controls.claimStartDate.patchValue(this.convertDateToUtc(this.navInfoData['report']?.claimStartDate));
        this.totalBulkForm.controls.claimEndDate.patchValue(this.convertDateToUtc(this.navInfoData['report']?.claimEndDate));
        this.totalBulkForm.controls.claimName.patchValue(this.navInfoData['report']?.claimName);
        this.claimStartDate = this.navInfoData['report']?.claimStartDate
        this.claimEndDate = this.navInfoData['report']?.claimEndDate
        this.minStartDate = this.navInfoData['report']?.claimStartDate;
        this.minEndDate = this.navInfoData['report']?.claimEndDate;
        this.FormattedMinStartDate = this.convertDateToUtc(this.navInfoData['report']?.claimStartDate);
        this.FormattedMinEndDate = this.convertDateToUtc(this.navInfoData['report']?.claimEndDate);
      }
    }

  }

  get bulkItemsForms() {
    return this.totalBulkForm.get("bulkItems") as FormArray;
  }

  addBulkItem(bulkModel: any) {
    ++this.currentDatePickerInd;
    ++this.totalExpensesCount;
    this.setExpensesMaxLimit();
    if (bulkModel) {
      bulkModel.showAdditionalFields = true;
      this.totalBulkModel.expenseRequestDTOList.push(bulkModel);
      // console.log(this.totalBulkModel.expenseRequestDTOList)
      let expenseIndex = this.totalBulkModel?.expenseRequestDTOList?.length;
      this.bulkItemsForms.push(this.addBulkFormGroup(bulkModel,true,expenseIndex || 0));
    } else {
      let newModel = new BulkItemModel();
      newModel.currencyCode = this.userInfo.baseCurrency.currencyCode;
      newModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
      newModel.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
      newModel.expConvRate = 1;
      newModel.exchangeRate = 1;
      newModel.showOptions = (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false;;
      newModel.expenseDateExceed = false;
      newModel.violationType = 1;
      newModel.limitType = 1;
      newModel.limitValue = 0;
      newModel.ruleType = 1;
      newModel.categoryCountLimitWarning = false;
      newModel.showCategoryList = false;
      newModel.approversList = [];
      newModel.monthlyDataList = [];
      newModel.hasExpenseSplit = false;
      newModel.userlists = [];
      newModel.expenseUserSplitList = [];
      newModel.deviatedApproversList = [];
      newModel.financeIntegrationCode = '';
      newModel.taxCode = '';
      newModel.hsnCode = '';
      newModel.financeIntegrationId = '';
      newModel.taxCodeId = '';
      newModel.hsnCodeId = '';
      newModel.editedExchangeRate = null;
      newModel.bandId = this.userInfo?.bandId;
      newModel.departmentId = this.userInfo?.departmentId;
      newModel.costCenterId = this.userInfo?.costCenterId;
      newModel.projectCodeId = this.userInfo?.projectCodeId;
      newModel.bandName = this.userInfo?.bandName;
      newModel.departmentName = this.userInfo?.departmentName;
      newModel.costCenterName = this.userInfo?.costCenterName;
      newModel.projectCodeName = this.userInfo?.projectCodeName;
      newModel.showAdditionalFields = false;
      newModel.paidBy = '';
      newModel.paidByName = '';
      newModel.showpaidByList = false;
      newModel.categoryCode = '';
      this.totalBulkModel.expenseRequestDTOList.push(newModel);
      let expenseIndex = this.totalBulkModel?.expenseRequestDTOList?.length;
      this.bulkItemsForms.push(this.addBulkFormGroup(newModel, false, expenseIndex || 0));
          }
  }

  paidByList = [
    {id: 1, name: 'Company Paid'},
    {id: 2, name: 'Self Paid'}
  ]
  // formE
  addBulkFormGroup(bulkModel: BulkItemModel, edit?, expenseIndex?): FormGroup {
        // console.log(7729,bulkModel);
    
    let fic = (bulkModel.financeIntegrationCode && bulkModel.financeIntegrationCode != '') ? (bulkModel.financeIntegrationCode + ' - ') : '';
    let fin = (bulkModel.financeIntegrationName && bulkModel.financeIntegrationName != '') ? bulkModel.financeIntegrationName : '';    
    bulkModel.financeCodeName = fic + fin;

    let band = {
      id: bulkModel.bandId || this.userInfo?.bandId,
      name: bulkModel.bandName || this.userInfo?.bandName,
    }
    let department = {
      id: bulkModel.departmentId || this.userInfo?.departmentId,
      name: bulkModel.departmentName || this.userInfo?.departmentName,
    }
    let costCenter = {
      id: bulkModel.costCenterId || this.userInfo?.costCenterId,
      name: bulkModel.costCenterName || this.userInfo?.costCenterName,
    }
    let projectCode = {
      id: bulkModel.projectCodeId || this.userInfo?.projectCodeId,
      name: bulkModel.projectCodeName || this.userInfo?.projectCodeName,
    }
    let designation = {
      id: bulkModel.designationId || this.userInfo?.designationId,
      name: bulkModel.designationName || this.userInfo?.designationName,
    }

    const formGroupList = this.formBuilder.group({
      parentCategoryId: [bulkModel.parentCategoryId, (this.userInfo?.isEnableCategorySelectionAtClaim && this.userInfo?.isEnableCategorySelectionAtExpense) ? [Validators.required] : []],
      parentCategoryName: [bulkModel.parentCategoryName, (this.userInfo?.isEnableCategorySelectionAtClaim && this.userInfo?.isEnableCategorySelectionAtExpense) ? [Validators.required] : []],
      categoryName: [bulkModel.categoryName, [Validators.required]],
      categoryType: [bulkModel.categoryId],
      description: [bulkModel.description, this.userInfo?.isDescriptionMandatory ? [Validators.required] : []],
      invoiceNumber: [bulkModel.invoiceNumber],
      taxNumber: [bulkModel.taxNumber],
      taxPercentage: [bulkModel.taxPercentage],
      taxAmount: [bulkModel.taxAmount],
      merchantCode: [bulkModel.merchantCode],
      merchantName: [bulkModel.merchantName],
      location: [bulkModel.location],
     
      expenseUserSplitList: [bulkModel.expenseUserSplitList],
      hasExpenseSplit: [bulkModel.hasExpenseSplit],
      date: [
        this.convertDateToUtc(bulkModel.expensedDate),
        [Validators.required],
      ],
      currencyCode: [bulkModel.currencyCode ? bulkModel.currencyCode : this.userInfo.baseCurrency.currencyCode, [Validators.required]],
      currencyId: [bulkModel.merchantName || this.userInfo.baseCurrency.currencyId],
      amount: [
        bulkModel.originalAmount,
        [Validators.required, Validators.maxLength(14)],
      ],
      hasAttachmt: [true],
      showOptions: [bulkModel.showOptions],
      expenseDateExceed: [bulkModel.expenseDateExceed],
      violationType: [bulkModel.violationType],
      categoryCountLimitWarning: [bulkModel.categoryCountLimitWarning],
      userlists: [bulkModel.userlists],
      expenseId: [bulkModel.expenseId],
      expenseGUID: [bulkModel.expenseGUID],
      financeIntegrationCode: [bulkModel.financeCodeName],
      financeIntegrationId: [bulkModel.financeIntegrationId],
      hsnCode: [bulkModel.hsnCode],
      hsnCodeId: [bulkModel.hsnCodeId],
      taxCodeId: [bulkModel.taxCodeId],
      taxCode: [bulkModel.taxCode],

      isSpentLocationManditory: [bulkModel.isSpentLocationManditory,[]],
      isStartEndDateEnabled: [bulkModel.isStartEndDateEnabled,[]],
      isPolicyCalcPerNight: [bulkModel.isPolicyCalcPerNight, []],


      expenseEndDate: [
        bulkModel?.expenseEndDate ? this.convertDateToUtc(bulkModel?.expenseEndDate) : '',
        bulkModel.isStartEndDateEnabled == true ? [Validators.required] : []
      ],
      countryName: [bulkModel?.countryName, bulkModel.isSpentLocationManditory == true ? [Validators.required] : []],
      cityName: [bulkModel?.cityName, bulkModel.isSpentLocationManditory == true ? [] : []],
      isPerDayEnabled: [bulkModel.isPerDayEnabled],


      originalApproveAmount: [bulkModel.originalApproveAmount],
      usereditexchangerate: [bulkModel.editedExchangeRate],
      bandId: [band.id || '', this.bandsMandatory.expense ? [Validators.required] : []],
      departmentId: [department.id || '', this.departmentsMandatory.expense ? [Validators.required] : []],
      costCenterId: [costCenter.id || '', this.costCentersMandatory.expense ? [Validators.required] : []],
      projectCodeId: [projectCode.id || '', this.projectCodesMandatory.expense ? [Validators.required] : []],
      designationId: [bulkModel.designationId || designation.id],
      designationName: [bulkModel.designationName || designation.name],
      bandName: [band.name || ''],
      departmentName: [department.name || ''],
      costCenterName: [costCenter.name || ''],
      projectCodeName: [projectCode.name || ''],
      paidBy: [bulkModel.paidBy, this.paidByMandatory ? [Validators.required] : []],
      paidByName: [bulkModel.paidByName, this.paidByMandatory ? [Validators.required] : []],
      coUserId: [bulkModel.coUserId],
      coUserName: [bulkModel.coUserName],
      coEmployeeId: [bulkModel.coEmployeeId],
      restrictClaimOnStartEndDates: [bulkModel.restrictClaimOnStartEndDates || false],
      showProofofpayment: [bulkModel.showProofofpayment || false]
    });

    if (edit) {
      formGroupList.get('date').markAsTouched();
    }

    // if (this.bInd >= 0 && this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1]) {
    //   this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].bandId = band.id || '';
    //   this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].bandName = band.name || '';
    // }
    // if (this.dInd >= 0 && this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1]) {
    //   this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].departmentId = department.id || '';
    //   this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].departmentName = department.name || '';
    // }
    // if (this.ccInd >= 0 && this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1]) {
    //   this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].costCenterId = costCenter.id || '';
    //   this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].costCenterName = costCenter.name || '';
    // }
    // if (this.pcInd >= 0 && this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1]) {
    //   this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].projectCodeId = projectCode.id || '';
    //   this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].projectCodeName = projectCode.name || '';
    // }

    console.log('edit :',edit,bulkModel.customFieldList,this.customFields?.expenseForm?.customFieldList);

    let customFields = [];


    
    this.customFields?.expenseForm?.customFieldList?.forEach((field, i) => {
      const controlName = field.fieldName;
      const controlValue = edit ? (bulkModel.customFieldList ? bulkModel.customFieldList[i]?.customFieldValue : '') : '';
      const validators = [];
      formGroupList.addControl(controlName, new FormControl(controlValue, validators));
      formGroupList.controls.merchantName.setValidators([Validators.required]);
      formGroupList.controls.merchantName.updateValueAndValidity();
      
      
      customFields[i] = {
        id: bulkModel.customFieldList ? bulkModel.customFieldList[i]?.id : '',
        expenseId: bulkModel.expenseId || '',
        customFieldName: field.fieldName,
        customFieldValue: bulkModel.customFieldList ? bulkModel.customFieldList[i]?.customFieldValue : '',
        fieldId: field.fieldId
      };
    });
    // this.cdr.detectChanges();
    if (expenseIndex - 1 >= 0 && this.totalBulkModel?.expenseRequestDTOList[expenseIndex - 1]) {
      this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].customFieldList = customFields;
      this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].showCustomFields = true;
      this.totalBulkModel.expenseRequestDTOList[expenseIndex - 1].showAdditionalFields = true;
    }

    return formGroupList;

  }


 
isMandatoryField(fieldTypeName,isSystem,ind,form,formControlName) {
  if (isSystem) {
    let index;
    index = this.customFields?.expenseForm?.systemFieldList.findIndex(c => c.fieldName == fieldTypeName && c.categoryIds?.includes(this.totalBulkModel.expenseRequestDTOList[ind]?.categoryId) && (this.userInfo?.isEnableClaimTypes ? c?.claimTypeName == this.totalBulkForm?.controls?.claimTypeName?.value : true));
    if(index < 0) {
      index = this.customFields?.expenseForm?.systemFieldList.findIndex(c => c.fieldName == fieldTypeName && !c.categoryIds && (this.userInfo?.isEnableClaimTypes ? c?.claimTypeName == this.totalBulkForm?.controls?.claimTypeName?.value : true));
    }
    let showCat = index >= 0 ? true : false;
    if (showCat && this.customFields?.expenseForm?.systemFieldList[index]?.isMandatory) {
      form.controls[formControlName].setValidators([Validators.required]);
      form.controls[formControlName].updateValueAndValidity();
    } else {
      form.controls[formControlName]?.setValidators([]);
      form.controls[formControlName]?.updateValueAndValidity();
    }
    return showCat && this.customFields?.expenseForm?.systemFieldList[index]?.isMandatory ? true : false;

  
  } else {
    const index = this.customFields?.expenseForm?.customFieldList.findIndex(c => c.fieldName == fieldTypeName);
    return this.customFields?.expenseForm?.customFieldList[index]?.isMandatory || false;
  }
}

basicAdvanced(type, isSystem, ind?) {
  if (isSystem) {
    let index;
    index = this.customFields?.expenseForm?.systemFieldList.findIndex(c => c.fieldName == type && c.categoryIds?.includes(this.totalBulkModel.expenseRequestDTOList[ind]?.categoryId) && (this.userInfo?.isEnableClaimTypes ? c?.claimTypeName == this.totalBulkForm?.controls?.claimTypeName?.value : true));
    if(index == -1) {
      index = this.customFields?.expenseForm?.systemFieldList.findIndex(c => c.fieldName == type && !c.categoryIds && (this.userInfo?.isEnableClaimTypes ? c?.claimTypeName == this.totalBulkForm?.controls?.claimTypeName?.value : true));
    }
    return this.customFields?.expenseForm?.systemFieldList[index]?.displayType;
  } else {
    const index = this.customFields?.expenseForm?.customFieldList.findIndex(c => c.fieldName == type);
    return this.customFields?.expenseForm?.customFieldList[index]?.isMandatory || false;
  }
}

displayField(type, isTrue?, ind?) {
  let index;
  index = this.customFields?.expenseForm?.systemFieldList.findIndex(c => c.fieldName == type && c.categoryIds?.includes(this.totalBulkModel.expenseRequestDTOList[ind]?.categoryId) && (this.userInfo?.isEnableClaimTypes ? c?.claimTypeName == this.totalBulkForm?.controls?.claimTypeName?.value : true));
  if(index == -1) {
    index = this.customFields?.expenseForm?.systemFieldList.findIndex(c => c.fieldName == type && !c.categoryIds && (this.userInfo?.isEnableClaimTypes ? c?.claimTypeName == this.totalBulkForm?.controls?.claimTypeName?.value : true));
  }
  let showCat = false;
  if(index >= 0) {
    showCat = this.customFields?.expenseForm?.systemFieldList[index]?.statusId == 1 ? true : false;
  } else {
    // showCat = true;
  }
  return showCat;
}

  
  // fieldMapping = {
  //   'Category': 'categoryName',
  //   'Currency': 'categoryType',
  //   'Description/Reason': 'description',
  //   'Invoice Number': 'invoiceNumber',
  //   'Merchant': 'merchantName',
  //   'Merchant code': 'merchantCode',
  //   // Add more mappings here as needed
  // };
  removedataform(type, index, removeformTemp: TemplateRef<any>) {
    console.log(index)
    this.expensetype = type;
    // if(removeformTemp){
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
      //stringtype: type
    };
    this.modalRef = this.modalService.show(removeformTemp, config);
    this.index = index;
    //}
  }

  removeBulkItems(type, index, removedeleteTemp?) {
    this.expensetype = type;
    if (removedeleteTemp) {
      const config = {
        ignoreBackdropClick: false,
        class: "modal-md modal-dialog-centered",
        keyboard: false,
        stringtype: type
      };
      this.modalRef = this.modalService.show(this.removedeleteTemp, config);
      this.index = index;
    } else {
      this.totalBulkModel.expenseRequestDTOList.splice(index, 1);
      this.bulkItemsForms.removeAt(index);
    }
  }

  removeMileageBulkItems(type, index, removedeleteTemp?) {
    this.expensetype = type;
    if (removedeleteTemp) {
      const config = {
        ignoreBackdropClick: false,
        class: "modal-md modal-dialog-centered",
        keyboard: false,
        stringtype: type
      };
      this.modalRef = this.modalService.show(this.removedeleteTemp, config);
      this.index = index;
    } else {
      let previous_cat_id = this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId;
      this.totalMileageBulkModel.expenseRequestDTOList.splice(index, 1);
      this.MileagebulkItemsForms.removeAt(index);
    }

    // this.afterRemoveMileageCatRulesValidation(previous_cat_id);
  }

  mileageFormValidations(count: any) {
    console.log(7820, this.GPSData);
    this.totalMileageBulkModel.expenseRequestDTOList = [];
    this.mileageForm = this.formBuilder.group({
      claimName: [''],
      claimStartDate: [""],
      claimEndDate: [""],
      bulkItems: this.formBuilder.array([]),
    });
    while (count > 0) {
      this.addMileageBulkItem(null);
      count = count - 1;
    }
    if (this.userPreferenceObj) {
      this.stateIds = this.userPreferenceObj.mileageStateId;
      this.CategoryMerchantModel.countryId = this.userPreferenceObj.mileageCountryId;
      this.getmastCategorylist(true, 0);
    }

    if (this.GPSData) {
      this.active = 'middle';
      this.mileageForm.patchValue({
        claimStartDate: new Date(),
        claimEndDate: new Date(),
        bulkItems: [
          {
            location: this.userPreferenceObj?.mileageStateName,
            categoryName: this.userPreferenceObj?.mileageCategoryName,
            categoryId: this.userPreferenceObj?.mileageCategoryId,
            distance: this.GPSData.distance.toFixed(2),
            description: 'Mileage Expense Created on ' + moment(new Date()).format("DD MMM YYYY"),
            expensedDate: moment().format(),
            fromLocationName: `${this.GPSData.fromLat}, ${this.GPSData.fromLong}`,
            toLocationName: (this.GPSData.toLat == 0 && this.GPSData.toLong == 0) ? `${this.GPSData.fromLat}, ${this.GPSData.fromLong}` : `${this.GPSData.toLat}, ${this.GPSData.toLong}`
          }
        ]
      })
      this.latLong_from = `${this.GPSData.fromLat}, ${this.GPSData.fromLong}`
      this.latLong_to = (this.GPSData.toLat == 0 && this.GPSData.toLong == 0) ? `${this.GPSData.fromLat}, ${this.GPSData.fromLong}` : `${this.GPSData.toLat}, ${this.GPSData.toLong}`;
      if (this.totalMileageBulkModel?.expenseRequestDTOList[0]) {
        this.totalMileageBulkModel.expenseRequestDTOList[0].distance = this.GPSData.distance.toFixed(2);
        this.totalMileageBulkModel.expenseRequestDTOList[0].categoryName = this.userPreferenceObj?.mileageCategoryName;
        this.totalMileageBulkModel.expenseRequestDTOList[0].categoryId = this.userPreferenceObj?.mileageCategoryId;
        this.totalMileageBulkModel.expenseRequestDTOList[0].location = this.userPreferenceObj?.mileageStateName;
        this.totalMileageBulkModel.expenseRequestDTOList[0].fromLocationName = 'fromLocationName';
        this.totalMileageBulkModel.expenseRequestDTOList[0].toLocationName = 'toLocationName';
        this.totalMileageBulkModel.expenseRequestDTOList[0].expensedDate = moment().format();
      }
    }
  }

  async openTripEstimatePopup(tripEstimatePopOver?: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-xl",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(tripEstimatePopOver, config);
    }

  closeModal() {
    this.modalController.dismiss();
  }
  get MileagebulkItemsForms() {
    return this.mileageForm.get("bulkItems") as FormArray;
  }

  addMileageBulkItem(bulkModel: any, fromTrip?) {
    ++this.currentDatePickerInd;
    ++this.totalMileagesCount;
    this.setExpensesMaxLimit();
    if (bulkModel) {
      console.log('before enteringinto DTO list :', bulkModel);
      this.totalMileageBulkModel.expenseRequestDTOList.push(bulkModel);
      this.MileagebulkItemsForms.push(this.addMileageBulkFormGroup(bulkModel));
      if(fromTrip) {
        let mListlength = this.MileagebulkItemsForms?.value?.length || 0;
        let stateIndex =  this.stateList?.findIndex(c => c.stateName == bulkModel?.location);
        if(stateIndex >= 0) {
          let stateObj = this.stateList[stateIndex];
          this.getStatesCall({stateId: stateObj.stateId, countryId: stateObj.countryId}, mListlength > 0 ? (mListlength - 1) : 0);
        }
      }
    } else {
      let newModel = new MileageBulkItemModel();
      newModel.originalCurrencyCode = this.userInfo.baseCurrency.currencyCode;
      newModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
      newModel.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
      newModel.expConvRate = 1;
      newModel.exchangeRate = 1;
      newModel.showOptions = (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false;
      newModel.showStateList = false;
      newModel.loaderBoolean = false;
      newModel.bulkImgUpload = false;
      newModel.vehicalList = [];
      newModel.expenseAttachments = [];
      newModel.checkPointsList = [];
      newModel.categoryRuleLimitAmountWarning = false;
      newModel.categoryLimitAmountWarning = false;
      newModel.categoryLimitReceiptWarning = false;
      newModel.limitType = 1;
      newModel.limitValue = 0;
      newModel.violationType = 1;
      newModel.taxCodeId = '';
      newModel.monthlyDataList = [];
      newModel.bandId = '';
      newModel.departmentId = '';
      newModel.costCenterId = '';
      newModel.projectCodeId = '';
      newModel.bandName = '';
      newModel.departmentName = '';
      newModel.costCenterName = '';
      newModel.projectCodeName = '';
      newModel.showAdditionalFields = false;
      newModel.categoryCode = '';
      this.totalMileageBulkModel.expenseRequestDTOList.push(newModel);
      let expenseIndex = this.totalMileageBulkModel?.expenseRequestDTOList?.length;
      this.MileagebulkItemsForms.push(this.addMileageBulkFormGroup(newModel, false, expenseIndex));
    }
  }

  //   greaterThanZero(control: FormControl) {
  //   const value = parseFloat(control.value);
  //   return value > 0 ? null : { notGreaterThanZero: true };
  // }

  hasRequiredValidator(control: AbstractControl | null): boolean {
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      return validator && validator.required;
    }
    return false;
  }

  // formM
  addMileageBulkFormGroup(bulkModel: MileageBulkItemModel, edit?, expenseIndex?): FormGroup {
    console.log('incming model :', bulkModel);
    
    let band = {
      id: bulkModel.bandId || this.bands[this.bInd]?.bandId,
      name: bulkModel.bandId || this.bands[this.bInd]?.bandName,
    }
    let department = {
      id: bulkModel.departmentId || this.departments[this.dInd]?.departmentId,
      name: bulkModel.departmentName || this.departments[this.dInd]?.departmentName,
    }
    let costCenter = {
      id: bulkModel.costCenterId || this.costCenters[this.ccInd]?.costCenterId,
      name: bulkModel.costCenterName || this.costCenters[this.ccInd]?.costCenterName,
    }
    let projectCode = {
      id: bulkModel.projectCodeId || this.departments[this.dInd]?.projectCodeId,
      name: bulkModel.projectCodeName || this.departments[this.dInd]?.projectCodeName,
    }
    let fic = (bulkModel.financeIntegrationCode && bulkModel.financeIntegrationCode != '') ? (bulkModel.financeIntegrationCode + ' - ') : '';
    let fin = (bulkModel.financeIntegrationName && bulkModel.financeIntegrationName != '') ? bulkModel.financeIntegrationName : '';    
    bulkModel.financeCodeName = fic + fin;
    // console.log('incming model :', bulkModel);
    const formGroupList = this.formBuilder.group({
      location: [bulkModel.location, [Validators.required]],
      categoryId: [bulkModel.categoryId],
      categoryName: [bulkModel.categoryName, [Validators.required]],
      fromLocationName: [bulkModel.fromLocationName, bulkModel.mandateFromToLocations ? [Validators.required] : []],
      toLocationName: [bulkModel.toLocationName, bulkModel.mandateFromToLocations ? [Validators.required] : []],
      // distance: [bulkModel.distance, [Validators.required, Validators.pattern(/^([1-9][0-9]*|0\.[0-9]+)$/),
      //   this.greaterThanZero]],
      distance: [bulkModel.distance, [Validators.required]],
      mileageRate: [bulkModel.mileageRate],
      mileageUnit: [bulkModel.mileageUnit],
      description: [bulkModel.description, this.userInfo?.isDescriptionMandatory ? [Validators.required] : []],
      expensedDate: [this.convertDateToUtc(bulkModel.expensedDate), [Validators.required]],
      originalCurrencyCode: [bulkModel.originalCurrencyCode, [Validators.required]],
      originalCurrencyId: [bulkModel.originalCurrencyId],
      originalAmount: [bulkModel.originalAmount, [Validators.required],],
      showOptions: [bulkModel.showOptions],
      showStateList: false,
      loaderBoolean: false,
      originalApproveAmount: [bulkModel.unformattedOriginalApproveAmount],
      bulkImgUpload: [bulkModel.bulkImgUpload],
      vehicalList: [],
      status: [bulkModel.status],
      expenseAttachments: [bulkModel.expenseAttachments],
      categoryRuleLimitAmountWarning: [bulkModel.categoryRuleLimitAmountWarning],
      categoryLimitAmountWarning: [bulkModel.categoryLimitAmountWarning],
      categoryLimitReceiptWarning: [bulkModel.categoryLimitReceiptWarning],
      limitType: [bulkModel.limitType],
      limitValue: [bulkModel.limitValue],
      violationType: [bulkModel.violationType],
      approvedDistance: [bulkModel.approvedDistance],
      financeIntegrationCode: [bulkModel.financeCodeName],
      financeIntegrationId: [bulkModel.financeIntegrationId],
      taxCode: [bulkModel.taxCode],
      hsnCode: [bulkModel.hsnCode],
      taxCodeId: [bulkModel.taxCodeId],
      hsnId: [bulkModel.hsnId],
      expenseId: [bulkModel.expenseId],
      // approvedDistance:[bulkModel.approvedDistance]
      bandId: [band.id || '', this.bandsMandatory.mileage ? [Validators.required] : []],
      departmentId: [department.id || '', this.departmentsMandatory.mileage ? [Validators.required] : []],
      costCenterId: [costCenter.id || '', this.costCentersMandatory.mileage ? [Validators.required] : []],
      projectCodeId: [projectCode.id || '', this.projectCodesMandatory.mileage ? [Validators.required] : []],
      bandName: [band.name || ''],
      departmentName: [department.name || ''],
      costCenterName: [costCenter.name || ''],
      projectCodeName: [projectCode.name || ''],
      isAutoCalculateDistanceAmount: [bulkModel.isAutoCalculateDistanceAmount ?? true],
      mandateFromToLocations: [bulkModel.mandateFromToLocations || false],
      invoiceNumber: [bulkModel.invoiceNumber]
    });

    if (edit) {
      formGroupList.get('expensedDate').markAsTouched();
    }

    if (this.bInd >= 0 && this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1]) {
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].bandId = band.id || '';
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].bandName = band.name || '';
    }
    if (this.dInd >= 0 && this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1]) {
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].departmentId = department.id || '';
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].departmentName = department.name || '';
    }
    if (this.ccInd >= 0 && this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1]) {
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].costCenterId = costCenter.id || '';
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].costCenterName = costCenter.name || '';
    }
    if (this.pcInd >= 0 && this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1]) {
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].projectCodeId = projectCode.id || '';
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].projectCodeName = projectCode.name || '';
    }

    // let expenseIndex = this.totalBulkModel?.expenseRequestDTOList?.length;

    // setTimeout(() => {
    let customFields = [];

    this.customFields?.mileageForm?.customFieldList?.forEach((field, i) => {
      formGroupList.addControl(field.fieldName, new FormControl((edit && bulkModel.customFieldList) ? bulkModel.customFieldList[i]?.customFieldValue : '', []));
      customFields[i] = {
        // id: '',
        // expenseId: bulkModel.expenseId || '',
        // customFieldName: field.fieldName,
        // customFieldValue: '',
        // fieldId: field.fieldId
        id: (edit && bulkModel.customFieldList) ? bulkModel.customFieldList[i]?.id : '',
        expenseId: bulkModel.expenseId || '',
        customFieldName: field.fieldName,
        customFieldValue: (edit && bulkModel.customFieldList) ? bulkModel.customFieldList[i]?.customFieldValue : '',
        fieldId: field.fieldId
      };
    });
    console.log('\n\n\n');
    console.log(expenseIndex);
    if (expenseIndex - 1 >= 0 && this.totalMileageBulkModel?.expenseRequestDTOList[expenseIndex - 1]) {
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].customFieldList = customFields;
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].showCustomFields = true;
      this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1].showAdditionalFields = true;
    }
    console.log(this.totalMileageBulkModel.expenseRequestDTOList[expenseIndex - 1]);
    console.log('\n\n\n');
    // }, expenseIndex == 1 ? 1000 : 0)
    // this.getMilageStates();

    return formGroupList;
  }

  closeviolation(){
    this.modalRef.hide();
  }


  updateBulkForm(e, form: FormGroup, fieldType: any, index: any,violationpopup? :TemplateRef<any>) {
    console.log('selected categoy',e);
    this.selectedCategoryIndex = index;
    if (fieldType == "categoryName") {

      form.controls.categoryName.patchValue(e.categoryName);
      form.controls.merchantName.patchValue("");
      form.controls.isPolicyCalcPerNight.patchValue(e.isPolicyCalcPerNight);
      form.controls.isSpentLocationManditory.patchValue(e.isSpentLocationManditory);
      if(e.isSpentLocationManditory){
        console.log(e.isSpentLocationManditory);
        form.controls.countryName.setValidators(Validators.required);
        console.log(form.controls.countryName.valid);
      } else {
        form.controls.countryName.clearValidators();
        form.controls.cityName.clearValidators();
        form.controls.cityName.updateValueAndValidity();
      }
      form.controls.countryName.updateValueAndValidity();
      form.controls.isStartEndDateEnabled.patchValue(e.isStartEndDateEnabled);
      
      if(e.isBillNumberManditory) {
        form.controls.invoiceNumber.setValidators(Validators.required);
      } else {
        form.controls.invoiceNumber.clearValidators();
      }
      form.controls.invoiceNumber.updateValueAndValidity();

      // category resstrion againt category level at master data

      let request = {
        'categoryName':e?.categoryName,
        'categoryRestricted':e?.categoryRestricted != null ? e?.categoryRestricted:null,
        'isCategoryRestricted':e?.isCategoryRestricted
      }

      this.categoryRes[index] = request;
      
      // if(e.isCategoryRestricted && e.categoryRestricted != null){
      //   const restrictedCategories = e.categoryRestricted.split(",");
      //   sessionStorage.setItem('categoryRestricted',JSON.stringify(restrictedCategories))
      //   this.markCategoriesAsDisabled(this.subCategoriesList,restrictedCategories);
      // }

      if(this.isCategoryRestricted(e.categoryName.split(' - ')[0])){
          const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg",
            keyboard: false,
          };
          this.modalRef = this.modalService.show(violationpopup, config);
          
          const formArray = this.totalBulkForm.get('bulkItems') as FormArray;
          const categoryNameC = formArray.at(index).get('categoryName');
          categoryNameC.reset();
          this.totalBulkModel.expenseRequestDTOList[index].categoryName = '';
          this.totalBulkModel.expenseRequestDTOList[index].categoryId = '';
          return;
      }      

      // if(e.isSpentLocationManditory) {
      //   form.controls.cityName.setValidators([Validators.required])
      // } else {
      //   form.controls.cityName.clearValidators();
      // }

      if(e.isStartEndDateEnabled) {
        form.controls.expenseEndDate.setValidators([Validators.required])
      } else {
        form.controls.expenseEndDate.clearValidators();
      }

      form.controls.cityName.updateValueAndValidity();
      form.controls.expenseEndDate.updateValueAndValidity();


      console.log('category selection:',form.controls);

          if((this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled  && !this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory  && this.totalBulkModel.expenseRequestDTOList[index].expenseEndDate != null) ||
             (this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory && !this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled) ||
             (this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory && this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled  && this.totalBulkModel.expenseRequestDTOList[index].expenseEndDate != null) ||
             (!this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled  && !this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory)){

              if (this.totalBulkModel.expenseRequestDTOList[index]?.categoryId != null && this.totalBulkModel.expenseRequestDTOList[index].expensedDate) {
                if (this.userInfo.isCategoryBasedEnabled) {
                  this.getCatPolicy(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'count', 'bulk');
                } else {
                  this.getPolicyApi(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'count', 'bulk');
                }
              }

          }

          this.showProjectCodes.expense = this.enableFields('Project Code', 'expense');

    } else if (fieldType == "currencyCode") {
      form.controls.currencyCode.patchValue(e.currencyCode);
    } else if (fieldType == "date") {
      if(this.dp_type == 'expenseEndDate') {
        form.controls.expenseEndDate.patchValue(
          this.convertDateToUtc(
            this.totalBulkModel.expenseRequestDTOList[index]?.expenseEndDate
          )
        );
      } else {
        form.controls.date.patchValue(
          this.convertDateToUtc(
            this.totalBulkModel.expenseRequestDTOList[index].expensedDate
          )
        );
        this.fomattedMinStartDate = this.convertDateToUtc(this.totalBulkModel.expenseRequestDTOList[index].expensedDate);
        // console.log('sdsdsd',this.fomattedMinStartDate);
      }
      // this.singleClaimDateValidation(index);
      console.log('expendate date :', this.totalBulkModel.expenseRequestDTOList[index].expensedDate);

      if ((this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled && !this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory && this.totalBulkModel.expenseRequestDTOList[index].expenseEndDate != null) ||
        (this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory && !this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled) ||
        (this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory && this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled && this.totalBulkModel.expenseRequestDTOList[index].expenseEndDate != null) ||
        (!this.totalBulkModel.expenseRequestDTOList[index].isStartEndDateEnabled && !this.totalBulkModel.expenseRequestDTOList[index].isSpentLocationManditory)) {

        if (this.totalBulkModel.expenseRequestDTOList[index]?.categoryId != null && this.totalBulkModel.expenseRequestDTOList[index].expensedDate) {
          if (this.userInfo.isCategoryBasedEnabled) {
            this.getCatPolicy(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'count', 'bulk');
          } else {
            this.getPolicyApi(this.totalBulkModel.expenseRequestDTOList[index].categoryId, this.totalBulkModel.expenseRequestDTOList[index].expensedDate, index, 'count', 'bulk');
          }
        }
      }
        
    }
  }

  isCategoryRestricted(categoryName: string): boolean {
    return this.categoryRes.some(category => {
      return category.categoryRestricted?.split(',').includes(categoryName) && category.isCategoryRestricted;
    });
  }


  markCategoriesAsDisabled(categories, categoriesToCheck) {
    categories.forEach(category => {
        const categoryName = category.categoryName.split(" - ")[0];
        if (categoriesToCheck.includes(categoryName)) {
            category.isDisable = true;
            this.markChildrenAsDisabled(category.children);
            this.markParentAsDisabled(category);
        } else {
            category.isDisable = false;
        }
        if (category.children && category.children.length > 0) {
            this.markCategoriesAsDisabled(category.children, categoriesToCheck);
        }
    });
}

  markChildrenAsDisabled(children) {
    children.forEach(child => {
        child.isDisable = true;
        if (child.children && child.children.length > 0) {
            this.markChildrenAsDisabled(child.children);
        }
    });
}

  markParentAsDisabled(category) {
    if (category.parentCategoryId) {
        const parentCategory = this.subCategoriesList.find(cat => cat.categoryId === category.parentCategoryId);
        if (parentCategory) {
            parentCategory.isDisable = true;
            this.markParentAsDisabled(parentCategory);
        }
    }
}



  updateMileageBulkForm(e, form: FormGroup, index: any) {
    // form.controls.expensedDate.patchValue(this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate);
  }

  openProofModal(proofPopup: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(proofPopup, config);
  }

  closeImgsModal() {
    if (this.platformType == 1) {
      this.modalRef.hide();
    }
  }

  async downloadTemplate() {
    let url =
      "https://noexpense-resource-dev.s3.ap-south-1.amazonaws.com/expenses-attachments/null/f08f9bd7-51ec-4b9d-9a2b-ab8b6b43d16c_bulkupload-mockup.csv";
    let errorStatus = this.commonService.downloadPdfUrl(url);
    if ((await errorStatus) != 0) {
      this.toastr.error("PDF is not found", "Error", { timeOut: 3000 });
    }
  }

  ruleCheckModel = new RuleCheckModel();

  categoryCheck() {
    let rule = this.policyList?.policyCategoryRuleList?.find(
      (elm) => elm.categoryId == this.expenseModel.categoryId
    );
    if (rule) {
      this.ruleCheckModel.categoryId = this.expenseModel.categoryId;
      this.ruleCheckModel.userId = this.userInfo.userId;
      this.ruleCheckModel.userGUID = this.userInfo.userGuId;
      this.ruleCheckModel.ruleType = rule.ruleType;
      this.ruleCheckModel.limitValue = rule.limitValue;
      this.commonService
        .getCategoryRule(this.ruleCheckModel)
        .subscribe((res) => {
          if (res && res?.payload) {
            this.categoryLimit = res.payload;
          } else {
            // this.toastr.error(res.errorMessage, 'Error');
          }
        });
    } else {
      return;
    }
  }

  /* bulk code end*/

  selectFromMap(e) {
    this.fromOrto = "from";
    this.showFromMapField = !this.showFromMapField;
    this.showDistanceFromMapField = false;
    this.fromCitiesList = [];
    setTimeout(() => {
      this.googleSearchAutoComplete.nativeElement.focus();
    }, 100);
  }

  selectMileageFromMap(e) {
    this.fromOrto = "from";
    this.showFromLocation = !this.showFromLocation;
    this.showDistanceFromMapField = false;
    this.fromCitiesList = [];
    setTimeout(() => {
      this.mileageFromGoogleSearch.nativeElement.focus();
    }, 0);
  }

  selectMileageToMap(e) {
    this.fromOrto = "from";
    this.showEndLocation = !this.showEndLocation;
    this.showDistanceFromMapField = false;
    this.fromCitiesList = [];
    setTimeout(() => {
      this.mileageToGoogleSearch.nativeElement.focus();
    }, 100);
  }

  // perdiemselectFromMap(e) {
  //   this.fromOrto = "from";
  //   this.perdiemshowFromMapField = !this.perdiemshowFromMapField;
  //   this.fromCitiesList = [];
  //   setTimeout(() => {
  //     this.perdiemgoogleSearchAutoComplete.nativeElement.focus();
  //   }, 100);
  // }


  onToFocus(e) {
    e.preventDefault();
    e.stopPropagation();
    this.toCitiesList = [];
    // this.toCitiesList = this.policyTravelModel.country;
  }

  googleAutoCompleteSearch(val) {

    let searchText = val.currentTarget.value.trim();
    this.locationSearchText.next(searchText);
  }

  googleAutoCompleteResult(val) {
    let searchText = val;
    let domesticpredictions = [];

    if (searchText.length > 2) {
      this.showcitySearchListSpinner = true;
      const displaySuggestions = (predictions, status) => {
        // if (status == google.maps.places.PlacesServiceStatus.OK) {
        //   this.showcitySearchListSpinner = false;

        //   predictions.forEach((prediction) => {
        //     domesticpredictions.push(prediction);
        //   });
        //   var dcountry = this;
        //   if (this.fromOrto == "from") {
        //     dcountry.fromCitiesList = domesticpredictions;
        //   } else {
        //     dcountry.toCitiesList = domesticpredictions;
        //   }
        // }
      };
      if (this.sessionTokenEnable) {
        // this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        this.sessionTokenEnable = false;
      }
      this.requestType = {
        input: searchText,
        // types: ['(cities)'],
        // componentRestrictions: { country: "IN" },
        sessionToken: this.sessionToken,
      };

      // const service = new google.maps.places.AutocompleteService();
      // service.getPlacePredictions(this.requestType, displaySuggestions);
    }
  }
  googleAutoCompleteSearchOnlyIndia(val) {

    let searchText = val.currentTarget.value.trim();
    this.locationSearchText.next(searchText);
  }

  googleAutoCompleteResultOnlyIndia(val) {
    let searchText = val;
    let domesticpredictions = [];

    if (searchText.length > 2) {
      this.showcitySearchListSpinner = true;
      const displaySuggestions = (predictions, status) => {
        // if (status == google.maps.places.PlacesServiceStatus.OK) {
        //   this.showcitySearchListSpinner = false;

        //   predictions.forEach((prediction) => {
        //     domesticpredictions.push(prediction);
        //   });
        //   var dcountry = this;
        //   if (this.fromOrto == "from") {
        //     dcountry.fromCitiesList = domesticpredictions;
        //   } else {
        //     dcountry.toCitiesList = domesticpredictions;
        //   }
        // }
      };
      if (this.sessionTokenEnable) {
        // this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        this.sessionTokenEnable = false;
      }
      this.requestType = {
        input: searchText,
        // types: ['(cities)'],
        componentRestrictions: { country: "IN" },
        sessionToken: this.sessionToken,
      };

      // const service = new google.maps.places.AutocompleteService();
      // service.getPlacePredictions(this.requestType, displaySuggestions);
    }
  }

  addCityandCountry(event, element: any, From: string) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedLocationId = element.place_id;
    this.geocodeAddress(element.description, element.place_id, From);
    if (this.active == "middle") {
      this.resetMileageForm();
      this.getmastCategorylist();
    }
  }

  addMileageFromLocation(event, element: any, From: string) {
    event.preventDefault();
    event.stopPropagation();
    if (From == 'mileageFrom') {
      this.expenseModel.fromLocationName = element.description;
      this.expenseModel.Flocation = element.description
      this.mileageForm.controls.fromLocationName.patchValue(element.description);
    } else {
      this.expenseModel.toLocationName = element.description;
      this.expenseModel.Tlocation = element.description
      this.mileageForm.controls.toLocationName.patchValue(element.description);
    }
    this.geocodeAddress(element.description, element.place_id, From);
  }

  geocodeAddress(location: string, place_id: any, From: string) {
    this.geocoder = new google.maps.Geocoder();
    this.geocoder.geocode({ address: location }, (results, status) => {
      this.ngZone.run(() => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (From == "nondistance") {
            this.expenseModel.location = location;
            this.showFromMapField = false;
          } else if (From == "mileageFrom") {
            this.expenseModel.fromLangitude = results[0].geometry.location.lng();
            this.expenseModel.fromLatitude = results[0].geometry.location.lat();
            this.showFromLocation = false;
            if (this.expenseModel.Flocation != '' && this.expenseModel.Tlocation != '') {
              this.getDistanceFromMapMyIndia();
            }
          } else if (From == "mileageTo") {
            this.expenseModel.toLangitude = results[0].geometry.location.lng();
            this.expenseModel.toLatitude = results[0].geometry.location.lat();
            this.showEndLocation = false;
            if (this.expenseModel.Flocation != '' && this.expenseModel.Tlocation != '') {
              this.getDistanceFromMapMyIndia();
            }
          } else if (From == "distancefrom") {
            this.distancefrom = results[0].address_components[0].long_name;
            this.showDistanceFromMapField = false;
            this.distancefromobj.location =
              results[0].address_components[0].long_name;
            this.distancefromobj.lat = results[0].geometry.location
              .lat()
              .toString();
            this.distancefromobj.lang = results[0].geometry.location
              .lng()
              .toString();
          } else if (From == "distanceto") {
            this.distanceto = results[0].address_components[0].long_name;
            this.distancetoobj.location =
              results[0].address_components[0].long_name;
            this.distancetoobj.lat = results[0].geometry.location
              .lat()
              .toString();
            this.distancetoobj.lang = results[0].geometry.location
              .lng()
              .toString();
            this.showToMapField = false;
          } else if (From == "perdiem") {
            this.perdiemModel.PDlocation = location;
            this.perdiemModel.PDlocationId = place_id;
            this.showToMapField = false;
            this.perdiemRateList = [];
            let selectedCountryList = [];
            let chekingVariable = true;
            let SelectedcountryCode = ''
            let address_components = results[0].address_components;
            let address = address_components.filter(r => {
              if (r.types[0] == 'country') {
                return r;
              }
            }).map(r => {
              SelectedcountryCode = r.short_name;
              return r.short_name;
            })
            this.policyList.policyPerDiemList.forEach((element) => {
              if (SelectedcountryCode == element.countryCode) {
                selectedCountryList.push(element);
              }
            });

            if (selectedCountryList.length == 0) {
              this.isSelectedLocationConfigered = true;
            } else {
              this.isSelectedLocationConfigered = false;
              selectedCountryList.forEach((element) => {
                if (element.cityId == this.perdiemModel.PDlocationId) {
                  chekingVariable = false;
                  this.selectedPolicyPerdiem = element;
                  element.isAllowedWithReceipt == true ? this.addPerdiemRateList("Rate with Receipt") : "";
                  element.isAllowedWithoutReceipt == true ? this.addPerdiemRateList("Rate without Receipt") : "";
                }
              });
            }

            if (chekingVariable) {
              selectedCountryList.forEach((element) => {
                if (element.cityName == null && element.cityId == null) {
                  chekingVariable = false;
                  this.selectedPolicyPerdiem = element;
                  element.isAllowedWithReceipt == true ? this.addPerdiemRateList("Rate with Receipt") : "";
                  element.isAllowedWithoutReceipt == true ? this.addPerdiemRateList("Rate without Receipt") : "";
                }
              });
            } else {
              this.isSelectedLocationConfigered = false;
            }

            if (chekingVariable) {
              this.isSelectedLocationConfigered = true;
            } else {
              this.isSelectedLocationConfigered = false;
            }

          } else if (From == "perdiemSpentLocation") {
            this.perdiemModel.PDspentLocation = location;
            this.perdiemModel.PDspentLocationId = place_id;
            this.perdiemshowFromMapField = false;
          }
        }
      });
    });

    setTimeout(() => {
      if (
        this.distancetoobj.lat !== undefined &&
        this.distancefromobj.lat !== undefined
      ) {
        this.distance(
          this.distancefromobj.lat,
          this.distancetoobj.lat,
          this.distancefromobj.lang,
          this.distancetoobj.lang
        );
      } else {
        // this.expenseModel.originalDistance = 0;
        // this.expenseModel.distance = 0;
      }
    }, 1000);
  }

  async openCityModal(from?) {
    const searchItemModal = await this.modalController.create({
      component: SelectLocationComponent,
      componentProps: {},
      keyboardClose: false,
    });
    searchItemModal.onDidDismiss().then((callback) => {
      if (callback && callback.data) {
        if (from == 'from') {
          this.expenseModel.fromLocationName = callback.data[0].formatted_address;
          this.expenseModel.fromLatitude = callback.data[0].geometry.location.lat();
          this.expenseModel.fromLangitude = callback.data[0].geometry.location.lng();
          this.expenseModel.Flocation = callback.data[0].formatted_address;
          if (this.expenseModel.fromLocationName != '' && this.expenseModel.toLocationName != '') {
            this.getDistanceFromMapMyIndia();
          }
        } else if (from == 'to') {
          this.expenseModel.toLocationName = callback.data[0].formatted_address;
          this.expenseModel.toLatitude = callback.data[0].geometry.location.lat();
          this.expenseModel.toLangitude = callback.data[0].geometry.location.lng();
          this.expenseModel.Tlocation = callback.data[0].formatted_address;

          if (this.expenseModel.fromLocationName != '' && this.expenseModel.toLocationName != '') {
            this.getDistanceFromMapMyIndia();
          }
        } else if (from == 'perdiem') {
          this.perdiemModel.PDlocation = callback.data[0].formatted_address;;
          this.perdiemModel.PDlocationId = callback.data[0].place_id;

          this.perdiemRateList = [];
          let selectedCountryList = [];
          let chekingVariable = true;
          let SelectedcountryCode = ''
          let address_components = callback.data[0].address_components;
          let address = address_components.filter(r => {
            if (r.types[0] == 'country') {
              return r;
            }
          }).map(r => {
            SelectedcountryCode = r.short_name;
            return r.short_name;
          })
          this.policyList.policyPerDiemList.forEach((element) => {
            if (SelectedcountryCode == element.countryCode) {
              selectedCountryList.push(element);
            }
          });
          if (selectedCountryList.length == 0) {
            this.isSelectedLocationConfigered = true;
          } else {
            this.isSelectedLocationConfigered = false;
            selectedCountryList.forEach((element) => {
              if (element.cityId == this.perdiemModel.PDlocationId) {
                chekingVariable = false;
                this.selectedPolicyPerdiem = element;
                element.isAllowedWithReceipt == true ? this.addPerdiemRateList("Rate with Receipt") : "";
                element.isAllowedWithoutReceipt == true ? this.addPerdiemRateList("Rate without Receipt") : "";
              }
            });
          }

          if (chekingVariable) {
            selectedCountryList.forEach((element) => {
              if (element.cityName == null && element.cityId == null) {
                chekingVariable = false;
                this.selectedPolicyPerdiem = element;
                element.isAllowedWithReceipt == true ? this.addPerdiemRateList("Rate with Receipt") : "";
                element.isAllowedWithoutReceipt == true ? this.addPerdiemRateList("Rate without Receipt") : "";
              }
            });
          } else {
            this.isSelectedLocationConfigered = false;
          }

          if (chekingVariable) {
            this.isSelectedLocationConfigered = true;
          } else {
            this.isSelectedLocationConfigered = false;
          }
        } else {
          this.expenseModel.location = callback.data[0].formatted_address;
        }
      }
    });
    await searchItemModal.present();
  }

  hideUnhideViolations() {
    this.showMoreViolations = !this.showMoreViolations;
    if (this.showMoreViolations) {
      this.displayViolations = JSON.parse(
        JSON.stringify(this.editlist?.violationList)
      );
    } else {
      this.displayViolations = [];
      this.editlist?.violationList.forEach((item, i) => {
        if (i < 4) {
          this.displayViolations.push(item);
        }
      });
    }
  }

  distance(lat1, lat2, lon1, lon2) {
    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = (lon1 * Math.PI) / 180;
    lon2 = (lon2 * Math.PI) / 180;
    lat1 = (lat1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    let c = 2 * Math.asin(Math.sqrt(a));
    // Radius of earth in kilometers. Use 3956 for miles
    let r = 6371;
    // calculate the result
    this.distanceVal = (c * r).toFixed(); //--this variable is not used anywhere
    this.totaldistance = (c * r).toFixed();
    this.expenseModel.distance = parseInt(this.totaldistance);
    this.expenseModel.originalDistance = parseInt(this.totaldistance);
  }

  distancefrommap() {
    if (
      this.distancetoobj.lat !== undefined &&
      this.distancefromobj.lat !== undefined
    ) {
      // this.expenseModel.distance = parseInt(this.totaldistance);
      this.expenseModel.originalDistance = parseInt(this.totaldistance);
      this.modalRef.hide();
    } else {
      this.toastr.error("Please select from and to locations");
    }
  }

  removeGoogleocationdetails(fromOrTo) {
    if (fromOrTo == "from") {
      this.distancefrom = "";
      this.distancefromobj = new DistanceobjetModel();
    } else {
      this.distanceto = "";
      this.distancetoobj = new DistanceobjetModel();
    }
    this.expenseModel.originalDistance = 0;
    this.expenseModel.distance = 0;
  }

  // private _filter(value: string): string[] {
  //     const filterValue = value.toLowerCase();

  //     return this.options.filter(option => option.toLowerCase().includes(filterValue));
  //   }
  // }

  tempUserList: any = [];

  trimUsersSearch(searchTxt: any) {
    let searchText = searchTxt.trim();
    if (this.tempUserList != null && searchText != "") {
      if (searchText != "") {
        let filterdata = this.tempUserList.filter((data) => {
          if (
            data.userName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
          ) {
            return data;
          }
        });
        this.allUserList = filterdata;
      } else {
        this.allUserList = this.categorytempList;
      }
    }
  }

  searchUser(e: any) {
    this.searchFreetext = e.target.value;
    this.searchFreetext = e.target.value.toLowerCase();
    this.trimUsersSearch(this.searchFreetext);
    const departmentHeadRequestPayload = {
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
      searchText: this.searchFreetext, //e.target.value
    };
    if (this.searchFreetext && this.searchFreetext.length >= 3) {
      this.settingService
        .getUsersList(JSON.stringify(departmentHeadRequestPayload))
        .subscribe((response) => {
          if (response?.payload?.users.length > 0) {
            this.allUserList = response.payload.users;
            this.tempUserList = response.payload.users;
          }
        });
    }
  }
  defaultSearchUser() {
    const departmentHeadRequestPayload = {
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
      searchText: "",
    };
    this.settingService
      .getUsersList(JSON.stringify(departmentHeadRequestPayload))
      .subscribe((response) => {
        if (response?.payload?.users.length > 0) {
          this.allUserList = response.payload.users;
          this.tempUserList = response.payload.users;
        }
      });
  }
  addCorporateUser(user: any, e?: any) {
    e?.preventDefault();
    e?.stopPropagation();
    let ind = this.selectedUserList.findIndex(
      (ele) => ele.userId == user.userId
    );
    if (ind != -1) {
      return;
    } else {
      // let userdetail = new SplitExpenseListModel(user);
      this.resetSplitExtractAmount = true;
      this.resetSplitExtractAmountPer = true;
      this.selectedUserList.push(user);
      this.onTabChanged();
    }
    // this.splitExpense();
  }

  removeCorporateUser(index, e) {
    e.stopPropagation();
    if (index === 0) {
      this.toastr.error(`can't remove main user`);
      return;
    }
    this.resetSplitExtractAmount = true;
    this.resetSplitExtractAmountPer = true;
    this.selectedUserList.splice(index, 1);
    this.secondTabList = [];
    this.thirdTabList = [];
    // this.expenseModel.expenseUserSplitList.splice(index, 1);
    this.onTabChanged();
  }
  onTabChanged(e?) {
    this.matTabIndex = e ? e.index : 0;
    // console.log(this.matTabIndex);

    switch (this.matTabIndex) {
      case 0:
        this.splitExpense();
        break;
      case 1:
        this.shareAmount();
        break;
      case 2:
        if (
          this.expenseModel?.originalAmount &&
          this.expenseModel?.originalAmount != 0
        ) {
          console.log('IFIFIF');

          // this.selectedUserList.forEach((elm) => {
          //   elm.percentage =
          //     ((elm?.amount ? elm?.amount : 0) * 100) /
          //     this.expenseModel?.originalAmount;
          //   this.expenseModel.expenseUserSplitExPerList.push(elm);
          // });
          this.sharePerc();
        }
        break;
    }
  }

  splitExpense() {
    if (!this.splitAmountBooelan) {
      this.divAmt =
        (this.expenseModel?.originalAmount
          ? this.expenseModel?.originalAmount
          : 0) / this.selectedUserList.length;
      this.expenseModel.splitType = 1;
      this.expenseModel.expenseUserSplitList = [];
      this.selectedUserList.forEach((elm) => {
        elm.amount = this.divAmt;
        this.expenseModel.expenseUserSplitList.push(elm);
      });
    }
    this.expenseModel.expenseUserSplitList = this.selectedUserList;
  }

  shareAmount() {
    if (!this.expenseModel.originalAmount) {
      //if amount is not entered, then no need to go through splitting
      return;
    }
    if (this.secondTabList.length == 0) {
      let divAmt =
        (this.expenseModel?.originalAmount
          ? this.expenseModel?.originalAmount
          : 0) / this.selectedUserList.length;
      this.selectedUserList.forEach((element) => {
        //  console.log("selectedUserList", this.selectedUserList)
        let request = {
          userId: element.userId,
          title: element.title,
          userName: element.userName,
          firstName: element.firstName,
          lastName: element.lastName,
          emailId: element.emailId,
          mobile: element.mobile,
          dateOfBirth: element.dateOfBirth,
          userLogo: element.userLogo,
          amount: divAmt,
          id: element.id,
          employeeId: element.employeeId
        };
        this.secondTabList.push(request);
      });
    } else {
      if (this.resetSplitExtractAmount) {
        this.secondTabList = [];
        let divAmt =
          (this.expenseModel?.originalAmount
            ? this.expenseModel?.originalAmount
            : 0) / this.selectedUserList.length;
        this.selectedUserList.forEach((element) => {
          let request = {
            userId: element.userId,
            title: element.title,
            userName: element.userName,
            firstName: element.firstName,
            lastName: element.lastName,
            emailId: element.emailId,
            mobile: element.mobile,
            dateOfBirth: element.dateOfBirth,
            userLogo: element.userLogo,
            amount: divAmt,
            id: element.id,
            employeeId: element.employeeId
          };
          this.secondTabList.push(request);
        });
        this.resetSplitExtractAmount = false;
      }
      var tempSum = this.secondTabList.reduce((a, b) => a + +b.amount, 0);
      this.totalinputAmt = tempSum;
      if (Math.round(tempSum) != this.expenseModel.originalAmount) {
        // this.leftAmt = tempSum;
        this.leftAmt = this.expenseModel.originalAmount - this.totalinputAmt;
        return (this.amtError = true);
      } else {
        this.amtError = false;
        this.leftAmt = 0;
      }
    }
    // if(!this._splitExractAmount){
    //   console.log("if condition is calling",this.selectedUserList);
    //   this._splitExractAmount = true;
    //   var tempSum = this.selectedUserList.reduce((a, b) => a + +b.amount, 0);
    // this.totalinputAmt = tempSum;
    // if (Math.round(tempSum) != this.expenseModel.originalAmount) {
    //   // this.leftAmt = tempSum;
    //   this.leftAmt = this.expenseModel.originalAmount - this.totalinputAmt;
    //   return (this.amtError = true);
    // } else {
    //   this.amtError = false;
    //   this.leftAmt = 0;
    // }
    // this.expenseModel.splitType = 2;
    // this.expenseModel.expenseUserSplitExList = [];
    // this.selectedUserList.forEach((elm) => {
    //   this.expenseModel.expenseUserSplitExList.push(elm);
    // });
    // }else{
    //   console.log("else condition is called");
    // }
    this.expenseModel.splitType = 2;
    this.expenseModel.expenseUserSplitExList = [];
    this.expenseModel.expenseUserSplitList = this.secondTabList;
  }

  sharePerc() {
    if (!this.expenseModel.originalAmount) {
      //if amount is not entered, then no need to go through splitting
      return;
    }
    if (this.thirdTabList.length == 0) {
      let divAmt =
        (this.expenseModel?.originalAmount
          ? this.expenseModel?.originalAmount
          : 0) / this.selectedUserList.length;
      this.selectedUserList.forEach((element) => {
        let request = {
          userId: element.userId,
          title: element.title,
          userName: element.userName,
          firstName: element.firstName,
          lastName: element.lastName,
          emailId: element.emailId,
          mobile: element.mobile,
          dateOfBirth: element.dateOfBirth,
          userLogo: element.userLogo,
          amount: divAmt,
          id: element.id,
        };
        this.thirdTabList.push(request);
      });
      this.thirdTabList.forEach((elm) => {
        elm.percentage =
          ((elm?.amount ? elm?.amount : 0) * 100) /
          this.expenseModel?.originalAmount;
        elm.amount =
          ((elm?.percentage ? elm?.percentage : 0) / 100) *
          (this.expenseToalAmt
            ? this.expenseToalAmt
            : this.expenseModel?.originalAmount
              ? this.expenseModel?.originalAmount
              : 0);
      });
    } else {
      if (this.resetSplitExtractAmountPer) {
        this.thirdTabList = [];
        let divAmt =
          (this.expenseModel?.originalAmount
            ? this.expenseModel?.originalAmount
            : 0) / this.selectedUserList.length;
        this.selectedUserList.forEach((element) => {
          let request = {
            userId: element.userId,
            title: element.title,
            userName: element.userName,
            firstName: element.firstName,
            lastName: element.lastName,
            emailId: element.emailId,
            mobile: element.mobile,
            dateOfBirth: element.dateOfBirth,
            userLogo: element.userLogo,
            amount: divAmt,
            id: element.id,
            employeeId: element.employeeId
          };
          this.thirdTabList.push(request);
        });
        this.thirdTabList.forEach((elm) => {
          elm.percentage =
            ((elm?.amount ? elm?.amount : 0) * 100) /
            this.expenseModel?.originalAmount;
          elm.amount =
            ((elm?.percentage ? elm?.percentage : 0) / 100) *
            (this.expenseToalAmt
              ? this.expenseToalAmt
              : this.expenseModel?.originalAmount
                ? this.expenseModel?.originalAmount
                : 0);
        });
        this.resetSplitExtractAmountPer = false;
      }
      this.thirdTabList.forEach((elm) => {
        elm.amount =
          ((elm?.percentage ? elm?.percentage : 0) / 100) *
          (this.expenseToalAmt
            ? this.expenseToalAmt
            : this.expenseModel?.originalAmount
              ? this.expenseModel?.originalAmount
              : 0);
      });

      var tempPercSum = this.thirdTabList.reduce(
        (a, b) => a + (+b?.percentage ? +b?.percentage : 0),
        0
      );
      this.totalinputPerc = tempPercSum;
      if (+tempPercSum > 100) {
        this.leftPerc = tempPercSum;
        this.percError = true;
      } else {
        this.percError = false;
        this.leftPerc = 100 - +this.totalinputPerc;
      }
    }

    this.expenseModel.splitType = 3;
    this.expenseModel.expenseUserSplitExPerList = [];
    this.expenseModel.expenseUserSplitList = this.thirdTabList;
  }

  numberValidation(event: any, isTime?) {
    const pattern = this.userInfo?.roundOffClaimAmount ? /^[0-9!@#$%^&*()-]+$/ : /^[0-9!.@#$%^&*()-]+$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }


  trimValidation(event: any) {
    const inputField = event?.target as HTMLInputElement;
    const inputChar = String?.fromCharCode(event?.charCode);
  
    // Prevent entering a space if the cursor is at the beginning of the input field
    if (inputChar === ' ' && inputField?.selectionStart === 0) {
      event.preventDefault();
    } else {
      const pattern = /^[a-zA-Z0-9!.@#$%^&*()-\s]+$/; // Allow space in the pattern
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }

  approveAValidation(value: any) {
    if (value > this.expenseModel.originalAmount) {
      this.approveAmountValidation = true;
    } else {
      this.approveAmountValidation = false;
    }
  }
  taxPValidation(value: any) {
    if (value > 100) {
      this.taxPercentageValidation = true;
    } else {
      this.taxPercentageValidation = false;
    }
  }

  taxAValidation(value: any) {
    if (value > Number(this.expenseModel.originalAmount)) {
      this.taxAmountValidation = true;
    } else {
      this.taxAmountValidation = false;
    }
  }


  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  numberToFixed(x, item) {
    if (item.distance && item.distance != '' && item.mileageRate && item.mileageRate != '') {
      item.originalAmount = parseFloat(item.distance.toString()) * parseFloat(item.mileageRate.toString());
      x = item.originalAmount;
    }
    if (x != undefined && !Number.isNaN(x)) {
      var num = Number(x); // The Number() only visualizes the type and is not needed
      var roundedString = num.toFixed(2);
      var rounded = Number(roundedString); // toFixed() returns a string (often suitable for printing already)
      return rounded;
    } else {
      return 0;
    }
  }

  closemileagePicker() {
    this.mileagepicker.open();
    // setTimeout(() => {
    //     this.currencyAutoCompleteReference.nativeElement.focus();
    // }, 100);
  }

  showSubMenu(field?) {
    this.closeOptionsPopup = false;
    this.currentField = field;
    // this.mouseover = false;
  }

  setShowdatePicker() {
    this.showDatePicker = true;
  }


  insideExpense = false;
  onContentScrolled(e) {
    // this.showDatePicker = false;
    // setTimeout(() => {
    //   if(this.clickedDatePicker) {
    //     this.showDatePicker = true;
    //     this.clickedDatePicker = false;
    //   }
    // }, 100)
    if(!this.insideExpense) {
      this.customField = '';
    }
    this.exppickerbulk_i?.close();
    this.customPicker_i?.close();
    this.fromdate?.close();
    this.todate?.close();
    this.mileagepicker_i?.close();
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;
    this.closeOptionsPopup = this.mouseover ? false : true;
    if (scroll > this.currentPosition) {
      this.exppicker?.close();
      this._perdiemStartDate?.close();
      this._perdiemEndDate?.close();
      this.expensePickerOp = false;
      //this.showCurrencyList = false;
      this.showFromMapField = false;
      setTimeout(() => {
        this.categoryAutoCompleteReference?.nativeElement?.focus();
      }, -100);
      for (
        let i = 0;
        i < this.totalBulkModel.expenseRequestDTOList.length;
        i++
      ) {
        this.totalBulkModel.expenseRequestDTOList[i].showList = this.mouseover ? true : false;
      }
    } else {

      this.exppicker?.close();
      this._perdiemStartDate?.close();
      this._perdiemEndDate?.close();
      this.expensePickerOp = false;
      //this.showCurrencyList = false;
      for (
        let i = 0;
        i < this.totalBulkModel.expenseRequestDTOList.length;
        i++
      ) {
        this.totalBulkModel.expenseRequestDTOList[i].showList = this.mouseover ? true : false;
      }
    }
    this.currentPosition = scroll;
  }

  resetForm() {
    this.receiptWarning = false;
    this.receiptCatWarning = false;
    this.categDesc = false;
    this.expenseWarning = false;
    this.expenseCatWarning = false;
    this.selectedUserList = [];
    this.expenseModel.expenseAttachments = [];
    this.expenseModel.expenseUserSplitList = [];
    // this.expenseModel.originalCurrencyId = undefined;
    // this.expcurrencySymbol = undefined;
    // this.expcurrencyIcon = undefined;
    // this.expConvRate = '';
    if (this.active == "top") {
      this.expenseForm.reset();
      this.expenseModel = new ExpenseModel();
      this.expenseForm.controls.originalCurrencyCode.patchValue(this.userInfo.baseCurrency.currencyCode);
      this.mileageForm.controls.originalCurrencyCode.patchValue(this.userInfo.baseCurrency.currencyCode);
      this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
      this.milecurrencySymbol = this.userInfo.baseCurrency.currencyIcon;
      this.expenseModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
      this.expenseModel.originalCurrencyName = this.userInfo.baseCurrency.currencyCode;
    }
    if (this.active == "middle") {
      this.mileageForm.reset();
      this.expenseModel = new ExpenseModel();
      this.expenseForm.controls.originalCurrencyCode.patchValue(this.userInfo.baseCurrency.currencyCode);
      this.mileageForm.controls.originalCurrencyCode.patchValue(this.userInfo.baseCurrency.currencyCode);
      this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
      this.milecurrencySymbol = this.userInfo.baseCurrency.currencyIcon;
      this.expenseModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
      this.expenseModel.originalCurrencyName = this.userInfo.baseCurrency.currencyCode;
    } else {
      this.totalBulkForm.reset();
      this.bulkFormValidations(1);
    }
    this.removeImage();
    this.resetForNavChange();
    this.modalRef.hide();
    this.addCorporateUser({
      emailId: this.userInfo.email,
      userId: this.userInfo.userId,
      userLogo: this.userInfo.profilePicPath,
      userName: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
    });
    this.expenseModel.expenseUserSplitList = [];
    this.selectedUserList.forEach((elm) => {
      elm.amount = 0;
      this.expenseModel.expenseUserSplitList.push(elm);
    });
  }
  resetClose(from?) {
    if (from == 'attachment') {
      this.closeAttachmentDeleteModal();
    } else {
      this.modalRef.hide();
    }
  }

  openCurrencyDropDown(e, index?) {
    e.preventDefault();
    e.stopPropagation();
    this.showCurrencyList = true;
    this.currencyList = this.currencytempList;
    // this.totalBulkModel.expenseRequestDTOList.forEach(index,element => {
    //     if(this.selectedIndex == index){
    //         element.showList = true;
    //     }else{
    //         element.showList = false;
    //     }
    // });
    setTimeout(() => {
      this.currencyAutoCompleteReference.nativeElement.focus();
    }, 0);
  }

  openFinanceDropDown(e, index?) {
    console.log('method clicked');
    e.preventDefault();
    e.stopPropagation();
    this.showFinanceCodeList = true;
    console.log('mileage model :', this.MileagebulkItemsForms, this.totalMileageBulkModel);
    this.financeIntCodeList = this.financeIntCodeTempList;
    setTimeout(() => {
      this.financeIntCodefocusOff.nativeElement.focus();
    }, 0);
  }

  openTaxCodeDropDown(e, index?) {
    e.preventDefault();
    e.stopPropagation();
    this.showTaxCodeList = true;
    this.taxCodeList = this.taxCodeTempList;
    setTimeout(() => {
      this.taxCodefocusOff.nativeElement.focus();
    }, 0);
  }

  openHsnCodeDropDown(e, index?) {
    e.preventDefault();
    e.stopPropagation();
    this.showHsnCodeList = true;
    this.hsnCodeList = this.hsnCodeTempList;
    setTimeout(() => {
      this.hsnCodefocusOff.nativeElement.focus();
    }, 0);
  }

  onCurrencyEventFocus() {
    setTimeout(() => {
      this.currencyList = this.currencytempList;
    }, 0);
  }

  onFinanceEventFocus() {
    setTimeout(() => {
      this.financeIntCodeList = this.financeIntCodeTempList;
    }, 0);
  }

  onTaxCodeEventFocus() {
    setTimeout(() => {
      this.taxCodeList = this.taxCodeTempList;
    }, 0);
  }

  onHsnCodeEventFocus() {
    setTimeout(() => {
      this.hsnCodeList = this.hsnCodeTempList;
    }, 0);
  }
  onClickedOutsideCurrency() {
    this.showCurrencyList = false;
    for (let i = 0; i < this.totalBulkModel.expenseRequestDTOList.length; i++) {
      this.totalBulkModel.expenseRequestDTOList[i].showList = false;
    }
  }

  closeReport() {
    this.addReport = false;
    this.expenseModel.claimName = null;
    this.expenseForm.controls.claimName.patchValue("");
    this.mileageForm.controls.claimName.patchValue("");
  }

  onClickedOutsideLocation() {
    this.showFromMapField = false;
  }
  onClickedOutsideExpenseDate() {
    this.mileagepicker.close();
    this.mileagePickerOp = false;
  }

  onClickedOutsideReport() {
    this.matFormFieldParentElement.nativeElement.focus();
  }

  closeExpenseDate() {
    this.exppicker.close();
    this.expensePickerOp = false;
  }

  // onClickedOutsidebulkReport(){
  //     setTimeout(() => {
  //         this.bulkmatFormFieldParent.nativeElement.focus();
  //      }, 100);
  // }

  mileageExpenseCheckAll(value?) {
    this.matTabIndex = 0;
    if (this.mileagepicker) {
      this.mileagepicker.close();
    }
    if (value) {
      this.totalBulkModel.expenseRequestDTOList[value].showList = false;
    }
  }

  hideTimeDropDown(event: any) {
    let el = <HTMLElement>event.target;
    if (el.id != "") {
      this.prevenEvent(event);
    } else {
      if (this.exppicker) {
        this.exppicker.close();
        this.expensePickerOp = false;
      }
    }
  }

  hideTimeDropDown1(event: any) {
    let el = <HTMLElement>event.target;
    if (el.id != "") {
      this.prevenEvent(event);
    } else {
      this.mileagepicker?.close();
      this.mileagePickerOp = false;
    }
  }

  hideTimeDropDown2(event: any) {
    let el = <HTMLElement>event.target;
    this.bulkExpenseDate = el.id;
    if (el.id != "" && this.bulkExpenseDate) {
      this.prevenEvent(event);
    } else {
      // this.exppickerbulk.close();
    }
  }

  hideTimeDropDown4(event: any) {
    let el = <HTMLElement>event.target;
    if (el.id != "") {
      this.prevenEvent(event);
    } else {
      if (this._perdiemStartDate) {
        this._perdiemStartDate.close();
        this.perdiemStartD = false;
      }
      if (this._perdiemEndDate) {
        this._perdiemEndDate.close();
        this.perdiemEndD = false;
      }
    }
  }

  prevenEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  resetMileageForm() {
    this.expenseModel.originalAmount = undefined;
    this.expenseModel.categoryId = undefined;
    this.expenseModel.mileageRate = undefined;
    this.expenseModel.mileageUnit = undefined;
    // this.mileageForm.controls.categoryName.patchValue(undefined);
    // this.mileageForm.controls.mileageRate.patchValue(undefined);
  }

  getTripDetails(frompath, expenseDetails?) {
    let _getTripDetails = [];
    console.log('from path :',frompath,this.categoryList);
    if (frompath == 'expensesTrip') {
      this.expenseArray.forEach(element => {
        let rquest_object = {
          legalEntityId: this.userInfo.legalEntityId,
          legalEntityGUID: this.userInfo.legalEntityGuId,
          tripId: element.tripId,
          tripGUID: element.tripGUID,
          productId: element.productId,
          requestId: element.requestId,
          requestGUID: element.requestGUID,
        }
        _getTripDetails.push(rquest_object);

      });
    } else {
      this.selectedTrips.forEach(element => {
        let rquest_object = {
          legalEntityId: this.userInfo.legalEntityId,
          legalEntityGUID: this.userInfo.legalEntityGuId,
          tripId: element.tripId,
          tripGUID: element.tripGUID,
          productId: element.productId,
          requestId: element.requestId,
          requestGUID: element.requestGUID,
          serviceList: element.serviceList,
        }
        _getTripDetails.push(rquest_object);

      });
    }

    // IF U R CRATEING TRIP CLAIM FIRST ADD EXPENSE ARRAY ITEM TO SELECTEDTRIPS LIST_STYLE_POSITION SELECTEDTRIPS IS COMMON ARRAY
    if(frompath == 'expensesTrip' && this.expenseArray.length != 0){
      this.selectedTrips = [];
      this.selectedTrips = [...this.expenseArray];
    }

      this.BindingMyTripsIntoForm(true);
  }

  BindingMyTripsIntoForm(type?){
    if(JSON.parse(sessionStorage.getItem('catList')) != null){
      this.categoryList = JSON.parse(sessionStorage.getItem('catList'));
    }

    console.log(this.selectedTrips);

    if (this.selectedTrips[0]?.serviceList?.length != 0) {
      if (this.navInfoData?.url == 'expensesTrip' && !this.isAssociated && !this.bulkAlreadyRemoved && !this.navInfoData?.isAssociated) {
        this.removeBulkItems('Expense', 0);
        this.firstTimeIndexRemoved = true;
      }
      this.selectedTrips[0]?.serviceList?.forEach((item, index) => {

        if(this.userInfo?.isEnableClaimTypes) {
          // claimtype auto prepopulate from trips selection
          let cTypeIndex = this.claimTypes?.findIndex(c => c?.id == item?.claimTypeId);
          if(cTypeIndex >= 0){
            this.totalBulkForm.controls.claimTypeName.patchValue(this.claimTypes[cTypeIndex]?.claimType);
            this.totalBulkForm.controls.claimTypeId.patchValue(item?.claimTypeId);
            this.disableCLaimType = true;
          } else if(this.claimTypes?.length > 0 && this.claimTypes[0]?.id && this.claimTypes[0]?.claimType) {
            this.totalBulkForm.controls.claimTypeName.patchValue(this.claimTypes[0]?.claimType);
            this.totalBulkForm.controls.claimTypeId.patchValue(this.claimTypes[0]?.id);
          }else{
            this.disableCLaimType = false;
          }
          // CLAIM_TYPE Filter
          if(this.totalBulkForm?.controls?.claimTypeId?.value && this.totalBulkForm?.controls?.claimTypeId?.value != '') {
            let categoriesList = [];
            this.allCategories1?.forEach((le) => {
              if(le.claimTypeId?.split(',')?.includes(this.totalBulkForm?.controls?.claimTypeId?.value?.toString())) {
                categoriesList.push(le);
              }
            });
            this.filteredCategories = categoriesList;
            this.child_cats = this.getSubCategories(categoriesList, 1);
            this.subCategoriesList = [...this.child_cats];
            this.allSubCategoriesList = [...this.child_cats];
          }
          // CLAIM_TYPE Filter
        }

        if (!item.CompanyPaid && item?.isSelfDriveCabRequest != 2) {
          let bulkItem = new BulkItemModel();
          if (item?.tripStartDate) {
            bulkItem.expensedDate = this.givenOCRDate(item?.tripStartDate);
          } else {
            bulkItem.expensedDate = '';
          }
          if (item?.tripEndDate) {
            bulkItem.expenseEndDate = this.givenOCRDate(item?.tripEndDate);
          } else {
            bulkItem.expenseEndDate = '';
          }
          bulkItem.currencyCode = this.userInfo.baseCurrency.currencyCode;
          bulkItem.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
          bulkItem.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
          bulkItem.expConvRate = 1;
          bulkItem.description = item.tripName;
          bulkItem.invoiceNumber = item?.invoiceNumber ? item.invoiceNumber : '';
          bulkItem.taxNumber = item?.taxNumber ? item.taxNumber : '';
          bulkItem.taxPercentage = item?.taxPercentage ? item.taxPercentage : '';
          bulkItem.taxAmount = item?.taxAmount ? item.taxAmount : '';
          bulkItem.originalAmount = Number(item.tripCost);
          bulkItem.expenseDateExist = false;
          bulkItem.showOptions = (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false;
          bulkItem.userlists = [];
          bulkItem.paidBy = item.CompanyPaid ? 1 : 2;
          bulkItem.bandId = this.userInfo?.bandId || null;
          bulkItem.bandName = this.userInfo?.bandName || null;
          bulkItem.departmentId = this.userInfo?.departmentId || null;
          bulkItem.departmentName = this.userInfo?.departmentName || null;
          bulkItem.costCenterId = this.userInfo?.costCenterId || null;
          bulkItem.costCenterName = this.userInfo?.costCenterName || null;
          bulkItem.designationId = this.userInfo?.designationId || null;
          bulkItem.designationName = this.userInfo?.designationName || null;
          bulkItem.projectCodeId = this.userInfo?.projectCodeId || null;
          bulkItem.projectCodeName = this.userInfo?.projectCodeName || null;
          let invoices = [];
          item.invoiceList?.forEach(invoice => {
            if (invoice?.invoiceUrl) {
              const urlParts = invoice?.invoiceUrl?.split('/');
              const fileName = urlParts[urlParts?.length - 1];
              invoices.push({
                fileName: fileName,
                url: invoice.invoiceUrl,
                content: null,
                attachmentType: "1",
              });
            }
          });
          bulkItem.expenseAttachments = invoices || [];
          const category = _.find(this.categoryList, data => { return data.productId == item?.productId })          
          if (category) {
            bulkItem.categoryType = category?.categoryType;
            bulkItem.categoryName = category?.categoryName;
            bulkItem.categoryCode = category?.categoryCode;
            bulkItem.categoryId = category?.categoryId;
            bulkItem.isPolicyCalcPerNight = category?.isPolicyCalcPerNight || false;
            bulkItem.isStartEndDateEnabled = category?.isStartEndDateEnabled || false;
            bulkItem.isSpentLocationManditory = category?.isSpentLocationManditory || false;
            bulkItem.merchantList = category?.merchantList || [];
            bulkItem.merchantList?.forEach(m => {
              if(item?.supplierName && (item?.supplierName == m.merchantName)) {
                bulkItem.merchantName = m?.merchantName;
                bulkItem.merchantCode = m?.merchantCode;
              }
              if(item?.merchantName && item?.merchantName == m.merchantName) {
                bulkItem.merchantName = m?.merchantName;
                bulkItem.merchantCode = m?.merchantCode;
              }
            })

            //  THE CODE IS FOR TO RESTICTION THE CATEGORY WHICH IS  ALREADY SETUP WITH ANOTHER CATEGORY
            let request = {
              'categoryName':category?.categoryName,
              'categoryRestricted':category?.categoryRestricted != null ? category?.categoryRestricted:null,
              'isCategoryRestricted':category?.isCategoryRestricted
            }
            this.categoryRes[index] = request;
            

          } else {
            bulkItem.categoryName = "";
            // bulkItem.merchantName = item.merchantName;
            // bulkItem.productId = null;
          }

          let trip_category = item?.productName || null;
          let trip_date = item.tripStartDate || null;
          const category_index = this.categoryList?.findIndex(c => c.categoryName == trip_category)          
          if (this.categoryList && this.categoryList[category_index]?.categoryId && trip_date) {
            if (this.userInfo.isCategoryBasedEnabled) {
              this.getCatPolicy(this.categoryList[category_index].categoryId, trip_date, index, 'count', 'bulk');
            } else {
              this.getPolicyApi(this.categoryList[category_index].categoryId, trip_date, index, 'count', 'bulk');
            }
          }

          let findIndex = _.findIndex(this.customFields?.expenseForm?.customFieldList, (object) => { return object.fieldName == 'locationName' });
          if (findIndex != -1) {
            this.customFields.expenseForm.customFieldList[findIndex].customFieldValue = item.locationName;
            // bulkItem.customFieldList = this.customFields.expenseForm.customFieldList;
          }
          this.addBulkItem(bulkItem);
        } else if (!item.CompanyPaid && item?.isSelfDriveCabRequest == 2) {
          this.MileagebulkItemsForms?.value?.forEach((element, index) => {
            if (!element.categoryName && (element.distance < 0 || !element.distance)) {
              this.removeMileageBulkItems('mileage', index);
            }
          });

          let newModel = new MileageBulkItemModel();
          newModel.originalCurrencyCode = this.userInfo.baseCurrency.currencyCode;
          newModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
          newModel.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
          newModel.expConvRate = 1;
          newModel.exchangeRate = 1;
          newModel.showOptions = (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false;
          newModel.showStateList = false;
          newModel.loaderBoolean = false;
          newModel.bulkImgUpload = false;
          newModel.vehicalList = [];
          newModel.expenseAttachments = [];
          newModel.checkPointsList = [];
          newModel.categoryRuleLimitAmountWarning = false;
          newModel.categoryLimitAmountWarning = false;
          newModel.categoryLimitReceiptWarning = false;
          newModel.limitType = 1;
          newModel.limitValue = 0;
          newModel.violationType = 1;
          newModel.taxCodeId = '';
          newModel.monthlyDataList = [];
          newModel.bandId = '';
          newModel.departmentId = '';
          newModel.costCenterId = '';
          newModel.projectCodeId = '';
          newModel.bandName = '';
          newModel.departmentName = '';
          newModel.costCenterName = '';
          newModel.projectCodeName = '';
          newModel.showAdditionalFields = false;
          newModel.categoryCode = '';
          newModel.distance = item.distance;
          newModel.description = item.tripName;
          newModel.expensedDate = item?.tripStartDate;
          newModel.location = item?.pickupStateName || null;
          newModel.isSelfDriveCabRequest = item.isSelfDriveCabRequest || null;
          newModel.distance = item.distance || null;
          newModel.location = item?.pickupStateName || null;
          // ONEEEE
          let invoices = [];
          item.invoiceList?.forEach(invoice => {
            if (invoice?.invoiceUrl) {
              const urlParts = invoice?.invoiceUrl?.split('/');
              const fileName = urlParts[urlParts?.length - 1];
              invoices.push({
                fileName: fileName,
                url: invoice.invoiceUrl,
                content: null,
                attachmentType: "1",
              });
            }
          });
          newModel.expenseAttachments = invoices || [];
          this.addMileageBulkItem(newModel, true);
        }else{
          const category = _.find(this.categoryList, data => { return data.categoryName?.split(" - ")[0] == item?.productName })
          if (category) {
            //  THE CODE IS FOR TO RESTICTION THE CATEGORY WHICH IS  ALREADY SETUP WITH ANOTHER CATEGORY
            let request = {
              'categoryName':category?.categoryName,
              'categoryRestricted':category?.categoryRestricted != null ? category?.categoryRestricted:null,
              'isCategoryRestricted':category?.isCategoryRestricted
            }
            this.categoryRes[index + 30] = request;
          }
        }

        })         
        if(this.totalBulkModel.expenseRequestDTOList?.length == 0) {
          this.addBulkItem(null);
        }
    }
  }

  getSingleTripDeatils(selectedTrip?) {
    let getTripModl = new GetTripInfo();
    getTripModl.legalEntityId = this.userInfo.legalEntityId;
    getTripModl.legalEntityGUID = this.userInfo.legalEntityGuId;
    getTripModl.companyId = this.userInfo.companyId;
    getTripModl.companyGUID = this.userInfo.companyGuId;
    getTripModl.tripId = selectedTrip.tripId;
    getTripModl.tripGUID = selectedTrip.tripGUID;
    getTripModl.productId = selectedTrip.productId;
    getTripModl.requestId = selectedTrip.requestId;
    getTripModl.requestGUID = selectedTrip.requestGUID;

    this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
      if (!res.isError && res.errorCode === 0 && res?.payload) {
        res?.payload.flightDetails?.flights[0]?.invoiceDetails?.forEach((innerelement) => {
          if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
            let object_data = {
              fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
              content: innerelement.base64Invoice,
              url: innerelement.invoiceUrl,
              isDeleted: "",
              attachmentType: 1,
              referenceId: res?.payload.tripId,
            };
            this.previewAttachmentsArray.push(object_data);
          }
        });
      }

      this.pdfloader = false;
    });
  }

  AddMore(tripSelection: TemplateRef<any>) {
    this.tripsSearchtxt = '';
    this.tripSelectionOpened = true;
    this.tripsList = [];
    this.selectedTrips = [];
    const config = {
      ignoreBackdropClick: false,
      class: "modal-xl modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(tripSelection, config);
    this.getTripsList();
  }

  showhideColumns(val: string) {
    let column = true;
    if (this.hideColumns.indexOf(val) > -1) {
      column = false;
    }
    return column;
  }

  getTripsList() {
    let modl = new FetchTripInfo();
    modl.legalEntityId = this.userInfo.legalEntityId;
    modl.legalEntityGUID = this.userInfo.legalEntityGuId;
    modl.companyId = this.userInfo.companyId;
    modl.companyGUID = this.userInfo.companyGuId;
    modl.searchText = this.tripsSearchtxt;
    modl.tripStartDate = new Date(this.totalBulkForm.controls.claimStartDate.value);
    modl.tripEndDate = this.totalBulkForm.controls.claimEndDate.value;
    modl.filterBy = this.tripfilterModal;
    modl.pageSize = 100;
    modl.pageNumber = 1;
    modl.filterBy.statusIds = modl.filterBy.statusIds[0] ? modl.filterBy.statusIds : [];
    modl.tripStartDate = moment(modl.tripStartDate).format('DD-MM-YYYY');
    modl.tripEndDate = moment(modl.tripEndDate).format('DD-MM-YYYY');
    this.reportsService.fetchTripDetails(modl).subscribe((res) => {
      if (!res.isError && res.errorCode == 0 && res?.payload?.tripList) {
        this.nodatafound = false;
        // This validation is for displaying services that fall between two dates.
        // res.payload.tripList = res.payload.tripList.filter((obj) => { return this.givenDateIsExistorNot(obj.tripStartDate) != '' });

        // This validation is for removing duplicate services.
        // if (this.expenseTaggedTripList.length && this.expenseTaggedTripList.length != 0) {
        //   res.payload.tripList = res.payload.tripList.filter(item => !this.expenseTaggedTripList.some(obj => obj.tripId == item.tripId))
        // }
        this.tripsList = [];
        this.tripsList = this.tripsList.concat(
          res.payload.tripList.map((list) => new NewTripModel(list))
        );
        if (this.tripsList.length == 0) {
          this.nodatafound = true;
        }
      } else {
        this.nodatafound = true;
        this.tripsList = [];
        if(res?.payload?.tripList != null) {
          this.toastr.error(res.errorMessage, "Error", { timeOut: 3000 });
        }
      }
    });
  }

  claimTripCost: number;
  selectSingleTrip(list, e) {
    list.isSelected = e.target.checked;
    if (list.isSelected) {
      this.selectedTrips.push(list);
    } else {
      let index = _.findIndex(this.selectedTrips, (e) => { return e.tripId == list.tripId },);
      this.selectedTrips.splice(index, 1);
    }
    // if(this.selectedTrips.length != 0){
    //   this.firstTimeIndexRemoved = true;
    // }

    this.claimTripCost = 0;
    this.selectedTrips.forEach(s => {
      this.claimTripCost += parseFloat(s.tripCost.toString());
    })

  }
  onSelectedTrip(list) {
  }

  trip_customFields: any;
  trip_customFieldsList: any;
  trip_reasonForTravel: any;
  trip_bookingType: any;
  trip_bookingTypeName: any;
  trip_costCenterCode: any;
  trip_estimate: any;
  tripEstimateInUSD: any;
  continueBtn(isAssociate) {
    this.continuoeDisable = true;
    this.selectedTrips[0].serviceList?.forEach(element => {
      this.invoiceList = [];
      this.tripServiceAttachments = [];
      element.invoiceList?.forEach(childelement => {
        if(childelement.invoiceUrl != null) {
          this.invoiceList.push({
            invoiceUrl: childelement.invoiceUrl
          });
          this.tripServiceAttachments.push({
            fileURL: childelement.invoiceUrl
          });
        }
      });
      let requestobject = {
        id: null,
        expenseId: null,
        requestId: element.requestId,
        requestGUID: element.requestGUID,
        tripId: element.tripId,
        tripGUID: element.tripGUID,
        startDate: element.tripStartDate,
        endDate: element.tripEndDate,
        fromLocation: element.tripName,
        toLocation: "",
        totalAmount: element.tripCost,
        paymentMode: element.paymentMode,
        productId: element.productId,
        isAssociated: isAssociate,
        serviceList: element.serviceList,
        advanceSummary: this.selectedTrips[0]?.advanceSummary,
        productName: element.productName,
        invoiceList: this.invoiceList,
        tripServiceAttachments: this.tripServiceAttachments,
        paidBy:element.CompanyPaid ? 1 : 2,
        tripEstimateCost: element.tripEstimateCost || 0,
        tripEstimateInUSD: element.tripEstimateInUSD || null,
        budgetRefNumber: element.budgetRefNumber,
        financeIntegrationCode: element.financeIntegrationCode || null,
        categoryId: element.categoryId || null,
        isSelfDriveCabRequest: element.isSelfDriveCabRequest || null,
        distance: element.distance || null,
        location: element?.pickupStateName || null
      };
      this.trip_estimate = element?.tripEstimateCost || 0;
      this.tripEstimateInUSD = element?.tripEstimateInUSD || null;
      this.expenseTaggedTripList.push(requestobject);

      this.expenseTaggedTripList.forEach(element => {
        if (element?.invoiceList) {
          element.invoiceList.forEach(childelement => {
            if (childelement?.invoiceUrl) {
              this.tripAttachemnet = true;
            } else {
              this.tripAttachemnet = false;
            }
          });
        }
      });
    });
    this.modalRef.hide();
  }

  closeExpenseSelection() {
    this.tripSelectionOpened = false;
    this.modalRef.hide();
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }
  onClickDownloadPdf(item) {
    // if (this.singleTravelTrip || this.multiTravelTrip) {
    //     let base64String = item.content;
    //     this.downloadPdf(base64String, "Invoice");
    // } else {
    //     console.log("this is called my someone", this.expenseModel.expenseAttachments);
    //     //this.imagePrevioeCall();
    // }
  }

  // showAttchements(e) {
  //     console.log("selected trips :", e);
  // }
  pdfSelectionCall(pdfSelection: TemplateRef<any>, trip) {    
    this.previewAttachmentsArray = [];
    this.pdfloader = false;
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    // this.getpdfTripDetails(trip);
    this.modalRef = this.modalService.show(pdfSelection, config);
    this.invoiceDetails = [];
    
    if(!trip?.invoiceList && trip?.tripServiceAttachments?.length > 0) {
      trip.invoiceList = [];
      trip?.tripServiceAttachments?.forEach(ts => {
        trip.invoiceList.push({invoiceUrl: ts.fileURL});
      });
    }

    trip?.invoiceList?.forEach(innerelement => {
      if (innerelement.invoiceUrl != null) {
        let object_data = {
          fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
          url: innerelement.invoiceUrl,
          content: '',
          isDeleted: "",
          attachmentType: 1,
          referenceId: innerelement.tripId,
        };
        if (innerelement.invoiceUrl.split("?")[0].split(".").pop() == 'pdf' && innerelement.base64Invoice == null) {
          this.getPdfAsBase64(innerelement.invoiceUrl).then((base64) => {
            object_data.content = base64;
          })
        } else {
          object_data.content = innerelement.base64Invoice;
        }
        this.previewAttachmentsArray.push(object_data);
        this.invoiceDetails.push(object_data);
        this.pdfloader = false;
      }
    })
    console.log(this.invoiceDetails);
  }
  getpdfTripDetails(expenseDetails) {
    this.invoiceDetails = [];
    this.invoiceDetails = (expenseDetails.invoiceList?expenseDetails.invoiceList:expenseDetails.tripServiceAttachments) ||[];
    if (this.invoiceDetails.length > 0 &&( this.invoiceDetails[0]?.invoiceUrl || this.invoiceDetails[0]?.fileURL) && 
      (this.invoiceDetails[0]?.invoiceUrl !== 'null' ||this.invoiceDetails[0]?.fileURL !== 'null')) {
      const urlStr = this.invoiceDetails[0].invoiceUrl?this.invoiceDetails[0].invoiceUrl:this.invoiceDetails[0].fileURL;
      if (urlStr.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
          this.invoiceDetails[0].content = baseRes;
          this.pdfloader = false;
        });
      } else {
        this.pdfloader = false;
        this.isPdf = false;
      }
    } else {
      // Handle the case when invoiceDetails is an empty array or URL is null or 'camw'
      this.pdfloader = false;
      this.isPdf = false;
    }
  }
  childServiceItemCall(pdfSelection: TemplateRef<any>, trip) {
    this.previewAttachmentsArray = [];
    this.pdfloader = false;
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(pdfSelection, config);
    trip?.invoiceList?.forEach(innerelement => {
      if (innerelement.invoiceUrl != null) {
        let object_data = {
          fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
          url: innerelement.invoiceUrl,
          content: '',
          isDeleted: "",
          attachmentType: 1,
          referenceId: trip.tripId,
        };
        if (innerelement.invoiceUrl.split("?")[0].split(".").pop() == 'pdf' && innerelement.base64Invoice == null) {
          this.getPdfAsBase64(innerelement.invoiceUrl).then((base64) => {
            object_data.content = base64;
          })
        } else {
          object_data.content = innerelement.base64Invoice;
        }
        this.previewAttachmentsArray.push(object_data);
      }
    })
  }

  async getPdfAsBase64(url: string, thumbnail?): Promise<string> {
    try {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();

      // Convert the response data to base64
      const base64 = btoa(
        new Uint8Array(buffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      return base64;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  cfPreviewIndex: any;
  previewExpIndex: any;
  previewCustomFieldImages(imagePreview: TemplateRef<any>, index?, isPdf?, expIndex?, cfIndex?) {
    this.attachmentIndex = index ? index : 0;
    this.cfPreviewIndex = cfIndex || 0;
    this.previewExpIndex =  expIndex || 0;
    this.previewAttachmentsArray = [];
    this.perdiemAttachmentIndex = '';
    this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[expIndex].customFieldList[cfIndex].customFieldValue;

    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(imagePreview, config);
  }

  imagePrevioeCall(imagePreview: TemplateRef<any>, index?, isPdf?, expense?, from?, expenseIndex?) {
    if (from == 'perdiem' && this.returnExtension(expense.expenseAttachments[0]?.fileName) == 'pdf') {
      isPdf = true;
    }
    // this.currentFormTemplate = imagePreview;
    this.attachmentIndex = index ? index : 0;
    this.previewAttachmentsArray = [];
    this.perdiemAttachmentIndex = '';
    if (isPdf) {
      this.getPdfAsBase64(expense.expenseAttachments[index].url).then((baseRes) => {
        let requestM = new GetExpenseModels();
        requestM.expenseIds = [expense?.expenseId];
        requestM.legalEntityId = this.userInfo.legalEntityId;
        requestM.legalEntityGUID = this.userInfo.legalEntityGuId;

        expense.expenseAttachments[index].content = baseRes;
        if (this.active == 'bottom') {
          this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
        } else if (this.active == 'top') {
          this.previewAttachmentsArray = this.expenseModel.expenseAttachments;
        } else if (this.active == 'middle') {
          this.previewAttachmentsArray = this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
        } else if (this.active == 'end') {
          this.previewAttachmentsArray = this.sampleDt[expenseIndex].expenseAttachments;
          this.attachmentIndex = 0;
          this.perdiemAttachmentIndex = expenseIndex;
          this.expensetype = 'perdiem-attachment';
        }
      })
    } else {
      if (this.active == 'bottom') {
        this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
      } else if (this.active == 'top') {
        this.previewAttachmentsArray = this.expenseModel.expenseAttachments;
      } else if (this.active == 'middle') {
        this.previewAttachmentsArray = this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
      } else if (this.active == 'end') {
        this.previewAttachmentsArray = this.sampleDt[expenseIndex].expenseAttachments;
        this.attachmentIndex = 0;
        this.perdiemAttachmentIndex = expenseIndex;
        this.expensetype = 'perdiem-attachment';
      }
    }
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(imagePreview, config);
  }

  // imagePrevioeCall(imagePreview: TemplateRef<any>, index?) {
  //   // this.currentFormTemplate = imagePreview;
  //   this.attachmentIndex = index ? index : 0;
  //   this.previewAttachmentsArray = [];
  //   this.perdiemAttachmentIndex = '';
  //   if (this.active == 'bottom') {
  //     this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
  //   } else if (this.active == 'top') {
  //     this.previewAttachmentsArray = this.expenseModel.expenseAttachments;
  //   } else if (this.active == 'middle') {
  //     this.previewAttachmentsArray = this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
  //   } else if (this.active == 'end') {
  //     this.previewAttachmentsArray = this.sampleDt[index].expenseAttachments;
  //     this.attachmentIndex = 0;
  //     this.perdiemAttachmentIndex = index;
  //     this.expensetype = 'perdiem-attachment';
  //   }
  //   const config = {
  //     ignoreBackdropClick: false,
  //     class: "modal-dialog-centered modal-lg baa",
  //     keyboard: false,
  //   };
  //   this.modalRef = this.modalService.show(imagePreview, config);
  // }
  closeSelection() {
    this.modalRef.hide();
    this.proofofPaymentPreview = false;
    setTimeout(() => {
      this.defaultIndex = 0;
      this.previewAttachmentsArray = [];
    }, 500)
  }

  uploadMilegefile() {
    let _request = {
      attachments: this.uploadedfiles,
      extract: true,
      uploadType: "expenses",
    };
    this.expenseService.ocrDataPulling(_request).subscribe((res) => {
      if (!res.isError) {
        if (res.payload.attachments) {
          this.expenseModel.expenseAttachments.forEach((element) => {
            if (!element.attachmentType) {
              element.fileName = res.payload.attachments[0].fileName;
              element.url = res.payload.attachments[0].uploadedURL;
              element.content = res.payload.attachments[0].base64;
              element.attachmentType = "1";
            }
          });
        }
        this.expenseService
          .createExpenseandMileage(this.expenseModel)
          .subscribe(
            (res) => {
              if (!res.isError && res.errorCode == 0) {
                this.backClick = true;
                this.buttonDisabled = false;
                this.submitMileage = false;
                this.mileagePickPath = false;
                this.associatedAttchements = [];
                this.expenseModel.expenseAttachments = [];
                this.sharedService.setMapData(null);
                this.mileageForm.reset();
                this.removeImage();
                if (this.platformType == 2) {
                  this.mileageForm.controls.originalCurrencyCode.patchValue({
                    originalCurrencyCode:
                      this.userInfo.baseCurrency.currencyCode,
                    currencyCode: this.userInfo.baseCurrency.currencyCode,
                    originalCurrencyId: this.userInfo.baseCurrency.currencyId,
                  });
                  this.expenseModel.originalCurrencyId =
                    this.userInfo.baseCurrency.currencyId;
                } else {
                  this.expenseForm.controls.originalCurrencyCode.patchValue(
                    this.userInfo.baseCurrency.currencyCode
                  );
                  this.mileageForm.controls.originalCurrencyCode.patchValue(
                    this.userInfo.baseCurrency.currencyCode
                  );
                  this.expenseModel.originalCurrencyId =
                    this.userInfo.baseCurrency.currencyId;
                }
                this.onSaveClick(res.payload);
                this.sharedService.setExpenseData(res.payload);
                this.displayApiMsgs(2);
              } else if (res.isError && res.errorCode == 2) {
                this.buttonDisabled = false;
                this.submitMileage = false;
                this.toastr.error(
                  res.errorMessage,
                  this.translate.instant("alerts.error", { timeOut: 3000 })
                );
              }
            },
            (error) => { }
          );
      }
    });
  }

  preBtn() {
    if (this.defaultIndex != 0) {
      this.defaultIndex = this.defaultIndex - 1;
    }
    if (this.attachmentIndex != 0) {
      this.attachmentIndex = this.attachmentIndex - 1;
    }
    if (this.returnExtension(this.previewAttachmentsArray[this.attachmentIndex]?.fileName) == 'pdf') {
      this.getPdfAsBase64(this.previewAttachmentsArray[this.attachmentIndex]?.url).then((base64) => {
        this.previewAttachmentsArray[this.attachmentIndex].content = base64;
      })
    }
  }

  nextBtn() {
    if (this.defaultIndex + 1 ==
      (this.singleTravelTrip || this.multiTravelTrip
        ? this.selectedTripInvoiceArray.length
        : this.expenseModel.expenseAttachments.length)
    ) {
    } else {
      this.defaultIndex = this.defaultIndex + 1;
    }
  }
  ImgnextBtn() {
    if (this.defaultIndex + 1 == this.previewAttachmentsArray.length) {
    } else {
      this.defaultIndex = this.defaultIndex + 1;
    }
    if (this.attachmentIndex + 1 == this.previewAttachmentsArray.length) {
    } else {
      this.attachmentIndex = this.attachmentIndex + 1;
    }
    if (this.returnExtension(this.previewAttachmentsArray[this.attachmentIndex]?.fileName) == 'pdf') {
      this.getPdfAsBase64(this.previewAttachmentsArray[this.attachmentIndex]?.url).then((base64) => {
        this.previewAttachmentsArray[this.attachmentIndex].content = base64;
      })
    }
  }

  searchBtn() {
    this.tripsList = [];
    this.selectedTrips = [];
    this.getTripsList();
  }

  returnExtension(filename) {
    let text = filename?.split("?")[0].split(".").pop();
    return text?.toLowerCase();
  }


  onKey(event) {
    const inputValue = event.target.value;
    this.secondTabList = [];
    this.thirdTabList = [];
    if (this.expenseModel.taxPercentage) {
      let _incomingData = (inputValue / 100) * this.expenseModel.taxPercentage;
      var countDecimals = function (value) {
        if (Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0;
      }
      if (countDecimals(_incomingData) > 2) {
        const first2Str = String(_incomingData).slice(0, 4); // 👉️ '13'
        const first2Num = Number(first2Str); // 👉️ 13
        this.expenseModel.taxAmount = first2Num
        this.expenseForm.controls.taxAmount.patchValue(first2Num);
      } else {
        const returnV = (inputValue / 100) * this.expenseModel.taxPercentage;
        if (this.countDecimals(returnV) > 2) {
          const first2Str = String(returnV).slice(0, 4); // 👉️ '13'
          const first2Num = Number(first2Str); // 👉️ 13
          this.expenseModel.taxAmount = first2Num;
          this.expenseForm.controls.taxAmount.patchValue(first2Num);
        } else {
          this.expenseModel.taxAmount = (inputValue / 100) * this.expenseModel.taxPercentage;
          this.expenseForm.controls.taxAmount.patchValue((inputValue / 100) * this.expenseModel.taxPercentage);
        }
      }
    }
    // this.combinedAmt();
    // this.onTabChanged();
    if (this.userInfo.isCategoryBasedEnabled && this.expenseModel.expensedDate != null && this.expenseModel.categoryId != null && this.expenseModel.originalAmount != null) {
      this.getCatPolicy(this.expenseModel.categoryId, this.expenseModel.expensedDate, 0, 'amount', 'bulk');
    } else if (!this.userInfo.isCategoryBasedEnabled && this.expenseModel.expensedDate != null && this.expenseModel.categoryId != null && this.expenseModel.originalAmount != null) {
      this.getPolicyApi(this.expenseModel.categoryId, this.expenseModel.expensedDate, 0, 'amount', 'bulk');
    }
  }
  onKeyItemSide(event, index) {
    const inputValue = event.target.value;
    let _incomingData = (inputValue / 100) * this.expenseItemsArray.controls[index].value.taxPercentage;
    var countDecimals = function (value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split(".")[1].length || 0;
    }
    if (countDecimals(_incomingData) > 2) {
      const first2Str = String(_incomingData).slice(0, 4); // 👉️ '13'
      const first2Num = Number(first2Str); // 👉️ 13
      this.expenseItemsArray.at(index).get('taxAmount').setValue(first2Num);
    } else {
      const returnV = (inputValue / 100) * this.expenseItemsArray.controls[index].value.taxPercentage;
      if (this.countDecimals(returnV) > 2) {
        const first2Str = String(returnV).slice(0, 4); // 👉️ '13'
        const first2Num = Number(first2Str); // 👉️ 13
        this.expenseItemsArray.at(index).get('taxAmount').setValue(first2Num);
      } else {
        this.expenseModel.taxAmount = (inputValue / 100) * this.expenseItemsArray.controls[index].value.taxPercentage;
        this.expenseItemsArray.at(index).get('taxAmount').setValue((inputValue / 100) * this.expenseItemsArray.controls[index].value.taxPercentage);
      }
    }
    // this.combinedAmt();
    // this.onTabChanged();
  }

  taxAmountCalUsingApprovalAmount(event) {
    const inputValue = event.target.value;
    if (this.countDecimals(inputValue) > 2) {
      const first2Str = String(inputValue).slice(0, 4); // 👉️ '13'
      const first2Num = Number(first2Str); // 👉️ 13
      this.expenseModel.taxAmount = first2Num;
      this.expenseForm.controls.taxAmount.patchValue(first2Num);
    } else {
      const returnV = (inputValue / 100) * this.expenseModel.taxPercentage;
      if (this.countDecimals(returnV) > 2) {
        const first2Str = String(returnV).slice(0, 4); // 👉️ '13'
        const first2Num = Number(first2Str); // 👉️ 13
        this.expenseModel.taxAmount = first2Num;
        this.expenseForm.controls.taxAmount.patchValue(first2Num);
      } else {
        this.expenseModel.taxAmount = (inputValue / 100) * this.expenseModel.taxPercentage;
        this.expenseForm.controls.taxAmount.patchValue((inputValue / 100) * this.expenseModel.taxPercentage);
      }
    }
    if (this.expenseModel.taxAmount > this.expenseModel.originalAmount) {
      this.taxAmountValidation = true;
    } else {
      this.taxAmountValidation = false;
    }
  }

  countDecimals(value) {
    if (Math.floor(value) == value) return 0;
    return value.toString()?.split(".")[1]?.length || 0;
  }

  percentageCalculation(event) {
    const inputValue = event.target.value;
    let _incomingData = (inputValue / (this.showHsnBtns ? this.expenseModel.unformattedOriginalApproveAmount : this.expenseModel.originalAmount)) * 100;
    if (this.countDecimals(_incomingData) > 6) {
      const first3Str = String(_incomingData).slice(0, 4); // 👉️ '13'
      const first3Num = Number(first3Str); // 👉️ 13
      this.expenseModel.taxPercentage = first3Num
      this.expenseForm.controls.taxPercentage.patchValue(first3Num);
    } else {
      this.expenseModel.taxPercentage = _incomingData;
      this.expenseForm.controls.taxPercentage.patchValue(_incomingData);
    }

    if (this.expenseModel.taxPercentage > 100) {
      this.taxPercentageValidation = true;
    } else {
      this.taxPercentageValidation = false;
    }
  }

  percentageCalculationItemWise(event, index) {
    const inputValue = event.target.value;
    let _incomingData = inputValue / this.expenseItemsArray.controls[index].value.amount * 100;
    if (this.countDecimals(_incomingData) > 6) {
      const first3Str = String(_incomingData).slice(0, 4); // 👉️ '13'
      const first3Num = Number(first3Str); // 👉️ 13
      this.expenseItemsArray.at(index).get('taxPercentage').setValue(first3Num);
    } else {
      this.expenseItemsArray.at(index).get('taxPercentage').setValue(_incomingData);
    }

    if (this.expenseItemsArray.controls[index].value.taxPercentage > 100) {
      this.itemwise_taxPercentageValidation = true;
      this.itemwise_taxAmountValidation = true;
    } else {
      this.itemwise_taxPercentageValidation = false;
      this.itemwise_taxAmountValidation = false;
    }
  }

  percentageCalculationBulk(event, index) {
    const inputValue = event.target.value;
    let _incomingData = (inputValue / (this.totalBulkModel.expenseRequestDTOList[index].originalAmount - inputValue)) * 100;
    console.log('before calucl :',inputValue,this.totalBulkModel.expenseRequestDTOList[index].originalAmount);
    console.log('incoming vaue :',_incomingData);
    if (this.countDecimals(_incomingData) > 6) {
      const first3Str = String(_incomingData).slice(0, 4); // 👉️ '13'
      const first3Num = Number(first3Str); // 👉️ 13
      this.totalBulkModel.expenseRequestDTOList[index].taxPercentage = first3Num;
    } else {
      this.totalBulkModel.expenseRequestDTOList[index].taxPercentage = _incomingData
    }

    if (this.totalBulkModel.expenseRequestDTOList[index].taxPercentage > 100) {
      this.totalBulkModel.expenseRequestDTOList[index].taxPercentageStatus = true;
    } else {
      this.totalBulkModel.expenseRequestDTOList[index].taxPercentageStatus = false;
    }
    if (Number(this.totalBulkModel.expenseRequestDTOList[index].taxAmount) > Number(this.totalBulkModel.expenseRequestDTOList[index].originalAmount)) {
      this.totalBulkModel.expenseRequestDTOList[index].taxAmountStatus = true;
    } else {
      this.totalBulkModel.expenseRequestDTOList[index].taxAmountStatus = false;
    }
  }

  TaxAmountCalculation(event) {
    let per = event.target.value;
    let finalVal = (per / 100) * (this.showHsnBtns ? (this.expenseModel.unformattedOriginalApproveAmount ? this.expenseModel.unformattedOriginalApproveAmount : 0) : this.expenseModel.originalAmount);
    if (this.expenseModel.taxPercentage) {
      if (this.countDecimals(finalVal) > 6) {
        const first3Str = String(finalVal).slice(0, 4); // 👉️ '13'
        const first3Num = Number(first3Str); // 👉️ 13
        this.expenseModel.taxAmount = first3Num
        this.expenseForm.controls.taxAmount.patchValue(first3Num);
      } else {
        this.expenseModel.taxAmount = finalVal;
        this.expenseForm.controls.taxAmount.patchValue(finalVal);
      }
    } else {
      this.expenseModel.taxAmount = 0;
      this.expenseForm.controls.taxAmount.patchValue(0);
    }

    if (Number(this.expenseModel.taxAmount) > Number(this.expenseModel.originalAmount)) {
      this.taxAmountValidation = true;
    } else {
      this.taxAmountValidation = false;
    }

  }

  TaxAmountCalculationItemWise(event, index) {
    let per = event.target.value;
    let finalVal = (per / 100) * this.expenseItemsArray.controls[index].value.amount;
    if (this.expenseItemsArray.controls[index].value.taxPercentage) {
      if (this.countDecimals(finalVal) > 6) {
        const first3Str = String(finalVal).slice(0, 4); // 👉️ '13'
        const first3Num = Number(first3Str); // 👉️ 13
        this.expenseItemsArray.at(index).get('taxAmount').setValue(first3Num)
      } else {
        this.expenseItemsArray.at(index).get('taxAmount').setValue(finalVal)
      }
    } else {
      this.expenseItemsArray.at(index).get('taxAmount').setValue(0)
    }

    if (this.expenseItemsArray.controls[index].value.taxPercentage > 100) {
      this.itemwise_taxPercentageValidation = true;
      this.itemwise_taxAmountValidation = true;
    } else {
      this.itemwise_taxPercentageValidation = false;
      this.itemwise_taxAmountValidation = false;
    }
  }

  TaxAmountCalculationBulk(event, index) {
    let per = event.target.value;
    this.totalBulkModel.expenseRequestDTOList[index].originalAmount = parseFloat(this.totalBulkModel.expenseRequestDTOList[index].originalAmount?.toString().replace(/,/g, ''))
    // let finalVal = (per / 100) * this.totalBulkModel.expenseRequestDTOList[index].originalAmount;
    let finalVal = (this.totalBulkModel.expenseRequestDTOList[index].originalAmount * parseInt(per)) / (100 + parseInt(per));
    // console.log('final2 :', finalVal);
    if (this.totalBulkModel.expenseRequestDTOList[index].taxPercentage) {
      if (this.countDecimals(finalVal) > 6) {
        const first3Str = String(finalVal).slice(0, 4); // 👉️ '13'
        const first3Num = Number(first3Str); // 👉️ 13
        this.totalBulkModel.expenseRequestDTOList[index].taxAmount = first3Num;
      } else {
        this.totalBulkModel.expenseRequestDTOList[index].taxAmount = finalVal;
      }
    } else {
      this.totalBulkModel.expenseRequestDTOList[index].taxAmount = 0;
    }

    if (this.totalBulkModel.expenseRequestDTOList[index].taxPercentage > 100) {
      this.totalBulkModel.expenseRequestDTOList[index].taxPercentageStatus = true;
    } else {
      this.totalBulkModel.expenseRequestDTOList[index].taxPercentageStatus = false;
    }

    if (Number(this.totalBulkModel.expenseRequestDTOList[index].taxAmount) > Number(this.totalBulkModel.expenseRequestDTOList[index].originalAmount)) {
      this.totalBulkModel.expenseRequestDTOList[index].taxAmountStatus = true;
    } else {
      this.totalBulkModel.expenseRequestDTOList[index].taxAmountStatus = false;
    }

  }

  decimalLengthFind(conversion, amount) {
    let value = (amount?.toString().replace(/,/g, '') || 0) * (conversion || 1);
    if (this.countDecimals(value) > 6) {
      const first3Str = String(value).slice(0, 4); // 👉️ '13'
      const first3Num = Number(first3Str); // 👉️ 13
      return first3Num;
    } else {
      return value;
    }
  }

  mileageVisibilityOption(index?) {
    this.miileageBulkSelectedIndex = index;
    if (this.mileagePickerOp) {
      this.mileagePickerOp = false;
      this.mileagepicker.close();
    } else {
      this.mileagePickerOp = true;
      this.mileagepicker.open();
    }

  }

  expenseVisibilityOption() {
    if (this.expensePickerOp) {
      this.expensePickerOp = false;
    } else {
      this.expensePickerOp = true;
    }
    if (this.expensePickerOp) {
      this.exppicker.open();
    } else {
      this.exppicker.close();
    }
  }

  perdiemexpenseVisibilityOption() {
    if (this.perdiemPickerOp) {
      this.perdiemPickerOp = false;
    } else {
      this.perdiemPickerOp = true;
    }
    if (this.perdiemPickerOp) {
      this.perdiempicker.open();
    } else {
      this.perdiempicker.close();
    }
  }

  perdiemStartDateVisibilityOption() {
    if (this.perdiemStartD) {
      this.perdiemStartD = false;
    } else {
      this.perdiemStartD = true;
    }
    if (this.perdiemStartD) {
      this._perdiemStartDate.open();
    } else {
      this._perdiemStartDate.close();
    }
  }

  perdiemEndDateVisibilityOption() {
    if (this.perdiemEndD) {
      this.perdiemEndD = false;
    } else {
      this.perdiemEndD = true;
    }
    if (this.perdiemEndD) {
      this._perdiemEndDate.open();
    } else {
      this._perdiemEndDate.close();
    }
  }

  base64dataRe(incomingD) {
  // console.log('incoming data',incomingD);
    const linkSource = `data:application/pdf;base64,${incomingD}`;
    return linkSource;
  }

  getBase64ImageSrc(photo) {
    return "data:image/png;base64," + photo;
  }

  getExpenseAttachments(list) {
    let requestM = new GetExpenseModels();
    requestM.expenseIds = list;
    requestM.legalEntityId = this.userInfo.legalEntityId;
    requestM.legalEntityGUID = this.userInfo.legalEntityGuId;

    // "legalEntityId": 396,
    // "legalEntityGUID": "9483d2d5382a1a7ee7057b2064d412cb"
    this.expenseService.expenseAttachViewer(requestM).subscribe((res) => {
      if (!res.isError && res.errorCode == 0 && res.payload.attachementList) {
        if (!this.isAssociated) {
          for (let i = 0; i < res.payload.attachementList.length; i++) {
            if (this._expenseTypeVerification == 5) {
              for (
                let j = 0;
                j < this.perdiemModel.expenseAttachments.length;
                j++
              ) {
                if (
                  res.payload.attachementList[i].attachmentId ==
                  this.perdiemModel.expenseAttachments[j].attachmentId
                ) {
                  this.perdiemModel.expenseAttachments[j].content =
                    res.payload.attachementList[i].base64;
                }
              }
            } else {
              for (
                let j = 0;
                j < this.expenseModel.expenseAttachments.length;
                j++
              ) {
                if (
                  res.payload.attachementList[i].attachmentId ==
                  this.expenseModel.expenseAttachments[j].attachmentId
                ) {
                  this.expenseModel.expenseAttachments[j].content =
                    res.payload.attachementList[i].base64;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < res.payload.attachementList.length; i++) {
            for (let j = 0; j < this.associatedAttchements.length; j++) {
              if (
                res.payload.attachementList[i].attachmentId ==
                this.associatedAttchements[j].attachmentId
              ) {
                this.associatedAttchements[j].content =
                  res.payload.attachementList[i].base64;
              }
            }
          }
        }
      } else if (res.isError && res.errorCode === 2) {
      }
    });
  }

  perdiemOptionSelection(value) {
    // if (this.platformType == 1) {
    //   this.perdiemRateSelected = value;
    //   this.perdiemSelected = value;
    // } else {
      this.perdiemRateSelected = value;
      this.getPDList();
    // }
    // this._perDay =
    //   this.perdiemRateSelected == "Rate with Receipt"
    //     ? this.selectedPolicyPerdiem.perDayWithReceipt
    //     : this.selectedPolicyPerdiem.perDayWithoutReceipt;
    // this._perHalfDay =
    //   this.perdiemRateSelected == "Rate with Receipt"
    //     ? this.selectedPolicyPerdiem.perHalfDayWithReceipt
    //     : this.selectedPolicyPerdiem.perHalfDayWithoutReceipt;
    // this._perHour =
    //   this.perdiemRateSelected == "Rate with Receipt"
    //     ? this.selectedPolicyPerdiem.perHourWithReceipt
    //     : this.selectedPolicyPerdiem.perHourWithoutReceipt;

    // this.perdiemTypeList = [];
    // if (this._perDay) {
    //   let obj = {
    //     name: "Per Day",
    //     id: 1
    //   }
    //   this.perdiemTypeList.push(obj);
    // }
    // if (this._perHalfDay) {
    //   let obj = {
    //     name: "Per Half Day",
    //     id: 2
    //   }
    //   this.perdiemTypeList.push(obj);
    // }
    // if (this._perDay) {
    //   let obj = {
    //     name: "Per Hour",
    //     id: 3
    //   }
    //   this.perdiemTypeList.push(obj);
    // }

    // if (this.perdiemModel.PDradioSelection) {
    //   if (this.perdiemModel.PDradioSelection == 1) {
    //     this.perdiemPerDay();
    //   } else if (this.perdiemModel.PDradioSelection == 2) {
    //     this.perdiemPerHalfDay();
    //   } else if (this.perdiemModel.PDradioSelection == 3) {
    //     this.perdiemPerHour();
    //   }
    // }
  }

  prediemRadioSelection(event?) {
    if (event) {
      this.perdiemModel.PDradioSelection = event.detail.value;
      this.perdiemModel.PDradioStatus = true;
      this.perdiemForm.controls["PDtype"].setValue(event.detail.value);
    }
    // switch (this.perdiemModel.PDradioSelection) {
    //   case 1:
    //     this.perdiemPerDay();
    //     break;
    //   case 2:
    //     this.perdiemPerHalfDay();
    //     break;
    //   case 3:
    //     this.perdiemPerHour();
    //     break;
    // }
  }

  perdiemselection(e) {
    if (e) {
      this.perdiemModel.PDradioSelection = e.id;
      this.perdiemModel.PDradioSelectionName = e.name;
      this.perdiemModel.pdstatus = true;
      this.perdiemForm.controls["PDtype"].setValue(e.name);
    }
    // switch (this.perdiemModel.PDradioSelection) {
    //   case 1:
    //     this.perdiemPerDay();
    //     break;
    //   case 2:
    //     this.perdiemPerHalfDay();
    //     break;
    //   case 3:
    //     this.perdiemPerHour();
    //     break;
    // }
  }
  perdiemStartDateChange(event, fromDoubleDate?) {    
    var date: any;
    if(fromDoubleDate) {
      date = moment(event, "DD-MM-YYYY").toDate();
      this.perdiemForm.controls.PDstartDate.patchValue(this.convertDateToUtc(event));
    } else {
      date = event.target.value;
    }

    this.perdiemModel.PDstartDateFormate = date;
    this.perdiemModel.PDstartDate = this.datechangeService.convertCalendarToAPI(date);    
    this.minDate = date;
    if (this.perdiemForm.controls.PDendDate.value && !moment(this.perdiemForm.controls.PDendDate.value).isSameOrAfter(this.perdiemForm.controls.PDstartDate.value)) {
      this.perdiemForm.controls.PDendDate.setValue(this.perdiemForm.controls.PDstartDate.value);
      this.perdiemEndDateChange(this.perdiemForm.controls.PDstartDate.value);
    }
    // if (this.perdiemModel.PDendDate) {
    //   switch (this.perdiemModel.PDradioSelection) {
    //     case 1:
    //       this.perdiemPerDay();
    //       break;
    //     case 2:
    //       this.perdiemPerHalfDay();
    //       break;
    //     case 3:
    //       this.perdiemPerHour();
    //       break;
    //   }
    // }
    this.perdiemEndDateStatus = true;
    this.getPDList();
  }

  perdiemEndDateChange(value, fromDoubleDate?) {
    // console.log(this.perdiemModel.PDradioSelection);
    // console.log('policyPerDiemList ______', this.policyPerDiemList);
    var date: any;
    if(fromDoubleDate) {
      date = moment(value, "DD-MM-YYYY").toDate();
      this.perdiemForm.controls.PDendDate.patchValue(this.convertDateToUtc(value));
    } else {
      date = value;
    }

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // var date: any;
    // date = this.datechangeService.convertCalendarToAPI(value);
    this.perdiemModel.PDendDateFormate = date;
    this.perdiemModel.PDendDate = this.datechangeService.convertCalendarToAPI(date);
    // console.log(this.perdiemModel);

    // if (this.perdiemModel.PDstartDate) {
    //   switch (this.perdiemModel.PDradioSelection) {
    //     case 1:
    //       this.perdiemPerDay();
    //       break;
    //     case 2:
    //       this.perdiemPerHalfDay();
    //       break;
    //     case 3:
    //       this.perdiemPerHour();
    //       break;
    //   }
    // }
    this.getPDList();
  }

  showStartTimer() {
    this.startTimer = !this.startTimer;
  }

  showEndTimer() {
    this.endTimer = !this.endTimer;
  }

  startTimeEvent(event) {
    this.perdiemModel.PDstartTime = event;
    this.Etime.hour = event;
    this.getPDList();
    // if (this.platformType == 1) {
    //   this.perdiemModel.PDstartTime = event.hour;
    //   this.Etime.hour = event.hour;
    // } else {
    //   let d = event.detail.value.split('T')[1];
    //   let m = d.split(':')[0];
    //   this.perdiemModel.PDstartTime = m;
    // }
    // if (this.perdiemModel.PDstartTime != 0) {
    //   this.perdiemPerHour();
    // }
  }

  endTimeEvent(event) {
    this.perdiemModel.PDendTime = event;
    this.getPDList();
    // if (this.platformType == 1) {
    //   this.perdiemModel.PDendTime = event.hour;
    // } else {
    //   let d = event.detail.value.split('T')[1];
    //   let m = d.split(':')[0];
    //   this.perdiemModel.PDendTime = m;
    // }
    // this.perdiemPerHour();
    // if (this.perdiemModel.PDstartTime != 0) {
    //   this.perdiemPerHour();
    // }
  }

  perdiemDaysCalculation() {
    // console.log(this.perdiemModel);

    let a = moment(this.perdiemModel.PDstartDateFormate);
    let b = moment(this.perdiemModel.PDendDateFormate);
    // console.log(a, b);

    let tempDate = a;
    let daysCount = b.diff(a, "days");
    let dates: Array<Date> = [];
    this.perdiemModel.PDstartDateFormate != undefined
      ? dates.push(this.perdiemModel.PDstartDateFormate)
      : "";
    for (let i = 0; i < daysCount; i++) {
      tempDate = tempDate.add(1, "day");
      dates.push(tempDate.toDate());
    }
    // console.log(dates);

    return dates;
  }

  perdiemPerDay() {
    return;
    console.log('selected permidem expConvRate:',this.perdiemRateSelected);
    // console.log("oneday")
    this.sampleDt = [];
    // console.log('8635 ______ ', this.perdiemDaysCalculation().length, this.perdiemDaysCalculation());

    const add_request = this.perdiemForm.get("PDList") as FormArray;
    for (let i = 0; i < this.perdiemDaysCalculation().length; i++) {
      var innerV: any;
      innerV = new Date(this.perdiemDaysCalculation()[i]);
      // console.log('this.selectedPolicyPerdiem ______ ',this.selectedPolicyPerdiem);

      console.log('selelele :',this.selectedPolicyPerdiem,this.perdiemSelected);

      let _request = {
        expenseDate: this.datechangeService.convertCalendarToAPI(innerV),
        perDiemRate: this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
        perDiemType: this.perdiemModel.PDradioSelection,
        baseCurrencyId: 64,
        originalCurrencyName: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perDayCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perDayCurrencyIdWithoutReceiptCode,
        originalCurrencyCode: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perDayCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perDayCurrencyIdWithoutReceiptCode,
        originalCurrencyIcon: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perDayCurrencyIdWithReceiptIcon : this.selectedPolicyPerdiem.perDayCurrencyIdWithoutReceiptIcon,
        expConvRate: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perDayCurrencyIdWithoutReceiptExchangeRate : this.selectedPolicyPerdiem.perDayCurrencyIdWithReceiptExchangeRate,
        merchantName: null,
        startDate: this.perdiemModel.PDstartDate,
        startTime: this.perdiemModel.PDstartTime.toString(),
        endDate: this.perdiemModel.PDendDate,
        endTime: this.perdiemModel.PDendTime.toString(),
        originalCurrencyId:
          this.perdiemRateSelected == "Rate with Receipt"
            ? this.selectedPolicyPerdiem.perDayCurrencyIdWithReceipt
            : this.selectedPolicyPerdiem.perDayCurrencyIdWithoutReceipt,
        duration: "Per Day",
        hours: null,
        originalAmount:
          this.perdiemRateSelected == "Rate with Receipt"
            ? this.selectedPolicyPerdiem.perDayAmountWithReceipt
            : this.selectedPolicyPerdiem.perDayAmountWithoutReceipt,
        showLoader: false,
        expenseAttachments: [],
        description: ''
      };
      this.sampleDt.push(_request);
      console.log("assignmenting ",this.sampleDt)
      this.getTotalAmount();
      add_request.push(
        this.formBuilder.group({
          expenseDate: this.datechangeService.convertCalendarToAPI(innerV),
        })
      );
    }
  }

  perdiemPerHalfDay() {
    return;
    console.log('selected permidem expConvRate:',this.perdiemRateSelected);
    this.sampleDt = [];
    for (let i = 0; i < this.perdiemDaysCalculation().length; i++) {
      var innerV: any;
      innerV = new Date(this.perdiemDaysCalculation()[i]);
      let _request = {
        expenseDate: this.datechangeService.convertCalendarToAPI(innerV),
        perDiemRate: this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
        perDiemType: this.perdiemModel.PDradioSelection,
        expConvRate: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithReceiptExchangeRate : this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithoutReceiptExchangeRate,
        baseCurrencyId: 64,
        originalCurrencyName: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithoutReceiptCode,
        originalCurrencyCode: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithoutReceiptCode,
        originalCurrencyIcon: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithReceiptIcon : this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithoutReceiptIcon,
        merchantName: null,
        startDate: this.perdiemModel.PDstartDate,
        startTime: this.perdiemModel.PDstartTime.toString(),
        endDate: this.perdiemModel.PDendDate,
        endTime: this.perdiemModel.PDendTime.toString(),
        originalCurrencyId:
          this.perdiemRateSelected == "Rate with Receipt"
            ? this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithReceipt
            : this.selectedPolicyPerdiem.perHalfDayCurrencyIdWithoutReceipt,
        duration: "Per Half Day",
        hours: null,
        originalAmount:
          this.perdiemRateSelected == "Rate with Receipt"
            ? this.selectedPolicyPerdiem.perHalfDayAmountWithReceipt
            : this.selectedPolicyPerdiem.perHalfDayAmountWithoutReceipt,
        showLoader: false,
        expenseAttachments: [],
        description: ''
      };

      // let _request = {
      //   date: this.datechangeService.convertCalendarToAPI(innerV),
      //   duration: "00:00 - 22:33",
      //   mount:
      //     this.perdiemRateSelected == "Rate with Receipt"
      //       ? this.selectedPolicyPerdiem.perHalfDayAmountWithReceipt
      //       : this.selectedPolicyPerdiem.perHalfDayAmountWithoutReceipt,
      //   expenseAttachments: [],
      // };
      this.sampleDt.push(_request);
    }
    // console.log(this.sampleDt);
    this.getTotalAmount();
  }

  perdiemPerHour() {
    return;
    this.sampleDt = [];
    if (this.perdiemDaysCalculation().length == 1) {
      let _request = {
        expenseDate: this.perdiemModel.PDstartDate,
        perDiemRate: this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
        perDiemType: this.perdiemModel.PDradioSelection,
        expConvRate: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptExchangeRate : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptExchangeRate,
        baseCurrencyId: 64,
        originalCurrencyName: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptCode,
        originalCurrencyCode: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptCode,
        originalCurrencyIcon: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptIcon : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptIcon,
        merchantName: null,
        startDate: this.perdiemModel.PDstartDate,
        startTime: this.perdiemModel.PDstartTime.toString(),
        endDate: this.perdiemModel.PDendDate,
        endTime: '24',
        originalCurrencyId:
          this.perdiemRateSelected == "Rate with Receipt"
            ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceipt
            : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceipt,
        duration:
          this.perdiemModel.PDstartTime + " - " + this.perdiemModel.PDendTime,
        hours: this.perdiemModel.PDendTime - this.perdiemModel.PDstartTime,
        originalAmount:
          this.perdiemRateSelected == "Rate with Receipt"
            ? (this.perdiemModel.PDendTime - this.perdiemModel.PDstartTime) *
            this.selectedPolicyPerdiem.perHourAmountWithReceipt
            : (this.perdiemModel.PDendTime - this.perdiemModel.PDstartTime) *
            this.selectedPolicyPerdiem.perHourAmountWithoutReceipt,
        showLoader: false,
        expenseAttachments: [],
        description: ''
      };
      this.sampleDt.push(_request);
    } else {
      for (let i = 0; i < this.perdiemDaysCalculation().length; i++) {
        if (i == 0) {
          var innerV: any;
          innerV = new Date(this.perdiemDaysCalculation()[i]);
          let _request = {
            expenseDate: this.datechangeService.convertCalendarToAPI(innerV),
            perDiemRate:
              this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
            perDiemType: this.perdiemModel.PDradioSelection,
            expConvRate: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptExchangeRate : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptExchangeRate,
            baseCurrencyId: 64,
            originalCurrencyName: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptCode,
        originalCurrencyCode: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptCode,
        originalCurrencyIcon: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptIcon : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptIcon,
            merchantName: null,
            startDate: this.perdiemModel.PDstartDate,
            startTime: this.perdiemModel.PDstartTime.toString(),
            endDate: this.perdiemModel.PDendDate,
            endTime: 24,
            originalCurrencyId:
              this.perdiemRateSelected == "Rate with Receipt"
                ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceipt
                : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceipt,
            duration: this.perdiemModel.PDstartTime + " - " + 24,
            hours: 24 - this.perdiemModel.PDstartTime,
            originalAmount:
              this.perdiemRateSelected == "Rate with Receipt"
                ? (24 - this.perdiemModel.PDstartTime) *
                this.selectedPolicyPerdiem.perHourAmountWithReceipt
                : (24 - this.perdiemModel.PDstartTime) *
                this.selectedPolicyPerdiem.perHourAmountWithoutReceipt,
            showLoader: false,
            expenseAttachments: [],
          };
          this.sampleDt.push(_request);
        } else if (i == this.perdiemDaysCalculation().length - 1) {
          var innerV: any;
          innerV = new Date(this.perdiemDaysCalculation()[i]);
          let _request = {
            expenseDate: this.datechangeService.convertCalendarToAPI(innerV),
            perDiemRate:
              this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
            perDiemType: this.perdiemModel.PDradioSelection,
            baseCurrencyId: 64,
            originalCurrencyName: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptCode,
            originalCurrencyCode: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptCode,
            originalCurrencyIcon: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptIcon : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptIcon,
            expConvRate: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptExchangeRate : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptExchangeRate,
            merchantName: null,
            startDate: this.perdiemModel.PDstartDate,
            startTime: 0,
            endDate: this.perdiemModel.PDendDate,
            endTime: this.perdiemModel.PDendTime.toString(),
            originalCurrencyId:
              this.perdiemRateSelected == "Rate with Receipt"
                ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceipt
                : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceipt,
            duration: "0 - " + this.perdiemModel.PDendTime,
            hours: this.perdiemModel.PDendTime,
            originalAmount:
              this.perdiemRateSelected == "Rate with Receipt"
                ? this.perdiemModel.PDendTime *
                this.selectedPolicyPerdiem.perHourAmountWithReceipt
                : this.perdiemModel.PDendTime *
                this.selectedPolicyPerdiem.perHourAmountWithoutReceipt,
            showLoader: false,
            expenseAttachments: [],
          };
          this.sampleDt.push(_request);
        } else {
          var innerV: any;
          innerV = new Date(this.perdiemDaysCalculation()[i]);
          let _request = {
            expenseDate: this.datechangeService.convertCalendarToAPI(innerV),
            perDiemRate:
              this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
            perDiemType: this.perdiemModel.PDradioSelection,
            baseCurrencyId: 64,
            originalCurrencyName: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptCode,
            originalCurrencyCode: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptCode : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptCode,
            originalCurrencyIcon: this.perdiemRateSelected == 'Rate with Receipt' ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceiptIcon : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceiptIcon,
            merchantName: null,
            startDate: this.perdiemModel.PDstartDate,
            startTime: 0,
            endDate: this.perdiemModel.PDendDate,
            endTime: 24,
            originalCurrencyId:
              this.perdiemRateSelected == "Rate with Receipt"
                ? this.selectedPolicyPerdiem.perHourCurrencyIdWithReceipt
                : this.selectedPolicyPerdiem.perHourCurrencyIdWithoutReceipt,
            duration: "0 - 24",
            hours: 24,
            originalAmount:
              this.perdiemRateSelected == "Rate with Receipt"
                ? 24 * this.selectedPolicyPerdiem.perHourAmountWithReceipt
                : 24 * this.selectedPolicyPerdiem.perHourAmountWithoutReceipt,
            showLoader: false,
            expenseAttachments: [],
          };
          this.sampleDt.push(_request);
        }
      }
    }
    console.log(this.sampleDt);
    this.getTotalAmount();
  }

  perdiemCategoryOptions(value) {
    this.perdiemModel.PDcategory = value;
  }

  removeAttchement(index) {
    if (this.sampleDt[index]?.expenseId) {
      this.deleteExpense(this.sampleDt[index]?.expenseId, 'perdiem', index);
    } else {
      this.sampleDt[index].expenseAttachments = [];
      this.sampleDt.splice(index, 1)
      this.getTotalAmount();
    }

    // this.sampleDt[index].expenseAttachments = [];
    // this.sampleDt.splice(index, 1)
    // console.log(this.sampleDt);
    // this.getTotalAmount();
    // this.toastr.success("Attachement Removed Successfully", "Success",{ timeOut: 3000 });
  }

  getstateList() {
    let requestObj = {
      countryId: this.userInfo.baseCurrency.countryId,
    };
    this.commonService.getStates(requestObj).subscribe((data) => {
      this.stateList = data.payload;
      let listarray = data.payload;
      this.stateTempList = listarray.map((list) => new stateListModel(list));
      this.stateList = listarray.map((list) => new stateListModel(list));
    });
  }

  getMilageStates(currencyCode?, searchText?) {

    if(currencyCode){

      let requestObj = {
        countryIds:currencyCode,
        searchText: searchText || '',
        legalEntityId: this.userInfo.legalEntityId,
        legalEntityGUID: this.userInfo.legalEntityGuId
      };

      this.expenseService.getMileageCitiesList(requestObj).subscribe((data) => {
        // console.log('respose :',data);
        let listarray = data.payload;
        this.spentLocations = listarray.map((list) => new CitiesModel(list));
      });

    }else{

     let requestObj = {
        countryId: null,
        searchText: searchText || '',
        legalEntityId: this.userInfo.legalEntityId,
        legalEntityGUID: this.userInfo.legalEntityGuId
      };

      this.expenseService.getMileageStatesList(requestObj).subscribe((data) => {
        this.stateList = data.payload.mileageStateList;
        let listarray = data.payload.mileageStateList;
        this.stateTempList = listarray.map((list) => new stateListModel(list));
        this.stateList = listarray.map((list) => new stateListModel(list));
        if(this.stateList?.length == 1 && !this.isUpdate && this.expenseTabIndex == 1) {
          const mileageItem = this.totalMileageBulkModel?.expenseRequestDTOList?.length - 1 || 0;
          const state = this.stateList[0];
          this.selectEventState(state, mileageItem);
          this.getStatesCall(state, mileageItem);
        }
      });
    }
  }

  getRecurrentCodesList() {
    console.log('userinfo :',this.toPathInfoData);
    let requestObj = {
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
      claimTypeId: this.toPathInfoData?.detailReport?.claimTypeId ? this.toPathInfoData?.detailReport?.claimTypeId : null
    };
    this.expenseService.getRecurrentCodesList(requestObj).subscribe((data) => {
      data.payload.financeCodeList.forEach(element => {
        let fic = (element.financeIntegrationCode && element.financeIntegrationCode != '') ? (element.financeIntegrationCode + ' - ') : '';
        let fin = (element.financeIntegrationName && element.financeIntegrationName != '') ? element.financeIntegrationName : '';    
        element.financeCodeName = fic + fin;
      });
      let listarray = data.payload.financeCodeList;
      this.financeIntCodeList = listarray.map((list) => new FinanceIntCodeModel(list));
      this.financeIntCodeTempList = listarray.map((list) => new FinanceIntCodeModel(list));
      // console.log(this.MileagebulkItemsForms.value);

      // this.financeIntCodeList.forEach(f => {
      //   console.log(f);
      //   console.log(f.financeCodeName);

      // })
      let listarray_tax = data.payload.taxCodeList;
      this.taxCodeList = listarray_tax.map((list) => new TaxCodeModel(list));
      this.taxCodeTempList = listarray_tax.map((list) => new TaxCodeModel(list));

      let listarray_hsn = data.payload.hsnCodeList;
      this.hsnCodeList = listarray_hsn.map((list) => new HsnCodeModel(list));
      this.hsnCodeTempList = listarray_hsn.map((list) => new HsnCodeModel(list));

    });
  }

  onstateSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.stateTempList != null) {
      if (searchText != "") {
        let filterdata = this.stateTempList.filter((data) => {
          if (
            data.stateName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
          ) {
            return data;
          }
        });
        this.stateList = filterdata;
      } else {
        this.stateList = this.stateTempList;
      }
    }
  }

  @ViewChild("state", { static: false }) stateAutoCompleteReference: ElementRef;
  @ViewChild("statefocusOff", { static: false })
  statefocusOff: ElementRef;
  showStateList: boolean = false;
  selectedState: any;

  onStateEventFocus() {
    setTimeout(() => {
      this.stateList = this.stateTempList;
    }, 0);
  }
  onClickedOutsideState() {
    this.showStateList = false;
  }
  openStateDropDown(e, index) {
    this.closeAll();
    e.preventDefault();
    e.stopPropagation();
    this.stateList = this.stateTempList;
    this.showStateList = true;
    this.MileagebulkItemsForms.value[index].showStateList = true;
    setTimeout(() => {
      this.stateAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  selectEventState(e, index?) {
    this.stateIds = e.stateId;
    if (this.platformType == 1) {
      this.MileagebulkItemsForms.value[index].location = e.stateName;
      this.MileagebulkItemsForms.value[index].categoryName = '';
      this.MileagebulkItemsForms.value[index].categoryId = '';
      this.totalMileageBulkModel.expenseRequestDTOList[index].categoryId = '';
      this.totalMileageBulkModel.expenseRequestDTOList[index].categoryName = '';
      this.MileagebulkItemsForms.value[index].showStateList = false;
      if (this.userInfo.isCategoryBasedEnabled && this.totalMileageBulkModel.expenseRequestDTOList[this.index]?.expensedDate != null && this.totalMileageBulkModel.expenseRequestDTOList[this.index]?.categoryId != null && this.totalMileageBulkModel.expenseRequestDTOList[this.index]?.originalAmount != null) {
        this.getCatPolicy(e.categoryId, this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate, 0, 'count', 'mileage');
      } else if (!this.userInfo.isCategoryBasedEnabled && this.totalMileageBulkModel.expenseRequestDTOList[this.index]?.expensedDate != null && this.totalMileageBulkModel.expenseRequestDTOList[this.index]?.categoryId != null && this.totalMileageBulkModel.expenseRequestDTOList[this.index]?.originalAmount != null) {
        this.getPolicyApi(e.categoryId, this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate, 0, 'count', 'mileage');
      }
    }
    // this.MileagebulkItemsForms.value[index].patchValue({
    // location: e.stateName,
    // categoryName: "",
    // });  

    //this.MileagebulkItemsForms.value[index].categoryName ="";

    // if (e != null) {
    //   this.expenseModel.stateId = e.stateId;
    //   this.expenseModel.countryId = e.countryId;
    //   this.expenseModel.stateName = e.stateName;
    //   this.expenseModel.statusCode = e.statusCode;
    // }
    this.showStateList = false;
    // if (this.active == "middle") {
    //   this.resetMileageForm();
    // }
  }
  closeAll() {
    this.showStateList = false;
  }
  getStatesCall(obj, index?) {
    this.stateIds = obj.stateId;
    this.CategoryMerchantModel.countryId = obj.countryId;
    this.getmastCategorylist(true, index);
    // this.resetMileageForm();
  }

  returnSelectedV(value) {
    this.selectedMerchant = value;
    this.expenseModel.merchantCode = value.merchantCode;
    this.expenseModel.taxNumber = value.taxNumber;
    this.expenseModel.taxPercentage = value.taxPercentage;
  }

  setBulkMerchantCode(index, event, form: FormGroup) {
    form.controls.merchantName.patchValue(event.merchantName);
    this.totalBulkModel.expenseRequestDTOList[index].merchantCode = event.merchantCode;
    this.totalBulkModel.expenseRequestDTOList[index].merchantName = event.merchantName;
    this.totalBulkModel.expenseRequestDTOList[index].merchantId = event.merchantId;
    this.totalBulkModel.expenseRequestDTOList[index].taxNumber = event.taxNumber;
    this.totalBulkModel.expenseRequestDTOList[index].taxPercentage = event.taxPercentage;
  }

  getDistanceFromMapMyIndia() {

    let requestObj = {
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
      startPoint: {
        latitude: this.expenseModel.fromLatitude,
        longitude: this.expenseModel.fromLangitude,
        fromLocationName: this.expenseModel.Flocation
      },
      endPoint: {
        latitude: this.expenseModel.toLatitude,
        longitude: this.expenseModel.toLangitude,
        toLocationName: this.expenseModel.Tlocation
      },
      'checkPoints': null
    }

    this.commonService.getDistanceFromMapMyIndia(requestObj).subscribe((res) => {
      if (!res.isError && res.errorCode == 0) {
        this.expenseModel.distance = res.payload.distance;
        this.mapMyIndiaReferenceId.push(res.payload.mapMyIndiaReferenceId);
        sessionStorage.setItem("MapRefIDs", JSON.stringify(this.mapMyIndiaReferenceId));
        this.expenseModel.mapMyIndiaReferenceIds = JSON.parse(sessionStorage.getItem("MapRefIDs"));;
        this.getDistance(res.payload.distance);
      } else if (res.isError && res.errorCode == 2) {
        this.toastr.error(res.errorMessage, "Fail", { timeOut: 3000 });
      }

    });
  }

  addreport_perdiem(value, event) {
    if (value == 'dynamic') {
      this.perdiemModel.PDclaimName = event.claimName;
      this.perdiemModel.PDclaimId = event.claimId;
      this.perdiemForm.controls.PDclaimName.patchValue(event.claimName);
      this.perdiemForm.controls.PDclaimId.patchValue(event.claimId);
    } else {
      this.perdiemModel.PDclaimName = event.target.value;
    }
  }
  addreport_mielage(event) {
    if (this.platformType == 1) {
      if (event != undefined) {
        this.mileageForm.controls.claimName.patchValue(event.claimName);
        this.mileageForm.controls.claimId.patchValue(event.claimId);
        this.expenseModel.claimName = event.claimName;
        this.expenseModel.claimId = event.claimId;
      }
    }
    // if (value == 'dynamic') {
    //   this.expenseModel.claimName = event.claimName;
    //   this.expenseModel.claimId = event.claimId;
    // } else {
    //   this.expenseModel.claimName = event.target.value;
    // }
  }
  expenseType(event) {
    switch (event.detail.value) {
      case 'Expense':
        this.type = 1;
        this.active = 'top';
        break;
      case 'Mileage':
        this.type = 2;
        this.isGPSShow = true;
        this.active = 'middle';
        break;
      case 'Perdiem':
        this.type = 4;
        this.active = 'end';
        break;
    }
  }

  async present() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
      message: 'Loading...'
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async _dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

  async cancelCall() {
    if (this.GPSData) {
      const alert = await this.alertController.create({
        mode: "md",
        message: "All the entered data in this form will be removed. Would you like to proceed?",
        backdropDismiss: false,
        buttons: [
          {
            text: 'No',
            role: "cancel",
          },
          {
            text: 'Yes',
            handler: () => {
              this.zone.run(() => {
                this.dismiss();
                // this.navControlr.navigateRoot(['camera']);
              });
            },
          },
        ],
      });
      await alert.present();
    } else {
      this.dismiss();
    }
  }
  addCheckPoints(parentIndex) {
    // this.checkpointModel = new checkpointModel()
    // this.checkpointModel.checkPointLocationName = '';
    // this.checklist.checkpoint.push(this.checkpointModel);
    let model = new checkpointModel();
    this.totalMileageBulkModel.expenseRequestDTOList[parentIndex].checkPointsList.push(model);
  }
  changeInputVal(event, parentIndex, childIndex) {
    this.totalMileageBulkModel.expenseRequestDTOList[parentIndex].checkPointsList[childIndex].checkPointLocationName = event.currentTarget.value;
  }
  deleteCheckPoint(parentIndex, childIndex) {
    this.totalMileageBulkModel.expenseRequestDTOList[parentIndex].checkPointsList.splice(childIndex, 1);
  }
  removeGoogleocation(fromOrTo, parentIndex, childIndex) {
    this.totalMileageBulkModel.expenseRequestDTOList[parentIndex].checkPointsList[childIndex].checkPointLocationName = '';
    // if (fromOrTo == "check") {
    //   this.checklist.checkpoint[childIndex].checkPointLocationName = "";
    // }
  }
  getUserPreferance() {
    let requestObj = {
      userId: this.userInfo.userId,
      userGUID: this.userInfo.userGuId,
    }
    this.commonService.getUserPreferance(requestObj).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        this.userPreferanceData = res.payload;
        this.getmastMileageCategorylist();
        this.setDefaultValues();
      } else if (res.isError && res.errorCode === 2) {
        this.toastr.error(res.errorMessage, "Fail", { timeOut: 3000 });
      }
    });
  }
  setDefaultValues() {

    if (this.userPreferanceData.mileageCategoryId) {

      this.expenseModel.location = this.userPreferanceData.mileageStateName ? this.userPreferanceData.mileageStateName : this.userPreferanceData.mileageCountryName;
      this.expenseModel.categoryName = this.userPreferanceData.mileageCategoryName;
      this.expenseModel.stateId = this.userPreferanceData.mileageStateId;
      this.expenseModel.categoryId = this.userPreferanceData.mileageCategoryId;
      this.expenseModel.countryId = this.userPreferanceData.mileageCountryId;

    } else {
      // localStorage.setItem('mileageDataLocal', JSON.stringify(localData));
      let objData = JSON.parse(localStorage.getItem('mileageDataLocal'));
      this.expenseModel.location = objData.mileageStateName ? objData.mileageStateName : objData.mileageCountryName;
      this.expenseModel.categoryName = objData.mileageCategoryName;
      this.expenseModel.stateId = objData.mileageStateId;
      this.expenseModel.categoryId = objData.mileageCategoryId;
      this.expenseModel.countryId = objData.mileageCountryId;

    }

    if(this.MileagebulkItemsForms.value[0]) {
      this.MileagebulkItemsForms.value[0].categoryName = this.expenseModel.categoryName;
      this.MileagebulkItemsForms.value[0].categoryId = this.expenseModel.categoryId;
      this.MileagebulkItemsForms.value[0].location = this.expenseModel.location;
      this.totalMileageBulkModel.expenseRequestDTOList[0].categoryName = this.expenseModel.categoryName;
      this.totalMileageBulkModel.expenseRequestDTOList[0].categoryId = this.expenseModel.categoryId;
      this.totalMileageBulkModel.expenseRequestDTOList[0].location = this.expenseModel.location;
    }
  }

  getmastMileageCategorylist() {
    this.CategoryMerchantModel.recordType = 1;
    this.CategoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
    this.CategoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.CategoryMerchantModel.locationId = null;
    this.CategoryMerchantModel.stateId = this.userPreferanceData.mileageStateId;
    this.CategoryMerchantModel.countryId = this.userPreferanceData.mileageCountryId;
    this.commonService
      .getCatgryMasterlist(this.CategoryMerchantModel)
      .subscribe((res) => {
        if (!res.isError && res.errorCode == 0) {
          let listarray = res.payload;
          this.milageCategoryList = listarray.mileageCategoryList.map((list) => new CategoryList(list));
          if (this.milageCategoryList != null) {
            this.expenseModel.categoryId = this.milageCategoryList[0].categoryId;
            this.expenseModel.mileageUnit = this.milageCategoryList[0].mileageUnit;
            this.expenseModel.mileageRate = this.milageCategoryList[0].mileageRate;
            this.mileageRate = this.milageCategoryList[0].mileageRate
              ? this.milageCategoryList[0].mileageRate + " " + this.milageCategoryList[0].mileageRateCurrencyCode + "/" + this.milageCategoryList[0].mileageUnit
              : "";
            this.milRate = this.milageCategoryList[0].mileageRate;
            if (this.expenseModel.distance !== undefined) {
              // this.expenseModel.originalAmount =
              //   this.expenseModel.distance * this.milRate;

              if (this.countDecimals(this.expenseModel.distance * this.milRate) > 2) {
                const first3Str = String(this.expenseModel.distance * this.milRate).slice(0, 4); // 👉️ '13'
                const first3Num = Number(first3Str); // 👉️ 13
                this.expenseModel.originalAmount = first3Num
              } else {
                this.expenseModel.originalAmount = this.expenseModel.distance * this.milRate;
              }

              this.milageTotalAmt =
                this.milageConvRate * this.expenseModel.originalAmount;
            }
          }


        } else if (res.isError == true) {
          this.toastr.error(
            res.errorMessage,
            this.translate.instant("alerts.error", { timeOut: 3000 })
          );
        }
      });
  }
  fromTime() {
    const date = new Date();
    const time = date.toLocaleTimeString('en-GB');
    this.currentTime = time;
  }

  async presentPrompt() {
    let alert = await this.alertController.create({
      header: 'Add marchant',
      inputs: [
        {
          name: 'Merchant',
          placeholder: 'Merchant'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
            alert.dismiss();
          }
        },
        {
          text: 'Ok',
          handler: data => {
            this.expenseForm.controls.merchantName.patchValue(data.Merchant);
            this.expenseModel.merchantName = data.Merchant;
            alert.dismiss();
          }
        }
      ]
    });
    alert.present();
  }
  async presentImageDeletePrompt() {
    let alert = await this.alertController.create({
      header: 'Delete Attachment',
      subHeader: 'Are you sure you want to delete the attachment?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
            alert.dismiss();
          }
        },
        {
          text: 'Continue',
          handler: data => {
            this.zone.run(() => {
              alert.dismiss();
              this.deleteImage();
            });
          }
        }
      ]
    });
    alert.present();
  }

  deleteImage() {
    this.caputredImageModal.capturedImages.splice(this.activeIndex, 1);
    this.expenseModel.expenseAttachments.splice(this.activeIndex, 1);
  }
  onCategoryEventFocus() {
    setTimeout(() => {
      this.categoryList = this.categorytempList;
    }, 0);
  }


  openCategoryDropdown(e) {
    e.preventDefault();
    e.stopPropagation();
    this.listLoaderDic[0].loader = true;
    setTimeout(() => {
      this.categoryAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  openCategorybulkDropdown(e) {
    e.preventDefault();
    e.stopPropagation();
    this.listLoaderDic[0].loader = true;
    setTimeout(() => {
      this.category.nativeElement.focus();
    }, 0);
  }

  showhideOption(index) {
    if (this.bulkItemsForms.value[index].showOptions) {
      this.bulkItemsForms.controls[index].get('showOptions').setValue(false);
    } else {
      this.bulkItemsForms.controls[index].get('showOptions').setValue(true);
    }
  }

  expenseShowOptions() {
    if (this.expenseForm.value.showOptions) {
      this.expenseForm.get('showOptions').setValue(false);
    } else {
      this.expenseForm.get('showOptions').setValue(true);
    }
  }

  showMileageAdvanced = false;
  showMileageAdvancedIndex: any;
  mileageShowOptions(index, advanceOptions?) {
    if (advanceOptions) {
      if (this.MileagebulkItemsForms.value[index].showOptions) {
        this.MileagebulkItemsForms.value[index].showOptions = false;
      } else {
        this.MileagebulkItemsForms.value[index].showOptions = true;
      }
      this.showMileageAdvanced = !this.showMileageAdvanced;
      this.showMileageAdvancedIndex = index;
    }

  }
  perdiemShowOptios() {
    if (this.perdiemForm.value.showOptions) {
      this.perdiemForm.get('showOptions').setValue(false);
    } else {
      this.perdiemForm.get('showOptions').setValue(true);
    }
  }

  loadMore(event) {
  }

  checkingExpense(isAssociate?) {
    this.closeAllModals();
    this.modalRef?.hide();
    this.tripdetailviewenable = false;
    this.tripSelectionOpened = false;
    this.showMileageBtn = true;
    this.showperdiemBtn = true;
    this.isAssociated = true;

    console.log('valeu is :',this.tripdetailview,this.toPathInfoData);

    if (!this.firstTimeIndexRemoved && !this.bulkAlreadyRemoved) {
      this.totalBulkForm.value.bulkItems.forEach((element, index) => {
        if (element.categoryName == undefined) {
          this.removeBulkItems('Expense', index);
        }
      });
      this.bulkAlreadyRemoved = true;
    }

    this.modalRef?.hide();
    if (isAssociate) {
      if(this.expenseTabIndex == 0) {
        this.addBulkItem(null);
      }
      this.continueBtn(isAssociate);
    } else {
      this.continueBtn(isAssociate);
      this.getTripDetails('expenseForm');
    }
  }

  bulkformreset() {
    if (this.expensetype == 'Expense') {
      this.bulkItemsForms.controls[this.index].reset();
      this.totalBulkModel.expenseRequestDTOList[this.index].expenseAttachments = [];
      this.totalBulkModel.expenseRequestDTOList[this.index].categoryRuleLimitAmountWarning = false;
      this.totalBulkModel.expenseRequestDTOList[this.index].categoryLimitAmountWarning = false;
      this.totalBulkModel.expenseRequestDTOList[this.index].categoryLimitReceiptWarning = false;
      this.totalBulkModel.expenseRequestDTOList[this.index].categoryCountLimitWarning = false;
      this.totalBulkModel.expenseRequestDTOList[this.index].policyLimitAmountWarning = false;
      this.totalBulkModel.expenseRequestDTOList[this.index].policyLimitReceiptWarning = false
      this.totalBulkModel.expenseRequestDTOList[this.index].approversList = [];
      this.totalBulkModel.expenseRequestDTOList[this.index].loaderBoolean = false;
      this.totalBulkModel.expenseRequestDTOList[this.index].expensedDate = null;
      this.totalBulkModel.expenseRequestDTOList[this.index].productId = null;
      this.totalBulkModel.expenseRequestDTOList[this.index].isAlcohol = false;
      this.bulkItemsForms.controls[this.index].get('currencyCode').setValue('INR');
      this.bulkFieldsMandatory = false;
      this.submitExpense = false;
      this.modalRef.hide();
    } else if (this.expensetype == 'mileage') {
      this.MileagebulkItemsForms.controls[this.index].reset();
      this.totalMileageBulkModel.expenseRequestDTOList[this.index].loaderBoolean = false;
      this.totalMileageBulkModel.expenseRequestDTOList[this.index].expenseAttachments = [];
      this.totalMileageBulkModel.expenseRequestDTOList[this.index].approversList = [];
      this.totalMileageBulkModel.expenseRequestDTOList[this.index].categoryRuleLimitAmountWarning = false;
      this.MileagebulkItemsForms.controls[this.index].get('originalCurrencyCode').setValue('INR');
      this.mileageFieldsMandatory = false;
      this.submitMileage = false;
      this.modalRef.hide();
    } else if (this.expensetype == 'perdeim') {
      this.perdiemForm.reset();
      this.sampleDt = [];
      this.perdiemModel = new PerdiemModel();
      this.perdiemModel.PDstartTime = 0;
      this.perdiemModel.PDendTime = 0;
      this.perdiemModel.PDradioSelectionName = '';
      this.perdiemFieldsMandatory = false;
      this.submitPerdiem = false;
      this.modalRef.hide();
      console.log(this.perdiemForm.value);
      console.log(this.perdiemModel);
    }
  }
  bulkdeleteAttchementt() {
    if (this.expensetype == 'Expense') {
      let expenseId = this.totalBulkModel.expenseRequestDTOList[this.index].expenseId;
      if (expenseId) {
        this.deleteExpense(expenseId, this.expensetype, this.index);
      } else {
        this.totalBulkModel.expenseRequestDTOList.splice(this.index, 1);
        this.bulkItemsForms.removeAt(this.index);
      }

      if(this.expenseTaggedTripList?.length != 0 && (this.totalBulkForm.controls.claimTypeName.value != null && this.totalBulkForm.controls.claimTypeName.value != '') ){
        this.disableCLaimType = true;
      }else{
        this.disableCLaimType = false;
      }
      
    } else if (this.expensetype == 'mileage') {
      let expenseId = this.totalMileageBulkModel.expenseRequestDTOList[this.index].expenseId;
      if (expenseId) {
        this.deleteExpense(expenseId, this.expensetype, this.index);
      } else {
        this.totalMileageBulkModel.expenseRequestDTOList.splice(this.index, 1);
        this.MileagebulkItemsForms.removeAt(this.index);
      }
    } else {
      this.trip_bookingType = null;
      this.trip_bookingTypeName = null;
      this.trip_customFields = null;
      this.trip_costCenterCode = null;
      this.trip_reasonForTravel = null;
      this.trip_customFieldsList = [];
      this.trip_estimate = null;
      if (this.navInfoData.isEdit) {
        this.deleteTrip(this.expenseTaggedTripList[0]);
      } else {
        this.expenseTaggedTripList = [];
        this.TravelAdvanceSummary = null;
      }
    }
    this.modalRef.hide();
    if (this.expenseTaggedTripList?.length == 0) {
      this.firstTimeIndexRemoved = false;
      this.showMileageBtn = true;
      this.showperdiemBtn = true;
    }
    this.getTotalAmount();
  }

  claimStartDate: any;
  claimEndDate: any;
  minStartDate: any;
  fomattedMinStartDate: any;
  minEndDate: any;
  FormattedMinStartDate: any;
  FormattedMinEndDate: any;
  ClaimdateChange(event, from, isTrip?, template?: TemplateRef<any>, fromDoubleDate?) {
    let ev: any;
    if(fromDoubleDate) {
      ev = event;
    } else {
      ev = event.target.value;
    }
    
    if (from == 'from') {
      const currentDate = this.claimStartDate || this.navInfoData['report']?.claimStartDate;
      console.log(moment(this.totalBulkForm.controls.claimEndDate.value).format());


      if (!this.expenseOutsideStartEndDate(this.convertDateToUtc(ev), moment(this.totalBulkForm.controls.claimEndDate.value).format())) {
        this.totalBulkForm.controls.claimStartDate.patchValue(this.convertDateToUtc(ev));
        this.mileageForm.controls.claimStartDate.patchValue(this.convertDateToUtc(ev));
        this.minStartDate = this.convertDateToUtc(ev);
        this.FormattedMinStartDate = this.convertDateToUtc(ev);
        this.claimMindate = this.convertDateToUtc(ev);
        this.claimStartDate = ev;
        if (isTrip) {
          this.tripsList = [];
          this.nodatafound = false;
          this.getTripsList();
        }
      } else {
        // this.reportModel.claimStartDate = this.datechangeService.convertCalendarToAPI(moment(currentDate, 'DD-MM-YYYY').format());
        // this.mileageForm.controls.claimStartDate.patchValue(this.convertDateToUtc(currentDate));
        this.totalBulkForm.controls.claimStartDate.patchValue(this.convertDateToUtc(currentDate));
        this.mileageForm.controls.claimStartDate.patchValue(this.convertDateToUtc(currentDate));
        this.minStartDate = this.convertDateToUtc(currentDate);
        this.FormattedMinStartDate = this.convertDateToUtc(currentDate);
        this.expensesOutsideType = 'start';
        this.currentTemplate = template;
        this.openExpensesOutsideAlert();
      }
    } else {
      const currentDate = this.claimEndDate || this.navInfoData['report']?.claimEndDate;

      if (!this.expenseOutsideStartEndDate(moment(this.totalBulkForm.controls.claimStartDate.value).format(), this.convertDateToUtc(ev))) {
        this.totalBulkForm.controls.claimEndDate.patchValue(this.convertDateToUtc(ev));
        this.mileageForm.controls.claimEndDate.patchValue(this.convertDateToUtc(ev));
        this.minEndDate = this.convertDateToUtc(ev);
        this.FormattedMinEndDate = this.convertDateToUtc(ev);
        this.claimMindate = this.convertDateToUtc(ev);
        this.claimEndDate = ev;
        if (isTrip) {
          this.tripsList = [];
          this.nodatafound = false;
          this.getTripsList();
        }
      } else {
        // this.reportModel.claimEndDate = this.datechangeService.convertCalendarToAPI(moment(currentDate, 'DD-MM-YYYY').format());
        // this.totalBulkForm.controls.claimEndDate.patchValue(this.convertDateToUtc(currentDate));
        this.totalBulkForm.controls.claimEndDate.patchValue(this.convertDateToUtc(currentDate));
        this.mileageForm.controls.claimEndDate.patchValue(this.convertDateToUtc(currentDate));
        this.minEndDate = this.convertDateToUtc(currentDate);
        this.FormattedMinEndDate = this.convertDateToUtc(currentDate);
        this.expensesOutsideType = 'end';
        this.currentTemplate = template;
        this.openExpensesOutsideAlert();
      }
    }
    //  this.singleClaimDateValidation();
  }

  singleClaimDateValidation(index?) {
    let claimFdate = this.totalBulkForm.value.claimStartDate;
    let claimTdate = this.totalBulkForm.value.claimEndDate;
    if (index) {
      let clickedExpenseDate = this.totalBulkForm.value.bulkItems[index].date;
      if (clickedExpenseDate.valueOf() >= claimFdate.valueOf() && clickedExpenseDate.valueOf() <= claimTdate.valueOf()) {
        this.totalBulkModel.expenseRequestDTOList[index].expenseDateExist = false;
      } else {
        this.totalBulkModel.expenseRequestDTOList[index].expenseDateExist = true;
      }
    } else {
      this.totalBulkForm.value.bulkItems.forEach((element, index) => {
        let clickedExpenseDate = element.date;
        if (clickedExpenseDate.valueOf() >= claimFdate.valueOf() && clickedExpenseDate.valueOf() <= claimTdate.valueOf()) {
          this.totalBulkModel.expenseRequestDTOList[index].expenseDateExist = false;
        } else {
          this.totalBulkModel.expenseRequestDTOList[index].expenseDateExist = true;
        }
      });
    }
  }

  givenDateIsExistorNot(clickedExpenseDate) {
    let claimFdate = this.totalBulkForm.value.claimStartDate;
    let claimTdate = this.totalBulkForm.value.claimEndDate;
    if (claimFdate && claimTdate) {
      if (this.convertDateToUtc(clickedExpenseDate).valueOf() >= claimFdate.valueOf() && this.convertDateToUtc(clickedExpenseDate).valueOf() <= claimTdate.valueOf()) {
        return clickedExpenseDate;
      } else {
        return '';
      }
    } else {
      return clickedExpenseDate;
    }
  }

  givenOCRDate(clickedExpenseDate) {
    let claimFdate = this.totalBulkForm.value.claimStartDate;
    let claimTdate = this.totalBulkForm.value.claimEndDate;
    return clickedExpenseDate;
  }

  daysCalculate(fromPath) {

    if (fromPath == 'expense') {
      let a = moment(this.totalBulkForm.controls.claimStartDate.value);
      let b = moment(this.totalBulkForm.controls.claimEndDate.value);
      let daysCount = b.diff(a, "days");
      return daysCount + 1;
    } else {
      let a = moment(this.totalBulkForm.controls.claimStartDate.value);
      let b = moment(this.totalBulkForm.controls.claimEndDate.value);
      let daysCount = b.diff(a, "days");
      return daysCount + 1;
    }

  }
  sumofCatAmount(incomingArray, incomingString, categoryId, booleanValue, expensedDate?) {
    let sum = 0;

    if (incomingString == 'bulk') {
      incomingArray.forEach(item => {
        if (item.originalAmount != '') {
          sum += parseFloat(item.originalAmount) * (item.expConvRate || 1);
        }
      });
      if (this.navInfoData && booleanValue && this.navInfoData?.url == 'newreport' && this.navInfoData?.expenseList) {
        const list = this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == categoryId) });
        if (list) {
          list.forEach(item => {
            if (item.originalAmount != '') {
              sum += parseFloat(item.originalAmount) * (item.expConvRate || 1);
            }
          });
        }
      }
    } else {
      incomingArray.forEach(item => {
        if (item.distance != '') {
          sum += parseInt(item.distance);
        }
      });
      if (this.navInfoData && booleanValue && this.navInfoData?.url == 'newreport' && this.navInfoData?.expenseList) {
        const list = this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == categoryId && obj.expensedDate === expensedDate) });
        if (list) {
          list.forEach(item => {
            if (item.distance != '') {
              sum += item.distance;
            }
          });
        }
      }
    }
    return sum;
  }


  open_bulk_dropdown(i, path, field?) {
    this.currentField = field;
    this.showSubMenu(field);
    if (path == 'category') {
      if (this.totalBulkModel.expenseRequestDTOList[i].showCategoryList) {
        this.totalBulkModel.expenseRequestDTOList[i].showCategoryList = false;
      } else {
        this.totalBulkModel.expenseRequestDTOList[i].showCategoryList = true;
      }
      this.categoryList = this.categorytempList;
      setTimeout(() => {
        this.categoryfocus.nativeElement.focus();
      }, 100);
    } else if (path == 'currency') {
      this.selectedIndex = i;
      if (this.totalBulkModel.expenseRequestDTOList[i].showList) {
        this.totalBulkModel.expenseRequestDTOList[i].showList = false;
      } else {
        this.totalBulkModel.expenseRequestDTOList[i].showList = true;
      }
      this.currencyList = this.currencytempList;
      setTimeout(() => {
        this.currencyAutoCompleteReference.nativeElement.focus();
      }, 100);
    } else if (path == 'merchant') {
      this.selectedIndex = i;
      if (this.totalBulkModel.expenseRequestDTOList[i].showMerchantList) {
        this.totalBulkModel.expenseRequestDTOList[i].showMerchantList = false;
      } else {
        this.totalBulkModel.expenseRequestDTOList[i].showMerchantList = true;
      }
      this.totalBulkModel.expenseRequestDTOList[i].merchantList = this.merchanttempList;
      setTimeout(() => {
        this.bulkMerchant.nativeElement.focus();
      }, 100);
    } else if (path == 'paidBy') {
      this.selectedIndex = i;
      this.totalBulkModel.expenseRequestDTOList[i].showpaidByList = !this.totalBulkModel.expenseRequestDTOList[i].showpaidByList;
      setTimeout(() => {
        this.paidByfocus.nativeElement.focus();
      }, 100);
    }
  }

  mileage_category_dropdown(index) {
    if (this.MileagebulkItemsForms.value[index].showvehicalList) {
      this.MileagebulkItemsForms.value[index].showvehicalList = false;
    } else {
      this.MileagebulkItemsForms.value[index].showvehicalList = true;
    }
    this.MileagebulkItemsForms.value[index].vehicalList = this.vehicalList_tenp;
    setTimeout(() => {
      this.mileagecategoryId.nativeElement.focus();
    }, 100);
  }

  bulk_focus(e) {
    e.preventDefault();
    e.stopPropagation();
  }


  onVehicalSearch(val, i) {
    let searchText = val.currentTarget.value.trim();
    if (this.categorytempList != null) {
      if (searchText != "") {
        let filterdata = this.MileagebulkItemsForms.value[i].vehicalList.filter((data) => {
          if (
            data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          }
        });
        this.MileagebulkItemsForms.value[i].vehicalList = filterdata;
      } else {
        this.MileagebulkItemsForms.value[i].vehicalList = this.vehicalList_tenp;
      }
    }
  }
  fromDatev2() {
    // console.log('step 1 :',this.openfromdateexp);
    this.openfromdate = true;
    this.opentodate = false;
    this.openfromdateexp = false;
    this.openfromdatemileage = false;
  }

  //mielage clickoutside functionality
  fromDatemileage() {
    this.openfromdatemileagee = true;
    this.opentodatemileage = false;
    this.openfromdatemileage = false;
  }
  toDatemileage() {
    this.opentodatemileage = true;
    this.openfromdatemileagee = false;
    this.openfromdatemileage = false;
  }

  // onClickedOutsideCalendertodatemileage(e) {
  //   let el = <HTMLElement>e.target;
  //   if (this.opentodatemileage && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
  //     || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today"
  //     || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
  //     this.todate.open();

  //   }
  //   else {
  //     this.todate.close();
  //   }

  // }

  // onClickedOutsideCalenderfrommileage(e) {
  //   let el = <HTMLElement>e?.target;
  //   if (this.openfromdatemileagee && el && !this.tripSelectionOpened && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
  //     || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today"
  //     || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
  //       this.fromdate.open();
  //   }
  //   else {
  //     this.fromdate.close();
  //   }

  // }
  fromDatevmileage() {
    this.openfromdatemileage = true;
    this.openfromdatemileagee = false;
    this.opentodatemileage = false;
    this.openfromdate = false;
    this.opentodate = false;
  }
  onClickedOutsideCalendermileage(e) {
    let el = <HTMLElement>e?.target;
    if (this.openfromdatemileage && !this.tripSelectionOpened && el && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
      || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused"
      || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
      || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today"
      || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
      if (!this.mileagepicker_i.opened) {
        this.mileagepicker_i.open();
      }
    } else if (el) {
      this.mileagepicker_i?.close();
    }
  }

  tripSelectionOpened = false;
  onClickedOutsideCalenderfrom(e) {
    let el = <HTMLElement>e?.target;
    if (this.openfromdate && el && !this.tripSelectionOpened && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
      || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused"
      || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused" || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
      || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base"
      || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
      this.fromdate.open();
    } else if (el) {
      this.fromdate?.close();
    }
    if(this.isMobile == 1 && !this.mouseover) {
      this.dp_type = null;
    }
  }
  toDatev2() {
    // console.log('step 2:',this.openfromdateexp);
    this.opentodate = true;
    this.openfromdate = false;
    this.openfromdateexp = false;
  }
  onClickedOutsideCalendertodate(e) {
    let el = <HTMLElement>e?.target;
    if (this.opentodate && el && !this.tripSelectionOpened && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
      || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused"
      || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
      || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today"
      || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
      this.todate.open();
    } else if (el) {
      this.todate?.close();
    }
    if(this.isMobile == 1 && !this.mouseover) {
      this.dp_type = null;
    }
  }
  bulkdate() {
    this.openfromdateexp = true;
    this.openfromdate = false;
    this.opentodate = false;
    console.log('staus :', this.openfromdateexp);
  }
  onClickedOutsideCalenderbulk(e) {

    let el = <HTMLElement>e?.target;
    if (this.openfromdateexp && !this.tripSelectionOpened && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
      || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today"
      || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header"
      || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base"
      || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer"
      || el?.className == "mat-button-focus-overlay" || el?.className == "mat-ripple mat-button-ripple mat-button-ripple-round" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
      || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
      || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused")) {
      this.exppickerbulk_i?.open();
    }
    else if (el) {
      this.exppickerbulk_i?.close();
      this.customPicker_i?.close();
    }

    if(this.isMobile == 1 && !this.mouseover) {
      this.dp_type = null;
    }

  }
  addCategory(e, i?) {
    if (this.isReadOnly) {
      this.totalBulkModel.expenseRequestDTOList[i].showlist = false;
    } else {
      this.totalBulkModel.expenseRequestDTOList[i].showlist = true;
    }
    if (!this.editlist) {
      if (this.totalBulkModel.expenseRequestDTOList[i].showlist) {
        this.totalBulkModel.expenseRequestDTOList[i].showlist = false;
      } else {
        this.totalBulkModel.expenseRequestDTOList[i].showlist = true;
      }
    } else {
      this.showlist = true;
    }

    const input = e.input;
    const value = e.value;
    if ((value || '').trim()) {
      this.totalBulkModel.expenseRequestDTOList[i].userlists.push(value.trim());
      this.userlists.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    e.preventDefault();
    e.stopPropagation();
    if (!this.editlist) {
      this.totalBulkModel.expenseRequestDTOList[i].showlist = true;
    } else {
      this.showlist = true;
    }

    setTimeout(() => {
      this.corporateList.nativeElement.focus();
    }, 0);
  }
  onuserEventFocus() {
    setTimeout(() => {
      this.allUserList = this.tempUserList;
    }, 0);
  }
  removable: boolean = true;
  removeuserlist(i?, parentindex?, childidex?) {
    if (!this.editlist) {
      this.totalBulkModel.expenseRequestDTOList[parentindex].userlists.splice(childidex, 1);
      this.totalBulkModel.expenseRequestDTOList[parentindex].expenseUserSplitList.splice(childidex, 1)
    } else {
      this.userlists.splice(childidex, 1)
      this.expenseModel.expenseUserSplitList.splice(childidex, 1);
      if (this.expenseModel.expenseUserSplitList.length != 0) {
        this.expenseModel.hasExpenseSplit = true;
      } else {
        this.expenseModel.hasExpenseSplit = false;
      }
    }

  }
  selecteduserlist(event: MatAutocompleteSelectedEvent, i?): void {
    if (!this.editlist) {
      if (this.totalBulkModel.expenseRequestDTOList[i].userlists?.indexOf(event.option.value.userName) == -1) {
        this.totalBulkModel.expenseRequestDTOList[i].userlists.push(event.option.value.userName);
        let categorydata = {
          id: null,
          userName: event.option.value.userName,
          userId: event.option.value.userId
        };
        this.userlists.push(categorydata);
        this.totalBulkModel.expenseRequestDTOList[i].expenseUserSplitList?.push(categorydata);

        if (this.userlists.length != 0) {
          this.totalBulkModel.expenseRequestDTOList[i].hasExpenseSplit = true;
        } else {
          this.totalBulkModel.expenseRequestDTOList[i].hasExpenseSplit = false;
        }
        this.totalBulkModel.expenseRequestDTOList[i].showlist = false
      } else {
        this.totalBulkModel.expenseRequestDTOList[i].showlist = false
      }
    } else {
      if (this.userlists.indexOf(event.option.value.userName) == -1) {
        this.userlists.push(event.option.value.userName);
        const categorydata = {
          id: '',
          userName: event.option.value.userName,
          userId: event.option.value.userId
        };

        if (this.expenseModel.expenseUserSplitList == null) {
          this.expenseModel.expenseUserSplitList = [];
        }
        this.expenseModel.expenseUserSplitList.push(categorydata);

        if (this.expenseModel.expenseUserSplitList.length != 0) {
          this.expenseModel.hasExpenseSplit = true;
        } else {
          this.expenseModel.hasExpenseSplit = false;
        }
        this.expenseForm.controls.expenseUserSplitList.patchValue(this.userlists);
        this.showlist = false;
      } else {
        this.showlist = false;
      }

    }

  }
  patchingFormDetails() {
    var myVal: any;
    myVal = [];
    if (this.editlist.expenseUserSplitList) {
      this.editlist.expenseUserSplitList.forEach((obj: any) => {
        if (obj) {
          this.userlists.push(obj.userName);
        } else {
          this.userlists = [];
        }
      });
    }
    this.listNames = myVal;
  }
  getUserList() {
    const departmentHeadRequestPayload = {
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
      searchText: this.filterSearch,
    };
    this.settingService
      .getUsersList(JSON.stringify(departmentHeadRequestPayload))
      .subscribe((response) => {
        if (response?.payload?.users.length > 0) {
          this.allUserList = response.payload.users;
          if (!this.tempUserList.length) {
            this.tempUserList = response.payload.users;
          }
          this.allUserList.forEach(element => {
            if (this.userInfo.userId == element.userId) {
              element.userdisabled = true;
            } else {
              element.userdisabled = false;
            }
          });
          this.onuserSearchLoading = false;
        } else {
          this.allUserList = [];
        }
      });
  }

  onuserSearchLoading: boolean;
  onuserSearch(val) {
    this.showSearch = true;
    this.onuserSearchLoading = true;
    this.filterSearch = val.currentTarget.value.trim();
    this.getUserList();
    // this.allUserList = this.tempUserList
    // if (searchText != "") {
    //   let filterdata = this.tempUserList.filter((data) => {
    //     if (
    //       data.userName.toLowerCase().indexOf(searchText.toLowerCase()) >=
    //       0
    //     ) {
    //       return data;
    //     } else {
    //     }
    //   });
    //   this.allUserList = filterdata;

    // } else {
    //   this.allUserList = this.tempUserList
    // }
  }

  // GET POLICY API CALLING //


  getPolicyApi(catgoryId, expensedDate, index, type, parentType, update?) {
    this.getViolations(update);
    return;

    const recordType = this.fromPettyCash ? 4 : 1;
    let expDate = expensedDate ? expensedDate : this.currentDateStr
    // if(moment(expDate).isUTC() || moment.isMoment(expDate)) {
    // let cleanedDate = expDate?.toString().replace(/\(.*\)/, '').trim();
    if (!moment.utc(expDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(expDate) || this.checkDateFormat(expDate)) {
      expDate = moment(expDate).format('DD-MM-YYYY');
    }
    this.commonService.getpolicyList({
      expenseEndDate: this.totalBulkModel.expenseRequestDTOList[this.selectedCategoryIndex]?.isStartEndDateEnabled ?  this.totalBulkModel.expenseRequestDTOList[this.selectedCategoryIndex]?.expenseEndDate : null,
      cityId: this.totalBulkModel.expenseRequestDTOList[this.selectedCategoryIndex]?.isSpentLocationManditory ?  this.totalBulkModel.expenseRequestDTOList[this.selectedCategoryIndex]?.cityId : null,
      userId: this.userInfo.userId,
      userGUID: this.userInfo.userGuId,
      categoryId: catgoryId,      
      expensedDate: expDate,
      claimTypeId: this.totalBulkForm?.controls?.claimTypeId?.value || null
    })
      .subscribe((res) => {
        if (res && res?.payload) {
          res.payload.policyPerDiemList?.forEach((element) => {
            element.countryCity = element.countryName + (element.cityName != null ? ' - ' + element.cityName : '');
          });
          this.policyList = res.payload;
          this.policyPerDiemList = this.policyList.policyPerDiemList;

          if (update) {
            this.policyPerDiemList.forEach((element) => {
              if (element.cityId == this.perdiemModel.PDlocationId) {
                this.selectedPolicyPerdiem = element;
                element.isAllowedWithReceipt == true
                  ? this.addPerdiemRateList("Rate with Receipt")
                  : "";
                element.isAllowedWithoutReceipt == true
                  ? this.addPerdiemRateList("Rate without Receipt")
                  : "";
              }
            });
          }

          if (res.payload) {
            sessionStorage.setItem("policylist", JSON.stringify(res.payload));
          }
          if (parentType == 'bulk') {
            if (type == 'count') {
              this.policyCatCountValidation(index);
              this.policyCatCommonMethod(index);
            } else {
              this.policyCatCommonMethod(index);
              this.policyCatCountValidation(index);
            }
          } else {
            this.policyMileageCatCommonMethod(index);
          }
        } else {
          //  this.toastr.error(res.errorMessage, "Error");
        }
      });
  }

  getCatPolicy(catgoryId, expensedDate, index, type, parentType) {
    this.getViolations();
    return;

    const recordType = this.fromPettyCash ? 4 : 1;
    expensedDate = expensedDate != ''? moment(expensedDate, 'DD-MM-YYYY').format('DD-MM-YYYY') : null;    
    this.commonService.getForUserPolicyCatpolicyList({
      expenseEndDate: this.totalBulkModel.expenseRequestDTOList[this.selectedCategoryIndex].isStartEndDateEnabled ?  this.totalBulkModel.expenseRequestDTOList[this.selectedCategoryIndex]?.expenseEndDate : null,
      cityId: this.totalBulkModel.expenseRequestDTOList[this.selectedCategoryIndex].isSpentLocationManditory ?  this.totalBulkModel.expenseRequestDTOList[this.selectedCategoryIndex]?.cityId : null,
      userId: this.userInfo.userId,
      userGUID: this.userInfo.userGuId,
      categoryId: catgoryId,
      claimTypeId: this.totalBulkForm?.controls?.claimTypeId?.value || null,
      expensedDate: expensedDate ? expensedDate : this.currentDateStr
    }).subscribe((res) => {
      if (res && res?.payload) {
        res.payload.policyPerDiemList?.forEach((element) => {
          element.countryCity = element.countryName + (element.cityName != null ? ' - ' + element.cityName : '');
        });
        this.policyList = res.payload;
        this.policyPerDiemList = this.policyList.policyPerDiemList;
        if (res.payload) {
          sessionStorage.setItem("policylist", JSON.stringify(res.payload));
        }
        if (catgoryId != '') {
          if (parentType == 'bulk') {
            if (type == 'count') {
              this.policyCatCountValidation(index);
              this.policyCatCommonMethod(index);
            } else {
              this.policyCatCommonMethod(index);
              this.policyCatCountValidation(index);
            }
          } else {
            this.policyMileageCatCommonMethod(index);
          }
        }

      } else {
        //  this.toastr.error(res.errorMessage, "Error");
      }
    });
  }

  // BULK EXPENSE CATEGORY COUNT VALIDATION //
  policyCatCountValidation(indexOrId?) {
    if (this.policyList) {
      if (!this.editlist) {
        const cat_id = this.totalBulkModel.expenseRequestDTOList[indexOrId]?.categoryId;
        let expenseDate = this.totalBulkModel.expenseRequestDTOList[indexOrId]?.expensedDate;
        let category_rule;
        let selected_category_rule;
        let category_notRule;
        let selected_category_notRule;
        category_rule = this.policyList;
        selected_category_rule = category_rule?.policyCategoryRuleList?.find((secondelm) => secondelm.categoryId == cat_id);
        selected_category_notRule = category_rule?.policyCategoryList?.find((elm) => elm.categoryId == cat_id);

        // APPROVALS LIST//
        if (category_rule && category_rule?.approversList) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].approversList = category_rule.approversList;
        } else if (category_notRule && category_notRule?.approversList) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].approversList = category_notRule.approversList;
        }

        if (category_rule && category_rule?.deviatedApproversList) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].deviatedApproversList = category_rule.deviatedApproversList;
        } else if (category_notRule && category_notRule?.deviatedApproversList) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].deviatedApproversList = category_notRule.deviatedApproversList;
        }

        //DAILY LIMIT //
        if (category_rule && category_rule?.dailyDataList) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].dailyDataList = category_rule.dailyDataList;
        } else if (category_notRule && category_notRule?.dailyDataList) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].dailyDataList = category_notRule.dailyDataList;
        }

        //MONTHLY LIMIT //
        if (category_rule && category_rule?.monthlyDataList) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].monthlyDataList = category_rule.monthlyDataList;
        } else if (category_notRule && category_notRule?.monthlyDataList) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].monthlyDataList = category_notRule.monthlyDataList;
        }

        // console.log('\n\n', this.totalBulkModel.expenseRequestDTOList);
        // console.log(selected_category_rule)
        // console.log(this.totalBulkModel.expenseRequestDTOList[indexOrId]);

        if (selected_category_rule) {
          this.totalBulkModel.expenseRequestDTOList[indexOrId].ruleType = selected_category_rule.ruleType;
          this.totalBulkModel.expenseRequestDTOList[indexOrId].limitType = selected_category_rule.limitType;
          this.totalBulkModel.expenseRequestDTOList[indexOrId].limitValue = selected_category_rule.limitValue;
          this.totalBulkModel.expenseRequestDTOList[indexOrId].violationType = selected_category_rule.violationType;

          if (selected_category_rule?.limitType == 1 && selected_category_rule?.ruleType == 1) {
            // if(moment(expenseDate).isUTC() || moment.isMoment(expenseDate)) {
            let cleanedDate = expenseDate?.toString().replace(/\(.*\)/, '').trim();
            if (!moment.utc(expenseDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(expenseDate) || this.checkDateFormat(expenseDate)) {
              expenseDate = moment(expenseDate).format('DD-MM-YYYY');
            }
            const list = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id && obj.expensedDate === expenseDate; });

            // IF DAILY LIMIT NULL WE HAVE TO TAKE CALCULATE PREVIOUS VALUES //
            const nav_list = this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == cat_id && obj.expensedDate == expenseDate) });

            let filterd_cat_item = category_rule?.dailyDataList?.find((obj) => obj.categoryId === cat_id);
            let final_count = (list ? list?.length : 0) + (filterd_cat_item ? filterd_cat_item.totalCount : 0);

            if (category_rule?.dailyDataList == null) {
              final_count = final_count + nav_list.length;
            }

            if (final_count && (final_count > this.totalBulkModel.expenseRequestDTOList[indexOrId].limitValue)) {
              this.totalBulkModel.expenseRequestDTOList[indexOrId].categoryCountLimitWarning = true;
            } else {
              this.totalBulkModel.expenseRequestDTOList[indexOrId].categoryCountLimitWarning = false;
            }
          } else if (selected_category_rule?.limitType == 1 && selected_category_rule?.ruleType == 2) {
            const list = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
            let filterd_cat_item = category_rule?.monthlyDataList?.find((obj) => obj.categoryId === cat_id);
            // console.log('\n\n');

            // console.log('list __ ', list);
            // console.log('navInfoData?.expenseList __ ', this.navInfoData?.expenseList);
            // console.log(this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == cat_id && this.monthCalculation(expenseDate, obj.expensedDate)) }));
            // this.navInfoData?.expenseList.forEach(ne => {
            //   console.log(9999, '___ ', ne);
            // })
            const nav_list = this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == cat_id && this.monthCalculation(expenseDate, obj.expensedDate)) });
            // console.log('nav list count :', nav_list);

            let expenseIdsCount = 0;
            list.forEach(ne => {
              if (ne.expenseId) {
                ++expenseIdsCount;
              }
            })
            // console.log('expenseIdsCount _______ ', expenseIdsCount);

            let final_count = 0;
            if (filterd_cat_item) {
              final_count = (list ? list.length : 0) + (filterd_cat_item ? filterd_cat_item.totalCount : 0) - expenseIdsCount;
              // final_count = (list ? list.length : 0) + (filterd_cat_item ? filterd_cat_item.totalCount : 0);
            } else {
              final_count = (list ? list.length : 0) + (nav_list ? nav_list.length : 0);
            }

            // console.log(selected_category_rule.limitValue, final_count);
            // console.log(selected_category_rule.limitValue < final_count);

            if (selected_category_rule.limitValue < final_count) {
              this.totalBulkModel.expenseRequestDTOList[indexOrId].categoryCountLimitWarning = true;
            } else {
              this.totalBulkModel.expenseRequestDTOList[indexOrId].categoryCountLimitWarning = false;
            }
            // console.log('\n\n');

          } else if (selected_category_rule?.limitType == 1 && selected_category_rule?.ruleType == 3) {

            const list = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
            let filterd_cat_item = category_rule?.yearlyDataList?.find((obj) => obj.categoryId === cat_id);
            const nav_list = this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == cat_id && this.yearCalculation(expenseDate, obj.expensedDate)) });
            console.log('nav list count :', nav_list);

            let final_count = 0;
            if (filterd_cat_item) {
              final_count = (list ? list.length : 0) + (filterd_cat_item ? filterd_cat_item.totalCount : 0);
              if (selected_category_rule.limitValue < final_count) {
                this.totalBulkModel.expenseRequestDTOList[indexOrId].categoryCountLimitWarning = true;
              } else {
                this.totalBulkModel.expenseRequestDTOList[indexOrId].categoryCountLimitWarning = false;
              }
            } else {
              final_count = (list ? list.length : 0) + (nav_list ? nav_list.length : 0);
              if (selected_category_rule.limitValue < final_count) {
                this.totalBulkModel.expenseRequestDTOList[indexOrId].categoryCountLimitWarning = true;
              } else {
                this.totalBulkModel.expenseRequestDTOList[indexOrId].categoryCountLimitWarning = false;
              }
            }


          }
        }

        // console.log('\n\n');
      } else {
        const cat_id = this.expenseModel.categoryId;
        let category_rule;
        let selected_category_rule;
        let category_notRule;
        let selected_category_notRule;
        category_rule = this.policyList;
        selected_category_rule = category_rule?.policyCategoryRuleList?.find((secondelm) => secondelm.categoryId == cat_id);
        selected_category_notRule = category_rule?.policyCategoryList?.find((elm) => elm.categoryId == cat_id);

        if (selected_category_rule) {
          if (selected_category_rule?.limitType == 1 && selected_category_rule?.ruleType == 1) {
            const list = this.navInfoData.expenseList.filter((obj) => { return obj.isSelected == true && obj.categoryId == cat_id && obj.expensedDate == this.expenseModel.expensedDate });
            let filterd_cat_item = category_rule?.dailyDataList?.find((obj) => obj.categoryId === cat_id);
            if (filterd_cat_item) {
              if ((filterd_cat_item?.totalCount + 1) > selected_category_rule.limitValue) {
                this.categoryCountLimitWarning = true;
              } else {
                this.categoryCountLimitWarning = false;
              }
            } else {
              if ((list.length + 1) > selected_category_rule.limitValue) {
                this.categoryCountLimitWarning = true;
              } else {
                this.categoryCountLimitWarning = false;
              }
            }
          } else if (selected_category_rule?.limitType == 1 && selected_category_rule?.ruleType == 2) {
            // const list = this.navInfoData.expenseList.filter((obj) => { return obj.isSelected == true && obj.categoryId == cat_id });
            let filterd_cat_item = category_rule?.monthlyDataList?.find((obj) => obj.categoryId === cat_id);
            if (filterd_cat_item) {
              if (selected_category_rule.limitValue < (filterd_cat_item?.totalCount + 1)) {
                this.categoryCountLimitWarning = true;
              } else {
                this.categoryCountLimitWarning = false;
              }
            } else {
              this.categoryCountLimitWarning = false;
            }

          } else if (selected_category_rule?.limitType == 1 && selected_category_rule?.ruleType == 3) {
            let filterd_cat_item = category_rule?.yearlyDataList?.find((obj) => obj.categoryId === cat_id);
            if (filterd_cat_item) {
              if (selected_category_rule.limitValue < (filterd_cat_item?.totalCount + 1)) {
                this.categoryCountLimitWarning = true;
              } else {
                this.categoryCountLimitWarning = false;
              }
            } else {
              this.categoryCountLimitWarning = false;
            }

          }
        } else {
          this.categoryCountLimitWarning = false;
        }

      }
    } else {
      this.categoryCountLimitWarning = false;
    }
  }

  // BULK EXPENSE EXPENSE AMOUNT VALIDATION //
  policyCatCommonMethod(index) {
    if (this.policyList) {
      if (!this.editlist) {
        // console.log('\n\n');
        const cat_id = this.totalBulkModel.expenseRequestDTOList[index]?.categoryId;
        const isSpentLocationManditory = this.totalBulkModel.expenseRequestDTOList[index]?.isSpentLocationManditory;
        const cityId = isSpentLocationManditory ? this.totalBulkModel.expenseRequestDTOList[index]?.cityId : null;
        // console.log('cat_id ___ ', cat_id);
        // console.log(this.policyList);

        const expenseDate = this.totalBulkModel.expenseRequestDTOList[index]?.expensedDate;
        let category_rule;
        let selected_category_rule;
        let selected_category_notRule;
        category_rule = this.policyList;
        selected_category_rule = category_rule?.policyCategoryRuleList?.find((secondelm) => secondelm.categoryId == cat_id && secondelm.limitType == 3);
        selected_category_notRule = category_rule?.policyCategoryList?.find((elm) => elm.categoryId == cat_id && (isSpentLocationManditory ? elm.cityIds?.includes(cityId) : true));

        // console.log(selected_category_rule);
        // console.log(selected_category_notRule);


        // POLICY CATEGORY RULE LIMIT VALIDATION //
        if (selected_category_rule) {
          if (selected_category_rule.limitType == 3 && selected_category_rule.ruleType == 1 && selected_category_rule.limitValue) {
            const list = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id && obj.expensedDate === expenseDate; });
            let filterd_cat_item = category_rule?.dailyDataList?.find((obj) => obj.categoryId === cat_id);
            let catAmount = this.sumofCatAmount(list, 'bulk', cat_id, (filterd_cat_item ? false : true));
            if (selected_category_rule.limitValue < (catAmount + (filterd_cat_item?.totalBaseAmount || 0))) {
              this.totalBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
            } else {
              this.totalBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
                if (cat_id == element.categoryId) {
                  this.totalBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
                }
              });
            }
          } else if (category_rule?.monthlyDataList && selected_category_rule.limitType == 3 && selected_category_rule.ruleType == 2) {
            const list = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
            let catAmount = this.sumofCatAmount(list, 'bulk', cat_id, false);

            const nav_list = this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == cat_id && this.monthCalculation(expenseDate, obj.expensedDate)) });
            let filterd_cat_item = category_rule?.monthlyDataList?.find((obj) => obj.categoryId === cat_id);

            if (filterd_cat_item) {
              if (selected_category_rule?.limitValue < (catAmount + (filterd_cat_item?.totalBaseAmount || 0))) {
                this.totalBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
              } else {
                this.totalBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
                  if (cat_id == element.categoryId) {
                    this.totalBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
                  }
                });
              }
            } else {

              if (nav_list) {
                nav_list.forEach(item => {
                  if (item.originalAmount != '') {
                    catAmount += parseInt(item.originalAmount);
                  }
                });
              }

              if (selected_category_rule?.limitValue < catAmount) {
                this.totalBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
              } else {
                this.totalBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
                  if (cat_id == element.categoryId) {
                    this.totalBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
                  }
                });
              }
            }

            // if (selected_category_rule.limitValue < catAmount) {
            //   this.totalBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
            // } else {
            //   this.totalBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
            //     if (cat_id == element.categoryId) {
            //       this.totalBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
            //     }
            //   });
            // }
          } else if (category_rule?.yearlyDataList && selected_category_rule.limitType == 3 && selected_category_rule.ruleType == 3) {
            const list = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
            let catAmount = this.sumofCatAmount(list, 'bulk', cat_id, false);
            let filterd_cat_item = category_rule?.yearlyDataList?.find((obj) => obj.categoryId === cat_id);
            if (selected_category_rule?.limitValue < (catAmount + filterd_cat_item?.totalBaseAmount)) {
              this.totalBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
            } else {
              this.totalBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
                if (cat_id == element.categoryId) {
                  this.totalBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
                }
              });
            }
          }
        }

        if (selected_category_notRule) {
          // POLICY CATEGORY LIMIT VALIDATION //
          if (selected_category_notRule.expenseAmountLimit < parseFloat(this.totalBulkModel.expenseRequestDTOList[index]?.originalAmount?.toString().replace(/,/g, '')) * (this.totalBulkModel.expenseRequestDTOList[index]?.expConvRate || 1)) {
            // if (selected_category_notRule.expenseAmountLimit < this.totalBulkModel.expenseRequestDTOList[index].originalAmount) {
            this.totalBulkModel.expenseRequestDTOList[index].categoryLimitAmountWarning = true;
          } else {
            this.totalBulkModel.expenseRequestDTOList[index].categoryLimitAmountWarning = false;
          }

          // POLICY CATEGORY RECEIPT LIMIT VALIDATION //
          if (selected_category_notRule.receiptRequiredLimit < parseFloat(this.totalBulkModel.expenseRequestDTOList[index]?.originalAmount?.toString().replace(/,/g, '')) * (this.totalBulkModel.expenseRequestDTOList[index]?.expConvRate || 1)) {
            // if (selected_category_notRule.receiptRequiredLimit < this.totalBulkModel.expenseRequestDTOList[index].originalAmount) {
            this.totalBulkModel.expenseRequestDTOList[index].categoryLimitReceiptWarning = true;
          } else {
            this.totalBulkModel.expenseRequestDTOList[index].categoryLimitReceiptWarning = false;
          }

        } else {
          //If the category level limit is available, the system should not apply the default limit to it  --IMPORTANT NOTE
          // POLICY AMOUNT LIMIT VIOLATIONS //          
          if (!this.userInfo.isCategoryBasedEnabled) {
            if (this.editlist) {
              if (category_rule?.expenseAmountLimit) {
                if (this.expenseModel.originalAmount > category_rule?.expenseAmountLimit) {
                  this.expensePolicyAmount = true;
                } else {
                  this.expensePolicyAmount = false;
                }
              }
            } else {
              if (category_rule?.expenseAmountLimit) {
                if (this.totalBulkModel.expenseRequestDTOList[index]?.originalAmount * (this.totalBulkModel.expenseRequestDTOList[index]?.expConvRate || 1) > category_rule?.expenseAmountLimit) {
                  this.totalBulkModel.expenseRequestDTOList[index].policyLimitAmountWarning = true;
                } else {
                  this.totalBulkModel.expenseRequestDTOList[index].policyLimitAmountWarning = false;
                  this.showClaimFormViolation = false;
                }
              }
            }
            // POLICY RECEIPT REQUIRE VIOLATIONS //
            if (this.editlist) {
              if (category_rule?.receiptRequiredLimit) {
                if (this.expenseModel.originalAmount > category_rule?.receiptRequiredLimit) {
                  this.expensePolicyReceipt = true;
                } else {
                  this.expensePolicyReceipt = false;
                }
              }
            } else {
              // console.log(index, category_rule);
              // console.log(category_rule?.receiptRequiredLimit);
              if (category_rule?.receiptRequiredLimit) {
                // console.log((this.totalBulkModel.expenseRequestDTOList[index].expConvRate || 1), this.totalBulkModel.expenseRequestDTOList[index].originalAmount);
                // console.log((this.totalBulkModel.expenseRequestDTOList[index].expConvRate || 1) * this.totalBulkModel.expenseRequestDTOList[index].originalAmount, category_rule?.receiptRequiredLimit);
                // console.log((this.totalBulkModel.expenseRequestDTOList[index].expConvRate || 1) * this.totalBulkModel.expenseRequestDTOList[index].originalAmount > category_rule?.receiptRequiredLimit);

                if ((this.totalBulkModel.expenseRequestDTOList[index].expConvRate || 1) * this.totalBulkModel.expenseRequestDTOList[index].originalAmount > category_rule?.receiptRequiredLimit) {
                  this.totalBulkModel.expenseRequestDTOList[index].policyLimitReceiptWarning = true;
                } else {
                  this.totalBulkModel.expenseRequestDTOList[index].policyLimitReceiptWarning = false;
                }
              }
            }
          }
        }
        // console.log('\n\n');

      } else {
        const cat_id = this.expenseModel.categoryId;
        let category_rule;
        let selected_category_rule;
        let category_notRule;
        let selected_category_notRule;
        category_rule = this.policyList;
        selected_category_rule = category_rule?.policyCategoryRuleList?.find((secondelm) => secondelm.categoryId == cat_id);
        selected_category_notRule = category_rule?.policyCategoryList?.find((elm) => elm.categoryId == cat_id);

        if (selected_category_rule?.limitValue && selected_category_rule?.limitType == 3 && selected_category_rule?.ruleType == 1) {
          let catAmount = 0;
          let filterd_cat_item = category_rule?.dailyDataList?.find((obj) => obj.categoryId === cat_id);
          // catAmount = this.sumofCatAmount(list, 'bulk', cat_id, false);
          catAmount = ((filterd_cat_item?.totalBaseAmount) + parseInt(this.expenseModel.originalAmount));
          // let divisionValue = this.numberToFixed((catAmount / this.daysCalculate('expense')));
          if (selected_category_rule.limitValue < catAmount) {
            this.categoryRuleLimitAmountWarning = true;
          } else {
            this.categoryRuleLimitAmountWarning = false;
          }
        } else if (selected_category_rule?.limitValue && selected_category_rule?.limitType == 3 && selected_category_rule?.ruleType == 2) {
          // const list = this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == cat_id && obj.expenseId != this.editlist.expenseId) });
          let catAmount = 0;
          // let empty = [];
          // catAmount = this.sumofCatAmount(empty, 'bulk', cat_id, false);
          // catAmount = (catAmount + parseInt(this.expenseModel.originalAmount));
          let filterd_cat_item = category_rule?.monthlyDataList?.find((obj) => obj.categoryId === cat_id);
          if (selected_category_rule?.limitValue < (parseInt(this.expenseModel.originalAmount) + filterd_cat_item?.totalBaseAmount)) {
            this.categoryRuleLimitAmountWarning = true;
          } else {
            this.categoryRuleLimitAmountWarning = false;
          }
        } else if (selected_category_rule?.limitValue && selected_category_rule?.limitType == 3 && selected_category_rule?.ruleType == 3) {
          // let catAmount = 0;
          // let empty = [];
          // catAmount = this.sumofCatAmount(empty, 'bulk', cat_id, false);
          // catAmount = (catAmount + parseInt(this.expenseModel.originalAmount));
          let filterd_cat_item = category_rule?.yearlyDataList?.find((obj) => obj.categoryId === cat_id);
          if (selected_category_rule?.limitValue < (parseInt(this.expenseModel.originalAmount) + filterd_cat_item?.totalBaseAmount)) {
            this.categoryRuleLimitAmountWarning = true;
          } else {
            this.categoryRuleLimitAmountWarning = false;
          }
        }

      }
    } else {
      this.categoryRuleLimitAmountWarning = false;
    }
  }

  // MILEAGE DISTANCE VALIDATION //
  policyMileageCatCommonMethod(index) {
    if (this.policyList) {
      console.log('mileage llist :', this.totalMileageBulkModel.expenseRequestDTOList);
      const cat_id = this.totalMileageBulkModel.expenseRequestDTOList[this.editlist ? 0 : index].categoryId;
      const expensedDate = this.totalMileageBulkModel.expenseRequestDTOList[index].expensedDate;
      let category_rule;
      let category_notRule;
      let selected_category_rule;

      category_rule = this.policyList;
      selected_category_rule = this.policyList?.policyMileageRuleList?.find((elm) => elm.categoryId == cat_id);
      if (category_rule && category_rule?.approversList) {
        this.totalMileageBulkModel.expenseRequestDTOList[index].approversList = category_rule.approversList;
      } else if (category_notRule && category_notRule?.approversList) {
        this.totalMileageBulkModel.expenseRequestDTOList[index].approversList = category_notRule.approversList;
      }
      // POLICY CATEGORY RULE LIMIT VALIDATION 

      if (selected_category_rule) {
        this.totalMileageBulkModel.expenseRequestDTOList[index].limitType = selected_category_rule?.limitType;
        this.totalMileageBulkModel.expenseRequestDTOList[index].limitValue = selected_category_rule?.ruleType == 1 ? (this.daysCalculate('mileage') * selected_category_rule.limitValue) : selected_category_rule.limitValue;
        this.totalMileageBulkModel.expenseRequestDTOList[index].violationType = selected_category_rule?.violationType;
      }

      //MONTHLY LIMIT //
      if (category_rule && category_rule?.monthlyDataList) {
        this.totalMileageBulkModel.expenseRequestDTOList[this.editlist ? 0 : index].monthlyDataList = category_rule.monthlyDataList;
      }

      if (!this.editlist) {
        if (selected_category_rule && selected_category_rule.limitType == 2 && selected_category_rule.ruleType == 1 && selected_category_rule.limitValue) {
          const list = this.totalMileageBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id && obj.expensedDate === expensedDate; });
          let filterd_cat_item = category_rule?.dailyDataList?.find((obj) => obj.categoryId === cat_id);
          let catAmount = this.sumofCatAmount(list, 'mileage', cat_id, filterd_cat_item ? false : true, expensedDate);
          console.log('total distae :', catAmount);
          // let divisionValue = selected_category_rule.ruleType == 1 ? this.numberToFixed((catAmount / this.daysCalculate('mileage'))) : catAmount;
          if (selected_category_rule.limitValue < (catAmount + (filterd_cat_item ? filterd_cat_item.distance : 0))) {
            this.totalMileageBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
          } else {
            this.totalMileageBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
              if (cat_id == element.categoryId) {
                this.totalMileageBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
              }
            });
          }
        } else if (selected_category_rule && selected_category_rule.limitType == 2 && selected_category_rule.ruleType == 2 && selected_category_rule.limitValue) {
          const list = this.totalMileageBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
          let catAmount = this.sumofCatAmount(list, 'mileage', cat_id, false);
          let filterd_cat_item = category_rule?.monthlyDataList?.find((obj) => obj.categoryId === cat_id);
          let final_diatnce = (catAmount ? catAmount : 0) + (filterd_cat_item ? filterd_cat_item.distance : 0);

          // let divisionValue = selected_category_rule.ruleType == 1 ? this.numberToFixed((catAmount / this.daysCalculate('mileage'))) : catAmount;
          if (selected_category_rule.limitValue < final_diatnce) {
            this.totalMileageBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
          } else {
            this.totalMileageBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
              if (cat_id == element.categoryId) {
                this.totalMileageBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
              }
            });
          }
        } else if (selected_category_rule && selected_category_rule.limitType == 2 && selected_category_rule.ruleType == 3 && selected_category_rule.limitValue) {
          const list = this.totalMileageBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
          let catAmount = this.sumofCatAmount(list, 'mileage', cat_id, false);
          let filterd_cat_item = category_rule?.yearlyDataList?.find((obj) => obj.categoryId === cat_id);
          let final_diatnce = (catAmount ? catAmount : 0) + (filterd_cat_item ? filterd_cat_item.distance : 0);
          if (selected_category_rule.limitValue < final_diatnce) {
            this.totalMileageBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
          } else {
            this.totalMileageBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
              if (cat_id == element.categoryId) {
                this.totalMileageBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
              }
            });
          }
        }
      } else {
        if (selected_category_rule && selected_category_rule.limitType == 2 && selected_category_rule.ruleType == 1 && selected_category_rule.limitValue) {
          const list = this.totalMileageBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
          let catAmount = this.sumofCatAmount(list, 'mileage', cat_id, false);
          let filterd_cat_item = category_rule?.dailyDataList?.find((obj) => obj.categoryId === cat_id);
          if (!filterd_cat_item) {
            if (this.navInfoData && this.navInfoData?.url == 'newreport' && this.navInfoData?.expenseList) {
              const list = this.navInfoData?.expenseList.filter((obj) => { return (obj.isSelected == true && obj.categoryId == cat_id && obj.expensedDate == expensedDate && obj.expenseId != this.editlist.expenseId) });
              if (list) {
                list.forEach(item => {
                  if (item.distance != '') {
                    catAmount += item.distance;
                  }
                });
              }
            }
          }
          // let divisionValue = selected_category_rule.ruleType == 1 ? this.numberToFixed((catAmount / this.daysCalculate('mileage'))) : catAmount;
          if (selected_category_rule.limitValue < catAmount) {
            this.totalMileageBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
          } else {
            this.totalMileageBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
              if (cat_id == element.categoryId) {
                this.totalMileageBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
              }
            });
          }
        } else if (selected_category_rule && selected_category_rule.limitType == 2 && selected_category_rule.ruleType == 2 && selected_category_rule.limitValue) {

          const list = this.totalMileageBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
          let catAmount = this.sumofCatAmount(list, 'mileage', cat_id, false);
          let filterd_cat_item = category_rule?.monthlyDataList?.find((obj) => obj.categoryId === cat_id);
          let final_diatnce = (catAmount ? catAmount : 0) + (filterd_cat_item ? filterd_cat_item.distance : 0);

          // let divisionValue = selected_category_rule.ruleType == 1 ? this.numberToFixed((catAmount / this.daysCalculate('mileage'))) : catAmount;
          if (selected_category_rule.limitValue < final_diatnce) {
            this.totalMileageBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
          } else {
            this.totalMileageBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
              if (cat_id == element.categoryId) {
                this.totalMileageBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
              }
            });
          }
        } else if (selected_category_rule && selected_category_rule.limitType == 2 && selected_category_rule.ruleType == 3 && selected_category_rule.limitValue) {
          const list = this.totalMileageBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryId === cat_id; });
          let catAmount = this.sumofCatAmount(list, 'mileage', cat_id, false);
          let filterd_cat_item = category_rule?.yearlyDataList?.find((obj) => obj.categoryId === cat_id);
          let final_diatnce = (catAmount ? catAmount : 0) + (filterd_cat_item ? filterd_cat_item.distance : 0);

          if (selected_category_rule.limitValue < final_diatnce) {
            this.totalMileageBulkModel.expenseRequestDTOList[index].categoryRuleLimitAmountWarning = true;
          } else {
            this.totalMileageBulkModel.expenseRequestDTOList.forEach((element, indexV) => {
              if (cat_id == element.categoryId) {
                this.totalMileageBulkModel.expenseRequestDTOList[indexV].categoryRuleLimitAmountWarning = false;
              }
            });
          }
        }

      }

    } else {
      index ? this.totalMileageBulkModel.expenseRequestDTOList[this.editlist ? 0 : index].categoryRuleLimitAmountWarning = false : '';
      index ? this.totalMileageBulkModel.expenseRequestDTOList[this.editlist ? 0 : index].categoryLimitAmountWarning = false : '';
    }
  }

  monthCalculation(fromdate, todate) {
    const date1Month = new Date(this.convertDateToUtc(fromdate)).getMonth() + 1;
    const date2Month = new Date(this.convertDateToUtc(todate)).getMonth() + 1;
    // console.log('months :', date1Month, date2Month);
    if (date1Month == date2Month) {
      return true;
    } else {
      return false;
    }
  }
  yearCalculation(fromdate, todate) {
    const date1Month = new Date(this.convertDateToUtc(fromdate)).getFullYear();
    const date2Month = new Date(this.convertDateToUtc(todate)).getFullYear();
    console.log('years :', date1Month, date2Month);
    if (date1Month == date2Month) {
      return true;
    } else {
      return false;
    }
  }

  BulkCreationValidation(type: any) {
    return;
  }
  mileageFormValidatoin() {
    if (this.navInfoData.url == 'newreport' && this.expenseTaggedTripList?.length == 0) {
      if (this.totalMileageBulkModel.expenseRequestDTOList.length == 1) {
        if (this.MileagebulkItemsForms.value[0].categoryName != null) {
          if (this.checkingAttMandatoryInForms('middle') || this.mileageDistance()) {
            return true;
          }
        }
      } else if (this.totalMileageBulkModel.expenseRequestDTOList.length > 1) {
        if (this.checkingAttMandatoryInForms('middle') || this.mileageDistance()) {
          return true;
        }
      }
    }
  }
  bulkFormValidation() {
    if (this.totalBulkModel.expenseRequestDTOList.length == 1) {
      if (this.totalBulkModel.expenseRequestDTOList[0].categoryName != undefined && this.totalBulkModel.expenseRequestDTOList[0].categoryName != null) {
        const attchamentNot_policy = this.totalBulkModel.expenseRequestDTOList.filter((element) => { return (element.policyLimitReceiptWarning || element.categoryLimitReceiptWarning) && !element.bulkImgUpload; });
        if (attchamentNot_policy && attchamentNot_policy.length != 0) {
          this.toastr.error('Error', 'Attachment is Mandatory As per the policy.', { timeOut: 3000 });
          return true;
        } else if (this.checkingAttMandatoryInForms('bottom') || this.bulkExpnseFixedAmount() || this.bulkExpenseCount()) {
          return true;
        }
      }

    } else if (this.totalBulkModel.expenseRequestDTOList.length > 1) {
      const attchamentNot_policy = this.totalBulkModel.expenseRequestDTOList.filter((element) => { return (element.policyLimitReceiptWarning || element.categoryLimitReceiptWarning) && !element.bulkImgUpload; });
      if (attchamentNot_policy && attchamentNot_policy.length != 0) {
        this.toastr.error('Error', 'Attachment is Mandatory As per the policy.', { timeOut: 3000 });
        return true;
      } else if (this.checkingAttMandatoryInForms('bottom') || this.bulkExpnseFixedAmount() || this.bulkExpenseCount()) {
        return true;
      }
    }
  }
  checkingAttMandatoryInForms(incoming) {
    if (incoming == 'bottom') {
      const attchamentNot = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.expenseAttachments.length == 0; });
      if (this.userInfo.isAttachmentMandatory && attchamentNot.length != 0) {
        this.toastr.error('Error', 'Please attach the reciept.', { timeOut: 3000 })
        return true;
      }
    } else if (incoming == 'middle') {
      const attchamentNot = this.totalMileageBulkModel.expenseRequestDTOList.filter((obj) => { return obj.expenseAttachments.length == 0; });
      if (this.userInfo.isAttachmentMandatory && attchamentNot.length != 0) {
        this.toastr.error('Error', 'Please attach the reciept.', { timeOut: 3000 })
        return true;
      }
    } else if (incoming == 'end' && this.sampleDt?.length != 0) {
      const attchamentNot = this.sampleDt.filter((obj) => { return obj.expenseAttachments.length == 0; });
      if ((this.userInfo.isAttachmentMandatory || this.perdiemRateSelected == "Rate with Receipt") && attchamentNot.length != 0) {
        this.toastr.error('Error', this.userInfo.isAttachmentMandatory ? 'Please attach the reciept.' : 'Please attach the reciept.')
        return true;
      }
    }

    // if (this.bulkFieldsMandatory || this.mileageFieldsMandatory || this.perdiemFieldsMandatory) {
    //   this.openFieldsMissingPopup();
    //   return;
    // }
  }

  bulkExpenseCount() {
    // const expense_count_list = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryCountLimitWarning == true && obj.limitType == 1 && obj.violationType == 2; });
    // if (expense_count_list.length != 0) {
      // this.toastr.error('Error', 'Category Rule - Number of Expenses Exceeded', { timeOut: 3000 });
    if (this.violationsData?.isBlock) {
      this.toastr.error('Error', this.violationsData?.isBlockMsg, { timeOut: 3000 });
      return true;
    }
  }
  bulkExpnseFixedAmount() {
    // const violations_list = this.totalBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryRuleLimitAmountWarning == true && obj.violationType == 2; });
    // if (violations_list.length != 0) {
      // this.toastr.error('Error', 'Category Rule - Expense Amount Exceeded', { timeOut: 3000 });
    if (this.violationsData?.isBlock) {
      this.toastr.error('Error', this.violationsData?.isBlockMsg, { timeOut: 3000 });
      return true;
    }
  }

  mileageDistance() {
    // const violations_list = this.totalMileageBulkModel.expenseRequestDTOList.filter((obj) => { return obj.categoryRuleLimitAmountWarning == true && obj.violationType == 2; });
    // if (violations_list.length != 0) {
      // this.toastr.error('Error', 'Category Rule - Distance Limit Exceeded', { timeOut: 3000 });
    if (this.violationsData?.isBlock) {
      this.toastr.error('Error', this.violationsData?.isBlockMsg, { timeOut: 3000 });
      return true;
    }
  }

  openFieldsMissingPopup() {
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.FieldsMissingTemp, config);
  }
  removeBulkattch(Expenseindex, fromPath) {
    console.log('incoming path ', fromPath, Expenseindex, this.bulkExActiveIndex);
    if (fromPath == 'bulk') {
      if (this.totalBulkModel.expenseRequestDTOList[Expenseindex].expenseAttachments.length == 1) {
        this.totalBulkModel.expenseRequestDTOList[Expenseindex].expenseAttachments = [];
        this.totalBulkModel.expenseRequestDTOList[Expenseindex].bulkImgUpload = false;
        this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[Expenseindex].expenseAttachments;
      } else {
        this.totalBulkModel.expenseRequestDTOList[Expenseindex].expenseAttachments.splice(this.bulkExActiveIndex, 1)
        this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[Expenseindex].expenseAttachments;
      }
    } else {
      if (this.totalMileageBulkModel.expenseRequestDTOList[Expenseindex].expenseAttachments.length == 1) {
        this.totalMileageBulkModel.expenseRequestDTOList[Expenseindex].expenseAttachments = [];
        this.totalMileageBulkModel.expenseRequestDTOList[Expenseindex].bulkImgUpload = false;
      } else {
        this.totalMileageBulkModel.expenseRequestDTOList[Expenseindex].expenseAttachments.splice(this.bulkExActiveIndex, 1)
      }
    }
    this.toastr.success("attachement removed successfully", "Success", { timeOut: 3000 });
  }

  attachmentExpenseIndex: any;
  removebtn(Expenseindex?, fromPath?) {
    if (this.active == 'middle') {
      if (this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments.length == 1) {
        this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments = [];
        this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].bulkImgUpload = false;
        this.previewAttachmentsArray = this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
      } else {
        this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex]?.expenseAttachments.splice(this.attachmentIndex, 1)
        this.previewAttachmentsArray = this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex]?.expenseAttachments[this.attachmentIndex];
      }
    } else if (this.active == 'bottom') {
      if (this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex]?.expenseAttachments.length == 1) {
        this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments = [];
        this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].bulkImgUpload = false;
        this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
      } else {
          this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments.splice(this.attachmentIndex, 1)
          this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments[this.attachmentIndex];
      }
      let isAlcohol = false;
      let gstMissMatch = false;
      let mobileMissMatch = false;
      this.totalBulkModel?.expenseRequestDTOList[this.attachmentExpenseIndex]?.expenseAttachments?.forEach(attch => {
        if(!isAlcohol && attch.isAlcoholAttach) {
          isAlcohol = true;
        }
        if(!gstMissMatch && attch.gstMissMatch) {
          gstMissMatch = true;
        }
        if(!mobileMissMatch && attch.mobileMissMatch) {
          mobileMissMatch = true;
        }
      })
      // this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].isAlcohol = isAlcohol;
      this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].gstMissMatch = gstMissMatch;
      this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].mobileMissMatch = mobileMissMatch;
    }
    // this.resetClose();
    this.toastr.success("attachement removed successfully", "Success", { timeOut: 3000 });
    this.closeAllModals();
    if (this.previewAttachmentsArray.length == 0) {
      // this.closeSelection();
      // this.closeAllModals();
    }
  }

  // currentFormTemplate: any;
  deleteConfirmationAlert(type, index, removeformTemp: TemplateRef<any>) {
    this.expensetype = type;
    if (this.expensetype == 'perdiem-field') {
      this.perdiemAttachmentIndex = undefined;
    }
    this.index = index;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false
    };
    this.modalRef = this.modalService.show(removeformTemp, config);
  }

  deleteCfFileConfirmation(removeformTemp: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false
    };
    this.modalRef = this.modalService.show(removeformTemp, config);
  }

  deleteCfFile() {
    console.log(this.totalBulkModel?.expenseRequestDTOList);
    console.log(this.totalBulkModel?.expenseRequestDTOList[this.previewExpIndex]);
    console.log(this.totalBulkModel?.expenseRequestDTOList[this.previewExpIndex]?.customFieldList[this.cfPreviewIndex]);
    console.log(this.totalBulkModel?.expenseRequestDTOList[this.previewExpIndex]?.customFieldList[this.cfPreviewIndex]?.customFieldValue[this.attachmentIndex]);
    
    this.modalRef?.hide();
    if(this.totalBulkModel?.expenseRequestDTOList[this.previewExpIndex]?.customFieldList[this.cfPreviewIndex]?.customFieldValue[this.attachmentIndex]) {
      this.totalBulkModel?.expenseRequestDTOList[this.previewExpIndex]?.customFieldList[this.cfPreviewIndex]?.customFieldValue?.splice(this.attachmentIndex, 1);
      console.log(this.totalBulkModel?.expenseRequestDTOList[this.previewExpIndex]?.customFieldList[this.cfPreviewIndex]?.customFieldValue);
      
    }
  }

  deleteOption() {
    this.modalRef.hide();
    if(this.proofofPaymentPreview){
      if(this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].proofOfPaymentFileUrl){
        this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].proofOfPaymentFileUrl = null;
      }
      this.toastr.success("attachement removed successfully", "Success", { timeOut: 3000 });
      this.closeAllModals();
    } else {
      if (this.perdiemAttachmentIndex != undefined && this.perdiemAttachmentIndex !== '' && this.perdiemAttachmentIndex >= 0) {
        this.deletePerdiemAttachment();
      } else if (this.expensetype == 'perdiem-field') {
        this.modalRef.hide();
        this.removeAttchement(this.index);
      } else {
        this.removebtn();
      }
    }
    
  }

  closeAllModals() {
    this.proofofPaymentPreview = false
    for (let i = 1; i <= this.modalService?.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  closeAttachmentDeleteModal() {
    for (let i = this.modalService.getModalsCount(); i > 1; i--) {
      this.modalService.hide(i);
    }
  }

  deletePerdiemAttachment() {
    this.sampleDt[this.perdiemAttachmentIndex].expenseAttachments = [];
    this.toastr.success("attachement removed successfully", "Success", { timeOut: 3000 });
    this.closeAllModals();
  }

  cancelCreateConfirmationAlert(removeformTemp: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false
    };
    this.modalRef = this.modalService.show(removeformTemp, config);
  }

  expensesOutsideType: string;
  currentTemplate: any;
  expenseOutsideStartEndDate(start, end) {
    let isExpenseOutside = false;
    // console.log('start _____ ', start, moment(start).format('DD-MM-YYYY'));
    // console.log('end _____ ', end, moment(end).format('DD-MM-YYYY'));
    // console.log(this.allExpenses);
    // if(moment(start).isUTC() || moment.isMoment(start)) {
    let cleanedDate = start?.toString().replace(/\(.*\)/, '').trim();
    if (!moment.utc(start, 'DD-MM-YYYY', true).isValid() || moment.isMoment(start) || this.checkDateFormat(start)) {
      start = moment(start).format('DD-MM-YYYY');
    }
    // if(moment(end).isUTC() || moment.isMoment(end)) {
    cleanedDate = end?.toString().replace(/\(.*\)/, '').trim();
    if (!moment.utc(end, 'DD-MM-YYYY', true).isValid() || moment.isMoment(end) || this.checkDateFormat(end)) {
      end = moment(end).format('DD-MM-YYYY');
    }
    // console.log('start _____ ', start);
    // console.log('end _____ ', end);
    this.allExpenses.forEach(s => {
      // console.log(s.expensedDate, isExpenseOutside);
      // console.log('isSameOrAfter : ', moment(s.expensedDate, 'DD-MM-YYYY').isSameOrAfter(moment(start, 'DD-MM-YYYY')));
      // console.log('isSameOrBefore : ', moment(s.expensedDate, 'DD-MM-YYYY').isSameOrBefore(moment(end, 'DD-MM-YYYY')));
      // console.log('\n');

      if (moment(s.expensedDate, 'DD-MM-YYYY').isSameOrAfter(moment(start, 'DD-MM-YYYY')) && moment(s.expensedDate, 'DD-MM-YYYY').isSameOrBefore(moment(end, 'DD-MM-YYYY')) && !isExpenseOutside) {
        isExpenseOutside = false;
      } else {
        isExpenseOutside = true;
      }
    })
    return isExpenseOutside;
  }

  openExpensesOutsideAlert() {
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.currentTemplate, config);
  }


  emptyClaimStatus: any;
  isUpdate = false;
  setBulkEditData(expenseId, expenseList) {
    this.isUpdate = true;

    // console.log(this.expenseTaggedTripList);
    if(this.expenseTaggedTripList?.length > 0) {
      this.trip_estimate = this.expenseTaggedTripList[0]?.tripEstimateInUSD || 0;
      this.tripEstimateInUSD = this.expenseTaggedTripList[0]?.tripEstimateInUSD || 0;
    }
    
    if(this.isTakePictureClicked) {
      this.enableUpdate = false;
      this.isTakePictureClicked = false;
    } else {
      this.enableUpdate = true;
    }
    this.editform = expenseList;
    let expensesCount = 0;
    let mileageCount = 0;
    this.sampleDt = [];
    expenseList.forEach(e => {
      if(e.cityId != null || e.isSpentLocationManditory){
        e.isSpentLocationManditory = true;
      }else{
        e.isSpentLocationManditory = false;
      }

      if(e.expenseEndDate != null || e.isStartEndDateEnabled){
        e.isStartEndDateEnabled = true;
      }else{
        e.isStartEndDateEnabled = false;
      }
      // console.log(e);
      // console.log(e.isSelected, e.categoryType, e.expenseType);

      // Expense
      if (e.isSelected && e.categoryType == 1 && e.expenseType == 1) {
        this.expenseTabIndex = 0;
        if (expenseId == e.expenseId) {
          this.expenseTabIndex = 0;
          this.active = 'bottom';
        }
        expensesCount++;
        // console.log(e);
        e.expConvRate = e.expConvRate || e.exchangeRate;
        e.expenseUserSplitList = e.expenseUserSplitList || [];
        let currentUserList = [];
        e.expenseUserSplitList?.forEach(u => {
          currentUserList.push(u.userName);
        })

        e.expenseAttachments = e.expenseAttachments || [];
        if (e.expenseAttachments.length > 0) {
          e.bulkImgUpload = true
        }
        e.userlists = currentUserList;
        e.expensedDate = moment(e.expensedDate, 'DD-MM-YYYY');
        e.currencyCode = e.originalCurrencyCode;
        // e.customFields = e.customFieldList;
        // let expenseHasPDF = false;
        // e.expenseAttachments.forEach(attach => {
        //   if(this.returnExtension(attach.fileName) == 'pdf' && !expenseHasPDF) {
        //     expenseHasPDF = true;
        //   }
        // })
        // expenseHasPDF = false;
        // if(expenseHasPDF) {
        //   this.getAttachmentsList([e.expenseId], e, 'expense')
        // } else {

        e.customFieldList?.forEach(cf_ele => {
          if(this.isValidJSON(cf_ele.customFieldValue)) {
            cf_ele.customFieldValue = this.convertToObj(cf_ele.customFieldValue);
          }
        });

        e.currencyCode = e.currencyCode ? e.currencyCode : this.userInfo?.baseCurrency?.currencyCode

        this.totalBulkModel.expenseRequestDTOList.push(e);
        let expenseIndex = this.totalBulkModel?.expenseRequestDTOList?.length;
        this.bulkItemsForms.push(this.addBulkFormGroup(e, true, expenseIndex));

        // Mileage
      } else if (e.isSelected && e.categoryType == 2 && e.expenseType == 1) {
        this.expenseTabIndex = 1;
        if (expenseId == e.expenseId) {
          this.expenseTabIndex = 1;
          this.active = 'middle';
        }
        mileageCount++;

        if (this.isAdminView) {
          e.showOptions = true;
        }
        e.expenseAttachments = e.expenseAttachments || [];
        e.checkPointsList = e.checkPointsList || [];
        // let expenseHasPDF = false;
        // e.expenseAttachments.forEach(attach => {
        //   if(this.returnExtension(attach.fileName) == 'pdf' && !expenseHasPDF) {
        //     expenseHasPDF = true;
        //   }
        // })
        // expenseHasPDF = false;
        // if(expenseHasPDF) {
        //   this.getAttachmentsList([e.expenseId], e, 'mileage')
        // } else {
        if(this.userInfo?.showGoogleLocationSearch) {
          e.fromLocationName = {"title": e.fromLocationName || null}
          e.toLocationName = {"title": e.toLocationName || null}
        }
        this.totalMileageBulkModel.expenseRequestDTOList.push(e);
        let expenseIndex = this.totalMileageBulkModel?.expenseRequestDTOList?.length;
        this.MileagebulkItemsForms.push(this.addMileageBulkFormGroup(e, true, expenseIndex));
        // }
        // this.totalMileageBulkModel.expenseRequestDTOList.push(e);
        // this.MileagebulkItemsForms.push(this.addMileageBulkFormGroup(e));
        // PERDIEM
      } else if (e.isSelected && e.categoryType == 1 && e.expenseType == 5) {
        this.expenseTabIndex = 2;
        if (expenseId == e.expenseId) {
          this.expenseTabIndex = 2;
          this.active = 'end';
        }

        this._perdiemEditForm = true;
        this.perdiemModel.PDlocation = e.locationName;
        this.perdiemModel.PDlocationId = e.location;
        this.perdiemModel.PDCity = e.cityName;
        this.perdiemModel.PDCityId = e.cityId;
        this.perdiemModel.PDrate = e.perDiemRateName;

        this.perdiemForm.controls.PDlocation.patchValue(e.locationName);
        this.perdiemForm.controls.PDCity.patchValue(e.cityName);
        this.perdiemForm.controls.PDclaimName.patchValue(e.claimName);
        this.perdiemForm.controls.PDclaimId.patchValue(e.claimId);
        this.perdiemForm.controls.PDrate.patchValue(e.perDiemRateName);

        this.perdiemModel.PDradioSelection = e.perDiemType;
        this.perdiemModel.PDradioSelectionName = e.perDiemTypeName;
        this.perdiemForm.controls.PDtype.patchValue(e.perDiemTypeName);
        this.perdiemForm.controls.PDstartDate.patchValue(this.convertDateToUtc(e.startDate));
        this.perdiemForm.controls.PDendDate.patchValue(this.convertDateToUtc(e.endDate));
        this.perdiemModel.PDstartDate = e.startDate;
        this.perdiemModel.PDendDate = e.endDate;
        this.perdiemModel.PDstartDateFormate = moment(e.startDate, 'DD-MM-YYYY');
        this.perdiemModel.PDendDateFormate = moment(e.endDate, 'DD-MM-YYYY');
        this.perdiemModel.PDstartTime = e.startTime;
        this.perdiemModel.PDendTime = e.endTime;
        this.perdiemModel.bandId = e.bandId;
        this.perdiemModel.bandName = e.bandName;
        this.perdiemModel.departmentId = e.departmentId;
        this.perdiemModel.departmentName = e.departmentName;
        this.perdiemModel.costCenterId = e.costCenterId;
        this.perdiemModel.costCenterName = e.costCenterName;
        this.perdiemModel.projectCodeId = e.projectCodeId;
        this.perdiemModel.projectCodeName = e.projectCodeName;
        this.perdiemModel.projectCodeName = e.projectCodeName;
        this.perdiemModel.originalApproveAmount = e.originalApproveAmount || null;
        this.perdiemModel.customFieldList?.forEach((cf, ind) => {
          const cfIndex = e.customFieldList?.findIndex(c => c.fieldId == cf.fieldId);
          if (cfIndex >= 0) {
            this.perdiemModel.customFieldList[ind] = e.customFieldList[cfIndex];
          }
        });

        this.getPolicyApi('', moment().format('DD-MM-YYYY'), 0, '', '', true)

        this.sampleDt.push({
          expenseDate: e.expensedDate,
          description: e.description,
          discription: e.description,
          originalAmount: e.originalAmount,
          originalCurrencyCode: e.originalCurrencyCode,
          originalCurrencyIcon: e.originalCurrencyIcon,
          originalCurrencyId: e.originalCurrencyId,
          expenseAttachments: e.expenseAttachments || [],
          // duration: e.perDiemType == 3 ?
          //   `${e.startTime} - ${e.endTime}` : e.perDiemTypeName,
          duration: e.duration,
          perDiemRate: e.perDiemRate,
          perDiemType: e.perDiemType,
          startDate: e.startDate,
          startTime: e.startTime,
          endDate: e.endDate,
          endTime: e.endTime,
          categoryId: 1,
          expenseType: 5,
          recordType: 1,
          exchangeRate: 1,
          expenseId: e.expenseId ? e.expenseId : null,
          expenseGUID: e.expenseGUID ? e.expenseGUID : null,
          financeIntegrationCode: e.financeIntegrationCode ? e.financeIntegrationCode : null,
          financeIntegrationId: e.financeIntegrationId ? e.financeIntegrationId : null,
          financeIntegrationName: e.financeIntegrationName ? e.financeIntegrationName : null,
          taxCode: e.taxCode ? e.taxCode : null,
          hsnCode: e.hsnCode ? e.hsnCode : null,
          taxCodeId: e.taxCodeId ? e.taxCodeId : null,
          originalApproveAmount: e.originalApproveAmount || null,
        })

        this.perdiemModel.PDhsnCode = e.hsnCode ? e.hsnCode : null
        this.perdiemModel.PDtaxNumner = e.financeIntegrationCode ? e.financeIntegrationCode : null
        this.perdiemModel.PDtaxPercentage = e.taxCode ? e.taxCode : null
      }
      if(this.expenseTabIndex == 0) {
        this.modalHeader = 'Update Expense';
        this.expenseTabLabel = 'Expenses';
        this.active = 'bottom';
      } else if (this.expenseTabIndex == 1) {
        this.modalHeader = `Update ${(this.userInfo?.mileageTabLabel && this.userInfo?.mileageTabLabel != '') ? this.userInfo?.mileageTabLabel : 'Mileage'}`;
        this.expenseTabLabel = 'Mileage';
        this.active = 'middle';
      } else if (this.expenseTabIndex == 2) {
        this.modalHeader = 'Update Perdiem';
        this.expenseTabLabel = 'Perdiem';
        this.active = 'end';
      }
    })

    // expenseList.forEach(e => {
    //   if (e.expenseId == expenseId) {
    //     setTimeout(() => {
    //       document.getElementById(e.expenseCode)?.scrollIntoView();
    //     }, 500)
    //   }
    // })
    if (expensesCount > 0) {
      this.totalBulkModel.expenseRequestDTOList.splice(0, 1);
      this.bulkItemsForms.removeAt(0);
    }
    if (mileageCount > 0) {
      this.totalMileageBulkModel.expenseRequestDTOList.splice(0, 1);
      this.MileagebulkItemsForms.removeAt(0);
    }
    this.getTotalAmount();
  }

  async compressImage(image: any): Promise<string> {
    const img = new Image();
    img.src = image;
    await new Promise(resolve => img.onload = resolve);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 500; // set the desired width of the compressed image
    canvas.height = canvas.width * (img.height / img.width);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL("image/jpeg", 0.7); // set the desired image quality

    return dataURL;
  }

  getAttachmentsList(expenseIds, expense, type, index?, template?: TemplateRef<any>) {
    let requestM = new GetExpenseModels();
    requestM.expenseIds = expenseIds;
    requestM.legalEntityId = this.userInfo.legalEntityId;
    requestM.legalEntityGUID = this.userInfo.legalEntityGuId;

    this.expenseService.expenseAttachViewer(requestM).subscribe((res) => {
      expense.expenseAttachments = res.payload.attachementList || [];
      expense.expenseAttachments.forEach(att => {
        att.content = att.base64
      })

      if (this.active == 'bottom') {
        this.previewAttachmentsArray = this.totalBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
      } else if (this.active == 'top') {
        this.previewAttachmentsArray = this.expenseModel.expenseAttachments;
      } else if (this.active == 'middle') {
        this.previewAttachmentsArray = this.totalMileageBulkModel.expenseRequestDTOList[this.attachmentExpenseIndex].expenseAttachments;
      } else if (this.active == 'end') {
        this.previewAttachmentsArray = this.sampleDt[index].expenseAttachments;
        this.attachmentIndex = 0;
        this.perdiemAttachmentIndex = index;
        this.expensetype = 'perdiem-attachment';
      }
      const config = {
        ignoreBackdropClick: false,
        class: "modal-dialog-centered modal-lg baa",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(template, config);
    })
  }

  // getAttachmentsList(expenseIds, expense, type) {
  //   let requestM = new GetExpenseModels();
  //   requestM.expenseIds = expenseIds;
  //   requestM.legalEntityId = this.userInfo.legalEntityId;
  //   requestM.legalEntityGUID = this.userInfo.legalEntityGuId;

  //   this.expenseService.expenseAttachViewer(requestM).subscribe((res) => {
  //     expense.expenseAttachments = res.payload.attachementList || [];
  //     expense.expenseAttachments.forEach(att => {
  //       att.content = att.base64
  //     })

  //     if (type == 'expense') {
  //       this.totalBulkModel.expenseRequestDTOList.push(expense);
  //       this.bulkItemsForms.push(this.addBulkFormGroup(expense, true));
  //     } else if (type == 'mileage') {
  //       this.totalMileageBulkModel.expenseRequestDTOList.push(expense);
  //       this.MileagebulkItemsForms.push(this.addMileageBulkFormGroup(expense, true));
  //     }
  //   })
  // }

  deleteTrip(trip) {
    console.log('redicrect value is :', trip);
    const obj = {
      "id": trip.id,
      "GUID": this.userInfo.legalEntityGuId,
      "claimId": trip.claimId,
      "claimGUID": trip.claimGUID,
      "tripId": trip.tripId,
      "tripGUID": trip.tripGUID
    }
    this.expenseService.deleteClaimTrip(obj).subscribe((res: any) => {
      if (!res.isError) {
        // this.expenseTaggedTripList.splice(this.index, 1);
        this.expenseTaggedTripList = [];
        this.TravelAdvanceSummary = null;
        this.toastr.success(res.payload, 'Success');
      }
    })
  }

  setExpensesMaxLimit() {
    if (this.totalExpensesCount >= 25) {
      this.expensesMaxLimit = true;
    }
    if (this.totalMileagesCount >= 25) {
      this.mileagesMaxLimit = true;
    }
  }

  deleteExpense(id, type, index) {
    const obj = {
      "expenseIds": [id],
      "userId": this.userInfo.userId,
      "userGUID": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "legalEntityGUID": this.userInfo.legalEntityGuId
    }
    this.expenseService.deleteExpense(obj).subscribe((res: any) => {
      if (!res.isError) {
        if (type == 'Expense') {
          this.totalBulkModel.expenseRequestDTOList.splice(index, 1);
          this.bulkItemsForms.removeAt(index);
        } else if (type == 'mileage') {
          this.totalMileageBulkModel.expenseRequestDTOList.splice(index, 1);
          this.MileagebulkItemsForms.removeAt(index);
        } else if (type == 'perdiem') {
          this.sampleDt[index].expenseAttachments = [];
          this.sampleDt.splice(index, 1)
          this.getTotalAmount();
        }
      }
    })
  }


  onInputFocus(id) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      setTimeout(() => {
        document.getElementById(id).scrollIntoView();
      }, 500)
    }
  }

  getSystemFieldsList() {
    // const requestModel = {
    //   "systemFieldList": [],
    //   "customFieldList": [],
    //   "searchText": "",
    //   "legalEntityGUID": "0988c9ef8b4d7dbb8ad203db3f7151f1",
    //   "legalEntityId": 423,
    //   "companyId": 346,
    //   "companyGUID": "1f0c1d0af398c29d0175e9fb3a3eec60",
    //   "formType": 1
    // }
    // this.commonService.getSystemFieldsList(requestModel).subscribe((response) => {
    //   this.customFields = response.payload.systemFieldList;
    //   console.log('\n\n\n');
    //   console.log('CUSTOM FIELDS __________ ', this.customFields);
    //   console.log('totalBulkForm __________ ', this.totalBulkForm?.controls.bulkItems);
    //   const formControlsConfig = {};
    //   this.customFields.forEach(field => {
    //     formControlsConfig[field.fieldName] = [null, field.isMandatory ? Validators.required : null];
    //   });
    //   console.log('\n\n\n');
    // })
    const requestModel = {
      "legalEntityGUID": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    // console.log('requestModel _________ ', requestModel);
    // this.commonService.getFieldsInfo(requestModel).subscribe((response) => {
    //   this.customFields = response.payload;
    //   console.log('\n\n\n');
    //   console.log('CUSTOM FIELDS __________ ', this.customFields);
    //   console.log('\n\n\n');
    // })

  }

  expId: any;
  customField: any;
  customFieldIndex: any;
  // open_custom_dropdown(index, type) {
  //   this.customField = type;
  //   this.customFieldIndex = index;
  // }

  showCustomSubMenu(type, index, expId) {
    this.expId = expId;
    this.customField = type;
    this.customFieldIndex = index;
    // e.preventDefault();
    // e.stopPropagation();
    setTimeout(() => {
      this.customAuto.nativeElement.focus();
    }, 0);
  }

  getCustomOptionsList(dropDownList) {
    return dropDownList.split(',').map(d => d.trim());
  }

  selectedDropdown(ev, i, field, type, form?: FormGroup) {
    // console.log(field, type);
    if (field == 'band') {
      if (type == 'expense') {
        this.totalBulkModel.expenseRequestDTOList[i].bandId = ev.bandId;
        this.totalBulkModel.expenseRequestDTOList[i].bandName = ev.bandName;
      } else if (type == 'mileage') {
        this.totalMileageBulkModel.expenseRequestDTOList[i].bandId = ev.bandId;
        this.totalMileageBulkModel.expenseRequestDTOList[i].bandName = ev.bandName;
      } else if (type == 'perdiem') {
        this.perdiemModel.bandId = ev.bandId;
        this.perdiemModel.bandName = ev.bandName;
        this.perdiemForm.controls.bandId.patchValue(ev.bandId);
      }
      form?.controls.bandId.patchValue(ev.bandId);
      form?.controls.bandName.patchValue(ev.bandName);
    } else if (field == 'department') {
      // const index = this.departments.findIndex(c => c.departmentId == ev.option.value);
      if (type == 'expense') {
        // this.totalBulkModel.expenseRequestDTOList[i].departmentId = ev.option.value;
        // this.totalBulkModel.expenseRequestDTOList[i].departmentName = this.departments[index].departmentName;
        this.totalBulkModel.expenseRequestDTOList[i].departmentId = ev.departmentId;
        this.totalBulkModel.expenseRequestDTOList[i].departmentName = ev.departmentName;
      } else if (type == 'mileage') {
        this.totalMileageBulkModel.expenseRequestDTOList[i].departmentId = ev.departmentId;
        this.totalMileageBulkModel.expenseRequestDTOList[i].departmentName = ev.departmentName;
      } else if (type == 'perdiem') {
        this.perdiemModel.departmentId = ev.departmentId;
        this.perdiemModel.departmentName = ev.departmentName;
        this.perdiemForm.controls.departmentId.patchValue(ev.departmentId);
      }
      // form.controls.departmentId.patchValue(ev.option.value);
      // form.controls.departmentName.patchValue(this.departments[index].departmentName);
      form?.controls.departmentId.patchValue(ev.departmentId);
      form?.controls.departmentName.patchValue(ev.departmentName);
    } else if (field == 'costCenter') {
      // const index = this.costCenters.findIndex(c => c.costCenterId == ev.option.value);
      if (type == 'expense') {
        // this.totalBulkModel.expenseRequestDTOList[i].costCenterId = ev.option.value;
        // this.totalBulkModel.expenseRequestDTOList[i].costCenterName = this.costCenters[index].costCenterName;
        this.totalBulkModel.expenseRequestDTOList[i].costCenterId = ev.costCenterId;
        this.totalBulkModel.expenseRequestDTOList[i].costCenterName = ev.costCenterName + ' - ' + ev.costCenterCode;
        this.totalBulkModel.expenseRequestDTOList[i].costCenterCode = ev.costCenterCode;
      } else if (type == 'mileage') {
        this.totalMileageBulkModel.expenseRequestDTOList[i].costCenterId = ev.costCenterId;
        this.totalMileageBulkModel.expenseRequestDTOList[i].costCenterName = ev.costCenterName + ' - ' + ev.costCenterCode;
        this.totalMileageBulkModel.expenseRequestDTOList[i].costCenterCode = ev.costCenterCode;
      } else if (type == 'perdiem') {
        this.perdiemModel.costCenterId = ev.costCenterId;
        this.perdiemModel.costCenterName = ev.costCenterName + ' - ' + ev.costCenterCode;
        this.perdiemForm.controls.costCenterId.patchValue(ev.costCenterId);
      }
      // form.controls.costCenterId.patchValue(ev.option.value);
      // form.controls.costCenterName.patchValue(this.costCenters[index].costCenterName);
      form?.controls.costCenterId.patchValue(ev.costCenterId);
      form?.controls.costCenterName.patchValue(ev.costCenterName + ' - ' + ev.costCenterCode);
    } else if (field == 'projectCode') {
      // const index = this.projectCodes.findIndex(c => c.projectId == ev.option.value);
      if (type == 'expense') {
        // this.totalBulkModel.expenseRequestDTOList[i].projectCodeId = ev.option.value;
        // this.totalBulkModel.expenseRequestDTOList[i].projectCodeName = this.projectCodes[index].projectName;
        this.totalBulkModel.expenseRequestDTOList[i].projectCodeId = ev.projectId;
        this.totalBulkModel.expenseRequestDTOList[i].projectCodeName = ev.projectName;
      } else if (type == 'mileage') {
        this.totalMileageBulkModel.expenseRequestDTOList[i].projectCodeId = ev.projectId;
        this.totalMileageBulkModel.expenseRequestDTOList[i].projectCodeName = ev.projectName;
      } else if (type == 'perdiem') {
        this.perdiemModel.projectCodeId = ev.projectId;
        this.perdiemModel.projectCodeName = ev.projectName;
        this.perdiemForm.controls.projectCodeId.patchValue(ev.projectId);
      }
      // form.controls.projectCodeId.patchValue(ev.option.value);
      // form.controls.projectCodeName.patchValue(this.projectCodes[index].projectName);
      form?.controls.projectCodeId.patchValue(ev.projectId);
      form?.controls.projectCodeName.patchValue(ev.projectName);
    }
    // console.log('DROP ___ ', this.perdiemModel);
    // console.log(this.totalBulkModel.expenseRequestDTOList[i]);
    // console.log(form);
  }

  selectedCustomOption(ev, index, expId, type) {
    // console.log('DROP ___ ', ev, index, expId);
    // console.log(index, this.totalBulkModel.expenseRequestDTOList);
    // console.log(index, this.totalBulkModel.expenseRequestDTOList[expId].customFieldList);
    if (type == 'expense') {
      this.totalBulkModel.expenseRequestDTOList[expId].customFieldList[index].customFieldValue = ev;
    } else if (type == 'mileage') {
      this.totalMileageBulkModel.expenseRequestDTOList[expId].customFieldList[index].customFieldValue = ev;
    } else if (type == 'perdiem') {
      this.perdiemModel.customFieldList[index].customFieldValue = ev;
    }
  }

  selectedCustomDate(ev, index, expId, type) {
    // console.log('DATE ___ ', index, expId);
    // console.log('expId ___ ', this.expId);
    // console.log('customFieldIndex ___ ', this.customFieldIndex);
    // console.log(index, this.totalBulkModel.expenseRequestDTOList);
    // console.log(index, this.totalBulkModel.expenseRequestDTOList[this.expId].customFieldList);
    if (type == 'expense') {
      this.totalBulkModel.expenseRequestDTOList[this.expId].customFieldList[this.customFieldIndex].customFieldValue = this.convertDateToUtc(ev.target.value);
    } else if (type == 'mileage') {
      this.totalMileageBulkModel.expenseRequestDTOList[this.expId].customFieldList[this.customFieldIndex].customFieldValue = this.convertDateToUtc(ev.target.value);
    } else if (type == 'perdiem') {
      this.perdiemModel.customFieldList[index].customFieldValue = this.convertDateToUtc(ev.target.value);
    }
  }

  budgetRefNumber: any;
  toggleExpanded(index: number, item: any) {
    console.log(item);
    this.budgetRefNumber = item?.budgetRefNumber || null;
    this.disablenextbtn = true;
    if (item.serviceList.length == 0) {
      let obj = {
        tripId: item.tripId, tripGUID: item.tripGUID,
        legalEntityGuid: this.userInfo?.legalEntityGuId,
        legalEntityId: this.userInfo?.legalEntityId,
      }
      this.reportService.getTripServiceList(obj).subscribe(response => {
        if (response.errorCode == 0) {
          let statusidcount:number = 0;
          // this.tripdetailview = response.payload.serviceList;
          response.payload.serviceList.forEach(element => {
            element.CompanyPaid = true;
            element.EmployeePaid = false;
          });
          response?.payload?.serviceList?.forEach(element => {
                        element.claimTypeId = response?.payload?.claimTypeId;
                        element.claimTypeName = response?.payload?.claimTypeName;
          });
          this.tripsList[index].serviceList = response.payload.serviceList;
          this.tripdetailview = response.payload.serviceList;
          this.tripsList[index].advanceSummary = response.payload.advanceSummary;
          this.tripTravellers = response?.payload.tripTravellers;
          this.TravelAdvanceSummary = response.payload.advanceSummary;
          this.toPathInfoData.tripEstimateDetails = response?.payload?.tripEstimateDetails;
          this.trip_customFieldsList = response.payload.customFields || null;
          // this.trip_customFieldsList?.forEach(t => {
          //   t.key = t.key.replace(/_/g, ' ');
          // });
          this.trip_customFields = response.payload.customFields || null;
          this.trip_reasonForTravel = response.payload.reasonForTravel || null;
          this.trip_bookingType = response.payload.bookingType || null;
          this.trip_bookingTypeName = response.payload.bookingTypeName || null;
          this.trip_costCenterCode = response.payload.costCenterCode || null;
          
          this.tripdetailview.forEach(element => {
            if(element.statusId != 3 && element.statusId != 6 && element.statusId != 11 && element.statusId != 13)
            {
             statusidcount++;
             if(statusidcount > 0)
             {
              this.disablenextbtn = false;
             }
             
            }
          });
        }
      
       
      });
    } else {
      this.disablenextbtn = true;
      let statusidcount:number = 0;
      item.serviceList.forEach(element => {
        if(element.statusId != 3 && element.statusId != 6 && element.statusId != 11 && element.statusId != 13)
        {
          statusidcount++;
          if( statusidcount > 0)
          {
           this.disablenextbtn = false;
          }
          
        }
      });
      this.tripdetailview = item?.serviceList;
    }

    if (this.tripsList[index].isMultiService == true) {
      if (this.tripsList[index].isExpanded == true) {
        this.tripsList[index].isExpanded = false;
        this.tripsList[index].isSelected = false;
        this.selectedTrips = [];
        this.claimTripCost = 0;
      } else {
        this.tripsList[index].isExpanded = true;
        this.tripsList[index].isSelected = true;
        this.selectedTrips = [];
        this.claimTripCost = 0;
        this.selectedTrips.push(item);
        this.claimTripCost = parseFloat(item?.tripCost?.toString());
      }
    } else {
      this.tripsList[index].isExpanded = false;
      this.tripsList[index].isSelected = true;
      this.selectedTrips = [];
      this.claimTripCost = 0;
      this.selectedTrips.push(item);
      this.claimTripCost = parseFloat(item?.tripCost?.toString());
    }

    this.tripsList.forEach((element, indexof) => {
      if (indexof != index) {
        element.isExpanded = false;
        element.isSelected = false;
      }
    });
  }

  formatDate(date) {
    if (date) {
      return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');
    }
  }
  removeTaggedTrips(removedeleteTemp?: TemplateRef<any>) {
    this.expensetype = 'trip';
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(removedeleteTemp, config);
  }

  checkDateFormat(date) {
    let cleanedDate = date?.toString().replace(/\(.*\)/, '').trim();
    return (!moment.utc(cleanedDate, 'DD-MM-YYYY', true).isValid() && !moment(cleanedDate, 'ddd MMM DD YYYY HH:mm:ss ZZ', true).isValid())
  }

  onBandsSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.bandsTemp != null) {
      if (searchText != "") {
        let filterdata = this.bandsTemp.filter((data) => {
          if (data.bandName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return data;
          }
        });
        this.bands = filterdata;
      } else {
        this.bands = this.bandsTemp;
      }
    }
  }

  onDepartmentSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.departmentsTemp != null) {
      if (searchText != "") {
        let filterdata = this.departmentsTemp.filter((data) => {
          if (data.departmentName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return data;
          }
        });
        this.departments = filterdata;
      } else {
        this.departments = this.departmentsTemp;
      }
    }
  }

  eventProp(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setTimeout(() => {
      this.claimCategoryfocus?.nativeElement.focus();
    }, 100);
  }

  onCostCenterSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.costCentersTemp != null) {
      if (searchText != "") {
        let filterdata = this.costCentersTemp.filter((data) => {
          if (data.costCenterName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return data;
          }
        });
        this.costCenters = filterdata;
      } else {
        this.costCenters = this.costCentersTemp;
      }
    }
  }

  onProjectCodeSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.projectCodesTemp != null) {
      if (searchText != "") {
        let filterdata = this.projectCodesTemp.filter((data) => {
          if (data.projectName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return data;
          }
        });
        this.projectCodes = filterdata;
      } else {
        this.projectCodes = this.projectCodesTemp;
      }
    }
  }

  open_files = false;
  chooseImage() {
    this.open_files = true;
  }

  isTakePictureClicked = false;
  takeImage(expenseIndex) {
    let expensesList = [];

    // expense
    this.totalBulkModel.expenseRequestDTOList.forEach((e) => {
        e.expenseType = 1;
        e.categoryType = 1;
        e.isSelected = true;
        e.expensedDate = this.dateFormatAPI(e.expensedDate);
        expensesList.push(e);
    })

    // mileage
    this.MileagebulkItemsForms.value.forEach((m, mi) => {
        m.recordType = 1;
        m.expenseType = 1;
        m.categoryType = 2;
        m.isSelected = true;
        m.expensedDate = this.dateFormatAPI(m.expensedDate);
        m.expenseId = this.totalMileageBulkModel.expenseRequestDTOList[mi].expenseId;
        m.categoryId = this.totalMileageBulkModel.expenseRequestDTOList[mi].categoryId;
        m.expenseGUID = this.totalMileageBulkModel.expenseRequestDTOList[mi].expenseGUID;
        m.customFieldList = this.totalMileageBulkModel.expenseRequestDTOList[mi].customFieldList || [];
        expensesList.push(m);
    });

    // perdiem    
    this.sampleDt.forEach(p => {
      p.recordType = 1;
      p.categoryId = 1;
      p.expenseType = 5;
      p.categoryType = 1;
      p.exchangeRate = 1;
      p.isSelected = true;
      p.endDate = this.perdiemModel.PDendDate;
      p.startDate = this.perdiemModel.PDstartDate;
      p.perDiemRateName = this.perdiemModel.PDrate;
      p.legalEntityId = this.userInfo.legalEntityId;
      p.locationId = this.perdiemModel.PDlocationId;
      p.locationName = this.perdiemModel.PDlocation;
      p.legalEntityGUID = this.userInfo.legalEntityGuId;
      p.expenseDate = this.dateFormatAPI(p.expenseDate);
      p.perDiemType = this.perdiemModel.PDradioSelection;
      p.perDiemTypeName = this.perdiemModel.PDradioSelectionName;
      p.discription = this.perdiemForm.controls["PDdescription"].value;
      p.description = this.perdiemForm.controls["PDdescription"].value;
      p.perDiemRate = this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2;
      expensesList.push(p);
    })

    let obj = {
      from: 'takePicture-Expenses',
      expenseIndex: expenseIndex,
      tabIndex: this.expenseTabIndex,
      claimName: this.totalBulkForm.value.claimName,
      claimEndDate: this.dateFormatAPI(this.totalBulkForm.value.claimEndDate),
      claimStartDate: this.dateFormatAPI(this.totalBulkForm.value.claimStartDate),
      claimStartTime: this.totalBulkForm.value.claimStartTime || 0,
      claimEndTime: this.totalBulkForm.value.claimEndTime || 0,
      claimCategory:this.totalBulkForm.value.claimCategory,
      claimCategoryId: this.totalBulkForm.value.claimCategoryId,
      claimTypeId: this.totalBulkForm.value.claimTypeId,
      claimTypeName: this.totalBulkForm.value.claimTypeName,
      expensesList: expensesList,
      claimTaggedTripList: this.expenseTaggedTripList,
      tripTravellers:this.tripTravellers
    }

    this.isTakePictureClicked = true;
    sessionStorage.setItem('toPathInfo', JSON.stringify(obj));
    this.navControlr.navigateRoot(['camera']);
  }

  dateFormatAPI(date) {
    if (!moment.utc(date, 'DD-MM-YYYY', true).isValid() || moment.isMoment(date) || this.checkDateFormat(date)) {
      return moment(date).format('DD-MM-YYYY');
    } else {
      return date;
    }
  }

  addPerdiemRateList(type) {
    // const index = this.perdiemRateList.findIndex(p => p == type);    
    // if(index < 0) {
    //   this.perdiemRateList.push(type)
    // }
  }

  showDocumentReferences(attachPreview: TemplateRef<any>, index) {
    this.attachmentIndex = index ? index : 0;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    if (this.returnExtension(this.documentReferenceURLs[index]?.url) == 'pdf') {
      this.getPdfAsBase64(this.documentReferenceURLs[index].url).then((baseRes) => {
        this.documentReferenceURLs[index].content = `data:application/pdf;base64,${baseRes}`;
        this.modalRef = this.modalService.show(attachPreview, config);
      })
    } else {
      this.modalRef = this.modalService.show(attachPreview, config);
    }
  }

  preDocAttach() {
    if (this.attachmentIndex != 0) {
      this.attachmentIndex = this.attachmentIndex - 1;
    }
    if (this.returnExtension(this.documentReferenceURLs[this.attachmentIndex]?.url) == 'pdf') {
      this.getPdfAsBase64(this.documentReferenceURLs[this.attachmentIndex].url).then((base64) => {
        this.documentReferenceURLs[this.attachmentIndex].content = `data:application/pdf;base64,${base64}`;
      })
    }
  }

  nextDocAttach() {
    if (this.attachmentIndex + 1 == this.documentReferenceURLs.length) {
    } else {
      this.attachmentIndex = this.attachmentIndex + 1;
    }
    if (this.returnExtension(this.documentReferenceURLs[this.attachmentIndex]?.url) == 'pdf') {
      this.getPdfAsBase64(this.documentReferenceURLs[this.attachmentIndex].url).then((base64) => {
        this.documentReferenceURLs[this.attachmentIndex].content = `data:application/pdf;base64,${base64}`;
      })
    }
  }

  downloadAttachment(id) {
    this.downloadingAttachment = true;
    let attachObj = {
      attachmentIds: [id]
    }
    this.expenseService.downloadAttachment(attachObj).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        console.log(res);
        this.downloadedProofs = res.payload.downloadFileResponseDTO;
        this.downloadedProofs.forEach(element => {
          if (element) {
            window.location.href = element.url;
          }
        });
        this.downloadingAttachment = false;
        this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
      } else {
        this.downloadingAttachment = false;
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    }, err => {
      this.downloadingAttachment = false;
    });
  }

  getSubCategories(categories: any, from?) {
    console.log(from, categories);
    
    let subCategories = [];
    categories.forEach(c => {c.children = []});
    categories.forEach(c => {
      if(c.parentCategoryId) {
        const index = categories.findIndex(c_i => c_i.categoryId == c.parentCategoryId);
        if(index >= 0) {
          categories[index].children.push(c);
        }
      }
    })
    subCategories = categories.filter((c) => c.parentCategoryId == null);
    console.log('subCategories :::', subCategories);
    
    return subCategories;
  }

  searchedSubCategories(categories: any) {    
    categories.forEach(c => {
      c.children = [];
      c.isChild = false;
    });
    categories.forEach((c, i) => {
      if(c.parentCategoryId) {
        const index = categories.findIndex(c_i => c_i.categoryId == c.parentCategoryId);
        if(index >= 0) {
          categories[index].children.push(c);
          c.isChild = true;
        }
      }
    })    
    return categories.filter((c) => !c.isChild);
  }

  selectedClaimType(event, type) {
    this.totalBulkForm.controls.claimTypeId.patchValue(type.id);
    this.totalBulkForm.controls.claimTypeName.patchValue(type.claimType);
    this.currentField = '';

    // CLAIM_TYPE
    let categoriesList = [];
    console.log('sdsdd',this.allCategories1);
    this.allCategories1?.forEach((le) => {
      if(le.claimTypeId?.split(',')?.includes(type.id?.toString())) {
        categoriesList.push(le);
      }
    });
    this.filteredCategories = categoriesList;
    
    this.child_cats = this.getSubCategories(categoriesList, 2);
    this.subCategoriesList = [...this.child_cats];
    this.allSubCategoriesList = [...this.child_cats];
  }

  selectedSubCategory(event, category, form?: FormGroup, index?, expense?) {
    console.log('sdds',event);
    this.totalBulkForm.controls.claimCategory.patchValue(event?.categoryName);
    this.totalBulkForm.controls.claimCategoryId.patchValue(event?.categoryId);
    this.selectedClaimCatObj.categoryId = event?.categoryId;
    this.selectedClaimCatObj.categoryName = event?.categoryName;
    this.currentField = '';
    if(expense && form) {
      this.totalBulkModel.expenseRequestDTOList[index].parentCategoryId = event.categoryId;
      this.totalBulkModel.expenseRequestDTOList[index].parentCategoryName = event.categoryName;
      form.controls.parentCategoryId?.patchValue(event.categoryId);
      form.controls.parentCategoryName?.patchValue(event.categoryName);

      // to reset subcategory when select parent category
      if (this.userInfo?.isEnableCategorySelectionAtClaim) {
        this.totalBulkModel.expenseRequestDTOList[index].categoryName = null;
        this.totalBulkModel.expenseRequestDTOList[index].categoryId = null;
        form.controls.categoryName?.patchValue(null);
        form.controls.categoryId?.patchValue(null);
      }
    }
    console.log(this.totalBulkModel);
    console.log(form);
    
    this.getSelectedSubCats(event);
  }

  subCategoriesList = [];
  allSubCategoriesList = [];
  getSelectedSubCats(category) {
    let list = [];
    this.subCategoriesList = [];
    this.filteredCategories = [];
    this.child_cats.forEach(parent => {
      // parent
      if((parent.categoryId == category.categoryId)) {
        this.subCategoriesList = [parent];
        this.filteredCategories.push(parent);
        // child
      } else if (parent.children.length > 0) {
        parent.children.forEach(child => {
          if(child.categoryId == category.categoryId) {
            this.subCategoriesList = [child];
            this.filteredCategories.push(child);
            // subchild1
          } else if (child.children.length > 0) {
            child.children.forEach(subchild1 => {
              if(subchild1.categoryId == category.categoryId) {
                this.subCategoriesList = [subchild1];
                this.filteredCategories.push(subchild1);
                // subchild2
              } else if (subchild1.children.length > 0) {
                subchild1.children.forEach(subchild2 => {
                  if(subchild2.categoryId == category.categoryId) {
                    this.subCategoriesList = [subchild2];
                    this.filteredCategories.push(subchild2);
                    // subchild3
                  } else if (subchild2.children.length > 0) {
                    subchild2.children.forEach(subchild3 => {
                      if(subchild3.categoryId == category.categoryId) {
                        this.subCategoriesList = [subchild3];
                        this.filteredCategories.push(subchild3);
                        // subchild4
                      } else if (subchild3.children.length > 0) {
                        subchild3.children.forEach(subchild4 => {
                          if(subchild4.categoryId == category.categoryId) {
                            this.subCategoriesList = [subchild4];
                            this.filteredCategories.push(subchild4);
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }
    })
    this.allSubCategoriesList = [...this.subCategoriesList];
    console.log('subCategoriesList ____ ', this.subCategoriesList);
    // this.subCategoriesList = this.subCategoriesList[0]?.children || [];
  }

  onClaimCatSearch(e) {
    let categoriesList = this.allCategories?.filter((c) => c.categoryName?.toLowerCase().includes(e.target.value?.toLowerCase()))

    if(!e.target.value || e.target.value == '') {
      this.child_cats = this.getSubCategories(categoriesList,3);
    } else {
      this.child_cats = this.searchedSubCategories(categoriesList);
    }
  }

  onExpenseCatSearch(e) {
    let categoriesList = this.allSubCategoriesList?.filter((c) => c.categoryName?.toLowerCase().includes(e.target.value?.toLowerCase()))

    if(!e.target.value || e.target.value == '') {
      this.subCategoriesList = this.getSubCategories(categoriesList,4);
    } else {
      this.subCategoriesList = this.searchedSubCategories(categoriesList);
    }
  }

  companyPaid = 0;
  employeePaid = 0;
  getTotalExtension() {
    this.companyPaid = 0;
    this.employeePaid = 0;

    this.expenseTaggedTripList?.forEach(e => {
      if(e.paidBy == 1) {
        this.companyPaid += parseFloat(e.totalAmount?.toString().replace(/,/g, '') || 0);
      }
    });

    this.totalBulkModel?.expenseRequestDTOList?.forEach(e => {
      let exchangeRate;
      exchangeRate = e.editedExchangeRate ? e.editedExchangeRate?.toString() : e.expConvRate?.toString();
      if (this.isInt(e.originalAmount)) {
        if(e.cardTrasactionId || e.paidBy == 1) {
          this.companyPaid += parseInt(e.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(exchangeRate || 1);
        } else {
          this.employeePaid += parseInt(e.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(exchangeRate || 1);
        }
      } else {
        if(e.cardTrasactionId || e.paidBy == 1) {
          this.companyPaid += parseFloat(e.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(exchangeRate || 1);
        } else {
          this.employeePaid += parseFloat(e.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(exchangeRate || 1);
        }
      }
    })
    this.MileagebulkItemsForms?.value?.forEach(m => {
      if (this.isInt(m.distance) && this.isInt(m.mileageRate)) {
        this.employeePaid += parseInt(m.distance?.toString().replace(/,/g, '') || 0) * parseInt(m.mileageRate || 0) * parseInt(m.expConvRate || 1);
      } else {
        this.employeePaid += parseFloat(m.distance?.toString().replace(/,/g, '') || 0) * parseFloat(m.mileageRate || 0) * parseInt(m.expConvRate || 1);
      }
    })
    this.sampleDt?.forEach(s => {
      if (this.isInt(s.originalAmount)) {
        this.employeePaid += parseInt(s.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(s.expConvRate || 1);
      } else {
        this.employeePaid += parseFloat(s.originalAmount?.toString().replace(/,/g, '') || 0) * parseInt(s.expConvRate || 1);
      }
    })
  }

  claimTypes: any = [];
  claimTypesTemp: any = [];
  getClaimTypes() {
    const obj = {
      "action": "claim_types",
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      this.claimTypes = res.payload || [];
      this.claimTypesTemp = [...this.claimTypes];
      if(this.toPathInfoData?.report?.claimTypeId) {
        this.totalBulkForm.controls.claimTypeId.patchValue(this.toPathInfoData?.report?.claimTypeId);
        this.totalBulkForm.controls.claimTypeName.patchValue(this.toPathInfoData?.report?.claimTypeName);
      }
    })
  }

  openFutureDatePopup(temp) {
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(temp, config);
  }
  nextExpenseview() {
    if (this.tripdetailview) {
      this.tripdetailview.forEach(element => {
        if(element.isCompanyPaid) {
          element.CompanyPaid = true;
          element.EmployeePaid = false;
        } else {
          element.CompanyPaid = false;
          element.EmployeePaid = true;
        }
       this.enableproceededbtn = true;
      });

      this.tripdetailviewenable = true;
      // if((this.userInfo.isAssociatedExpenseEnabled && !this.userInfo.isServiceExpenseEnabled) || ( !this.userInfo.isAssociatedExpenseEnabled && this.userInfo.isServiceExpenseEnabled))
      // {
      //   this.enableproceededbtn = false;
      // }
      if((this.userInfo.isAssociatedExpenseEnabled && !this.userInfo.isServiceExpenseEnabled))
      {
        this.tripdetailview.forEach((element,i) => {
        
          element.CompanyPaid = true;
          element.EmployeePaid = false;
          this.enableproceededbtn = true;
          
        });
      }
      if((this.userInfo.isServiceExpenseEnabled && !this.userInfo.isAssociatedExpenseEnabled ))
      {
        this.tripdetailview.forEach((element,i) => {
          element.EmployeePaid = true;
          element.CompanyPaid = false;
          this.enableproceededbtn = true;
          
        });
      }
    }
  }
  backtoExpenseSelection() {
    this.tripdetailviewenable = false;
  }
  onchange(index, event) {
    let allSelected = true;
    this.tripdetailview.forEach((element,i) => {
      if (i == index) {
        if (event == 'CompanyPaid') {
          element.CompanyPaid = true;
           element.EmployeePaid = false;
        }
        if (event == 'EmployeePaid') {
          element.EmployeePaid = true;
           element.CompanyPaid = false;
        }
      }
      if(!element.CompanyPaid && !element.EmployeePaid) {
        allSelected = false;
      }
      
    });
    this.enableproceededbtn = allSelected;
  }

  strToNum(str) {
    return parseFloat(str?.toString().replace(/,/g, '') || 0);
  }

  violationsData: any;
  getViolations(update?) {
    let userId: any;
    let expenseList = [];
    let mileageList = [];
    let perDiemList = [];
    let tripList = [];

    this.totalBulkModel?.expenseRequestDTOList?.forEach(e => {
      if(e.categoryId) {
        expenseList.push({
          expenseId: e.expenseId || null,
          categoryId: e.categoryId || null,
          cityId: e.cityId || null,
          countryId: e.countryId || null,
          originalAmount: e.originalAmount ? this.strToNum(e.originalAmount) : null,
          expenseEndDate: e.expenseEndDate ? this.dateFormatAPI(e.expenseEndDate) : null,
          expenseStartDate: e.expensedDate ? this.dateFormatAPI(e.expensedDate) : null,
          originalCurrencyId: e.originalCurrencyId || null,
          exchangeRate: e.exchangeRate || 1,
          editedExchangeRate: e.editedExchangeRate || null
        });
      }
    });

    this.totalMileageBulkModel?.expenseRequestDTOList?.forEach(e => {
      if(e.categoryId) {
        mileageList.push({
          expenseId: e.expenseId || null,
          categoryId: e.categoryId || null,
          cityId: e.cityId || null,
          distance: e.distance ? this.strToNum(e.distance) : null,
          originalAmount: e.originalAmount ? this.strToNum(e.originalAmount) : null,
          expenseEndDate: e.expenseEndDate ? this.dateFormatAPI(e.expenseEndDate) : null,
          expenseStartDate: e.expensedDate ? this.dateFormatAPI(e.expensedDate) : null,
          originalCurrencyId: e.originalCurrencyId || null,
          exchangeRate: e.exchangeRate || 1,
          editedExchangeRate: e.editedExchangeRate || null,
          rate: e.rate || null
        });
      }
    });

    let claimStartDate = this.totalBulkForm?.controls?.claimStartDate?.value;
    let claimEndDate = this.totalBulkForm?.controls?.claimEndDate?.value;
    let claimId = null;
    if(this.totalBulkModel?.claimId) {
      claimId = this.totalBulkModel?.claimId
    } else if (this.toPathInfoData?.report?.claimId) {
      claimId = this.toPathInfoData?.report?.claimId
    } else if (this.toPathInfoData?.detailReport?.claimId) {
      claimId = this.toPathInfoData?.detailReport?.claimId
    }

    if(this.expenseTaggedTripList?.length > 0) {
      this.expenseTaggedTripList?.forEach(element => {
        let request = {
          'productId':element.productId,
          'productName':element?.productName,
          'startDate':element?.tripStartDate || element.startDate,
          'endDate':element?.tripEndDate || element.endDate,
          'amount':element?.tripCost || element.totalAmount,
          'tripTravelType':element?.tripTravelType,
        }
        tripList.push(request);
      });
    }
    let violationsModel = new claimViolationsModel();
    if(!this.totalBulkModel.claimTypeId && this.userInfo?.isEnableClaimTypes) {
      this.totalBulkModel.claimTypeId = this.toPathInfoData?.report?.claimTypeId
    }
    violationsModel.claimId = claimId;
    violationsModel.claimUserId = this.userInfo.userId;  // on update claimUserId ? claimUserId : userId
    violationsModel.legalEntityId = this.userInfo.legalEntityId;
    violationsModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    violationsModel.claimTypeId = this.totalBulkModel?.claimTypeId;
    violationsModel.claimEndDate = claimEndDate ? this.dateFormatAPI(claimEndDate) : null;
    violationsModel.claimStartDate = claimStartDate ? this.dateFormatAPI(claimStartDate) : null;
    violationsModel.parentCategoryId = this.selectedClaimCatObj?.categoryId || null;
    violationsModel.isPerDiem = (this.expenseTabIndex == 2 || this.matTabLabel == 'Perdiem') ? true : false;
    violationsModel.expenseList = expenseList;
    violationsModel.mileageList = mileageList;
    violationsModel.perDiemList = perDiemList;
    violationsModel.tripList = tripList;

    if (violationsModel.isPerDiem) {
      violationsModel.perDiem = {
        "perDiemId": null,
        "perDiemRate": null,
        "startDate": null,
        "endDate": null,
        "startTime": null,
        "endTime": null
      };
    } else {
      violationsModel.perDiem = null;
    }

    this.commonService.getViolations(violationsModel).subscribe((res: any) => {
      this.violationsData = res?.payload;
            
      this.addExpenseViolations(res?.payload?.expenseList || []);
      this.addMileageViolations(res?.payload?.mileageList || []);
      this.addPerdiemViolations(res.payload || [], update);
    });
  }

  addExpenseViolations(expenseList) {
    expenseList?.forEach((e, i) => {
      if(this.totalBulkModel.expenseRequestDTOList[i]) {
        const expense = this.totalBulkModel.expenseRequestDTOList[i];
        if(e.autoCalculatedAmount != null && e.autoCalculatedAmount >= 0) {
          if(!expense.amountAutoCalculated && !expense.isPerDayEnabled) {
            expense.originalAmount = this.userInfo?.roundOffClaimAmount ? Math.round(e.autoCalculatedAmount) : e.autoCalculatedAmount;
            expense.amountAutoCalculated = true;
          } else if (expense.isPerDayEnabled) {
            expense.originalAmount = this.userInfo?.roundOffClaimAmount ? Math.round(e.autoCalculatedAmount) : e.autoCalculatedAmount;
            expense.amountAutoCalculated = true;
          }
        } else {
          expense.isPerDayEnabled = false;
        }
        expense.amountVoilation = e.amountVoilation;
        expense.attachmentVoilation = e.attachmentVoilation;
        expense.expenseLevelVoilations = e.expenseLevelVoilations;
        expense.approversList = e.approversList;
        expense.deviationApproversList = e.deviationApproversList;
        expense.restrictMessage = e.restrictMessage;
        expense.isProofOfPaymentAttachmentRequired = e.proofOfPaymentLimitViolation ? true : false;
        this.totalBulkModel.showRestrictMessage = this.violationsData.showRestrictMessage;
        this.isProofOfPaymentAttachmentRequired = e.proofOfPaymentLimitViolation;
      }
    });
  }

  addMileageViolations(mileageList) {
    mileageList?.forEach((e, i) => {
      if(this.totalMileageBulkModel.expenseRequestDTOList[i]) {
        this.totalMileageBulkModel.expenseRequestDTOList[i].attachmentVoilation = e.attachmentVoilation;
        this.totalMileageBulkModel.expenseRequestDTOList[i].expenseLevelVoilations = e.expenseLevelVoilations;
      }
    });
  }
  
  pdCountries: any;
  pdCities: any;
  addPerdiemViolations(data, update) {
    // this.pdCountries = [];
    // perDiemList?.forEach((element) => {
    //   element.countryCity = element.countryName + (element.cityName != null ? ' - ' + element.cityName : '');
    //   console.log(element.countryCity);

    //   element.countryName?.forEach(ee => {
    //     this.pdCountries.push({countryName: ee, pId: element.perDiemId} || []);
    //   });
    //   console.log(this.pdCountries);
    //   element.pdCountries = this.pdCountries;
    // });
    // this.policyList = perDiemList;
    // this.policyPerDiemList = this.policyList;

    // this.perdiemRateList = ['Rate with Receipt', 'Rate without Receipt']
    // this.perdiemRateList = [];
    // if(data?.isAllowedWithReceipt) {
    //   this.perdiemRateList.push('Rate with Receipt');
    // }
    // if(data?.isAllowedWithoutReceipt) {
    //   this.perdiemRateList.push('Rate without Receipt');
    // }
    this.pdCountries = data?.perDiemCountryList || [];

    if (update) {
      this.policyPerDiemList?.forEach((element) => {
        if (element.cityId == this.perdiemModel?.PDlocationId) {
          this.selectedPolicyPerdiem = element;
          // element.isAllowedWithReceipt == true ? this.addPerdiemRateList("Rate with Receipt") : "";
          // element.isAllowedWithoutReceipt == true ? this.addPerdiemRateList("Rate without Receipt") : "";
        }
      });
    }
  }

  // getPDCities(country) {
  //   console.log(country);
  //   const index = this.policyPerDiemList?.findIndex(p => p.perDiemId == country.pId);
  //   console.log(this.policyPerDiemList[index]);
  //   this.pdCities = this.policyPerDiemList[index]?.cityNames || [];
  //   console.log(this.pdCities);
  // }

  employeesList: any = [];
  getUsers(searchText?) {
    let obj = {
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGuid": this.userInfo.companyGuId,
      "pageNumber": 1,
      "pageSize": 100,
      "searchText": searchText?.term || "",
    };
    this.commonService.getUsers(obj).subscribe((res) => {
      this.employeesList = res?.payload?.usersList || [];
      // console.log('employeesList _____ ', res?.payload?.usersList);
      // console.log('costCenters _____ ', this.costCenters);
      // console.log('designations _____ ', this.designations);
      // console.log('bands _____ ', this.bands);
      // console.log('departments _____ ', this.departments);
    })
  }

  splitExpenseIndex: any;
  openSplitExpense(splitExpense: TemplateRef<any>, index) {
    this.customPicker_i?.close();
    this.exppickerbulk_i?.close();
    this.exppickerEndbulk_i?.close();
    this.showTotalErrorMsg = false;
    this.splitExpenseIndex = index;
    let current_exp = this.totalBulkModel?.expenseRequestDTOList[index];
    if(!current_exp?.originalAmount || current_exp?.originalAmount == '') {
      return;
    }

    this.maxSplitAmt = parseFloat(current_exp?.originalAmount?.toString().replace(/,/g, '') || 0);
    this.splitExpensesList = [];
    this.addSplitEmployee(current_exp);
    if (splitExpense) {
      const config = {
        ignoreBackdropClick: false,
        class: "modal-xl modal-dialog-centered",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(splitExpense, config);
    }
  }

  splitExpensesList: any = []
  addSplitEmployee(expense?) {
    this.showDatePicker = false;
    this.exppickerbulk_i?.close();
    this.exppickerbulk_i = undefined;

    let eId: any = null;
    let eName: any = null;
    let coEmployeeId: any = null;
    if(expense && expense?.coUserId) {
      eId = expense?.coUserId;
      eName = expense?.coUserName;
    } else if (expense) {
      eId = this.userInfo?.userId;
      eName = this.userInfo?.firstName + ' ' + this.userInfo?.lastName;
      coEmployeeId = this.userInfo?.employeeId
    }

    this.splitExpensesList.push({
      employeeId: eId || null,
      emplopyeeName: eName || null,
      coEmployeeId: coEmployeeId || null,
      costCenterId: expense?.costCenterId || null,
      costCenterName: expense?.costCenterName || null,
      departmentId: expense?.departmentId || null,
      departmentName: expense?.departmentName || null,
      bandId: expense?.bandId || null,
      bandName: expense?.bandName || null,
      designationId: expense?.designationId || null,
      designationName: expense?.designationName || null,
      amount: ''
    })
  }

  removeSplitEmployee(index) {
    this.splitExpensesList.splice(index, 1);
  }

  saveSplitExpenses() {
    let existing_expense: any = {...this.totalBulkModel?.expenseRequestDTOList[this.splitExpenseIndex]};
    this.totalBulkModel.expenseRequestDTOList.splice(this.splitExpenseIndex, 1);
    this.bulkItemsForms.removeAt(this.splitExpenseIndex);
    this.splitExpensesList.forEach((s, i) => {
      let expense: any = {...existing_expense};
      expense.expenseId = i == 0 ? expense.expenseId : null;
      expense.bandId = s.bandId;
      expense.bandName = s.bandName;
      expense.costCenterId = s.costCenterId;
      expense.costCenterName = s.costCenterName;
      expense.departmentId = s.departmentId;
      expense.departmentName = s.departmentName;
      expense.designationId = s.designationId;
      expense.designationName = s.designationIdName;
      expense.originalAmount = s.amount;
      expense.coUserId = s.employeeId;
      expense.coUserName = s.emplopyeeName;
      expense.coEmployeeId = s.coEmployeeId;
      expense.expenseAttachments = expense.expenseAttachments ? [...expense.expenseAttachments] : [];
      this.totalBulkModel.expenseRequestDTOList.push({...expense});
      let expenseIndex = this.totalBulkModel?.expenseRequestDTOList?.length;
      this.bulkItemsForms.push(this.addBulkFormGroup({...expense}, true, expenseIndex));
    })
    this.modalRef?.hide();
  }

  setEmployeeData(employee, index) {
    this.splitExpensesList[index].employeeId = employee.userId;
    this.splitExpensesList[index].coEmployeeId = employee.employeeId;
    this.splitExpensesList[index].emplopyeeName = employee.firstName + ' ' + employee.lastName;
    this.splitExpensesList[index].bandId = employee.bandId;
    this.splitExpensesList[index].bandName = employee.bandName;
    this.splitExpensesList[index].departmentId = employee.departmentId;
    this.splitExpensesList[index].departmentName = employee.departmentName;
    this.splitExpensesList[index].costCenterId = employee.costCenterId;
    this.splitExpensesList[index].costCenterName = employee.costCenterName;
    this.splitExpensesList[index].designationId = employee.designationId;
    this.splitExpensesList[index].designationName = employee.designationName
  }

  setCostCenterData(data, index) {
    this.splitExpensesList[index].costCenterId = data.costCenterId;
    this.splitExpensesList[index].costCenterName = data.costCenterName;
  }

  setDepartmentData(data, index) {
    this.splitExpensesList[index].departmentId = data.departmentId;
    this.splitExpensesList[index].departmentName = data.departmentName;
  }

  setBandData(data, index) {
    this.splitExpensesList[index].bandId = data.bandId;
    this.splitExpensesList[index].bandName = data.bandName;
  }

  setDesignationData(data, index) {
    this.splitExpensesList[index].designationId = data.designationId;
    this.splitExpensesList[index].designationName = data.designationName
  }

  maxSplitAmt: number;
  totalSplitAmt: number;
  showTotalErrorMsg = false;
  getSplitTotalAmt() {
    this.showTotalErrorMsg = true;
    this.totalSplitAmt = 0;
    this.splitExpensesList.forEach(s => {
      this.totalSplitAmt += s.amount;
    });
  }

  ocrOverRide(){
    console.log('index :',this.extractDataIndex,this.extractData);
    if(this.extractData != null){

      let attchmntLength = this.totalBulkModel?.expenseRequestDTOList[this.extractDataIndex]?.expenseAttachments?.length || 0;
      console.log('atta',attchmntLength);

      if(attchmntLength <= 1) {     
        const ExpenseDateValid = moment.utc(this.extractData['ExpenseDate'], 'DD-MM-YYYY', true).isValid();
        const CheckInDateValid = moment.utc(this.extractData['CheckInDate'], 'DD-MM-YYYY', true).isValid();
        const CheckOutDateValid = moment.utc(this.extractData['CheckOutDate'], 'DD-MM-YYYY', true).isValid();
        if(CheckInDateValid && this.extractData['CheckInDate'] && this.extractData['CheckInDate'] != '' && this.extractData['CheckInDate'] != 'Unknown'
          && this.extractData['CheckInDate'] != 'Not available' && this.extractData['CheckInDate'] != 'N/A' && this.extractData['CheckInDate'] != 'NA') {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].expensedDate = this.givenOCRDate(this.extractData['CheckInDate']);
          this.updateBulkForm("",this.bulkItemsForms.controls[this.extractDataIndex] as FormGroup,"date",this.extractDataIndex);
        } else if(this.extractData['ExpenseDate'] && this.extractData['ExpenseDate'] != '' && ExpenseDateValid) {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].expensedDate = this.givenOCRDate(this.extractData['ExpenseDate']);
          this.updateBulkForm("",this.bulkItemsForms.controls[this.extractDataIndex] as FormGroup,"date",this.extractDataIndex);
        }

        if(this.extractData['CheckOutDate'] && this.extractData['CheckOutDate'] != '' && CheckOutDateValid) {
          this.dp_type = 'expenseEndDate';
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].expenseEndDate = this.givenOCRDate(this.extractData['CheckOutDate']);
          this.updateBulkForm("",this.bulkItemsForms.controls[this.extractDataIndex] as FormGroup,"date",this.extractDataIndex);
        }

        let cat_index: any;
        let category: any;
        if(this.extractData['Category']?.toLowerCase() == 'hotel') {
          cat_index = this.allCategories?.findIndex(a => a.productId == 2);
          category = this.allCategories[cat_index];
        } else {
          cat_index = this.filteredCategories?.findIndex(a => this.extractData['Category'] && a.categoryName?.toLowerCase().includes(this.extractData['Category']?.toLowerCase()));
          category = this.filteredCategories[cat_index];
        }
        if(cat_index >= 0 && category?.categoryId) {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].categoryName = category?.categoryName;
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].categoryId = category?.categoryId;
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].categoryCode = category?.categoryCode;
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].productCode = category?.productId;
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].isSpentLocationManditory = category?.isSpentLocationManditory;
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].isStartEndDateEnabled = category?.isStartEndDateEnabled;
        }

        if(this.extractData['Currency'] && this.extractData['Currency'] != '') {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].currencyCode = this.extractData['Currency'];
        }
        if(this.extractData['TotalAmount'] != '' && this.extractData['TotalAmount'] >= 0) {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].originalAmount = this.userInfo?.roundOffClaimAmount ? Math.round(this.extractData['TotalAmount']) : this.extractData['TotalAmount'];  
        }
        if(this.extractData['MerchantName'] && this.extractData['MerchantName'] != '') {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].merchantName = this.extractData['MerchantName'];
          if(this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex]?.customFieldList?.length > 0) {
            const mIndex = this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex]?.customFieldList?.findIndex(cf => cf.customFieldName?.toLowerCase() == 'merchant');
            if(mIndex >= 0) {
              this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].customFieldList[mIndex].customFieldValue = this.extractData['MerchantName'];
            }
          }
        }
        if(this.extractData['InvoiceNumber'] && this.extractData['InvoiceNumber'] != '') {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].invoiceNumber = this.extractData['InvoiceNumber'];
        }
        if(this.extractData['Alcohol'] != '') {
          // this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].isAlcohol = this.extractData['Alcohol'];
        }
        if(this.extractData['TaxAmount'] && this.extractData['TaxAmount'] != '') {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].taxAmount = this.extractData['TaxAmount'];
        }
        if(this.extractData['TaxPercentage'] && this.extractData['TaxPercentage'] != '') {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].taxPercentage = this.extractData['TaxPercentage'];
        }
        if(this.extractData['ExpenseDescription'] && this.extractData['ExpenseDescription'] != '') {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].description = this.extractData['ExpenseDescription'];
        }
        if(this.extractData['PhoneNumber'] && this.extractData['PhoneNumber'] != '' && this.userInfo?.restrictMobileBillMissMatch && this.userInfo?.mobile && this.userInfo?.mobile != '' && this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex]?.categoryId) {
          let cat_index = this.allCategories?.findIndex(a => a.categoryId == this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex]?.categoryId);
          if(cat_index >= 0 && this.allCategories[cat_index]?.isMobile) {
            let mobileMissMatch = this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].mobileMissMatch;
            // this.totalBulkModel.expenseRequestDTOList[index].ocrMobileNumbers.push(this.extractData?.PhoneNumber);
            // attachement.PhoneNumber = this.extractData?.PhoneNumber;
            if (!mobileMissMatch && (!this.userInfo?.mobile.includes(this.extractData?.PhoneNumber?.replace(/[\s-]/g, '')) && !this.extractData?.PhoneNumber?.replace(/[\s-]/g, '')?.includes(this.userInfo?.mobile))) {
              this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].mobileMissMatch = true;
              // attachement.mobileMissMatch = true;
            }
          }
        }
        
        if(this.extractData['Country']?.Id && this.extractData['Country']?.Name) {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].countryId = this.extractData['Country'].Id;
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].countryName = this.extractData['Country'].Name;
          if(this.extractData['City']?.Name && this.userInfo?.restrictGSTNumberMissMatch) {
            let requestObj = {
              countryIds: this.strToNum(this.extractData['Country'].Id),
              searchText: this.extractData['City'].Name,
              legalEntityId: this.userInfo.legalEntityId,
              legalEntityGUID: this.userInfo.legalEntityGuId
            };
            this.expenseService.getMileageCitiesList(requestObj).subscribe((data) => {
              if(data?.payload?.length > 0) {
                this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].cityId = data?.payload[0]?.cityId;
                this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].cityName = data?.payload[0]?.cityName;
                this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].companyGstNumber = data?.payload[0]?.gstNumber;



                // console.log(this.userInfo?.restrictGSTNumberMissMatch && this.totalBulkModel?.expenseRequestDTOList[this.extractDataIndex]?.expenseAttachments?.length > 0 && (this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex]?.productCode == 2 || this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex]?.productId == 2));
                if (this.userInfo?.restrictGSTNumberMissMatch && this.totalBulkModel?.expenseRequestDTOList[this.extractDataIndex]?.expenseAttachments?.length > 0 && (this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex]?.productCode == 2 || this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex]?.productId == 2)) {
                  let gstMissMatch = this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].gstMissMatch;
                  let companyGstNumber = this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].companyGstNumber;
                  this.totalBulkModel?.expenseRequestDTOList[this.extractDataIndex]?.expenseAttachments?.forEach(ea => {
                    // console.log(gstMissMatch, ea);
                    if (!gstMissMatch) {
                      console.log(ea?.gstNumber?.trim(), companyGstNumber?.trim());
                      console.log(ea?.gstNumber?.trim()?.includes(companyGstNumber?.trim()));
                      console.log(companyGstNumber?.trim()?.includes(ea?.gstNumber?.trim()));
                      console.log(!ea?.gstNumber?.trim()?.includes(companyGstNumber?.trim()), !companyGstNumber?.trim()?.includes(ea?.gstNumber?.trim()), !ea?.gstNumber?.trim()?.includes(companyGstNumber?.trim()) || !companyGstNumber?.trim()?.includes(ea?.gstNumber?.trim()));
                      if (!ea?.gstNumber?.trim()?.includes(companyGstNumber?.trim()) && !companyGstNumber?.trim()?.includes(ea?.gstNumber?.trim())) {
                        this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].gstMissMatch = true;
                        this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].taxAmount = 0;
                        this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].taxPercentage = 0;
                        let totalAmount = this.strToNum(this.extractData['TotalAmount']);
                        let taxAmount = this.strToNum(this.extractData['TaxAmount']);
                        this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].originalAmount = (totalAmount || 0) - (taxAmount || 0);
                      }
                    }
                  });
                }


              }
            });
          }
        }
        if(this.extractData['City']?.Id && this.extractData['City']?.Name && !this.userInfo?.restrictGSTNumberMissMatch) {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].cityId = this.extractData['City'].Id;
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].cityName = this.extractData['City'].Name;
        }
        if(this.extractData['State']?.Id && this.extractData['State']?.Name) {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].stateId = this.extractData['State'].Id;
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].stateName = this.extractData['State'].Name;
          // this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].stateTin = "36";
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].stateTin = this.extractData['State'].StateTin;
        }
        if(this.extractData['companyGstNumber'] && this.extractData['companyGstNumber'] != '') {
          this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].companyGstNumber = this.extractData['companyGstNumber'];
        }

        // let cat_index = this.allCategories?.findIndex(a => a.categoryName?.toLowerCase().includes(this.extractData['Category']?.toLowerCase()));
        // if(cat_index >= 0 && this.allCategories[cat_index]?.categoryId) {
        //   console.log(15519, this.allCategories[cat_index]);
        //   this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].categoryName = this.extractData['Category'];
        //   this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].categoryId = this.allCategories[cat_index]?.categoryId;
        //   this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].categoryCode = this.allCategories[cat_index]?.categoryCode;
        //   this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].productCode = this.allCategories[cat_index]?.productId;
        //   this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].isSpentLocationManditory = this.allCategories[cat_index]?.isSpentLocationManditory;
        // }
      } else if(!this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].isAlcohol) {
        // this.totalBulkModel.expenseRequestDTOList[this.extractDataIndex].isAlcohol = this.extractData['Alcohol'];
      }

    }
    this.modalRef.hide();
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  mobileBillMissMatch: any = false;
  extractImageOCR(filename, s3AttachmentUrl, index) {
    console.clear();
    let attachement = {
      fileName: filename,
      url: s3AttachmentUrl,
      content: null,
      attachmentType: "1",
      isAlcoholAttach: false,
      mobileMissMatch: false,
      gstMissMatch: false,
      gstNumber: null,
      PhoneNumber: null,
    };

    let obj = {
      imagePathUrl: s3AttachmentUrl
    }
    let attchmntLength = this.totalBulkModel?.expenseRequestDTOList[index]?.expenseAttachments?.length || 0;

    this.expenseService.extractImageOCR(obj).subscribe((res) => {
      if(res?.payload && Object.keys(res?.payload)?.length != 0) {
        this.extractData = res.payload;
        this.extractDataIndex = index;
        // blockMobileBillMissMatch
        if(this.extractData?.PhoneNumber && this.extractData?.PhoneNumber != '' && this.userInfo?.restrictMobileBillMissMatch && this.userInfo?.mobile && this.userInfo?.mobile != '' && this.totalBulkModel.expenseRequestDTOList[index]?.categoryId) {
          let cat_index = this.allCategories?.findIndex(a => a.categoryId == this.totalBulkModel.expenseRequestDTOList[index]?.categoryId);
          if(cat_index >= 0 && this.allCategories[cat_index]?.isMobile) {
            let mobileMissMatch = this.totalBulkModel.expenseRequestDTOList[index].mobileMissMatch;
            // this.totalBulkModel.expenseRequestDTOList[index].ocrMobileNumbers.push(this.extractData?.PhoneNumber);
            attachement.PhoneNumber = this.extractData?.PhoneNumber;
            if (!mobileMissMatch && (!this.userInfo?.mobile.includes(this.extractData?.PhoneNumber?.replace(/[\s-]/g, '')) && !this.extractData?.PhoneNumber?.replace(/[\s-]/g, '')?.includes(this.userInfo?.mobile))) {
              this.totalBulkModel.expenseRequestDTOList[index].mobileMissMatch = true;
              attachement.mobileMissMatch = true;
            }
          }
        }
        // get productID
        let cat_index = this.allCategories?.findIndex(a => a.categoryName?.toLowerCase().includes(this.extractData['Category']?.toLowerCase()));
        if (cat_index >= 0 && this.allCategories[cat_index]?.productId) {
          this.totalBulkModel.expenseRequestDTOList[index].productCode = this.allCategories[cat_index]?.productId;
        }
        // this.extractData.GSTNumber = "37MNBCXZIUYTRE"; // Bza
        // this.extractData.GSTNumber = "36AAEFH181QGAIZX"; //Hyd
        // blockGSTNumberMissMatch
        // console.log(this.extractData?.CompanyGSTNumber);
        // console.log(this.totalBulkModel.expenseRequestDTOList);
        // console.log(this.totalBulkModel.expenseRequestDTOList[index].companyGstNumber);
        // console.log(this.totalBulkModel.expenseRequestDTOList[index]);
        // console.log(this.extractData?.CompanyGSTNumber && this.extractData?.CompanyGSTNumber != '' && this.userInfo?.restrictGSTNumberMissMatch && (this.totalBulkModel.expenseRequestDTOList[index]?.productCode == 2  || this.totalBulkModel.expenseRequestDTOList[index]?.productId == 2));
        attachement.gstNumber = this.extractData?.CompanyGSTNumber;
        attachement.PhoneNumber = this.extractData?.PhoneNumber;
        
        if(this.extractData?.CompanyGSTNumber && this.extractData?.CompanyGSTNumber != '' && this.userInfo?.restrictGSTNumberMissMatch && (this.totalBulkModel.expenseRequestDTOList[index]?.productCode == 2  || this.totalBulkModel.expenseRequestDTOList[index]?.productId == 2)) {
          let gstMissMatch = this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch;
          // let stateTin = this.totalBulkModel.expenseRequestDTOList[index].stateTin;
          let companyGstNumber = this.totalBulkModel.expenseRequestDTOList[index].companyGstNumber;
          attachement.gstNumber = this.extractData?.CompanyGSTNumber;
          // console.log(gstMissMatch, companyGstNumber);
          


          if (!gstMissMatch && companyGstNumber) {
            if (!this.extractData?.CompanyGSTNumber?.trim()?.includes(companyGstNumber?.trim()) && !companyGstNumber?.trim()?.includes(this.extractData?.CompanyGSTNumber?.trim())) {
              this.totalBulkModel.expenseRequestDTOList[index].gstMissMatch = true;
              attachement.gstMissMatch = true;
              this.extractData['TaxAmount'] = 0;
              this.extractData['TaxPercentage'] = 0;
              this.totalBulkModel.expenseRequestDTOList[index].taxAmount = 0;
              this.totalBulkModel.expenseRequestDTOList[index].taxPercentage = 0;
            }
          }
        }


        attachement.isAlcoholAttach = this.extractData['Alcohol'] ? true : false;
        if(attchmntLength <= 0) {
          const config = {
            ignoreBackdropClick: false,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
          };
          this.modalRef = this.modalService.show(this.ocrOverrideTemp, config);
          
          // attachement.isAlcoholAttach = this.extractData['Alcohol'] ? true : false;
        } else if(!this.totalBulkModel.expenseRequestDTOList[index].isAlcohol) {
          // attachement.isAlcoholAttach = this.extractData['Alcohol'] ? true : false;
        }
        this.totalBulkModel.expenseRequestDTOList[index].expenseAttachments.push(attachement);
        this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false;
      } else {
        this.totalBulkModel.expenseRequestDTOList[index].expenseAttachments.push(attachement);
        this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false;
      }
    }, err => {
      this.totalBulkModel.expenseRequestDTOList[index].expenseAttachments.push(attachement);
      this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false;
    });
  }

  getPDCities(ev) {
    this.perdiemModel.PDlocation = ev.countryName;
    this.perdiemModel.PDlocationId = ev.countryId;
    this.perdiemModel.policyId = ev.policyId;
    this.pdCities = ev?.perDiemCities || [];
    this.perdiemRateList = [];
    if(ev?.isAllowedWithReceipt) {
      this.perdiemRateList.push('Rate with Receipt');
    }
    if(ev?.isAllowedWithoutReceipt) {
      this.perdiemRateList.push('Rate without Receipt');
    }
    this.getPDList();
  }

  setPDCities(ev) {
    this.perdiemModel.PDCity = ev.cityName;
    this.perdiemModel.PDCityId = ev.cityId;
    this.perdiemModel.perDiemId = ev.perDiemId;
    this.perdiemRateList = [];
    if(ev?.isAllowedWithReceipt) {
      this.perdiemRateList.push('Rate with Receipt');
    }
    if(ev?.isAllowedWithoutReceipt) {
      this.perdiemRateList.push('Rate without Receipt');
    }
    this.getPDList();
  }

  getPDList() {
    const country = this.perdiemModel.PDlocation && this.perdiemModel.PDlocation != '';
    // const city = this.perdiemModel.PDCity && this.perdiemModel.PDCity != '';
    const rate = this.perdiemModel.PDrate && this.perdiemModel.PDrate != '';
    const sD = this.perdiemModel.PDstartDate && this.perdiemModel.PDstartDate != '';
    const eD = this.perdiemModel.PDendDate && this.perdiemModel.PDendDate != '';
    // const sT = this.perdiemModel.PDstartTime >= 0 && this.perdiemModel.PDstartTime != '';
    // const eT = this.perdiemModel.PDendTime >= 0 && this.perdiemModel.PDendTime != '';
    
    if (country && rate && sD && eD) {
      let claimStartDate = this.totalBulkForm?.controls?.claimStartDate?.value;
      let claimEndDate = this.totalBulkForm?.controls?.claimEndDate?.value;
      let claimId = null;
      if (this.totalBulkModel?.claimId) {
        claimId = this.totalBulkModel?.claimId
      } else if (this.toPathInfoData?.report?.claimId) {
        claimId = this.toPathInfoData?.report?.claimId
      } else if (this.toPathInfoData?.detailReport?.claimId) {
        claimId = this.toPathInfoData?.detailReport?.claimId
      }

      let obj = {
        "claimId": claimId,
        "claimUserId": this.userInfo.userId,
        "legalEntityId": this.userInfo.legalEntityId,
        "legalEntityGUID": this.userInfo.legalEntityGuId,
        "claimTypeId": this.totalBulkForm?.controls?.claimTypeId?.value,
        "claimEndDate": claimEndDate ? this.dateFormatAPI(claimEndDate) : null,
        "claimStartDate": claimStartDate ? this.dateFormatAPI(claimStartDate) : null,
        "parentCategoryId": this.selectedClaimCatObj?.categoryId || null,
        "isPerDiem": true,
        "expenseList": [],
        "mileageList": [],
        "perDiemList": [],
        "perDiem": {
          "perDiemId": this.perdiemModel.perDiemId,
          "perDiemRate": this.perdiemModel.PDrate == "Rate with Receipt" ? 1 : 2,
          "startDate": this.perdiemModel.PDstartDate,
          "endDate": this.perdiemModel.PDendDate,
          "startTime": this.perdiemModel.PDstartTime,
          "endTime": this.perdiemModel.PDendTime,
          "countryId": this.perdiemModel.PDlocationId,
          "cityId": this.perdiemModel.PDCityId || null,
          "policyId": this.perdiemModel.policyId
        }
      }

      this.sampleDt = [];
      this.commonService.getViolations(obj).subscribe((res: any) => {
        res.payload?.perDiemList?.forEach(pr => {
          let _request = {
            expenseDate: pr.perDiemDate,
            expensedDate: pr.perDiemDate,
            perDiemRate: this.perdiemRateSelected == "Rate with Receipt" ? 1 : 2,
            baseCurrencyId: this.userInfo.baseCurrency.currencyId,
            originalCurrencyName: pr.currencyCode || null,
            originalCurrencyCode: pr.currencyCode || null,
            originalCurrencyIcon: pr.currencyIcon || null,
            expConvRate: pr.cxchangeRate || null,
            merchantName: null,
            startDate: this.perdiemModel.PDstartDate,
            startTime: this.perdiemModel.PDstartTime.toString(),
            endDate: this.perdiemModel.PDendDate,
            endTime: this.perdiemModel.PDendTime.toString(),
            originalCurrencyId: pr.currencyId || null,
            duration: pr.duration,
            hours: null,
            originalAmount: pr.originalAmount,
            baseAmount: pr.baseAmount,
            showLoader: false,
            expenseAttachments: [],
            description: '',
            expenseType: 5,
            exchangeRate: pr.exchangeRate || 1
          };
          this.sampleDt.push(_request);
        })
        this.getTotalAmount();
      });
    }
  }

  searchCities(ev, i) {
    this.getMilageStates(this.totalBulkModel.expenseRequestDTOList[i]?.countryId, ev.term);
  }


  searchMerchants(ev, i) {
    this.getMerchantlist(i, ev.term);
  }

  getMerchantlist(i, searchText?) {
    console.log(this.totalBulkModel.expenseRequestDTOList[i]);
    
    if(this.totalBulkModel?.expenseRequestDTOList[i]?.categoryId) {
      let obj = {
        searchText: searchText || '',
        categoryId: this.totalBulkModel?.expenseRequestDTOList[i]?.categoryId,
        legalEntityId: this.userInfo?.legalEntityId,
        legalEntityGUID: this.userInfo?.legalEntityGuId
      }
      this.commonService.getMerchantlistByCategory(obj).subscribe((res) => {
        this.totalBulkModel.expenseRequestDTOList[i].merchantList = res?.payload?.merchantList || [];
        console.log(this.totalBulkModel.expenseRequestDTOList[i]);
      })
    }
  }

  searchCostCenters(ev?) {
    const requestModel = {
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "searchText": ev?.term || ''
    }
    this.settingService.getCostCenterList(requestModel).subscribe((res) => {
      this.costCenters = res.payload.costCenterList || [];
    })
  }

  searchBands(ev?) {
    const requestModel = {
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "searchText": ev?.term || ''
    }
    this.settingService.getBrandsList(requestModel).subscribe((res) => {
      this.bands = res?.payload?.bandList || [];
    })
  }

  searchDepartments(ev?) {
    const requestModel = {
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "searchText": ev?.term || ''
    }
    this.settingService.getDepartmentsist(requestModel).subscribe((res) => {
      this.departments = res?.payload?.departmentList || [];
    })
  }

  searchDesignations(ev?) {
    const requestModel = {
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "searchText": ev?.term || ''
    }
    this.settingService.getDesignationsList(requestModel).subscribe((res) => {
      this.designations = res?.payload?.designationList || [];
    })
  }

  searchProjectCodes(ev?) {
    const requestModel = {
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "searchText": ev?.term || ''
    }
    this.settingService.getProjectCodesList(requestModel).subscribe((res) => {
      this.projectCodes = res?.payload?.projectList || [];
    })
  }

  convertToObj(str) {
    if (typeof str === 'string') {
      return JSON.parse(str);
    } else {
      return str;
    }
  }

  isValidJSON(jsonString: string): boolean {
    if (typeof jsonString !== 'string') {
      return false;
    }
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  }

  editNextExpense() {
    if(this.currentEditIndex < this.allEditExpenses.length-1) {
      this.currentEditIndex++;
      this.allEditExpenses[this.currentEditIndex].isSelected = true;
      this.setBulkEditData(this.allEditExpenses[this.currentEditIndex]?.expenseId, [this.allEditExpenses[this.currentEditIndex]]);
    }
  }

  editPrevExpense() {
    if(this.currentEditIndex > 0) {
      this.currentEditIndex--;
      this.allEditExpenses[this.currentEditIndex].isSelected = true;
      this.setBulkEditData(this.allEditExpenses[this.currentEditIndex]?.expenseId, [this.allEditExpenses[this.currentEditIndex]]);
    }
  }
  handleProofInputChange(event, expenseType?, index?, readOCR?) {
    if (!event.target) {
      return;
    }
    if (!event.target.files) {
      return;
    }
    var file = event.target.files;
    this.imagesCount = event.target.files.length;
      this.totalBulkModel.expenseRequestDTOList[index].proofOfPaymentLoader = true;
      const obj = {
        "legalEntityGUID": this.userInfo.legalEntityGuId,
        "legalEntityId": this.userInfo.legalEntityId,
        "fileName": file[0].name,
        "uploadFor": "EXPENSE-ATTACHMMENTS"
      }
      this.expenseService.getUploadUrl(obj).subscribe((res: any) => {
        sessionStorage.setItem('s3uploadUrl', res.payload?.s3Url)

        this.expenseService.uploadFileInS3(file[0], res.payload?.preSignedS3URL).subscribe(response => {
            this.toastr.success("Attachments added successfully", "Success", index);
            let request = {
              fileName: file[0].name,
              url: res.payload.s3Url,
              content: null,
              attachmentType: "1",
            };        
              this.totalBulkModel.expenseRequestDTOList[index].proofOfPaymentFileUrl = request;
              this.totalBulkModel.expenseRequestDTOList[index].proofOfPaymentFileName = file[0]?.name;
              this.totalBulkModel.expenseRequestDTOList[index].proofOfPaymentLoader = false;
          if (response['status'] == 200) {
            sessionStorage.removeItem('s3uploadUrl');
          } else {
            sessionStorage.removeItem('s3uploadUrl');
            this.toastr.error('Unable to submit file');
          }
        }, err => {
          sessionStorage.removeItem('s3uploadUrl');
          this.toastr.error('Unable to submit file');
          this.totalBulkModel.expenseRequestDTOList[index].proofOfPaymentLoader = false;
        })
      }, err => {
        sessionStorage.removeItem('s3uploadUrl');
        this.toastr.error('Unable to submit file');
        this.totalBulkModel.expenseRequestDTOList[index].proofOfPaymentLoader = false;
      })
  }
  imagePreviousCall(imagePreview: TemplateRef<any>, proofOfPaymentFileUrl, index?) {
    this.proofofPaymentPreview = true
    this.attachmentExpenseIndex = index;
    let isPdf;
    if (this.returnExtension(proofOfPaymentFileUrl?.fileName) == 'pdf') {
      isPdf = true;
    }
    this.attachmentIndex = 0;
    this.previewAttachmentsArray = [];
    this.perdiemAttachmentIndex = '';
    if (isPdf) {
      this.getPdfAsBase64(proofOfPaymentFileUrl?.url).then((baseRes) => {
        let requestM = new GetExpenseModels();
        // requestM.expenseIds = [expense?.expenseId];
        // requestM.legalEntityId = this.userInfo.legalEntityId;
        // requestM.legalEntityGUID = this.userInfo.legalEntityGuId;

        proofOfPaymentFileUrl.content = baseRes;
        this.previewAttachmentsArray = [this.totalBulkModel.expenseRequestDTOList[index].proofOfPaymentFileUrl];
      })
    } else {
      this.previewAttachmentsArray = [this.totalBulkModel.expenseRequestDTOList[index].proofOfPaymentFileUrl];
    }
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(imagePreview, config);
  }

  getMissingRequiredFields(formGroup: FormGroup | FormArray): string[] {
    const missingFields: string[] = [];
  
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
  
      if (control instanceof FormGroup || control instanceof FormArray) {
        // Recursively check nested FormGroups or FormArrays
        missingFields.push(...this.getMissingRequiredFields(control));
      } else if (control && control.invalid && control.errors?.['required']) {
        missingFields.push(key);
      }
    });
  
    return missingFields;
  }

  handleCancelClick(gobackTemplate: TemplateRef<any>) {
    if (this.isReadOnly || !this.totalBulkForm?.dirty) {
      this.oncancelClick();
    } else {
      this.cancelCreateConfirmationAlert(gobackTemplate);
    }
  }

  closeDynamicModal() {
    this.modalRefDynamic?.hide();
  }
  
}
