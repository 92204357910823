import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportsService } from 'src/app/services/reports.service';
import {
    CategoryAndMerchantModel,
    CategoryList,
    DownloadModel,
    EmailApprovalM,
    ExpenseAndReportModel,
    FilterModal,
    StatusListModel,
    filterM
} from 'src/app/models/Common/common.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApprovialstatRes, ApprstatRequest, ReportListResModel, ReportSummaryResponseModal } from 'src/app/models/Reports/reports.model';
import { IonInput, MenuController, ModalController, NavController } from '@ionic/angular';
import { DetailreportComponent } from '../reports/detailreport/detailreport.component';
import { Subject } from 'rxjs';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import * as moment from 'moment';
// import { IonicSelectableComponent } from 'ionic-selectable';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CommonService } from 'src/app/services/common-services/common.service';
import * as _ from "lodash";
import { ChangeContext, LabelType, Options } from 'ng5-slider';
import { ReportDetailviewComponent } from '../reports/report-detailview/report-detailview.component';
import { Constants } from 'src/app/Constants';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { DetailapprovalComponent } from './detailapproval/detailapproval.component';
import { Title } from "@angular/platform-browser";
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';
import { CustomSelectDropdownComponent } from '../custom-select-dropdown/custom-select-dropdown.component';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import { MatMenuTrigger } from '@angular/material/menu';
import { element } from 'protractor';
import { log } from 'console';
import { LoginModel } from 'src/app/models/Login/login.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
import { ExpensesService } from 'src/app/services/expenses.service';


@Component({
    selector: "app-approvals",
    templateUrl: "./approvals.component.html",
    styleUrls: ["./approvals.component.scss"],
})
export class ApprovalsComponent implements OnInit {
    @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
    @ViewChild("datefocusOff", { static: false }) datefocusOff: ElementRef;
    // @ViewChild("selectCatComponent") selectCatComponent: IonicSelectableComponent;
    @ViewChild("downloadLoader", { static: false }) private downloadLoader;
    @ViewChild("downloaddataLoader", { static: false }) private downloaddataLoader;
    @ViewChild('fromdate') fromdate: MatDatepicker<Date>;
    @ViewChild('todate') todate: MatDatepicker<Date>;
    @ViewChild("selectstatusComponent")
    selectstatusComponent: any;
    platformType: number;
    isMobileDevice: boolean;
    myControl = new FormControl();
    options: any;
    userInfo: any;
    hideColumns = [];
    expenseAndReportModel = new ExpenseAndReportModel();
    totalRecords = 0;
    minAndMax: any;
    approveList: any;
    filterModal = new FilterModal();
    showApprovalFilter = false;
    aprovList = true;
    approvalHide = true;
    segmentModel = "Awiating Approval";
    draft: any;
    maxDate: any;
    fromDate: any;
    toDate: any;
    amount: any;
    mobMaxDate: string;
    selectedstatusmob = [];
    selectcatarraymob = [];
    showDetail = false;
    ExpenseorReport: string;
    status: string;
    categoryName: any;
    categoryList: any;
    showFilter: boolean;
    minPrice: any;
    maxPrice: any;
    amountoptions: any;
    statusList = [];
    public manualRefresh = new Subject<void>();
    dummyList = [1, 2, 3];
    searchBar = false;
    dropdownFlag: any;
    showLoading = true;
    sortBtn = true;
    approvestat: any;
    isPageLoad: boolean;
    pageNumber = 1;
    pageSize = 50;
    approvetempstat: any;
    showDetailreport = false;
    hideReporttable = true;
    detailReport: any;
    detailsReport: any;
    fromPage = "approvePage";
    isApprovalExist: boolean;
    labelName = "Pending";
    showMobileDetails = true;
    isApicallinProgress: any = false;
    approvesearch: string;
    frmDate: any;
    minDate: string;
    tDate: any;
    currentday: any;
    CategoryMerchantModel = new CategoryAndMerchantModel();
    downloadActionPoolCount = 0;
    downloadTime: any;
    selectedReport = [];
    checkAll: boolean;
    indeterminateState: boolean;
    downloadBtn = false;
    statsLoading: boolean = true;
    private SearchListText: Subject<string> = new Subject();
    showTypes: any;
    totalAdvanceReportCount: any;
    totalExpenseReportCount: any;
    isUserToggle: any;
    modalRef: BsModalRef;
    // tabList = [
    // { name: "All", id: [] },
    // { name: "Claim", id: [1] },
    // { name: "Local Conveyence", id: [3] },
    // { name: "Advance", id: [2] }, 
    // { name: "Petty Cash", id: [4, 5] }, 
    // { name: "Travel", id: [6] }];
    maxDateStr: any = moment(new Date()).format('YYYY-MM-DD');
    minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');

    isLocalConveyanceEnabled: boolean = false;
    isPettyCashEnabled: boolean = false;
    isTravelEnabled: boolean = false;
    recordTypeStr: any;
    showDownloadOptions: boolean = false;
    downloadOptionSelection = 0;
    segmentData: any;
    expcurrencyIcon: any;
    fileName: any;
    imgContent: string = "";
    showBulkUpload_loader: boolean = false;
    bulkForm: FormGroup;
    downloadForm: FormGroup;
    uploadFileStutus: boolean = false;
    submitted: boolean;
    email: any;
    minimumDate: Date;
    maximumDate: Date;
    _fromdate: any;
    _todate: any;
    maxExpire: Date;
    allowMax: any;
    tabList: any;
    statusdata = [];
    label: string = 'All';
    approvalstatus: any[];
    recordStatus: any[];
    selectType: string = "Claim";
    statusName: any;
    selectedvalue = [];
    openfromdate: boolean = false;
    opentodate: boolean = false;
    currentClaimIndex: number;
    approveListdata = [];
    filterdata = [];
    filterdataM = new filterM();
    selectedOption: string = null;
    mobile_approval_dropdown = [];
    routerState: any;
    loginModel = new LoginModel();
    email_model = new EmailApprovalM();
    sortColumn: any;
    sortType: any;
    sortdate: any;
    ascending: boolean =false;
    selectedSort: string = 'newest';
    selectedBulkIndex: number = -1;
    isMenuOpen: boolean = false;

    selectedItems = [];
    selectedClaimId:any;
    selectedClaimGuid:any;
    showApprovalLoder:boolean = false
    repushloading:boolean = true;    
    integrationHistory:Array<any> = [];
    filterClaimTypeId: any;
    filterClaimTypeName: any;
    matChildTabIndex = 0;
    matChildTabLabel = 'All';
    approvalTabs: any = [];
  
    // Reference to the down-arrow span in the template
    @ViewChild('menuTrigger') menuTrigger: ElementRef;
    index:number;
    constructor(
        private platformService: PlatformService,
        private reportsService: ReportsService,
        public toastr: ToastrService,
        private modalService: BsModalService,
        public router: NavController,
        private active_router: Router,
        public formBuilder: FormBuilder,
        public modalController: ModalController,
        private commonService: CommonService,
        private datechangeService: DatechangeService,
        public sharedService: SharedService,
        public navCtrl: NavController,
        private title: Title,
        public menuCtrl: MenuController,
        private translate: TranslateService,
        private expenseService: ExpensesService,
        private toaster: ToastrService, private authenticationService: AuthenticationService,private activeRouter: ActivatedRoute,
    ) {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

        this.getClaimTypes();

        this.sharedService.getUserToggle().subscribe((val) => {
            this.isUserToggle = val;
        });
        
        if (this.active_router.url.includes('/emailApproval/')) {
            this.loginModel.ssoToken = this.activeRouter.snapshot.params['ssoToken'];
            this.email_model.claimId = this.activeRouter.snapshot.params['claimId'];
            this.email_model.claimGUID = this.activeRouter.snapshot.params['claimGUID'];
            this.email_model.roleId = this.activeRouter.snapshot.params['roleId'];
            this.email_model.userId = this.activeRouter.snapshot.params['userId'];
            this.email_model.userGuid = this.activeRouter.snapshot.params['userGuid'];
            this.detailReport = {
                'claimId': this.activeRouter.snapshot.params['claimId'],
                'claimGUID': this.activeRouter.snapshot.params['claimGUID'],
                'userId': this.activeRouter.snapshot.params['userId'],
                'userGuid': this.activeRouter.snapshot.params['userGuid'],
                'recordType': 1
            }
            this.hideReporttable = false;
            setTimeout((data) => {
                this.showDetailreport = true;
                this.hideReporttable = false;
            }, 2000);
            this.emailApprovalformSubmit();
            }else if(JSON.parse(sessionStorage.getItem("isEmailApproval"))){
                this.detailReport = JSON.parse(sessionStorage.getItem('detailReport'));
                this.hideReporttable = false;
                this.showDetailreport = true;
            } 

        // FILTER LOGIC WHEN WE COME FROM DASHVBOARD
        this.routerState = this.active_router.getCurrentNavigation().extras.state;
        if (this.routerState && this.routerState.data) {
            if (this.routerState.data.name == 'pending') {
                this.labelName = 'Pending'
                this.filterApprovalStatus();
            } else {
                this.matTabIndex = 1;
                this.filterModal.recordTypes = [1];
                this.filterModal.statusIds = [];
                this.labelName = 'All Claims';
            }
        } else {
            this.labelName = 'Pending'
            this.filterApprovalStatus();
        }

        this.expcurrencyIcon = this.userInfo?.baseCurrency.currencyIcon;
        this.authenticationService.setisLocalConveyanceEnabled(this.userInfo?.isLocalConveyanceEnabled);
        this.authenticationService.setisPettyCashEnabled(this.userInfo?.isPettyCashEnabled);
        this.authenticationService.setisTravelEnabled(this.userInfo?.isTravelEnabled);
        this.sharedService.getRefreshData().subscribe((value) => {
            if (value) {
                this.draft = null;
                if (this.platformType == 2) {
                    if (this.userInfo?.roleId) {
                        if (this.segmentModel === 'Awaiting Approval') {
                            this.filterModal.statusIds = [2]
                            this.segmentModel = 'Awaiting Approval';
                        } else if (this.segmentModel === 'Approved') {
                            this.segmentModel = 'Approved';
                        }
                    }
                    if (((this.userInfo?.roleId == 3) && (this.isUserToggle == false))) {
                        // this.filterModal.statusIds = [3]
                        // this.filterModal.statusAction = 'Awaiting Finance';
                        // this.segmentModel = 'Awaiting Finance';
                        if (this.segmentModel === 'Awaiting Approval') {
                            this.filterModal.statusIds = [3]
                            this.segmentModel = 'Awaiting Finance';
                        } else if (this.segmentModel === 'Approved') {
                            this.segmentModel = 'Approved';
                        }
                    }
                }
                this.filterApprovalStatus();
                this.getApproveList(true);

            }
        });


        this.authenticationService.getisLocalConveyanceEnabled().subscribe(status => {
            if (status === true) {
                this.isLocalConveyanceEnabled = true;
            } else if (status === false) {
                this.isLocalConveyanceEnabled = false
            }
        });

        this.authenticationService.getisPettyCashEnabled().subscribe(status => {
            if (status === true) {
                this.isPettyCashEnabled = true;
            } else if (status === false) {
                this.isPettyCashEnabled = false
            }
        });

        this.authenticationService.getisTravelEnabled().subscribe(status => {
            if (status === true) {
                this.isTravelEnabled = true;
            } else if (status === false) {
                this.isTravelEnabled = false
            }
        });
        this.tabList = [
            { name: "All", id: [], show: true, dropdown: true },
            { name: "Claim", id: [1], show: true, dropdown: true },
            { name: "Claim + Expenses", id: [1], show: true, dropdown: true },
            { name: "Local Conveyence", id: [3], show: this.isLocalConveyanceEnabled ? this.isLocalConveyanceEnabled : false, dropdown: true },
            { name: "Advance", id: [2], show: true, dropdown: true },
            { name: "Petty Cash", id: [4, 5], show: this.userInfo?.isPettyCashEnabled ? this.userInfo?.isPettyCashEnabled : false, dropdown: true },
            { name: "Travel", id: [6], show: this.userInfo?.isTravelEnabled ? this.userInfo?.isTravelEnabled : false, dropdown: true }];

        this.mobile_approval_dropdown = ['Pending', 'Claims', 'Advance', 'All']
    }
    ngOnInit() {
        this.approvalTabs = JSON.parse(sessionStorage.getItem('approval-tabs'));
        if (((this.userInfo?.roleId == 3) && (this.isUserToggle == false))) {
            this.segmentModel = "Awaiting Finance";
        } else {
            this.segmentModel = "Awaiting Approval";
        }
        this.SearchListText.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((term: string) => {
                this.ExpenseorReport = term;
                this.showLoading = true;
                this.getApproveList(false);
            });
        

        this.bulkForm = this.formBuilder.group({
            toEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        });
        this.downloadForm = this.formBuilder.group({
            selectType: ['', [Validators.required]],
            statusName: ['', [Validators.required]],
            claimTypeId: [''],
            claimTypeName: ['']
        });
        this.downloadForm.controls.selectType.patchValue('selectType');
        this.downloadForm.controls.statusName.patchValue(this.statusName);
        //  this.filterModal.recordTypes = [1];
        // if (this.selectType == "Claim") {
        //     this.downloadModel.isReportLevel = true;
        //    // this.filterModal.recordTypes = [1]; 
        // } else if (this.selectType == "Claim + Expenses") {
        //     this.downloadModel.isReportLevel = false; 
        //     this.filterModal.recordTypes = [1];
        // }else{
        //     this.downloadModel.isReportLevel = false; 
        // }
        let date = new Date();
        this.minimumDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        this.maxExpire = new Date(date.getFullYear() + 40, 11);
        this.allowMax = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    ionViewWillEnter() {
        if (((this.userInfo?.roleId == 3) && (this.isUserToggle == false))) {
            this.segmentModel = "Awaiting Finance";
        } else {
            this.segmentModel = "Awaiting Approval";
        }

        this.pageNumber = 1;
        this.platformType = this.platformService.getPlatformType();
        this.isMobileDevice = this.platformService.isMobileDevice();
        let applicationTitle = this.userInfo?.applicationTitle;
        if (applicationTitle) {
            this.title.setTitle('Approval ' + ' | ' + applicationTitle);
        } else {
            this.title.setTitle('Approval | NoExpenses');
        }
        this.approvestat = [
            { label: "All", count: 0, statusIds: [] },
            { label: "Pending", count: 0, statusIds: [] },
            { label: "Approved", count: 0, statusIds: [] },
            { label: "Rejected", count: 0, statusIds: [] },
        ];
        if (this.platformType == 2) {
            if (this.userInfo?.roleId == 1) {
                this.filterModal.statusIds = [2];
                this.draft = null;
                this.getApproveList(false);
            } else if (this.userInfo?.roleId == 3 && this.isUserToggle == false) {
                this.filterModal.statusIds = [3,12];
                this.draft = null;
            }
            if (this.userInfo?.roleId == 3 && this.isUserToggle == true) {
                this.filterModal.statusIds = [2];
                this.draft = null;
            }
        }
        // if (!this.active_router.url.includes('/emailApproval/')) {
        //     this.getApprovialStats();
        // }else{
        //     this.getApproveList(true);
        // }
        // if (JSON.parse(sessionStorage.getItem('approvalData'))) {
        //     let previous_data = JSON.parse(sessionStorage.getItem('approvalData'));
        //     this.matTabIndex = previous_data.position;
        //     this.approveList = previous_data.approveList;
        //     this.showReportDetail(this.approveList[previous_data.index]);            
        // } else {
        //     if (!this.active_router.url.includes('/emailApproval/')) {
        //         this.getApproveList(true);
        //     }
        // }

        this.filterApprovalStatus();
        if (!this.active_router.url.includes('/emailApproval/')) {
            this.getApproveList(true);
        }

        //status list recall functionality
        if (JSON.parse(localStorage.getItem('common-status'))) {
            this.statusList = JSON.parse(localStorage.getItem('common-status'));
        } else {
            this.getStatusList();
        }
        const date = new Date();
        this.maxDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        );
        this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.currentday = moment(new Date()).format(Constants.mobileCalenderFormat);

        // category list recall functionality
        if (JSON.parse(localStorage.getItem('category-list'))) {
            this.categoryList = JSON.parse(localStorage.getItem('category-list'));
        } else {
            this.getCategorylist();
        }
        if (this.platformType === 2) {
            this.searchBar = true;
        }
    }

    get b() {
        return this.bulkForm.controls;
    }
    apprstatRequest = new ApprstatRequest();

    getApprovialStats() {
        let typeV = JSON.parse(sessionStorage.getItem('isadmin'));
        this.statsLoading = true;
        this.apprstatRequest.userId = this.userInfo?.userId;
        this.apprstatRequest.userGUID = this.userInfo?.userGuId;
        this.apprstatRequest.viewType = typeV ? this.userInfo?.roleId : 1;
        this.apprstatRequest.legalEntityId = this.userInfo?.legalEntityId;
        this.apprstatRequest.recordType = this.matTabIndex != 0 ? this.filterModal.recordTypes[0] : null;
        this.apprstatRequest.legalEntityGUID = this.userInfo?.legalEntityGuId;
        this.reportsService
            .approvialStats(this.apprstatRequest)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    const listarray = res.payload.approvalStats;
                    this.approvestat = listarray.map(
                        (list) => new ApprovialstatRes(list)
                    );
                    // if (typeV) {
                        this.approvalstatus = [...this.approvestat]
                    // }
                    this.approvestat.forEach(element => {
                        element.isSelected = false;
                    });
                    const stateIndex = this.approvestat.findIndex(s => s.label == this.labelName);
                    if (stateIndex != -1) {
                        this.approvestat[stateIndex].isSelected = true;
                    }

                    if (this.routerState && this.routerState.data) {
                        if (this.routerState.data.name == 'approved') {
                            this.approvestat.forEach((element, index) => {
                                if (element.label == 'Finance Approved') {
                                    element.isSelected = true;
                                    let newModel = new filterM();
                                    newModel.index = index;
                                    newModel.type = 'status';
                                    newModel.value = element.label;
                                    this.filterdata.push(newModel);
                                }
                            });
                        } else if (this.routerState.data.name == 'rejected') {
                            this.approvestat.forEach((element, index) => {
                                if (element.label == 'Rejected') {
                                    element.isSelected = true;
                                    let newModel = new filterM();
                                    newModel.index = index;
                                    newModel.type = 'status';
                                    newModel.value = element.label;
                                    this.filterdata.push(newModel);
                                }
                            });
                        }
                    }

                    this.totalAdvanceReportCount = res.payload.totalAdvanceReportCount;
                    this.totalExpenseReportCount = res.payload.totalExpenseReportCount;
                    this.statsLoading = false;

                    if (this.platformType == 2) {
                        this.getStatsforMobile();
                    }


                } else {
                    this.toastr.error(res.errorMessage, "Error");
                    this.statsLoading = false;
                }
            });
    }

    async updateReport(list) {
        const modal = await this.modalController.create({
            component: ReportDetailviewComponent,
            cssClass: "my-custom-class",
            componentProps: {
                detailReport: list,
                reportList: this.approveList,
                fromPage: 'approvePage'
            },
            id: 'approvemodal'

        });
        modal.onDidDismiss().then(result => {
            this.getApproveList(true);
        });
        return await modal.present();
    }

    loadMore(event) {
        this.filterApprovalStatus();
        if (!this.isApicallinProgress) {
            this.pageNumber++;
            setTimeout(() => {
            this.getApproveList(false, event);
            }, 2000);
        }
    }

    getApproveList(isPageLoad, event?, hideListReload?) {

        let typeV = JSON.parse(sessionStorage.getItem('isadmin'));
        this.isPageLoad = isPageLoad;
        if (this.pageNumber === 1 && !hideListReload) {
            this.showLoading = true;
            this.approveList = [];
        }

        // filter logic when we come from dashboard
        if (this.routerState && this.routerState.data) {
            if (this.routerState.data.name == 'approved') {
                this.filterModal.statusIds = [6];
            } else if (this.routerState.data.name == 'rejected') {
                this.filterModal.statusIds = [4];
            }
        }

        this.filterModal.claimTypeId = this.filterClaimTypeId;
        this.filterModal.claimTypeName = this.filterClaimTypeName;

        // this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        this.expenseAndReportModel.legalEntityId = this.userInfo?.legalEntityId;
        this.expenseAndReportModel.legalEntityGUID = this.userInfo?.legalEntityGuId;
        this.expenseAndReportModel.userId = this.userInfo?.userId;
        this.expenseAndReportModel.userGUID = this.userInfo?.userGuId;
        this.expenseAndReportModel.pageNumber = this.pageNumber;
        this.expenseAndReportModel.pageSize = this.pageSize;
        this.expenseAndReportModel.filterBy = this.filterModal;
        this.filterModal.searchText = this.ExpenseorReport;
        this.expenseAndReportModel.viewType = typeV ? this.userInfo?.roleId : 1;
        this.expenseAndReportModel.filterBy.isDraft = this.platformType == 2 ? this.draft : null;
        this.expenseAndReportModel.sortType = this.ascending ? 1 : 2;
        this.expenseAndReportModel.isTripAdvance = this.filterModal.isTripAdvance;
        // this.expenseAndReportModel.sortColumn = "submittedDate";
        this.isApicallinProgress = true;

        this.reportsService
            .ApproveList(this.expenseAndReportModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    if (this.pageNumber == 1) {
                        this.approveList = [];
                    }
                    if(res.payload.pendingCount != null){
                        this.sharedService.setPeningApprovalCount(res.payload.pendingCount);
                    }
                    this.showLoading = false;
                    this.isApprovalExist = res.payload.isApprovalExist;
                    this.minAndMax = res.payload;
                    this.changeOptions(
                        res.payload.reportMinAmount,
                        res.payload.reportMaxAmount
                    );
                    res.payload.reportResponseList?.forEach(element => {
                        element.showCheckBox = false;
                        element.visibility = false;
                    });
                    const listarray = res.payload.reportResponseList;

                    this.approveList = this.approveList.concat(listarray?.map((list) => new ReportSummaryResponseModal(list)));
                    // console.log('sdsdsdsdsd',this.approveList);
                    this.totalRecords = res.payload.reportsCount != null ? res.payload.reportsCount : 0
                    // if((this.isUserToggle || this.userInfo?.roleId == 1) && this.approveList?.length == 0){
                    //     this.hideReporttable = false;
                    // }
                    if (this.approveListdata.length == 0) {
                        // this.approveListdata = this.approveList.concat(listarray.map((list) => new ReportListResModel(list)));
                        this.approveListdata = [...this.approveList];
                    }

                    if (this.pageNumber === 1 && this.isPageLoad && typeV) {
                        this.isPageLoad = false;
                    }
                    if (event) {
                        event.target.complete();
                    }
                    if (res.payload.reportResponseList?.length < this.pageSize && event) {
                        event.target.disabled = true;
                    }
                    this.isApicallinProgress = false;
                    this.returnExpSegment();

                } else {
                    this.showLoading = false;
                    this.isApicallinProgress = false;
                    this.toastr.error(res.errorMessage, "Error");
                    if (event) {
                        event.target.disabled = true;
                    }
                }
            });
    }

    showCheckbox(item): void {
        if(this.matTabIndex == 0){
            item.showCheckbox = true;
        }
      }
    
      hideCheckbox(item): void {
        item.showCheckbox = false;
      }

    onChangeListener(event, index, listItem) {
        this.approveList[index].visibility = event.detail.checked;
        if (this.approveList[index].visibility) {
            this.selectedClaimId = this.approveList[index].claimId;
            this.selectedClaimGuid = this.approveList[index].claimGUID;
            this.selectedItems.push(this.approveList[index].claimId);
        } else {
            const indexv = this.selectedItems.findIndex((item) => item === listItem.claimId);
            if (indexv !== -1) {
                this.selectedItems.splice(indexv, 1);
            }
        }
    }

      approvalAll(){
        this.showApprovalLoder = true;
        let request = {
            "isLinkedToAdvancePayment": false,
            "skipBudgetCheckMessage": true,
            "skipAnomolies": true,
            "claimId": this.selectedClaimId,
            "claimIds": this.selectedItems,
            "claimGUID": this.selectedClaimGuid,
            "recordType": 1,
            "isLinkedToPettyCashPayment": false,
            "expenseList": [],
            "userId": this.userInfo.userId,
            "userGUID": this.userInfo.userGuId,
            "legalEntityId": this.userInfo.legalEntityId,
            "legalEntityGUID": this.userInfo.legalEntityGuId
        }

        this.reportsService.Approve(request).subscribe((res) => {
             if (!res.isError && res.errorCode === 0) {
                this.showApprovalLoder = false;
                this.pageNumber = 1;
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                this.selectedItems = [];
                this.getApproveList(false);
            } else if (res.isError && res.errorCode === 2) {
                this.showApprovalLoder = false;
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
      }

    async opendetails(report) {
        const modal = await this.modalController.create({
            component: DetailapprovalComponent,
            cssClass: "my-custom-class",
            componentProps: {
                report
            },
        });

        return await modal.present();
    }


    returnDasbord() {
        //this.router.navigateRoot(["dashboard"]);
        let typeVal = JSON.parse(sessionStorage.getItem("fc365"));
        if (typeVal) {
            this.router.navigateRoot(["moreoptions"]);
        } else {
            this.router.navigateRoot(["dashboard"]);
        }
    }

    approveFilter() {
        this.showApprovalFilter = true;
        this.aprovList = false;
        this.approvalHide = false;
        this.showLoading = false;
    }

    returnExpSegment() {
        this.showApprovalFilter = false;
        this.aprovList = true;
    }

    returnExpense() {
        this.approvalHide = true;
    }

    approveSegment(e) {
        this.segmentModel = e.target.value;
        if (this.userInfo?.roleId == 1) {
            if (e.target.value === "Awaiting Approval") {
                this.draft = null;
                this.expenseAndReportModel.recordType = 1;
            } else {
                this.draft = null;
                this.expenseAndReportModel.recordType = 1;
            }
        }
        if (this.userInfo?.roleId == 1) {
            this.approvestat.forEach(element => {
                if (e.target.value == element.label) {
                    if (element.label == "Awaiting Approval") {
                        this.filterModal.statusIds = element.statusIds
                    } else if (element.label == "Approved") {
                        this.filterModal.statusIds = element.statusIds
                    }
                }
            });
        }

        if (this.userInfo?.roleId == 3 && (this.isUserToggle == false)) {
            if (e.target.value === "Awaiting Finance") {
                this.draft = null;
                this.expenseAndReportModel.recordType = 1;
            } else if (e.target.value === "Finance Approved") {
                this.draft = null;
                this.expenseAndReportModel.recordType = 1;
            }
        }
        if (!(this.userInfo?.roleId == 3 && (this.isUserToggle == false))) {
            if (e.target.value === "Awaiting Approval") {
                this.draft = null;
                this.expenseAndReportModel.recordType = 1;
            } else if (e.target.value === "Approved") {
                this.draft = null;
                this.expenseAndReportModel.recordType = 1;
            }
        }
        if (this.userInfo?.roleId == 3 && (this.isUserToggle == false)) {
            this.approvestat.forEach(element => {
                if (e.target.value == element.label) {
                    if (element.label == "Awaiting Finance") {
                        this.filterModal.statusIds = element.statusIds
                    } else if (element.label == "Finance Approved") {
                        this.filterModal.statusIds = element.statusIds
                    }
                } else {
                }
            });
        }
        if (!(this.userInfo?.roleId == 3 && (this.isUserToggle == false))) {
            this.approvestat.forEach(element => {
                this.approvestat.forEach(element => {
                    if (e.target.value == element.label) {
                        if (element.label == "Awaiting Approval") {
                            this.filterModal.statusIds = element.statusIds
                        } else if (element.label == "Approved") {
                            this.filterModal.statusIds = element.statusIds
                        }
                    }
                });
            });
        }
        this.pageNumber = 1;
        this.getApproveList(false);
        // this.resetAll();
        this.selectcatarraymob = [];
        this.selectedstatusmob = [];

    }

    getCategorylist() {
        this.CategoryMerchantModel.companyId = this.userInfo?.companyId;
        this.CategoryMerchantModel.companyGUID = this.userInfo?.companyGuId;
        this.CategoryMerchantModel.legalEntityId = this.userInfo?.legalEntityId;
        this.CategoryMerchantModel.legalEntityGUID = this.userInfo?.legalEntityGuId;
        this.CategoryMerchantModel.status = 1;
        this.CategoryMerchantModel.categoryType = null;
        this.commonService
            .getCategorylist(this.CategoryMerchantModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    const listarray = res.payload.categoryList;
                    this.categoryList = listarray.map((list) => new CategoryList(list));
                    localStorage.setItem('category-list', JSON.stringify(this.categoryList));
                } else if (res.isError === true) {
                    this.toastr.error(res.errorMessage, "Error");
                }
            });
    }

    selectCategorymob(e) {
        const selected = e.isSelected;
        // this.selectcatarraymob = e.component._selectedItems;

        if (selected === true) {
            this.selectcatarraymob.push(e.item.categoryId);
        } else {
            let index = _.indexOf(this.selectcatarraymob, e.item.categoryId);
            this.selectcatarraymob.splice(index, 1);
        }
        this.filterModal.categoryIds = this.selectcatarraymob;
    }

    selectstatusmob(e) {
        const selected = e.isSelected;
        if (selected == true) {
            this.selectedstatusmob.push(e.item.statusId);
        } else {
            let index = _.indexOf(this.selectedstatusmob, e.item.statusId);
            this.selectedstatusmob.splice(index, 1);
        }
        this.filterModal.statusIds = this.selectedstatusmob;
    }

    reportexpsearch(e) {
        this.filterModal.searchText = e.detail.value;
    }

    getfromDate(e) {
        if (e.detail.value != "") {
            this.frmDate = e.detail.value;
            this.filterModal.fromDate = this.datechangeService.convertCalendarToAPI(e.target.value);
            this.minDate = moment(new Date(this.frmDate)).format(Constants.mobileCalenderFormat);
        } else {
            this.filterModal.fromDate = null;
        }

    }

    gettodate(e) {
        if (e?.detail?.value != "") {
            this.tDate = e.detail.value;
            this.filterModal.toDate = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
            this.mobMaxDate = moment(new Date(this.tDate)).format(Constants.mobileCalenderFormat);
        } else {
            this.filterModal.fromDate = null;
        }

    }

    getFromDateCall(ev) {
        this.frmDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.fromDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.minDate = moment(ev.detail.value).format("DD-MM-YYYY");
        this.minDateStr = moment(ev.detail.value).format("YYYY-MM-DD");
    }

    getToDateCall(ev) {
        this.tDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.toDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.mobMaxDate = moment(ev.detail.value).format("DD-MM-YYYY");
    }

    applyfilter() {
        this.filterdata = [];
        this.showFilter = false;
        this.pageNumber = 1;
        if (this.frmDate !== undefined && this.tDate == undefined) {
            this.tDate = this.mobMaxDate;
            this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
        }
        if (this.frmDate == undefined && this.tDate !== undefined) {
            this.frmDate = this.tDate;
            this.filterModal.fromDate = this.tDate;
        }
        this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
        this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;

        this.filterModal.claimTypeId = this.filterClaimTypeId;
        this.filterModal.claimTypeName = this.filterClaimTypeName;

        if (this.filterModal.fromDate && this.filterModal.toDate) {
            let dateModel = new filterM();
            dateModel.type = 'date';
            dateModel.index = 0;
            dateModel.value = moment(new Date(this.frmDate)).format('DD-MMM-YY') + ' to ' + moment(new Date(this.tDate)).format('DD-MMM-YY');
            this.filterdata.push(dateModel);
        } else if (this.filterModal.fromDate && !this.filterModal.toDate) {
            let dateModel = new filterM();
            dateModel.type = 'date';
            dateModel.index = 0;
            dateModel.value = moment(new Date(this.frmDate)).format('DD-MMM-YY');
            this.filterdata.push(dateModel);
        } else if (!this.filterModal.fromDate && this.filterModal.toDate) {
            let dateModel = new filterM();
            dateModel.type = 'date';
            dateModel.index = 0;
            dateModel.value = moment(new Date(this.tDate)).format('DD-MMM-YY');
            this.filterdata.push(dateModel);
        }

        if (this.filterModal.minPrice > 1 || this.filterModal.maxPrice < this.minAndMax?.reportMinAmount) {
            let dateModel = new filterM();
            dateModel.type = 'amount';
            dateModel.index = 0;
            dateModel.value = this.filterModal.minPrice + ' to ' + this.filterModal.maxPrice
            this.filterdata.push(dateModel);
        }
        this.filterModal.statusIds = [];
        this.approvestat.forEach((element, index) => {
            if (element.isSelected && element.label != 'All') {
                let newModel = new filterM();
                newModel.index = index;
                newModel.type = 'status';
                newModel.value = element.label;
                this.filterModal.statusIds = this.filterModal.statusIds.concat(element.statusIds);
                this.filterdata.push(newModel);
            }
        });
        this.filterModal.statusIds = _.uniqBy(this.filterModal.statusIds);

        if (this.labelName == 'All Claims') {
            this.filterModal.recordTypes = [1];
        } else if (this.labelName == 'Local Conveyence') {
            this.filterModal.recordTypes = [3];
        } else if (this.labelName == 'All Advances') {
            this.filterModal.recordTypes = [2];
        } else if (this.labelName == 'Petty Cash') {
            this.filterModal.recordTypes = [4, 5];
        } else if (this.labelName == 'Travel') {
            this.filterModal.recordTypes = [6];
        }
        // if(concat_arry.length != 0){
        //     console.log('concate:',concat_arry);
        //     this.filterModal.statusIds = concat_arry;
        // }
        if(this.labelName == 'Pending') {
            this.filterApprovalStatus();
        }
        this.getApproveList(false);
        this.returnExpSegment();
        this.maxDate = new Date();

    }

    removeFIlter(item, indexV) {
        this.filterdata.splice(indexV, 1);
        this.pageNumber = 1;
        if (item.type == 'date') {
            this.resetDates();
            this.getApproveList(false);
            this.maxDate = new Date();
        } else if (item.type == 'status') {
            this.approvestat[item.index].isSelected = false;
            this.filterModal.statusIds = [];
            this.approvestat.forEach((element, index) => {
                if (element.isSelected == true) {
                    this.filterModal.statusIds = this.filterModal.statusIds.concat(element.statusIds);
                }
            });
            // filter logic when we come from dashboard...REMOVING ROUTER INFORMATION
            if (this.filterdata.length == 0) {
                this.routerState = null;
            }
            this.getApproveList(false);
        } else if (item.type == 'amount') {
            this.resetAmount();
            this.getApproveList(false);
        }
    }

    showRepushButton: any;
    repushButtonLoader = false;
    RepushHistoryClick(RepushHistory:TemplateRef<any>,report){
        const config = {
            ignoreBackdropClick: false,
            class: "modal-xl modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(RepushHistory, config);

        this.integrationHistory = [];
        this.expenseService.getIntegrationHistory({claimId:report.claimId,claimGUID:report.claimGUID}).subscribe((res) =>{
            if(res.errorCode == 0){
                this.integrationHistory = [];
                if(res.payload.integrationHistory.length != 0){
                    res.payload.integrationHistory.forEach(element => {
                       element.repushSpinner = false;
                    });
                    this.showRepushButton = res?.payload?.showRepushButton || false;
                // res.payload.integrationHistory = _.map(res.payload.integrationHistory, (item) => ({ ...item, repushSpinner: false }));
                }
                this.repushloading = false;
                this.integrationHistory = [...res.payload.integrationHistory];
            }

        })
    }

    RepushRequest(item?,index?){
        console.log(this.integrationHistory);
        let obj = {
            claimId:this.integrationHistory[0]?.claimId,
            claimGUID:this.integrationHistory[0]?.claimGUID,
            legalEntityGuId:this.userInfo?.legalEntityGuId,
            legalEntityId:this.userInfo?.legalEntityId
        }
        console.log(obj);
        // this.integrationHistory[index].repushSpinner = true;
        this.repushButtonLoader = true;
        this.expenseService.repushRequest(obj).subscribe(res =>{
            console.log(res);
            this.repushButtonLoader = false;
            // this.integrationHistory[index].repushSpinner = false;
            // this.integrationHistory[index].showRepushButton = false;
            this.toaster.success('Success',res.payload);
            this.pageNumber = 1;
            this.getApproveList(false);
            this.modalClose();
        }, err => {
            this.repushButtonLoader = false;
        })
    }

    resetAllFilters() {
        this.resetDates();
        this.resetAmount();
        this.resetClaimType();
        this.filterdata = [];
        this.filterModal.statusIds = [];
        // if (this.userInfo?.roleId == 1 || this.userInfo?.roleId == 2) {
        //     this.labelName = 'All'
        //     this.filterModal.statusIds = [2, 3, 4, 5, 6, 7, 9, 10]
        //     this.filterModal.statusAction = 'All';
        //     this.filterdataM.type = 'status';
        //     this.filterdataM.index = 0;
        //     this.filterdataM.value = 'All';
        //     this.filterdata.push(this.filterdataM);
        // } else {
        //     this.labelName = 'Awaiting Approval'
        //     this.filterModal.statusIds = [2]
        //     this.filterModal.statusAction = 'Awaiting Approval';
        // }
        this.approvestat.forEach(element => {
            element.isSelected = false;
        });
        this.filterApprovalStatus();
        this.getApproveList(false);
    }

    changeOptions(minValue, maxValue) {
        const newOptions: Options = Object.assign({}, this.options);
        newOptions.floor = minValue;
        newOptions.ceil = maxValue;
        this.minPrice = minValue;
        this.maxPrice = maxValue;
        this.options = newOptions;
    }

    resetAmount() {
        this.filterModal.minPrice = this.minAndMax?.reportMinAmount;
        this.filterModal.maxPrice = this.minAndMax?.reportMaxAmount;
    }

    resetStatus() {
        this.approvestat.forEach(element => {
            element.isSelected = false;
        });
        this.filterModal.statusIds = [];
    }

    priceSlider(changeContext: ChangeContext) {
        this.filterModal.minPrice = changeContext.value;
        this.filterModal.maxPrice = changeContext.highValue;
    }

    apply() {
        // this.selectCatComponent.confirm();
        // this.selectCatComponent.close();
    }

    statusApply() {
        this.selectstatusComponent.confirm();
        this.selectstatusComponent.close();
    }

    resetAll() {
        this.ExpenseorReport = "";
        this.frmDate = undefined;
        this.tDate = null;
        this.filterModal.fromDate = null;
        this.filterModal.toDate = null;
        this.filterModal.maxPrice = null;
        this.filterModal.minPrice = null;
        this.status = "";
        this.categoryName = "";
        this.minDate = "";
        this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        // this.resetAmount();
    }

    resetSinglefilter(type) {
        if (type) {
            switch (type) {
                case 1:
                    this.frmDate = undefined;
                    this.filterModal.fromDate = this.frmDate;
                    this.minDate = "";
                    this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
                    break;
                case 2:
                    this.tDate = undefined;
                    this.filterModal.toDate = this.tDate;
                    this.minDate = "";
                    this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
                    break;
                case 3:
                    this.status = "";
                    this.selectedstatusmob = [];
                    this.filterModal.statusIds = this.selectedstatusmob;
                    break;
                case 4:
                    this.categoryName = "";
                    this.selectcatarraymob = [];
                    this.filterModal.categoryIds = this.selectcatarraymob;
                    break;
            }
        }
    }

    toggleColumns(e, val: string) {
        if (e.target.checked == true) {
            this.hideColumns.push(val);
        } else {
            let index = this.hideColumns.indexOf(val);
            if (index > -1) {
                this.hideColumns.splice(index, 1);
            }
        }
    }

    showhideColumns(val: string) {
        let column = true;
        if (this.hideColumns.indexOf(val) > -1) {
            column = false;
        }
        return column;
    }

    sortReport() {
        this.sortBtn = !this.sortBtn;
        if (this.sortBtn) {
            this.approveList.sort(
                (a, b) =>
                    new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
            );
        }
        if (!this.sortBtn) {
            this.approveList.sort(
                (b, a) =>
                    new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
            );
        }
    }

    addSearchBar() {
        this.searchBar = !this.searchBar;
    }

    approvalSearch(val) {
        this.ExpenseorReport = val.currentTarget.value;
        if (this.ExpenseorReport?.length >= 0) {
            this.resetPaging();
            this.SearchListText.next(this.ExpenseorReport);
        }
        if (this.ExpenseorReport === "") {
            this.resetPaging();
            this.getApproveList(false);
        }
    }


    getApprovestatus(list, adv, index, event) {
        if (list.label == 'All') {
            this.approvestat.forEach(element => {
                element.isSelected = event.detail.checked;
            });
        } else {
            this.approvestat[index].isSelected = event.detail.checked;
        }
        // this.label = list.label;
        this.labelName = list.label;
        this.filterModal.toDate = list.toDate;
        this.filterModal.fromDate = list.fromDate;
        this.downloadForm.controls.selectType.patchValue('selectType');

        if (list.label == 'All') {
            this.statusName = '';
            this.filterModal.statusIds = list.statusIds;
            this.downloadForm.controls.statusName.patchValue('');
            // this.approvalstatus = [...this.approvestat];
        } else {
            this.filterModal.statusIds = list.statusIds;
            this.statusName = this.label;
            this.downloadForm.controls.statusName.patchValue(this.label);
        }
        
    }

    showReportDetail(list, index?,event?) {
        if(list != undefined){
            sessionStorage.setItem('selectedValue',JSON.stringify(list));
        }
        this.currentClaimIndex = index || 0;
        this.detailReport = list;
        sessionStorage.setItem('selected-value', JSON.stringify(this.detailReport))
        this.detailsReport = this.approveList[index];
        this.showDetailreport = true;
        let approvalData = {
            'position': this.matTabIndex,
            'approveList': this.approveList,
            'index':index,
        }
        sessionStorage.setItem('approvalData', JSON.stringify(approvalData));
        this.hideReporttable = false;
    }

    backToapproval(report?) {
        if (JSON.parse(sessionStorage.getItem('approvalData'))) {
            let previous_data = JSON.parse(sessionStorage.getItem('approvalData'));
            this.matTabIndex = previous_data.position;
            this.approveList = previous_data.approveList;
            this.hideReporttable = true;
            this.showLoading = false;
            this.showDetailreport = false;
            if (report) {
                if (this.matTabIndex == 0 && JSON.parse(sessionStorage.getItem('selected-value')).status != report.status) {
                    this.approveList.splice(this.currentClaimIndex, 1)
                } else {
                    this.approveList[this.currentClaimIndex].status = report?.status;
                    this.approveList[this.currentClaimIndex].statusDescription = report?.statusDescription;
                }
            }
            this.pageNumber = 1;
            this.getApproveList(false, null, true);
        }
    }

    getStatusList() {
        let type = { type: +2 };
        this.commonService.statusList(type).subscribe(res => {
            if (!res.isError && res.errorCode == 0) {
                let listarray = res.payload;
                this.statusList = listarray.map((list) => new StatusListModel(list));
                localStorage.setItem('common-status', JSON.stringify(this.statusList));
            } else if (res.isError == true) {
                this.toastr.error(res.errorMessage, "Error");
            }
        });
    }

    ngOnDestroy() {
        this.sharedService.setRefreshData(false);
        if (this.SearchListText) {
            this.SearchListText.unsubscribe();
        }
    }

    ionViewWillLeave() {
        this.sharedService.setRefreshData(false);
        if (this.SearchListText) {
            this.SearchListText.unsubscribe();
        }
    }

    async openDateSheet(isFromDate: boolean) {
        const modal = await this.modalController.create({
            component: CustomDatePickerComponent,
            componentProps: {
                selectedDate: isFromDate ? this.frmDate : this.tDate,
                fromDate: isFromDate ? new Date('1981-01-01') : this.frmDate,
                toDate: isFromDate ? this.tDate ? this.tDate : new Date() : new Date()
            },
            cssClass: 'custom-bottom-sheet'
        });
        modal.onDidDismiss().then(result => {
            if (result.role === 'changed' && result.data) {
                if (isFromDate) {
                    this.frmDate = result.data.displayDate;
                } else {
                    this.tDate = result.data.displayDate;
                }
            }
        });
        return await modal.present();
    }

    async openModal(value: string, type: string, isCategory?: boolean) {
        const componentProps = this.getComponentProps(type);
        if (value && value !== '') {
            componentProps.value = this.filterModal[componentProps.setValueKey];
        }
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    // TODO give onchange methods for status and category
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        const arr = [];
                        if (isCategory) {
                            this.categoryName = null;
                        } else {
                            this.status = null;
                        }
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                arr.push(obj[componentProps.itemValueField]);
                                if (isCategory) {
                                    if (this.categoryName && this.categoryName !== '') {
                                        this.categoryName = `${this.categoryName} , ${obj[componentProps.itemTextField]}`;
                                    } else {
                                        this.categoryName = obj[componentProps.itemTextField];
                                    }
                                } else {
                                    if (this.status && this.status !== '') {
                                        this.status = `${this.status} , ${obj[componentProps.itemTextField]}`;
                                    } else {
                                        this.status = obj[componentProps.itemTextField];
                                    }
                                }
                            }
                        });
                        if (arr && arr.length) {
                            this.filterModal[componentProps.setValueKey] = arr;
                        }
                        /* callback.data.forEach((obj: any) => {
                          if (obj.isSelected) {
                            if (isExpenseForm) {
                              this.expenseForm.controls[type].setValue(obj[componentProps.itemTextField]);
                              this.expenseModel[componentProps.itemTextField] = obj[componentProps.itemTextField];
                              this.expenseModel[componentProps.itemValueField] = obj[componentProps.itemValueField];
                              if (type === 'categoryName') {
                                this.getMerchantlist(obj);
                              }
                            } else {
                              this.mileageForm.controls[type].setValue(obj[componentProps.itemTextField]);
                              this.expenseModel[componentProps.itemTextField] = obj[componentProps.itemTextField];
                              this.expenseModel[componentProps.itemValueField] = obj[componentProps.itemValueField];
                              if (type === 'categoryName') {
                                this.selectCategoryMileageOption(obj);
                              }
                            }
                          }
                        });
                       } else {
                        if (isExpenseForm) {
                          this.expenseForm.controls[type].setValue(callback.data);
                          this.expenseModel[componentProps.itemTextField] = callback.data;
                        } else {
                          this.mileageForm.controls[type].setValue(callback.data);
                          this.mileageForm[componentProps.itemTextField] = callback.data;
                        }*/
                    }
                }
            });
        return await searchItemModal.present();
    }

    getComponentProps(type: string) {
        switch (type) {
            case 'categoryName':
                return {
                    title: 'Categories',
                    value: null,
                    mainList: this.categoryList,
                    isMultiSelect: true,
                    itemValueField: 'categoryId',
                    itemTextField: 'categoryName',
                    listName: `categoryList`,
                    setValueKey: 'categoryIds'
                };
            case 'status':
                return {
                    title: 'Status',
                    value: null,
                    mainList: this.statusList,
                    isMultiSelect: true,
                    itemValueField: 'statusId',
                    itemTextField: 'statusDescription',
                    listName: `statusList`,
                    setValueKey: 'statusIds'
                };
        }
    }

    resetBlur(eleInput: IonInput) {
        eleInput.getInputElement().then((ele) => ele.blur());
    }

    selectSinglereport(list, e) {
        list.isSelected = e.target.checked;
        if (list.isSelected) {
            this.selectedReport.push(list.claimId);
        } else {
            let index = _.indexOf(this.selectedReport, list.claimId);
            this.selectedReport.splice(index, 1);
        }
        //  this.downloadModel.referenceIds = this.selectedReport.toString();

        if (
            this.approveList.length != this.selectedReport.length &&
            this.selectedReport.length > 0
        ) {
            this.checkAll = false;
            this.indeterminateState = true;
        } else if (this.selectedReport.length == 0) {
            this.checkAll = false;
            this.indeterminateState = false;
        } else {
            this.checkAll = true;
            this.indeterminateState = false;
        }
        this.downloadModel.referenceIds = this.selectedReport.toString();
    }

    selectAllApproveList(event?) {
        setTimeout(() => {
            this.approveList.forEach((item) => {
                item.isSelected = event ? false : this.checkAll;
            });
        });
    }

    closeDownloadOptTemp() {
        this.modalRef.hide();
    }

    downloadOptSelector(value) {
        switch (value) {
            case 'excel':
                this.showDownloadOptions = true;
                this.downloadOptionSelection = 1;
                this.downloadApiCall(null);
                break;
            case 'pdf':
                this.showDownloadOptions = true;
                this.downloadOptionSelection = 2;
                this.downloadApiCall('PDF');
                break;
        }
    }

    downloadModel = new DownloadModel();
    downloadCancel() {
        this.modalRef.hide();
    }
    bulkDownload(downloadOptTemp?: TemplateRef<any>) {
        if (this.selectedReport.length == 0 || this.selectedReport.length > 1) {
            const config = {
                ignoreBackdropClick: true,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.downloadLoader, config);
            this.downloadApiCall();
        } else {
            const config = {
                ignoreBackdropClick: true,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(downloadOptTemp, config);
        }
    }

    downloadApiCall(value?) {

        if (this.selectedReport.length) {
            this.downloadModel.referenceIds = this.selectedReport.toString();
        } else {
            this.downloadModel.referenceIds = null;
        }
        this.downloadBtn = true;
        this.filterModal.isDraft = null;
        this.downloadModel.filterBy = this.filterModal;
        this.downloadModel.userId = this.userInfo?.userId;
        this.downloadModel.userGUID = this.userInfo?.userGuId;
        this.downloadModel.entityType = 2;
        this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        this.downloadModel.legalEntityId = this.userInfo?.legalEntityId;
        this.downloadModel.legalEntityGUID = this.userInfo?.legalEntityGuId;
        this.downloadModel.viewType = this.userInfo?.roleId;
        this.downloadModel.docType = value;
        this.downloadModel.isApprover = true;
        this.downloadModel.downloadActionType = this.matTabLabel;
        this.reportsService.downloadList(this.downloadModel).subscribe(res => {
            if (!res.error && res.payload) {
                this.downloadPoolingStart(res.payload, 3000);
            }
        });

    }
    downloadPoolingStart(request, time) {
        this.downloadActionPoolCount++;
        this.downloadModel.jobId = request.jobId;
        this.downloadModel.jobGUID = request.jobGUID;
        this.commonService.downloadpoolStart(this.downloadModel).subscribe(res => {
            if (!res.isError && !res.payload?.resultFileURL && this.downloadActionPoolCount < 20) {
                this.downloadTime = setTimeout(() => {
                    this.downloadPoolingStart(res.payload, time);
                }, time);
            } else if (!res.isError && res.payload.status == 3) {
                this.downloadBtn = false;
                window.open(res['payload']['resultFileURL'], '_self');
                this.toaster.success('Download successfully', 'Success');
                this.filterModal.fromDate = '';
                this.filterModal.toDate = '';
                //  this.fileName.statusAction='',
                this.downloadActionPoolCount = 0;
                this.showDownloadOptions = false;
                this.approveList.forEach(element => {
                    element.isSelected = false;
                });
                this.modalRef.hide();
                this.submitted = false;
                // this.downloadForm.reset();
                this.downloadForm.controls.selectType.patchValue('selectType');
                if (this.label == "All") {
                    this.downloadForm.controls.statusName.patchValue('');
                } else {
                    this.downloadForm.controls.statusName.patchValue(this.statusName);
                }
                this.filterModal.statusIds = [];
                this.filterModal.recordTypes = [];
                // this.downloadForm.controls.statusName.patchValue(this.statusName); 

            } else {
                this.toaster.error(res.errorMessage, 'Error');
                this.downloadBtn = false;
                this.downloadActionPoolCount = 0;
                this.showDownloadOptions = false;
                this.approveList.forEach(element => {
                    element.isSelected = false;
                });
                this.modalRef.hide();
                this.submitted = false;
                this.downloadForm.reset();

            }
        });
    }

    resetPaging() {
        this.pageNumber = 1;
        this.approveList = [];
        this.approveList.length = 0;
    }

    showType() {
        this.showTypes = !this.showTypes;
    }

    advance() {

    }

    matTabIndex = 0;
    matTabLabel = 'Pending';
    onTabChanged(e) {
        this.showFilter = false;
        this.pageNumber = 1;
        this.resetDates();
        this.resetAmount();
        this.resetClaimType();
        this.selectedItems = [];
        this.approvestat.forEach(element => {
            element.isSelected = false;
        });
        this.filterdata = [];
        if (e.tab.textLabel == 'All Claims') {
            this.filterModal.recordTypes = [1];
            this.filterModal.statusIds = [];
            this.labelName = 'All Claims';
        } else if (e.tab.textLabel == 'Local Conveyence') {
            this.filterModal.recordTypes = [3];
            this.filterModal.statusIds = [];
            this.labelName = 'Local Conveyence';
        } else if (e.tab.textLabel == 'All Advances') {
            this.filterModal.recordTypes = [2];
            this.filterModal.statusIds = [];
            this.labelName = 'All Advances';
        } else if (e.tab.textLabel == 'Petty Cash') {
            this.filterModal.recordTypes = [4, 5];
            this.filterModal.statusIds = [];
            this.labelName = 'Petty Cash';
        } else if (e.tab.textLabel == 'Travel') {
            this.filterModal.recordTypes = [6];
            this.labelName = 'Travel';
            this.filterModal.statusIds = [];
        } else if (e.tab.textLabel == 'All') {
            this.recordTypeStr = [];
            this.filterModal.recordTypes = [];
            this.filterModal.statusIds = [];
            this.labelName = 'All';
        } else if (e.tab.textLabel == 'Pending') {
            this.labelName = 'Pending';
            this.recordTypeStr = [];
        } else {
            this.recordTypeStr = [];
        }
        this.matTabIndex = e.index;
        this.matTabLabel = e.tab.textLabel;
        this.downloadForm.controls.selectType.patchValue('selectType');

        this.tabList = this.tabList.map(element => ({ ...element, dropdown: true }));
        
         if (e.tab.textLabel == 'All Claims') {
            this.tabList = this.tabList.map(element => ({ ...element, dropdown: false }));
            this.tabList[1].dropdown = true;
            this.tabList[2].dropdown = true;
        } else if (e.tab.textLabel == 'All Advances') {
            this.tabList = this.tabList.map(element => ({ ...element, dropdown: false }));
            this.tabList[4].dropdown = true;
        }

        if (e.tab.textLabel == "All Claims") {
            this.downloadModel.isReportLevel = true;
        } else if (e.tab.textLabel == "Claim + Expenses") {
            this.filterModal.recordTypes = [1];
            this.downloadModel.isReportLevel = false;
        } else {
            this.downloadModel.isReportLevel = false;
        }
        this.filterApprovalStatus();
        this.getApproveList(false);
    }

    onChildTabChanged(e) {
        this.pageNumber = 1;
        this.matChildTabIndex = e.index;
        this.matChildTabLabel = e.tab.textLabel;
        this.filterApprovalStatus();
        this.getApproveList(false);
    }

    filterApprovalStatus() {
        const matchingTab = this.approvalTabs.find(
            (at) => at.menuName.toLowerCase() === this.matTabLabel.trim().toLowerCase()
        );

        if (matchingTab) {
            // Set statusIds
            this.filterModal.statusIds = matchingTab.statusIds || [];

            // Find the matching submenu based on subMenuName
            const matchingSubMenu = matchingTab.subMenu?.find((sm) => sm.subMenuName.toLowerCase() === this.matChildTabLabel.trim().toLowerCase());

            if (matchingSubMenu) {
                
                // Set recordTypes
                this.filterModal.recordTypes = matchingSubMenu.recordTypes || [];
                this.filterModal.isCards = matchingSubMenu.isCards || false;
                this.filterModal.isTripAdvance = matchingSubMenu.isTripAdvance || false;

                const listarray = matchingSubMenu.filter || [];
                this.approvestat = listarray?.map(
                    (list) => new ApprovialstatRes(list)
                );
                this.approvalstatus = [...this.approvestat]
                this.approvestat.forEach(element => {
                    element.isSelected = false;
                });
            }

        }
    }

    mobileTabClick(e) {
        this.showFilter = false;
        this.pageNumber = 1;
        this.resetDates();
        this.resetAmount();
        this.approvestat.forEach(element => {
            element.isSelected = false;
        });
        this.filterdata = [];
        if (e.value == 'Claims') {
            this.filterModal.recordTypes = [1];
            this.filterModal.statusIds = [];
            this.labelName = 'All Claims';
        } else if (e.value == 'Local Conveyence') {
            this.filterModal.recordTypes = [3];
            this.filterModal.statusIds = [];
            this.labelName = 'Local Conveyence';
        } else if (e.value == 'Advance') {
            this.filterModal.recordTypes = [2];
            this.filterModal.statusIds = [];
            this.labelName = 'All Advances';
        } else if (e.value == 'Petty Cash') {
            this.filterModal.recordTypes = [4, 5];
            this.filterModal.statusIds = [];
            this.labelName = 'Petty Cash';
        } else if (e.value == 'All') {
            this.filterModal.recordTypes = [];
            this.filterModal.statusIds = [];
            this.labelName = 'All';
        } else if (e.value == 'Pending') {
            this.labelName = 'Pending';
            this.filterApprovalStatus();
            this.recordTypeStr = [];
        } else {
            this.recordTypeStr = [];
        }
        this.filterApprovalStatus();

    }

    refresh_list() {
        this.getApproveList(false);
    }

    getStatsforMobile() {
        var segmentsChange = []
        this.approvestat.forEach(element => {
            if (element) {
                if (element.label == "Awaiting Approval") {
                    segmentsChange.push(element)
                } else if (element.label == "Approved") {
                    segmentsChange.push(element)
                }
            }
        });
    }

    bulkupload(bulkuploadPopup?: TemplateRef<any>) {
        // this.buttonDisabled = false;
        // this.sentMail = true;
        // this.validateEmail = true;
        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(bulkuploadPopup, config);
    }

    addBulkCSV(event) {
        this.handleInputChange(event);
    }

    handleInputChange(event, incomingV?) {
        if (!event.target) {
            return;
        }
        if (!event.target.files) {
            return;
        }

        var file = event.target.files;
        let filesCount = event.target.files.length;
        // this.imagesCount = event.target.files.length;
        for (let i = 0; i < filesCount; i++) {
            var reader = new FileReader();
            var imgfile = event.dataTransfer ? event.dataTransfer.files[i] : file[i];
            //   if (this.totalBulkModel.isCSVFile) {
            //     this.totalBulkModel.csvFileName = imgfile.name;
            //   }
            this.fileName = imgfile.name;
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(imgfile);
        }
    }

    _handleReaderLoaded(e) {
        var reader = e.target;
        this.imgContent = reader.result;
        let _request = {
            fileName: this.fileName,
            content: reader.result,
        };
        this.showBulkUpload_loader = false;
    }
    uploadFile() {
        // this.uploadingFileProgress = true;
        // this.httpCount = 0;
        this.submitted = true;
        if (!this.bulkForm.valid) {
            return;
        } else {
            this.uploadFileStutus = true;
            if (this.fileName) {
                let requestModal = {
                    base64: this.imgContent,
                    legalEntityId: this.userInfo?.legalEntityId,
                    legalEntityGUID: this.userInfo?.legalEntityGuId,
                    toEmailId: this.email,
                    fileName: this.fileName
                }
                this.commonService.bulkFinancePayment(requestModal).subscribe(res => {
                    if (!res.isError && res.errorCode === 0) {
                        this.toastr.success(res.payload, 'Success');
                        this.modalRef.hide();
                        this.bulkForm.reset();
                        this.fileName = null;
                        this.submitted = false;
                        this.uploadFileStutus = false;
                    } else if (res.isError) {
                        this.toastr.success(res.errorMessage, 'Error');
                        this.modalRef.hide();
                        this.bulkForm.reset();
                        this.fileName = null;
                        this.submitted = false;
                        this.uploadFileStutus = false;
                    }
                });
            } else {
                this.toastr.error('please Upload CSV file', 'Error');
                this.uploadFileStutus = false;
                this.submitted = false;
            }
        }

    }
    modalClose() {
        this.modalRef.hide();
    }

    fromdateChange(event) {
        this.minimumDate = new Date(event.target.value);
        this._fromdate = moment(event.value).format("DD-MM-YYYY");
        this.filterModal.fromDate = this._fromdate;
    }
    todateChange(event) {
        this._todate = moment(event.value).format("DD-MM-YYYY");
        this.filterModal.toDate = this._todate;
    }
    selectedrecordType(e: any, item?) {
        if (item.name == "Claim") {
            this.downloadModel.isReportLevel = true;
        } else {
            this.downloadModel.isReportLevel = false;
        }
        this.filterModal.recordTypes = item.id;
        this.downloadForm.controls.selectType.patchValue(item.name);
    }
    selectedstatusType(e: any, item?) {
        this.statusName = item.label;
        this.filterModal.statusIds = item.statusIds;
        this.downloadForm.controls.statusName.patchValue(item.label);
    }
    bulkdataDownload(downloadOptTemp?: TemplateRef<any>) {

        if (this.matTabIndex >= 0) {
            const config = {
                ignoreBackdropClick: true,
                class: "modal-lg modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.downloaddataLoader, config);
            // this.downloadApiCall();

            if (this.selectType == "Claim") {
                this.filterModal.recordTypes = [1]
                this.downloadModel.isReportLevel = true;
            } else if (this.selectType == "Claim +Expenses") {
                this.filterModal.recordTypes = [1]
                this.downloadModel.isReportLevel = false;
            }
            else {
                this.downloadModel.isReportLevel = false;
            }
        } else if (this.matTabIndex == 0) {
            const config = {
                ignoreBackdropClick: true,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.downloadLoader, config);
            this.downloadApiCall();
        }
        else {
            const config = {
                ignoreBackdropClick: true,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(downloadOptTemp, config);
        }

    }
    downloadfilterCancel() {
        this.modalRef.hide();
        setTimeout(() => {
            this.submitted = false;
        }, 2000);
        // this.downloadForm.reset();
        this.downloadForm.controls.selectType.patchValue('selectType');
        //this.downloadForm.controls.statusName.patchValue('statusName');
    }

    get f() {
        return this.downloadForm.controls;
    }
    downloadfilterApiCall(value?) {
        let start = moment(this.filterModal.fromDate, 'DD-MM-YYYY');
        let end = moment(this.filterModal.toDate, 'DD-MM-YYYY');
        let daysCount = end.diff(start, "days");
        if(daysCount > 60 && this.matTabIndex != 0) {
            this.toaster.error("You can\'t download reports more than 60 days.", 'Error');
            return;
        }

        this.submitted = true;
        if (!this.downloadForm.valid) {
            return;
        } else {
            if (this.selectedReport.length) {
                this.downloadModel.referenceIds = this.selectedReport.toString();
            } else {
                this.downloadModel.referenceIds = null;
            }
            this.downloadBtn = true;
            this.filterModal.isDraft = null;
            this.downloadModel.filterBy = this.filterModal;
            this.downloadModel.userId = this.userInfo?.userId;
            this.downloadModel.userGUID = this.userInfo?.userGuId;
            this.downloadModel.entityType = 2;
            this.downloadModel.isAdminView = this.userInfo?.roleId == 3 ? (JSON.parse(sessionStorage.getItem('isadmin')) == true ? false : false) : JSON.parse(sessionStorage.getItem('isadmin'));
            this.downloadModel.legalEntityId = this.userInfo?.legalEntityId;
            this.downloadModel.legalEntityGUID = this.userInfo?.legalEntityGuId;
            this.downloadModel.viewType = this.userInfo?.roleId;
            this.downloadModel.docType = value;
            this.downloadModel.isApprover = true;
            this.downloadModel.downloadActionType = this.matTabLabel;

            this.downloadModel.filterBy.claimTypeId = this.downloadForm?.value?.claimTypeId;
            this.downloadModel.filterBy.claimTypeName = this.downloadForm?.value?.claimTypeName;
            

            this.reportsService.downloadList(this.downloadModel).subscribe(res => {
                if (!res.error && res.payload) {
                    this.downloadPoolingStart(res.payload, 3000);
                }
            });
        }
    }
    showFiltersection(filterby: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-xl modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(filterby, config);
        // this.showFilter = !this.showFilter;
        // this.dropdownFlag = false;
    }
    resetDates() {
        this.frmDate = undefined;
        this.tDate = undefined;
        this.filterModal.fromDate = undefined;
        this.filterModal.toDate = undefined;
        this.minDate = undefined;
        this.maxDate = new Date();
    }
    fromDatev2() {
        this.openfromdate = true;
        this.opentodate = false;
    }
    onClickedOutsideCalenderfrom(e) {
        let el = <HTMLElement>e?.target;
        if (this.openfromdate && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
            || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today"
            || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
            this.fromdate?.open();
        }
        else {
            this.fromdate?.close();

        }

    }
    toDatev2() {
        this.opentodate = true;
        this.openfromdate = false;
    }
    onClickedOutsideCalendertodate(e) {
        let el = <HTMLElement>e?.target;
        if (this.opentodate && (el?.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el?.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
            || el?.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-arrow mat-calendar-invert" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-today"
            || el?.className == "mat-calendar-period-button mat-button mat-button-base" || el?.className == "mat-button-wrapper" || el?.className == "mat-calendar-body-cell-content" || el?.className == "mat-calendar-arrow" || el?.className == "mat-calendar-header" || el?.className == "cdk-overlay-container" || el?.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el?.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el?.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el?.className == "mat-calendar-spacer")) {
            this.todate?.open();


        }
        else {
            this.openfromdate = false;
            this.todate?.close();

        }
    }

    backToDashboard() {
        this.router.navigateRoot(['moreoptions']);
    }
    formatDate(date) {
        if (date) {
          return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');
    
        }
    }










    emailApprovalformSubmit() {
        this.authenticationService.authenticateUser(this.loginModel).subscribe(res => {
            if (!res.isError && res.errorCode === 0 && res.payload) {
                sessionStorage.setItem("isAuthenticated", JSON.stringify(true));
                sessionStorage.setItem("isAttachmentMandatory", JSON.stringify(res.payload.isAttachmentMandatory));
                sessionStorage.setItem("userInfo", JSON.stringify(res.payload));
                localStorage.setItem("userInfo", JSON.stringify(res.payload));
                sessionStorage.setItem("mainheadehidden", JSON.stringify(true));

                this.menuCtrl.enable(true, 'mainMenu');
                this.menuCtrl.enable(false, 'settingMenu');
                // this.isRememberedMe();
                // this.buttonDisabled = false;
                sessionStorage.setItem("current-lang", res.payload.baseLanguage);
                this.authenticationService.status.next(true);
                this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                // this.getperdiemstatus(this.userInfo);

                const obj = {
                    primary: res.payload.primaryColor,
                    secondary: res.payload.secondaryColor,
                    fontFamily: res.payload.font
                };
                sessionStorage.setItem('theme', JSON.stringify(obj));
                // this.theme.applyTheme(obj);
                sessionStorage.setItem("isadmin", JSON.stringify(true));
                sessionStorage.setItem("isEmailApproval", JSON.stringify(true));
                this.showDetailreport = true;
                this.hideReporttable = false;
                // this.router.navigateRoot(['approvals']);
            } else if (res.isError && res.errorCode === 2) {
                // this.buttonDisabled = false;
                this.toaster.error(res.errorMessage, 'Error');
            }
        });
    }
    
    toggleSort(order?: string) {
        order = (this.selectedSort == 'oldest') ? 'newest' : 'oldest';
        if (this.selectedSort === order) {
          // Toggle between ascending and descending if the same option is clicked again
          this.selectedSort = this.selectedSort === 'oldest' ? 'newest' : 'oldest';
        } else {
          this.selectedSort = order;
        }
        // Set sortType based on the selectedSort value
        if (this.labelName === 'Pending') {
          this.selectedSort = order;
          this.ascending = this.selectedSort === 'oldest';
      
          this.expenseAndReportModel.sortType = this.ascending ? 1 : 2;
        //   this.expenseAndReportModel.sortColumn = "submittedDate";
          this.getApproveList(false);
          this.dropdownFlag =false;
        }
      }
 
    toggleMenu(index?) {
      if (this.selectedBulkIndex === index) {
        this.selectedBulkIndex = -1;
        this.isMenuOpen = false;
      } else {
        this.selectedBulkIndex = index;
        this.isMenuOpen = true;
      }
    }
    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        if (!this.menuTrigger) {
            // console.log("menuTrigger is null or undefined");
            return;
        }
    
        // Check if the menuTrigger element contains the event target
        if (!this.menuTrigger.nativeElement.contains(event.target)) {
            this.isMenuOpen = false;
            // console.log("Clicked outside menu. Closing menu.");
        } else {
            // console.log("Clicked inside menu. Menu remains open.");
        }
    }

    resetClaimType(removeFilter?) {
        this.filterModal.claimTypeId = null;
        this.filterModal.claimTypeName = null;
        this.filterClaimTypeId = null;
        this.filterClaimTypeName = null;
        if(removeFilter) {
            this.applyfilter();
        }
    }

    setFilterClaimType(type) {
        this.filterClaimTypeId = type.id;
        this.filterClaimTypeName = type.claimType;
        this.filterModal.claimTypeId = this.filterClaimTypeId;
        this.filterModal.claimTypeName = this.filterClaimTypeName;
    }

    setDownloadClaimType(type) {
        console.log(this.downloadForm.value);
        this.downloadForm.controls.claimTypeId.patchValue(type.id);
        this.downloadForm.controls.claimTypeName.patchValue(type.claimType);
    }

    claimTypes: any = [];
    getClaimTypes() {
      const obj = {
        "action": "claim_types",
        "legalEntityGuid": this.userInfo?.legalEntityGuId,
        "legalEntityId": this.userInfo?.legalEntityId,
        "companyId": this.userInfo?.companyId,
        "companyGUID": this.userInfo?.companyGuId,
      }
      this.expenseService.getClaimTypes(obj).subscribe(res => {
        this.claimTypes = res?.payload || [];
      })
    }
    
}

