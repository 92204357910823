import { Component, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Mileage, MileageList } from 'src/app/models/Settings/mileage.model';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { AppConfig } from 'src/app/config/app.config';
import { SettingsService } from '../../../services/settings-services';
import * as moment from 'moment';
import { Constants } from 'src/app/Constants';
import { CustomDatePickerComponent } from '../../custom-date-picker/custom-date-picker.component';
import { ModalController, IonInput, NavController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common-services/common.service';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-add-mileage',
    templateUrl: './add-mileage.component.html',
    styleUrls: ['./add-mileage.component.scss'],
})
export class AddMileageComponent implements OnInit {
    @ViewChild('close') descriptionRe;
    @ViewChild("country", { static: false }) countryAutoCompleteReference: ElementRef;
    @ViewChild("state", { static: false }) stateAutoCompleteReference: ElementRef;
    @ViewChild('togoglemapCity', { static: false }) googleSearchAutoComplete: ElementRef;
    selectedLegalEntity: any;
    platformType: number;
    categoryList: any;
    loaderList = [1, 2, 3, 4];
    displayTitle = "Add New";
    buttonDisabled: boolean = false;
    mileageModel: any = new Mileage();
    showCategoryList: boolean = false;
    categoryData: any;
    selectedCategory: any;
    mileageForm: FormGroup;
    showStartDate: boolean = false;
    minDate: Date;
    maxExpire: Date;
    allowMax:any;
    validationMsg: any;
    showLoader: boolean = false;
    submitted: boolean = false;
    displayRate = "km";
    mileageUnit: string;
    calendarErrorMsg: boolean = false;
    maxxDate: string;
    minnDate: string;
    userInfo: any;
    selectedDate: any;
    showFromMapField = false;
    showcitySearchListSpinner: boolean = false;
    sessionTokenEnable = true;
    sessionToken: any;
    requestType: any;
    private geocoder: any;
    public showFromList: boolean = false;
    fromCitiesList = [];
    showCountryList: boolean = false;
    showStateList: boolean = false;
    showCurrencyList: boolean = false;
    countryList: any;
    countryData: any;
    selectedCountry: any;
    // legalEntityModel = new LegalEntity();
    stateList: any;
    stateData: any;
    selectedState: any;
    request = {};
    expensePickerOp: boolean = false;

    constructor(
        private platformService: PlatformService,
        private router: NavController,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService,
        private ngZone: NgZone,
        private commonService: CommonService,
        private displaydate: DatechangeService,
        private settingsService: SettingsService,
        private modalController: ModalController
    ) {
        this.mileageForm = this.fb.group({
            locationName: [''],
            vehicleName: ['', Validators.required],
            currency: [''],
            rate: ['', Validators.required],
            startDate: [''],
            country: ["", Validators.required],
            endDate:[''],
            state: [''],
        });
    }

    ngOnInit() {
        this.getCountriesList();
        this.countryList = JSON.parse(sessionStorage.getItem("country"))
    }
    getCountriesList() {
        this.commonService.getCountries(this.request).subscribe(data => {
            this.countryList = data.payload;
            sessionStorage.setItem('country', JSON.stringify(this.countryList));
        });
    }
    ionViewWillEnter() {
        let date = new Date();
        this.minDate = new Date(date.getFullYear(),date.getMonth(), date.getDate());
        this.maxExpire = new Date(date.getFullYear() + 40, 11);
        this.allowMax = new Date(date.getFullYear(),date.getMonth(),date.getDate());
    
        this.platformType = this.platformService.getPlatformType();
        // this.minnDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        // this.maxxDate = moment(this.minnDate).add(40, 'y').format(Constants.mobileCalenderFormat);
        this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        this.mileageForm.patchValue({
            currency: this.userInfo.baseCurrency.currencyCode
        });
        // let date = new Date();
        this.commonService.googlescriptAdd();
        // this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        // this.maxExpire = new Date(date.getFullYear() + 40, 11);
        this.mileageModel.mileageRateId = this.activatedRoute.snapshot.params["id"];
        this.mileageModel.mileageRateGUID = this.activatedRoute.snapshot.params["guid"];
        this.mileageModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        this.mileageModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        this.mileageModel.categoryType = 2;
        this.mileageUnit = sessionStorage.getItem('MileageUnit');
        if (this.mileageUnit == '1') {
            this.displayRate = 'km';
        } else {
            this.displayRate = 'mile';
        }
        if (this.mileageModel.mileageRateId) {
            this.displayTitle = "Update";
            this.getSingleMileage();
       
        } else {
            this.getMileageCategories();
        }
        this.validationMsg = AppConfig;
        this.mileageModel.units = this.displayRate;
    }

    getSingleMileage() {
        this.showLoader = true;
        this.settingsService.getSingleMileage(this.mileageModel).subscribe((data: any) => {
            if (!data.isError) {
                this.showLoader = false;
                this.mileageModel = new MileageList(data.payload);
                console.log(this.mileageModel);
                if(this.mileageModel.isAutoCalculateDistanceAmount) {
                    this.mileageForm.controls.rate.setValidators([Validators.required]);
                } else {
                    this.mileageForm.controls.rate.setValidators([]);
                }
                this.mileageForm.controls.rate.updateValueAndValidity();
                this.minDate= new Date(data.payload.startDate);
                // if (this.mileageModel.startDate) {
                //     this.showStartDate = true;
                //     this.mileageForm.controls.startDate.patchValue(
                //         this.convertDateToUtc(this.mileageModel.startDate)
                //     );
                // }
                if(this.mileageModel.countryId){
                    const statesBody = {
                      countryId: this.mileageModel.countryId
                    };
                    this.getStates(statesBody);
                  }
                this.getMileageCategories();
            } else {
                this.toastr.error(data.errorMessage, "Error");
            }
        });
    }

    startDate(formatDate) {
        if (this.platformType == 1) {
            this.showStartDate = true;
            const startDate = this.displaydate.convertAPIToCalendar(formatDate.target.value);
            this.mileageForm.patchValue({
                startDate: startDate
            });
        } else {
            this.mileageModel.startDate = moment(new Date(formatDate.detail.value)).format(Constants.mobileCalenderFormat);
        }
    }

    removestartDate(type) {
        if (type == 'startDate') {
            this.mileageForm.controls.startDate.patchValue('');
            this.showStartDate = false;
        }
    }

    addorUpdateMileage() {
        this.submitted = true;
        // let date = new Date();
        // let latestDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        // let start = new Date(this.mileageForm.get('startDate').value);
        // if (start.getTime() < latestDate.getTime() && this.mileageModel.startDate) {
        //     this.calendarErrorMsg = true;
        // }
        if (!this.mileageForm.valid) {
            return;
        } else {
            this.buttonDisabled = true;
            this.settingsService.saveMileage(this.mileageModel).subscribe((data) => {
                if (!data.isError) {
                    this.buttonDisabled = false;
                    this.toastr.success(data.payload, 'Success');
                    this.backToMileage();
                } else {
                    this.buttonDisabled = false;
                    this.toastr.error(data.errorMessage, 'Error');
                    this.backToMileage();
                }
            });
        }
    }

    backToMileage() {
        this.router.navigateRoot(['/settings/mileage']);
    }

    get f() {
        return this.mileageForm.controls;
    }
    onissueDate(e) {
        this.minDate = new Date(e.target.value);
        let issuedate = this.displaydate.convertAPIToCalendar(e.target.value);
        this.mileageForm.patchValue({ startDate: issuedate });
        this.mileageForm.patchValue({ endDate: "" });
    }
    onexpireDate(e) {
        let expiredate = this.displaydate.convertAPIToCalendar(e.target.value);
        this.mileageForm.patchValue({ endDate: expiredate });
    }
    async openDateSheet(format?: string) {
        const modal = await this.modalController.create({
            component: CustomDatePickerComponent,
            componentProps: {
                selectedDate: this.selectedDate ? this.selectedDate : new Date(),
                fromDate: this.minDate,
                toDate: this.maxxDate
            },
            cssClass: 'custom-bottom-sheet'
        });
        modal.onDidDismiss().then(result => {
            if (result.role === 'changed' && result.data) {
                this.selectedDate = result.data.displayDate;
                this.mileageModel.startDate = moment(result.data.displayDate).format(Constants.mobileCalenderFormat);
            }
        });
        return await modal.present();
    }

    convertDateToUtc(date) {
        this.selectedDate = moment(new Date(date)).format('DD MMM YYYY');
        return this.selectedDate;
    }

    resetFormControlAndBlur(form: FormGroup, formControlName: string, eleInput: IonInput) {
        form.controls[formControlName].reset();
        eleInput.getInputElement().then((ele) => ele.blur());
    }


    selectFromMap(e) {
        e.preventDefault();
        e.stopPropagation();
        this.fromCitiesList = [];
        this.showFromMapField = !this.showFromMapField;
        setTimeout(() => {
            this.googleSearchAutoComplete.nativeElement.focus();
        }, 100);
    }

    onFromFocus(e) {
        e.preventDefault();
        e.stopPropagation();
        this.fromCitiesList = [];
        // this.fromCitiesList = this.policyTravelModel.country;
    }

    googleAutoCompleteSearch(val) {
        let searchText = val.currentTarget.value.trim();

        let domesticpredictions = [];

        if (searchText.length > 2) {
            this.showcitySearchListSpinner = true;
            const displaySuggestions = (predictions, status) => {
                // if (status == google.maps.places.PlacesServiceStatus.OK) {
                //     this.showcitySearchListSpinner = false;

                //     predictions.forEach((prediction) => {
                //         domesticpredictions.push(prediction);
                //     });
                //     var dcountry = this;
                //     dcountry.fromCitiesList = domesticpredictions;


                // }
                ;
            };
            if (this.sessionTokenEnable) {
                // this.sessionToken = new google.maps.places.AutocompleteSessionToken();
                // this.sessionTokenEnable = false;
            }
            this.requestType = {
                input: searchText,
                // types: ['(cities)'],
                componentRestrictions: { country: 'IN' },
                sessionToken: this.sessionToken,
            };

            // const service = new google.maps.places.AutocompleteService();
            // service.getPlacePredictions(this.requestType, displaySuggestions);
        }


    }

    addCityandCountry(event, element: any, From: string) {
        event.preventDefault();
        event.stopPropagation();
      //  console.log("dss", element);
        this.mileageModel.locationName = element.description;
        this.mileageModel.locationId = element.place_id;
        this.geocodeAddress(element.description, element.place_id, From);
    }

    geocodeAddress(location: string, place_id: any, From: string) {
        // this.travelFromModel.description = location;
        // this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode({ 'address': location }, (results, status) => {
            // this.ngZone.run(() => {
            //     if (status == google.maps.GeocoderStatus.OK) {

            //         // this.expenseModel.location = location;
            //         this.showFromMapField = false;

            //     }
            // });
        });
    }
    onClickedOutsideCountry() {
        this.showCountryList = false;
    }
    openCountryDropdown(e) {
        this.closeAll();
        e.preventDefault();
        e.stopPropagation();
        this.showCountryList = true;
        setTimeout(() => {
            this.countryAutoCompleteReference.nativeElement.focus();
        }, 0);
    }
    closeAll() {
        this.showCountryList = false;
        this.showStateList = false;
    }
    onCountryEventFocus() {
        setTimeout(() => {
            this.countryData = this.countryList;
        }, 0);
    }
    selectEventCountry(e) {
        this.selectedCountry = e;
        this.mileageForm.patchValue({
            country: this.selectedCountry.countryName,
            state: "",
        });
        this.mileageModel.countryId = this.selectedCountry.countryId;
        this.mileageModel.stateId = "";
        const statesBody = {
            countryId: this.selectedCountry.countryId
        };
        this.getStates(statesBody);
        this.showCountryList = false;
    }
    onCountrySearch(val: any) {
        let searchText = val.currentTarget.value.trim();
        let filterCountryData = this.countryList.filter(countryData => {
            return countryData.countryName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
        });
        this.countryData = filterCountryData;
    }
    getStates(statesBody) {
        this.commonService.getStates(statesBody).subscribe(data => {
            this.stateList = data.payload;
        })
    }
    onClickedOutsideState() {
        this.showStateList = false;
    }
    openStateDropDown(e) {
        this.closeAll();
        e.preventDefault();
        e.stopPropagation();
        this.showStateList = true;
        setTimeout(() => {
            this.stateAutoCompleteReference.nativeElement.focus();
        }, 0);
    }
    onStateEventFocus() {
        setTimeout(() => {
            this.stateData = this.stateList;
        }, 0)
    }
    onStateSearch(val: any) {
        let searchText = val.currentTarget.value.trim();
        let filterStateData = this.stateList.filter(stateData => {
            return stateData.stateName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
        });
        this.stateData = filterStateData;
    }
    selectEventState(e) {
        this.selectedState = e;
        this.mileageForm.patchValue({
            state: this.selectedState.stateName,
        });
        this.mileageModel.stateId = this.selectedState.stateId;
        this.showStateList = false;
    }
    @ViewChild("issuedate", { read: undefined, static: false })
    issuedate: MatDatepicker<Date>;

    @ViewChild("expiredate", { read: undefined, static: false })
    expiredate: MatDatepicker<Date>;

    startDatemethod() {
        if (this.expensePickerOp) {
          this.expensePickerOp = false;
        } else {
          this.expensePickerOp = true;
        }
        if (this.expensePickerOp) {
          this.issuedate.open();
        } else {
          this.issuedate.close();
        }
      }
      endDatemethod() {
        if (this.expensePickerOp) {
          this.expensePickerOp = false;
        } else {
          this.expensePickerOp = true;
        }
        if (this.expensePickerOp) {
            this.expiredate.open();
          } else {
            this.expiredate.close();
          }
      }
      
    //   onClickedOutsideState(){
    //     this.showStateList = false;
    //   }

    //   closeAll(){
    //     this.showStateList = false;
    //   }


    toggleAutoCalDistance(e) {
        this.mileageModel.isAutoCalculateDistanceAmount = e?.detail?.checked;
        if(this.mileageModel.isAutoCalculateDistanceAmount) {
            this.mileageForm.controls.rate.setValidators([Validators.required]);
        } else {
            this.mileageForm.controls.rate.setValidators([]);
        }
        this.mileageForm.controls.rate.updateValueAndValidity();
    }

    toggleFromToDistance(e) {
        this.mileageModel.mandateFromToLocations = e?.detail?.checked;
    }

    mileageCategoriesList: any = [];
    getMileageCategories() {
        let payload = {
            legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
            legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
        }
        this.settingsService.mileageList(payload).subscribe((data: any) => {
            this.mileageCategoriesList = data?.payload?.mileageList?.filter(
                (category) => category.categoryId !== this.mileageModel.categoryId
            );
        });
    }

    changeLinkedCat(ev) {
        this.mileageModel.linkedCategoryIds = ev.map(e => `${e.categoryId}`);
        this.mileageModel.linkedCategoryNames = ev.map(e => `${e.categoryName}`);
    }

    toggleBillNumber(e) {
        this.mileageModel.isBillNumberManditory = e?.detail?.checked;
    }
}


